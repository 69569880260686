import React, { useState, useRef } from "react";
import "./CreateMeeting.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel,
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Bin } from "../../components/svgJS/Bin";

import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

const CreateMeeting = () => {
  // Start of Create Meeting Modal
  const { TextArea } = Input;

  const [createMeetingLocalData, setCreateMeetingLocalData] = useState({
    title: "",
    start_time: "",
    end_time: "",
    description: "",
  });

  const validator = useRef(new SimpleReactValidator());

  const [forceUpdate, setForceUpdate] = useState(false);
  const [isCreateMeetingModalVisible, setCreateMeetingModalVisible] =
    useState(false);

  const handleCreate = () => {
    if (validator.current.allValid()) {
      alert("Meeting Created");
    } else {
      validator.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const handleFieldChange = (val, name) => {
    console.log({ ...createMeetingLocalData, [name]: val });
    setCreateMeetingLocalData({
      ...createMeetingLocalData,
      [name]: val,
    });
  };

  const showCreateMeetingModal = () => {
    setCreateMeetingModalVisible(true);
  };

  const handleCreateMeetingOk = () => {
    setCreateMeetingModalVisible(false);
  };

  const handleCreateMeetingCancel = () => {
    setCreateMeetingModalVisible(false);
  };

  const columns = [
    {
      title: "Delegate",
      dataIndex: "delegate",
    },
    {
      title: "Company",
      dataIndex: "company",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div>
          <EyeView />
          <span className="fs-pink">View Profile</span>
        </div>
      ),
    },
  ];
  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      delegate: `Richard Woodward ${i}`,
      company: "Gravit8",
      action: `London, Park Lane no. ${i}`,
    });
  }

  // End of Create Meeting Modal

  return (
    <>
      <Button className="ms-2" type="primary" onClick={showCreateMeetingModal}>
        Open Create Meeting
      </Button>

      <Modal
        title=""
        visible={isCreateMeetingModalVisible}
        onOk={handleCreateMeetingOk}
        onCancel={handleCreateMeetingCancel}
        className=""
        width={650}
        footer={null}
      >
        <div className=" w-100 p-4 ">
          <Row className="mt-4">
            <Col
              span={4}
              className="d-flex justify-content-end align-items-center"
            >
              <span>Meeting Title</span>
            </Col>
            <Col offset={1} span={17}>
              <Input
                placeholder="Enter a title here"
                className="createmeet_input"
                onChange={(val) => {
                  handleFieldChange(val, "title");
                }}
                onBlur={() => validator.current.showMessageFor("title")}
              />
              {validator.current.message(
                "Title",
                createMeetingLocalData.title,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              span={4}
              className="d-flex justify-content-end align-items-center"
            >
              <span>Time</span>
            </Col>
            <Col offset={1} span={8}>
              <TimePicker
                name="start_time"
                id="start_time"
                format={"HH:mm"}
                className="w-100 createmeet_input"
                onSelect={(val) => {
                  handleFieldChange(moment(val).format("hh:mm"), "start_time");
                }}
                onBlur={() => validator.current.showMessageFor("start_time")}
              />

              {validator.current.message(
                "Start Time",
                createMeetingLocalData.start_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
            <Col offset={1} span={8}>
              <TimePicker
                name="end_time"
                id="end_time"
                disabledDate={(d) =>
                  !d || d.isSameOrBefore(createMeetingLocalData.start_time)
                }
                value={createMeetingLocalData.end_time}
                format={"HH:mm"}
                className="w-100 createmeet_input"
                onSelect={(val) =>
                  handleFieldChange(moment(val).format("hh:mm"), "end_time")
                }
                onBlur={() => validator.current.showMessageFor("end_time")}
              />
              {validator.current.message(
                "End Time",
                createMeetingLocalData.end_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={4} className="d-flex justify-content-end">
              <span>Description</span>
            </Col>
            <Col offset={1} span={17}>
              <TextArea
                rows={4}
                placeholder=""
                onChange={(val) => {
                  handleFieldChange(val, "description");
                }}
                onBlur={() => validator.current.showMessageFor("description")}
              />
              {validator.current.message(
                "Description",
                createMeetingLocalData.description,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              span={4}
              className="d-flex justify-content-end align-items-center"
            >
              <span>Add Attendees</span>
            </Col>
            <Col offset={1} span={17}>
              <Input
                prefix={<SearchOutlined />}
                className="createmeet_input"
                placeholder="type attendee name or email address"
              />
            </Col>
          </Row>
          <h5 className="mt-3 ms-3 mb-2">Attendees</h5>
          <Table
            className="createmeet_table"
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 240 }}
          />
          <div className="w-100 d-flex mt-4 mb-2">
            <div className="w-50"></div>
            <Button className="createmeet_deleteBtn  fs-16">
              <Bin />
              <span className="ms-2"> Delete Event</span>
            </Button>
            <Button
              onClick={handleCreate}
              className="createmeet_saveBtn mx-2 fs-16"
            >
              <BoothRed />
              <span className="ms-2"> Save Meeting</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateMeeting;
