import React, { useState, useEffect, useRef } from "react";

import "./BrandZoneDeatils.css";
import { Button, Breadcrumb, Input, Modal, Row, Col, Tooltip } from "antd";
import {
  logo,
  chat,
  calendar,
  download,
  union,
  chatRed,
  unionRed,
  calendarRed
} from "../../components/Images/Images";

import {
  VideoCameraOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import { FilePdfOutlined, FileJpgOutlined } from "@ant-design/icons";
import PublicIcon from "@mui/icons-material/Public";
import { Link, useLocation } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Chat } from "../../components/svgJS/Chat";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import { Union } from "../../components/svgJS/Union";
import { Download } from "../../components/svgJS/Download";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";

import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { sendMessageAPI, videoRequestAPI } from "../../utils/commonApis";

const BrandZoneDetails = ({ match, brandObj, history, vistaEventId, vistaBrandId, vistaZoneData, isFromVista }) => {

  const [zoneData, setZoneData] = useState(null);

  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [zone_id, setzone_id] = useState();

  const [message_text, set_message_text] = useState("");
  let event_id = localStorage.getItem("event_id");
  let brand_id = null;
  let brandData = [];

  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: ""
  });

  const [forceUpdate, setForceUpdate] = useState(false);



  const location = useLocation();
  console.log("location", location);

  // if (vistaEventId && vistaBrandId) {
  //   event_id = vistaEventId;
  //   brand_id = vistaBrandId;
  // } else {
  //   event_id = location?.state?.event_id;
  //   brand_id = match?.params?.brand_id;
  // }


  // console.log("brand_id", brand_id);
  // console.log("event_id", event_id);
  // console.log("Vistabranddata", vistaZoneData)

  useEffect(async () => {

    if (vistaZoneData) {
      console.log("Zone Data", vistaZoneData)
      if (!Array.isArray(vistaZoneData)) {
        setZoneData(vistaZoneData);
      } else {
        setZoneData(null)
      }

    } else {
      brandData = await location?.state?.brandData;
      if (brandData && brandData.brands_default_zone_id) {
        console.log("Zone Data", brandData.brands_default_zone_id)
        setZoneData(brandData?.brands_default_zone_id);
      }


    }

    // console.log')
    // setzone_id(zoneDat.zone_id)
  }, [vistaZoneData]);

  const messageValidator = useRef(new SimpleReactValidator());

  // useEffect(async () => {

  //   await getBrandDetails();

  // }, []);

  useEffect(() => {
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        response => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid()
            });
          }
        },
        error => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, []);



  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }



  if (!zoneData) {
    return (
      <>
        {
          isFromVista ? (
            ""
          ) : (
            <div className="d-flex justify-content-between">

              <div>
                <h4 className="fs-24">Zone</h4>
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/brand">Brands</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Zone Details</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          )
        }

        <div className={isFromVista ? "w-100 text-center font-weight-bold text-danger fs-18 flex_center bg-white h-100" : "w-100  text-center font-weight-bold text-danger"} >
          Default Zone not Assigned
        </div>
      </>
    );
  } else
    return (
      <>
        {
          isFromVista ? "" : (
            <div className="d-flex justify-content-between">

              <div>
                <h4 className="fs-24">Zone</h4>
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/brand">Brands</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Zone Details</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          )
        }

        <div className="ms_page_container">
          <div className="ms_img_container">
            {zoneData &&
              zoneData?.zone_hero_image_url ? (
              <img
                className="img-fluid w-100 h-100"
                src={zoneData?.zone_hero_image_url}
              />
            ) : (
              <span className="ms_zero_hero">Zero Hero Image</span>
            )}
          </div>
          <div className="ms_page_content">
            <h6 className="ms_page_title fs-pink">
              {zoneData?.zone_title}
            </h6>
            <p className="ms_page_description">
              {zoneData?.zone_description}
            </p>
            <h6 className="ms_interact_live">Interact Live</h6>
            <div className="ms_btns_container d-flex flex-wrap">
              {/* <Button className="ms_btn">
                <Chat />
                <span>Chat</span>
              </Button> */}
              {zoneData?.is_text_chat_enable ? (
                <>
                  <Button
                    className="ms_btn"
                    onClick={() => {
                      setMessageModal({
                        isOpen: true,
                        channel_id: zoneData?.channel_id
                      });
                    }}
                  >
                    <Chat />
                    <span>Chat</span>
                  </Button>
                </>
              ) : null}

              {messageModal.isOpen ? (
                <Modal
                  title={<b>Send Message</b>}
                  visible={messageModal.isOpen}
                  onOk={() => clearMessageAndModal()}
                  onCancel={() => clearMessageAndModal()}
                  footer={[
                    <Button
                      className="px-3 button-pink rounded"
                      key="cancel"
                      onClick={() => clearMessageAndModal()}
                    >
                      Cancel
                    </Button>,
                    <Button
                      className="px-3 rounded"
                      key="save"
                      type="primary"
                      onClick={() =>
                        callSendMessageAPI(messageModal.channel_id)
                      }
                    >
                      Send
                    </Button>
                  ]}
                  className="message_modal"
                >
                  <Row className="ticker_configuration_row mt-2">
                    <Col span={6} className="ticker_configuration_label">
                      <p>
                        Message
                        <RedAsterick />
                      </p>
                    </Col>
                    <Col span={12}>
                      <TextArea
                        name="message_text"
                        id="message_text"
                        className="ticker_configuration_inputDescription"
                        placeholder="Enter a message"
                        rows={4}
                        value={message_text}
                        onChange={e => set_message_text(e.target.value)}
                        onBlur={() =>
                          messageValidator.current.showMessageFor(
                            "message_text"
                          )
                        }
                      />
                      {messageValidator.current.message(
                        "Message",
                        message_text,
                        "required",
                        { className: "text-danger py-1" }
                      )}
                    </Col>
                  </Row>
                </Modal>
              ) : null}

              {zoneData
                ?.is_one_to_one_video_calls_enable ? (
                <Button
                  onClick={async () => {
                    let channel_id =
                      zoneData?.channel_id;
                    console.log("channel_id video = ", channel_id);
                    const response = await videoRequestAPI(channel_id);
                    if (response.data.code === 200)
                      toast.success(response.data.message);
                    else toast.error(response.data.message);
                  }}
                  className="ms_btn"
                >
                  <VideoCameraOutlined />
                  <span>1-to-1 Video Call</span>
                </Button>
              ) : (
                ""
              )}

              {zoneData?.zone_demo_room_link &&
                zoneData?.is_video_demo_room_enable ? (
                <Button
                  onClick={() =>
                    window
                      .open(
                        zoneData?.zone_demo_room_link,
                        "_blank"
                      )
                      .focus()
                  }
                  className="ms_btn"
                >
                  <VideoCameraOutlined />
                  <span>Join the Demo Room</span>
                </Button>
              ) : (
                ""
              )}

              <Link
                to={`/zonecalender/${event_id}/${zoneData?.brand_id}/${zoneData?.zone_id}`}
              >
                <Button className="ms_btn">
                  <Calendar6 />
                  <span>Zone Calendar</span>
                </Button>
              </Link>

              {zoneData?.survey_id ? (
                <Button
                  onClick={() =>
                    window.open("/surveyManagement", "_blank").focus()
                  }
                  className="ms_btn"
                >
                  <Union />
                  <span>Survey</span>
                </Button>
              ) : (
                ""
              )}
            </div>
            <h6 className="ms_external_links">External & Social Links</h6>
            <div className="ms_links">
              {zoneData?.zones_social_media_handles_id?.map(
                item => {
                  let { zonesSocialMediaHandles_social_media_id } = item;
                  let {
                    social_media
                  } = zonesSocialMediaHandles_social_media_id;
                  console.log(social_media);
                  let url = "";
                  if (
                    item?.social_media_url.startsWith("http://") ||
                    item?.social_media_url.startsWith("https://")
                  ) {
                    url = item?.social_media_url;
                  } else {
                    url = "https://" + item.social_media_url;
                  }
                  if (social_media === "Instagram") {
                    return (
                      <div
                        className="cursor-pointer"
                        onClick={e => {
                          window.open(url, "_blank");
                        }}
                      >
                        <InstagramOutlined className="zone_card_links" />
                      </div>
                    );
                  } else if (social_media === "LinkedIn") {
                    return (
                      <div
                        className="cursor-pointer"
                        onClick={() => window.open(url, "_blank")}
                      >
                        <LinkedinOutlined className="zone_card_links" />
                      </div>
                    );
                  } else if (social_media === "Twitter") {
                    return (
                      <div
                        className="cursor-pointer"
                        onClick={() => window.open(url, "_blank")}
                      >
                        <TwitterOutlined className="zone_card_links" />
                      </div>
                    );
                  } else if (social_media === "facebook") {
                    return (
                      <div
                        className="cursor-pointer"
                        onClick={() => window.open(url, "_blank")}
                      >
                        <FacebookOutlined className="zone_card_links" />
                      </div>
                    );
                  }
                }
              )}

              {/* {zoneDat.zones_social_media_handles_id.forEach(
                social => {
                  console.log("social", social);
                  return (
                    <div>
                      <PublicIcon fontSize="small" />
                      <LinkedinOutlined />
                      <TwitterOutlined />
                      <FacebookOutlined />
                    </div>
                  );
                }
              )} */}
            </div>
            {/* {console.log("params", match.params.brand_id)} */}
            <h6 className="ms_assets">Assets</h6>
            <div className="container ">
              <div className="row">
                {zoneData &&
                  zoneData?.zones_assets_id?.map(
                    item => {
                      return (
                        <div className="zone_card_asset col-3 m-2 p-3 ">
                          <div className="ms_card_upper_section">
                            {item?.asset_file_type === "mp4" ? (
                              <>
                                <h6 className="ms_card_heading">
                                  {item?.asset_name}.mp4
                                </h6>
                                <VideoCameraOutlined className="assets_icons" />
                              </>
                            ) : item.asset_file_type === "pdf" ? (
                              <>
                                <h6 className="ms_card_heading">
                                  {item?.asset_name}.pdf
                                </h6>
                                <FilePdfOutlined className="assets_icons" />
                              </>
                            ) : item?.asset_file_type === "jpg" ||
                              item.asset_file_type === "jpeg" ||
                              item.asset_file_type === "png" ||
                              item.asset_file_type === "image" ? (
                              <>
                                <h6 className="ms_card_heading">
                                  {item?.asset_name}.jpg
                                </h6>
                                <FileJpgOutlined className="assets_icons" />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ms_card_lower_section">
                            <p className="ms_card_p">
                              {item?.asset_description
                                ? item?.asset_description
                                : "NA"}
                            </p>

                            {item?.asset_file_type === "mp4" ? (
                              ""
                            ) : (
                              <Button className="zone_card_download_btn flex_center p-0" onClick={() => (window.location.href = item?.asset_file_url)}>
                                <Download />

                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default BrandZoneDetails;
