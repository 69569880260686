import React, { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import { Paper, Divider } from "@material-ui/core";
import "./networkTable.css";
import _ from "lodash";
import moment from "moment";
import { imageURI } from "./SVGData";
import { Download } from "../../components/svgJS/Download";
import { Booth } from "../../components/svgJS/Booth";

import { Chat } from "../../components/svgJS/Chat";

import { Video } from "../../components/svgJS/Video";
import { Calendar6 } from "../../components/svgJS/Calendar6";

import { Avatar, Button, Modal, Row, Col, Input } from "antd";
import { joinTable } from "../../redux/slice/networking";
import { toast } from "react-toastify";
import { ApiCall, ApiCallForm } from "../../utils/api";
import { UserOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";

import {
  VideoCameraOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  FilePdfOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
import PublicIcon from "@mui/icons-material/Public";
import { getTiers } from "../../utils/commonApis";
import RedAsterick from "../../components/Widgets/RedAsterick";

import { sendMessageAPI } from "../../utils/commonApis";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { getEvents } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";

const NetworkTableUI = ({
  match,
  networkingSessionTableDetails,
  currentTableData,
  loadingTable,
}) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const [tableData, setTableData] = useState([]);
  let [tableSponsorData, setTableSponsorData] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userID, setUserID] = useState(null);
  const [userData, setUserData] = useState();
  const [tiers, setTiers] = useState();

  const [forceUpdate, setForceUpdate] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });

  const [message_text, set_message_text] = useState("");

  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });

  const messageValidator = useRef(new SimpleReactValidator());

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  useEffect(async () => {
    const tiers = await getTiers();
    setTiers(tiers);

    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, []);

  useEffect(async () => {
    console.log("enenene");
    if (currentTableData) {
      let newUsers = currentTableData?.networking_session_table_joinees;
      let url = currentTableData?.networking_session_table_link;
      let tableSponsorData = currentTableData?.sponsor_brand_details;
      initializeSponsorData(tableSponsorData, url);
      initializeTableData(newUsers, url);
    }
  }, [currentTableData]);

  useEffect(() => {
    if (userID) {
      let joineeDetails =
        currentTableData?.networking_session_table_joinees?.find((data) => {
          if (data?.user?.user_id === userID) {
            return data;
          }
        });

      if (joineeDetails) {
        console.log(joineeDetails?.user);
        let user = joineeDetails?.user;

        let tier_name =
          tiers &&
          tiers?.find((item) => {
            if (user?.tier_type_id === item?.tier_id) {
              return item;
            }
          }).tier;

        setUserData({
          user_id: user?.user_id ?? null,
          user_name: user?.user_name ?? "",
          email: user?.email ?? "",
          company: user?.company ?? "",
          job_title: user?.job_title ?? "",
          tier_name: tier_name ?? "",
          country: user?.country_name ?? "",
          user_type: user?.user_type ?? "",
          bio: user?.bio ?? "",
          image_url: user?.image_url ?? "",
          event_domain_name: user?.event_domain_name ?? "",
          social_medias: user?.users_users_social_media_handles_user_id,
        });
      }
    }
  }, [userID]);

  const initializeTableData = (users, url) => {
    console.log("usersssss", users);
    console.log("url", url);
    let seatsPerTable = currentTableData?.seat_per_tables;

    let count = 1;
    let newData = [];
    users?.forEach((item) => {
      if (item && count <= seatsPerTable) {
        newData.push({
          id: count,
          position: count,
          details: {
            user_id: item?.user?.user_id,
            name: item?.user?.user_name,
            companyName: item?.user?.company,
          },
        });
        count = count + 1;
      }
    });
    console.log("init coococ", count);
    while (count <= seatsPerTable) {
      newData.push({
        id: count,
        position: count,
        details: null,
        url: url,
      });
      count = count + 1;
    }

    console.log("new Data", newData);
    setTableData(newData);
  };

  const initializeSponsorData = (data, url) => {
    console.log(data?.zones_assets_id?.[0]?.asset_file_url);
    setTableSponsorData({
      logo_url: data?.logo_url,
      is_chat_enabled: data?.is_chat_enabled,
      is_demo_room_enabled: data?.is_demo_room_enabled,
      is_assets_enabled: data?.is_assets_enabled,
      is_booth_link_enabled: data?.is_booth_link_enabled,
      is_video_call_enabled: data?.is_video_call_enabled,
      zone_demo_room_link: url,
      asset_file_url: data?.asset_file_url,
    });
  };

  const joinTableNow = async () => {
    let finalData = {
      networking_lounge_session_table_id:
        currentTableData?.networking_lounge_session_table_id,
      joined_at: null,
      left_at: null,
    };
    const response = await ApiCall(
      "POST",
      finalData,
      "/admin/networkingsession/jointables"
    );
    console.log("Join Table Resp", response);

    if (response?.data?.code === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const ChairData = ({ data }) => {
    console.log("chair Data", data);
    return (
      // <text id="David_Bulley"></text>
      <g
        cursor="pointer"
        onClick={() => {
          showModal();

          setUserID(data?.details?.user_id);
        }}
      >
        {data?.details ? (
          <>
            <text
              id={`name${data?.id}`}
              data-name={data?.details?.name}
              transform="translate(1097 751)"
              font-size="16"
              font-family="Jost-Medium, Jost"
              font-weight="500"
            >
              <tspan x="0" y="0">
                {data?.details?.name}
              </tspan>
            </text>
            <text
              id={`company${data?.id}`}
              data-name={data?.details?.companyName}
              transform="translate(1097 770)"
              fill="#ef0855"
              font-size="12"
              font-family="Jost-Regular, Jost"
            >
              <tspan x="0" y="0">
                {data?.details?.companyName}
              </tspan>
            </text>
            <g
              transform="matrix(1, 0, 0, 1, 594, 677)"
              filter="url(#Rectangle_566-6)"
            >
              <g
                id="Rectangle_566-12"
                data-name="Rectangle 566"
                transform="translate(457 61)"
                fill="#ef0855"
                stroke="#ef0855"
                stroke-width="1"
              >
                <rect width="36" height="36" rx="5" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="35"
                  height="35"
                  rx="4.5"
                  fill="none"
                />
              </g>
            </g>
            <g
              id="_028-user-1-6"
              data-name="028-user-1"
              transform="translate(1059 746)"
            >
              <g
                id="Group_1422-6"
                data-name="Group 1422"
                transform="translate(0)"
              >
                <path
                  id="Path_49273-6"
                  data-name="Path 49273"
                  d="M19.95,17.138a.781.781,0,0,0-1.53.318.816.816,0,0,1-.165.684.794.794,0,0,1-.625.3H2.371a.794.794,0,0,1-.625-.3.816.816,0,0,1-.165-.684,8.632,8.632,0,0,1,8.213-6.835q.1,0,.207,0t.208,0a8.594,8.594,0,0,1,6.941,3.815.781.781,0,1,0,1.3-.87,10.162,10.162,0,0,0-5.266-4,5.312,5.312,0,1,0-6.359,0A10.161,10.161,0,0,0,.051,17.138,2.374,2.374,0,0,0,2.371,20H17.63a2.374,2.374,0,0,0,2.32-2.862ZM6.25,5.313a3.75,3.75,0,1,1,3.94,3.745l-.19,0-.189,0A3.755,3.755,0,0,1,6.25,5.313Z"
                  transform="translate(0)"
                  fill="#fff"
                />
              </g>
            </g>
          </>
        ) : (
          <g
            onClick={(e) => {
              e.stopPropagation();
              joinTableNow();
              window.open(data?.url, "_blank").focus();
            }}
            cursor="pointer"
          >
            <rect
              id="Rectangle_20478"
              data-name="Rectangle 20478"
              width="36"
              height="36"
              rx="5"
              transform="translate(491 312)"
              fill="#ef0855"
            />
            <g id="Booth" transform="translate(555.225 328.764)">
              <path
                id="Union_16"
                data-name="Union 16"
                d="M16.8,19.219a.78.78,0,0,1,.779-.781.822.822,0,0,0,.82-.822.78.78,0,1,1,1.56,0A2.384,2.384,0,0,1,17.582,20,.78.78,0,0,1,16.8,19.219ZM11.852,20H6.163a.781.781,0,0,1,0-1.562H13.8A.781.781,0,0,1,13.8,20ZM0,17.616a.78.78,0,1,1,1.56,0,.821.821,0,0,0,.82.822.781.781,0,0,1,0,1.562A2.384,2.384,0,0,1,0,17.616ZM9.958,15a.784.784,0,0,1-.006-1.107l2.921-2.964,0,0a.784.784,0,0,0,0-1.107L9.956,6.894a.782.782,0,1,1,1.1-1.107l2.921,2.924a2.354,2.354,0,0,1,0,3.319l-2.92,2.962A.78.78,0,0,1,9.958,15Zm8.483-.977V6.368a.779.779,0,1,1,1.559,0v7.654a.779.779,0,1,1-1.559,0ZM0,14.022V6.368a.78.78,0,1,1,1.56,0v7.654a.78.78,0,1,1-1.56,0Zm5.664-2.867a.783.783,0,0,1,0-1.566h5.7a.783.783,0,0,1,0,1.566ZM18.4,2.384a.822.822,0,0,0-.82-.822.781.781,0,0,1,0-1.562,2.385,2.385,0,0,1,2.38,2.384.78.78,0,1,1-1.56,0ZM0,2.384A2.384,2.384,0,0,1,2.381,0a.781.781,0,0,1,0,1.562.822.822,0,0,0-.821.822A.779.779,0,1,1,0,2.384Zm11.85-.822H6.163A.781.781,0,0,1,6.163,0H13.8a.781.781,0,0,1,0,1.562Z"
                transform="translate(-56.225 -8.764)"
                fill="#fff"
              />
            </g>
          </g>
        )}
      </g>
    );
  };

  if (loadingTable) {
    return (
      <>
        <CommonLoader />
      </>
    );
  } else
    return (
      <Fragment>
        <Paper>
          {console.log("Table Sponsor Data", tableSponsorData)}
          {console.log(
            "Net Session Table details",
            networkingSessionTableDetails
          )}
          {console.log("Table data", tableData)}
          {console.log("Current Table Data", currentTableData)}
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={900}
            footer={null}
          >
            <div className="p-2">
              <Row className="netTable_profile_header pt-4 ">
                <Col span={2}>
                  <Avatar src={userData?.image_url} />
                </Col>
                <Col span={4}>
                  <span className="text-black">{userData?.user_name}</span>
                </Col>
                <Col span={6}>
                  <span className="text-black">{userData?.email}</span>
                </Col>
                <Col span={6}>
                  <span className="text-black">
                    {userData?.event_domain_name}
                  </span>
                </Col>
                <Col className="" span={6}>
                  <div className="d-flex align-items-center ms-5">
                    <PublicIcon fontSize="small" />
                    <LinkedinOutlined className="ms-2" />
                    <TwitterOutlined className="ms-2" />
                    <FacebookOutlined className="ms-2" />
                  </div>
                </Col>
              </Row>
              <Divider />
              <div className="netTable_profile_content p-2">
                <Row>
                  <Col span={6}>
                    <Avatar
                      shape="square"
                      size={200}
                      src={userData?.image_url}
                    />
                  </Col>
                  <Col span={18}>
                    <div className="d-flex w-100">
                      <div className="neTTable_profile_userData p-2">
                        <span>User Type</span>
                        <p className="text-black">{userData?.user_type}</p>
                      </div>
                      <div className="neTTable_profile_userData p-2">
                        <span>Tier</span>
                        <p className="text-black">{userData?.tier_name}</p>
                      </div>
                      <div className="neTTable_profile_userData p-2">
                        <span>Company</span>
                        <p className="text-black">{userData?.company}</p>
                      </div>
                      <div className="neTTable_profile_userData p-2">
                        <span>Job Role</span>
                        <p className="text-black">{userData?.job_title}</p>
                      </div>
                      <div className="neTTable_profile_userData p-2">
                        <span>Country</span>
                        <p className="text-black">{userData?.country}</p>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <span className="w-25">Bio</span>
                      <div>
                        <span>{userData?.bio}</span>
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <Button className="">
                        <Chat />
                        <span className="ms-2">Chat</span>
                      </Button>
                      <Button className="ms-2">
                        <Video />
                        <span className="ms-2">Video Call</span>
                      </Button>
                      <Button className="ms-2">
                        <Calendar6 />
                        <span className="ms-2">Book a Meeting</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
          <div className="d-flex">
            <div className="left_netTable d-flex flex-column align-items-center">
              {console.log("user data", userData)}
              <h5 className="fs-18 text-center">Sponsored by</h5>

              <img
                className="sponsored_netTable_img mb-3"
                src={tableSponsorData?.logo_url}
              />
              {console.log(tableSponsorData?.asset_file_url)}
              {tableSponsorData?.is_assets_enabled &&
              tableSponsorData?.asset_file_url ? (
                <Button
                  onClick={() =>
                    (window.location.href = tableSponsorData?.asset_file_url)
                  }
                  className="netTable_btn border p-1"
                >
                  <div className="text-start ms-4">
                    <Download />
                    <span className="ms-2">Sponsorasset.pdf</span>
                  </div>
                </Button>
              ) : (
                ""
              )}

              {tableSponsorData?.is_booth_link_enabled ? (
                <Button className="netTable_btn border p-1">
                  <div className="text-start ms-4">
                    <Booth />
                    <span className="ms-2">Visit Booth</span>
                  </div>
                </Button>
              ) : (
                ""
              )}

              {tableSponsorData?.is_chat_enabled &&
              currentTableData?.channel_id ? (
                <Button
                  onClick={() => {
                    setMessageModal({
                      isOpen: true,
                      channel_id: currentTableData?.channel_id,
                    });
                  }}
                  className="netTable_btn border p-1"
                >
                  <div className="text-start ms-4">
                    <Chat />
                    <span className="ms-2">Chat</span>
                  </div>
                </Button>
              ) : (
                ""
              )}

              {tableSponsorData?.is_demo_room_enabled &&
              tableSponsorData?.zone_demo_room_link ? (
                <Button
                  onClick={() =>
                    window
                      .open(tableSponsorData?.zone_demo_room_link, "_blank")
                      .focus()
                  }
                  className="netTable_btn border p-1"
                >
                  <div className="text-start ms-4">
                    <Video />
                    <span className="ms-2">Join the Demo Room</span>
                  </div>
                </Button>
              ) : (
                ""
              )}

              {tableSponsorData?.is_video_call_enabled ? (
                <Button className="netTable_btn border p-1">
                  <div className="text-start ms-4">
                    <Video />
                    <span className="ms-2">Next Available Agent</span>
                  </div>
                </Button>
              ) : (
                ""
              )}
            </div>
            <div className="right_netTable">
              <div className="conf-table-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="700px"
                  height="582.087"
                  viewBox="0 0 900 582.087"
                >
                  <defs>
                    <pattern
                      id="pattern"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="0 0 1120 800"
                    >
                      <image width="1120" height="800" xlinkHref={imageURI} />
                    </pattern>
                    <filter
                      id="Rectangle_566"
                      x="79"
                      y="269"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                    <filter
                      id="Rectangle_566-2"
                      x="241"
                      y="474"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur-2" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur-2" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                    <filter
                      id="Rectangle_566-3"
                      x="653"
                      y="474"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur-3" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur-3" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                    <filter
                      id="Rectangle_566-4"
                      x="717"
                      y="205"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur-4" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur-4" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                    <filter
                      id="Rectangle_566-5"
                      x="618"
                      y="75"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur-5" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur-5" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                    <filter
                      id="Rectangle_566-6"
                      x="427"
                      y="34"
                      width="96"
                      height="96"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="10" result="blur-6" />
                      <feFlood flood-color="#ef0855" flood-opacity="0.2" />
                      <feComposite operator="in" in2="blur-6" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g id="Table" transform="translate(-828 -257)">
                    <rect
                      id="NeXP0s"
                      width="814.121"
                      height="582.087"
                      transform="translate(859.435 257)"
                      fill="url(#pattern)"
                    />
                    {/* 1st chair and empty icon */}
                    {tableData &&
                      tableData?.map((item) => {
                        return (
                          <>
                            {item?.position === 1 ? (
                              <g
                                id="Group_12692"
                                transform={
                                  item?.details
                                    ? "translate(232 -410)"
                                    : "translate(804, -5)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 2 ? (
                              <g
                                id="Group_12693"
                                transform={
                                  item?.details
                                    ? "translate(435 -375)"
                                    : "translate(960, 40)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 3 ? (
                              <g
                                id="Group_12694"
                                transform={
                                  item?.details
                                    ? "translate(524 -249)"
                                    : "translate(1080, 200)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 4 ? (
                              <g
                                id="Group_12695"
                                transform={
                                  item?.details
                                    ? "translate(460 20)"
                                    : "translate(990, 430)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 5 ? (
                              <g
                                id="Group_12696"
                                transform={
                                  item?.details
                                    ? "translate(150 80)"
                                    : "translate(745, 480)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 6 ? (
                              <g
                                id="Group_12697"
                                transform={
                                  item?.details
                                    ? "translate(-190 -90)"
                                    : "translate(480, 320)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : (
                              ""
                            )}
                            {item?.position === 7 ? (
                              <g
                                id="Group_12698"
                                transform={
                                  item?.details
                                    ? "translate(-125 -350)"
                                    : "translate(465, 90)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : null}
                            {item?.position === 8 ? (
                              <g
                                id="Group_12698"
                                transform={
                                  !item?.details
                                    ? "translate(600, 10)"
                                    : "translate(55, -410)"
                                }
                              >
                                <ChairData data={item} />
                              </g>
                            ) : null}
                          </>
                        );
                      })}
                  </g>
                </svg>
              </div>
            </div>
          </div>
          {messageModal.isOpen ? (
            <Modal
              title={<b>Send Message</b>}
              visible={messageModal.isOpen}
              onOk={() => clearMessageAndModal()}
              onCancel={() => clearMessageAndModal()}
              footer={[
                <Button
                  className="px-3 button-pink rounded"
                  key="cancel"
                  onClick={() => clearMessageAndModal()}
                >
                  Cancel
                </Button>,
                <Button
                  className="px-3 rounded"
                  key="save"
                  type="primary"
                  onClick={() => callSendMessageAPI(messageModal.channel_id)}
                >
                  Send
                </Button>,
              ]}
              className="message_modal"
            >
              <Row className="ticker_configuration_row mt-2">
                <Col span={6} className="ticker_configuration_label">
                  <p>
                    Message
                    <RedAsterick />
                  </p>
                </Col>
                <Col span={12}>
                  <TextArea
                    name="message_text"
                    id="message_text"
                    className="ticker_configuration_inputDescription"
                    placeholder="Enter a message"
                    rows={4}
                    value={message_text}
                    onChange={(e) => set_message_text(e.target.value)}
                    onBlur={() =>
                      messageValidator.current.showMessageFor("message_text")
                    }
                  />
                  {messageValidator.current.message(
                    "Message",
                    message_text,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            </Modal>
          ) : null}
        </Paper>
      </Fragment>
    );
};

export default NetworkTableUI;

// const [tableData, setTableData] = useState([
//   {
//     id: 1,
//     position: 1,
//     details: {
//       name: "Alaistair Reece",
//       companyName: "DBPixels"
//     }
//   },
//   {
//     id: 2,
//     position: 2,
//     details: {
//       name: "Richard Woodward",
//       companyName: "DBPixelHouse"
//     }
//   },
//   {
//     id: 3,
//     position: 3,
//     details: {
//       name: "Jon Snow",
//       companyName: "Microsoft"
//     }
//   },
//   {
//     id: 4,
//     position: 4,
//     details: {
//       name: "Rob Stark",
//       companyName: "Samsung"
//     }
//   },
//   {
//     id: 5,
//     position: 5,
//     details: {
//       name: "Jane Doe",
//       companyName: "Brainvire"
//     }
//   },
//   {
//     id: 6,
//     position: 6,
//     details: {
//       name: "Theon Greyjoy",
//       companyName: "DBPixel"
//     }
//   },
//   {
//     id: 7,
//     position: 7,
//     details: {
//       name: "George Orwell",
//       companyName: "Animal Farm"
//     }
//   },
//   {
//     id: 8,
//     position: 8,
//     details: null
//   }
// ]);
