import React, { useEffect } from "react";
import "./index.css";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import CryptoAES from "crypto-js/aes";

const ResetPassword = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const brand_color = query.get("brand_color");
  const logo_url = query.get("logo_url");

  useEffect(() => {
    window.less
      .modifyVars({
        "@primary-color": "#" + brand_color,
      })
      .then(() => {
        // console.log("Theme updated successfully 1234");
      });
  }, []);

  const onFinish = async (values) => {
    if (values.password !== values.confirm_password) {
      toast.error("Confirm Password does not match");
    } else {
      let obj = {
        token: token,
        password: values.password,
        confirm_password: values.password,
      };
      console.log(JSON.stringify(obj));
      let ciphertext = CryptoAES.encrypt(
        JSON.stringify(obj),
        "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe"
      ).toString();
      const response = await ApiCall(
        "POST",
        { data: ciphertext },
        "/admin/resetpassword",
        ""
      );
      if (response.data.code === 200) {
        toast.success(response.data.message);
        props.history.push("/login");
      } else toast.error(response.data.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="rsp_bg d-flex flex-column align-items-center">
      <img
        className="login_img_logo resetpass_logo mb-3"
        src={logo_url}
        alt="logo"
      />
      <div className="rsp_container mt-3  text-center">
        <h3 className="fs-pink mb-5 mt-4 mt-2">Reset Password</h3>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          className="mx-2"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              className="resetpass_input"
              placeholder="Enter Password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              className="resetpass_input"
              placeholder="Enter Password"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button className="rs_btn" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
