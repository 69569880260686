import React from 'react';
import { Button, Result } from 'antd';
const PageNotFound = () => {
    return (
        <div className="m-auto text-center h-100"
            style={{ height: "150%" }}
        >
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                // extra={<Button type="primary">Back Home</Button>}
                className="h-100"
            />
        </div>
    )
}
export default PageNotFound