/**
 * This dummy data is to replace where the api would return data,
 * and is in no way to be used as a representation of the structure of the api
 * it is a means to demo the return of the data we need, nothing more
 */

// Every vista index within the hall will have a "exit" deep link value
// This value can be used to deep link to that location
// We can also pass a degree value pointing towards an index of the stand

// Stand and hall relation
// Every stand location/index within a hall will have an exit index and rotation deep link

export const HOTSPOT_TYPES = {
	ASSET: 1,
	BRAND_ZONE: 2,
	VIEW: 3,
	NAVIGATE: 4,
};

export const event = {
	id: 1,
	area: [
		{
			name: "concourse",
			url: "some vista url here??",
			deepLinks: [1, 2, 3, 4],
		},
		{
			name: "networking",
			url: "some vista url here??",
			deepLinks: [1, 2, 3, 4],
		},
		{
			name: "audatorium",
			url: "some vista url here??",
			deepLinks: [1, 2, 3, 4],
		},
	],
	halls: [
		{
			id: 1,
			url: "SOME VISTA",
			stands: [
				{
					id: 1,
					status: true,
				},
				{
					id: 2,
					status: true,
				},
			],
		},
	],
};

// All stands have 10 panos
export const standsData = [
	{
		id: 1,
		url: "/booth-publish/diamond/index.html",
		exit: 2, // this will be the pano to return to within the hall (deep link location)
		hotspots: [
			{
				target: 24,
				index: 1,
				status: false,
			},
			{
				target: 25,
				index: 2,
				status: false,
			},
			{
				target: 26,
				index: 3,
				status: true,
			},
			{
				target: 27,
				index: 4,
				status: false,
			},
			{
				target: 28,
				index: 5,
				status: false,
			},
			{
				target: 29,
				index: 6,
				status: false,
			},
			{
				target: 30,
				index: 7,
				status: false,
			},
			{
				target: 31,
				index: 8,
				status: false,
			},
			{
				target: 32,
				index: 9,
				status: false,
			},
		],
	},
	{
		id: 2,
		url: "/booth-publish/gold/index.html",
		exit: 3, // this will be the pano to return to within the hall (deep link location)
		hotspots: [
			{
				target: 33,
				index: 1,
				status: false,
			},
			{
				target: 34,
				index: 2,
				status: false,
			},
			{
				target: 35,
				index: 3,
				status: true,
			},
			{
				target: 36,
				index: 4,
				status: false,
			},
			{
				target: 37,
				index: 5,
				status: false,
			},
			{
				target: 38,
				index: 6,
				status: false,
			},
		],
	},
	{
		id: 3,
		url: "/booth-publish/silver/index.html",
		exit: 4, // this will be the pano to return to within the hall (deep link location)
		hotspots: [
			{
				target: 39,
				index: 1,
				status: false,
			},
			{
				target: 40,
				index: 2,
				status: false,
			},
			{
				target: 41,
				index: 3,
				status: true,
			},
		],
	},
	{
		id: 4,
		url: "/booth-publish/bronze/index.html",
		exit: 5, // this will be the pano to return to within the hall (deep link location)
		hotspots: [
			{
				target: 42,
				index: 1,
				status: false,
			},
			{
				target: 43,
				index: 2,
				status: false,
			},
		],
	},
	{
		id: 5,
		url: "/booth-publish/copper/index.html",
		exit: 6, // this will be the pano to return to within the hall (deep link location)
		hotspots: [
			{
				target: 44,
				index: 1,
				status: false,
			},
		],
	},
];

export const hallData = [
	{
		id: 1,
		name: "Help",
		url: "/hall-publish/index.html",
		stands: [
			{
				index: 1, // which button inside vista it associates to.
				standID: 1, // target stand
				status: true,
			},
			{
				index: 2,
				standID: 1,
				status: true,
			},
			{
				index: 3,
				standID: 1,
				status: true,
			},
			{
				index: 4,
				standID: 2,
				status: true,
			},
			{
				index: 5,
				standID: 1,
				status: true,
			},
			{
				index: 6,
				standID: 3,
				status: true,
			},
			{
				index: 7,
				standID: 2,
				status: true,
			},
			{
				index: 8,
				standID: 4,
				status: true,
			},
			{
				index: 9,
				standID: 5,
				status: true,
			},
			{
				index: 10,
				standID: 5,
				status: true,
			},
		],
		hotspots: [
			{
				id: 1,
				hotSpotType: 4,
				redirectionUrl: "/concourse-publish/index.html?media-name=6",
				nextPostion: "concoursePublished",
			},
		],
	},
	{
		id: 2,
		name: "Flubber",
		url: "/hall-publish/index.html",
		stands: [
			{
				standID: 3,
				index: 1,
				status: true,
			},
			{
				standID: 4,
				index: 2,
				status: true,
			},
		],
		hotspots: [
			{
				index: 1,
				target: 12,
			},
		],
	},
];

export const hotSpotData = [
	{
		id: 1,
		data: {
			type: 4,
			value: {
				location: 2,
				url: "/concourse-publish/index.html",
			},
		},
	},
	{
		id: 2,
		data: {
			type: 3,
			value: "Reception",
		},
	},
	{
		id: 3,
		data: {
			type: 4,
			value: {
				location: 3,
				url: "/auditorium-publish/index.html",
			},
		},
	},
	{
		id: 4,
		data: {
			type: 3,
			value: "Auditorium Planner",
		},
	},
	{
		id: 5,
		data: {
			type: 4,
			value: {
				location: 4, // id of the location im going to
				url: "/networking-publish/index.html",
			},
		},
	},
	{
		id: 6,
		data: {
			type: 3,
			value: "Network Planner",
		},
	},
	{
		id: 7,
		data: {
			type: 1,
			value: {
				id: 1,
				name: "Sponsor 1 GFX",
				type: "image",
				url: "some url",
			},
		},
	},
	{
		id: 8,
		data: {
			type: 1,
			value: {
				id: 1,
				name: "Sponsor 2 GFX",
				type: "image",
				url: "some url",
			},
		},
	},
	{
		id: 9,
		data: {
			type: 3,
			value: "Social Wall",
		},
	},
	{
		id: 10,
		data: {
			type: 3,
			value: "Exhibition Map",
		},
	},
	{
		id: 11,
		data: {
			type: 3,
			value: "expo",
		},
	},
	{
		id: 12,
		data: {
			type: 4,
			value: {
				location: 2,
				// find the right pano to go back to in the concourse
				url: "/concourse-publish/index.html?media-name=3",
			},
		},
	},
	{
		id: 13,
		data: {
			type: 3,
			value: "Auditorium Main Stream",
		},
	},
	{
		id: 14,
		data: {
			type: 3,
			value: "Auditorium Calendars 1",
		},
	},
	{
		id: 15,
		data: {
			type: 3,
			value: "Auditorium Calendars 2",
		},
	},
	{
		id: 16,
		data: {
			type: 4,
			value: {
				location: 2,
				url: "/concourse-publish/index.html",
			},
		},
	},
	{
		id: 17,
		data: {
			type: 3,
			value: "Networking Reception",
		},
	},
	{
		id: 18,
		data: {
			type: 3,
			value: "Quick Join 1",
		},
	},
	{
		id: 19,
		data: {
			type: 3,
			value: "Quick Join 2",
		},
	},
	{
		id: 20,
		data: {
			type: 4,
			value: {
				location: 2,
				url: "/concourse-publish/index.html",
			},
		},
	},
	{
		id: 21,
		data: {
			type: 3,
			value: "Satellite Calendar 1",
		},
	},
	{
		id: 22,
		data: {
			type: 3,
			value: "Satellite View Stream",
		},
	},
	{
		id: 23,
		data: {
			type: 3,
			value: "Satellite Calendar 2",
		},
	},
	// ------DIAMOND STAND------
	{
		id: 24,
		data: {
			type: 3,
			value: "Stand1:Hotspot1",
		},
	},
	{
		id: 25,
		data: {
			type: 3,
			value: "Stand1:Hotspot2",
		},
	},
	{
		id: 26,
		data: {
			type: 3,
			value: "Stand1:Hotspot3",
		},
	},
	{
		id: 27,
		data: {
			type: 3,
			value: "Stand1:Hotspot4",
		},
	},
	{
		id: 28,
		data: {
			type: 3,
			value: "Stand1:Hotspot5",
		},
	},
	{
		id: 29,
		data: {
			type: 3,
			value: "Stand1:Hotspot6",
		},
	},
	{
		id: 30,
		data: {
			type: 3,
			value: "Stand1:Hotspot7",
		},
	},
	{
		id: 31,
		data: {
			type: 3,
			value: "Stand1:Hotspot8",
		},
	},
	{
		id: 32,
		data: {
			type: 3,
			value: "Stand1:Hotspot9",
		},
	},
	// ------GOLD STAND------
	{
		id: 33,
		data: {
			type: 3,
			value: "Stand2:Hotspot1",
		},
	},
	{
		id: 34,
		data: {
			type: 3,
			value: "Stand2:Hotspot2",
		},
	},
	{
		id: 35,
		data: {
			type: 3,
			value: "Stand2:Hotspot3",
		},
	},
	{
		id: 36,
		data: {
			type: 3,
			value: "Stand2:Hotspot4",
		},
	},
	{
		id: 37,
		data: {
			type: 3,
			value: "Stand2:Hotspot5",
		},
	},
	{
		id: 38,
		data: {
			type: 3,
			value: "Stand2:Hotspot6",
		},
	},
	// ------SILVER STAND------
	{
		id: 39,
		data: {
			type: 3,
			value: "Stand3:Hotspot1",
		},
	},
	{
		id: 40,
		data: {
			type: 3,
			value: "Stand3:Hotspot2",
		},
	},
	{
		id: 41,
		data: {
			type: 3,
			value: "Stand3:Hotspot3",
		},
	},
	// ------BRONZE STAND------
	{
		id: 42,
		data: {
			type: 3,
			value: "Stand4:Hotspot1",
		},
	},
	{
		id: 43,
		data: {
			type: 3,
			value: "Stand4:Hotspot2",
		},
	},
	// ------COPPER STAND------
	{
		id: 44,
		data: {
			type: 3,
			value: "Stand5:Hotspot1",
		},
	},
	// ------EXIT DEEP LINKS------
	{
		id: 45,
		data: {
			type: 4,
			hotSpotType: 4,
			redirectionUrl: "/concourse-publish/index.html?media-name=3",
			nextPostion: "concoursePublished",
		},
	},
	{
		id: 46,
		data: {
			type: 4,
			value: {
				location: 2,
				// exit from networking to concourse in front of networking door
				url: "/concourse-publish/index.html?media-name=4",
			},
		},
	},
	{
		id: 47,
		data: {
			type: 4,
			value: {
				location: 2,
				// exit from satellite to concourse main entry
				url: "/concourse-publish/index.html?media-name=6",
			},
		},
	},
	{
		id: 48,
		data: {
			type: 4,
			value: {
				location: 2,
				// exit from auditorium to in front of auditorium door
				url: "/concourse-publish/index.html?media-name=3",
			},
		},
	},
	{
		id: 49,
		data: {
			type: 4,
			value: {
				location: 5,
				// Go to the satellites
				url: "/satellite-publish/index.html",
			},
		},
	},
	{
		id: 50,
		data: {
			type: 4,
			value: {
				location: 2,
				// Exit the Satellites
				url: "/concourse-publish/index.html?media-name=3",
			},
		},
	},
];

export const locationData = [
	{
		id: 1, // Exterior
		hotspots: [
			{
				target: 1,
			},
		],
	},
	{
		id: 2, // Concourse
		hotspots: [
			{
				index: 1,
				target: 2, // Reception hotspot
			},
			{
				index: 2,
				target: 3, // Auditorium
			},
			{
				index: 3,
				target: 4, // Auditorium Planner
			},
			{
				index: 4,
				target: 5, // Networking
			},
			{
				index: 5,
				target: 6, // Networking Planner
			},
			{
				index: 6,
				target: 7, // Sponsor 1 GFX
			},
			{
				index: 7,
				target: 8, // Sponsor 2 GFX
			},
			{
				index: 8,
				target: 9, // Social Wall
			},
			{
				index: 9,
				target: 10, // Expo Map
			},
			{
				index: 10,
				target: 11, // Enter Exhibition
			},
			{
				index: 11,
				target: 49, // Enter Satellites
			},
		],
	},
	{
		id: 3, // Auditorium
		hotspots: [
			{
				index: 1,
				target: 48,
			},
			{
				index: 2,
				target: 13,
			},
			{
				index: 3,
				target: 14,
			},
			{
				index: 4,
				target: 15,
			},
		],
	},
	{
		id: 4, // Networking
		hotspots: [
			{
				index: 1,
				target: 46,
			},
			{
				index: 2,
				target: 17,
			},
			{
				index: 3,
				target: 18,
			},
			{
				index: 4,
				target: 19,
			},
		],
	},
	{
		id: 5, // Satellite
		hotspots: [
			{
				index: 1,
				target: 50,
			},
			{
				index: 2,
				target: 21,
			},
			{
				index: 3,
				target: 22,
			},
			{
				index: 4,
				target: 23,
			},
		],
	},
];

export const exteriorPublished = [
	{
		id: 1,
		hotSpotType: 4,
		redirectionUrl: "/concourse-publish/index.html",
		nextPostion: "concoursePublished",
	},
];

export const concoursePublished = [
	{
		id: 1,
		hotSpotType: 4,
		redirectionUrl: "/concourse-publish/index.html",
		nextPostion: "concoursePublished",
	},
	{
		id: 2,
		hotSpotType: 4,
		redirectionUrl: "/auditorium-publish/index.html",
		nextPostion: "auditoriumPublished",
	},
	{
		id: 3,
		hotSpotType: 3,
		nextPostion: "concoursePublished",
	},
	{
		id: 4,
		hotSpotType: 4,
		redirectionUrl: "/networking-publish/index.html",
		nextPostion: "networkingPublished",
	},
	{
		id: 8,
		hotSpotType: 1,
		nextPostion: "concoursePublished",
		url: "https://worldfinancialreview.com/wp-content/uploads/2022/03/Eventos-virtuales-1080x675-1.jpg",
		value: "Social Wall",
		type: "image",
	},
];

export const auditoriumPublished = [
	{
		id: 2,
		hotSpotType: 1,
		nextPostion: "auditoriumPublished",
		url: "https://vimeo.com/243556536",
		value: "Auditorium Main Stream",
		type: "video",
	},
	{
		id: 1,
		hotSpotType: 4,
		redirectionUrl: "/concourse-publish/index.html?media-name=3",
		nextPostion: "concoursePublished",
	},
];

export const networkingPublished = [
	{
		id: 1,
		hotSpotType: 4,
		redirectionUrl: "/concourse-publish/index.html?media-name=4",
		nextPostion: "concoursePublished",
	},
	{
		id: 2,
		hotSpotType: 1,
		nextPostion: "networkingPublished",
		url: "https://vimeo.com/243556536",
		value: "Networking Main Stream",
		type: "video",
	},
];

export const satellitePublished = [
	{
		id: 1,
		hotSpotType: 4,
		redirectionUrl: "/concourse-publish/index.html?media-name=9",
		nextPostion: "concoursePublished",
	},
	{
		id: 2,
		hotSpotType: 1,
		nextPostion: "networkingPublished",
		url: "https://vimeo.com/243556536",
		value: "Networking Main Stream",
		type: "video",
	},
];
