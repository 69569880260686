import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./ProductZoneSession.css";
import "./../../EventCalender/eventCalender.css";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Switch,
  Empty,
  Table,
  TimePicker,
  Select,
  DatePicker,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { blackbin } from "../../../components/Images/Images";
import FileUpload from "../../common/FileUpload";
import {
  getZoneSessionDetails,
  updateZoneSessionDetails,
  createZoneSession,
  getInvitationDetails,
} from "./../../../redux/slice/productZoneSession";
import {
  getJobTitles,
  getCountryList,
  getUsers,
  getZoneDropdowns,
  getBrandDropdowns,
  getAgentsForUser,
} from "./../../../utils/commonApis";
import _, { cloneDeep } from "lodash";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { mapSelectedDdlData } from "../../../utils/commonFunctions";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { dialogs } from "../../../components/constsnts/string.constants";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";

const ProductZoneSession = (appointmentData) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  //Validations
  const productzonesession_validator = useRef(new SimpleReactValidator());
  const [productZoneSessionForceUpdate, setProductZoneSessionForceUpdate] =
    useState(false);

  const [agentsList, setAgentsList] = useState([]);
  const [taggedAgents, setTaggedAgents] = useState([]);
  const [whiteListUsers, setWhiteListUsers] = useState([]);
  const [blackListUsers, setBlackListUsers] = useState([]);
  const [whiteListBrands, setWhiteListBrands] = useState([]);
  const [blackListBrands, setBlackListBrands] = useState([]);
  const [whiteListCountries, setWhiteListCountries] = useState([]);
  const [blackListCountries, setBlackListCountries] = useState([]);
  const [whiteListJobTitles, setWhiteListJobTitles] = useState([]);
  const [blackListJobTitles, setBlackListJobTitles] = useState([]);
  const [invited_brands, set_invited_brands] = useState([]);
  const [invited_zones, set_invited_zones] = useState([]);
  const [invited_user_types, set_invited_user_types] = useState([]);
  const [invited_countries, set_invited_countries] = useState([]);
  const [invited_job_titles, set_invited_job_titles] = useState([]);
  const [loader, setLoader] = useState(true);
  const [previous_pending_invites, set_previous_pending_invites] = useState([]);
  const [invitationModal, setInvitationModal] = useState({
    isOpen: false,
    data: null,
    isLoader: false,
  });
  const [invitationTableData, setInvitationTableData] = useState([]);
  const [delete_scheduled_invitations, set_delete_scheduled_invitations] =
    useState([]);

  let date = moment().format("YYYY/MM/DD HH:mm");

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type - b.user_type,
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return getStatusButton(record);
      },
      // sorter: (a, b) => a.rsvp_type - b.rsvp_type,
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const [brandsCollection, setBrandsCollection] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [countryCollection, setCountryCollection] = useState([]);
  const [jobTitleCollection, setJobTitleCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);

  const [productZoneSessionFields, setProductZoneSessionFields] = useState({
    productZoneSession_start_date_time: moment(
      moment().format("HH:mm"),
      TIME_FORMAT
    ),
    productZoneSession_end_date_time: moment(
      moment().format("HH:mm"),
      TIME_FORMAT
    ), //moment().add(30, "minutes"),
    productZoneSession_title: "",
    productZoneSession_description: "",
    is_ical_download_enabled: false,

    productZoneSession_specific_users: "",
    productZoneSession_brand_list: "",
    productZoneSession_country_list: "",
    productZoneSession_job_titles_list: "",

    productZoneSession_invitees_brand: "",
    productZoneSession_invitees_zone: "",
    productZoneSession_invitees_user_type: "",
    productZoneSession_invitees_country: "",
    productZoneSession_invitees_job_title: "",

    productZoneSession_prev_pending_date_time_1: "",
    productZoneSession_prev_pending_date_time_2: "",
    productZoneSession_invitation_title: "",
    productZoneSession_invitation_description: "",
    productZoneSession_invitation_is_ical_download_enabled: false,
    productZoneSession_invitation_re_invite: false,
    productZoneSession_schedule_invitation: false,
    productZoneSession_when_to_send_date_time: undefined,
    productZoneSession_schedule_now: false,
  });

  const [productZoneSessionFileUpload, setProductZoneSessionFileUpload] =
    useState("");

  const [productZoneSessionCsvUpload, setProductZoneSessionCsvUpload] =
    useState("");

  const populateListsData = async (responseData = null) => {
    console.log("Event Id", appointmentData.data.event_id);
    let jobTitlesList = await getJobTitles({
      event_id: appointmentData.data.event_id,
      for_calendar: true,
    });
    let countriesList = await getCountryList({
      event_id: appointmentData.data.event_id,
      for_calendar: true,
    });
    let agentsList = await getAgentsForUser(appointmentData.data.event_id);
    let brandsList = await getBrandDropdowns(appointmentData.data.event_id);
    let usersList = await getUsers({
      event_id: appointmentData.data.event_id,
      for_calendar: true,
    });
    let zonesList = await getZoneDropdowns(appointmentData.data.event_id);

    setAgentsCollectionData(agentsList);
    setBrandsCollection(brandsList);
    setCountryCollection(countriesList);
    setJobTitleCollection(jobTitlesList);
    setZoneCollection(zonesList);

    if (responseData) {
      console.log("responseData = ", responseData);
      // let agentsListData = mapListData(
      //   agentsList,
      //   "user_id",
      //   responseData?.assign_staff_ids
      // );
      // console.log("agentsListData.blackList = ", agentsListData.blackList);
      // setAgentsList(agentsList);
      // setUsersList(usersList);
      // setBrandsList(brandsList);
      // setCountriesList(countriesList);
      // setJobTitlesList(jobTitlesList);

      let abc = await mapSelectedDdlData(
        responseData?.assign_staff_ids,
        taggedAgents,
        agentsList,
        "user_id"
      )
        .then((dataToMap) => {
          console.log("dataToMap 11 = ", dataToMap);
          if (dataToMap && dataToMap.mainList)
            setAgentsList(dataToMap.mainList);
          if (dataToMap && dataToMap.selectedList)
            setTaggedAgents(dataToMap.selectedList);
        })
        .catch((err) => {
          // console.log("countriesToMap 22 = ", err);
        });

      let userListData = mapListData(
        usersList,
        "user_id",
        responseData?.restricted_users
      );
      setWhiteListUsers(userListData.whiteList);
      setBlackListUsers(userListData.blackList);

      let brandListData = mapListData(
        brandsList,
        "brand_id",
        responseData?.restricted_brands
      );
      setBlackListBrands(brandListData.blackList);
      setBrandsCollection(brandListData.whiteList);
      setWhiteListBrands(brandListData.whiteList);

      let countriesListData = mapListData(
        countriesList,
        "country_id",
        responseData?.restricted_countries
      );
      setBlackListCountries(countriesListData.blackList);
      setCountryCollection(countriesListData.whiteList);
      setWhiteListCountries(countriesListData.whiteList);

      let jobTitlesListData = mapListData(
        jobTitlesList,
        "job_title_id",
        responseData?.restricted_job_titles
      );
      setBlackListJobTitles(jobTitlesListData.blackList);
      setJobTitleCollection(jobTitlesListData.whiteList);
      setWhiteListJobTitles(jobTitlesListData.whiteList);
    } else {
      setAgentsList(agentsList);
      setWhiteListUsers(usersList);
      setWhiteListBrands(brandsList);
      setWhiteListCountries(countriesList);
      setWhiteListJobTitles(jobTitlesList);
    }

    // console.log(usersList, brandsList, countriesList, jobTitlesList);
  };

  const getProductZoneSessionData = async (id) => {
    const response = await dispatch(
      getZoneSessionDetails({
        configuration_id: appointmentData.data.configuration_id,
      })
    );
    console.log("response in brand details", response.payload.data.data);
    if (response.payload.data.data) {
      let responseData = response.payload.data.data;
      await populateListsData(response.payload.data.data);
      let modifiedResponse = productZoneSessionFields;
      modifiedResponse.productZoneSession_title =
        responseData.session_details.session_title;
      modifiedResponse.productZoneSession_description =
        responseData.session_details.session_description;
      modifiedResponse.productZoneSession_start_date_time = moment(
        responseData.session_details.start_time,
        TIME_FORMAT
      );
      modifiedResponse.productZoneSession_end_date_time = moment(
        responseData.session_details.end_time,
        TIME_FORMAT
      );
      modifiedResponse.is_ical_download_enabled =
        responseData.session_details.is_ical_download_enabled;
      setProductZoneSessionFields({ ...modifiedResponse });
      set_previous_pending_invites(responseData.previous_pending_invites);
    }
  };

  const mapListData = (list, key, valuesList) => {
    let blackList = [],
      whiteList = [];
    list.map((x) => {
      if (!blackList.includes(x[key] || !whiteList.includes(x[key]))) {
        if (valuesList.includes(x[key])) blackList.push(x);
        else whiteList.push(x);
      }
    });
    return { blackList, whiteList };
  };

  const checkIfInviationIsSelected = () => {
    if (
      productZoneSessionFields?.productZoneSession_schedule_invitation ||
      productZoneSessionFields?.productZoneSession_schedule_now
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveZoneSessionDetails = async () => {
    if (productzonesession_validator.current.allValid()) {
      setLoading(true);
      const flag = checkIfInviationIsSelected();
      if (flag) {
        let data = {
          session_details: {
            brand_id: appointmentData.data.brand_id,
            zone_id: appointmentData.data.zone_id,
            start_date_time:
              appointmentData.data.currentDateForCalender +
              " " +
              moment(
                productZoneSessionFields.productZoneSession_start_date_time
              ).format("HH:mm:ss"),
            end_date_time:
              appointmentData.data.currentDateForCalender +
              " " +
              moment(
                productZoneSessionFields.productZoneSession_end_date_time
              ).format("HH:mm:ss"),
            session_title: productZoneSessionFields.productZoneSession_title,
            session_description:
              productZoneSessionFields.productZoneSession_description,
            is_ical_download_enabled:
              productZoneSessionFields.is_ical_download_enabled,
          },
          assign_staff_ids: _.map(taggedAgents, "user_id"),
          restricted_users: _.map(blackListUsers, "user_id"),
          restricted_brands: _.map(blackListBrands, "brand_id"),
          restricted_countries: _.map(blackListCountries, "country_id"),
          restricted_job_titles: _.map(blackListJobTitles, "job_title_id"),
          invitation_setup: {
            invitation_title:
              productZoneSessionFields.productZoneSession_invitation_title,
            invitation_description:
              productZoneSessionFields.productZoneSession_invitation_description,
            is_iCal_download_enabled:
              productZoneSessionFields.productZoneSession_invitation_is_ical_download_enabled,
            is_reinvite_enabled:
              productZoneSessionFields.productZoneSession_invitation_re_invite,
            send_invitation_now:
              !productZoneSessionFields.productZoneSession_schedule_invitation,
          },
          invited_brands: invited_brands,
          invited_zones: invited_zones,
          invited_user_types: invited_user_types,
          invited_countries: invited_countries,
          invited_job_titles: invited_job_titles,
        };

        if (productZoneSessionFields.productZoneSession_schedule_invitation)
          data.invitation_setup.date_time_to_invite =
            productZoneSessionFields.productZoneSession_when_to_send_date_time;

        if (appointmentData.data.configuration_id) {
          data["configuration_id"] = appointmentData.data.configuration_id;
        }

        console.log("data to pass = ", JSON.stringify(data));

        let requestObject = new FormData();
        requestObject.append("data", JSON.stringify(data));
        requestObject.set("invitation_list", null);
        if (productZoneSessionFileUpload) {
          requestObject.set("media", productZoneSessionFileUpload);
        } else {
          requestObject.set("media", null);
        }

        const response = await dispatch(
          data.configuration_id
            ? updateZoneSessionDetails(requestObject)
            : createZoneSession(requestObject)
        );
        appointmentData.onSave(true);

        productZoneSessionFields.productZoneSession_invitation_title = "";
        productZoneSessionFields.productZoneSession_invitation_description = "";
        productZoneSessionFields.productZoneSession_schedule_invitation = false;
        productZoneSessionFields.productZoneSession_schedule_now = false;
      } else {
        toast.error("When to Send Invitation is mandatory");
      }
      setLoading(false);
    } else {
      productzonesession_validator.current.showMessages();
      toast.error(dialogs.missingFields);
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setProductZoneSessionForceUpdate(!productZoneSessionForceUpdate);
    }
  };

  const onCancel = () => {
    appointmentData.onCancel();
  };

  const handleProductZoneSessionFieldsChange = (val, name) => {
    console.log({ ...productZoneSessionFields, [name]: val });
    setProductZoneSessionFields({ ...productZoneSessionFields, [name]: val });
  };

  function getLeftRightDDLs(leftOrRight, leftDDL, rightDDL, indexToShift) {
    if (leftOrRight === "left") {
      rightDDL.push(leftDDL[indexToShift]);
      leftDDL.splice(indexToShift, 1);
    } else {
      leftDDL.push(rightDDL[indexToShift]);
      rightDDL.splice(indexToShift, 1);
    }
    return { leftDDL, rightDDL };
  }

  async function handleDDLChange(value, type, leftOrRight) {
    let leftDDL, rightDDL, indexToShift;
    if (type === "taggedAgents") {
      leftDDL = cloneDeep(agentsList);
      rightDDL = cloneDeep(taggedAgents);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.user_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.user_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setAgentsList(finalRes.leftDDL);
      setTaggedAgents(finalRes.rightDDL);
    } else if (type === "blackListUsers") {
      leftDDL = cloneDeep(whiteListUsers);
      rightDDL = cloneDeep(blackListUsers);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.user_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.user_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setWhiteListUsers(finalRes.leftDDL);
      setBlackListUsers(finalRes.rightDDL);
    } else if (type === "blackListBrands") {
      leftDDL = cloneDeep(whiteListBrands);
      rightDDL = cloneDeep(blackListBrands);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.brand_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.brand_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setWhiteListBrands(finalRes.leftDDL);
      setBlackListBrands(finalRes.rightDDL);
    } else if (type === "blackListCountries") {
      leftDDL = cloneDeep(whiteListCountries);
      rightDDL = cloneDeep(blackListCountries);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.country_id === value);
      else indexToShift = rightDDL.findIndex((ele) => ele.country_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setWhiteListCountries(finalRes.leftDDL);
      setBlackListCountries(finalRes.rightDDL);
    } else if (type === "blackListJobTitles") {
      leftDDL = cloneDeep(whiteListJobTitles);
      rightDDL = cloneDeep(blackListJobTitles);
      if (leftOrRight === "left")
        indexToShift = leftDDL.findIndex((ele) => ele.job_title_id === value);
      else
        indexToShift = rightDDL.findIndex((ele) => ele.job_title_id === value);
      const finalRes = getLeftRightDDLs(
        leftOrRight,
        leftDDL,
        rightDDL,
        indexToShift
      );
      setWhiteListJobTitles(finalRes.leftDDL);
      setBlackListJobTitles(finalRes.rightDDL);
    }
  }

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });
    let params = {
      configuration_id: ele.configuration_id,
    };
    if (isFromSingle) params.invitation_setup_id = ele.invitation_setup_id;
    console.log("params = ", params);
    let invitationData = await getInvitationDetails(params);
    setInvitationTableData(invitationData);
  };

  useEffect(async () => {
    setLoading(true);
    // setModalInfo(Modal.info());
    let fieldsData = {
      productZoneSession_start_date_time: "",
      productZoneSession_end_date_time: "",
      productZoneSession_title: "",
      productZoneSession_description: "",
      is_ical_download_enabled: false,

      productZoneSession_specific_users: "",
      productZoneSession_brand_list: "",
      productZoneSession_country_list: "",
      productZoneSession_job_titles_list: "",

      productZoneSession_invitees_brand: "",
      productZoneSession_invitees_zone: "",
      productZoneSession_invitees_user_type: "",
      productZoneSession_invitees_country: "",
      productZoneSession_invitees_job_title: "",

      productZoneSession_prev_pending_date_time_1: "",
      productZoneSession_prev_pending_date_time_2: "",
      productZoneSession_invitation_title: "",
      productZoneSession_invitation_description: "",
      productZoneSession_invitation_is_ical_download_enabled: false,
      productZoneSession_invitation_re_invite: false,
      productZoneSession_schedule_invitation: false,
      productZoneSession_when_to_send_date_time: undefined,
    };
    if (appointmentData.data?.configuration_id)
      await getProductZoneSessionData(appointmentData.data.configuration_id);
    else {
      let modifiedResponse = fieldsData;
      await populateListsData();
      setProductZoneSessionFields({ ...modifiedResponse });
      setProductZoneSessionFileUpload("");
    }
    setLoading(false);
  }, [appointmentData]);

  if (loading) return <CommonLoader />;
  else
    return (
      <>
        <h4 className="text-center fs-pink pt-2">Product Zone - Session</h4>
        <Form className="productZoneSession_section_1_form">
          <Row className="adminSatellite_row">
            <Col span={6} className="productZoneSession_label mb-3">
              <p>
                Start Time
                <RedAsterick />
              </p>
            </Col>
            <Col span={18}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                id="productZoneSession_start_date_time"
                name="productZoneSession_start_date_time"
                format={"HH:mm"}
                className="adminSatellite_date_time w-100"
                value={
                  productZoneSessionFields.productZoneSession_start_date_time
                }
                onSelect={(val) => {
                  handleProductZoneSessionFieldsChange(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "productZoneSession_start_date_time"
                  );
                }}
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_start_date_time"
                  )
                }
              />
              {productzonesession_validator.current.message(
                "",
                productZoneSessionFields.productZoneSession_start_date_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>

          <Row className="adminSatellite_row">
            <Col span={6} className="productZoneSession_label mb-3">
              <p className="text-end">
                End Time
                <RedAsterick />
              </p>
            </Col>
            <Col span={18}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                id="productZoneSession_end_date_time"
                name="productZoneSession_end_date_time"
                disabledTime={(d) =>
                  !d ||
                  d.isSameOrBefore(
                    productZoneSessionFields.productZoneSession_start_date_time
                  )
                }
                format={"HH:mm"}
                className="adminSatellite_date_time w-100"
                value={
                  productZoneSessionFields.productZoneSession_end_date_time
                }
                onSelect={(val) =>
                  handleProductZoneSessionFieldsChange(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "productZoneSession_end_date_time"
                  )
                }
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_end_date_time"
                  )
                }
              />
              {productzonesession_validator.current.message(
                "",
                productZoneSessionFields.productZoneSession_end_date_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Title</p>
            </Col>
            <Col span={18}>
              <Input
                name="productZoneSession_title"
                className="productZoneSession_inputTitle"
                placeholder="Enter a Title here"
                value={productZoneSessionFields.productZoneSession_title}
                onChange={(e) =>
                  handleProductZoneSessionFieldsChange(
                    e.target.value,
                    "productZoneSession_title"
                  )
                }
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_title"
                  )
                }
              />
              {productzonesession_validator.current.message(
                "Title",
                productZoneSessionFields.productZoneSession_title,
                "required|min:1|max:100",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p style={{ marginTop: "9px" }}>Description</p>
            </Col>
            <Col span={18}>
              <TextArea
                name="productZoneSession_description"
                value={productZoneSessionFields.productZoneSession_description}
                className="productZoneSession_inputDescription"
                placeholder="Enter a description here"
                rows={4}
                onChange={(e) =>
                  handleProductZoneSessionFieldsChange(
                    e.target.value,
                    "productZoneSession_description"
                  )
                }
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_description"
                  )
                }
              />
              {productzonesession_validator.current.message(
                "Description",
                productZoneSessionFields.productZoneSession_description,
                "required|min:1|max:200",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>

          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Download iCal</p>
            </Col>

            <Col span={6} style={{ alignSelf: "center" }}>
              <Switch
                checked={productZoneSessionFields.is_ical_download_enabled}
                onChange={(val) =>
                  handleProductZoneSessionFieldsChange(
                    val,
                    "is_ical_download_enabled"
                  )
                }
              />
            </Col>
          </Row>
        </Form>
        <Form className="productZoneSession_assignstaffs_form">
          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                Assign Staff
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                Tagged Agents
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                Assigned
              </h6>
            </Col>
          </Row>

          <Row className="modal_row mb-3">
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}>
              <div
                id="assigned_staff"
                className="adminSatellite_section_5_taggedagents_container"
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("assigned_staff")
                  }
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  onChange={(value) =>
                    handleDDLChange(value, "taggedAgents", "left")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Agents"
                >
                  {agentsList?.map((data) => {
                    return (
                      <Option
                        key={"restricted_users_" + data.user_id}
                        value={data.user_id}
                        className="adminSatellite_section_4_taggedagents_item"
                      >
                        {data.first_name + " " + data.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={2}>
              <div
                id="selected_restricted_users_div"
                className="adminSatellite_section_5_taggedagents_container"
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder="Search Tagged Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {taggedAgents?.map((data) => {
                    return (
                      <Option
                        key={"restricted_users_selected_" + data.user_id}
                        value={data.user_id}
                        className="adminSatellite_section_4_taggedagents_item"
                      >
                        {data.first_name + " " + data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleDDLChange(
                                data.user_id,
                                "taggedAgents",
                                "right"
                              )
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={18}></Col>
          </Row>
        </Form>
        <Form className="productZoneSession_media_assets_form">
          <Row className="mb-2">
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_media_assets_title">
                Media Assets
              </h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={18}>
              <p className="productZoneSession_media_assets_info">
                NB: By default, all assets for this zone will already be
                available in your demo room playlist.
                <br /> You can either pre-upload assets here or add assets in
                the meeting room itself.
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col span={6}>
              <p
                style={{ opacity: 1 }}
                className="productZoneSession_label fs-16"
              >
                Upload Individual Assets
              </p>
            </Col>
            <Col span={4}>
              <FileUpload
                labelClassName="rounded border-pink btn-svg  px-4 py-2"
                labelTitle="File Upload"
                name="productZoneSessionFileUpload"
                inputType="file"
                inputOnChange={(e) => {
                  setProductZoneSessionFileUpload(e.target.files[0]);
                }}
                inputId="productZoneSessionFileUpload"
                inputName="productZoneSessionFileUpload"
              />
            </Col>
            <Col span={9}>
              {productZoneSessionFileUpload ? (
                <>
                  <span className="mx-3">
                    {productZoneSessionFileUpload.name}
                  </span>
                  <span
                    onClick={() => {
                      setProductZoneSessionFileUpload(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
        <Form className="productZoneSession_restrict_access_form">
          <Row>
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_restrict_access_title">
                Restrict Access
              </h5>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={24}>
              <p className="productZoneSession_restrict_access_info">
                NB: Configure who can view / access your event.
                <br /> Attendees with any black list attributes will not be able
                to to see the event on any calendars or access the event.
                <br /> If an attendee/attribute is on the White List they will
                be able to see/attaned the event regardless of any black list
                attributes.
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}>
              <h6 className="adminSatellite_userListTitle">White List Users</h6>
            </Col>

            <Col span={8} offset={2}>
              <h6 className="adminSatellite_userListTitle">Black List Users</h6>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end">
              <p className="mx-3 mt-3">User List</p>
            </Col>
            <Col span={8}>
              <div
                id="restricted_users_div"
                className="adminSatellite_section_5_taggedagents_container"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  onChange={(value) =>
                    handleDDLChange(value, "blackListUsers", "left")
                  }
                  getPopupContainer={() =>
                    document.getElementById("restricted_users_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Agents"
                >
                  {whiteListUsers?.map((data) => {
                    return (
                      <Option
                        key={"restricted_users_" + data.user_id}
                        value={data.user_id}
                        className="adminSatellite_section_4_taggedagents_item"
                      >
                        {data.first_name + " " + data.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={2}>
              <div
                id="selected_restricted_users_div"
                className="adminSatellite_section_5_taggedagents_container"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search Tagged Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selected_restricted_users_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {blackListUsers?.map((data) => {
                    return (
                      <Option
                        key={"restricted_users_selected_" + data.user_id}
                        value={data.user_id}
                        className="adminSatellite_section_4_taggedagents_item"
                      >
                        {data.first_name + " " + data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleDDLChange(
                                data.user_id,
                                "blackListUsers",
                                "right"
                              )
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end"></Col>
            <Col span={8}></Col>
            <Col span={8} offset={2}></Col>
          </Row>
          <Row>
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}>
              <h6 className="adminSatellite_userListTitle">
                White List Brands
              </h6>
            </Col>

            <Col span={8} offset={2}>
              <h6 className="adminSatellite_userListTitle">
                Black List Brands
              </h6>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end">
              <p className="mx-3 mt-3">Brand List</p>
            </Col>
            <Col span={8}>
              <div id="restricted_brands_div" className="dropdown_container">
                <Select
                  id="restricted_brands"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  onChange={(value) => {
                    handleDDLChange(value, "blackListBrands", "left");
                  }}
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("restricted_brands_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Brands"
                >
                  {whiteListBrands &&
                    whiteListBrands.map((data) => (
                      <Option
                        key={"restricted_brands_" + data.brand_id}
                        value={data.brand_id}
                        className="dropdown_options"
                      >
                        <span>{data.brand_title}</span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={2}>
              <div
                id="selected_restricted_brands_div"
                className="dropdown_container"
              >
                <Select
                  id="selected_restricted_brands"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("restricted_brands_div")
                  }
                  onBlur={() =>
                    productzonesession_validator.current.showMessageFor(
                      "selected_restricted_brands"
                    )
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Brands"
                >
                  {blackListBrands &&
                    blackListBrands.map((data) => (
                      <Option
                        key={"selected_restricted_brands_" + data.brand_id}
                        value={data.brand_id}
                        className="dropdown_options"
                      >
                        <span>
                          {data.brand_title}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleDDLChange(
                                  data.brand_id,
                                  "blackListBrands",
                                  "right"
                                )
                              }
                            />
                          </span>
                        </span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end"></Col>
            <Col span={8}></Col>
            <Col span={8} offset={2}></Col>
          </Row>
          <Row>
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}>
              <h6 className="adminSatellite_userListTitle">
                White List Countries
              </h6>
            </Col>

            <Col span={8} offset={2}>
              <h6 className="adminSatellite_userListTitle">
                Black List Countries
              </h6>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end">
              <p className="mx-3 mt-3">Country List</p>
            </Col>
            <Col span={8}>
              <div id="restricted_countries_div" className="dropdown_container">
                <Select
                  id="restricted_countries"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  onChange={(value) =>
                    handleDDLChange(value, "blackListCountries", "left")
                  }
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("restricted_countries_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Countries"
                >
                  {whiteListCountries &&
                    whiteListCountries.map((data) => (
                      <Option
                        key={"restricted_countries_" + data.country_id}
                        value={data.country_id}
                        className="dropdown_options"
                      >
                        <span>{data.country}</span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={2}>
              <div
                id="selected_restricted_countries_div"
                className="dropdown_container"
              >
                <Select
                  id="selected_restricted_countries"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selected_restricted_countries_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Countries"
                >
                  {blackListCountries &&
                    blackListCountries.map((data) => (
                      <Option
                        key={"selected_restricted_countries_" + data.country_id}
                        value={data.country_id}
                        className="dropdown_options"
                      >
                        <span>
                          {data.country}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleDDLChange(
                                  data.country_id,
                                  "blackListCountries",
                                  "right"
                                )
                              }
                            />
                          </span>
                        </span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}></Col>

            <Col span={8} offset={2}></Col>
          </Row>
          <Row>
            <Col span={6} className="modal_field_label"></Col>
            <Col span={8}>
              <h6 className="adminSatellite_userListTitle">
                White List Job Titles
              </h6>
            </Col>

            <Col span={8} offset={2}>
              <h6 className="adminSatellite_userListTitle">
                Black List Job Titles
              </h6>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="text-end">
              <p className="mx-3 mt-3">Job Titles List</p>
            </Col>
            <Col span={8}>
              <div
                id="restricted_job_titles_div"
                className="dropdown_container"
              >
                <Select
                  id="restricted_job_titles"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  onChange={(value) =>
                    handleDDLChange(value, "blackListJobTitles", "left")
                  }
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("restricted_job_titles_div")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Job Titles"
                >
                  {whiteListJobTitles &&
                    whiteListJobTitles.map((data) => (
                      <Option
                        key={"restricted_job_titles_" + data.job_title_id}
                        value={data.job_title_id}
                        className="dropdown_options"
                      >
                        <span>{data.job_title}</span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={2}>
              <div
                id="selected_restricted_job_titles_div"
                className="dropdown_container"
              >
                <Select
                  id="selected_restricted_job_titles"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Search to Select"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById(
                      "selected_restricted_job_titles_div"
                    )
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="All Job Titles"
                >
                  {blackListJobTitles &&
                    blackListJobTitles.map((data) => (
                      <Option
                        key={
                          "selected_restricted_job_titles_" + data.job_title_id
                        }
                        value={data.job_title_id}
                        className="dropdown_options"
                      >
                        <span>
                          {data.job_title}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleDDLChange(
                                  data.job_title_id,
                                  "blackListJobTitles",
                                  "right"
                                )
                              }
                            />
                          </span>
                        </span>
                      </Option>
                    ))}
                </Select>
              </div>
            </Col>
          </Row>
        </Form>

        <div className="adminNetworking_section_4_form">
          <Row>
            <Col span={24}>
              <h5 className="adminNetworking_section_4_title">
                Previous & Pending Invitees
              </h5>
            </Col>
          </Row>
          {appointmentData.data && appointmentData.data.configuration_id ? (
            <>
              {previous_pending_invites ? (
                <Row className="modal_row">
                  <Col span={6} />
                  <Col span={9} className="adminSatellite_eyeViewBtn">
                    <Button
                      type="primary"
                      className="adminSatellite_section_8_previousInvitedBtn"
                      size={"large"}
                      onClick={() =>
                        openInviteesModalAndApiCall(
                          previous_pending_invites[0],
                          false
                        )
                      }
                    >
                      View List of Previously Invited
                    </Button>
                  </Col>
                </Row>
              ) : null}

              <Row className="modal_row">
                <Col span={6} />
                <Col span={9}>
                  <h6 className="adminSatellite_section_8_heading">
                    Currently Scheduled Invitations
                  </h6>
                </Col>
              </Row>
              {previous_pending_invites ? (
                previous_pending_invites.map((ele, index) => {
                  if (ele && !ele.is_delete)
                    return (
                      <Row
                        className="modal_row"
                        key={"pending_inv_" + ele.date_time_to_invite}
                      >
                        <Col span={6} className="modal_field_label">
                          Date & Time
                        </Col>
                        <Col span={7} className="invite_center">
                          {ele.date_time_to_invite
                            ? moment(ele.date_time_to_invite).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : null}
                        </Col>
                        <Col span={9} offset={2}>
                          <Button
                            type="primary"
                            className="adminSatellite_section_8_viewListBtn"
                            size={"large"}
                            onClick={async () =>
                              openInviteesModalAndApiCall(ele, true)
                            }
                          >
                            View List
                          </Button>
                          <img
                            style={{ marginLeft: "15px" }}
                            src={blackbin}
                            onClick={() => {
                              let clonedDeleteIds = _.cloneDeep(
                                delete_scheduled_invitations
                              );
                              clonedDeleteIds.push(
                                ele.networking_lounge_invitation_setup_id
                              );
                              set_delete_scheduled_invitations(clonedDeleteIds);

                              let clonedData = _.cloneDeep(
                                previous_pending_invites
                              );
                              clonedData[index].is_delete = true;
                              set_previous_pending_invites(clonedData);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                })
              ) : (
                <Empty description={"No Scheduled Invitations"} />
              )}
            </>
          ) : (
            <Empty description="No invitees" />
          )}
        </div>
        <Modal
          title={<div className="invite_center">{"Previously Invited"}</div>}
          visible={invitationModal.isOpen}
          width={650}
          onOk={() =>
            setInvitationModal({ isOpen: false, data: null, isLoader: false })
          }
          onCancel={() =>
            setInvitationModal({ isOpen: false, data: null, isLoader: false })
          }
          footer={null}
        >
          <div className="px-3">
            <Table
              columns={invitation_table_columns}
              pagination={false}
              dataSource={invitationTableData}
              bordered={false}
              //   rowClassName={"brand_row"}
              scroll={{ y: 240 }}
              rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
            />
          </div>
        </Modal>
        <div
          className="adminNetworking_section_5_form"
          style={{ borderBottom: "1px solid #eae4f2" }}
        >
          <Row>
            <Col span={24}>
              <h5 className="adminNetworking_section_5_title fs-pink">
                New Invitees
              </h5>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={9}>
              <h6 className="adminNetworking_section_5_heading">
                Filter Attendees
              </h6>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>Brand</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                mode="multiple"
                className="w-100"
                placeholder="Select Brands"
                value={invited_brands}
                onChange={(val) => set_invited_brands(val)}
              >
                {brandsCollection &&
                  brandsCollection.map((item, i) => {
                    return (
                      <Option
                        key={"invited_brands_" + item.brand_id}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>Zone</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                className="w-100"
                mode="multiple"
                placeholder="Select Zones"
                value={invited_zones}
                onChange={(val) => set_invited_zones(val)}
              >
                {zoneCollection &&
                  zoneCollection.map((item, i) => {
                    return (
                      <Option
                        key={"invited_zones" + item.zone_id}
                        value={item.zone_id}
                      >
                        {item.zone_title}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>User Type</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                className="w-100"
                mode="multiple"
                placeholder="Select User Types"
                value={invited_user_types}
                onChange={(val) => set_invited_user_types(val)}
              >
                <Option value="AGENT">Agent</Option>
                <Option value="DELEGATE">Delegate</Option>
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>Country</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                className="w-100"
                mode="multiple"
                placeholder="Select Countries"
                value={invited_countries}
                onChange={(val) => set_invited_countries(val)}
              >
                {countryCollection &&
                  countryCollection.map((item, i) => {
                    return (
                      <Option
                        key={"invited_countries" + item.country_id}
                        value={item.country_id}
                      >
                        {item.country}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>Job Title</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                className="w-100"
                mode="multiple"
                placeholder="Select Job Titles"
                value={invited_job_titles}
                onChange={(val) => set_invited_job_titles(val)}
              >
                {jobTitleCollection &&
                  jobTitleCollection.map((item, i) => {
                    return (
                      <Option
                        key={"invited_job_titles" + item.job_title_id}
                        value={item.job_title_id}
                      >
                        <span>{item.job_title}</span>
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
        </div>
        <div className="adminSatellite_section_10_form">
          <Row>
            <Col span={24}>
              <h5 className="section_title section_theme_title fs-pink">
                Invitation Setup
              </h5>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p>
                Title
                <RedAsterick />
              </p>
            </Col>
            <Col span={18}>
              <Input
                name="invitation_title"
                className="adminSatellite_inputTitle"
                placeholder="Enter a Title here"
                value={
                  productZoneSessionFields.productZoneSession_invitation_title
                }
                onChange={(e) =>
                  handleProductZoneSessionFieldsChange(
                    e.target.value,
                    "productZoneSession_invitation_title"
                  )
                }
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_invitation_title"
                  )
                }
                minLength={1}
                maxLength={100}
              />
              {productzonesession_validator.current.message(
                "Title",
                productZoneSessionFields.productZoneSession_invitation_title,
                "required|regex:^[A-Za-z0-9_-]*$",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p style={{ alignSelf: "flex-start" }}>
                Description
                <RedAsterick />
              </p>
            </Col>
            <Col span={18}>
              <TextArea
                name="invitation_description"
                className="adminSatellite_inputDescription"
                placeholder="Enter a description here"
                rows={4}
                value={
                  productZoneSessionFields.productZoneSession_invitation_description
                }
                onChange={(e) =>
                  handleProductZoneSessionFieldsChange(
                    e.target.value,
                    "productZoneSession_invitation_description"
                  )
                }
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSession_invitation_description"
                  )
                }
                minLength={0}
                maxLength={200}
              />
              {productzonesession_validator.current.message(
                "Invitation Description",
                productZoneSessionFields.productZoneSession_invitation_description,
                "required|regex:^[A-Za-z0-9_-]*$",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p className="fs-14">Attach iCal</p>
            </Col>
            <Col span={18}>
              <Switch
                defaultChecked
                value={
                  productZoneSessionFields.productZoneSession_invitation_is_ical_download_enabled
                }
              />
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="modal_field_label">
              <p className="fs-14">Re-invite</p>
            </Col>
            <Col span={18}>
              <Switch
                defaultChecked
                value={
                  productZoneSessionFields.productZoneSession_invitation_re_invite
                }
              />
              <span style={{ marginLeft: "30px" }}>(if already invited)</span>
            </Col>
          </Row>

          <Form className="productZoneSession_whentosend_form">
            <Row className="productZoneSession_row">
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_whentosend_title">
                  When to Send Invitation
                </h5>
              </Col>
            </Row>

            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Scheduled</p>
              </Col>
              <Col span={18}>
                <Switch
                  checked={
                    productZoneSessionFields?.productZoneSession_schedule_invitation
                  }
                  onClick={(val) => {
                    setProductZoneSessionFields({
                      ...productZoneSessionFields,
                      productZoneSession_schedule_invitation: val,
                      productZoneSession_schedule_now: !val,
                    });
                  }}
                />
              </Col>
            </Row>

            {productZoneSessionFields.productZoneSession_schedule_invitation ? (
              <Row>
                <Col span={6} className="productZoneSession_label">
                  <p>Date & Time</p>
                </Col>
                <Col span={7}>
                  <DatePicker
                    suffixIcon={<CalenderGrey width="15" />}
                    name="productZoneSession_when_to_send_date_time"
                    showTime
                    className="productZoneSession_date_time"
                    disabledDate={(d) => !d || d.isSameOrBefore(date)}
                    disabledTime={(d) => !d || d.isSameOrBefore(date)}
                    value={moment(
                      productZoneSessionFields.productZoneSession_when_to_send_date_time
                    )}
                    format={"DD/MM/YYYY HH:mm"}
                    onChange={(val) =>
                      handleProductZoneSessionFieldsChange(
                        val,
                        "productZoneSession_when_to_send_date_time"
                      )
                    }
                    onBlur={() =>
                      productzonesession_validator.current.showMessageFor(
                        "productZoneSession_when_to_send_date_time"
                      )
                    }
                  />
                  {productzonesession_validator.current.message(
                    "",
                    productZoneSessionFields.productZoneSession_when_to_send_date_time,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col span={6} className="productZoneSession_label"></Col>
              <Col span={7}>
                <span className="fs-pink productZoneSession_or">OR</span>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Now</p>
              </Col>
              <Col span={18}>
                <Switch
                  // disabled
                  checked={
                    productZoneSessionFields?.productZoneSession_schedule_now
                  }
                  onClick={(val) => {
                    setProductZoneSessionFields({
                      ...productZoneSessionFields,
                      productZoneSession_schedule_invitation: !val,
                      productZoneSession_schedule_now: val,
                    });
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="d-flex justify-content-end p-4 ">
          <Button
            className="productZoneSession_cancelBtn ms-4"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            className="productZoneSession_saveBtn ms-4"
            onClick={() => saveZoneSessionDetails()}
          >
            Save Changes & Send Invitations
          </Button>
        </div>
        {/* </Modal> */}
      </>
    );
};
export default ProductZoneSession;
