import React from "react";
import { Input } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

const FileUpload = (props) => {
  return (
    <div className="fileupload_all fileupld_height">
      <label htmlFor={props.inputId} className="rounded  btn-svg px-3 py-1">
        <span className="d-flex align-items-center" style={props.spanStyle}>
          <CloudUploadOutlined className="fs-20" />
          <span className="ms-1">{props.labelTitle}</span>
        </span>
      </label>

      <Input
        type={props.inputType}
        value={props.value}
        name={props.inputName}
        id={props.inputId}
        onChange={props.inputOnChange}
        onBlur={props.onBlur}
        style={{ display: "none" }}
        accept={props.accept ? ".xlsx" : ""}
        // onClick= { props.inputOnChange}
        ref={props.ref}
      />
    </div>
  );
};

export default FileUpload;
