import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AppContext from "./contexts/AppContext";
import VistaContext from "./contexts/VistaContext";
import GlobalStyles from "./globalStyles";

// Import environment configurations
import { config } from "dotenv";

// Load environment variables
config();

class VistaApp extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<React.StrictMode>
				<GlobalStyles />
				<AppContext.Provider>
					<VistaContext.Provider>
						<BrowserRouter>
							<App props={this.props} />
						</BrowserRouter>
					</VistaContext.Provider>
				</AppContext.Provider>
			</React.StrictMode>
		);
	}
}

export default VistaApp;
