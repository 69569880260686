import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const getZoneSessionDetails = createAsyncThunk(
  "productZoneSession/getZoneSessionDetails",

  async (params) => {
    const response = await ApiCall("GET", "", "/admin/productzonesession/details/get", params);
    return response;
  }
);

export const updateZoneSessionDetails = createAsyncThunk(
  "productzonesession/update",

  async (params) => {
    console.log("params", params);
    const response = await ApiCallForm(
      "PUT",
      params,
      "/admin/productzonesession/update",
      "",
      ""
    );

    return response.payload.data;
  }
);

export const createZoneSession = createAsyncThunk(
  "productzonesession/create",

  async (params) => {
    console.log("params", params);
    const response = await ApiCallForm(
      "POST",
      params,
      "/admin/productzonesession/create",
      "",
      ""
    );

    return response.payload.data;
  }
);

export const getInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/productzonesessioninvitation/details/get",
    params
  );
  // console.log("response getNetworkInvitationDetails = ", response);
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

