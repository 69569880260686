import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const getProfileDetails = createAsyncThunk(
  "profile/getProfile",
  async (params) => {
    console.log("params params", params);
    const response = await ApiCall("GET", "", "/admin/profile/get", params);
    console.log("profileResp", response);

    return response.data.data;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateprofile",

  async (body) => {
    console.log(body);
    const response = await ApiCall("PUT", body, "/admin/profile/update");
    console.log("profile slice response", response);
    return response.data;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    profileData: [],
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getProfileDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getProfileDetails.fulfilled]: (state, { payload, meta }) => {
      state.profileData = payload;
      state.loading = false;
    },
    [getProfileDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const profileReducer = profileSlice.reducer;
