import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  updateTierEventList: {},
  loading: false,
};

export const updateTierEvent = createAsyncThunk(
  "updateTierEvent/updateTierEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/tiers/update",
      "",
      ""
    );
    console.log("create response updateTierEvent", response);

    return response;
  }
);

export const updateTierEventSlice = createSlice({
  name: "updateTierEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [updateTierEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateTierEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.updateTierEventList = payload;
    },
    [updateTierEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const updateTierEventReducer = updateTierEventSlice.reducer;
