import React, { useState, useEffect, useRef } from "react";
import "../eventCalender.css";
import { Modal, Button, Input, Row, Col } from "antd";
import { getAudiSessionForView } from "../../../utils/commonApis";
import { BoothRed } from "../../../components/svgJS/BoothRed";
import { Chat } from "../../../components/svgJS/Chat";
import { Video } from "../../../components/svgJS/Video";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../../utils/commonApis";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../../components/constsnts/common.constants";
import { videoRequestAPI } from "../../../utils/commonApis";
// import { sendMessageAPI } from "../../../utils/commonApis";

const AudiView = ({ onCloseModal, sessionData, onSuccess }) => {

  const { TextArea } = Input;
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });

  const messageValidator = useRef(new SimpleReactValidator());

  const [isAuditoriumEventModalVisible, setAuditoriumEventModalVisible] = useState(true);
  const [audiLocalData, setAudiLocalData] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false)

  useEffect(async () => {
    if (sessionData) {
      let sessionAPIData = await getAudiSessionForView({
        auditorium_configuration_id: sessionData?.auditorium_configuration_id,
        auditorium_session_id: sessionData?.auditorium_session_id,
      });
      setAudiLocalData(sessionAPIData);
    }
  }, []);

  useEffect(() => {
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, [])

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const speakerData =
    audiLocalData &&
    audiLocalData?.auditorium_hosting_brands?.map((data) => {
      return data?.auditorium_hosting_speakers;
    });

  const speakerArray = [];

  speakerData?.forEach((element, index) => {
    element.forEach((data, i) => {
      speakerArray.push(data.user);
    });
  });

  console.log("speakerArray", speakerArray)

  return (
    <>
      {/* Start of audtorium event Modal */}
      <Modal
        title=""
        visible={isAuditoriumEventModalVisible}
        onOk={() => onCloseModal(false)}
        onCancel={() => onCloseModal(false)}
        className=""
        width={650}
        footer={null}
      >
        <div className=" w-100 p-4">
          <h6 className="opacity-75">Title :</h6>
          <h5>{audiLocalData?.session_title ?? "NA"}</h5>
          <h6 className="opacity-75">Time :</h6>
          <p>
            {audiLocalData?.start_time ?? "NA"} -
            {audiLocalData?.end_time ?? "NA"}
          </p>
          <h6 className="opacity-75">Description :</h6>
          <p>{audiLocalData?.session_description ?? "NA"}</p>
          {audiLocalData?.is_iCal_enabled == true ?
            <div className="d-flex mb-3">
              <Button className="audiEvent_downloadBtn">
                <span className="">Download iCal </span>
              </Button>
            </div>
            :
            ""
          }
          <h5>Speaker(s)</h5>
          {speakerArray &&
            speakerArray?.map((data) => {
              return (
                <>
                  <div className="d-flex p-3 mx-4 mb-2 my-2">
                    <img
                      width={120}
                      height={120}
                      src={
                        data?.image_url
                          ? data?.image_url
                          : "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                      }
                    />

                    <div className="ms-3">
                      <span className="opacity-75">Speaker</span>
                      <h6>
                        {data?.first_name} {data?.last_name}
                      </h6>
                      <span>Bio</span>
                      <p className="text-dark">{data?.bio ?? "NA"}</p>
                    </div>
                  </div>
                </>
              );
            })}

          {
            speakerArray.length == 0 ? <p>NA</p> : ""
          }
          <h5 className="my-3">Brand</h5>
          <div className=" p-3  audiEvent_brand  mx-4">
            {audiLocalData?.auditorium_hosting_brands?.map((data) => {
              return (
                <div className="w-100 d-flex my-1  border-bottom py-3 px-2">
                  <img
                    width={120}
                    height={120}
                    className="border border-grey"
                    src={
                      data?.logo_url
                        ? data?.logo_url
                        : "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                    }
                  />
                  <div className="ms-3">
                    <h6>{data?.brand_title}</h6>
                    <p>Platinum</p>

                    {data?.is_booth_link_enabled ? (
                      <>
                        <BoothRed />
                        <span className="fs-pink" style={{ marginLeft: "5px" }}>
                          Visit Booth{" "}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="d-flex mt-3">
                      {data?.is_chat_enabled ? (
                        <div className="border p-1 mx-1"
                          onClick={() => {
                            let channel_id = data
                              ? data.channel_id
                              : data.channel_id;
                            setMessageModal({
                              isOpen: true,
                              channel_id: data
                                ? data.channel_id
                                : data.channel_id,
                            });
                          }}>
                          <Chat />
                        </div>
                      ) : (
                        ""
                      )}

                      {messageModal.isOpen ? (
                        <Modal
                          title={<b>Send Message</b>}
                          visible={messageModal.isOpen}
                          onOk={() => clearMessageAndModal()}
                          onCancel={() => clearMessageAndModal()}
                          footer={[
                            <Button
                              className="px-3 button-pink rounded"
                              key="cancel"
                              onClick={() => clearMessageAndModal()}
                            >
                              Cancel
                            </Button>,
                            <Button
                              className="px-3 rounded"
                              key="save"
                              type="primary"
                              onClick={() => callSendMessageAPI(messageModal.channel_id)}
                            >
                              Send
                            </Button>,
                          ]}
                          className="message_modal"
                        >
                          <Row className="ticker_configuration_row mt-2">
                            <Col span={6} className="ticker_configuration_label">
                              <p>
                                Message
                                <RedAsterick />
                              </p>
                            </Col>
                            <Col span={12}>
                              <TextArea
                                name="message_text"
                                id="message_text"
                                className="ticker_configuration_inputDescription"
                                placeholder="Enter a message"
                                rows={4}
                                value={message_text}
                                onChange={(e) => set_message_text(e.target.value)}
                                onBlur={() =>
                                  messageValidator.current.showMessageFor("message_text")
                                }
                              />
                              {messageValidator.current.message(
                                "Message",
                                message_text,
                                "required",
                                { className: "text-danger py-1" }
                              )}
                            </Col>
                          </Row>
                        </Modal>
                      ) : null}
                      {data?.is_video_call_enabled ? (
                        <div className="border mx-1 p-1" onClick={() => window.open(data?.zone_demo_room_link, "_blank").focus()}>
                          <Video />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="border mx-1 p-1 bg-white"
                        onClick={async () => {
                          // let channel_id = 4;
                          const response = await videoRequestAPI(data.channel_id);
                          if (response.data.code === 200)
                            toast.success(response.data.message);
                          else toast.error(response.data.message);
                        }}
                      // className="ms-2 mb-2"
                      >
                        <Video />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-100 d-flex justify-content-end mt-4 mb-2">
            <div className="w-50"></div>
            {/* <Button className="audiEvent_deleteBtn fs-16">Delete Event</Button> */}
            <Button
              className="audiEvent_joinBtn mx-4 fs-16"
              onClick={() =>
                window.open(audiLocalData?.join_session_url, "_blank").focus()
              }
            >
              Join Session
            </Button>
          </div>
        </div>
      </Modal>
      {/* End of  auditorium event mOdal */}
    </>
  );
};

export default AudiView;
