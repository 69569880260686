import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  DatePicker,
  TimePicker,
  Collapse,
  Upload,
  List,
  Modal,
} from "antd";
import "./Zone.css";
import {
  MinusOutlined,
  PlusOutlined,
  CalendarOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  cloudComputing,
  cube,
  cubeWhite,
  edit,
  settings,
  bin,
  editRed,
  calendarRed,
  addGroupRed,
} from "../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import { AddGroup } from "../../components/svgJS/AddGroup";
import { CalenderGrey, CalenderRed } from "../../components/svgJS/CalenderRed";
import { EditRed } from "../../components/svgJS/EditRed";
import { Settings } from "../../components/svgJS/Settings";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";
import { ImgUpload } from "../../components/svgJS/ImgUpload";
import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEventShow,
  editZone,
} from "../../redux/slice/zoneManagementSlice";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import FileUpload from "../common/FileUpload";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { cloneDeep, isEmpty } from "lodash";
import AddNewSurvey from "../surveyManagement/AddNewSurvey";
import InviteAttend from "../UserManagement/InviteAttend";
import CommonLoader from "../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import { STATIC_URLS } from "../../components/constsnts/common.constants";
import AddUser from "../UserManagement/AddUser";

const EditZone = (props) => {
  console.log(props);
  var CryptoJS = require("crypto-js");
  const validator = useRef(new SimpleReactValidator());

  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [surveyList, setsurveyList] = useState(null);

  const [allAgentsList, setAllAgentsList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [zoneHeroImg, setzoneHeroImg] = useState(null);
  const [assetFile, setassetFile] = useState(null);
  const [fetchedZoneData, setfetchedZoneData] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [socialMediaList, setSocialMediaList] = useState([]);

  const [socialMediaUrl, setSocialMediaUrl] = useState([]);
  const [userType, setUserType] = useState();
  const [viewVisible, setViewVisible] = useState(false);
  const [assetDetail, setAssetDetail] = useState();

  const [assetsArray, setassetsArray] = useState([
    // {
    //   asset_file_type: "image",
    //   asset_name: "Asset 1",
    //   asset_description:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    //   is_active: true,
    //   is_downloadable: true,
    //   max_no_of_downloads: 3,
    //   total_downloads: 6,
    //   whitelisted_domains: "",
    //   blacklisted_domains: "",
    //   view_count: 9,
    // },
  ]);
  const [updateAssetValue, setupdateAssetValue] = useState(null);
  console.log(assetsArray);
  console.log("updateAssetValue", updateAssetValue);

  const [eventList, seteventList] = useState(null);
  const [brandList, setbrandList] = useState(null);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [boothLocationList, setboothLocation] = useState(null);
  let zone_id = props?.match?.params?.zoneid;

  const [chatBotDetails, setchatBotDetails] = useState([
    // {
    //   start_time: "",
    //   end_time: "",
    //   start_date: "",
    //   end_date: "",
    // },
  ]);

  const [prevEventId, setprevEventId] = useState(null);
  const [prevChatBot, setprevChatBot] = useState(null);

  const [chatBotFileUpload, setChatBotFileUpload] = useState(null);

  console.log("hiii", chatBotDetails);
  const [initial_state, setinitial_state] = useState({
    zone_id: parseInt(props?.match.params.zoneid),
    event_id: null,
    brand_id: null,
    booth_location_id: null,
    is_active: true,
    zone_title: null,
    zone_description: null,
    website_url: null,
    is_text_chat_enable: null,
    is_message_board_enable: null,
    is_moderated: null,
    is_one_to_one_video_calls_enable: null,
    survey_id: null,
    is_on_booth_leads_enable: null,
    is_video_rep_enable: null,
    is_video_demo_room_enable: null,
    is_text_bot_enable: null,
    is_individual: null,
    zones_agents: [],
    zone_chat_bot_setting_details: null,
    zones_social_media_handles_details: [],
    zones_assets_details: [],
    zone_hero_img: null,
    chatbotUpload: null,
  });
  // console.log(chatBotDetails);
  console.log(fetchedZoneData);

  const [forceUpdate, setForceUpdate] = useState(false);

  const { loading } = useSelector((state) => state.zoneManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { eventShow } = useSelector((state) => state.zoneManagement);

  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [isInviteAgentModalVisible, setIsInviteAgentModalVisible] =
    useState(false);

  const showSurveyModal = () => {
    setIsSurveyModalVisible(true);
  };

  const dispatch = useDispatch();
  useEffect(async () => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    }

    getEventsList();
    getBrandsList();
    getBoothLocation();

    getSurveyList();
    getSocialMediaList();
    await getZoneDetail();

    setTimeout(() => {
      setZoneLoading(false);
    }, 2000);
  }, []);
  const [brand_id, setBrand_id] = useState(null);

  useEffect(() => {
    //Restriced Tier Logic
    if (brandList && initial_state.brand_id) {
      brandList.forEach((elem) => {
        if (elem.brand_id === initial_state.brand_id) {
          callRestrictedAPI(elem?.brand_tier);
        }
      });
    }
    ApiCall("get", null, "/admin/boothlocations/get", { brand_id: brand_id })
      .then((response) => {
        setboothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [brandList, initial_state.brand_id, brand_id]);

  // useEffect(async () => {
  //   console.log("eveennntt");
  //   if(prevEventId)
  //   let tempArr = await eventShow.map((elem) => ({
  //     start_time: "",
  //     end_time: "",
  //     start_date: moment(elem.start_date).format("DD-MM-YYYY"),
  //     end_date: moment(elem.end_date).format("DD-MM-YYYY"),
  //   }));
  //   setchatBotDetails(tempArr);
  // }, [eventShow, initial_state.event_id]);

  useEffect(() => {
    if (initial_state.event_id) {
      getAgentsList();
    }
  }, [initial_state.event_id]);

  function handleAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
    console.log("selectedAgents list12", selectedAgents);
  }

  function handleSelectedAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);

    // console.log("selectedAgents list34", selectedAgents);
  }

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        seteventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getBrandsList = (val) => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: val ? val : initial_state?.event_id,
    })
      .then((response) => {
        const { rows } = response.data.data;
        setbrandList(rows);
        // setbrandList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBoothLocation = () => {
    ApiCall("get", null, "/admin/boothlocations/get", null)
      .then((response) => {
        setboothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getZoneDetail = async () => {
    await ApiCall("get", null, "/admin/zones/details/get", {
      zone_id: props?.match.params.zoneid,
    }).then((response) => {
      const { details } = response?.data?.data;

      setfetchedZoneData(details);

      // let chatbotTemp = details?.zones_chat_bot_setting_id.map((elem) => {
      //   return {
      //     chat_bot_settings_id: elem.chat_bot_settings_id,
      //     start_date: moment(elem.start_date_time).format("YYYY-MM-DD"),
      //     start_time:elem.start_time,
      //     end_date: moment(elem.end_date_time).format("YYYY-MM-DD"),
      //     end_time: elem.end_time,
      //   };
      // });
      // setchatBotDetails(chatbotTemp);
      // addChatbot(details?.event_id);
      setassetsArray(details.zones_assets_id);
      // setzoneHeroImg(details.zone_hero_image_url)

      let selected_agents = [];

      details?.zones_agents?.map((val, idx) => {
        console.log(val);
        selected_agents.push(val?.zonesAgents_user_id?.user_id);
      });
      console.log(selected_agents);
      let zones_social_media_handles_details = [];

      details?.zones_social_media_handles_id?.map((val, idx) => {
        zones_social_media_handles_details.push({
          zone_social_id: val.zone_social_id,
          social_media_id: val.social_media_id,
          social_media_url: val.social_media_url,
        });
      });

      const tt =
        details?.zones_chat_bot_setting_id &&
        details?.zones_chat_bot_setting_id.map((elem) => {
          return {
            chat_bot_settings_id: elem.chat_bot_settings_id,
            start_date: moment(elem.start_date_time).format("YYYY-MM-DD"),
            start_time: elem.start_time,
            end_date: moment(elem.end_date_time).format("YYYY-MM-DD"),
            end_time: elem.end_time,
          };
        });
      console.log(tt);
      setchatBotDetails(tt);
      setprevChatBot(tt);
      setprevEventId(details?.event_id);

      setinitial_state({
        // zone_id: match.params.zoneid,
        ...initial_state,
        event_id: details?.event_id,
        brand_id: details?.brand_id,
        booth_location_id: details?.booth_location_id,
        is_active: details?.is_active,
        zone_title: details?.zone_title,
        zone_description: details?.zone_description,
        website_url: details?.website_url,
        is_text_chat_enable: details?.is_text_chat_enable,
        is_message_board_enable: details?.is_message_board_enable,
        is_moderated: details?.is_moderated,
        is_one_to_one_video_calls_enable:
          details?.is_one_to_one_video_calls_enable,
        survey_id: details?.survey_id,
        is_on_booth_leads_enable: details?.is_on_booth_leads_enable,
        is_video_rep_enable: details?.is_video_rep_enable,
        is_video_demo_room_enable: details?.is_video_demo_room_enable,
        is_text_bot_enable: details?.is_text_bot_enable,
        is_individual: details?.is_individual,
        zones_agents: selected_agents,
        zone_chat_bot_setting_details: details?.zones_chat_bot_setting_id,
        zones_social_media_handles_details: zones_social_media_handles_details,
        zones_assets_details: assetsArray,
        hero_img_url: details?.zone_hero_image_url,
      });
      setBrand_id(details?.brand_id);

      // setsurveyList(response?.data?.data?.rows);
    });
  };
  console.log(initial_state.zones_agents);

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  // useEffect(() => {
  //   console.log("initial_state", initial_state);
  //   const tt =
  //     initial_state.zone_chat_bot_setting_details &&
  //     initial_state.zone_chat_bot_setting_details.map((elem) => {
  //       return {
  //         chat_bot_settings_id: elem.chat_bot_settings_id,
  //         start_date: moment(elem.start_date_time).format("YYYY-MM-DD"),
  //         start_time: elem.start_time,
  //         end_date: moment(elem.end_date_time).format("YYYY-MM-DD"),
  //         end_time: elem.end_time,
  //       };
  //     });
  //   console.log(tt);
  //   setchatBotDetails(tt);
  // }, [initial_state]);

  const getAgentsList = () => {
    ApiCall("get", "", "/admin/usertypeagents/get", {
      event_id: initial_state?.event_id ? initial_state.event_id : null,
    })
      .then((response) => {
        console.log("Agents Respsssss", response);
        setAgents(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSurveyList = () => {
    ApiCall("get", null, "/admin/survey/get", null)
      .then((response) => {
        setsurveyList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function onChange(value, dateString) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }

  // const onStartTimeChange = (time) => {
  //   setStartTimeValue(time);
  // };
  // const onEndTimeChange = (time) => {
  //   setEndTimeValue(time);
  // };

  const handleHeroImgChange = (event) => {
    // console.log("img upload console", event.target.files[0]);

    // setzoneHeroImg(event.target.files[0]);
    setzoneHeroImg(URL.createObjectURL(event.target.files[0]));
    setinitial_state({ ...initial_state, hero_img_url: event.target.files[0] });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  let [bool, setBool] = useState(false);

  useEffect(() => {
    console.log("agent-object");
    if (allAgentsList && !bool) {
      initial_state.zones_agents.map((val, idx) => {
        if (agents.some((agent) => agent.user_id === val)) {
          handleAgentsChange(val);
          setBool(true);
        }
      });
    }
  }, [allAgentsList, initial_state.zones_agents, agents]);

  const handleEditZone = async (data) => {
    // console.log(data);
    let data_temp = data;
    console.log(chatBotDetails);
    const chatbot = chatBotDetails.map((elem, index) => {
      // chat_bot_settings_id: elem.chat_bot_settings_id,
      // start_date_time:moment(elem.start_date_time).format("YYYY-MM-DD") + " " + moment(elem.start_date_time).format("h:mm"),
      // end_date_time: moment(elem.end_date_time).format("YYYY-MM-DD") + " " + moment(elem.end_date_time).format("h:mm"),
      if (data.event_id === initial_state.event_id) {
        return {
          chat_bot_settings_id: elem.chat_bot_settings_id,
          start_date_time:
            elem.start_date +
            " " +
            moment(
              initial_state?.zone_chat_bot_setting_details?.start_time
            ).format("HH:mm"),
          end_date_time:
            elem.end_date +
            " " +
            moment(
              initial_state?.zone_chat_bot_setting_details?.end_time
            ).format("HH:mm"),
        };
      } else {
        return {
          chat_bot_settings_id: elem.chat_bot_settings_id,
          start_date_time:
            elem.start_date + " " + moment(elem.start_time).format("HH:mm:ss"),
          end_date_time:
            elem.end_date + " " + moment(elem.end_time).format("HH:mm:ss"),
        };
      }
    });
    console.log(chatbot);

    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);

    data_temp.zone_chat_bot_setting_details = chatbot;
    data_temp.zones_agents = agentsIdArr;
    data_temp.zones_social_media_handles_details =
      initial_state.zones_social_media_handles_details;

    console.log("Final Data ->", data_temp);

    let formData = new FormData();
    formData.append("data", JSON.stringify(data_temp));
    formData.append("zone_hero_image_file", initial_state?.hero_img_url);
    formData.append("chat_bot_qa", chatBotFileUpload);
    // console.log(formData);
    assetsArray.forEach((elem, index) => {
      if (elem.uploadObj) {
        let number = 1;
        formData.append(`asset_${number}`, elem.uploadObj);
        number++;
      }
    });

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const res = await dispatch(editZone(formData));
    console.log(res);
    if (res?.payload?.code === 200) {
      console.log("200 success");
      toast.success(res?.payload?.message);
      props.history.push("/zonemanagement");
    }
    if (res?.payload?.code != 200) {
      toast.error(res?.payload?.message);
    }
  };

  const addChatbot = async (eventId) => {
    await dispatch(fetchEventShow({ event_id: eventId }));

    const response = await ApiCall("GET", "", "/admin/events/shows/get", {
      event_id: eventId,
    });
    // console.log(response)

    console.log("prevEventid", prevEventId);
    console.log("event", eventId);
    if (prevEventId != eventId) {
      console.log("chatbot sss", eventShow);
      let tempArr = response?.data?.data.map((elem) => {
        console.log("addchatbooot", elem);
        return {
          start_date: moment(elem.start_date_time).format("YYYY-MM-DD"),
          start_time: elem.start_time,
          end_date: moment(elem.end_date_time).format("YYYY-MM-DD"),
          end_time: elem.end_time,
        };
      });
      setchatBotDetails(tempArr);
    } else {
      console.log("showmathi");
      setchatBotDetails(prevChatBot);
    }
  };
  console.log("chatbot 00000000", chatBotDetails);
  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
    setchatBotDetails([...chatbot_arr]);
  };
  // const addChatBot = () => {
  //   let chatbot_arr = chatBotDetails;
  //   chatbot_arr.push({
  //     start_time: "",
  //     end_time: "",
  //     start_date: "",
  //     end_date: "",
  //   });
  //   setchatBotDetails([...chatbot_arr]);
  // };

  const handleDeletChatbot = (index) => {
    console.log(index);
    let tempArr = chatBotDetails;

    tempArr.splice(index, 1);

    setchatBotDetails([...tempArr]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAsset = (data) => {
    // console.log(data);
    // let asset_arr = data;
    // asset_arr.push(data);
    setassetsArray([...assetsArray, data]);
    setIsModalVisible(false);
  };

  const updateAsset = (data) => {
    console.log(data);
    const { tempIndex } = data;
    console.log(tempIndex);
    delete data.tempIndex;
    const arr = [...assetsArray];
    arr[tempIndex] = data;
    setassetsArray(arr);
    setIsUpdateModalVisible(false);
  };
  const handleAssetsUpdate = (elem, index) => {
    console.log(index);
    console.log(elem);
    const temp = { ...elem, arrIndex: index };
    setupdateAssetValue(temp);
    setIsUpdateModalVisible(true);
  };

  const handleAssetsFile = (e) => {
    console.log(e.target.files[0]);
  };

  // const socialMediaHandleChange = (id, event) => {
  //   console.log("id...........", id);
  //   console.log("event......", event.target.value);

  //   var social_media_array = socialMediaUrl;

  //   social_media_array.push({
  //     social_media_id: id,
  //     social_media_url: event.target.value,
  //   });

  //   setSocialMediaUrl([...social_media_array]);

  //   console.log("testing array  social media", social_media_array);
  // };

  const disabledTime = (current) => {
    const now = current;
    return {
      disabledHours: () => range(0, now.get("hours")),
      disabledMinutes: () => range(0, now.get("minutes") + 3),
    };
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    console.log(idx);
    console.log(checked);

    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      initial_state.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: "",
      });
    } else {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      zones_social_media_handles_details.splice(index, 1);
    }
    setinitial_state({
      ...initial_state,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };
  const socialMediaHandleChange = (idx, event) => {
    console.log(event);
    console.log(idx);

    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      initial_state.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      zones_social_media_handles_details[index]["social_media_url"] =
        event.target.value;
    } else {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value,
      });
    }

    setinitial_state({
      ...initial_state,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  const [restricedFields, setRestricedFields] = useState({
    tier_settings_id: null,
    open_demo_rooms: null,
    new_video_rep: null,
    chart_boards: null,
    chat_bots: null,
    on_booth_leads: null,
  });

  const callRestrictedAPI = async (tier) => {
    await ApiCall("get", null, "/admin/zone/restriction/details/get", {
      event_id: initial_state.event_id,
      brand_tier: tier,
    })
      .then((response) => {
        console.log("Restricted Response", response);
        let data = response?.data?.data;
        if (response?.data?.code === 200) {
          setRestricedFields({
            tier_settings_id: data?.tier_settings_id,
            open_demo_rooms: data?.open_demo_rooms,
            new_video_rep: data?.new_video_rep,
            chart_boards: data?.chart_boards,
            chat_bots: data?.chat_bots,
            on_booth_leads: data?.on_booth_leads,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showInviteAgentModal = () => {
    setIsInviteAgentModalVisible(true);
  };
  const handleOkInviteAgents = () => {
    setIsInviteAgentModalVisible(false);
  };

  const handleCancelInviteAgents = () => {
    setIsInviteAgentModalVisible(false);
  };

  const DetailComponent = ({ assetData }) => {
    // console.log("asset details---->", assetDetail);
    // console.log("asset data---->", assetData);
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          {/* <div className="row border-gray rounded  py-2"> */}
          <div className="row">
            <div className="col-4 text-end">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Max D/L's</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.max_no_of_downloads}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2">
              {/* <Switch defaultChecked={assetData?.is_downloadable} /> */}
            </div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date: {moment(assetData?.uploaded_at).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Uploaded By: {assetData?.uploaded_by}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ValidationSchema = Yup.object().shape({
    event_id: Yup.number().required("Required").nullable(),
    brand_id: Yup.string().required("Required").nullable(),
    booth_location_id: Yup.string().required("Required").nullable(),
    zone_title: Yup.string().required("Required").nullable(),
    // survey_id: Yup.string().required("Required").nullable(),
    // zone_agents:Yup.array().length(1,"Required")
    // zone_hero_img: Yup.mixed().required("Required"),
    chatbotUpload: Yup.mixed().when("is_text_bot_enable", {
      is: true,
      then: Yup.mixed().required("Required"),
    }),
  });

  if (zoneLoading) {
    return <CommonLoader />;
  } else
    return (
      <>
        <Formik
          initialValues={initial_state}
          validationSchema={ValidationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            handleEditZone(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {loading ? (
                <CommonLoader />
              ) : (
                <div>
                  <h4 className="fs-24">Edit Zone</h4>
                  <Breadcrumb className="my-2" separator=">">
                    {/* <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
          <Breadcrumb.Item>Zone Management</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Zone</Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                      <Link to="/zonemanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/zonemanagement">Zone Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/createnewzone">Edit Zone</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="bg-white px-5 py-3">
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Zone Editor
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Active</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_active}
                          name="is_active"
                          onChange={() =>
                            setFieldValue("is_active", !values.is_active)
                          }
                        />
                      </div>
                    </div>
                    {/* <img src={zoneHeroImg} alt="alt" height={100}/> */}
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Select Event
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          className="fs-bold-14"
                          // placeholder="Select"
                          optionFilterProp="children"
                          name="event_id"
                          value={values.event_id}
                          onChange={(val) => {
                            setFieldValue("event_id", val);
                            setFieldValue("brand_id", null);
                            setFieldValue("booth_location_id", null);
                            setFieldValue("survey_id", null);

                            addChatbot(val);
                            getBrandsList(val);
                          }}
                          listHeight={120}
                          listItemHeight={4}
                          disabled={userType === "BRAND ADMIN" ? true : false}
                        >
                          {eventList &&
                            eventList.map((data) => (
                              <Option value={data.event_id}>
                                {data.event_name}
                              </Option>
                            ))}
                        </Select>
                        {errors.event_id && touched.event_id ? (
                          <div className="text-danger">{errors.event_id}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Select Brand
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          // placeholder="Select"
                          optionFilterProp="children"
                          className="fs-bold-14"
                          name="brand_id"
                          value={values.brand_id}
                          onChange={(val) => {
                            setBrand_id(val);
                            setFieldValue("brand_id", val);
                            setFieldValue("booth_location_id", null);
                            setFieldValue("survey_id", null);
                          }}
                          listHeight={120}
                          listItemHeight={4}
                          disabled={userType === "BRAND ADMIN" ? true : false}
                        >
                          {brandList &&
                            brandList.map((data) => (
                              <Option value={data.brand_id}>
                                {data.brand_title}
                              </Option>
                            ))}
                        </Select>
                        {errors.brand_id && touched.brand_id ? (
                          <div className="text-danger">{errors.brand_id}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Location on Wall
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          // placeholder="Select"
                          optionFilterProp="children"
                          className="fs-bold-14"
                          name="booth_location_id"
                          value={values.booth_location_id}
                          onChange={(val) => {
                            setFieldValue("booth_location_id", val);
                            setFieldValue("survey_id", null);
                          }}
                          listHeight={120}
                          listItemHeight={4}
                        >
                          {boothLocationList &&
                            boothLocationList.map((data) => (
                              <Option value={data.booth_location_id}>
                                {data.booth_location}
                              </Option>
                            ))}
                        </Select>
                        {errors.booth_location_id &&
                        touched.booth_location_id ? (
                          <div className="text-danger">
                            {errors.booth_location_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col-3 text-end">Zone Hero Image</div>
                      <div className="col-2 image-upload">
                        <FileUpload
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="Image Upload"
                          inputType="file"
                          // inputOnChange={handleHeroImgChange}
                          inputOnChange={(e) => {
                            setFieldValue("zone_hero_img", e.target.files[0]);
                            // setzoneHeroImg(e.target.files[0])
                            setzoneHeroImg(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setinitial_state({
                              ...initial_state,
                              hero_img_url: e.target.files[0],
                            });
                          }}
                          inputId="heroImglogo"
                          inputName="zone_hero_img"
                        />
                        {/* {zoneHeroImg ? (
                <>
                  <span className="mx-3"> {zoneHeroImg.name} </span>
                  <span onClick={()=>{
                    document.getElementById('heroImglogo').value=null
                    setzoneHeroImg(null)
                    setinitial_state({...initial_state,hero_img_url:null})
                    }}>
                    <DeleteOutlined />
                  </span>
                </>
              ) : (
                ""
              )} */}
                        {/* {console.log("RF", restricedFields)} */}
                        {zoneHeroImg ? (
                          <>
                            <span className="mx-3">
                              {initial_state.hero_img_url?.name}
                            </span>
                            <span
                              // onClick={() => {
                              //   document.getElementById("heroImglogo").value = null;
                              //   setzoneHeroImg(null);
                              //   setinitial_state({
                              //     ...initial_state,
                              //     hero_img_url: null,
                              //   });
                              // }}
                              onClick={() => {
                                // document.getElementById("heroImglogo").value = null;
                                setzoneHeroImg(null);
                                setFieldValue("zone_hero_img", null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {errors.zone_hero_img && touched.zone_hero_img ? (
                          <div className="text-danger">
                            {errors.zone_hero_img}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {zoneHeroImg ? (
                      <div className="row ">
                        <div className="col-3"></div>
                        <div className="col-9">
                          <img
                            src={zoneHeroImg}
                            alt=" Uploaded Logo"
                            className="img-fluid user-img-preview"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row ">
                        <div className="col-3"></div>
                        <div className="col-9">
                          <img
                            src={initial_state?.hero_img_url}
                            alt={
                              initial_state.hero_img_url ? "Uploaded Logo" : ""
                            }
                            className="img-fluid user-img-preview"
                          />
                        </div>
                      </div>
                    )}
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Zone Title
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-4">
                        <Input
                          name="zone_title"
                          value={values.zone_title}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                          onChange={handleChange}
                        />
                        {errors.zone_title && touched.zone_title ? (
                          <div className="text-danger">{errors.zone_title}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Zone Description</div>
                      <div className="col-4">
                        <TextArea
                          rows={4}
                          className="w-100 fs-bold-14"
                          name="zone_description"
                          value={values.zone_description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Interaction
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Text Chat</div>
                      <div className="col-4">
                        <Switch
                          disabled={values.is_message_board_enable}
                          checked={values.is_text_chat_enable}
                          name="is_text_chat_enable"
                          onChange={() => {
                            if (restricedFields.chart_boards !== 0) {
                              setFieldValue(
                                "is_text_chat_enable",
                                !values.is_text_chat_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row mt-1">
              <div className="col-3 text-end">
                <span className="fs-12 fs-pink">OR</span>
              </div>
            </div> */}
                    {/* <div className="row my-3">
              <div className="col-3 text-end">Message Board</div>
              <div className="col-1">
                <Switch
                  disabled={values.is_text_chat_enable}
                  checked={values.is_message_board_enable}
                  name="is_message_board_enable"
                  onChange={() =>
                    setFieldValue(
                      "is_message_board_enable",
                      !values.is_message_board_enable
                    )
                  }
                />
              </div>
              {values.is_message_board_enable ? (
                <>
                  <div className="col-1 text-start">Moderated?</div>
                  <div className="col-4">
                    <Switch
                      checked={values.is_moderated}
                      name="is_moderated"
                      onChange={() =>
                        setFieldValue("is_moderated", !values.is_moderated)
                      }
                    />
                  </div>
                </>
              ) : null}
            </div> */}
                    <div className="row my-3">
                      <div className="col-3 text-end">1-1 Video Call</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_one_to_one_video_calls_enable}
                          name="is_one_to_one_video_calls_enable"
                          onChange={() => {
                            if (restricedFields.new_video_rep !== 0) {
                              setFieldValue(
                                "is_one_to_one_video_calls_enable",
                                !values.is_one_to_one_video_calls_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Select Survey</div>
                      <div className="col-4">
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select"
                          optionFilterProp="children"
                          value={values.survey_id}
                          className="fs-bold-14"
                          name="survey_id"
                          onChange={(val) => setFieldValue("survey_id", val)}
                        >
                          {surveyList &&
                            surveyList.map((elem) => (
                              <>
                                <Option value={elem.survey_id}>
                                  {elem.survey_title}
                                </Option>
                              </>
                            ))}
                        </Select>
                      </div>
                      <div className="col-4">
                        <Button onClick={showSurveyModal} className="">
                          + Create New Survey
                        </Button>
                      </div>
                      <Modal
                        visible={isSurveyModalVisible}
                        // onOk={handleOk}
                        onCancel={() => setIsSurveyModalVisible(false)}
                        footer={null}
                        width={1000}
                      >
                        <div className="p-2">
                          <AddNewSurvey
                            event_id={values?.event_id}
                            isFromEvent={true}
                            onCancelFromModal={() =>
                              setIsSurveyModalVisible(false)
                            }
                            onSuccessFromModal={async () => {
                              getSurveyList();
                              setIsSurveyModalVisible(false);
                            }}
                          />
                        </div>
                      </Modal>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">On Booth Leads</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_on_booth_leads_enable}
                          name="is_on_booth_leads_enable"
                          onChange={() => {
                            if (restricedFields.on_booth_leads !== 0) {
                              setFieldValue(
                                "is_on_booth_leads_enable",
                                !values.is_on_booth_leads_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row my-3">
              <div className="col-3 text-end">Available Video Rep</div>
              <div className="col-4">
                <Switch
                  checked={values.is_video_rep_enable}
                  name="is_video_rep_enable"
                  onChange={() =>
                    setFieldValue(
                      "is_video_rep_enable",
                      !values.is_video_rep_enable
                    )
                  }
                />
              </div>
            </div> */}
                    <div className="row my-3">
                      <div className="col-3 text-end">Video Demo Room</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_video_demo_room_enable}
                          name="is_video_demo_room_enable"
                          onChange={() => {
                            if (restricedFields.open_demo_rooms !== 0) {
                              setFieldValue(
                                "is_video_demo_room_enable",
                                !values.is_video_demo_room_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Chat Bot</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_text_bot_enable}
                          name="is_text_bot_enable"
                          onChange={() => {
                            if (restricedFields.chat_bots) {
                              setFieldValue(
                                "is_text_bot_enable",
                                !values.is_text_bot_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Zone Calender</div>
                      <div className="col-4">
                        <Link
                          to={`/zonecalender/${values?.event_id}/${values?.brand_id}/${zone_id}`}
                        >
                          <Button className=" btn-svg" icon={<CalenderGrey />}>
                            <span className="mx-2">Open Calender</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                    {values.is_text_bot_enable ? (
                      <>
                        <Divider />
                        <div className="row my-3">
                          <div className="col-12 mt-4">
                            <h6 className="font-pink fw-bolder fs-20">
                              Chat Bot Settings
                            </h6>
                          </div>
                        </div>
                        <b>
                          A Chat Bot can be enabled for this product zone for
                          set dates / times
                        </b>
                        <br />
                        <b>
                          1. Select the days you want the chat bot on for from
                          the mini calender on the right
                        </b>
                        <br />
                        <b>
                          2. Select the times that times the chatbot will NOT be
                          running for those days below
                        </b>
                        <div className="row mt-4  pb-4">
                          <div className="col-9 event-date-scroll-div">
                            <Collapse
                              bordered={false}
                              defaultActiveKey={["2"]}
                              expandIcon={({ isActive }) =>
                                (isActive && (
                                  <MinusOutlined
                                    className="rounded minus-logo"
                                    style={{}}
                                  />
                                )) || (
                                  <PlusOutlined className="rounded plus-logo" />
                                )
                              }
                              className="site-collapse-custom-collapse"
                            >
                              {chatBotDetails &&
                                chatBotDetails.map((elem, index) => {
                                  console.log(
                                    "chatbotloop________________",
                                    elem
                                  );
                                  var start = moment(
                                    chatBotDetails[index].start_date,
                                    "YYYY-MM-DD"
                                  );
                                  var end = moment(
                                    chatBotDetails[index].end_date,
                                    "YYYY-MM-DD"
                                  );

                                  return (
                                    <>
                                      <Panel
                                        header={
                                          <span>
                                            <span className="fw-bold">
                                              {/* {end.diff(start, "days") + " "}
                                              Day Show */}
                                              Shows Days/Times - Block{" "}
                                              {index + 1}
                                            </span>
                                            <span
                                              className="font-pink mx-2
                                "
                                            >
                                              {/* {"[ "+chatBotDetails[index].start_date +"-"+ chatBotDetails[index].end_date+" , "+chatBotDetails[index].start_time+"-"+chatBotDetails[index].end_time+" ]"} */}
                                              {elem.iss
                                                ? "[ " +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .start_date
                                                  ).format("DD-MM-YYYY") +
                                                  " - " +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .end_date
                                                  ).format("DD-MM-YYYY") +
                                                  " , " +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .start_time
                                                  ).format("HH:mm") +
                                                  "-" +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .end_time
                                                  ).format("HH:mm") +
                                                  " ]"
                                                : "[ " +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .start_date
                                                  ).format("DD-MM-YYYY") +
                                                  " - " +
                                                  moment(
                                                    chatBotDetails[index]
                                                      .end_date
                                                  ).format("DD-MM-YYYY") +
                                                  " , " +
                                                  chatBotDetails[index]
                                                    .start_time +
                                                  "-" +
                                                  chatBotDetails[index]
                                                    .end_time +
                                                  " ]"}
                                            </span>
                                          </span>
                                        }
                                        key={index}
                                        className="site-collapse-custom-panel"
                                      >
                                        <div className="row mt-4">
                                          <div className="col-3 text-end">
                                            <label className="text-secondary mt-1">
                                              Start & End Time
                                            </label>
                                          </div>
                                          <div className="col-6 d-flex m-0">
                                            <TimePicker
                                              // suffixIcon={
                                              //   <CalenderGrey width="15" />
                                              // }
                                              // defaultValue={
                                              //   // elem?.start_time
                                              //   //   ? moment(
                                              //   //       elem?.start_time,
                                              //   //       "h:mm"
                                              //   //     ).utcOffset(elem?.start_time)
                                              //   //   : null
                                              //   chatBotDetails[index].start_time
                                              // }
                                              value={moment(
                                                chatBotDetails[index]
                                                  .start_time,
                                                "HH:mm"
                                              )}
                                              renderExtraFooter={() =>
                                                "extra footer"
                                              }
                                              showTime
                                              format="HH:mm"
                                              allowClear={false}
                                              onOk={onOk}
                                              className="w-100"
                                              onSelect={(val) => {
                                                handlechatbotArrChange(
                                                  val,
                                                  index,
                                                  "start_time"
                                                );
                                                handlechatbotArrChange(
                                                  true,
                                                  index,
                                                  "iss"
                                                );
                                              }}
                                            />
                                            <TimePicker
                                              // suffixIcon={
                                              //   <CalenderGrey width="15" />
                                              // }
                                              renderExtraFooter={() =>
                                                "extra footer"
                                              }
                                              value={moment(
                                                chatBotDetails[index].end_time,
                                                "HH:mm"
                                              )}
                                              disabledTime={() =>
                                                disabledTime(
                                                  moment(
                                                    chatBotDetails[index]
                                                      .start_time,
                                                    "HH:mm"
                                                  )
                                                )
                                              }
                                              showTime
                                              format="HH:mm"
                                              allowClear={false}
                                              onOk={onOk}
                                              className="w-100"
                                              onSelect={(val) => {
                                                handlechatbotArrChange(
                                                  val,
                                                  index,
                                                  "end_time"
                                                );
                                                handlechatbotArrChange(
                                                  true,
                                                  index,
                                                  "iss"
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Panel>
                                    </>
                                  );
                                })}
                            </Collapse>
                          </div>
                          {/* <div className="col-3">
              <Button
                className="px-2 button-pink rounded"
                onClick={addChatBot}
              >
                + Add Days with different times
              </Button>
            </div> */}
                        </div>
                        <div className="d-flex">
                          <span className="">Bot Responses</span>
                          <div
                            className="ms-2 text-decoration-underline fs-pink cursor-pointer"
                            onClick={() => {
                              window.location.href =
                                STATIC_URLS.default_chatbot;
                            }}
                          >
                            Click Here To Download Our Template
                          </div>
                        </div>
                        <div className="d-flex mt-4">
                          <span className="align-self-center mx-2">
                            Chat Bot{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </span>
                          <FileUpload
                            labelClassName="rounded border-pink btn-svg  px-3 py-2 ms-3"
                            labelTitle="File Upload"
                            name="chatbotUpload"
                            inputType="file"
                            inputOnChange={(e) => {
                              setFieldValue("chatbotUpload", e.target.files[0]);
                              setChatBotFileUpload(e.target.files[0]);
                            }}
                            accept={true}
                            onChange={(e) => console.log("eeeeeeeee", e)}
                            inputId="chatbot-upload"
                            inputName="chatbotUpload"
                            // value={chatBotFileUpload}
                            // onBlur={() =>
                            //   validator.current.showMessageFor("chatbot-upload")
                            // }
                          />
                          {/* <input
                          style={{ color: "white" }}
                          type="file"
                          id="chatbotFile"
                          name="chatbotFile"
                          onChange={(e) => {
                            // setFieldValue("chatbotFile", e.target.files[0]);
                            setChatBotFileUpload(e.target.files[0]);
                          }}
                        /> */}
                        </div>
                        <div
                          className="ms-2"
                          style={{ paddingLeft: "65px", paddingTop: "10px" }}
                        >
                          {chatBotFileUpload ? (
                            <>
                              <span className="">
                                {" "}
                                {chatBotFileUpload.name}{" "}
                              </span>
                              <span
                                onClick={() => {
                                  setChatBotFileUpload(null);
                                  setFieldValue("chatbotUpload", null);
                                  // setFieldValue("chatbotFile", null);
                                  // document.getElementById("chatbotFile").value =
                                  //   null;
                                }}
                              >
                                <DeleteOutlined />
                              </span>
                            </>
                          ) : null}
                          {errors.chatbotUpload && touched.chatbotUpload ? (
                            <div className="text-danger">
                              {errors.chatbotUpload}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Externals & Social Links
                        </h6>
                      </div>
                    </div>
                    N8: If you paste your details into these boxes then anyone
                    using site will be able to view/contact you via these
                    platform
                    <div className="social-media-div border-bottom-gray pb-4 mb-4">
                      {socialMediaList &&
                        socialMediaList.map((data, idx) => (
                          <div className="row mt-4 ">
                            <div className="col-2 text-end ">
                              {data?.social_media}
                            </div>
                            <div className="col-1 text-center ">
                              <Switch
                                checked={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(checked) =>
                                  onHandleSocialSwitchChange(checked, idx)
                                }
                              />
                            </div>
                            <div className="col-4 text-start ">
                              <Input
                                placeholder="https://webaddress.com"
                                className="w-100 fs-bold-14"
                                disabled={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? false
                                    : true
                                }
                                value={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? initial_state
                                        .zones_social_media_handles_details[
                                        initial_state.zones_social_media_handles_details.findIndex(
                                          (social_media) =>
                                            social_media.social_media_id ===
                                            data.social_media_id
                                        )
                                      ]["social_media_url"]
                                    : ""
                                }
                                onChange={(event) =>
                                  socialMediaHandleChange(idx, event)
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor(
                                    "social_media"
                                  )
                                }
                              />
                              {validator.current.message(
                                "social_media",
                                initial_state?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? initial_state
                                      ?.zones_social_media_handles_details[
                                      initial_state?.zones_social_media_handles_details.findIndex(
                                        (social_media) =>
                                          social_media.social_media_id ===
                                          data.social_media_id
                                      )
                                    ]["social_media_url"]
                                  : "",
                                initial_state?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? "required|url"
                                  : "",
                                { className: "text-danger py-1" }
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">
                            Assign Agents to this Zone
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4 event-editor-dropdown-div">
                        <div className="col-2 text-end mt-4">
                          <span className="text-secondary mt-4">Agents</span>
                        </div>

                        <div
                          className={Tablet ? "col-5" : "col-4"}
                          id="area"
                          style={{ position: "relative" }}
                        >
                          <label className="py-2 mx-2">
                            <b>All Agents</b>
                          </label>
                          <br />
                          <Select
                            showSearch
                            style={{ width: 300 }}
                            dropdownClassName="agent-dropdown"
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            value="All Agents"
                            getPopupContainer={() =>
                              document.getElementById("area")
                            }
                            onChange={handleAgentsChange}
                            open={true}
                            listItemHeight={4}
                            listHeight={130}
                            className="border border-gray fs-bold-14"
                          >
                            {agents?.map((data) => (
                              <Option
                                className="select-text-dark"
                                value={data.user_id}
                              >
                                {data.first_name} {data.last_name}
                              </Option>
                            ))}
                            {/* {agents.map((val, idx) => {
                                 return  val.brands_agents.map((data,idx) => {
                                        // console.log("dattttaaaa",data.brand_id)
                                       return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                    })
                                    })} */}
                          </Select>
                          {isEmpty(selectedAgents) &&
                          errors.zone_agents &&
                          touched.zone_agents ? (
                            <div className="text-danger agents-error-margin-top">
                              {errors.zone_agents}
                            </div>
                          ) : null}
                        </div>

                        <div
                          className={Tablet ? "col-5" : "col-4"}
                          id="area1"
                          style={{ position: "relative" }}
                        >
                          <label className="py-2 mx-2">
                            <b>Tagged Agents</b>
                          </label>
                          <br />
                          {/* <div className="event-editor-dropdown-background-gray rounded w-75  p-2"> */}
                          <Select
                            getPopupContainer={() =>
                              document.getElementById("area1")
                            }
                            showSearch
                            dropdownClassName="agent-dropdown"
                            style={{ width: 300 }}
                            placeholder="Search Agents"
                            optionFilterProp="children"
                            value="Tagged Agents"
                            // onChange={handleAgentsChange}
                            open={true}
                            listItemHeight={4}
                            listHeight={130}
                            className="border border-gray fs-bold-14"
                          >
                            {selectedAgents?.map((data) => (
                              <Option
                                value={data.user_id}
                                onClick={() =>
                                  handleSelectedAgentsChange(data.user_id)
                                }
                              >
                                {data.first_name} {data.last_name}
                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleSelectedAgentsChange(data.user_id)
                                    }
                                  />
                                </span>
                              </Option>
                            ))}
                            {/* {agents.map((val, idx) => {
                                 return  val.brands_agents.map((data,idx) => {
                                        // console.log("dattttaaaa",data.brand_id)
                                       return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                    })
                                    })} */}
                          </Select>
                          {/* </div> */}
                        </div>
                      </div>

                      <div className="row mt-4 border-bottom-gray pb-4">
                        <div className="col-2"></div>
                        <div className="col-4">
                          <Button
                            className="px-3  rounded btn-svg"
                            onClick={showInviteAgentModal}
                          >
                            <AddGroup fill="#ef0855" />
                            <span className="mx-2">Invite Agents</span>
                          </Button>
                          <Modal
                            title="Add User"
                            visible={isInviteAgentModalVisible}
                            onOk={handleOkInviteAgents}
                            onCancel={handleCancelInviteAgents}
                            footer={null}
                            width={800}
                          >
                            {/* <InviteAttend
                            event_id={values?.event_id}
                            onCancelFromModal={() =>
                              setIsInviteAgentModalVisible(false)
                            }
                            onSuccessFromModal={async () => {
                              setIsInviteAgentModalVisible(false);
                            }}
                          /> */}
                            <AddUser
                              event_id={values?.event_id}
                              brand_id={values?.brand_id}
                              onCancelFromModal={() =>
                                setIsInviteAgentModalVisible(false)
                              }
                              onSuccessFromModal={async () => {
                                setIsInviteAgentModalVisible(false);
                              }}
                            />
                          </Modal>
                          <Button
                            className={
                              "px-3 rounded btn-svg" +
                              (Tablet ? " mt-2" : " mx-2")
                            }
                            onClick={() =>
                              window.open(
                                `/zonecalender/${initial_state?.event_id}/${initial_state?.brand_id}/${zone_id}`,
                                "_blank"
                              )
                            }
                          >
                            <CalenderGrey />
                            <span className="mx-2">Staffing Calendar</span>
                          </Button>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className={Tablet ? "col-12" : "col-2"}>
                          <Button className="px-3  rounded" onClick={showModal}>
                            + Add New Asset
                          </Button>
                        </div>
                        {assetsArray ? (
                          <div className="col-4 assets-scroll-div">
                            {assetsArray &&
                              assetsArray.map((elem, index) => {
                                console.log(elem);
                                return (
                                  <>
                                    <div
                                      className={Tablet ? "mt-4" : " py-2"}
                                      // onMouseOver={() => setViewVisible(elem)}
                                      onClick={() => setViewVisible(elem)}
                                    >
                                      <div className="row border-gray rounded py-1">
                                        <div className="col-1 assets_svg_color">
                                          <Cube />
                                        </div>
                                        <div className="col-11">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <span className="fs-18 mx-2">
                                                <b>{elem.asset_name}</b>
                                              </span>
                                            </div>
                                            <div>
                                              {/* <span className="px-3">
                                      <Settings />
                                    </span> */}
                                              <span
                                                className="px-3 assets_svg_color"
                                                onClick={() => {
                                                  console.log(elem);
                                                  handleAssetsUpdate(
                                                    elem,
                                                    index
                                                  );
                                                }}
                                              >
                                                <EditRed />
                                              </span>
                                              <span
                                                className="px-3 assets_svg_color"
                                                onClick={async () => {
                                                  // console.log(index);
                                                  // console.log(elem);
                                                  if (elem.zone_asset_id) {
                                                    let temp_asset =
                                                      assetsArray;
                                                    temp_asset.splice(index, 1);
                                                    setassetsArray(temp_asset);
                                                    await ApiCall(
                                                      "DELETE",
                                                      {
                                                        zone_asset_id:
                                                          elem.zone_asset_id,
                                                      },
                                                      "/admin/zoneassetfile/delete"
                                                    );
                                                  } else {
                                                    let temp_asset =
                                                      assetsArray;
                                                    temp_asset.splice(index, 1);
                                                    await setassetsArray(
                                                      temp_asset
                                                    );
                                                  }

                                                  setViewVisible(false);
                                                }}
                                              >
                                                <Bin />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <span className="fs-16 mx-2">
                                              {elem.asset_description}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        ) : null}

                        {viewVisible || !assetsArray ? (
                          <DetailComponent assetData={viewVisible} />
                        ) : null}
                      </div>
                    </section>
                  </div>
                  <div
                    className="shadow-sm p-3 d-flex justify-content-between"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <Link to="/zonemanagement">
                      <Button className="px-3 d-block button-pink rounded">
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        if (validator.current.allValid() && isValid) {
                          setFieldValue("zones_agents", selectedAgents);
                          setFieldValue(
                            "zone_chat_bot_setting_details",
                            chatBotDetails
                          );
                          setFieldValue(
                            "zones_social_media_handles_details",
                            socialMediaUrl
                          );
                          const temp_asset = assetsArray.map((elem, index) => ({
                            zone_asset_id: elem?.zone_asset_id,
                            asset_file_type: elem.asset_file_type,
                            asset_file_name: elem.asset_file_name,
                            asset_name: elem.asset_name,
                            asset_description: elem.asset_description,
                            is_active: elem.is_active,
                            is_downloadable: elem.is_downloadable,
                            max_no_of_downloads: parseInt(
                              elem.max_no_of_downloads
                            ),
                            total_downloads: elem.total_downloads,
                            whitelisted_domains: elem.whitelisted_domains,
                            blacklisted_domains: elem.blacklisted_domains,
                            view_count: elem.view_count,
                          }));
                          setFieldValue("zones_assets_details", temp_asset);
                        } else {
                          validator.current.showMessages();
                          toast.error("Fields are missing");
                          setForceUpdate(!forceUpdate);
                        }
                        window.scrollTo(0, 0);
                      }}
                    >
                      {!loading ? (
                        "Edit Zone"
                      ) : (
                        <div style={{ width: "70px" }}>
                          <Spin
                            indicator={loadingIcon}
                            style={{ color: "white" }}
                          />
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          )}
        </Formik>

        <Modal
          title="Add New Assets"
          footer={false}
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "image",
                asset_file_name: "",
                asset_name: "",
                asset_description: "",
                is_active: true,
                is_downloadable: true,
                max_no_of_downloads: 3,
                total_downloads: 6,
                whitelisted_domains: "",
                blacklisted_domains: "",
                view_count: 9,
                uploadObj: null,
                // asset_file_url:
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
                uploadObj: Yup.mixed().required("Required"),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                addAsset(values);
                action.resetForm();
                // console.log(values);
                // setIsModalVisible(false);
                // let asset_arr = assetsArray;
                // asset_arr.push(values);
                // setassetsArray([...assetsArray, values]);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets File</div>

                    <div className="col-7 image-upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="Image Upload"
                        inputType="file"
                        inputOnChange={(e) => {
                          console.log(e.target.files[0]);
                          setFieldValue("uploadObj", e.target.files[0]);
                          setFieldValue(
                            "asset_file_name",
                            e.target.files[0].name
                          );
                        }}
                        inputName="uploadObj"
                        inputId="pree"
                      />
                      {errors.uploadObj &&
                      touched.uploadObj &&
                      !values.uploadObj ? (
                        <div className="text-danger">{errors.uploadObj}</div>
                      ) : null}
                      {values.uploadObj ? (
                        <>
                          <span className="mx-3">
                            {" "}
                            {values.uploadObj.name}{" "}
                          </span>
                          <span
                            onClick={() => {
                              setFieldValue("uploadObj", null);
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={() =>
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Max No. of download</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="max_no_of_downloads"
                        // value={values.max_no_of_downloads}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        name="whitelisted_domains"
                        // value={values.whitelisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        name="blacklisted_domains"
                        // value={values.blacklisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        // htmlType="submit"
                        onClick={
                          () =>
                            // addAsset(values)
                            handleCancel()
                          // console.log("object")
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() =>
                          // addAsset(values)
                          console.log("object")
                        }
                      >
                        Add Assets
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        {/* update asset model */}

        <Modal
          title="Update Assets"
          footer={false}
          visible={isUpdateModalVisible}
          // onOk={handleOk}
          onCancel={() => {
            setIsUpdateModalVisible(false);
          }}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                zone_asset_id: updateAssetValue?.zone_asset_id,
                asset_file_type: "image",
                asset_file_name: "",
                asset_name: updateAssetValue?.asset_name,
                asset_description: updateAssetValue?.asset_description,
                is_active: updateAssetValue?.is_active,
                is_downloadable: updateAssetValue?.is_downloadable,
                max_no_of_downloads: updateAssetValue?.max_no_of_downloads,
                total_downloads: updateAssetValue?.total_downloads,
                whitelisted_domains: updateAssetValue?.whitelisted_domains,
                blacklisted_domains: updateAssetValue?.blacklisted_domains,
                view_count: 9,
                uploadObj: null,
                tempIndex: updateAssetValue?.arrIndex,
                asset_file_url: assetsArray?.asset_file_url,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
                // uploadObj: Yup.mixed().required("Required")
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                updateAsset(values);
                action.resetForm();
                // console.log(values);
                // setIsModalVisible(false);
                // let asset_arr = assetsArray;
                // asset_arr.push(values);
                // setassetsArray([...assetsArray, values]);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets File</div>

                    <div className="col-7 image-upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="Image Upload"
                        inputType="file"
                        inputOnChange={(e) => {
                          console.log("update asset file onchange", values);
                          console.log(e.target.files[0]);
                          setFieldValue("uploadObj", e.target.files[0]);
                          setFieldValue(
                            "asset_file_name",
                            e.target.files[0].name
                          );
                        }}
                        inputId="pree"
                        inputName="pree"
                      />
                      {values.uploadObj ? (
                        <>
                          <span className="mx-3">
                            {" "}
                            {values.uploadObj.name}{" "}
                          </span>
                          <span
                            onClick={() => {
                              setFieldValue("uploadObj", null);
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="mx-3">
                            {updateAssetValue?.original_file_name}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={() =>
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Max No. of download</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="max_no_of_downloads"
                        value={values.max_no_of_downloads}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        name="whitelisted_domains"
                        value={values.whitelisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        name="blacklisted_domains"
                        value={values.blacklisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        // htmlType="submit"
                        onClick={
                          () =>
                            // addAsset(values)
                            // handleCancel()
                            setIsUpdateModalVisible(false)
                          // console.log("object")
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() =>
                          // addAsset(values)
                          console.log("object")
                        }
                      >
                        Update Assets
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      </>
    );
};

export default EditZone;
