import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState = {
    loading: false,
    headerObj: {},
    events: [],
    initial_event_id: null,
    event_id: null
}


export const setHeaderData = createAsyncThunk(

    'setHeader/setHeader',

    async ({ title, breadcrumbs }) => {

        return {
            title,
            breadcrumbs
        };
    })


export const setHeaderEventList = createAsyncThunk(

    'setEventHeaderList/setEventHeaderList',

    async (events, event_id) => {

        return {
            events
        };
    })

export const setHeaderDefaultEventId = createAsyncThunk(

    'setHeaderDefaultEventID/setEventDefaultHeaderID',

    async (event_id) => {

        return {
            event_id
        };
    })

export const setHeaderNewEventId = createAsyncThunk(

    'setHeaderNewEventID/setEventNewHeaderID',

    async (event_id) => {

        return {
            event_id
        };
    })

export const setHeaderSlice = createSlice({
    name: 'HeaderData',
    initialState,
    reducers: {},
    extraReducers: {
        [setHeaderData.pending]: (state) => {
            state.loading = true
        },
        [setHeaderData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.headerObj = payload
        },
        [setHeaderData.rejected]: (state) => {
            state.loading = false
        },
        [setHeaderEventList.pending]: (state) => {
            state.loading = true
        },
        [setHeaderEventList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.events = payload.events
            // state.event_id = payload.event_id

        },
        [setHeaderEventList.rejected]: (state) => {
            state.loading = false
        },
        [setHeaderDefaultEventId.pending]: (state) => {
            state.loading = true
        },
        [setHeaderDefaultEventId.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.initial_event_id = payload.event_id

        },
        [setHeaderDefaultEventId.rejected]: (state) => {
            state.loading = false
        },
        [setHeaderNewEventId.pending]: (state) => {
            state.loading = true
        },
        [setHeaderNewEventId.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.event_id = payload.event_id

        },
        [setHeaderNewEventId.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const headerReducer = setHeaderSlice.reducer
