import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./index.css";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEventList, changeStatus } from "../../redux/slice/eventManagement";
import moment from "moment";
import { clearEventData } from "../../redux/slice/editEvent.js";
import { clearNewEventData } from "../../redux/slice/createEventNew.js";
import { Filter } from "../../components/svgJS/Filter";
import { isEmpty } from "lodash";
import { ApiCall } from "../../utils/api";

const EventManagement = () => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  const [searchData, setSearchData] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [fields, setFields] = useState({
    customerName: null,
    creatorName: null,
  });
  let eventSelector = useSelector((state) => state.eventManagement.eventList);

  const { Option } = Select;

  useEffect(() => {
    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    }

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    window.scrollTo(0, 0);
    dispatch(getEventList());
    dispatch(clearEventData({}));
    dispatch(clearNewEventData({}));
    getCustomerList();
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
      customer_id: fields?.customerName,
    };
    dispatch(getEventList(searchObj));
  }, [searchData, fields.customerName]);

  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        event_id: id,
        is_active: !bool,
      })
    );
  };

  const tableData =
    eventSelector &&
    !isEmpty(eventSelector) &&
    eventSelector.rows &&
    eventSelector.rows.map((elem, i) => {
      return {
        key: "event_table_row_" + i.toString(),
        event_id: elem.event_id,
        domain: elem.sub_domain,
        name: elem.customer.customer_name ?? "NA",
        start: elem.registration_start_date_time ?? "-",
        end: elem.registration_end_date_time ?? "-",
        brands: elem.total_brands_count + "/" + elem.brand_count,
        users: elem.total_users_count + "/" + elem.max_no_of_users,
        storage: elem.storage ?? "NA",
        streaming: elem.streaming ?? "NA",
        videoHours: elem.videoHours ?? "NA",
        contact: elem.customer.main_contact_email ?? "NA",

        created_at: moment(elem?.created_at).format("DD-MM-YYYY HH:mm"),
        created_by: elem?.created_user_name,
        eventdomainname: elem?.sub_domain,
        customername: elem?.customer.customer_name,
        eventduration: elem?.event_duration + " Days",
        // tierstype:
        //   elem?.events_tiers_settings[0]?.eventsTiersSettings_tier_id?.tier,
        brand_title: elem?.events_organizer_brand_id?.brand_title,
        is_active: elem?.is_active === true ? true : false,
      };
    });

  const columns = [
    {
      title: "Event ID",
      dataIndex: "event_id",
      key: "event_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 120,
      render: (text, record) => {
        return (
          <button
            className={
              record.is_active === true
                ? "bg-button-green active_btns"
                : "bg-button-red"
            }
            onClick={() => handleStatus(record.event_id, record.is_active)}
            shape="round"
          >
            {record.is_active === true ? "Active" : "Inactive"}
          </button>
        );
      },
      sorter: true,
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150,
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150,
    },
    {
      title: "# Brands",
      dataIndex: "brands",
      key: "brands",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "# Users",
      dataIndex: "users",
      key: "users",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "streaming",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Video Hours",
      dataIndex: "videoHours",
      key: "videoHours",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 130,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
      align: "Left",
      className: "p-5 m-5",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      // align: "Left",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 200,
      columnWidth: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (created_by) => (
        <Tooltip placement="topLeft" title={created_by}>
          {created_by}
        </Tooltip>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },

    // {
    //   title: "Tiers Type",
    //   dataIndex: "tierstype",
    //   key: "tier",
    //   sorter: true,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },

    {
      title: "",
      dataIndex: "operation",
      width: 40,
      align: "Center",
      fixed: "right",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link
                to={{
                  pathname: "viewnewevent",
                  // pathname: `viewnewevent/${record.event_id}`,
                  eventId: record,
                }}
              >
                <p className="options_color">
                  <EyeOutlined className="mx-1" />
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editevent/${record.event_id}`}>
                <p className="options_color">
                  <EditOutlined className="mx-1" />
                  <span className="">Edit Event</span>
                </p>
              </Link>
              {userType && userType === "CUSTOMER" ? (
                <p></p>
              ) : (
                <p className="options_color">
                  <SelectOutlined className="mx-1" />
                  <span className=""> View Domain </span>
                </p>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      width: 60,
      fixed: "right",
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editevent/${record.event_id}`}>
            <p className="options_color m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      eventid: "#041254121",
      createdat: "12-12-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "2",
      eventid: "#041254215",
      createdat: "10-10-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 2",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "3",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 3",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "4",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 4",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "5",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 5",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "6",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "7",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "8",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "9",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "10",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "11",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "12",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "13",
      eventid: "#041254121",
      createdat: "12-12-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "14",
      eventid: "#041254215",
      createdat: "10-10-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "15",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "16",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "17",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "18",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "19",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "20",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "21",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "22",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "23",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "24",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
  ];

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    dispatch(getEventList(sortObj));
  };

  const handleInputSearch = (e) => {
    setSearchData(e.target.value);
  };
  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  console.log("fields in event listing", fields);
  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Event Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Event Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="createevent">
            <Button className="button-pink rounded px-3 py-1 mt-2">
              <span className="fw-bold"> + Create New Event </span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            <Input
              size="medium"
              placeholder="Search Cust Name, Event Name, Brand Name, Creator Name, Zone Name, User Name"
              style={{ width: "130%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
              allowClear
            />
          </div>
          <div className="d-flex">
            <Button
              className=" rounded"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>
          </div>
        </div>

        <div className="mb-2">
          {showFilter ? (
            <div>
              <Select
                name="customerName"
                placeholder="Customer Name"
                style={{
                  width: 280,
                }}
                onChange={(val) => handleFieldsChange(val, "customerName")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                name="creatorName"
                placeholder="Creator Name"
                className="ms-2"
                onChange={(val) => handleFieldsChange(val, "creatorName")}
                style={{
                  width: 280,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                <option>Dummy</option>
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default EventManagement;
