import React, { useState } from "react";
import "./NetworkingSession.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";

import { Download } from "../../components/svgJS/Download";

import { Bin } from "../../components/svgJS/Bin";

const NetworkingSession = () => {
  const [
    isNetworkingSessionModalVisible,
    setIsNetworkingSessionModalVisible
  ] = useState(false);

  const showNetworkingSessionModal = () => {
    setIsNetworkingSessionModalVisible(true);
  };

  const handleNetworkingSessionOk = () => {
    setIsNetworkingSessionModalVisible(false);
  };

  const handleNetworkingSessionCancel = () => {
    setIsNetworkingSessionModalVisible(false);
  };

  // End of Networking Session Modal

  return (
    <>
      <Button
        className="ms-2"
        type="primary"
        onClick={showNetworkingSessionModal}
      >
        Open Networking Session Modal
      </Button>
      {/* Start of Networking Session Modal */}
      <Modal
        title=""
        visible={isNetworkingSessionModalVisible}
        onOk={handleNetworkingSessionOk}
        onCancel={handleNetworkingSessionCancel}
        className=""
        width={650}
        footer={null}
      >
        <div className="demoroom_container w-100 p-4">
          <span className="fs-18">Title :</span>
          <h5>Speed Dating for Sales People</h5>
          <span className="fs-18">Time :</span>
          <h6>14.10 - 15.10</h6>
          <span className="fs-18">Description :</span>
          <p className="fs-16">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <Row className="mb-3">
            <Col span={12}>
              <span className="opacity-75 fs-18">Seats per Table :</span> <br />
              <span>6</span> <br />
              <span className="opacity-75 fs-18">Shuffle Time :</span> <br />
              <span>15 minutes</span> <br />
              <span className="opacity-75 fs-18">
                Waterfall (Intro) Time : :
              </span>{" "}
              <br />
              <span>45 seconds</span> <br />
              <span className="opacity-75 fs-18">
                Attending Live Currently : :
              </span>{" "}
              <br />
              <span>1,432</span> <br />
            </Col>
            <Col span={12}>
              <span className="opacity-75 fs-18">Speed Dating Enabled? :</span>{" "}
              <br />
              <span>Yes</span> <br />
              <span className="opacity-75 fs-18">
                Waterfall Intros Enabled :
              </span>{" "}
              <br />
              <span>Yes</span> <br />
              <span className="opacity-75 fs-18">
                Provisionally Attending :
              </span>{" "}
              <br />
              <span>1,732</span> <br />
              <Button className="mt-2 networking_downloadBtn">
                <Download />
                <span className="ms-2">Download iCal </span>
              </Button>
            </Col>
          </Row>

          <h5 className="mb-3">Sponsored by</h5>

          <Carousel
            className="w-75 mx-auto"
            arrows
            nextArrow={
              <div>
                <i class="arrow right"></i>
              </div>
            }
            prevArrow={
              <div>
                <i class="arrow left"></i>
              </div>
            }
          >
            <div className="networksess_card_container p-4  d-flex  align-items-center mt-2">
              <div className="mx-2">
                <img
                  width={150}
                  height={150}
                  className="img-responsive"
                  src="https://www.emotivebrand.com/wp-content/uploads/2016/09/tumblr_o05v3eZmyT1ugn1wu_og_1280-1080x675.png"
                />
              </div>
              <div>
                <Button className="audi_pdfBtn mb-3">
                  <Download />
                  <span className="ms-2 fs-16">sponsorasset.pdf</span>
                </Button>
                <div className="d-flex justify-content-evenly w-100 mb-3">
                  <div className="border p-1">
                    <Booth />
                  </div>
                  <div className="border p-1">
                    <Chat />
                  </div>
                  <div className="border p-1">
                    <Video />
                  </div>
                  <div className="border p-1">
                    <Video />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex  align-items-center mt-2">
              <h6>2</h6>
            </div>
          </Carousel>

          <div className="w-100 d-flex mt-4 mb-4">
            <div className="w-50"></div>
            <Button className="networkingsess_deleteBtn  fs-16">
              <Bin />
              <span className="ms-2">Delete Event</span>
            </Button>
            <Button className="networkingsess_audiBtn mx-4 fs-16">
              <BoothRed />
              <span className="ms-2"> Join Session</span>
            </Button>
          </div>
        </div>
      </Modal>
      {/* End of Networking Session Modal */}
    </>
  );
};

export default NetworkingSession;
