import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Popconfirm,
  Popover,
  Breadcrumb,
  Input,
  Menu,
  Select,
  message,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
  DeleteOutlined,
  UserOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { filter } from "../../components/Images/Images";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { data } from "./temp-data";
import { getZone, changeStatus } from "../../redux/slice/zoneManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import moment from "moment";
import { Filter } from "../../components/svgJS/Filter";

const ZoneManagement = () => {
  const [searchData, setsearchData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [tierList, setTierList] = useState();
  const [brandList, setBrandList] = useState();
  const [creatorName, setCreatorName] = useState();
  const [fields, setFields] = useState({
    customerName: null,
    eventName: null,
    creatorName: null,
    tier: null,
    brandName: null,
  });

  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");

  useEffect(() => {
    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    }
    window.scrollTo(0, 0);
    dispatch(getZone());
    getEventList();
    getCustomerList();
    getTierList();
    getBrandList();
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
      customer_id: fields?.customerName,
      event_id: fields?.eventName,
      brand_id: fields?.brandName,
      tier: fields?.tier,
    };
    dispatch(getZone(searchObj));
  }, [
    searchData,
    fields?.eventName,
    fields?.tier,
    fields?.brandName,
    fields?.customerName,
  ]);

  const { rows } = useSelector((state) => state.zoneManagement.data);

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        // key: "1",
        zone_id: elem.zone_id,
        created_at: elem.created_at,
        zone_title: elem.zone_title,
        brands: elem.zone_brand_title,
        locationonwall: elem.booth_location.booth_location,
        // assets: elem.asset_description,
        agent: elem.total_agents_count + "/" + elem.no_of_agents,
        customername: elem.account_name ?? "NA",
        eventdomain: elem.sub_domain ?? "NA",
        assets_count: {
          upper: elem?.no_of_assets_per_zone,
          lower: elem?.total_assets_count,
        },
        contact: elem?.main_contact_email,
        is_active: elem.is_active,
      };
    });

  const { Option } = Select;

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  function handleButtonClick(e) {
    message.info("Click on left button.");
    console.log("click left button", e);
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("clicks", e);
  }
  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        zone_id: id,
        is_active: !bool,
      })
    );
  };

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : null,
    })
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        setTierList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : "",
    })
      .then((response) => {
        const { rows } = response.data.data;
        setBrandList(rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Zone ID",
      dataIndex: "zone_id",
      key: "zone_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
      //   render: text => <a href="#3">{text}</a>,
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (brands) => (
        <Tooltip placement="topLeft" title={brands}>
          {brands}
        </Tooltip>
      ),
    },
    {
      title: "Zone Title",
      dataIndex: "zone_title",
      key: "zone_title",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (zone_title) => (
        <Tooltip placement="topLeft" title={zone_title}>
          {zone_title}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 150,
      render: (text, record) => (
        <button
          className={
            record.is_active === true
              ? "bg-button-green active_btns"
              : "bg-button-red  inactive_btns"
          }
          onClick={() => handleStatus(record.zone_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Location",
      dataIndex: "locationonwall",
      key: "locationonwall",
      width: 120,
      // width: "10%",
    },
    // {
    //   title: "Hero Image",
    //   dataIndex: "heroimage",
    //   key: "heroimage",
    // },
    {
      title: "Assets",
      dataIndex: "assets_count",
      key: "assets_count",
      sorter: true,
      width: 100,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record) => (
        <>
          <span
          // style={{ color: "#ef0855" }}
          >
            {record?.upper}
          </span>
          /<span>{record?.lower}</span>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 100,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      width: 120,
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
      // width: "20%",
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (customername) => (
        <Tooltip placement="topLeft" title={customername}>
          {customername}
        </Tooltip>
      ),
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (eventdomain) => (
        <Tooltip placement="topLeft" title={eventdomain}>
          {eventdomain}
        </Tooltip>
      ),
    },

    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      align: "Center",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewzone/${record.zone_id}`}>
                <p className="options">
                  <EyeOutlined /> <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editzone/${record.zone_id}`}>
                <p className="options">
                  <EditOutlined /> <span className="">Edit Zone</span>
                </p>
              </Link>
              <p className="options">
                <DeleteOutlined /> <span className=""> Delete Zone</span>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 60,
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editzone/${record.zone_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("sorter", sorter);

    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };

    dispatch(getZone(sortObj));
  };
  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  console.log("fields in zonelisting", fields);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Zone Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Zone Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="createnewzone">
            <Button className="rounded px-3 py-1 m-2">
              <span className="fw-bold"> + Create New Zone </span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-75">
            <Input
              size="medium"
              placeholder="Search by Cust. Name, Event Name, Brand Name, Creator Name, Zone Name, User Name"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>
          <div className="d-flex">
            <Button
              className=" rounded"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>
          </div>
        </div>

        <div className="mb-2">
          {showFilter ? (
            <div>
              <Select
                name="customerName"
                placeholder="Customer Name"
                style={{
                  width: 260,
                }}
                onChange={(val) => handleFieldsChange(val, "customerName")}
                allowClear
                showSearch
                listHeight={120}
                listItemHeight={4}
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                name="eventName"
                placeholder="Event Name"
                className="ms-2"
                style={{
                  width: 260,
                }}
                onChange={(val) => handleFieldsChange(val, "eventName")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>

              <Select
                name="brandName"
                placeholder="Brand Name"
                className="ms-2"
                style={{
                  width: 260,
                }}
                onChange={(val) => handleFieldsChange(val, "brandName")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                {brandList &&
                  brandList?.map((data) => {
                    return (
                      <Option value={data.brand_id}>{data.brand_title}</Option>
                    );
                  })}
              </Select>

              <Select
                name="eventName"
                placeholder="Creator Name"
                className="ms-2"
                // onChange={val =>
                //   handleFieldsChange(val, "eventName")
                // }
                style={{
                  width: 260,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                <option>Data dall</option>
              </Select>

              <Select
                name="tier"
                placeholder="Tier"
                className="ms-2"
                onChange={(val) => handleFieldsChange(val, "tier")}
                style={{
                  width: 260,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
              >
                {tierList &&
                  tierList?.map((data) => {
                    return <Option value={data.tier_id}>{data.tier}</Option>;
                  })}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default ZoneManagement;
