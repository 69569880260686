import React, { useEffect, useState, useRef } from "react";
import "../eventCalender.css";
import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Select,
  Switch,
  Button,
  DatePicker,
  InputNumber,
  Table,
  Empty,
} from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { blackbin } from "../../../components/Images/Images";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { useDispatch, useSelector } from "react-redux";
import { getZone } from "../../../redux/slice/zoneManagementSlice";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import {
  getNetworkLoungeSession,
  getCountryList,
  getJobTitles,
  getNetworkInvitationDetails,
  getAgentsForUser,
  getBrandDropdowns,
  getZoneDropdowns,
} from "../../../utils/commonApis";
import _, { cloneDeep, isEmpty } from "lodash";
import { mapSelectedDdlData } from "./modals.utils";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";
import {
  createNetworkSession,
  updateNetworkSession,
} from "../../../redux/slice/eventCalender";
import { toast } from "react-toastify";
import { dialogs } from "../../../components/constsnts/string.constants";
import { ifTimeSlotAvailable } from "../eventCalender.utils";
// import { EyeView } from "../../../components/svgJS/EyeView";

const NetworkingSession = (props) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const format = "HH:mm";
  const { rows: zoneRow } = useSelector((state) => state.zoneManagement.data);
  const validator = useRef(new SimpleReactValidator());
  const validatorAddBrand = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);

  const [
    networking_lounge_session_details,
    set_networking_lounge_session_details,
  ] = useState({
    is_active: true,
    start_time: moment(moment().format("HH:mm"), TIME_FORMAT),
    end_time: moment(moment().format("HH:mm"), TIME_FORMAT),
    session_title: "",
    session_description: "",
    is_iCal_enabled: false,
    is_iCal_download_enabled: false,
    is_speed_dating_enabled: false,
    is_waterfall_intros_enabled: false,
    seats_per_table: 0,
    seconds_per_intro: 0,
    minutes_per_shuffle: 0,
  });

  const [restricted_users, set_restricted_users] = useState([]);
  const [selected_restricted_users, set_selected_restricted_users] = useState(
    []
  );
  const [final_restricted_users, set_final_restricted_users] = useState([]);
  const [restricted_brands, set_restricted_brands] = useState([]);
  const [selected_restricted_brands, set_selected_restricted_brands] = useState(
    []
  );
  const [final_restricted_brands, set_final_restricted_brands] = useState([]);
  const [restricted_countries, set_restricted_countries] = useState([]);
  const [selected_restricted_countries, set_selected_restricted_countries] =
    useState([]);
  const [final_restricted_countries, set_final_restricted_countries] = useState(
    []
  );
  const [restricted_job_titles, set_restricted_job_titles] = useState([]);
  const [selected_restricted_job_titles, set_selected_restricted_job_titles] =
    useState([]);
  const [final_restricted_job_titles, set_final_restricted_job_titles] =
    useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [countryCollection, setCountryCollection] = useState([]);
  const [jobTitleCollection, setJobTitleCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const defaultBlankNewBrand = {
    brand_id: null,
    sponsorship_id: "",
    networking_configuration_id: "",
    networking_session_id: "",
    brand_title: "",
    zone_id: null,
    is_chat_enabled: false,
    is_demo_room_enabled: false,
    is_assets_enabled: false,
    is_booth_link_enabled: false,
    is_video_call_enabled: false,
    is_delete: false,
    sequence: 0,
  };
  const [sponsor_brand_details, set_sponsor_brand_details] = useState([]);
  const [add_new_brand, set_add_new_brand] = useState(defaultBlankNewBrand);
  const [invitation_setup, set_invitation_setup] = useState({
    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: undefined,
    is_scheduled: false,
  });
  const [invited_brands, set_invited_brands] = useState([]);
  const [invited_zones, set_invited_zones] = useState([]);
  const [invited_user_types, set_invited_user_types] = useState([]);
  const [invited_countries, set_invited_countries] = useState([]);
  const [invited_job_titles, set_invited_job_titles] = useState([]);
  const [loader, setLoader] = useState(true);
  const [networkSessionAllData, setNetLoungeSessionAllData] = useState({});
  const [previous_pending_invites, set_previous_pending_invites] = useState([]);
  const [invitationModal, setInvitationModal] = useState({
    isOpen: false,
    data: null,
    isLoader: false,
  });
  const [invitationTableData, setInvitationTableData] = useState([]);
  const [delete_scheduled_invitations, set_delete_scheduled_invitations] =
    useState([]);

  useEffect(async () => {
    const agentListAPI = await getAgentsForUser(props.sessionData.event_id);
    setAgentsCollectionData(agentListAPI);
    const brandListAPI = await getBrandDropdowns(props.sessionData.event_id);
    setBrandsCollection(brandListAPI);
    const countryListAPI = await getCountryList();
    const jobTitlesListAPI = await getJobTitles();
    const zoneListAPI = await getZoneDropdowns(props.sessionData.event_id);
    setZoneCollection(zoneListAPI);

    console.log("props.sessionData = ", props.sessionData);
    if (props.sessionData) {
      let sessionAPIData = await getNetworkLoungeSession({
        networking_configuration_id:
          props.sessionData.networking_configuration_id,
        networking_session_id: props.sessionData.networking_session_id,
      });
      console.log("sessionAPIData = ", sessionAPIData);
      setNetLoungeSessionAllData(sessionAPIData);
      set_previous_pending_invites(sessionAPIData.previous_pending_invites);
      setTimeout(() => {
        setLocalStateData({
          sessionAPIData,
          agentListAPI,
          brandListAPI,
          countryListAPI,
          jobTitlesListAPI,
        });
      }, 500);
    } else setLoader(false);
  }, []);

  async function setLocalStateData(localProps) {
    const { sessionAPIData } = localProps;
    if (sessionAPIData.networking_lounge_session_details) {
      let sessData = sessionAPIData.networking_lounge_session_details;
      sessData.start_time = moment(sessData.start_time, TIME_FORMAT);
      sessData.end_time = moment(sessData.end_time, TIME_FORMAT);
      console.log("sessData networking_lounge_session_details = ", sessData);
      sessData.minutes_per_shuffle = sessData.minutes_per_shuffle ?? 0;
      set_networking_lounge_session_details(sessData);
    }

    if (sessionAPIData.invited_brands)
      set_invited_brands(sessionAPIData.invited_brands);

    if (sessionAPIData.invited_zones)
      set_invited_zones(sessionAPIData.invited_zones);

    if (sessionAPIData.invited_user_types)
      set_invited_user_types(sessionAPIData.invited_user_types);

    if (sessionAPIData.invited_countries)
      set_invited_countries(sessionAPIData.invited_countries);

    if (sessionAPIData.invited_job_titles)
      set_invited_job_titles(sessionAPIData.invited_job_titles);

    if (sessionAPIData.sponsor_brand_details)
      set_sponsor_brand_details(sessionAPIData.sponsor_brand_details);

    mapAllDDL(localProps);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }

  const mapAllDDL = async (localProps) => {
    const { sessionAPIData, agentListAPI, brandListAPI } = localProps;
    const { countryListAPI, jobTitlesListAPI } = localProps;
    const dataHere = sessionAPIData;

    if (agentListAPI) {
      if (dataHere && !isEmpty(dataHere.restricted_users)) {
        set_final_restricted_users(dataHere.restricted_users);
        const clonedAgents = cloneDeep(agentListAPI);
        let abc = await mapSelectedDdlData(
          dataHere.restricted_users,
          selected_restricted_users,
          clonedAgents,
          "user_id"
        )
          .then((agentsToMap) => {
            console.log("agentsToMap 11 = ", agentsToMap);
            if (agentsToMap && agentsToMap.mainList)
              set_restricted_users(agentsToMap.mainList);
            if (agentsToMap && agentsToMap.selectedList)
              set_selected_restricted_users(agentsToMap.selectedList);
          })
          .catch((err) => {
            // console.log("agentsToMap 22 = ", err);
          });
      } else {
        const clonedAgents = cloneDeep(agentListAPI);
        set_restricted_users(clonedAgents);
      }
    }

    if (dataHere && !isEmpty(dataHere.restricted_brands)) {
      set_final_restricted_brands(dataHere.restricted_brands);
      const clonedBrands = cloneDeep(brandListAPI);
      let abc = await mapSelectedDdlData(
        dataHere.restricted_brands,
        selected_restricted_brands,
        clonedBrands,
        "brand_id"
      )
        .then((brandsToMap) => {
          // console.log("brandsToMap 11 = ", brandsToMap);
          if (brandsToMap && brandsToMap.mainList)
            set_restricted_brands(brandsToMap.mainList);
          if (brandsToMap && brandsToMap.selectedList)
            set_selected_restricted_brands(brandsToMap.selectedList);
        })
        .catch((err) => {
          // console.log("brandsToMap 22 = ", err);
        });
    } else set_restricted_brands(brandListAPI);

    if (
      props.sessionData.currentEventData &&
      props.sessionData.currentEventData.registration_and_communication_details
    ) {
      if (countryListAPI) {
        let event_country_ids =
          props.sessionData.currentEventData
            .registration_and_communication_details.country_collection_ids;
        console.log("event_country_ids = ", event_country_ids);
        if (event_country_ids) {
          let countriesOfEvents = [];
          countryListAPI.forEach((ele) => {
            if (event_country_ids.includes(ele.country_id))
              countriesOfEvents.push(ele);
          });
          console.log("countriesOfEvents = ", countriesOfEvents);
          if (countriesOfEvents) {
            setCountryCollection(countriesOfEvents);

            if (dataHere && !isEmpty(dataHere.restricted_countries)) {
              set_final_restricted_countries(dataHere.restricted_countries);
              let abc = await mapSelectedDdlData(
                dataHere.restricted_countries,
                selected_restricted_countries,
                countriesOfEvents,
                "country_id"
              )
                .then((countriesToMap) => {
                  console.log("countriesToMap 11 = ", countriesToMap);
                  if (countriesToMap && countriesToMap.mainList)
                    set_restricted_countries(countriesToMap.mainList);
                  if (countriesToMap && countriesToMap.selectedList)
                    set_selected_restricted_countries(
                      countriesToMap.selectedList
                    );
                })
                .catch((err) => {
                  // console.log("countriesToMap 22 = ", err);
                });
            } else set_restricted_countries(countriesOfEvents);
          }
        }
      }

      if (jobTitlesListAPI) {
        let event_job_titles_ids =
          props.sessionData.currentEventData
            .registration_and_communication_details.job_title_collection_ids;
        console.log("event_job_titles_ids = ", event_job_titles_ids);
        if (event_job_titles_ids) {
          let titlesOfEvents = [];
          jobTitlesListAPI.forEach((ele) => {
            if (event_job_titles_ids.includes(ele.job_title_id))
              titlesOfEvents.push(ele);
          });
          console.log("titlesOfEvents = ", titlesOfEvents);
          if (titlesOfEvents) {
            setJobTitleCollection(titlesOfEvents);
            if (dataHere && !isEmpty(dataHere.restricted_job_titles)) {
              set_final_restricted_job_titles(dataHere.restricted_job_titles);
              let abc = await mapSelectedDdlData(
                dataHere.restricted_job_titles,
                selected_restricted_job_titles,
                titlesOfEvents,
                "job_title_id"
              )
                .then((jobTitlesToMap) => {
                  console.log("jobTitlesToMap 11 = ", jobTitlesToMap);
                  if (jobTitlesToMap && jobTitlesToMap.mainList)
                    set_restricted_job_titles(jobTitlesToMap.mainList);
                  if (jobTitlesToMap && jobTitlesToMap.selectedList)
                    set_selected_restricted_job_titles(
                      jobTitlesToMap.selectedList
                    );
                })
                .catch((err) => {
                  // console.log("jobTitlesToMap 22 = ", err);
                });
            } else set_restricted_job_titles(titlesOfEvents);
          }
        }
      }
    }
  };

  async function handleDDLChange(value, key, leftOrRight) {
    let leftDDL, rightDDL, index;

    if (key === "restricted_users") {
      leftDDL = restricted_users;
      rightDDL = selected_restricted_users;
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.user_id === value);
      else index = rightDDL.findIndex((ele) => ele.user_id === value);
    }

    if (key === "restricted_brands") {
      leftDDL = restricted_brands;
      rightDDL = selected_restricted_brands;
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.brand_id === value);
      else index = rightDDL.findIndex((ele) => ele.brand_id === value);
    }

    if (key === "restricted_countries") {
      leftDDL = restricted_countries;
      rightDDL = selected_restricted_countries;
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.country_id === value);
      else index = rightDDL.findIndex((ele) => ele.country_id === value);
    }

    if (key === "restricted_job_titles") {
      leftDDL = restricted_job_titles;
      rightDDL = selected_restricted_job_titles;
      if (leftOrRight === "left")
        index = leftDDL.findIndex((ele) => ele.job_title_id === value);
      else index = rightDDL.findIndex((ele) => ele.job_title_id === value);
    }

    if (leftOrRight === "left") {
      rightDDL.push(leftDDL[index]);
      leftDDL.splice(index, 1);
    } else {
      leftDDL.push(rightDDL[index]);
      rightDDL.splice(index, 1);
    }

    assignDDLData(leftDDL, rightDDL, key);
  }

  function assignDDLData(leftDDL, rightDDL, key) {
    let onlyIdArr;

    if (key === "restricted_users") {
      onlyIdArr = selected_restricted_users.map((elem, index) => elem.user_id);
      set_restricted_users(leftDDL);
      set_selected_restricted_users(rightDDL);
      set_final_restricted_users(onlyIdArr);
    }

    if (key === "restricted_brands") {
      onlyIdArr = selected_restricted_brands.map(
        (elem, index) => elem.brand_id
      );
      set_restricted_brands(leftDDL);
      set_selected_restricted_brands(rightDDL);
      set_final_restricted_brands(onlyIdArr);
    }

    if (key === "restricted_countries" && selected_restricted_countries) {
      onlyIdArr = selected_restricted_countries.map(
        (elem, index) => elem.country_id
      );
      set_restricted_countries(leftDDL);
      set_selected_restricted_countries(rightDDL);
      set_final_restricted_countries(onlyIdArr);
    }

    if (key === "restricted_job_titles") {
      onlyIdArr = selected_restricted_job_titles.map(
        (elem, index) => elem.job_title_id
      );
      set_restricted_job_titles(leftDDL);
      set_selected_restricted_job_titles(rightDDL);
      set_final_restricted_job_titles(onlyIdArr);
    }
  }

  const createUpdateAPI = async (dataToUpdate) => {
    console.log("dataToUpdate/create = ", dataToUpdate);
    console.log("json data = ", JSON.stringify(dataToUpdate));
    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToUpdate));

    if (dataToUpdate.networking_session_id) {
      console.log("update case ");
      const updateRes = await dispatch(updateNetworkSession(formData));
      console.log("update response", updateRes);
      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error update network");
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      console.log("create case ");
      const createRes = await dispatch(createNetworkSession(formData));
      console.log("create network response", createRes);

      if (createRes?.payload?.data?.code === 200) {
        console.log("in 200 create network", createRes);
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error create network");
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    if (validator.current.allValid()) {
      let dataToUpdate = cloneDeep(networkSessionAllData);

      let new_audi_session_details = cloneDeep(
        networking_lounge_session_details
      );
      let dataToCheckTime = cloneDeep(networking_lounge_session_details);
      new_audi_session_details.start_time =
        props.sessionData.currentDateForCalender +
        " " +
        moment(new_audi_session_details.start_time).format("HH:mm:ss");
      new_audi_session_details.end_time =
        props.sessionData.currentDateForCalender +
        " " +
        moment(new_audi_session_details.end_time).format("HH:mm:ss");
      dataToUpdate.networking_lounge_session_details = new_audi_session_details;

      if (sponsor_brand_details) {
        dataToUpdate.sponsor_brand_details = sponsor_brand_details;
      }

      dataToUpdate.restricted_users = final_restricted_users;
      dataToUpdate.restricted_brands = final_restricted_brands;
      dataToUpdate.restricted_countries = final_restricted_countries;
      dataToUpdate.restricted_job_titles = final_restricted_job_titles;

      dataToUpdate.invited_brands = invited_brands;
      dataToUpdate.invited_zones = invited_zones;
      dataToUpdate.invited_user_types = invited_user_types;
      dataToUpdate.invited_countries = invited_countries;
      dataToUpdate.invited_job_titles = invited_job_titles;
      dataToUpdate.invitation_setup = invitation_setup;

      if (delete_scheduled_invitations)
        dataToUpdate.delete_scheduled_invitations =
          delete_scheduled_invitations;

      dataToUpdate.event_id = props.sessionData.event_id;
      dataToUpdate.networking_configuration_id =
        props.sessionData.networking_configuration_id;
      dataToUpdate.networking_session_id =
        props.sessionData.networking_session_id ?? "";
      console.log("data to update 111 - ", dataToUpdate);
      if (
        dataToUpdate.networking_lounge_session_details.start_time ===
        dataToUpdate.networking_lounge_session_details.end_time
      )
        toast.error("Please change end time");
      else if (
        !ifTimeSlotAvailable(
          moment(dataToCheckTime.start_time),
          moment(dataToCheckTime.end_time),
          props.sessionData.existingTimings
        )
      ) {
        toast.error("Timeslot is not available");
      } else {
        if (
          !dataToUpdate.sponsor_brand_details ||
          isEmpty(dataToUpdate.sponsor_brand_details)
        )
          toast.error("Please add a Sponsoring Brand");
        else if (
          dataToUpdate.networking_lounge_session_details
            .is_speed_dating_enabled &&
          dataToUpdate.networking_lounge_session_details.minutes_per_shuffle ===
            0
        ) {
          toast.error(
            "Please add minutes per shuffle as speed dating is enabled"
          );
        } else if (
          dataToUpdate.networking_lounge_session_details
            .is_waterfall_intros_enabled &&
          dataToUpdate.networking_lounge_session_details.seconds_per_intro === 0
        ) {
          toast.error(
            "Please add seconds per intro as waterfall intros is enabled"
          );
        } else if (
          !invitation_setup.is_scheduled &&
          !invitation_setup.send_invitation_now
        )
          toast.error("Please select time period for sending invitations");
        else createUpdateAPI(dataToUpdate);
      }
    } else {
      toast.error(dialogs.missingFields);
      validator.current.showMessages();
      let abd = validator.current.getErrorMessages();
      console.log("abd = ", abd);
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const handleSubmitAddBrand = () => {
    if (validatorAddBrand.current.allValid()) {
      //add the data to brands drag and drop table
      //and reset the add new table data
      console.log("add_new_brand going to create = ", add_new_brand);
      let cloned_add_new_brand = cloneDeep(add_new_brand);
      let cloned_sponsor_brand_details = cloneDeep(sponsor_brand_details);
      cloned_add_new_brand.sponsorship_id = "";
      cloned_add_new_brand.networking_session_id = "";
      cloned_add_new_brand.networking_configuration_id = "";
      cloned_add_new_brand.is_delete = false;

      if (
        !cloned_sponsor_brand_details ||
        isEmpty(cloned_sponsor_brand_details)
      ) {
        cloned_add_new_brand.sequence = 1;
        cloned_sponsor_brand_details.push(cloned_add_new_brand);
      } else {
        let tempArr = cloned_sponsor_brand_details;
        cloned_sponsor_brand_details = [];
        tempArr.forEach((ele) => {
          if (ele.is_delete === false)
            cloned_sponsor_brand_details.push(cloned_add_new_brand);
          else cloned_sponsor_brand_details.push(ele);
        });
      }

      console.log(
        "cloned_sponsor_brand_details = ",
        cloned_sponsor_brand_details
      );

      set_sponsor_brand_details(cloned_sponsor_brand_details);
      set_add_new_brand(defaultBlankNewBrand);
    } else {
      validatorAddBrand.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const onChangeFields = (val, key, parentKey) => {
    if (parentKey === "networking_lounge_session_details")
      if (
        (key === "is_waterfall_intros_enabled" ||
          key === "is_speed_dating_enabled") &&
        val === false
      ) {
        let newValues;
        if (key === "is_waterfall_intros_enabled")
          newValues = { seconds_per_intro: 0 };
        else newValues = { minutes_per_shuffle: 0 };
        set_networking_lounge_session_details({
          ...networking_lounge_session_details,
          ...newValues,
          [key]: val,
        });
      } else {
        set_networking_lounge_session_details({
          ...networking_lounge_session_details,
          [key]: val,
        });
      }

    if (parentKey === "add_new_brand")
      set_add_new_brand({ ...add_new_brand, [key]: val });

    if (parentKey === "invitation_setup") {
      let cloned_invitation_setup = cloneDeep(invitation_setup);
      if (key === "is_scheduled") {
        cloned_invitation_setup.is_scheduled = val;
        cloned_invitation_setup.send_invitation_now = false;
        set_invitation_setup(cloned_invitation_setup);
      } else if (key === "send_invitation_now") {
        cloned_invitation_setup.is_scheduled = false;
        cloned_invitation_setup.send_invitation_now = val;
        set_invitation_setup(cloned_invitation_setup);
      } else {
        set_invitation_setup({ ...cloned_invitation_setup, [key]: val });
      }
    }
  };

  const renderSwitches = (key, parentKey, checked) => {
    return (
      <Switch
        checked={checked}
        onChange={(val) => onChangeFields(val, key, parentKey)}
        className="adminSatellite_switch"
      />
    );
  };

  const sponsor_brand_details_columns = [
    {
      title: "Brand Title",
      dataIndex: "brand_title",
      width: "100%",
      render: (_, ele, indexToDelete) => {
        if (ele && ele.brand_title && !ele.is_delete)
          return (
            <div className="d-flex flex-column">
              <Row className="adminSatellite_section_2_brand_row1">
                <Col span={23}>
                  <p className="adminSatellite_section_2_brand_name">
                    {ele.brand_title}
                  </p>
                </Col>
                <Col span={1}>
                  <img
                    style={{ marginRight: "10.79px" }}
                    className="adminNetworking_delete_icon"
                    src={blackbin}
                    onClick={() => {
                      console.log("index to delte = ", indexToDelete);
                      console.log(
                        "sponsor_brand_details = ",
                        sponsor_brand_details
                      );
                      let clonedDataSource = cloneDeep(sponsor_brand_details);
                      // clonedDataSource.splice(indexToDelete, 1);
                      clonedDataSource[indexToDelete].is_delete = true;
                      clonedDataSource[indexToDelete].sequence = null;

                      let finalData = [];
                      clonedDataSource &&
                        clonedDataSource.forEach((ele, index) => {
                          if (!ele.is_delete)
                            finalData.push({ ...ele, sequence: index });
                        });

                      finalData.push(clonedDataSource[indexToDelete]);
                      console.log("finalData final = ", finalData);

                      set_sponsor_brand_details(finalData);
                    }}
                  />
                </Col>
              </Row>
            </div>
          );
        else return null;
      },
    },
  ];

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type - b.user_type,
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return getStatusButton(record);
      },
      // sorter: (a, b) => a.rsvp_type - b.rsvp_type,
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });
    let params = {
      networking_configuration_id: ele.networking_configuration_id,
      networking_session_id: ele.networking_session_id,
    };
    if (isFromSingle)
      params.networking_lounge_invitation_setup_id =
        ele.networking_lounge_invitation_setup_id;
    console.log("params = ", params);
    let invitationData = await getNetworkInvitationDetails(params);
    setInvitationTableData(invitationData);
  };

  return (
    <Modal
      title={props.title}
      visible={true}
      onOk={() => props.onCloseModal(false)}
      onCancel={() => props.onCloseModal(false)}
      width={950}
      footer={[
        <Button
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "25%" }}
          onClick={() => {
            validator.current.purgeFields();
            handleSubmit();
          }}
        >
          Save Changes & Send Invitations
        </Button>,
      ]}
    >
      {loader ? (
        <CommonLoader />
      ) : (
        <>
          <div className="adminNetworking_section_1_form">
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Active</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_active",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_active
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Start & End Time
                  <RedAsterick />
                </p>
              </Col>
              <Col span={8}>
                <TimePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="start_time"
                  id="start_time"
                  format={format}
                  value={networking_lounge_session_details.start_time}
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "start_time",
                      "networking_lounge_session_details"
                    );
                  }}
                  onBlur={() => validator.current.showMessageFor("start_time")}
                />
                {validator.current.message(
                  "",
                  networking_lounge_session_details.start_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>

              <Col span={8} offset={2}>
                <TimePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="end_time"
                  id="end_time"
                  format={format}
                  value={networking_lounge_session_details.end_time}
                  disabledDate={(d) =>
                    !d ||
                    d.isSameOrBefore(
                      networking_lounge_session_details.start_time
                    )
                  }
                  className="expohall_timepicker"
                  style={{ width: "100%" }}
                  onSelect={(val) => {
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_time",
                      "networking_lounge_session_details"
                    );
                  }}
                  onBlur={() => validator.current.showMessageFor("end_time")}
                />
                {validator.current.message(
                  "",
                  networking_lounge_session_details.end_time,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Title
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <Input
                  name="session_title"
                  className="adminNetworking_inputTitle"
                  placeholder="Enter a Title here"
                  value={networking_lounge_session_details.session_title}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_title",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor(
                      "networking_lounge_session_details"
                    )
                  }
                  minLength={1}
                  maxLength={30}
                />
                {validator.current.message(
                  "Title",
                  networking_lounge_session_details.session_title,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p style={{ marginTop: "9px" }}>
                  Description
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="session_description"
                  value={networking_lounge_session_details.session_description}
                  className="adminNetworking_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "session_description",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("session_description")
                  }
                  minLength={1}
                  maxLength={200}
                />
                {validator.current.message(
                  "Description",
                  networking_lounge_session_details.session_description,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Seats per table</p>
              </Col>
              <Col span={18}>
                <InputNumber
                  name="seats_per_table"
                  min={1}
                  max={6}
                  maxLength={1}
                  defaultValue={
                    networking_lounge_session_details.seats_per_table
                  }
                  onChange={(val) =>
                    onChangeFields(
                      val,
                      "seats_per_table",
                      "networking_lounge_session_details"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("seats_per_table")
                  }
                  className="adminNetworking_section_1_inputSeat"
                />
                {validator.current.message(
                  "Seats per table",
                  networking_lounge_session_details.seats_per_table,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Speed Dating</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_speed_dating_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_speed_dating_enabled
                )}
              </Col>
            </Row>
            {networking_lounge_session_details.is_speed_dating_enabled ? (
              <Row className="modal_row">
                <Col span={6} className="modal_field_label">
                  <p>Mins Per Shuffle</p>
                </Col>
                <Col span={18}>
                  <InputNumber
                    name="minutes_per_shuffle"
                    min={1}
                    max={10}
                    value={
                      networking_lounge_session_details.minutes_per_shuffle
                    }
                    className="adminNetworking_section_1_inputSeat"
                    onChange={(val) =>
                      onChangeFields(
                        val,
                        "minutes_per_shuffle",
                        "networking_lounge_session_details"
                      )
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("minutes_per_shuffle")
                    }
                  />
                  {validator.current.message(
                    "Mins Per Shuffle",
                    networking_lounge_session_details.minutes_per_shuffle,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            ) : null}
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Waterfall Intros</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_waterfall_intros_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_waterfall_intros_enabled
                )}
              </Col>
            </Row>
            {networking_lounge_session_details.is_waterfall_intros_enabled ? (
              <Row className="modal_row">
                <Col span={6} className="modal_field_label">
                  <p>Seconds Per Intro</p>
                </Col>
                <Col span={18}>
                  <InputNumber
                    name="seconds_per_intro"
                    min={0}
                    max={10}
                    value={networking_lounge_session_details.seconds_per_intro}
                    onChange={(val) =>
                      onChangeFields(
                        val,
                        "seconds_per_intro",
                        "networking_lounge_session_details"
                      )
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("seconds_per_intro")
                    }
                    className="adminNetworking_section_1_inputSeat"
                  />
                  {validator.current.message(
                    "Mins Per Shuffle",
                    networking_lounge_session_details.seconds_per_intro,
                    "required",
                    {
                      className: "text-danger py-1",
                    }
                  )}
                </Col>
              </Row>
            ) : null}

            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Download iCal</p>
              </Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {renderSwitches(
                  "is_iCal_download_enabled",
                  "networking_lounge_session_details",
                  networking_lounge_session_details.is_iCal_download_enabled
                )}
              </Col>
            </Row>
          </div>
          <div className="adminNetworking_section_2_form">
            <Row>
              <Col span={24}>
                <h5 className="adminNetworking_section_2_title fs-pink">
                  Sponsoring Brand
                  <RedAsterick />
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="adminNetworking_section_2_info">
                  NB: The brands of speakers will automatically be added to the
                  list below, you can remove them ({" "}
                  <img className="adminNetworking_delete_icon" src={blackbin} />{" "}
                  ) or add more brands here.
                </p>
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={6} className="modal_field_label">
                <p>Assigned Brands</p>
              </Col>
              <Col span={18} className="adminNetworking_fields">
                {sponsor_brand_details && !isEmpty(sponsor_brand_details) ? (
                  <Table
                    columns={sponsor_brand_details_columns}
                    pagination={false}
                    dataSource={sponsor_brand_details}
                    showHeader={false}
                    bordered={false}
                    rowClassName={"brand_row"}
                    scroll={{ y: 240 }}
                  />
                ) : (
                  <Empty description="No Data Found" />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={8} className="modal_field_label">
                <h6 className="addBrandTitle">Add a Sponsor Brand</h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Brand</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="brand_id"
                  optionFilterProp="children"
                  placeholder="Select Brand"
                  className="w-100 fs-bold-14"
                  value={
                    add_new_brand.brand_id ? add_new_brand.brand_id : undefined
                  }
                  onChange={(e) => {
                    onChangeFields(e, "brand_id", "add_new_brand");
                    let cloned_add_new_brand = add_new_brand;
                    cloned_add_new_brand.brand_id = e;
                    let itemSelected = brandsCollection.find(
                      (item) => item.brand_id === e
                    );
                    cloned_add_new_brand.brand_title = itemSelected.brand_title;
                    set_add_new_brand(cloned_add_new_brand);
                    dispatch(getZone({ brand_id: e }));
                  }}
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor("brand_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                >
                  {brandsCollection &&
                    brandsCollection.map((item, i) => {
                      return (
                        <Option
                          key={item.brand_id + "_brand"}
                          value={item.brand_id}
                        >
                          {item.brand_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Brand Name",
                  add_new_brand.brand_id,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Zone</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="zone_id"
                  bordered={false}
                  className="adminSatellite_selectZone"
                  placeholder="Select Zone"
                  value={
                    add_new_brand.zone_id ? add_new_brand.zone_id : undefined
                  }
                  onChange={(val) =>
                    onChangeFields(val, "zone_id", "add_new_brand")
                  }
                  onBlur={() =>
                    validatorAddBrand.current.showMessageFor("zone_id")
                  }
                >
                  {zoneRow &&
                    zoneRow.map((item, i) => {
                      return (
                        <Option
                          key={"add_new_brand" + item.zone_id}
                          value={item.zone_id}
                        >
                          {item.zone_title}
                        </Option>
                      );
                    })}
                </Select>
                {validatorAddBrand.current.message(
                  "Zone",
                  add_new_brand.zone_id,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Chat</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_chat_enabled",
                  "add_new_brand",
                  add_new_brand.is_chat_enabled
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Demo Room</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_demo_room_enabled",
                  "add_new_brand",
                  add_new_brand.is_demo_room_enabled
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Asset/s</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_assets_enabled",
                  "add_new_brand",
                  add_new_brand.is_assets_enabled
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Booth Link</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_booth_link_enabled",
                  "add_new_brand",
                  add_new_brand.is_booth_link_enabled
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="adminSatellite_fs-14">Video Call</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_video_call_enabled",
                  "add_new_brand",
                  add_new_brand.is_video_call_enabled
                )}
              </Col>
            </Row>
            <Row>
              <Col span={6} />
              <Col span={6}>
                <Button
                  type="primary"
                  className="adminSatellite_section_2_savechanges"
                  size={"large"}
                  onClick={handleSubmitAddBrand}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_7_form">
            <Row>
              <Col span={24}>
                <h5 className="adminSatellite_section_7_title fs-pink">
                  Restrict Access
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={24}>
                <p className="adminSatellite_section_3_info">
                  NB: Configure who can view / access your event.
                  <br /> Attendees with any black list attributes will not be
                  able to to see the event on any calendars or access the event.
                  <br /> If an attendee/attribute is on the White List they will
                  be able to see/attaned the event regardless of any black list
                  attributes.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  White List Users
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Black List Users
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Agents</p>
              </Col>
              <Col span={8}>
                <div
                  id="restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Agents"
                    open={true}
                    className="border border-gray fs-bold-14"
                    onChange={(val) =>
                      handleDDLChange(val, "restricted_users", "left")
                    }
                    getPopupContainer={() =>
                      document.getElementById("restricted_users_div")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Agents"
                  >
                    {restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_" + data.user_id}
                          value={data.user_id}
                          className="adminSatellite_section_4_taggedagents_item"
                        >
                          {data.first_name + " " + data.last_name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_users_div"
                  className="adminSatellite_section_5_taggedagents_container"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search Tagged Agents"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selected_restricted_users_div")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="Tagged Agents"
                  >
                    {selected_restricted_users?.map((data) => {
                      return (
                        <Option
                          key={"restricted_users_selected_" + data.user_id}
                          value={data.user_id}
                          className="adminSatellite_section_4_taggedagents_item"
                        >
                          {data.first_name + " " + data.last_name}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleDDLChange(
                                  data.user_id,
                                  "restricted_users",
                                  "right"
                                )
                              }
                            />
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Brand List</p>
              </Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  White List Brands
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Black List Brands
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div id="restricted_brands_div" className="dropdown_container">
                  <Select
                    id="restricted_brands"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) => {
                      const filteredData =
                        sponsor_brand_details &&
                        sponsor_brand_details.filter(
                          (item) => item.brand_id === value
                        );
                      if (filteredData && !isEmpty(filteredData))
                        toast.error(
                          "This brand cannot be blacklisted as it is in hosting brands"
                        );
                      else handleDDLChange(value, "restricted_brands", "left");
                    }}
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Brands"
                  >
                    {restricted_brands &&
                      restricted_brands.map((data) => (
                        <Option
                          key={"restricted_brands_" + data.brand_id}
                          value={data.brand_id}
                          className="dropdown_options"
                        >
                          <span>{data.brand_title}</span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_brands_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_brands"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_brands_div")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor(
                        "selected_restricted_brands"
                      )
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Brands"
                  >
                    {selected_restricted_brands &&
                      selected_restricted_brands.map((data) => (
                        <Option
                          key={"selected_restricted_brands_" + data.brand_id}
                          value={data.brand_id}
                          className="dropdown_options"
                        >
                          <span>
                            {data.brand_title}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.brand_id,
                                    "restricted_brands",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Country List</p>
              </Col>
              <Col span={8}></Col>
              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  White List Countries
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Black List Countries
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_countries", "left")
                    }
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_countries_div")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Countries"
                  >
                    {restricted_countries &&
                      restricted_countries.map((data) => (
                        <Option
                          key={"restricted_countries_" + data.country_id}
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          <span>{data.country}</span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_countries_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_countries"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_countries_div"
                      )
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="Tagged Countries"
                  >
                    {selected_restricted_countries &&
                      selected_restricted_countries.map((data) => (
                        <Option
                          key={
                            "selected_restricted_countries_" + data.country_id
                          }
                          value={data.country_id}
                          className="dropdown_options"
                        >
                          <span>
                            {data.country}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.country_id,
                                    "restricted_countries",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Job Titles List</p>
              </Col>
              <Col span={8}></Col>

              <Col span={8} offset={2}></Col>
            </Row>
            <Row>
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <h6 className="adminSatellite_userListTitle">
                  White List Job Titles
                </h6>
              </Col>

              <Col span={8} offset={2}>
                <h6 className="adminSatellite_userListTitle">
                  Black List Job Titles
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label"></Col>
              <Col span={8}>
                <div
                  id="restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    onChange={(value) =>
                      handleDDLChange(value, "restricted_job_titles", "left")
                    }
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("restricted_job_titles_div")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Job Titles"
                  >
                    {restricted_job_titles &&
                      restricted_job_titles.map((data) => (
                        <Option
                          key={"restricted_job_titles_" + data.job_title_id}
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          <span>{data.job_title}</span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={2}>
                <div
                  id="selected_restricted_job_titles_div"
                  className="dropdown_container"
                >
                  <Select
                    id="selected_restricted_job_titles"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Search to Select"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById(
                        "selected_restricted_job_titles_div"
                      )
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="All Job Titles"
                  >
                    {selected_restricted_job_titles &&
                      selected_restricted_job_titles.map((data) => (
                        <Option
                          key={
                            "selected_restricted_job_titles_" +
                            data.job_title_id
                          }
                          value={data.job_title_id}
                          className="dropdown_options"
                        >
                          <span>
                            {data.job_title}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() =>
                                  handleDDLChange(
                                    data.job_title_id,
                                    "restricted_job_titles",
                                    "right"
                                  )
                                }
                              />
                            </span>
                          </span>
                        </Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>

          <div className="adminNetworking_section_4_form">
            <Row>
              <Col span={24}>
                <h5 className="adminNetworking_section_4_title">
                  Previous & Pending Invitees
                </h5>
              </Col>
            </Row>
            {props.sessionData && props.sessionData.networking_session_id ? (
              <>
                {previous_pending_invites ? (
                  <Row className="modal_row">
                    <Col span={6} />
                    <Col span={9} className="adminSatellite_eyeViewBtn">
                      <Button
                        type="primary"
                        className="adminSatellite_section_8_previousInvitedBtn"
                        size={"large"}
                        onClick={() =>
                          openInviteesModalAndApiCall(
                            previous_pending_invites[0],
                            false
                          )
                        }
                      >
                        View List of Previously Invited
                      </Button>
                    </Col>
                  </Row>
                ) : null}

                <Row className="modal_row">
                  <Col span={6} />
                  <Col span={9}>
                    <h6 className="adminSatellite_section_8_heading">
                      Currently Scheduled Invitations
                    </h6>
                  </Col>
                </Row>
                {networkSessionAllData && previous_pending_invites ? (
                  previous_pending_invites.map((ele, index) => {
                    if (ele && !ele.is_delete)
                      return (
                        <Row className="modal_row">
                          <Col span={6} className="modal_field_label">
                            Date & Time
                          </Col>
                          <Col span={7} className="invite_center">
                            {ele.date_time_to_invite
                              ? moment(ele.date_time_to_invite).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </Col>
                          <Col span={9} offset={2}>
                            <Button
                              type="primary"
                              className="adminSatellite_section_8_viewListBtn"
                              size={"large"}
                              loading={invitationModal.isLoader}
                              onClick={async () =>
                                openInviteesModalAndApiCall(ele, true)
                              }
                            >
                              View List
                            </Button>
                            <img
                              style={{ marginLeft: "15px" }}
                              src={blackbin}
                              onClick={() => {
                                let clonedDeleteIds = cloneDeep(
                                  delete_scheduled_invitations
                                );
                                clonedDeleteIds.push(
                                  ele.networking_lounge_invitation_setup_id
                                );
                                set_delete_scheduled_invitations(
                                  clonedDeleteIds
                                );

                                let clonedData = cloneDeep(
                                  previous_pending_invites
                                );
                                clonedData[index].is_delete = true;
                                set_previous_pending_invites(clonedData);
                              }}
                            />
                          </Col>
                        </Row>
                      );
                  })
                ) : (
                  <Empty description={"No Scheduled Invitations"} />
                )}
              </>
            ) : (
              <Empty description="No invitees" />
            )}
          </div>
          <Modal
            title={<div className="invite_center">{"Previously Invited"}</div>}
            visible={invitationModal.isOpen}
            width={650}
            onOk={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            onCancel={() =>
              setInvitationModal({ isOpen: false, data: null, isLoader: false })
            }
            footer={null}
          >
            <div className="px-3">
              <Table
                columns={invitation_table_columns}
                pagination={false}
                dataSource={invitationTableData}
                bordered={false}
                //   rowClassName={"brand_row"}
                scroll={{ y: 240 }}
                rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
              />
            </div>
          </Modal>
          <div
            className="adminNetworking_section_5_form"
            style={{ borderBottom: "1px solid #eae4f2" }}
          >
            <Row>
              <Col span={24}>
                <h5 className="adminNetworking_section_5_title fs-pink">
                  New Invitees
                </h5>
              </Col>
            </Row>

            <Row>
              <Col span={6}></Col>
              <Col span={9}>
                <h6 className="adminNetworking_section_5_heading">
                  Filter Attendees
                </h6>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Brand</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Select Brands"
                  value={invited_brands}
                  onChange={(val) => set_invited_brands(val)}
                >
                  {brandsCollection &&
                    brandsCollection.map((item, i) => {
                      return (
                        <Option
                          key={"invited_brands_" + item.brand_id}
                          value={item.brand_id}
                        >
                          {item.brand_title}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Zone</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Select Zones"
                  value={invited_zones}
                  onChange={(val) => set_invited_zones(val)}
                >
                  {zoneCollection &&
                    zoneCollection.map((item, i) => {
                      return (
                        <Option
                          key={"invited_zones" + item.zone_id}
                          value={item.zone_id}
                        >
                          {item.zone_title}
                        </Option>
                      );
                    })}{" "}
                </Select>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>User Type</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Select User Types"
                  value={invited_user_types}
                  onChange={(val) => set_invited_user_types(val)}
                >
                  <Option value="AGENT">Agent</Option>
                  <Option value="DELEGATE">Delegate</Option>
                </Select>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Country</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Select Countries"
                  value={invited_countries}
                  onChange={(val) => set_invited_countries(val)}
                >
                  {countryCollection &&
                    countryCollection.map((item, i) => {
                      return (
                        <Option
                          key={"invited_countries" + item.country_id}
                          value={item.country_id}
                        >
                          {item.country}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>Job Title</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Select Job Titles"
                  value={invited_job_titles}
                  onChange={(val) => set_invited_job_titles(val)}
                >
                  {jobTitleCollection &&
                    jobTitleCollection.map((item, i) => {
                      return (
                        <Option
                          key={"invited_job_titles" + item.job_title_id}
                          value={item.job_title_id}
                        >
                          <span>{item.job_title}</span>
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
          </div>
          <div className="adminSatellite_section_10_form">
            <Row>
              <Col span={24}>
                <h5 className="section_title section_theme_title fs-pink">
                  Invitation Setup
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p>
                  Title
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <Input
                  name="invitation_title"
                  className="adminSatellite_inputTitle"
                  placeholder="Enter a Title here"
                  value={invitation_setup.invitation_title}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "invitation_title",
                      "invitation_setup"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("invitation_title")
                  }
                  minLength={1}
                  maxLength={100}
                />
                {validator.current.message(
                  "Invitation title",
                  invitation_setup.invitation_title,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p style={{ alignSelf: "flex-start" }}>
                  Description
                  <RedAsterick />
                </p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="invitation_description"
                  className="adminSatellite_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  value={invitation_setup.invitation_description}
                  onChange={(e) =>
                    onChangeFields(
                      e.target.value,
                      "invitation_description",
                      "invitation_setup"
                    )
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("invitation_description")
                  }
                  minLength={0}
                  maxLength={200}
                />
                {validator.current.message(
                  "Invitation title",
                  invitation_setup.invitation_description,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Attach iCal</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_iCal_download_enabled",
                  "invitation_setup",
                  invitation_setup.is_iCal_download_enabled
                )}
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Re-invite</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_reinvite_enabled",
                  "invitation_setup",
                  invitation_setup.is_reinvite_enabled
                )}
                <span style={{ marginLeft: "30px" }}>(if already invited)</span>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={24}>
                <h5 className="section_title section_black_title">
                  When to Send Invitation
                </h5>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Scheduled</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "is_scheduled",
                  "invitation_setup",
                  invitation_setup.is_scheduled
                )}
              </Col>
            </Row>
            {invitation_setup.is_scheduled ? (
              <Row>
                <Col span={6} className="modal_field_label">
                  <p>
                    Date & Time
                    <RedAsterick />
                  </p>
                </Col>
                <Col span={7}>
                  <DatePicker
                    disabled={!invitation_setup.is_scheduled}
                    suffixIcon={<CalenderGrey width="15" />}
                    id="invitation_setup_date_time_to_invite"
                    name="invitation_setup_date_time_to_invite"
                    showTime
                    className="w-100"
                    disabledDate={(d) =>
                      !d ||
                      d.isSameOrBefore(invitation_setup?.date_time_to_invite)
                    }
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(invitation_setup?.date_time_to_invite)
                    }
                    value={moment(invitation_setup?.date_time_to_invite)}
                    format={"DD/MM/YYYY HH:mm"}
                    onChange={(e) =>
                      onChangeFields(
                        moment(e).format("YYYY-MM-DD HH:mm:ss"),
                        "date_time_to_invite",
                        "invitation_setup"
                      )
                    }
                    onBlur={() =>
                      validator.current.showMessageFor(
                        "invitation_setup_date_time_to_invite"
                      )
                    }
                  />
                  {invitation_setup.is_scheduled &&
                    isEmpty(invitation_setup.date_time_to_invite) &&
                    validator.current.message(
                      "Date & Time",
                      invitation_setup?.date_time_to_invite,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col span={6} className="modal_field_label"></Col>
              <Col span={7}>
                <span className="adminSatellite_or">OR</span>
              </Col>
            </Row>
            <Row className="modal_row">
              <Col span={6} className="modal_field_label">
                <p className="fs-14">Now</p>
              </Col>
              <Col span={18}>
                {renderSwitches(
                  "send_invitation_now",
                  "invitation_setup",
                  invitation_setup.send_invitation_now
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
    </Modal>
  );
};
export default NetworkingSession;
