import React from "react";

export const CalenderRed = () => {
  return (
    <>
      <svg
        id="_032-calendar-8"
        data-name="032-calendar-8"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="Group_1116" data-name="Group 1116">
          <g id="Group_1115" data-name="Group 1115">
            <path
              id="Path_630"
              data-name="Path 630"
              d="M19.219,13.75A.781.781,0,0,0,20,12.969V4.688a3.129,3.129,0,0,0-3.125-3.125H15.859V.781a.781.781,0,0,0-1.562,0v.781H10.742V.781a.781.781,0,0,0-1.562,0v.781H5.664V.781A.781.781,0,0,0,4.1.781v.781H3.125A3.129,3.129,0,0,0,0,4.688V16.875A3.129,3.129,0,0,0,3.125,20h13.75A3.129,3.129,0,0,0,20,16.875a.781.781,0,0,0-1.562,0,1.564,1.564,0,0,1-1.562,1.563H3.125a1.564,1.564,0,0,1-1.562-1.562V4.688A1.564,1.564,0,0,1,3.125,3.125H4.1v.781a.781.781,0,0,0,1.563,0V3.125H9.18v.781a.781.781,0,0,0,1.563,0V3.125H14.3v.781a.781.781,0,0,0,1.563,0V3.125h1.016a1.564,1.564,0,0,1,1.563,1.563v8.281A.781.781,0,0,0,19.219,13.75Z"
            />
            <circle
              id="Ellipse_31"
              data-name="Ellipse 31"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.516 10.636)"
            />
            <circle
              id="Ellipse_32"
              data-name="Ellipse 32"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.516 13.939)"
            />
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 7.333)"
            />
            <circle
              id="Ellipse_34"
              data-name="Ellipse 34"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.495 7.333)"
            />
            <circle
              id="Ellipse_35"
              data-name="Ellipse 35"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 7.333)"
            />
            <circle
              id="Ellipse_36"
              data-name="Ellipse 36"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 7.333)"
            />
            <circle
              id="Ellipse_37"
              data-name="Ellipse 37"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 13.947)"
            />
            <circle
              id="Ellipse_38"
              data-name="Ellipse 38"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 10.636)"
            />
            <circle
              id="Ellipse_39"
              data-name="Ellipse 39"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 10.636)"
            />
            <circle
              id="Ellipse_40"
              data-name="Ellipse 40"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 10.636)"
            />
            <circle
              id="Ellipse_41"
              data-name="Ellipse 41"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 13.939)"
            />
            <circle
              id="Ellipse_42"
              data-name="Ellipse 42"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 13.939)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const CalenderGrey = props => {
  return (
    <>
      <svg
        id="_032-calendar-8"
        data-name="032-calendar-8"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="Group_1116" data-name="Group 1116">
          <g id="Group_1115" data-name="Group 1115">
            <path
              id="Path_630"
              data-name="Path 630"
              d="M19.219,13.75A.781.781,0,0,0,20,12.969V4.688a3.129,3.129,0,0,0-3.125-3.125H15.859V.781a.781.781,0,0,0-1.562,0v.781H10.742V.781a.781.781,0,0,0-1.562,0v.781H5.664V.781A.781.781,0,0,0,4.1.781v.781H3.125A3.129,3.129,0,0,0,0,4.688V16.875A3.129,3.129,0,0,0,3.125,20h13.75A3.129,3.129,0,0,0,20,16.875a.781.781,0,0,0-1.562,0,1.564,1.564,0,0,1-1.562,1.563H3.125a1.564,1.564,0,0,1-1.562-1.562V4.688A1.564,1.564,0,0,1,3.125,3.125H4.1v.781a.781.781,0,0,0,1.563,0V3.125H9.18v.781a.781.781,0,0,0,1.563,0V3.125H14.3v.781a.781.781,0,0,0,1.563,0V3.125h1.016a1.564,1.564,0,0,1,1.563,1.563v8.281A.781.781,0,0,0,19.219,13.75Z"
            />
            <circle
              id="Ellipse_31"
              data-name="Ellipse 31"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.516 10.636)"
            />
            <circle
              id="Ellipse_32"
              data-name="Ellipse 32"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.516 13.939)"
            />
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 7.333)"
            />
            <circle
              id="Ellipse_34"
              data-name="Ellipse 34"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(7.495 7.333)"
            />
            <circle
              id="Ellipse_35"
              data-name="Ellipse 35"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 7.333)"
            />
            <circle
              id="Ellipse_36"
              data-name="Ellipse 36"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 7.333)"
            />
            <circle
              id="Ellipse_37"
              data-name="Ellipse 37"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 13.947)"
            />
            <circle
              id="Ellipse_38"
              data-name="Ellipse 38"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(4.192 10.636)"
            />
            <circle
              id="Ellipse_39"
              data-name="Ellipse 39"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 10.636)"
            />
            <circle
              id="Ellipse_40"
              data-name="Ellipse 40"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 10.636)"
            />
            <circle
              id="Ellipse_41"
              data-name="Ellipse 41"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(10.799 13.939)"
            />
            <circle
              id="Ellipse_42"
              data-name="Ellipse 42"
              cx="0.86"
              cy="0.86"
              r="0.86"
              transform="translate(14.102 13.939)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const TimeCalendar = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="_167-wall-clock" data-name="167-wall-clock" opacity="0.5">
          <g id="Group_1177" data-name="Group 1177">
            <g id="Group_1176" data-name="Group 1176">
              <path
                id="Path_49243"
                data-name="Path 49243"
                d="M17.071,2.929A10,10,0,0,0,2.929,17.071a10,10,0,0,0,12.245,1.488.781.781,0,1,0-.809-1.336,8.512,8.512,0,1,1,2.642-2.539.781.781,0,0,0,1.3.868A9.975,9.975,0,0,0,17.071,2.929Z"
              />
            </g>
          </g>
          <g
            id="Group_1179"
            data-name="Group 1179"
            transform="translate(6.132 2.958)"
          >
            <g id="Group_1178" data-name="Group 1178">
              <path
                id="Path_49244"
                data-name="Path 49244"
                d="M160.665,76a.778.778,0,0,0-.778.778v5.905l-2.854,2.854a.778.778,0,1,0,1.1,1.1l3.082-3.082a.778.778,0,0,0,.228-.55V76.778A.778.778,0,0,0,160.665,76Z"
                transform="translate(-156.804 -76)"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
