import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  registerEventList: {},
  loading: false,
};

export const registerEvent = createAsyncThunk(
  "registerEvent/registerEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/registrationandcommunication/create",
      "",
      ""
    );
    console.log("create response registerEvent", response);

    return response;
  }
);

export const registerEventSlice = createSlice({
  name: "registerEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [registerEvent.pending]: (state) => {
      state.loading = true;
    },
    [registerEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.registerEventList = payload;
    },
    [registerEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const registerEventReducer = registerEventSlice.reducer;
