import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Select, Switch, Input, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";
import { useMediaQuery } from "react-responsive";
import _, { isEmpty } from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { getAgents } from "../../../utils/commonApis";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import RedAsterick from "../../../components/Widgets/RedAsterick";

const SocialWall = ({
  socialWallData,
  onChangeData,
  onValid,
  initiateValidations,
}) => {
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const { Option } = Select;
  const { TextArea } = Input;
  const [agents, setAgents] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [dataLocal, setDataLocal] = useState({});

  useEffect(async () => {
    window.scrollTo(0, 0);
    const agentListAPI = await getAgents();
    setAgentsCollectionData(agentListAPI);
    console.log("socialWallData...11", socialWallData);
    setDataLocal(socialWallData);
  }, []);

  useEffect(() => {
    if (!isEmpty(socialWallData)) setDataLocal(socialWallData);
  }, [socialWallData]);

  useEffect(() => {
    if (!isEmpty(agentsCollectionData))
      setAgentsFromAPIAndUseEffect(socialWallData);
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    let agents_list = [];
    console.log(
      "dataHere.social_wall_moderators = ",
      dataHere.social_wall_moderators
    );
    if (dataHere && !isEmpty(dataHere.social_wall_moderators)) {
      dataHere.social_wall_moderators.map((val) => {
        if (
          agentsCollectionData &&
          agentsCollectionData.some((agent) => agent?.user_id === val)
        ) {
          agents_list = agentsCollectionData;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          console.log(
            "agent from list to delete from main - ",
            agents_list[index]
          );
          agents_list.splice(index, 1);
          selectedAgents_list = _.uniqWith(selectedAgents_list, _.isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else {
      setAgents(agentsCollectionData);
    }
  };

  useEffect(() => {
    console.log("initiate", initiateValidations);
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (validator.current.allValid()) {
      console.log("valid....");
      onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  const onHandleChange = (key1, value) => {
    onChangeData({ [key1]: value });
  };

  const onHandleMedia = async (key, value, item) => {
    let prevValue = _.cloneDeep(dataLocal.social_wall_sharing_details);
    let prevSearch = prevValue;
    let tempArray = [];
    prevSearch.forEach((element) => {
      if (element.social_media_id === item.social_media_id) {
        element[key] = value;
        element.sharing_setting_id = item.sharing_setting_id;
      }
      tempArray.push(element);
    });
    onHandleChange("social_wall_sharing_details", prevValue);
  };

  async function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    const filteredData = await agents_list.filter(
      (item) => item.user_id === value
    );
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    console.log("agents_list", selectedAgents_list);
    const agentsIdArr = selectedAgents?.map((elem, index) => elem.user_id);
    console.log("agents ID----->", agentsIdArr);

    onHandleChange("social_wall_moderators", agentsIdArr);

    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  async function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
    const agentsIdArr = selectedAgents?.map((elem, index) => elem.user_id);
    console.log("agents ID----->", agentsIdArr);

    onHandleChange("social_wall_moderators", agentsIdArr);
  }

  console.log("data local", dataLocal);
  if (dataLocal)
    return (
      <Fragment>
        <div className="container-fluid px-5">
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Main Setting</h6>
              </div>
            </div>
            <section>
              <div className="row mt-4">
                <div className="col-3 text-end">
                  <span className="text-secondary">
                    Default status of new posts
                    <RedAsterick />
                  </span>
                </div>
                <div className="col-9">
                  <Switch
                    value={dataLocal?.default_status_new_post}
                    onChange={(e) =>
                      onHandleChange(
                        "default_status_new_post",
                        !dataLocal?.default_status_new_post
                      )
                    }
                    checked={
                      dataLocal.default_status_new_post
                        ? dataLocal.default_status_new_post
                        : false
                    }
                  />
                </div>
                <div className="col-1"></div>
                <div className="col-10 my-2 text-start">
                  {dataLocal?.default_status_new_post === true ? (
                    <p className="fs-14 my-2">
                      By default, posts are: "On until turned Off"
                    </p>
                  ) : (
                    <p className="fs-14 my-2">
                      By default, posts are: "Off until turned On"
                    </p>
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <span className="text-secondary ">
                    Title
                    <RedAsterick />
                  </span>
                </div>
                <div className="col-10 ">
                  <Input
                    id="social_wall_title"
                    onChange={(e) =>
                      onHandleChange("social_wall_title", e.target.value)
                    }
                    defaultValue={dataLocal.social_wall_title}
                    placeholder="Enter Title Here"
                    className="w-50 fs-bold-14"
                    onBlur={() =>
                      validator.current.showMessageFor("social_wall_title")
                    }
                  />
                  {validator.current.message(
                    "Title",
                    dataLocal.social_wall_title,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <span
                    className="text-secondary"
                    placeholder="Enter Description here"
                  >
                    Description
                  </span>
                </div>
                <div className="col-10">
                  <TextArea
                    rows={4}
                    className="w-50 fs-bold-14"
                    defaultValue={dataLocal.social_wall_description}
                    onChange={(e) =>
                      onHandleChange("social_wall_description", e.target.value)
                    }
                  />
                </div>
              </div>
            </section>
            <div className="row mt-4">
              <div className="col-4">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-5 text-end"></div>
                  <div className="col-3 text-center">
                    <b>Shareable </b>
                  </div>
                  <div className="col-3 text-start">
                    <b>Auto </b>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <b>Re-post Handle / Account</b>
                  </div>
                  <div className="col-6">
                    <b>Re-post Added Text</b>
                  </div>
                </div>
              </div>
            </div>

            {dataLocal.social_wall_sharing_details?.map((item, i) => {
              return (
                <div className="row mt-4">
                  <div className="col-4">
                    <div className="row">
                      <div className="col-6 text-end">{item?.social_media}</div>
                      <div className="col-3 text-center">
                        <Switch
                          onChange={(value) =>
                            onHandleMedia("enable_sharing", value, item)
                          }
                          checked={
                            item.enable_sharing ? item.enable_sharing : false
                          }
                        />
                      </div>
                      <div className="col-3 text-center">
                        <Switch
                          onChange={(value) =>
                            onHandleMedia("enable_auto_repost", value, item)
                          }
                          checked={
                            item.enable_auto_repost
                              ? item.enable_auto_repost
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <Input
                      defaultValue={item.repost_account}
                      onChange={(e, i) =>
                        onHandleMedia("repost_account", e.target.value, item)
                      }
                      placeholder="Enter handle or account name here"
                      className="fs-bold-14"
                    />
                  </div>
                  <div className="col-4">
                    <Input
                      defaultValue={item.repost_text}
                      onChange={(e, i) =>
                        onHandleMedia("repost_text", e.target.value, item)
                      }
                      placeholder="Enter Text here"
                      className="fs-bold-14"
                    />
                  </div>
                </div>
              );
            })}

            <Divider />
          </section>
          {dataLocal && dataLocal.event_id ? (
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    Assign Moderators
                  </h6>
                </div>
              </div>
              <div className="row mt-4 event-editor-dropdown-div">
                <div className="col-2 text-end mt-4">
                  <span className="text-secondary mt-4">Agents</span>
                </div>

                <div
                  className={Tablet ? "col-5" : "col-4 mt-2"}
                  id="socialEdit"
                  style={{ position: "relative" }}
                >
                  <label className="py-2 mx-2">
                    <b>All Agents</b>
                  </label>
                  <br />
                  <Select
                    name="agentsSocialEdit"
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search to Select"
                    onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("socialEdit")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("agentsSocialEdit")
                    }
                    value="All Agents"
                  >
                    {agents?.map((data, key) => (
                      <Option
                        className="select-text-dark"
                        key={key}
                        value={data?.user_id}
                      >
                        {data?.first_name} {data?.last_name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div
                  className={Tablet ? "col-5" : "col-4"}
                  id="selectedAgentsSocialEdit"
                  style={{ position: "relative" }}
                >
                  <label className="py-2 mx-2">
                    <b>Tagged Agents</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    name="selectedAgentsSocialEdit"
                    style={{ width: 300 }}
                    placeholder="Search Tagged Agents"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selectedAgentsSocialEdit")
                    }
                    listHeight={100}
                    listItemHeight={4}
                    value="Tagged Agent"
                  >
                    {selectedAgents?.map((data) => {
                      return (
                        <Option value={data?.user_id}>
                          {data?.first_name} {data?.last_name}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleSelectedAgentsChange(data?.user_id)
                              }
                            />
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="row mt-4 border-bottom-gray pb-4">
                <div className="col-2"></div>
                <div className="col-4">
                  <p className="fs-pink fs-12">OR</p>
                  <Button className="px-3  rounded btn-svg">
                    <AddGroup fill="#ef0855" />
                    <span className="mx-2">Invite Agents</span>
                  </Button>
                  <Button
                    className={
                      Tablet
                        ? "px-3  rounded mt-2 btn-svg"
                        : "px-3  rounded mt-2 d-block btn-svg"
                    }
                  >
                    <CalenderGrey />
                    <span className="mx-2">Social Wall Moderator Calendar</span>
                  </Button>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </Fragment>
    );
};

export default SocialWall;
