/**
 * Vista Styles
 */

import styled from "styled-components"

const VistaFrame = styled.iframe(() => ({
    width: "100%",
    height: "100%",
    border: "none"
}));

export default VistaFrame;