import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  tierEventList: {},
  loading: false,
};

export const getTiers = createAsyncThunk(
  "tierEvent/getTiers",

  async () => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall("GET", "", "/admin/tiers/get");
    console.log("response", response);

    return response.data.data;
  }
);

export const tierEvent = createAsyncThunk(
  "tierEvent/tierEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/tiers/create",
      "",
      ""
    );
    console.log("create response tierEvent", response);

    return response;
  }
);

export const tierEventSlice = createSlice({
  name: "tierEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [tierEvent.pending]: (state) => {
      state.loading = true;
    },
    [tierEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.tierEventList = payload;
    },
    [tierEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const tierEventReducer = tierEventSlice.reducer;
