import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Redirect, useHistory } from "react-router-dom";
import { ApiCall } from "../../utils/api";

const initialState = {
  user: {},
  userDetails: {},
  registerUser: {},
  loading: false,
  registerLoading: false,
  countries: [],
  jobtitles: []
};

export const getRegisterUser = createAsyncThunk(
  "adminconsole/getRegisterUser",
  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/user/registration/details/get",
      params
    );
    // console.log("get Register User Resp", response.data.data);
    return response?.data?.data;
  }
);

export const getUser = createAsyncThunk(
  "adminconsole/getuser",
  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/user/details/get",
      params
    );
    // console.log(response);
    return response.data.data;
  }
);

export const addUser = createAsyncThunk("adminconsole/adduser", async body => {
  const response = await ApiCall("POST", body, "/admin/user/register");
  console.log(response);
  return response.data;
});

export const getCountries = createAsyncThunk(
  "adminconsole/countries",
  async params => {
    const response = await ApiCall("GET", "", "/admin/country/get", params);
    // console.log(response);
    return response.data.data;
  }
);

export const getJobTitles = createAsyncThunk(
  "adminconsole/jobtitles",
  async params => {
    const response = await ApiCall("GET", "", "/admin/jobtitle/get", params);
    // console.log(response);
    return response.data.data;
  }
);

export const registerSlice = createSlice({
  name: "adminconsole",
  initialState,
  extraReducers: {
    //Get User
    [getUser.pending]: state => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },
    [getUser.rejected]: state => {
      state.loading = false;
    },
    //add user
    [addUser.pending]: state => {
      state.registerLoading = true;
    },
    [addUser.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.userDetails = payload;
      } else {
        state.userDetails = {};
      }
      state.registerLoading = false;
    },
    [addUser.rejected]: state => {
      state.registerLoading = false;
    },
    //Get Countries
    [getCountries.pending]: state => {},
    [getCountries.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.countries = payload;
      } else {
        state.countries = [];
      }
    },
    [getCountries.rejected]: state => {},
    //Get Job Title
    [getJobTitles.pending]: state => {},
    [getJobTitles.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.jobtitles = payload;
      } else {
        state.jobtitles = [];
      }
    },
    [getJobTitles.rejected]: state => {},
    //Get Register User
    [getRegisterUser.pending]: state => {
      state.loading = true;
    },
    [getRegisterUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.registerUser = payload;
    },
    [getRegisterUser.rejected]: state => {
      state.loading = false;
    }
  }
});

export const registerReducer = registerSlice.reducer;
