import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  surveyList: [],
  addSurveyData: [],
  loading: false
};

export const getSurveyList = createAsyncThunk(
  "surveyManagement/getSurveyList",

  async params => {
    const response = await ApiCall("GET", "", "/admin/survey/get", params);

    return response.data.data;
  }
);

export const addSurvey = createAsyncThunk(
  "surveyManagement/addSurvey",

  async body => {
    const response = await ApiCall("POST", body, "/admin/survey/create");

    return response.data;
  }
);

export const viewSurvey = createAsyncThunk(
  "surveyManagement/viewSurvey",

  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/survey/details/get",
      params
    );

    return response.data;
  }
);

export const updateSurvey = createAsyncThunk(
  "surveyManagement/updateSurvey",

  async body => {
    const response = await ApiCall("PUT", body, "/admin/survey/update");

    return response.data;
  }
);

export const surveyManagementSlice = createSlice({
  name: "surveyManagement",
  initialState,
  reducers: {},
  extraReducers: {
    [getSurveyList.pending]: state => {
      state.loading = true;
    },
    [getSurveyList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.surveyList = payload;
    },
    [getSurveyList.rejected]: state => {
      state.loading = false;
    },
    [addSurvey.pending]: state => {
      state.loading = true;
    },
    [addSurvey.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.surveyList = payload
    },
    [addSurvey.rejected]: state => {
      state.loading = false;
    },
    [viewSurvey.pending]: state => {
      state.loading = true;
    },
    [viewSurvey.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.surveyList = payload
    },
    [viewSurvey.rejected]: state => {
      state.loading = false;
    },

    [updateSurvey.pending]: state => {
      state.loading = true;
    },
    [updateSurvey.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.surveyList = payload
    },
    [updateSurvey.rejected]: state => {
      state.loading = false;
    }
  }
});

export const surveyReducer = surveyManagementSlice.reducer;
