/**
 * Vista component route
 */

import React, { useContext, useEffect } from "react";
import VistaContext from "../../contexts/VistaContext";
import AppContext from "../../contexts/AppContext";
import VistaFrame from "./styles";

const Vista = (props) => {
    console.log(process.env,"-----vista props------");
    const { frame } = useContext(VistaContext);
    const { src, hall, stand, vistaLoaded } = useContext(AppContext);
    console.log(src,"-------src------");

    // useEffect(() => {
    //     if(vistaLoaded && frame.current) {
    //         const targets = (stand? stand.hotspots : hall.stands) || []
    //         const data = {}
    //         targets.forEach(value => {
    //             data[value.id] = value.status
    //         })
    //         frame.current.contentWindow.postMessage(
    //             `DBUtils.IFrameManager:${JSON.stringify({
    //                 type: "hotspotStatus",
    //                 data
    //             })}`,)
    // const targets = (stand? stand.hotspots : hall.stands) || []
    // targets.map(value => frame && frame.current.contentWindow.postMessage(
    //     `DBUtils.IFrameManager:${JSON.stringify({
    //         type: "hotspotStatus",
    //         data: {
    //             hotspot: `${value.id}${value.status? "On" : "Off"}`,
    //             status: value.status
    //         },
    //     })}`,))
    //     }
    // }, [vistaLoaded])

    return <VistaFrame 
        ref={frame}
        // src={`${process.env.PUBLIC_URL}${src}`}
        src = {`${src}`}
        // src={props.url}
        title="View"
    />
}

export default Vista;