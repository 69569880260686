import React, { useState } from "react";
import "./JoinSession.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Button,
  Table,
  Carousel
} from "antd";

import { Booth } from "../../components/svgJS/Booth";
import { EyeView } from "../../components/svgJS/EyeView";

import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Download } from "../../components/svgJS/Download";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";

const JoinSession = () => {
  // Start of Join Session Modal
  const [isJoinSessionModalVisible, setIsJoinSessionModalVisible] = useState(
    false
  );

  const showJoinSessionModal = () => {
    setIsJoinSessionModalVisible(true);
  };

  const handleJoinSessionOk = () => {
    setIsJoinSessionModalVisible(false);
  };

  const handleJoinSessionCancel = () => {
    setIsJoinSessionModalVisible(false);
  };
  // End of Join Session Modal

  return (
    <>
      {/* Start of Join Session Modal */}
      <Button type="primary" onClick={showJoinSessionModal}>
        Open Join Session Modal
      </Button>
      <Modal
        title=""
        visible={isJoinSessionModalVisible}
        onOk={handleJoinSessionOk}
        onCancel={handleJoinSessionCancel}
        className="joinsess_modal"
        width={650}
        footer={null}
      >
        <div className="joinsess_container w-100 p-4">
          <h6 className="opacity-75">Title :</h6>
          <h5>Speed Dating for Sales People</h5>
          <h6 className="opacity-75">Time :</h6>
          <p>14.10 - 15.10</p>
          <h6 className="opacity-75">Description :</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="d-flex justify-content-around mb-3">
            <Button className="joinsess_downloadBtn">
              <Download />
              <span className="ms-2">Download iCal </span>
            </Button>
            <Button className="joinsess_calendarBtn mx-3">
              <CalenderGrey />
              <span className="ms-2">Add to My Calendar </span>
            </Button>
          </div>
          <h5>Speaker</h5>
          <div className="d-flex p-3 border mx-4 mb-2">
            <img
              width={120}
              height={120}
              src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
            />
            <div className="ms-3">
              <span className="opacity-75">Speaker</span>
              <h6>Alastair Reece</h6>
              <span>Bio</span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt magna aliqua.
              </p>
            </div>
          </div>
          <h5>Brand</h5>
          <div className="d-flex p-3 border joinsess_brand  mx-4">
            <img
              width={120}
              height={120}
              src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
            />
            <div className="ms-3">
              <h6>Brand Name</h6>
              <p>Platinum</p>
              <BoothRed /> <span className="fs-pink">Visit Booth </span>
              <div className="d-flex mt-2">
                <div className="joinsess_icons bg-white ms-2">
                  <Chat />
                </div>
                <div className="joinsess_icons bg-white ms-2">
                  <Video />
                </div>
                <div className="joinsess_icons bg-white ms-2">
                  <Video />
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex mt-4 mb-4">
            <div className="w-75"></div>
            <Button className="joinsess_audiBtn mx-4 fs-16">
              <BoothRed />
              <span className="ms-2">Join Session</span>
            </Button>
          </div>
        </div>
      </Modal>
      {/* End of Join Session mOdal */}
    </>
  );
};

export default JoinSession;
