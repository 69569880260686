import React from "react";
import video from "../../assets/videos/videoFile.mp4";

const TutorialVideo = () => {
  return (
    <div className="bg-light">
      <video
        src={video}
        width="100%"
        height="500px"
        controls="controls"
        autoplay="true"
      />
    </div>
  );
};

export default TutorialVideo;
