import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLayout from "../components/layouts/AdminLayout";
import EventManagement from "../views/eventManagement/index";
import CustomerManagement from "../views/customerManagement/index";
// import Dashboard from "../views/dashboard/index";
import Login from "../views/login";
import SurveyManagement from "../views/surveyManagement/SurveyManagement";
import AddNewSurvey from "../views/surveyManagement/AddNewSurvey";
import CreateNewAccount from "../views/customerManagement/CreateNewAccount";
import BrandManagement from "../views/brandManagement";
import CreateNewBrand from "../views/brandManagement/CreateNewBrand";
import UserManagement from "../views/UserManagement/UserManagement";
import InviteAttend from "../views/UserManagement/InviteAttend";
import CreateNewZone from "../views/ZoneManagement/CreateNewZone";
import ZoneManagement from "../views/ZoneManagement/ZoneManagement";
import ViewNewEvent from "../views/eventManagement/ViewNewEvent";
import ViewBrandDetails from "../views/brandManagement/ViewBrandDetails";
import EditBrandDetails from "../views/brandManagement/EditBrandDetails";
import ViewCustomerDetails from "../views/customerManagement/ViewCustomerDetails";
import EditEvent from "../views/eventManagement/EditEvent";
import ViewSurvey from "../views/surveyManagement/ViewSurvey";
import EditSurvey from "../views/surveyManagement/EditSurvey";
import AddUser from "../views/UserManagement/AddUser";
import EditZone from "../views/ZoneManagement/EditZone";
import ViewZone from "../views/ZoneManagement/ViewZone";
import EditCustomer from "../views/customerManagement/EditCustomer";
import ViewUser from "../views/UserManagement/ViewUser";
import EditUser from "../views/UserManagement/EditUser";
import Registration from "../views/registration";
import MyCalendar from "../views/myCalendar/MyCalendar";
// import NetworkingSponsorship from "../views/UiComponents/NetworkingSponsorship";
import CustomCalendar from "../views/myCalendar/CustomCalendar";
import MicrosoftSurface from "../views/ExpoMap/MicrosoftSurface";
import BrandZoneDetails from "../views/ExpoMap/BrandZoneDetails";

import ExpoMap from "../views/ExpoMap/ExpoMap";
import ZoneCalendar from "../views/ZoneCalender/ZoneCalender";
import ProductZoneSession from "../views/ZoneCalender/zone-pop-up/ProductZoneSession";
import EventCalender from "../views/EventCalender";
import ProductChatStaffing from "../views/ZoneCalender/zone-pop-up/ProductChatStaffing";
import BrandCalendarUI from "../views/brandCalendar";
import messages from "../views/messages";
import Brand from "../views/brand";
import SocialWall from "../views/socialWall";
import Networking from "../views/networking";
import Analysis from "../views/analysis";
import HelpAndSupport from "../views/HelpAndSupport";
import Feedback from "../views/feedback";
import People from "../views/People/People";
import MyProfile from "../views/MyProfile/MyProfile";
import Auditorium from "../views/auditorium/Auditorium";
import Popups from "../views/Popups";

import Survey from "../views/surveyManagement/surveyscreens";
import ResetPassword from "../views/reset-password";
import ForgotPassword from "../views/forgot-password";
import NetworkTableUI from "../views/networkTable";
import CustomerRegistration from "../views/customerManagement/CustomerRegistration";
import PageNotFound from "../views/common/PageNotFound";

const Main = () => {
  const { isLoggedIn } = useSelector((state) => state.login);
  console.log("isLoggedInState", isLoggedIn);

  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <AdminLayout>
        <Route
          {...rest}
          render={(props) =>
            // <Component {...props} />
            isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      </AdminLayout>
    );
  };
  return (
    <Switch>
      <Route exact={true} path="/registration" component={Registration} />
      <Route path="/login" exact={true} component={Login} />
      <Route exact={true} path="/resetpass" component={ResetPassword} />
      <Route exact={true} path="/forgotpass" component={ForgotPassword} />
      <Route
        exact={true}
        path="/customer-registration"
        component={CustomerRegistration}
      />

      <AdminRoute exact={true} path="/" component={EventManagement} />
      {/* <AdminRoute exact={true} path="/dashboard" component={Dashboard} /> */}

      <AdminRoute
        exact={true}
        path="/eventCalender"
        component={EventCalender}
      />
      <AdminRoute
        exact={true}
        path="/eventmanagement"
        component={EventManagement}
      />
      <AdminRoute
        exact={true}
        path="/customermanagement"
        component={CustomerManagement}
      />
      <AdminRoute exact={true} path="/createevent" component={EditEvent} />
      <AdminRoute exact={true} path="/viewnewevent" component={ViewNewEvent} />
      <AdminRoute
        exact={true}
        path="/editevent/:event_id"
        component={EditEvent}
      />
      <AdminRoute
        exact={true}
        path="/surveyManagement"
        component={SurveyManagement}
      />
      <AdminRoute exact={true} path="/addnewsurvey" component={AddNewSurvey} />
      <AdminRoute
        exact={true}
        path="/createnewaccount"
        component={CreateNewAccount}
      />
      <AdminRoute
        exact={true}
        path="/brandmanagement"
        component={BrandManagement}
      />
      <AdminRoute
        exact={true}
        path="/createnewbrand"
        component={CreateNewBrand}
      />
      <AdminRoute
        exact={true}
        path="/usermanagement"
        component={UserManagement}
      />
      <AdminRoute exact={true} path="/inviteattend" component={InviteAttend} />
      <AdminRoute exact={true} path="/adduser" component={AddUser} />
      <AdminRoute
        exact={true}
        path="/zonemanagement"
        component={ZoneManagement}
      />
      <AdminRoute
        exact={true}
        path="/createnewzone"
        component={CreateNewZone}
      />
      <AdminRoute
        exact={true}
        path="/viewbranddetails/:brand_id"
        component={ViewBrandDetails}
      />
      <AdminRoute
        exact={true}
        path="/editbranddetails/:brand_id"
        component={EditBrandDetails}
      />
      <AdminRoute exact={true} path="/editzone/:zoneid" component={EditZone} />
      <AdminRoute exact={true} path="/viewzone/:zoneid" component={ViewZone} />
      <AdminRoute
        exact={true}
        path="/viewcustomerdetails/:customerid"
        component={ViewCustomerDetails}
      />
      <AdminRoute
        exact={true}
        path="/editcustomer/:customer_id"
        component={EditCustomer}
      />
      <AdminRoute
        exact={true}
        path="/viewsurvey/:surveyid"
        component={ViewSurvey}
      />
      <AdminRoute
        exact={true}
        path="/editsurvey/:surveyid"
        component={EditSurvey}
      />
      <AdminRoute exact={true} path="/viewuser/:userid" component={ViewUser} />
      <AdminRoute exact={true} path="/edituser/:userid" component={EditUser} />
      <AdminRoute exact={true} path="/mycalendar" component={MyCalendar} />
      <AdminRoute exact={true} path="/brand" component={Brand} />
      <AdminRoute
        exact={true}
        path="/customcalendar"
        component={CustomCalendar}
      />
      <AdminRoute exact={true} path="/expomap" component={ExpoMap} />
      <AdminRoute
        exact={true}
        path="/expomap/microsoftsurface/:event_id/:brand_id"
        component={MicrosoftSurface}
      />
      <AdminRoute
        exact={true}
        path="/expomap/brandzonedetails/:brand_id"
        component={BrandZoneDetails}
      />
      <AdminRoute
        exact={true}
        path="/zonecalender/:event_id/:brand_id/:zone_id"
        component={ZoneCalendar}
      />

      <AdminRoute
        exact={true}
        path="/productzonesession"
        component={ProductZoneSession}
      />
      <AdminRoute
        exact={true}
        path="/eventCalender"
        component={EventCalender}
      />
      <AdminRoute
        exact={true}
        path="/productchatstaffing"
        component={ProductChatStaffing}
      />

      <AdminRoute
        exact={true}
        path="/brandCalendar/:event_id/:brand_id"
        component={BrandCalendarUI}
      />

      <AdminRoute exact={true} path="/people" component={People} />

      <AdminRoute exact={true} path="/myprofile" component={MyProfile} />
      <AdminRoute exact={true} path="/messages" component={messages} />
      <AdminRoute exact={true} path="/auditorium" component={Auditorium} />
      <AdminRoute exact={true} path="/socialWall" component={SocialWall} />
      <AdminRoute exact={true} path="/networking" component={Networking} />
      <AdminRoute
        exact={true}
        path="/networkTable"
        component={NetworkTableUI}
      />
      <AdminRoute exact={true} path="/analysis" component={Analysis} />
      <AdminRoute
        exact={true}
        path="/helpAndSupport"
        component={HelpAndSupport}
      />
      <AdminRoute exact={true} path="/feedback" component={Feedback} />
      <AdminRoute exact={true} path="/popups" component={Popups} />

      <AdminRoute exact={true} path="/people" component={People} />

      <AdminRoute exact={true} path="/survey" component={Survey} />
      <AdminRoute exact={true} path="/pagenotfound" component={PageNotFound} />
    </Switch>
  );
};

export default Main;
