import React, { useState, useEffect, Fragment } from "react";
import "./index.css";
import { Breadcrumb, Input } from "antd";
import { ApiCall } from "../../utils/api";
import { Menu } from "antd";
import MicrosoftSurface from "../ExpoMap/MicrosoftSurface";
import Brands from "../ExpoMap/Brands";

const Brand = (props) => {
  const [fields, setFields] = useState({
    event_id: null,
  });

  const [eventList, setEventList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brandObj, setBrandObj] = useState({
    event_id: null,
    brand_id: null,
    show: false,
  });


  useEffect(() => {
    getEventList();
    getBrandList();


  }, []);

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/get", "")
      .then((response) => {
        const { rows } = response?.data?.data;
        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBrandViewDetails = () => {
    setLoading(true);
    ApiCall("get", null, "/admin/brandview/details/get", { event_id: 52 })
      .then((response) => {
        const { rows } = response?.data?.data;
        setBrandData(rows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/get", null)
      .then((response) => {
        console.log(response);
        const { rows } = response?.data?.data;
        setBrandList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Sorting Logic
  const [showA_Z, setShowA_Z] = useState(true);

  const menu = (
    <Menu>
      {!showA_Z ? (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => {
            setShowA_Z(true);
            handleAscendingSort();
          }}
        >
          <span>A-Z</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => {
            setShowA_Z(false);
            handleDescendingSort();
          }}
        >
          <span>Z-A</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleAscendingSort = () => {
    setLoading(true);
    ApiCall("get", null, "/admin/brandview/details/get", {
      event_id: 52,
      sort_type: "brand_title",
      sort_by: "ASC",
    })
      .then((response) => {
        const { rows } = response?.data?.data;
        setBrandData(rows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDescendingSort = () => {
    setLoading(true);
    ApiCall("get", null, "/admin/brandview/details/get", {
      event_id: 52,
      sort_type: "brand_title",
      sort_by: "DSC",
    })
      .then((response) => {
        const { rows } = response?.data?.data;
        setBrandData(rows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (brandObj.show) {
    return <MicrosoftSurface brandObj={brandObj} />;
  } else
    return (
      <Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24">Brands</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
              <Breadcrumb.Item>Brands</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="p-2 bg-white">

          <Brands setBrandObj={setBrandObj} />
        </div>
      </Fragment>
    );
};

export default Brand;
