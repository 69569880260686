import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  Checkbox,
} from "antd";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser } from "../../redux/slice/userManagementSlice";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

const InviteAttend = (props) => {

  const validator = useRef(new SimpleReactValidator());
  var CryptoJS = require("crypto-js");


  // validation states
  const [showError, setshowError] = useState(false);
  const [isError, setisError] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false)
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [userType, setUserType] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [showNewEmail, setshowNewEmail] = useState(false);
  const [filteredZone, setFilteredZone] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [fields, setFields] = useState({
    tier_id: null,
    email: "",
    event_id: props?.event_id ?? null,
    user_type: null,
    brand_id: null,
    is_admin: false,
    is_speaker: false,
    zone_tag_details: [],
  })

  const dispatch = useDispatch();

  const { Option } = Select;
  const { TextArea } = Input;

  const { loading } = useSelector((state) => state.userManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setFilteredZone(
      zoneList?.filter(data => data?.brand_id === fields?.brand_id)
    );
  }, [fields.brand_id]);

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    getTierList();
    getEventList();
    getUserTypeList();
    getBrandList();
    getZoneList();
  }, []);

  useEffect(() => {
    if (fields?.event_id) {
      getBrandList();
    }
  }, [fields?.event_id, fields?.user_type]);

  useEffect(() => {
    if (fields?.brand_id) {
      getTierList();
    }
  }, [fields?.brand_id, fields?.user_type]);

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", {
      brand_id: fields?.brand_id && fields?.user_type ? brandList[0]?.brand_id : fields?.brand_id
    })
      .then(response => {
        const { data } = response.data;
        setTierList(data);

        if (data.length == 1) {
          console.log("If is runnned")
          if (fields?.user_type == "AGENT") {
            console.log("Second if is running")
            setFields({ ...fields, tier_id: data[0]?.tier_id });
          }
        }
        else {
          setTierList(data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then(response => {
        const { rows } = response.data.data;
        setEventList(rows);
        if (rows.length == 1) {
          setFields({ ...fields, event_id: rows[0]?.event_id });
        }
        if (userType == "BRAND ADMIN") {
          setFields({ ...fields, user_type: "AGENT" });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  const getUserTypeList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setUserTypeList(rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : ""
    })
      .then(response => {
        const { rows } = response.data.data;
        setBrandList(rows);

        if (rows.length == 1 && fields?.user_type == "AGENT") {
          setFields({ ...fields, brand_id: rows[0].brand_id });
        }
        if (userType == "BRAND ADMIN") {
          setFields({ ...fields, user_type: "AGENT" });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then(response => {
        let constantData = response.data.data.rows.map(response => {
          return {
            is_assigned: false,
            is_always_get_messages: false,
            ...response
          };
        });
        return constantData;
      })

      .then(data => {
        setZoneList(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleInviteaAttend = async (data) => {
    console.log(data);
    const res = await dispatch(inviteUser(data));
    console.log(res);
    if (res.payload.code === 200) {
      // console.log("200 success");
      // props.history.push("customermanagement");
      toast.success(res.payload.message);
    }
    if (res.payload.code != 200) {
      // console.log("200 success");
      // props.history.push("customermanagement");
      toast.error(res.payload.message);
    }
  };

  const handleFieldsChange = (val, name) => {
    console.log(
      "🚀 ~ file: CreateNewBrand.js ~ line 147 ~ handleFieldsChange ~ name",
      { ...fields, [name]: val }
    );
    setFields({ ...fields, [name]: val });
  };



  const handleInviteAgentFormSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0)

    // console.log("filteredZone...", filteredZone)
    const data = filteredZone && filteredZone?.map((res) => {

      return {
        zone_id: res.zone_id,
        is_assigned: res.is_assigned,
        is_always_get_messages: res.is_always_get_messages
      }

    })
    console.log("filteredZoneData...", data)
    // setFields({ ...fields, zone_tag_details: data })


    let formData = new FormData();
    formData.append("data", JSON.stringify({ ...fields, zone_tag_details: data }));
    console.log("fields.....//...", fields);

    if (validator.current.allValid()) {
      // post api call
      // const addNewUserResp = await dispatch(addUser(formData));
      // console.log("Response checking in adduser...", addNewUserResp);
      const res = await dispatch(inviteUser(fields));

      if (res.payload.data.code === 200) {
        toast.success(res.payload.data.message);
        setshowError(false)

        if (props?.onSuccessFromModal) {
          props.onSuccessFromModal()
        }
        else {
          props?.history?.push("/usermanagement")
        }
      }
      // if (addNewUserResp.payload.data.code != 200) {
      //   toast.error(addNewUserResp.payload.data.message);
      //   setshowError(true)
      // }
      else {
        toast.error(res.payload.data.message);
        setshowError(true)
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate)
    }
  };

  const handleCheckBox1Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_assigned = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  const handleCheckBox2Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_always_get_messages = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  console.log("Fields in invite", fields)
  console.log("Fields.usertype", fields?.user_type)

  return (
    <form autoComplete="off" >
      <div>

        {props?.event_id ?

          "" :
          <section>
            <h4 className="fs-24">Invite to Attend</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
              <Breadcrumb.Item>User Management</Breadcrumb.Item>
              <Breadcrumb.Item>Invite to Attend</Breadcrumb.Item>
            </Breadcrumb>
          </section>

        }
        <div className="bg-white px-5 py-3">
          <div className="row my-3">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Invite User</h6>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Select Event
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                className="w-25"
                placeholder="Select Event"
                optionFilterProp="children"
                name="event_id"
                value={fields.event_id}
                onChange={(val) => handleFieldsChange(val, "event_id")}
                onBlur={() => validator.current.showMessageFor("event_id")}
                autoComplete="none"
                listHeight={120}
                listItemHeight={4}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
              {validator.current.message(
                "event",
                fields.event_id,
                "required|text",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row my-3">
            <div className="col-3 text-end">
              Email Address
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Input
                name="email"
                id="email"
                className="w-25 border-gray rounded"
                placeholder="Enter Email"
                value={fields.email}
                onChange={(e) => handleFieldsChange(e.target.value, "email")}
                onBlur={() => validator.current.showMessageFor("email")}
                autoComplete="none"
              />
              {validator.current.message(
                "email",
                fields.email,
                "required|email",
                { className: "text-danger py-2" }
              )}
              {/* <Button
                className="mx-2 ant-btn ant-btn-primary rounded"
                icon={<EmailWhite />}
                onClick={() => setshowNewEmail(true)}
                disabled
              >
                <span className="mx-2">Change </span>
              </Button> */}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">Select User Type</div>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                className="w-25"
                placeholder="Select User"
                optionFilterProp="children"
                name="user_type"
                value={fields.user_type}
                onChange={(val) => handleFieldsChange(val, "user_type")}
                autoComplete="none"
              >
                {/* {userTypeList &&
                  userTypeList?.map((data) => {
                    return (
                      <Option value={data.user_type}>{data.user_type}</Option>
                    );
                  })} */}
                <Option value="AGENT">Agent</Option>
                <Option
                  value="DELEGATE"
                  className={userType === "BRAND ADMIN" ? "d-none" : ""}
                >
                  Delegate
                </Option>
                <Option
                  value="SPEAKER"
                  className={userType === "BRAND ADMIN" ? "d-none" : ""}
                >
                  Speaker
                </Option>
              </Select>

            </div>
          </div>


          {fields.user_type === "AGENT" && (
            <>
              <div className="row my-3">
                <div className="col-3 text-end">Admin</div>
                <div className="col-7">
                  <Switch
                    name="is_admin"
                    checked={fields.is_admin}
                    onChange={checked =>
                      handleFieldsChange(checked, "is_admin")
                    }
                  />
                </div>
              </div>

              {/* <div className="row my-3">
              <div className="col-3 text-end">Speaker</div>
              <div className="col-9">
                <Switch
                  name="is_speaker"
                  checked={fields.is_speaker}
                  onChange={checked =>
                    handleFieldsChange(checked, "is_speaker")
                  }
                />
              </div>
            </div> */}
              <div className="row my-3">
                <div className="col-3 text-end">
                  Brand
                  <sup>
                    <span
                      className="text-danger"
                      style={{ fontSize: "1.1rem" }}
                    >
                      *
                    </span>
                  </sup>
                </div>
                {userType && userType === "BRAND ADMIN" ? (
                  <div className="col-9">
                    <Input className="w-25" value={brandList[0]?.brand_title} />
                  </div>
                ) : (
                  <div className="col-9">
                    <Select
                      showSearch
                      className="w-25 fs-bold-14"
                      placeholder="Select Brand"
                      optionFilterProp="children"
                      name="brand_id"
                      value={fields.brand_id}
                      onChange={val => handleFieldsChange(val, "brand_id")}
                      onBlur={() =>
                        validator.current.showMessageFor("brand_id")
                      }
                      autoComplete="none"
                      listHeight={120}
                      listItemHeight={4}
                      disabled={
                        userType && userType === "BRAND ADMIN" ? true : false
                      }
                    >
                      {brandList &&
                        brandList?.map(data => {
                          return (
                            <Option value={data.brand_id}>
                              {data.brand_title}
                            </Option>
                          );
                        })}
                    </Select>
                    {fields.user_type === "Agent" &&
                      validator.current.message(
                        "brand",
                        fields.brand_id
                          ? fields.brand_id
                          : brandList[0].brand_id,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    {brandList && brandList.length === 0 && (
                      <div className="d-block mt-2 fs-14 text-danger">
                        <span>
                          Opps, No brands is available for above selected
                          "Event"{" "}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="row my-3">
                <div className="col-3 text-end">Zone Tags</div>
                <div className="col-8 border-bottom zone-tag-scroll-div">
                  <div className="row border border-gray rounded py-1 sticky-top bg-white">
                    <div className="col-4">
                      <b>Zones</b>
                    </div>
                    <div className="col-4">
                      {" "}
                      <b>Assign</b>
                    </div>
                    <div className="col-4">
                      <b>Always get Msgs</b>
                    </div>
                  </div>

                  {filteredZone &&
                    filteredZone?.map((data, idx) => (
                      <div className="row border-start border-end">
                        <div className="col-4">
                          <div className="my-3">{data.zone_title}</div>
                        </div>

                        <div className="col-4 text-start pt-3">
                          <Checkbox
                            className="mx-3"
                            name="assign"
                            //  checked = {checkBox1}
                            onChange={e => handleCheckBox1Change(e, data, idx)}
                          />
                        </div>

                        <div className="col-4 text-start pt-3">
                          <Checkbox
                            className="mx-3"
                            name="alwaysGetMags"
                            // checked={checkBox2}
                            onChange={e => handleCheckBox2Change(e, data, idx)}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}



          <div className="row my-3">
            <div className="col-3 text-end">
              Tier
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>

            {tierList && tierList.length === 1 ? (
              <div className="col-9">
                <Input className="w-25" value={tierList[0]?.tier} />
              </div>
            ) : (
              <div className="col-9">
                <Select
                  showSearch
                  name="tier_id"
                  className="w-25 fs-bold-14"
                  placeholder="Select Tier"
                  optionFilterProp="children"
                  value={fields.tier_id}
                  onChange={val => handleFieldsChange(val, "tier_id")}
                  onBlur={() =>
                    validator.current.showMessageFor("tier_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                  autoComplete="none"
                  disabled={
                    userType && userType === "BRAND ADMIN" ? true : false
                  }
                >
                  {tierList &&
                    tierList?.map(data => {
                      return <Option value={data.tier_id}>{data.tier}</Option>;
                    })}
                </Select>
                {validator.current.message(
                  "tiers",
                  fields.tier_type_id
                    ? fields.tier_type_id
                    : tierList[0]?.tier_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            )}
          </div>

        </div>

        <div
          className="shadow-sm p-3 d-flex justify-content-between"
          style={{ backgroundColor: "#eeeeee" }}
        >

          {props?.onCancelFromModal ?
            <Button className="px-3 d-block button-pink rounded" onClick={() => props?.onCancelFromModal()}>
              Cancel
            </Button>
            :

            <Link to="/usermanagement">
              <Button className="px-3 d-block button-pink rounded">
                Cancel
              </Button>
            </Link>
          }


          {props?.onSuccessFromModal ?
            <Button type="primary" onClick={() => props?.onSuccessFromModal(), handleInviteAgentFormSubmit}>
              {!loading ? (
                "Send Invitation"
              ) : (
                <div style={{ width: "80px" }}>
                  <Spin indicator={loadingIcon} style={{ color: "white" }} />
                </div>
              )}
            </Button>
            :
            <Button type="primary" onClick={handleInviteAgentFormSubmit}>
              {!loading ? (
                "Send Invitation"
              ) : (
                <div style={{ width: "80px" }}>
                  <Spin indicator={loadingIcon} style={{ color: "white" }} />
                </div>
              )}
            </Button>
          }
        </div>
      </div>
    </form>

  );
};

export default InviteAttend;
