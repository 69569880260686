import React, { useState, useEffect, useRef } from "react";

import "./MicrosoftSurface.css";
import { Button, Breadcrumb, Input, Modal, Row, Col, Tooltip } from "antd";
import {
  logo,
  chat,
  calendar,
  download,
  union,
  chatRed,
  unionRed,
  calendarRed
} from "../../components/Images/Images";
import {
  VideoCameraOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  FilePdfOutlined,
  FileJpgOutlined
} from "@ant-design/icons";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Chat } from "../../components/svgJS/Chat";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import { Union } from "../../components/svgJS/Union";
import { Download } from "../../components/svgJS/Download";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";

import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { sendMessageAPI } from "../../utils/commonApis";

const MicrosoftSurface = ({ match, brandObj, history }) => {
  const [zoneData, setZoneData] = useState("");
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [zone_id, setzone_id] = useState();
  console.log("zone_id", zone_id);
  const [message_text, set_message_text] = useState("");

  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: ""
  });

  const [forceUpdate, setForceUpdate] = useState(false);

  const messageValidator = useRef(new SimpleReactValidator());

  useEffect(async () => {
    console.log("running12345");
    setLoading(true);
    await getBrandDetails();
    setTimeout(() => setLoading(false), 1000);
  }, [brandObj]);

  useEffect(() => {
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        response => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid()
            });
          }
        },
        error => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, []);

  ApiCall("get", null, "/admin/brand/details/get", {
    brand_id: brandObj?.brand_id
  }).then(response => {
    if (response?.data?.data?.details?.brands_default_zone_id?.zone_id) {
      const { zone_id } = response?.data?.data?.details?.brands_default_zone_id;
      console.log(zone_id);
      setzone_id(zone_id);
    }
  });

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const getBrandDetails = () => {
    console.log("msbrandobj", brandObj);
    ApiCall("get", null, "/admin/brandview/details/get", {
      // event_id: match?.params?.event_id,
      // search: match?.params?.brand_id,
      event_id: brandObj?.event_id,
      search: brandObj?.brand_id
    })
      .then(response => {
        console.log("11", response);
        const { rows } = response.data.data;
        console.log(rows);
        setZoneData(rows[0]);
      })
      .catch(err => {
        console.error(err);
      });
  };

  // const sendVideoCallReq = async channel_id => {
  //   let data = {
  //     channel_id
  //   };
  //   await ApiCall("get", data, "/admin/channel/videocall/request", null)
  //     .then(response => {
  //       console.log("11111", response);
  //       toast.success(response?.data?.data?.message);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // };

  if (loading) {
    return <CommonLoader />;
  } else
    return (
      <>
        <div className="d-flex justify-content-between">
          {console.log("zoneData", zoneData)}
          <div>
            <h4 className="fs-24">Brands</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>
                <Link to="/brand">Brands</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Microsoft Surface</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="ms_page_container">
          <div className="ms_img_container">
            {zoneData && zoneData?.logo_url ? (
              <img className="img-fluid w-100 h-100" src={zoneData?.logo_url} />
            ) : (
              <span className="ms_zero_hero">Zero Hero Image</span>
            )}
          </div>
          <div className="ms_page_content">
            <h6 className="ms_page_title fs-pink">
              {zoneData?.brands_default_zone_id?.zone_title}
            </h6>
            <p className="ms_page_description">
              {zoneData?.brands_default_zone_id?.zone_description}
            </p>
            <h6 className="ms_interact_live">Interact Live</h6>
            <div className="ms_btns_container d-flex flex-wrap">
              {/* <Button className="ms_btn">
                <Chat />
                <span>Chat</span>
              </Button> */}
              {zoneData?.brands_default_zone_id?.channel_id ? (
                <Button
                  className="ms_btn"
                  onClick={() => {
                    setMessageModal({
                      isOpen: true,
                      channel_id: zoneData?.brands_default_zone_id?.channel_id
                    });
                  }}
                >
                  <Chat />
                  <span>Chat</span>
                </Button>
              ) : null}

              {messageModal.isOpen ? (
                <Modal
                  title={<b>Send Message</b>}
                  visible={messageModal.isOpen}
                  onOk={() => clearMessageAndModal()}
                  onCancel={() => clearMessageAndModal()}
                  footer={[
                    <Button
                      className="px-3 button-pink rounded"
                      key="cancel"
                      onClick={() => clearMessageAndModal()}
                    >
                      Cancel
                    </Button>,
                    <Button
                      className="px-3 rounded"
                      key="save"
                      type="primary"
                      onClick={() =>
                        callSendMessageAPI(messageModal.channel_id)
                      }
                    >
                      Send
                    </Button>
                  ]}
                  className="message_modal"
                >
                  <Row className="ticker_configuration_row mt-2">
                    <Col span={6} className="ticker_configuration_label">
                      <p>
                        Message
                        <RedAsterick />
                      </p>
                    </Col>
                    <Col span={12}>
                      <TextArea
                        name="message_text"
                        id="message_text"
                        className="ticker_configuration_inputDescription"
                        placeholder="Enter a message"
                        rows={4}
                        value={message_text}
                        onChange={e => set_message_text(e.target.value)}
                        onBlur={() =>
                          messageValidator.current.showMessageFor(
                            "message_text"
                          )
                        }
                      />
                      {messageValidator.current.message(
                        "Message",
                        message_text,
                        "required",
                        { className: "text-danger py-1" }
                      )}
                    </Col>
                  </Row>
                </Modal>
              ) : null}

              {/* <Button
                // onClick={() => {
                //   sendVideoCallReq(
                //     zoneData?.brands_default_zone_id?.channel_id
                //   );
                // }}
                className="ms_btn"
              >
                <VideoCameraOutlined />
                <span>1-to-1 Video Call</span>
              </Button> */}
              {zoneData?.brands_default_zone_id?.zone_demo_room_link ? (
                <Button
                  onClick={() =>
                    window
                      .open(
                        zoneData?.brands_default_zone_id?.zone_demo_room_link,
                        "_blank"
                      )
                      .focus()
                  }
                  className="ms_btn"
                >
                  <VideoCameraOutlined />
                  <span>Join the Demo Room</span>
                </Button>
              ) : (
                ""
              )}

              <Link
                to={`/zonecalender/${brandObj?.event_id}/${brandObj?.brand_id}/${zone_id}`}
              >
                <Button className="ms_btn">
                  <Calendar6 />
                  <span>Zone Calendar</span>
                </Button>
              </Link>
              <Button
                onClick={() =>
                  window.open("/surveyManagement", "_blank").focus()
                }
                className="ms_btn"
              >
                <Union />
                <span>Survey</span>
              </Button>
            </div>
            <h6 className="ms_external_links">External & Social Links</h6>
            <div className="ms_links">
              <PublicIcon fontSize="small" />
              <LinkedinOutlined />
              <TwitterOutlined />
              <FacebookOutlined />
            </div>
            {/* {console.log("params", match.params.brand_id)} */}
            <h6 className="ms_assets">Assets</h6>
            <div className="ms_assets_cards_container">
              {zoneData &&
                zoneData?.brands_default_zone_id?.zones_assets_id.map(item => {
                  return (
                    <div className="ms_assets_card">
                      <div className="ms_card_upper_section">
                        <h6 className="ms_card_heading">{item?.asset_name}</h6>
                        {item?.asset_file_type === "mp4" ? (
                          <VideoCameraOutlined className="assets_icons" />
                        ) : item.asset_file_type === "pdf" ? (
                          <FilePdfOutlined className="assets_icons" />
                        ) : item?.asset_file_type === "jpg" ||
                          item.asset_file_type === "jpeg" ||
                          item.asset_file_type === "png" ||
                          item.asset_file_type === "image" ? (
                          <FileJpgOutlined className="assets_icons" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="ms_card_lower_section">
                        <p className="ms_card_p">
                          {item?.asset_description
                            ? item?.asset_description
                            : "NA"}
                        </p>

                        {item?.asset_file_type === "mp4" ? (
                          ""
                        ) : (
                          <Button className="">
                            <Download />
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
};

export default MicrosoftSurface;
