import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import FileUpload from "../common/FileUpload";

import {
	Layout,
	Menu,
	Breadcrumb,
	Button,
	Input,
	Switch,
	Select,
	Modal,
	Row,
	Col,
	Tooltip,
} from "antd";
import {
	LinkedinOutlined,
	FacebookOutlined,
	TwitterOutlined,
	InstagramOutlined,
	HeartOutlined,
	HeartFilled,
} from "@ant-design/icons";
import { Download } from "../../components/svgJS/Download";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Sort } from "../../components/svgJS/Sort";
import { HeartFill } from "../../components/svgJS/HeartFill";
import { getSocialWallModerationDetails } from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { sendMessageAPI, videoRequestAPI } from "../../utils/commonApis";
// import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import CommonLoader from "../../components/Widgets/CommonLoader";

const SocialWall = (props) => {
	let CryptoJS = require("crypto-js");
	let localData_event_id = parseInt(localStorage.getItem("event_id"));
	const { TextArea } = Input;
	const { Option } = Select;
	const validator = useRef(new SimpleReactValidator());
	const messageValidator = useRef(new SimpleReactValidator());

	const [imgPreview, setImgPreview] = useState();
	const [logoImg, setLogoImg] = useState();
	const [socialWallData, setSocialWallData] = useState();
	const [socialMediaPost, setSocialMediaPost] = useState([]);
	const [socialMediaId, setSocialMediaId] = useState();
	const [eventList, setEventList] = useState([]);
	const [selectedEventId, setSelectedEventId] = useState();
	const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
	const [message_text, set_message_text] = useState("");
	const [cometChatUser, setCometChatUser] = useState({
		isValidUser: false,
		data: {},
		uid: "",
	});

	const [disableEvent, setDisableEvent] = useState(false);

	// validation states
	const [showError, setshowError] = useState(false);
	const [isError, setisError] = useState("");
	const [forceUpdate, setForceUpdate] = useState(false);
	const [loading, setLoading] = useState(false);

	//Role State
	const [userType, setUserType] = useState("");

	useEffect(() => {
		const ciphertext = localStorage.getItem("user_type");
		var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
		var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		setUserType(decryptedData);

		let chat_uid = localStorage.getItem("chat_uid");
		if (chat_uid) {
			CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
				(response) => {
					if (response) {
						console.log("CometChat Login Successful:", response);
						setCometChatUser({
							isValidUser: true,
							data: response,
							uid: response.getUid(),
						});
					}
				},
				(error) => {
					console.log("CometChat Login failed with exception:", { error });
				},
			);
		}
		getEventList();
	}, []);

	useEffect(() => {
		getAllDetails();
	}, [selectedEventId]);

	const getAllDetails = async () => {
		setLoading(true);
		let response = await getSocialWallModerationDetails(
			selectedEventId ? selectedEventId : "",
		);

		if (response != null || undefined) {
			if (response?.data?.event_social_wall_settings) {
				const { social_media_posts } =
					response?.data?.event_social_wall_settings;
				const { social_settings_id } =
					response?.data?.social_wall_moderators_details;

				await setSocialMediaPost(social_media_posts);
				await setSocialMediaId(social_settings_id);
				setFields({ ...fields, social_settings_id: social_settings_id });
			}
			await setSocialWallData(response.data);
		}
		setLoading(false);
	};

	const getEventList = () => {
		ApiCall("get", null, "/admin/events/dropdown/get", "")
			.then((response) => {
				const { rows } = response?.data?.data;
				console.log("rows in social wlal", rows)
				if (rows.length == 1) {
					setSelectedEventId(rows[0]?.event_id)
				}
				const ciphertext = localStorage.getItem("user_type");
				let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
				console.log(bytes);
				let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				setUserType(user_type);
				if (
					user_type === "DELEGATE" ||
					user_type === "SPEAKER" ||
					user_type === "BRAND ADMIN"
				) {
					const events = rows.filter((item) => {
						return item.event_id === localData_event_id;
					});

					setEventList(events);
					let id = events[0].event_id;
					setSelectedEventId(id);
					setDisableEvent(true);
				} else {
					if (props.event_id) {
						setEventList(rows);
						setSelectedEventId(props.event_id);
						setDisableEvent(true);
					} else {
						setEventList(rows);
					}
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const [fields, setFields] = useState({
		social_settings_id: socialMediaId,
		post_content: "",
		is_delete: false,
	});

	const handleImgChange1 = (event) => {
		setLogoImg(event.target.files[0]);
		let img = event.target.files[0];
		if (img) {
			showModal();
			setImgPreview(URL.createObjectURL(img));
		}
	};

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleFieldsChange = (val, name) => {
		setFields({ ...fields, [name]: val });
	};

	const sortByLike = () => {
		let sortedArr = Array.from(
			socialMediaPost.sort((a, b) => b.post_likes_count - a.post_likes_count),
		);

		setSocialMediaPost(sortedArr);
	};

	const addPost = async () => {
		let formData = new FormData();
		formData.append("data", JSON.stringify(fields));
		formData.append("logo_image_url", logoImg);

		if (validator.current.allValid()) {
			await ApiCall(
				"POST",
				formData,
				"/admin/socialwallmoderation/posts/create",
				null,
			)
				.then((response) => {
					console.log("create response", response);
					if (response?.data?.code === 200) {
						toast.success(response.data.message);
						// setFields({social_settings_id: socialMediaId,post_content: "", is_delete: false})
						fields.post_content = "";
						getAllDetails();
					} else {
						toast.error(response.data.message);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			validator.current.showMessages();
			setForceUpdate(!forceUpdate);
		}
	};

	const socialWallLike = async (post_id, like) => {
		await ApiCall(
			"POST",
			{ post_id: post_id, is_like: like },
			"/admin/socialwallmoderation/postlike/addandremove",
			{ event_id: selectedEventId },
		)
			.then((response) => {
				if (response?.data?.code === 200) {
					getAllDetails();
				} else {
					toast.error(response.data.message);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const socialWallPostVisibility = async (post_id, visible) => {
		await ApiCall(
			"PUT",
			{ post_id: post_id, is_visible: visible },
			"/admin/socialwallmoderation/postvisiblity/update",
			null,
		)
			.then((response) => {
				console.log("response of update api", response);
				if (response?.data?.code === 200) {
					toast.success(response.data.message);
					getAllDetails();
				} else {
					toast.error(response.data.message);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onRemoveClick = () => {
		setImgPreview(null);
		setLogoImg(null);
		setIsModalVisible(false);
	};

	function clearMessageAndModal() {
		set_message_text("");
		setMessageModal({ isOpen: false, data: {} });
	}

	async function callSendMessageAPI(channel_id) {
		if (messageValidator.current.allValid()) {
			if (cometChatUser.uid) {
				const response = await sendMessageAPI(channel_id, message_text);
				if (response.data.code === 200) {
					toast.success(response.data.message);
					clearMessageAndModal();
				} else {
					toast.error(response.data.message);
				}
			} else setForceUpdate(!forceUpdate);
		} else {
			messageValidator.current.showMessages();
			setForceUpdate(!forceUpdate);
		}
	}

	if (loading) {
		return <CommonLoader />;
	} else
		return (
			<>
				<div className="social_wall_image_modal">
					<Modal
						title=""
						visible={isModalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
						footer={null}
						// width={100}
						className="pro_pic_review"
					>
						<div className="d-flex flex-column justify-content-center align-items-center">
							<img
								width={300}
								height={300}
								// src="https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
								src={imgPreview}
							/>
							<div className="d- flex justify-content-center mt-2">
								<Button className="social_removeImg" onClick={onRemoveClick}>
									Remove
								</Button>
								<Button
									className="ms-2 social_yesImg"
									onClick={() => setIsModalVisible(false)}
								>
									Yes
								</Button>
							</div>
						</div>
					</Modal>
				</div>

				<div
					className={
						props.isFromVista
							? "d-flex justify-content-end bg-white p-4"
							: "d-flex justify-content-between"
					}
				>
					{props.isFromVista ? (
						""
					) : (
						<div>
							<h4>Social Wall</h4>
							<Breadcrumb separator=">">
								<Breadcrumb.Item>Social Wall</Breadcrumb.Item>
								<Breadcrumb.Item>Social Wall - Moderation</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					)}
					{props.isFromVista && userType !== "SUPER ADMIN" ? (
						""
					) : userType === "SUPER ADMIN" || userType === "CUSTOMER" ?
						<div>
							<span>Event Name</span>
							<Select
								showSearch
								placeholder="Select Event"
								name="event_id"
								disabled={disableEvent}
								allowClear
								className="expomap_expohall_search"
								value={selectedEventId}
								onChange={(val) => setSelectedEventId(val)}
								optionFilterProp="children"
								listHeight={120}
								listItemHeight={4}
								bordered={false}
							>
								{eventList &&
									eventList?.map((data) => {
										return (
											<Option value={data.event_id}>{data.event_name}</Option>
										);
									})}
							</Select>
						</div>
						:
						""
					}
				</div>

				{messageModal.isOpen ? (
					<Modal
						title={<b>Send Message</b>}
						visible={messageModal.isOpen}
						onOk={() => clearMessageAndModal()}
						onCancel={() => clearMessageAndModal()}
						footer={[
							<Button
								className="px-3 button-pink rounded"
								key="cancel"
								onClick={() => clearMessageAndModal()}
							>
								Cancel
							</Button>,
							<Button
								className="px-3 rounded"
								key="save"
								type="primary"
								onClick={() => callSendMessageAPI(messageModal.channel_id)}
							>
								Send
							</Button>,
						]}
						className="message_modal"
					>
						<Row className="ticker_configuration_row mt-2">
							<Col span={6} className="ticker_configuration_label">
								<p>
									Message
									<RedAsterick />
								</p>
							</Col>
							<Col span={12}>
								<TextArea
									name="message_text"
									id="message_text"
									className="ticker_configuration_inputDescription"
									placeholder="Enter a message"
									rows={4}
									value={message_text}
									onChange={(e) => set_message_text(e.target.value)}
									onBlur={() =>
										messageValidator.current.showMessageFor("message_text")
									}
								/>
								{messageValidator.current.message(
									"Message",
									message_text,
									"required",
									{ className: "text-danger py-1" },
								)}
							</Col>
						</Row>
					</Modal>
				) : null}

				<div className={props.isFromVista ? "vista_social_container" : ""}>
					<div className="social_container w-100 bg-white p-3">
						<div className="d-flex pb-4 border-bottom">
							{console.log("social wall data", socialWallData)}
							<div className="d-block">
								<h6>Sponsored by</h6>
								<img
									width={250}
									height={250}
									src={
										socialWallData?.social_wall_moderators_details?.logo_url
											? socialWallData?.social_wall_moderators_details?.logo_url
											: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
									}
									className="mx-2"
								/>
							</div>
							<div className="ms-3">
								<h5 className="fs-pink mt-4 vista_social_title">
									{socialWallData?.social_wall_moderators_details
										?.social_wall_title
										? socialWallData?.social_wall_moderators_details
											?.social_wall_title
										: "NA"}
								</h5>
								<p>
									{socialWallData?.social_wall_moderators_details
										?.social_wall_description
										? socialWallData?.social_wall_moderators_details
											?.social_wall_title
										: "NA"}
								</p>
								<h6 className="display-inline mb-2">From our sponsor : </h6>
								{socialWallData?.brand_sponsorship_details?.[0]
									?.is_asset_enable &&
									socialWallData?.zone_sponsor_assets_details !== null &&
									socialWallData?.zone_sponsor_assets_details
										?.zones_assets_id?.[0]?.asset_file_url ? (
									<Button
										onClick={() =>
										(window.location.href =
											socialWallData?.zone_sponsor_assets_details?.zones_assets_id?.[0]?.asset_file_url)
										}
										className="audi_pdfBtn mb-3 mx-auto"
									>
										<Download />
										<span className="ms-2 fs-16">sponsorasset.pdf</span>
									</Button>
								) : (
									""
								)}
								{/* {console.log("social media posts", socialMediaPost)} */}

								<div className="w-100 d-flex justify-content-start flex-wrap">
									{socialWallData &&
										socialWallData.brand_sponsorship_details &&
										socialWallData?.brand_sponsorship_details?.[0]
											?.is_chat_enable &&
										socialWallData.brand_sponsorship_details[0]?.channel_id ? (
										<Button
											className="p-1 social_chat_btn mb-2"
											onClick={() => {
												setMessageModal({
													isOpen: true,
													channel_id:
														socialWallData?.brand_sponsorship_details[0]
															?.channel_id,
												});
											}}
										>
											<Chat />
											<span className="ms-2">Chat</span>
										</Button>
									) : (
										""
									)}
									{/* <Button className="social_chat_btn mb-2">
                <Chat />
                <span className="ms-2">Chat</span>
              </Button> */}

									{socialWallData &&
										socialWallData?.brand_sponsorship_details &&
										socialWallData?.brand_sponsorship_details?.[0]
											?.is_video_call_enable &&
										socialWallData?.brand_sponsorship_details[0]?.channel_id ? (
										<Button
											onClick={async () => {
												let channel_id =
													socialWallData?.brand_sponsorship_details[0]
														?.channel_id;
												const response = await videoRequestAPI(channel_id);
												if (response.data.code === 200)
													toast.success(response.data.message);
												else toast.error(response.data.message);
											}}
											className="social_video_btn ms-2 mb-2"
										>
											<Video />
											<span className="ms-2">Video Call</span>
										</Button>
									) : (
										""
									)}

									{socialWallData &&
										socialWallData?.brand_sponsorship_details?.[0]
											?.is_demo_room_enable &&
										socialWallData?.brand_sponsorship_details?.[0]
											?.zone_demo_room_link ? (
										<Button className="social_demo_btn ms-2 mb-2">
											{console.log(
												socialWallData?.brand_sponsorship_details?.[0]
													?.zone_demo_room_link,
											)}
											<Video />
											<span
												className="ms-2"
												onClick={() => {
													window.open(
														socialWallData?.brand_sponsorship_details?.[0]
															?.zone_demo_room_link,
														"_blank",
													);
												}}
											>
												Join the Demo Room
											</span>
										</Button>
									) : (
										""
									)}

									{/* <Button className="social_booth_btn ms-2 mb-2">
                  <BoothRed />
                  <span className="ms-2">Visit Booth</span>
                </Button> */}
								</div>
							</div>
						</div>
						{/* Bottom Section */}
						<div className="social_bottom d-flex justify-content-between">
							<div className="social_input_post">
								<h5 className="mt-4 mb-4">Post</h5>
								<TextArea
									name="post_content"
									rows={4}
									className="fs-bold-14"
									placeholder="Enter A Description Here"
									value={fields?.post_content}
									onChange={(e) =>
										handleFieldsChange(e.target.value, "post_content")
									}
								/>
								{validator.current.message(
									"post_content",
									fields.post_content,
									"required|text",
									{ className: "text-danger py-2" },
								)}
								<div className="d-flex justify-content-start mt-3">
									<FileUpload
										labelClassName="rounded border-pink btn-svg px-3 py-1"
										labelTitle="Logo Image Upload"
										inputType="file"
										inputOnChange={handleImgChange1}
										inputId="social_wall_logo"
										inputName="social_wall_logo"
										name="logo"
									// accept="image/*"
									/>

									{socialWallData?.social_wall_moderators_details
										?.social_wall_title ? (
										<Button
											className="social_postBtn ms-3 fs-16"
											onClick={addPost}
										>
											Submit Post
										</Button>
									) : (
										<Button
											className="social_postBtn ms-3 fs-16"
											onClick={addPost}
											disabled
										>
											Submit Post
										</Button>
									)}
								</div>
							</div>

							<div className="social_postlist mt-4 ms-4">
								<div className="d-flex justify-content-between  w-100 mb-2 ">
									<h5 className="w-50">Post List</h5>

									<Button className="social_recent_sortBtn ms-4">
										<Sort /> <span className="ms-2">Recent</span>
									</Button>
									<Button
										onClick={() => {
											sortByLike();
										}}
										className="social_like_sortBtn ms-4"
									>
										<Sort /> <span className="ms-2">Likes</span>
									</Button>

									<span className="ms-4">Visible</span>
								</div>
								<div className="social_post_container">
									{socialMediaPost?.map((data, index) => {
										return (
											<div className="d-flex mb-3">
												<div className="social_post border d-flex  p-3">
													<img
														width={120}
														height={111}
														className="rounded m-1 mx-2"
														src={
															data.logo_image_url ??
															"https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
														}
													/>
													<div className="d-flex w-100">
														<span className="ms-2 w-75">
															<p className="mb-4 mt-2 fs-16">
																{data.post_content}
															</p>
															<span className="fs-14 text-bold">
																<b>- {data.created_user_name}</b>
															</span>
															<p className="fs-14 mt-1 text-muted">
																<span className="mx-1">
																	{moment(data.created_at).format("DD-MM-YYYY")}
																</span>
																<span className="mx-2">
																	{moment(data.created_at).format("HH:mm")}
																</span>
															</p>
															{/* <p>12-10-21 10:25</p> */}
														</span>
														<div className="d-flex align-self-end mb-4 border-start w-50">
															{data?.socail_media_post_created_by?.users_users_social_media_handles_user_id?.map(
																(item) => {
																	let { social_media } = item;
																	let url = "";
																	if (
																		item?.social_media_url.startsWith(
																			"http://",
																		) ||
																		item?.social_media_url.startsWith(
																			"https://",
																		)
																	) {
																		url = item?.social_media_url;
																	} else {
																		url = "https://" + item.social_media_url;
																	}

																	if (social_media === "Instagram") {
																		return (
																			<div
																				className=""
																				onClick={(e) => {
																					window.open(url, "_blank");
																				}}
																			>
																				<InstagramOutlined
																					style={{
																						fontSize: "25px",
																						opacity: "0.5",
																					}}
																					className="ms-2"
																				/>
																			</div>
																		);
																	} else if (social_media === "LinkedIn") {
																		return (
																			<div
																				onClick={() =>
																					window.open(url, "_blank")
																				}
																			>
																				<LinkedinOutlined
																					style={{
																						fontSize: "25px",
																						opacity: "0.5",
																					}}
																					className="ms-2"
																				/>
																			</div>
																		);
																	} else if (social_media === "Twitter") {
																		return (
																			<div
																				onClick={() =>
																					window.open(url, "_blank")
																				}
																			>
																				<TwitterOutlined
																					style={{
																						fontSize: "25px",
																						opacity: "0.5",
																					}}
																					className="ms-2"
																				/>
																			</div>
																		);
																	} else if (social_media === "facebook") {
																		return (
																			<div
																				onClick={() =>
																					window.open(url, "_blank")
																				}
																			>
																				<FacebookOutlined
																					style={{
																						fontSize: "25px",
																						opacity: "0.5",
																					}}
																					className="ms-2"
																				/>
																			</div>
																		);
																	}
																},
															)}

															<div
																className="ms-3"
																onClick={() =>
																	socialWallLike(
																		data.post_id,
																		data.is_like === true ? false : true,
																	)
																}
															>
																{data.is_like === true ? (
																	<HeartFilled
																		style={{
																			color: "#ef0855",
																			fontSize: "27px",
																		}}
																	/>
																) : (
																	<HeartOutlined
																		style={{
																			color: "#ef0855",
																			fontSize: "27px",
																		}}
																	/>
																)}
															</div>
															<span className="fs-pink my-1 mx-2">
																{data.post_likes_count}
															</span>
															{/* <div className="ms-5 social_edit"></div>
                      <div className="ms-2 social_delete"></div> */}
														</div>
													</div>
												</div>

												<Switch
													className=" ms-2"
													checked={data.is_visible ? true : false}
													onChange={() =>
														socialWallPostVisibility(
															data.post_id,
															data.is_visible === true ? false : true,
														)
													}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
};

export default SocialWall;
