import React from "react";
import { logo } from "../Images/Images";
import "./index.css";

function CommonLoader(props) {
  return (
    <div className="appLoader">
      <img src={logo} alt="logo" />
    </div>
  );
}

export default CommonLoader;
