import React, { useEffect, useState } from "react";
import "./ExpoMap.css";

import { Select } from "antd";

import { Layout, Menu, Breadcrumb } from "antd";
import {
	UserOutlined,
	LaptopOutlined,
	NotificationOutlined,
} from "@ant-design/icons";
import App from "../Vista/src/App";
import { Chat } from "../../components/svgJS/Chat";
import { ExpoHallSvg } from "../../components/svgJS/ExpoHallSvg";

import { Menu1 } from "../../components/svgJS/Menu1";
import { Chartdiagram } from "../../components/svgJS/Chartdiagram";
import { Calendar3 } from "../../components/svgJS/Calendar3";
import { Conference } from "../../components/svgJS/Conference";
import { Meeting } from "../../components/svgJS/Meeting";
import VistaApp from "../Vista/src/VistaApp";

import ExpoHalls from "./ExpoHalls";
import Brands from "./Brands";
import MicrosoftSurface from "../ExpoMap/MicrosoftSurface";
import BrandZoneDetails from "../ExpoMap/BrandZoneDetails";
import { useDispatch, useSelector } from "react-redux";
import {
	setHeaderData,
	setHeaderEventList,
} from "../../redux/slice/headerData";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const { Option } = Select;

const ExpoMap = (props) => {
	const [selectedMenuItem, setSelectedMenuItem] = useState("1");
	const dispatch = useDispatch();

	const [brandObj, setBrandObj] = useState({
		event_id: null,
		brand_id: null,
		show: false,
	});

	const componentsSwitch = (key) => {
		switch (key) {
			case "1":
				return <ExpoHalls />;
			case "2":
				return <Brands setBrandObj={setBrandObj} />;
			default:
				break;
		}
	};

	useEffect(() => {
		dispatch(
			setHeaderData({
				title: "Expo Map",
				breadcrumbs: ["Expo Map", "Expo Halls"],
			}),
		);
		return () => {
			dispatch(
				setHeaderData({
					title: "",
					breadcrumbs: [],
				}),
			);
			dispatch(setHeaderEventList([]));
		};

		console.log("local data", localStorage.getItem("Authorization"));
	}, []);

	useEffect(() => {
		if (selectedMenuItem === "1") {
			dispatch(
				setHeaderData({
					title: "Expo Map",
					breadcrumbs: ["Expo Map", "Expo Halls"],
				}),
			);
		} else if (selectedMenuItem === "2") {
			dispatch(
				setHeaderData({
					title: "Expo Map",
					breadcrumbs: ["Expo Map", "Brands"],
				}),
			);
		}
	}, [selectedMenuItem]);

	if (brandObj.show) {
		// console.log("brandObj", brandObj);
		// return <MicrosoftSurface brandObj={brandObj} />;
		return <BrandZoneDetails brandObj={brandObj} />;
	} else
		return (
			<>
				<Layout>
					<Layout>
						{/* <Sider width={200} className="site-layout-background expomap_sider">
              <Menu
                mode="inline"
                selectedKeys={selectedMenuItem}
                style={{ height: "100%", borderRight: 0 }}
                onClick={e => setSelectedMenuItem(e.key)}
                className="expomap_menu"
              >
                <Menu.Item
                  className="expomap_list"
                  icon={<ExpoHallSvg />}
                  key="1"
                >
                  <span className="expomap_link_list">Expo Halls</span>
                </Menu.Item>
                <Menu.Item className="expomap_list" icon={<Menu1 />} key="2">
                  <span className="expomap_link_list">Brands</span>
                </Menu.Item>

                <Menu.Item disabled>
                <p className="fs-pink">Quick jump to:</p>
                </Menu.Item>
                <Menu.Item className="expomap_list" icon={<Menu1 />} key="3">
                  <span className="expomap_link_list">Reception</span>
                </Menu.Item>
                <Menu.Item className="expomap_list" icon={<Meeting />} key="4">
                  <span className="expomap_link_list"> Concourse</span>
                </Menu.Item>
                <Menu.Item
                  className="expomap_list"
                  icon={<Conference />}
                  key="5"
                >
                  <span className="expomap_link_list">Main Auditorium</span>
                </Menu.Item>
                <Menu.Item className="expomap_list" icon={<Meeting />} key="6">
                  <span className="expomap_link_list">
                    Satellite Auditorium
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="expomap_list"
                  icon={<Chartdiagram />}
                  key="7"
                >
                  <span className="expomap_link_list">Networking Lounges</span>
                </Menu.Item>
                <Menu.Item
                  className="expomap_list"
                  icon={<Calendar3 />}
                  key="8"
                >
                  <span className="expomap_link_list">Event Calendar</span>
                </Menu.Item>
              </Menu>
            </Sider> */}
						<Layout>
							<Content
								className={
									props.isFromVista
										? "vista_expomap_container"
										: "remove_scroll_vista"
								}
								style={{ backgroundColor: "#FBFBFB" }}
							>
								<VistaApp props={props} />
								{/* {componentsSwitch(selectedMenuItem)} */}
							</Content>
						</Layout>
					</Layout>
				</Layout>
			</>
		);
};

export default ExpoMap;
