import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  Checkbox,
  Modal
} from "antd";
import { BinWhite } from "../../components/svgJS/Bin";
import { ImgUpload } from "../../components/svgJS/ImgUpload";
import { EmailRed, EmailWhite } from "../../components/svgJS/EmailRed";
import { useMediaQuery } from "react-responsive";
import { CalenderRed, CalenderGrey } from "../../components/svgJS/CalenderRed";
import { PadLock } from "../../components/svgJS/PadLock";
import { viewUserDetails } from "../../redux/slice/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { Link } from "react-router-dom";
import { constant } from "lodash-es";
import CommonLoader from "../../components/Widgets/CommonLoader";

const AddUser = ({ match }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  var CryptoJS = require("crypto-js");

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [loadingUser, setLoadingUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredZone, setFilteredZone] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    // const zoneArray = zoneList?.filter(data => data?.brand?.brand_id === fields?.brand_id);
    setFilteredZone(
      zoneList?.filter(data => data?.brand_id === userData?.brand_id)
    );
    // console.log("zoneArray....",zoneArray)
  }, []);

  useEffect(() => {
    setLoadingUser(true)
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }
    getUserDetail();
    getSocialMediaList();
    getZoneList();
    getJobTitle();
    setTimeout(() => {
      setLoadingUser(false);
    }, 2000);
  }, []);

  const getUserDetail = async () => {
    const response = await dispatch(
      viewUserDetails({ user_id: match.params.userid })
    );

    console.log("response...", response.payload.data);

    const { details } = response.payload.data;

    setUserData(details);

    console.log("Detailssss", details);
  };

  const getSocialMediaList = () => {
    console.log("getSocialMediaList");
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then(response => {
        setSocialMediaList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getJobTitle = () => {
    ApiCall("get", null, "/admin/jobtitle/get", null)
      .then(response => {
        console.log("jobtitle Response..", response.data.data);
        setJobTitle(response.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then(response => {
        console.log("Response of zone api ", response.data.data.rows);
        // setZoneList(response.data.data.rows);
        let constantData = response.data.data.rows.map(response => {
          return {
            is_assigned: false,
            is_always_get_messages: false,
            ...response
          };
        });
        console.log("constantData..", constantData);
        return constantData;
      })
      .then(data => {
        console.log("zone data", data);
        setZoneList(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const socialMediaAvailable = userData.users_users_social_media_handles_user_id?.some(
    social_media => social_media.social_media_id
  );
  console.log("socialmedia check", socialMediaAvailable);

  if (loadingUser) {
    return <CommonLoader />;
  } else
    return (
      <div className="view-all-common">
        <h4 className="fs-24">View User</h4>
        <Breadcrumb className="my-2" separator=">">
          <Breadcrumb.Item>
            <Link to="/usermanagement">Admin Console</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">User Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/viewuser">View User</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="bg-white px-5 py-3">
          <div className="row my-3">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">User Editor</h6>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">Active</div>
            <div className="col-1">
              <Switch checked={userData.is_active} />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Select Event
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Input
                name=""
                id=""
                className="w-25 border-gray rounded fs-bold-14"
                placeholder="Enter First Name"
                value={userData?.event_name}
                disabled
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              First Name
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Input
                name=""
                id=""
                className="w-25 border-gray rounded fs-bold-14"
                placeholder="Enter First Name"
                value={userData?.first_name}
                disabled
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Surname
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Input
                name="second name"
                id=""
                className="w-25 border-gray rounded fs-bold-14"
                placeholder="Enter Second Name"
                value={userData?.last_name}
                disabled
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Email Address
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Input
                name=""
                id=""
                className="w-25 border-gray rounded fs-bold-14"
                placeholder="Enter Email"
                value={userData.email}
                disabled
              />
            </div>
          </div>

          <div className="row my-3">
            <div className="col-3 text-end">
              Profile Picture
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Button className="border-pink rounded" onClick={showModal}>
                View File
              </Button>
              <Modal
                title="Profile Picture"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={400}
              >
                <img
                  src={userData.image_url}
                  alt="Uploaded Profile Picture"
                  className="img-fluid uploaded-image-modal-preview"
                />
              </Modal>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Company
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Select
                showSearch
                className="w-25 fs-bold-14"
                placeholder="Select Company"
                optionFilterProp="children"
                value={userData.company}
                disabled
              >
                <Option value={userData.company} Selected>
                  {userData.company}
                </Option>
              </Select>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Job Role
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Select
                showSearch
                className="w-25 fs-bold-14"
                placeholder="Select Job"
                optionFilterProp="children"
                value={userData.job_title_id}
                disabled
              >
                {jobTitle &&
                  jobTitle?.map(data => {
                    return (
                      <Option value={data.job_title_id}>{data.job_title}</Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">Country</div>
            <div className="col-9">
              <Select
                showSearch
                className="w-25 fs-bold-14"
                placeholder="Select Country"
                optionFilterProp="children"
                value={userData?.country?.country_id}
                disabled
              >
                <Option value={userData?.country?.country_id} Selected>
                  {userData?.country?.country}
                </Option>
              </Select>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">User Type</div>
            <div className="col-9">
              <Select
                showSearch
                className="w-25 fs-bold-14"
                placeholder="Select User"
                optionFilterProp="children"
                value={userData.user_type}
                disabled
              >
                <Option value={userData.user_type} Selected>
                  {userData.user_type}
                </Option>
              </Select>
            </div>
          </div>

          {userData.user_type === "AGENT" && (
            <>
              <div className="row my-3">
                <div className="col-3 text-end">Admin</div>
                <div className="col-9">
                  <Switch checked={userData.is_admin} />
                </div>
              </div>
              {/* <div className="row my-3">
              <div className="col-3 text-end">Speaker</div>
              <div className="col-9">
                <Switch checked={userData.is_speaker} />
              </div>
            </div> */}
              <div className="row my-3">
                <div className="col-3 text-end">
                  Brand
                  <sup>
                    <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                      *
                    </span>
                  </sup>
                </div>
                <div className="col-9">
                  <Input
                    name=""
                    id=""
                    className="w-25 border-gray rounded fs-bold-14"
                    placeholder="Enter New Email"
                    value={userData.brand_name}
                    disabled
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-3 text-end">Zone Tags</div>
                <div className="col-8 border-bottom zone-tag-scroll-div">
                  <div className="row border border-gray rounded py-1 sticky-top bg-white">
                    <div className="col-4">
                      <b>Zones</b>
                    </div>
                    <div className="col-4">
                      {" "}
                      <b>Assign</b>
                    </div>
                    <div className="col-4">
                      <b>Always get Msgs</b>
                    </div>
                  </div>

                  {/* {filteredZone && filteredZone?.map((data, idx) => ( */}

                  {userData.user_zones.map(data => (
                    <div className="row border-start border-end">
                      <div className="col-4">
                        <div className="my-3 fs-bold-14">
                          {data?.zone?.zone_title}
                        </div>
                      </div>

                      <div className="col-4 text-start pt-3">
                        <Checkbox
                          className="mx-3"
                          name="assign"
                          checked={data?.is_assigned}
                        />
                      </div>

                      <div className="col-4 text-start pt-3">
                        <Checkbox
                          className=" mx-3"
                          name="alwaysGetMags"
                          checked={data?.is_always_get_messages}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="row my-3">
            <div className="col-3 text-end">
              Tier
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <Select
                showSearch
                className="w-25 fs-bold-14"
                placeholder="Select Tier"
                optionFilterProp="children"
                value={userData?.tier?.tier_id}
                disabled
              >
                <Option value={userData?.tier?.tier_id} Selected>
                  {userData?.tier?.tier}
                </Option>
              </Select>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 text-end">
              Bio
              <sup>
                <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                  *
                </span>
              </sup>
            </div>
            <div className="col-9">
              <TextArea
                rows={6}
                className="w-50 fs-bold-14"
                placeholder="Enter a Bio here"
                value={userData.bio}
                name=""
                disabled
              />
            </div>
          </div>
          <div className="row border-bottom-gray pb-4">
            <div className="col-3"></div>
            <div className="col-9">
              <Button
                className={
                  "px-3 fs-pink border-pink rounded btn-svg" +
                  (Tablet ? " mt-2" : " mx-2")
                }
              >
                <CalenderGrey /> <span className="mx-2">View Calendar</span>
              </Button>
              <Button className="px-3  rounded btn-svg">
                <PadLock /> <span className="mx-2">Reset Password</span>
              </Button>
              <br />
              <br />
              <span className="">
                NB: This will send an email to the users current email address to
                create a new password.
              </span>
            </div>
          </div>
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">
                  External & Social Links
                </h6>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12 mt-1">
                <span className="fs-16">
                  {/* NB: Leave blank to use the same social links as your brand/global. Only enter details here if you want specific social links for this zone. */}
                  NB: If you paste your details into these boxes then anyone using
                  this site will be able to view/contact you via these platforms.
                </span>
              </div>
            </div>

            <div className="social-media-div border-bottom-gray pb-4 mb-4">
              {socialMediaList &&
                socialMediaList.length > 1 &&
                socialMediaList?.map((data, idx) => {
                  return (
                    <div className="row mt-4 ">
                      <div className="col-2 text-end">{data?.social_media}</div>
                      <div className="col-1 text-center ">
                        <Switch
                          checked={
                            userData?.users_users_social_media_handles_user_id?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-4 text-start ">
                        <Input
                          placeholder="https://webaddress.com"
                          className="w-100 fs-bold-14"
                          value={
                            userData.users_users_social_media_handles_user_id?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data?.social_media_id
                            )
                              ? userData.users_users_social_media_handles_user_id[
                              userData.users_users_social_media_handles_user_id.findIndex(
                                social_media =>
                                  social_media.social_media_id ===
                                  data?.social_media_id
                              )
                              ]["social_media_url"]
                              : ""
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
        <div
          className="shadow-sm p-3 d-flex justify-content-between"
          style={{ backgroundColor: "#eeeeee" }}
        >
          <Link to="/usermanagement">
            <Button className="px-3 d-block button-pink rounded">Cancel</Button>
          </Link>
          {/* <Button type="primary">Send Invitation</Button> */}
        </div>
      </div>
    );
};

export default AddUser;
