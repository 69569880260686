import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, TimePicker, Input, Select, Button } from "antd";
import moment from "moment";
import "../eventCalender.css";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import {
  createTickerConfig,
  updateTickerConfig,
} from "../../../redux/slice/eventCalender";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getZones,
  getEvents,
  getUsers,
  getBrandDropdowns,
  getTickerPickerList,
  getTickerData,
} from "../../../utils/commonApis";
import { ifTimeSlotAvailable } from "../eventCalender.utils";
import { mapTickerSchema } from "./modals.utils";
import { cloneDeep } from "lodash";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";

const TickerConfiguration = (props) => {
  const { tickerConfigData } = props;
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tickerLocalData, setTickerLocalData] = useState(mapTickerSchema(null));
  const [loader, setLoader] = useState(true);
  const [tickerPicker, setTickerPicker] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [usersCollection, setUsersCollection] = useState([]);
  const [eventsCollection, setEventsCollection] = useState([]);
  const [options, setOptions] = useState({ data: [], name: "" });

  useEffect(async () => {
    const brandsHere = await getBrandDropdowns(props.tickerConfigData.event_id);
    setBrandsCollection(brandsHere);
    const zonesHere = await getZones();
    setZoneCollection(zonesHere);
    const usersHere = await getUsers();
    setUsersCollection(usersHere);
    const eventsHere = await getEvents();
    setEventsCollection(eventsHere);

    const tickerPickerHere = await getTickerPickerList();
    setTickerPicker(tickerPickerHere);
    let tickerAPIData = await getTickerData({
      event_ticker_id: tickerConfigData.event_ticker_id,
    });

    if (tickerAPIData) {
      let tickerSchema = mapTickerSchema(tickerAPIData);
      setTickerLocalData(tickerSchema);
      if (tickerSchema.ticker_link_details) {
        handleAllDropdowns(
          tickerSchema,
          tickerSchema.ticker_link_details.reference_type,
          usersHere
        );
        console.log("tickerApiData", tickerSchema);
      }
      setLoader(false);
    } else setLoader(false);
  }, []);

  const handleAllDropdowns = async (tickerLocalDataHere, val, usersHere) => {
    switch (val) {
      case "BRAND":
        let clonedBrands = cloneDeep(brandsCollection);
        const filteredData =
          clonedBrands &&
          clonedBrands.filter(
            (item) =>
              tickerLocalDataHere &&
              tickerLocalDataHere.ticker_link_details &&
              item.brand_id === tickerLocalDataHere.ticker_link_details.brand_id
          );
        setOptions({
          data: filteredData,
          name: "brand",
        });
        break;
      case "ZONE":
        {
          let clonedZones = cloneDeep(zoneCollection);
          const filteredData =
            clonedZones &&
            clonedZones.filter(
              (item) =>
                tickerLocalDataHere &&
                tickerLocalDataHere.ticker_link_details &&
                item.brand_id ===
                  tickerLocalDataHere.ticker_link_details.brand_id
            );
          setOptions({
            data: filteredData,
            name: "zone",
          });
        }
        break;
      case "PERSON":
        {
          let clonedUsers = cloneDeep(usersHere);
          const filteredData =
            clonedUsers &&
            clonedUsers.filter(
              (item) =>
                tickerLocalDataHere &&
                tickerLocalDataHere.ticker_link_details &&
                item.brand_id ===
                  tickerLocalDataHere.ticker_link_details.brand_id
            );

          setOptions({
            data: filteredData,
            name: "user",
          });
        }

        break;
      case "EVENT":
        {
          if (tickerLocalDataHere.ticker_link_details.brand_id) {
            const eventsHere = await getEvents({
              brand_id: tickerLocalDataHere.ticker_link_details.brand_id,
            });
            setOptions({
              data: eventsHere,
              name: "event",
            });
          } else {
            setOptions({
              data: eventsCollection,
              name: "event",
            });
          }
        }

        break;
      default:
        setOptions({
          data: [],
          name: "",
        });
    }
  };

  const createUpdateAPI = async (dataToUpdate) => {
    console.log("dataToUpdate/create = ", dataToUpdate);
    if (dataToUpdate.event_ticker_id) {
      let updateRes = await dispatch(updateTickerConfig(dataToUpdate));
      console.log("update register response", updateRes);
      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error update Ticker");
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      let createRes = await dispatch(createTickerConfig(dataToUpdate));
      console.log("create tickerConfig response", createRes);
      if (createRes?.payload?.data?.code === 200) {
        console.log("in 200 create sponser", createRes);
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error create Ticker");
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleTickerConfigSubmit = () => {
    if (validator.current.allValid()) {
      const { tickerConfigData } = props;
      const dateHere = tickerConfigData.currentDateForCalender;
      let clonedData = cloneDeep(tickerLocalData);
      let dataToCheckTime = cloneDeep(tickerLocalData);

      clonedData.ticker_details.start_time =
        dateHere +
        " " +
        moment(clonedData.ticker_details.start_time).format("HH:mm:ss");
      clonedData.ticker_details.end_time =
        dateHere +
        " " +
        moment(clonedData.ticker_details.end_time).format("HH:mm:ss");

      clonedData.event_id = props.tickerConfigData.event_id;

      if (
        clonedData.ticker_details.start_time ===
        clonedData.ticker_details.end_time
      )
        toast.error("Please change end time");
      else if (
        !ifTimeSlotAvailable(
          moment(dataToCheckTime.ticker_details.start_time),
          moment(dataToCheckTime.ticker_details.end_time),
          props.tickerConfigData.existingTimings
        )
      ) {
        toast.error("Timeslot is not available");
      } else createUpdateAPI(clonedData);
    } else {
      validator.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const onChangeFields = (val, key, parentKey) => {
    let clonedData = cloneDeep(tickerLocalData);
    let dataToUpdate = clonedData[parentKey];
    dataToUpdate[key] = val;
    setTickerLocalData({ ...tickerLocalData, [parentKey]: dataToUpdate });
  };

  return (
    <Modal
      title="Ticker Configuration"
      visible={true}
      width={950}
      onOk={() => props.onCloseModal()}
      onCancel={() => props.onCloseModal()}
      footer={[
        <Button
          className="ticker_configuration_cancelBtn"
          key="cancel"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          className="ticker_configuration_SaveBtn"
          key="save"
          type="primary"
          onClick={handleTickerConfigSubmit}
        >
          Save
        </Button>,
      ]}
    >
      {loader ? (
        <CommonLoader />
      ) : (
        <div className="ticker_configuration_form">
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Start & End Time</p>
            </Col>
            <Col span={8}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="start_time"
                className="ticker_configuration_timepicker"
                format={format}
                style={{ width: "100%" }}
                value={tickerLocalData.ticker_details.start_time}
                onSelect={(val) =>
                  onChangeFields(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "start_time",
                    "ticker_details"
                  )
                }
                onBlur={() => validator.current.showMessageFor("start_time")}
              />
              {validator.current.message(
                "Start Time",
                tickerLocalData.ticker_details.start_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
            <Col span={8} offset={2}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="end_time"
                className="ticker_configuration_timepicker"
                disabledDate={(d) =>
                  !d ||
                  d.isSameOrBefore(tickerLocalData.ticker_details.start_time)
                }
                value={tickerLocalData.ticker_details.end_time}
                format={format}
                style={{ width: "100%" }}
                onSelect={(val) =>
                  onChangeFields(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "end_time",
                    "ticker_details"
                  )
                }
                onBlur={() => validator.current.showMessageFor("end_time")}
              />
              {validator.current.message(
                "End Time",
                tickerLocalData.ticker_details.end_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Picker</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="+ Create New Ticker"
                value={tickerLocalData.ticker_details.ticker_id}
                onChange={(val) => {
                  let clonedData = cloneDeep(tickerLocalData);
                  let clonedTickerDetails = cloneDeep(
                    clonedData.ticker_details
                  );
                  let record =
                    tickerPicker &&
                    tickerPicker.filter((item) => item.ticker_id === val);
                  if (record && record[0]) {
                    clonedTickerDetails.ticker_name = record[0].ticker_name;
                    clonedTickerDetails.ticker_description =
                      record[0].ticker_description;
                    if (record[0].ticker_fixed_title)
                      clonedTickerDetails.ticker_fixed_title =
                        record[0].ticker_fixed_title;
                  }

                  clonedData.ticker_details = clonedTickerDetails;
                  setTickerLocalData(clonedData);
                }}
              >
                {tickerPicker &&
                  tickerPicker.map((item, i) => {
                    return (
                      <Option
                        key={item.ticker_id + "_ticker"}
                        value={item.ticker_id}
                      >
                        {item.ticker_name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Name</p>
            </Col>
            <Col span={18}>
              <Input
                name="tickerConfig_ticker_name"
                className="ticker_configuration_inputTitle"
                placeholder="Enter a name here"
                value={tickerLocalData.ticker_details.ticker_name}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_name",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_ticker_name")
                }
              />
              {validator.current.message(
                "Name",
                tickerLocalData.ticker_details.ticker_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="modal_row">
            <Col span={6} className="ticker_configuration_label">
              <p>Fixed Title</p>
            </Col>
            <Col span={18}>
              <Input
                name="tickerConfig_fixed_title"
                className="ticker_configuration_inputTitle"
                placeholder="Enter a name here"
                value={tickerLocalData.ticker_details.ticker_fixed_title}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_fixed_title",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_fixed_title")
                }
                minLength={1}
                maxLength={100}
              />
              {validator.current.message(
                "Fixed Title",
                tickerLocalData.ticker_details.ticker_fixed_title,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row
            className="ticker_configuration_row"
            style={{ borderBottom: "1px solid #EAE4F2", paddingBottom: "30px" }}
          >
            <Col span={6} className="ticker_configuration_label">
              <p>Ticker Text</p>
            </Col>
            <Col span={18}>
              <TextArea
                name="tickerConfig_ticker_text"
                id="tickerConfig_ticker_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a ticker text here"
                rows={4}
                value={tickerLocalData.ticker_details.ticker_description}
                onChange={(e) =>
                  onChangeFields(
                    e.target.value,
                    "ticker_description",
                    "ticker_details"
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_ticker_text")
                }
              />
              {validator.current.message(
                "Ticker Text",
                tickerLocalData.ticker_details.ticker_description,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="ticker_configuration_row">
            <Col span={6} className="ticker_configuration_label">
              <h6 style={{ color: "#000000", opacity: 1 }}>Link</h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Brand</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                name="tickerConfig_link_brand"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select a Brand"
                value={tickerLocalData.ticker_link_details.brand_id}
                onChange={(val) => {
                  let clonedData = cloneDeep(tickerLocalData);
                  let dataToUpdate = clonedData.ticker_link_details;
                  dataToUpdate.brand_id = val;
                  setTickerLocalData({
                    ...tickerLocalData,
                    ticker_link_details: dataToUpdate,
                  });
                  handleAllDropdowns(
                    tickerLocalData,
                    "PERSON",
                    usersCollection
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_brand")
                }
              >
                {brandsCollection &&
                  brandsCollection.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_brand"}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
              </Select>
              {validator.current.message(
                "Brand Name",
                tickerLocalData.ticker_link_details.brand_id,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Type</p>
            </Col>
            <Col span={8}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="tickerConfig_link_type"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select a Type"
                value={tickerLocalData.ticker_link_details.reference_type}
                onChange={(val) => {
                  if (tickerLocalData.ticker_link_details.brand_id) {
                    handleAllDropdowns(tickerLocalData, val, usersCollection);
                    let clonedData = cloneDeep(tickerLocalData);
                    let dataToUpdate = clonedData.ticker_link_details;
                    dataToUpdate.reference_type = val;
                    dataToUpdate.reference_id = undefined;

                    if (val === "BRAND")
                      dataToUpdate.reference_id = dataToUpdate.brand_id;

                    setTickerLocalData({
                      ...tickerLocalData,
                      ticker_link_details: dataToUpdate,
                    });
                  } else toast.error("Please select a brand");
                }}
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_type")
                }
              >
                <Option value="BRAND">BRAND</Option>
                <Option value="ZONE">ZONE</Option>
                <Option value="PERSON">USER</Option>
                <Option value="EVENT">EVENT</Option>
              </Select>
              {validator.current.message(
                "Type",
                tickerLocalData.ticker_link_details.reference_type,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col span={8} offset={1}>
              <p style={{ marginTop: "5px" }}>(Brand, Zone, Person, Event)</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6} className="ticker_configuration_label">
              <p>Select</p>
            </Col>
            <Col span={8}>
              {/* {tickerLocalData.ticker_link_details.reference_type ===
              "BRAND" ? (
                <span>{brandName}</span>
              ) : (
                <>
                  
                </>
              )} */}
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                showSearch
                name="tickerConfig_link_select"
                bordered={false}
                className="ticker_configuration_selectBrand"
                placeholder="Select"
                value={tickerLocalData.ticker_link_details.reference_id}
                onChange={(val) =>
                  onChangeFields(val, "reference_id", "ticker_link_details")
                }
                onBlur={() =>
                  validator.current.showMessageFor("tickerConfig_link_select")
                }
              >
                {options &&
                  options.data &&
                  options.data.map((item) => {
                    return (
                      <Option
                        key={item[`${options.name}_id`]}
                        value={item[`${options.name}_id`]}
                      >
                        {options.name === "user" || options.name === "event"
                          ? item[`${options.name}_name`]
                          : item[`${options.name}_title`]}
                      </Option>
                    );
                  })}
              </Select>
              {validator.current.message(
                "Select",
                tickerLocalData.ticker_link_details.reference_id,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col span={8} offset={1}>
              <p style={{ marginTop: "5px" }}>(Brand, Zone, Person, Event)</p>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default TickerConfiguration;
