import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./ProductZoneSessionedit.css";
import { Row, Col } from "antd";
import { TimePicker, DatePicker } from "antd";
import moment from "moment";
import {
  Modal,
  Button,
  Form,
  Input,
  Switch,
  InputNumber,
  Table,
  Divider,
} from "antd";
import {
  DownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  blackbin,
  close,
  eyeViewWhite,
  eyeView,
  cloudComputing,
} from "../../../components/Images/Images";
import { Select } from "antd";
import FileUpload from "../../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import {
  getZoneSessionDetails,
  updateZoneSessionDetails,
  createZoneSession,
} from "./../../../redux/slice/productZoneSession";
import {
  getJobTitles,
  getAgents,
  getBrands,
  getCountryList,
  getUsers,
  getZones,
  getUserTypeAgents,
  getAudiInvitationDetails,
} from "./../../../utils/commonApis";
import _, { cloneDeep } from "lodash";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { EyeView } from "../../../components/svgJS/EyeView";
import { Formik } from "formik";
import * as Yup from "yup";
import { CloseOutlined } from "@ant-design/icons";
import { ApiCall } from "../../../utils/api";

import {
  CalenderGrey,
  TimeCalendar,
} from "../../../components/svgJS/CalenderRed";
import { toast } from "react-toastify";

const ProductZoneSession = (appointmentData) => {
  console.log("appointmentData Zone Session", appointmentData);
  const dispatch = useDispatch();
  const [productZoneSession, setProductZoneSessionVisible] = useState(true);
  const format = "HH:mm";
  const { TextArea } = Input;
  const { Option } = Select;

  const [modal, setModalInfo] = useState(null);

  //Validations
  const productzonesession_validator = useRef(new SimpleReactValidator());
  const [productZoneSessionForceUpdate, setProductZoneSessionForceUpdate] =
    useState(false);

  const [agentsList, setAgentsList] = useState([]);
  // console.log("agentsList", agentsList);
  const [taggedAgents, setTaggedAgents] = useState([]);
  // console.log('taggedAgents', taggedAgents)

  const [usersList, setUsersList] = useState([]);
  const [selectedusersList, setselecetedUsersList] = useState([]);

  const [whiteListUsers, setWhiteListUsers] = useState([]);
  const [blackListUsers, setBlackListUsers] = useState([]);

  const [brandsList, setBrandsList] = useState([]);
  console.log("brandsList", brandsList);
  const [selectedbrandsList, setselectedBrandsList] = useState([]);
  console.log("selectedbrandsList", selectedbrandsList);
  const [whiteListBrands, setWhiteListBrands] = useState([]);
  const [blackListBrands, setBlackListBrands] = useState([]);

  const [countriesList, setCountriesList] = useState([]);
  const [selectedcountriesList, setselectedCountriesList] = useState([]);
  const [whiteListCountries, setWhiteListCountries] = useState([]);
  const [blackListCountries, setBlackListCountries] = useState([]);

  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [selectedjobTitlesList, setselectedJobTitlesList] = useState([]);
  const [whiteListJobTitles, setWhiteListJobTitles] = useState([]);
  const [blackListJobTitles, setBlackListJobTitles] = useState([]);

  const [zoneList, setzoneList] = useState([]);
  const [responceSessionData, setresponceSessionData] = useState();
  console.log("responceSessionData", responceSessionData);

  const [productZoneSessionFields, setProductZoneSessionFields] = useState({
    productZoneSession_start_date_time: moment(),
    productZoneSession_end_date_time: moment().add(30, "minutes"),
    productZoneSession_title: "",
    productZoneSession_description: "",
    is_ical_download_enabled: false,

    productZoneSession_specific_users: "",
    productZoneSession_brand_list: "",
    productZoneSession_country_list: "",
    productZoneSession_job_titles_list: "",

    productZoneSession_invitees_brand: "",
    productZoneSession_invitees_zone: "",
    productZoneSession_invitees_user_type: "",
    productZoneSession_invitees_country: "",
    productZoneSession_invitees_job_title: "",

    productZoneSession_prev_pending_date_time_1: "",
    productZoneSession_prev_pending_date_time_2: "",
    productZoneSession_invitation_title: "",
    productZoneSession_invitation_description: "",
    productZoneSession_invitation_is_ical_download_enabled: false,
    productZoneSession_invitation_re_invite: false,
    productZoneSession_schedule_invitation: true,
    productZoneSession_when_to_send_date_time: "",
    productZoneSession_schedule_now: false,
  });

  const [initial_state, setinitial_state] = useState({
    start_date_time: "",
    end_date_time: "",
    session_title: "",
    session_description: "",
    is_ical_download_enabled: false,

    assign_staff_ids: [1, 2],
    restricted_users: [1, 2],
    restricted_brands: [1, 2],
    restricted_countries: [1, 2],
    restricted_job_titles: [1, 2],

    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled__invite: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: "",

    invited_brands: [],
    invited_zones: [],
    invited_user_types: [],
    invited_countries: [],
    invited_job_titles: [],
  });

  const [invitationModal, setInvitationModal] = useState({
    isOpen: false,
    data: null,
    isLoader: false,
  });
  const [invitationTableData, setInvitationTableData] = useState([]);

  useEffect(async () => {
    let jobTitlesList = await getJobTitles({
      event_id: appointmentData.extraProps.eventId,
    });
    let countriesList = await getCountryList({
      event_id: appointmentData.extraProps.eventId,
    });
    let usertypeAgent = await getUserTypeAgents({
      zone_id: appointmentData.extraProps.zoneId,
      is_zone_calendar: true,
    });
    let brandsList = await getBrands({
      event_id: appointmentData.extraProps.eventId,
    });
    let usersList = await getUserTypeAgents({
      event_id: appointmentData.extraProps.eventId,
      is_zone_calendar: false,
    });
    let zoneList = await getZones({
      event_id: appointmentData.extraProps.eventId,
    });

    setAgentsList(usertypeAgent);
    setUsersList(usersList);
    setBrandsList(brandsList);
    console.log("brandsList effecttt", brandsList);
    setCountriesList(countriesList);
    setJobTitlesList(jobTitlesList);
    setzoneList(zoneList);

    const response = await ApiCall(
      "GET",
      "",
      "/admin/productzonesession/details/get",
      { configuration_id: appointmentData.data.configuration_id }
    );
    // console.log('response', response)
    setresponceSessionData(response?.data?.data);
    // setselectedBrandsList(response?.data?.data?.restricted_brands);
    // console.log(response?.data?.data?.restricted_brands);
    const { restricted_brands } = response?.data?.data;
    // console.log("restricted_brands", restricted_brands);
  }, []);

  useEffect(async () => {
    let temp_arrbrand = [];
    let temp_arr_selectedbrand = [];
    brandsList.forEach((elem) => {
      console.log("elem", elem);
      // const { brand_id } = elem;
      if (responceSessionData?.restricted_brands.includes(elem.brand_id)) {
        temp_arr_selectedbrand.push(elem);
      } else {
        temp_arrbrand.push(elem);
      }
    });
    setBrandsList(temp_arrbrand);
    setselectedBrandsList(temp_arr_selectedbrand);

    let temp_arrCountry = [];
    let temp_arr_selectedCountry = [];
    countriesList.forEach((elem) => {
      console.log("elem", elem);
      // const { brand_id } = elem;
      if (responceSessionData?.restricted_countries.includes(elem.country_id)) {
        temp_arr_selectedCountry.push(elem);
      } else {
        temp_arrCountry.push(elem);
      }
    });
    setCountriesList(temp_arrCountry);
    setselectedCountriesList(temp_arr_selectedCountry);

    let temp_arrJobTitle = [];
    let temp_arr_selectedJobTitle = [];
    jobTitlesList.forEach((elem) => {
      console.log("elem", elem);
      // const { brand_id } = elem;
      if (
        responceSessionData?.restricted_job_titles.includes(elem.job_title_id)
      ) {
        temp_arr_selectedJobTitle.push(elem);
      } else {
        temp_arrJobTitle.push(elem);
      }
    });
    setJobTitlesList(temp_arrJobTitle);
    setselectedJobTitlesList(temp_arr_selectedJobTitle);

    let temp_arrUser = [];
    let temp_arr_selectedUser = [];
    usersList.forEach((elem) => {
      console.log("elem", elem);
      // const { brand_id } = elem;
      if (responceSessionData?.restricted_users.includes(elem.user_id)) {
        temp_arr_selectedUser.push(elem);
      } else {
        temp_arrUser.push(elem);
      }
    });
    setUsersList(temp_arrUser);
    setselecetedUsersList(temp_arr_selectedUser);

    let temp_arrAgent = [];
    let temp_arr_selectedAgent = [];
    agentsList.forEach((elem) => {
      console.log("elem", elem);
      // const { brand_id } = elem;
      if (responceSessionData?.assign_staff_ids.includes(elem.user_id)) {
        temp_arr_selectedAgent.push(elem);
      } else {
        temp_arrAgent.push(elem);
      }
    });
    setAgentsList(temp_arrAgent);
    setTaggedAgents(temp_arr_selectedAgent);

    setinitial_state((prev) => ({
      ...prev,
      session_title: responceSessionData?.session_details?.session_title,
      session_description:
        responceSessionData?.session_details?.session_description,
      invitation_title: responceSessionData?.session_details?.invitation_title,
      invitation_description:
        responceSessionData?.session_details?.invitation_description,
    }));
  }, [responceSessionData]);

  const invitation_table_columns = [
    {
      title: "Date/Time",
      key: "date_time_to_invite",
      dataIndex: "date_time_to_invite",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return moment(record.date_time_to_invite).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "User Name",
      key: "user_name",
      dataIndex: "user_name",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return record.first_name + " " + record.last_name;
      },
    },
    {
      title: "User Type",
      key: "user_type",
      dataIndex: "user_type",
      width: "25%",
      align: "center",
      sorter: (a, b) => a.user_type - b.user_type,
    },
    {
      title: "Invite Status",
      key: "rsvp_type",
      dataIndex: "rsvp_type",
      width: "25%",
      align: "center",
      render: (text, record, index) => {
        return getStatusButton(record);
      },
      // sorter: (a, b) => a.rsvp_type - b.rsvp_type,
    },
  ];

  const getStatusButton = (ele) => {
    if (ele.rsvp_type === "PENDING")
      return (
        <Button className="adminSatellite_section_2_pending">Pending</Button>
      );
    else if (ele.rsvp_type === "YES")
      return (
        <Button className="adminSatellite_section_2_accepted">Accepted</Button>
      );
    else if (ele.rsvp_type === "NO")
      return (
        <Button className="adminSatellite_section_2_reject">Rejected</Button>
      );
    else return null;
  };

  const openInviteesModalAndApiCall = async (ele, isFromSingle) => {
    console.log("ele", ele);
    setInvitationModal({
      isOpen: true,
      data: ele,
      isLoader: true,
    });

    if (isFromSingle) {
      let params = {
        configuration_id: ele.configuration_id,
      };
      let invitationData = await ApiCall(
        "GET",
        null,
        "/admin/productzonesessioninvitation/details/get",
        params
      );
      console.log("invitationData", invitationData);
      setInvitationTableData(invitationData?.data?.data);
      return;
    } else {
      let params = {
        configuration_id: ele.configuration_id,
        invitation_setup_id: ele.invitation_setup_id,
      };
      let invitationData = await ApiCall(
        "GET",
        null,
        "/admin/productzonesessioninvitation/details/get",
        params
      );
      console.log("invitationData", invitationData);
      setInvitationTableData(invitationData?.data?.data);
      return;
    }
  };

  const [productZoneSessionFileUpload, setProductZoneSessionFileUpload] =
    useState("");

  const [productZoneSessionStartDateTime, setProductZoneSessionStartDateTime] =
    useState(productZoneSessionFields.productZoneSession_start_date_time);

  const [productZoneSessionCsvUpload, setProductZoneSessionCsvUpload] =
    useState("");

  const mapListData = (list, key, valuesList) => {
    let blackList = [],
      whiteList = [];
    list.map((x) => {
      if (!blackList.includes(x[key] || !whiteList.includes(x[key]))) {
        if (valuesList.includes(x[key])) blackList.push(x);
        else whiteList.push(x);
      }
    });
    return { blackList, whiteList };
  };

  const handleProductZoneSessionFieldsChange = (val, name) => {
    console.log({ ...productZoneSessionFields, [name]: val });
    setProductZoneSessionFields({ ...productZoneSessionFields, [name]: val });
  };

  const onSelectValueChange = (value, type) => {
    switch (type) {
      case "taggedAgents": {
        let list = _.map(taggedAgents, "user_id");
        list.push(value);
        let agentsListData = mapListData(agentsList, "user_id", list);
        setTaggedAgents(agentsListData.blackList);
        break;
      }

      case "blackListUsers": {
        let list = _.map(blackListUsers, "user_id");
        list.push(value);
        let userListData = mapListData(usersList, "user_id", list);
        setBlackListUsers(userListData.blackList);
        setWhiteListUsers(userListData.whiteList);
        break;
      }

      case "blackListBrands": {
        let list = _.map(blackListBrands, "brand_id");
        list.push(value);
        let brandListData = mapListData(brandsList, "brand_id", list);
        setBlackListBrands(brandListData.blackList);
        setWhiteListBrands(brandListData.whiteList);
        break;
      }

      case "blackListCountries": {
        let list = _.map(blackListCountries, "country_id");
        list.push(value);
        let countriesListData = mapListData(countriesList, "country_id", list);
        setBlackListCountries(countriesListData.blackList);
        setWhiteListCountries(countriesListData.whiteList);
        break;
      }

      case "blackListJobTitles": {
        let list = _.map(blackListJobTitles, "job_title_id");
        list.push(value);
        let jobTitlesListData = mapListData(
          jobTitlesList,
          "job_title_id",
          list
        );
        setBlackListJobTitles(jobTitlesListData.blackList);
        setWhiteListJobTitles(jobTitlesListData.whiteList);
        break;
      }
    }
  };

  function handleAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let agents_list = agentsList;
    let selectedAgents_list = taggedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    if (selectedAgents_list) {
      selectedAgents_list.push(agents_list[index]);
      agents_list.splice(index, 1);

      setTaggedAgents([...selectedAgents_list]);
      setAgentsList([...agents_list]);
      console.log("taggedAgents list12", taggedAgents);
    }
  }

  function handleSelectedAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let agents_list = agentsList;
    let selectedAgents_list = taggedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgentsList([...agents_list]);
    setTaggedAgents([...selectedAgents_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangeUser(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let user_list = usersList;
    let selecteduser_list = selectedusersList;

    let index = user_list.findIndex((agent) => agent.user_id === value);
    if (selecteduser_list) {
      selecteduser_list.push(user_list[index]);
      user_list.splice(index, 1);

      setselecetedUsersList([...selecteduser_list]);
      setUsersList([...user_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangeUser(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let user_list = usersList;
    let selecteduser_list = selectedusersList;

    let index = selecteduser_list.findIndex((agent) => agent.user_id === value);

    user_list.push(selecteduser_list[index]);
    selecteduser_list.splice(index, 1);

    setUsersList([...user_list]);
    setselecetedUsersList([...selecteduser_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangebrand(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let brand_list = brandsList;
    let selectedbrand_list = selectedbrandsList;

    let index = brand_list.findIndex((agent) => agent.brand_id === value);
    if (selectedbrand_list) {
      selectedbrand_list.push(brand_list[index]);
      brand_list.splice(index, 1);

      setselectedBrandsList([...selectedbrand_list]);
      setBrandsList([...brand_list]);
      console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangebrand(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let brand_list = brandsList;
    let selectedbrand_list = selectedbrandsList;

    let index = selectedbrand_list.findIndex(
      (agent) => agent.brand_id === value
    );

    brand_list.push(selectedbrand_list[index]);
    selectedbrand_list.splice(index, 1);

    setBrandsList([...brand_list]);
    setselectedBrandsList([...selectedbrand_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangecountry(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let country_list = countriesList;
    let selectedcountry_list = selectedcountriesList;

    let index = country_list.findIndex((agent) => agent.country_id === value);
    if (selectedcountry_list) {
      selectedcountry_list.push(country_list[index]);
      country_list.splice(index, 1);

      setselectedCountriesList([...selectedcountry_list]);
      setCountriesList([...country_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangecountry(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let country_list = countriesList;
    let selectedcountry_list = selectedcountriesList;

    let index = selectedcountry_list.findIndex(
      (agent) => agent.country_id === value
    );

    country_list.push(selectedcountry_list[index]);
    selectedcountry_list.splice(index, 1);

    setCountriesList([...country_list]);
    setselectedCountriesList([...selectedcountry_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangejob(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let jobtitle_list = jobTitlesList;
    let selectedjobtitle_list = selectedjobTitlesList;

    let index = jobtitle_list.findIndex(
      (agent) => agent.job_title_id === value
    );
    if (selectedjobtitle_list) {
      selectedjobtitle_list.push(jobtitle_list[index]);
      jobtitle_list.splice(index, 1);

      setselectedJobTitlesList([...selectedjobtitle_list]);
      setJobTitlesList([...jobtitle_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangejob(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let jobtitle_list = jobTitlesList;
    let selectedjobtitle_list = selectedjobTitlesList;

    let index = selectedjobtitle_list.findIndex(
      (agent) => agent.job_title_id === value
    );

    jobtitle_list.push(selectedjobtitle_list[index]);
    selectedjobtitle_list.splice(index, 1);

    setJobTitlesList([...jobtitle_list]);
    setselectedJobTitlesList([...selectedjobtitle_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  const handleAddProductSession = async (data) => {
    const resAgent =
      taggedAgents &&
      taggedAgents.map((elm) => {
        return elm.user_id;
      });
    const resUser =
      selectedusersList &&
      selectedusersList.map((elm) => {
        return elm.user_id;
      });
    const resBrand =
      selectedbrandsList &&
      selectedbrandsList.map((elm) => {
        return elm.brand_id;
      });
    const resCountry =
      selectedcountriesList &&
      selectedcountriesList.map((elm) => {
        return elm.country_id;
      });
    const resjobTitle =
      selectedjobTitlesList &&
      selectedjobTitlesList.map((elm) => {
        return elm.job_title_id;
      });
    console.log(data);
    let bodyData = {
      configuration_id: appointmentData.data.configuration_id,
      session_details: {
        brand_id: appointmentData.data.brand_id,
        zone_id: appointmentData.data.zone_id,
        start_date_time: appointmentData.data.startDate,
        end_date_time: appointmentData.data.endDate,
        session_title: data.session_title,
        session_description: data.session_description,
        is_ical_download_enabled: data.is_ical_download_enabled,
      },
      assign_staff_ids: resAgent,
      restricted_users: resUser,
      restricted_brands: resBrand,
      restricted_countries: resCountry,
      restricted_job_titles: resjobTitle,
      invitation_setup: {
        invitation_title: data.invitation_title,
        invitation_description: data.invitation_description,
        is_iCal_download_enabled: data.is_iCal_download_enabled__invite,
        is_reinvite_enabled: data.is_reinvite_enabled,
        send_invitation_now: data.send_invitation_now,
        date_time_to_invite: data.date_time_to_invite,
      },
      invited_brands: data.invited_brands,
      invited_zones: data.invited_zones,
      invited_user_types: data.invited_user_types,
      invited_countries: data.invited_countries,
      invited_job_titles: data.invited_job_titles,
    };
    console.log("bodydata", bodyData);

    let formData = new FormData();
    formData.append("data", JSON.stringify(bodyData));
    const response = await ApiCall(
      "PUT",
      formData,
      "/admin/productzonesession/update"
    );
    // appointmentData.extraProps.visibleChange()
    if (response.data.code === 200) {
      toast.success(response?.data?.message);
      appointmentData.extraProps.getAppointmentDetails();
      appointmentData.extraProps.visibleChange();
    } else {
      toast.error(response?.data?.message);
    }
  };

  const ValidationSchema = Yup.object().shape({
    session_title: Yup.string().required("Required").nullable(),
    session_description: Yup.string().required("Required").nullable(),
    invitation_title: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, "Must be Alphanumeric")
      .max(100, "Must be exactly 100 characters"),
    invitation_description: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, "Must be Alphanumeric")
      .max(200, "Must be exactly 200 characters"),

    // zone_agents: Yup.array().length(1, "Required"),
  });

  return (
    <>
      <Formik
        initialValues={initial_state}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleAddProductSession(values);
          // handleCreateZone(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="px-5">
            {/* <h4 className="text-center fs-pink pt-2">Product Zone - Session</h4> */}
            <Row className="adminSatellite_row">
              <Col span={6} className="productZoneSession_label mb-3">
                <p>
                  Start Time <span style={{ color: "#EF0855" }}>*</span>
                </p>
              </Col>
              <Col span={18}>
                {/* <DatePicker
                  suffixIcon={<CalenderGrey width="15" />}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="start_date_time"
                  format={"D/M/YYYY HH:mm"}
                  placeholder="Select Date & Time"
                  className="adminSatellite_date_time w-100"
                  showTime
                  value={moment(appointmentData.data.startDate)}
                /> */}

                <TimePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  // disabled
                  name="start_time"
                  format={"HH:mm"}
                  placeholder="Select Time"
                  className="adminSatellite_date_time w-100"
                  value={moment(appointmentData.data.startDate)}
                  // onSelect={(val) => {
                  //   setFieldValue("start_time", val);
                  // }}
                />
              </Col>
            </Row>
            <Row className="adminSatellite_row">
              <Col span={6} className="productZoneSession_label mb-3">
                <p className="text-end">
                  End Time <span style={{ color: "#EF0855" }}>*</span>
                </p>
              </Col>
              <Col span={18}>
                <TimePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  // disabled
                  name="start_time"
                  format={"HH:mm"}
                  placeholder="Select Time"
                  className="adminSatellite_date_time w-100"
                  value={moment(appointmentData.data.endDate)}
                  // onSelect={(val) => {
                  //   setFieldValue("start_time", val);
                  // }}
                />
                {/* <DatePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="end_date_time"
                  disabledDate={(d) =>
                    !d || d.isSameOrBefore(productZoneSessionStartDateTime)
                  }
                  format={"D/M/YYYY HH:mm"}
                  placeholder="Select Date & Time"
                  className="adminSatellite_date_time w-100"
                  suffixIcon={<CalenderGrey width="15" />}
                  showTime
                  value={moment(appointmentData.data.endDate)}
   
                /> */}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Title</p>
              </Col>
              <Col span={18}>
                <Input
                  name="session_title"
                  className="productZoneSession_inputTitle"
                  placeholder="Enter a Title here"
                  value={values.session_title}
                  onChange={handleChange}
                  // onBlur={() =>
                  //   productzonesession_validator.current.showMessageFor(
                  //     "productZoneSession_title"
                  //   )
                  // }
                />
                {errors.session_title && touched.session_title ? (
                  <div className="text-danger">{errors.session_title}</div>
                ) : null}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p style={{ marginTop: "9px" }}>Description</p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="session_description"
                  // value={
                  //   productZoneSessionFields.productZoneSession_description
                  // }
                  value={values.session_description}
                  className="productZoneSession_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  onChange={handleChange}
                  // onBlur={() =>
                  //   productzonesession_validator.current.showMessageFor(
                  //     "productZoneSession_description"
                  //   )
                  // }
                />
                {errors.session_description && touched.session_description ? (
                  <div className="text-danger">
                    {errors.session_description}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Download iCal</p>
              </Col>

              <Col span={6} style={{ alignSelf: "center" }}>
                <Switch
                  checked={values.is_ical_download_enabled}
                  onChange={() =>
                    setFieldValue(
                      "is_ical_download_enabled",
                      !values.is_ical_download_enabled
                    )
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row className="mb-3">
              <Col span={6}>
                <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                  Assign Staff
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  Assigned
                </h6>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  Tagged Agents
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}></Col>
              <Col span={8}>
                <div
                  id="area"
                  style={{ position: "relative" }}
                  className="agentDropdown-calendar"
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value="All Agents"
                    onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {agentsList?.map((data) => {
                      // console.log('data', data)

                      return (
                        <Option value={data.user_id}>
                          {data.first_name} {data.last_name}
                          {/* {data.user_name} */}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  className="agentDropdown-calendar"
                  id="area1"
                  style={{ position: "absolute" }}
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search Agents"
                    optionFilterProp="children"
                    value="Tagged Agents"
                    // onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {taggedAgents?.map((data) => (
                      <Option value={data.user_id}>
                        {data.first_name} {data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChange(data?.user_id)
                            }
                          />
                        </span>
                      </Option>
                    ))}
                    {/* {agentsList.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                  </Select>
                </div>
              </Col>
            </Row>
            <Divider />
            {/* <Row>
              <Col span={6}></Col>
              <Col span={18}>
                <Button
                  className="productZoneSession_inviteAgentsBtn"
                  size={"large"}
                >
                  <AddGroup />
                  <span className="ms-2">Invite / Create</span>
                </Button>
              </Col>
            </Row> */}
            <Row className="m-3">
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_media_assets_title">
                  Media Assets
                </h5>
              </Col>
            </Row>
            <Row className="m-3">
              <Col span={18}>
                <p className="productZoneSession_media_assets_info">
                  NB: By default, all assets for this zone will already be
                  available in your demo room playlist.
                  <br /> You can either pre-upload assets here or add assets in
                  the meeting room itself.
                </p>
              </Col>
            </Row>
            <Row className="m-3">
              <Col span={6}>
                <p
                  style={{ opacity: 1 }}
                  className="productZoneSession_label fs-16"
                >
                  Upload Individual Assets
                </p>
              </Col>
              <Col span={6}>
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="File Upload"
                  name="productZoneSessionFileUpload"
                  inputType="file"
                  inputOnChange={(e) => {
                    setProductZoneSessionFileUpload(e.target.files[0]);
                  }}
                  inputId="productZoneSessionFileUpload"
                  inputName="productZoneSessionFileUpload"
                  onBlur={() =>
                    productzonesession_validator.current.showMessageFor(
                      "productZoneSessionFileUpload"
                    )
                  }
                />
                {productzonesession_validator.current.message(
                  "File upload",
                  productZoneSessionFileUpload,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
              <Col span={9}>
                {productZoneSessionFileUpload ? (
                  <>
                    <span className="mx-3">
                      {" "}
                      {productZoneSessionFileUpload.name}{" "}
                    </span>
                    <span>
                      {" "}
                      <DeleteOutlined />{" "}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_restrict_access_title">
                  Restrict Access
                </h5>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={24}>
                <p className="productZoneSession_restrict_access_info">
                  NB: Configure who can view / access your event.
                  <br /> Attendees with any black list attributes will not be
                  able to to see the event on any calendars or access the event.
                  <br /> If an attendee/attribute is on the White List they will
                  be able to see/attaned the event regardless of any black list
                  attributes.
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}>
                <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                  Assign Staff
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  BlackList Users
                </h6>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  WhiteList Users
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}></Col>
              <Col span={8}>
                <div
                  id="areauser"
                  style={{ position: "relative" }}
                  className="agentDropdown-calendar"
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areauser")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value="All Agents"
                    onChange={handleAgentsChangeUser}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {usersList?.map((data) => {
                      // console.log('data', data)

                      return (
                        <Option value={data.user_id}>
                          {data.first_name} {data.last_name}
                          {/* {data.user_name} */}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  className="agentDropdown-calendar"
                  id="areauserb"
                  style={{ position: "absolute" }}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areauserb")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search Agents"
                    optionFilterProp="children"
                    value="Tagged Agents"
                    // onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {selectedusersList?.map((data) => (
                      <Option value={data.user_id}>
                        {data.first_name} {data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangeUser(data?.user_id)
                            }
                          />
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={6}>
                <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                  {/* Assign Staff */}
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  BlackList Brand
                </h6>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  WhiteList Brand
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}></Col>
              <Col span={8}>
                <div
                  id="areabrandw"
                  style={{ position: "relative" }}
                  className="agentDropdown-calendar"
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areabrandw")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value="All Agents"
                    onChange={handleAgentsChangebrand}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {brandsList?.map((data) => {
                      // console.log('databand', data)

                      return (
                        <Option value={data.brand_id}>
                          {/* {data.first_name} {data.last_name} */}
                          {data.brand_title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  className="agentDropdown-calendar"
                  id="areabrandb"
                  style={{ position: "absolute" }}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areabrandb")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search Agents"
                    optionFilterProp="children"
                    value="Tagged Agents"
                    // onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {selectedbrandsList?.map((data) => (
                      <Option value={data.brand_id}>
                        {data.brand_title}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangebrand(data?.brand_id)
                            }
                          />
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={6}>
                <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                  {/* Assign Staff */}
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  BlackList Country
                </h6>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  WhiteList Country
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}></Col>
              <Col span={8}>
                <div
                  id="areacountryw"
                  style={{ position: "relative" }}
                  className="agentDropdown-calendar"
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areacountryw")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value="All Agents"
                    onChange={handleAgentsChangecountry}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {countriesList?.map((data) => {
                      // console.log('datacontry', data)

                      return (
                        <Option value={data.country_id}>
                          {/* {data.first_name} {data.last_name} */}
                          {data.country}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  className="agentDropdown-calendar"
                  id="areacountryb"
                  style={{ position: "absolute" }}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areacountryb")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search Agents"
                    optionFilterProp="children"
                    value="Tagged Agents"
                    // onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {selectedcountriesList?.map((data) => (
                      <Option value={data.country_id}>
                        {data.country}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangecountry(
                                data?.country_id
                              )
                            }
                          />
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={6}>
                <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                  {/* Assign Staff */}
                </h5>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  BlackList Job Title
                </h6>
              </Col>
              <Col span={9}>
                <h6 className="productZoneSession_assignstaffs_heading">
                  WhiteList Job Title
                </h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={6}></Col>
              <Col span={8}>
                <div
                  id="areajobtitlew"
                  style={{ position: "relative" }}
                  className="agentDropdown-calendar"
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areajobtitlew")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value="All Agents"
                    onChange={handleAgentsChangejob}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {jobTitlesList?.map((data) => {
                      // console.log('datajobtitll', data)

                      return (
                        <Option value={data.job_title_id}>
                          {/* {data.first_name} {data.last_name} */}
                          {data.job_title}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={8} offset={1}>
                <div
                  className="agentDropdown-calendar"
                  id="areajobtitleb"
                  style={{ position: "absolute" }}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("areajobtitleb")
                    }
                    showSearch
                    dropdownClassName="agent-dropdown"
                    style={{ width: 235 }}
                    placeholder="Search Agents"
                    optionFilterProp="children"
                    value="Tagged Agents"
                    // onChange={handleAgentsChange}
                    open={true}
                    listItemHeight={4}
                    listHeight={130}
                    className="border border-gray fs-bold-14"
                  >
                    {selectedjobTitlesList?.map((data) => (
                      <Option value={data.job_title_id}>
                        {data.job_title}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangejob(data?.job_title_id)
                            }
                          />
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_prevpending_title">
                  Previous & Pending Invitees
                </h5>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6}></Col>
              <Col span={8} md={12} className="productZoneSession_eyeViewBtn">
                <Button
                  type="primary"
                  className="productZoneSession_prevpending_previousInvitedBtn"
                  size={"large"}
                  onClick={() =>
                    openInviteesModalAndApiCall(
                      {
                        configuration_id: responceSessionData?.configuration_id,
                      },
                      true
                    )
                  }
                >
                  View List of Previously Invited
                </Button>
              </Col>
            </Row>
            <Modal
              title={
                <div className="invite_center">{"Previously Invited"}</div>
              }
              visible={invitationModal.isOpen}
              width={650}
              onOk={() =>
                setInvitationModal({
                  isOpen: false,
                  data: null,
                  isLoader: false,
                })
              }
              onCancel={() =>
                setInvitationModal({
                  isOpen: false,
                  data: null,
                  isLoader: false,
                })
              }
              footer={null}
              style={{ zIndex: 11111111 }}
            >
              <div className="px-3">
                <Table
                  columns={invitation_table_columns}
                  pagination={false}
                  dataSource={invitationTableData}
                  bordered={false}
                  //   rowClassName={"brand_row"}
                  scroll={{ y: 240 }}
                  rowKey={(obj) => obj.rsvp_detail_id + "_invi"}
                />
              </div>
            </Modal>
            <Row className="productZoneSession_row">
              <Col span={6}></Col>
              <Col span={9}>
                <h6 className="productZoneSession_prevpending_heading">
                  Currently Scheduled Invitations
                </h6>
              </Col>
            </Row>

            <Row className="productZoneSession_row">
              {responceSessionData?.previous_pending_invites.map((data) => {
                return (
                  <>
                    <Col span={6} className="productZoneSession_label">
                      <p>Date & Time</p>
                    </Col>
                    <Col span={10}>
                      <DatePicker
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        name="date_time_to_invite"
                        defaultValue={moment(data.date_time_to_invite)}
                        format={"D/M/YYYY HH:mm"}
                        className="productZoneSession_date_time"
                        showTime
                        onChange={(val) =>
                          setFieldValue("date_time_to_invite", val)
                        }
                      />
                    </Col>
                    <Col span={3}>
                      <Button
                        className="productZoneSession_prevpending_viewListBtn"
                        size={"large"}
                        onClick={() =>
                          openInviteesModalAndApiCall(
                            {
                              configuration_id: data?.configuration_id,
                              invitation_setup_id: data?.invitation_setup_id,
                            },
                            false
                          )
                        }
                      >
                        <EyeView />
                        <span className="ms-2">View List</span>
                      </Button>
                    </Col>
                  </>
                );
              })}
            </Row>

            <Row>
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_new_invitees_title">
                  New Invitees
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={6}></Col>
              <Col span={9}>
                <h6 className="productZoneSession_new_invitees_heading">
                  Filter Attendees
                </h6>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Brand</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="invited_brands"
                  // bordered={false}
                  className="productZoneSession_selectBrand"
                  value={values.invited_brands}
                  mode="multiple"
                  onChange={(value) => {
                    setFieldValue("invited_brands", value);
                  }}
                >
                  {brandsList?.map((data) => {
                    // console.log('databand', data)

                    return (
                      <Option value={data.brand_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.brand_title}
                      </Option>
                    );
                  })}
                </Select>
                {/* {productzonesession_validator.current.message(
                  "Brand",
                  productZoneSessionFields.productZoneSession_invitees_brand,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Zone</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="invited_zones"
                  bordered={false}
                  className="productZoneSession_selectBrand"
                  value={values.invited_zones}
                  mode="multiple"
                  onChange={(value) => {
                    setFieldValue("invited_zones", value);
                  }}
                >
                  {zoneList?.map((data) => {
                    // console.log('zooone', data)

                    return (
                      <Option value={data.zone_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.zone_title}
                      </Option>
                    );
                  })}
                </Select>
                {/* {productzonesession_validator.current.message(
                  "Zone",
                  productZoneSessionFields.productZoneSession_invitees_zone,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>User Type</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="invited_user_types"
                  bordered={false}
                  className="productZoneSession_selectBrand"
                  value={values.invited_user_types}
                  mode="multiple"
                  onChange={(value) => {
                    setFieldValue("invited_user_types", value);
                  }}
                >
                  <Option value="AGENT">AGENT</Option>
                  <Option value="DELEGATE">DELEGATE</Option>
                  <Option value="SPEAKER">SPEAKER</Option>
                </Select>
                {/* {productzonesession_validator.current.message(
                  "User Type",
                  productZoneSessionFields.productZoneSession_invitees_user_type,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Country</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="invited_countries"
                  bordered={false}
                  className="productZoneSession_selectBrand"
                  value={values.invited_countries}
                  mode="multiple"
                  onChange={(value) => {
                    setFieldValue("invited_countries", value);
                  }}
                >
                  {countriesList?.map((data) => {
                    // console.log('zooone', data)

                    return (
                      <Option value={data.country_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.country}
                      </Option>
                    );
                  })}
                </Select>
                {/* {productzonesession_validator.current.message(
                  "Country",
                  productZoneSessionFields.productZoneSession_invitees_country,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Job Title</p>
              </Col>
              <Col span={18}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="invited_job_titles"
                  bordered={false}
                  className="productZoneSession_selectBrand"
                  value={values.invited_job_titles}
                  mode="multiple"
                  onChange={(value) => {
                    setFieldValue("invited_job_titles", value);
                  }}
                >
                  {jobTitlesList?.map((data) => {
                    // console.log('zooone', data)

                    return (
                      <Option value={data.job_title_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.job_title}
                      </Option>
                    );
                  })}
                </Select>
                {/* {productzonesession_validator.current.message(
                  "Job Title",
                  productZoneSessionFields.productZoneSession_invitees_job_title,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <h5 className="productZoneSession_invitation_setup_title text-danger">
                  Invitation Setup
                </h5>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Title</p>
              </Col>
              <Col span={18}>
                <Input
                  name="invitation_title"
                  className="productZoneSession_inputTitle"
                  placeholder="Enter a Title here"
                  value={values.invitation_title}
                  // value={
                  //   productZoneSessionFields.productZoneSession_invitation_title
                  // }
                  onChange={handleChange}
                  // onBlur={() =>
                  //   productzonesession_validator.current.showMessageFor(
                  //     "productZoneSession_invitation_title"
                  //   )
                  // }
                />
                {errors.invitation_title && touched.invitation_title ? (
                  <div className="text-danger">{errors.invitation_title}</div>
                ) : null}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p style={{ marginTop: "9px" }}>Description</p>
              </Col>
              <Col span={18}>
                <TextArea
                  name="invitation_description"
                  className="productZoneSession_inputDescription"
                  placeholder="Enter a description here"
                  rows={4}
                  value={values.invitation_description}
                  // value={
                  //   productZoneSessionFields.productZoneSession_invitation_description
                  // }
                  onChange={handleChange}
                  // onBlur={() =>
                  //   productzonesession_validator.current.showMessageFor(
                  //     "productZoneSession_invitation_description"
                  //   )
                  // }
                />
                {errors.invitation_description &&
                touched.invitation_description ? (
                  <div className="text-danger">
                    {errors.invitation_description}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Attach iCal</p>
              </Col>
              <Col span={18}>
                <Switch
                  checked={values.is_iCal_download_enabled__invite}
                  onChange={() =>
                    setFieldValue(
                      "is_iCal_download_enabled__invite",
                      !values.is_iCal_download_enabled__invite
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Re-invite</p>
              </Col>
              <Col span={18}>
                <Switch
                  checked={values.is_reinvite_enabled}
                  onChange={() =>
                    setFieldValue(
                      "is_reinvite_enabled",
                      !values.is_reinvite_enabled
                    )
                  }
                />{" "}
                <span style={{ marginLeft: "30px" }}>(if already invited)</span>
              </Col>
            </Row>
            <Divider />
            <Row className="productZoneSession_row">
              <Col span={24}>
                <h5 className="fs-pink productZoneSession_whentosend_title">
                  When to Send Invitation
                </h5>
              </Col>
            </Row>
            {/* <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Scheduled</p>
              </Col>
              <Col span={18}>
                <Switch
                  defaultChecked
                  value={
                    productZoneSessionFields.productZoneSession_schedule_invitation
                  }
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={6} className="productZoneSession_label">
                <p>Date & Time</p>
              </Col>
              <Col span={7}>
                <DatePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="productZoneSession_when_to_send_date_time"
                  defaultValue={
                    productZoneSessionFields.productZoneSession_when_to_send_date_time
                  }
                  format={"D/M/YYYY HH:mm"}
                  className="productZoneSession_date_time"
                  showTime
                  onChange={(val) =>
                    handleProductZoneSessionFieldsChange(
                      val,
                      "productZoneSession_when_to_send_date_time"
                    )
                  }
                  onBlur={() =>
                    productzonesession_validator.current.showMessageFor(
                      "productZoneSession_when_to_send_date_time"
                    )
                  }
                />
                {productzonesession_validator.current.message(
                  "",
                  productZoneSessionFields.productZoneSession_when_to_send_date_time,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={6} className="productZoneSession_label"></Col>
              <Col span={7}>
                <span className="fs-pink productZoneSession_or">OR</span>
              </Col>
            </Row> */}
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Scheduled</p>
              </Col>
              <Col span={18}>
                <Switch
                  checked={!values.send_invitation_now}
                  onChange={() =>
                    setFieldValue(
                      "send_invitation_now",
                      !values.send_invitation_now
                    )
                  }
                />
              </Col>
            </Row>
            <Row className={values.send_invitation_now && "d-none"}>
              <Col span={6} className="productZoneSession_label">
                <p>Date & Time</p>
              </Col>
              <Col span={7}>
                <DatePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="date_time_to_invite"
                  disabledDate={(d) => !d || d.isSameOrBefore(moment())}
                  defaultValue={moment()}
                  format={"D/M/YYYY HH:mm"}
                  className="productZoneSession_date_time"
                  showTime
                  onChange={(val) => setFieldValue("date_time_to_invite", val)}
                  // onBlur={() =>
                  //   productzonesession_validator.current.showMessageFor(
                  //     "productZoneSession_prev_pending_date_time_2"
                  //   )
                  // }
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} className="productZoneSession_label"></Col>
              <Col span={7}>
                <span className="fs-pink productZoneSession_or">OR</span>
              </Col>
            </Row>
            <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p className="fs-14">Now</p>
              </Col>
              <Col span={18}>
                <Switch
                  checked={values.send_invitation_now}
                  onChange={() =>
                    setFieldValue(
                      "send_invitation_now",
                      !values.send_invitation_now
                    )
                  }
                />
              </Col>
            </Row>
            <div
              style={{
                height: "80px",
                // backgroundColor: "rgb(246 246 246)",
                marginBottom: "30px",
              }}
              className="d-flex justify-content-between"
            >
              <Button
                className="productZoneSession_cancelBtn my-auto"
                onClick={() => appointmentData.extraProps.visibleChange()}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="productZoneSession_saveBtn my-auto"
                onClick={() => {
                  console.log("isValid", isValid);

                  if (!isValid) {
                    toast.error("Fields are Missing");
                  }
                }}
              >
                Save Changes & Send Invitations
              </Button>
            </div>

            {/* </Modal> */}
          </form>
        )}
      </Formik>
    </>
  );
};
export default ProductZoneSession;
