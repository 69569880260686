import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  socialWallEventList: {},
  loading: false,
};

export const socialWallEvent = createAsyncThunk(
  "socialWallEvent/socialWallEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/socialwall/create",
      "",
      ""
    );
    console.log("create response socialWallEvent", response);

    return response;
  }
);

export const socialWallEventSlice = createSlice({
  name: "socialWallEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [socialWallEvent.pending]: (state) => {
      state.loading = true;
    },
    [socialWallEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.socialWallEventList = payload;
    },
    [socialWallEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const socialWallEventReducer = socialWallEventSlice.reducer;
