import React, { useState } from "react";
import "./index.css";
import { Form, Input, Button } from "antd";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const response = await ApiCall("POST", values, "/admin/forgotpassword", "");
    if (response.data.code === 200) toast.success(response.data.message);
    else toast.error(response.data.message);

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.error(errorInfo.errorFields[0].errors[0]);
  };

  return (
    <div className="fp_bg d-flex flex-column align-items-center">
      <img
        className="login_img_logo forgotpass_logo mb-2"
        src={localStorage.getItem("event_logo_url")}
        alt="logo"
      />
      <div className="fp_container mt-3 text-center">
        <h4 className="fs-pink mb-5 mt-4">Forgot Password</h4>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="mx-2"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              className="forgotpass_input"
              placeholder="Enter Email address"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button loading={loading} className="fp_btn" htmlType="submit">
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
