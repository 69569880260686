import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getUser = createAsyncThunk(
  "userManagement/getUser",

  async params => {
    const response = await ApiCall("GET", "", "/admin/user/get", params);
    // console.log('response', response)

    return response.data.data;
  }
);

export const getPeople = createAsyncThunk(
  "userManagement/getPeople",
  async params => {
    const response = await ApiCall("GET", "", "/admin/people/get", params);
    console.log("peopleResp", response);
    return response.data.data;
  }
);

export const changeStatus = createAsyncThunk(
  "userManagement/changeStatus",

  async body => {
    // console.log('paramsAPIBrand', params)

    await ApiCall("PUT", body, "/admin/user/status/update");

    const response = await ApiCall("GET", "", "/admin/user/get");

    // console.log('response of editcustomer  ', response)
    return response.data.data;
  }
);

export const viewUserDetails = createAsyncThunk(
  "userManagement/viewUserDetails",

  async params => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall(
      "GET",
      "",
      "/admin/user/details/get",
      params
    );

    // console.log('response of view user ', response)
    return response.data;
  }
);

export const addUser = createAsyncThunk(
  "userManagement/addUser",

  async body => {
    // console.log('paramsAPIBrand', body)

    const response = await ApiCall("POST", body, "/admin/user/add");

    console.log("response of add user ", response);
    return response;
  }
);

export const editUser = createAsyncThunk(
  "userManagement/editUser",

  async body => {
    // console.log('paramsAPIBrand', body)

    const response = await ApiCall("PUT", body, "/admin/user/update");

    console.log("response of edit user ", response);
    return response;
  }
);

export const inviteUser = createAsyncThunk(
  "userManagement/inviteUser",

  async body => {
    // console.log('paramsAPIBrand', body)

    const response = await ApiCall("POST", body, "/admin/user/invite");

    return response;
  }
);

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    loading: false,
    data: []
  },
  reducers: {},
  extraReducers: {
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
    },

    // add user
    [addUser.pending]: (state, action) => {
      state.loading = true;
    },
    [addUser.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [addUser.rejected]: (state, action) => {
      state.loading = false;
    },

    // edit
    [editUser.pending]: (state, action) => {
      state.loading = true;
    },
    [editUser.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [editUser.rejected]: (state, action) => {
      state.loading = false;
    },

    //status

    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },

    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      state.data = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },

    //Invite user
    [inviteUser.pending]: (state, action) => {
      state.loading = true;
    },

    [inviteUser.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      // state.data = payload
      state.loading = false;
    },
    [inviteUser.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export const userReducer = userManagementSlice.reducer;
