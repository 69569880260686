import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

var CryptoJS = require("crypto-js");

export const mapEventEditorSchema = (data) => {
  const ciphertext = localStorage.getItem("user_type");
  var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const userType = decryptedData;
  let customer_id = localStorage.getItem("customer_id");

  let final_event_shows_details = [];

  data &&
    data.event_shows_details &&
    data.event_shows_details.forEach((item) => {
      const obj = {
        show_id: item.show_id ?? "",
        start_date: item.start_date ?? "",
        start_time: item.start_time ?? "",
        end_date: item.end_date ?? "",
        end_time: item.end_time ?? "",
        is_delete: item.is_delete ?? false,
      };
      final_event_shows_details.push(obj);
    });

  let event_support_agents = [];

  data &&
    data.event_support &&
    data.event_support.agents.map((val) => {
      event_support_agents.push(val.user_id);
    });

  let event_technical_support_agents = [];
  data &&
    data.event_technical_support &&
    data.event_technical_support.agents.map((val) => {
      event_technical_support_agents.push(val.user_id);
    });

  return {
    event_id: data.event_id ?? "",
    customer_id:
      userType && userType === "CUSTOMER" && customer_id
        ? parseInt(customer_id)
        : data.customer_id
        ? data.customer_id
        : "",
    event_name: data.event_name ?? "",
    sub_domain: data.sub_domain ?? "",
    organizer_brand_id: data.organizer_brand_id ?? null,
    main_contact_name: data.main_contact_name ?? "",
    email_address: data.email_address ?? "",
    registration_start_date_time: data.registration_start_date_time ?? null,
    registration_end_date_time: data.registration_end_date_time ?? null,
    control_media_type: data.control_media_type ?? "",
    control_media_url: data.control_media_url || "",
    control_media_original_filename: data.control_media_original_filename ?? "",
    event_color_theme: data.event_color_theme ?? "#ef0855",
    default_button_background_color:
      data.default_button_background_color ?? "#ffffff",
    is_default_button_gradient_active:
      data.is_highlight_button_gradient_active ?? false,
    default_button_gradient_angle: data.default_button_gradient_angle ?? 0,
    default_button_background_second_color:
      data.default_button_background_second_color ?? "",
    default_button_text_color: data.default_button_text_color ?? "#ef0855",
    highlight_button_background_color:
      data.highlight_button_background_color ?? "#ef0855",
    is_highlight_button_gradient_active:
      data.is_highlight_button_gradient_active ?? false,
    highlight_button_gradient_angle: data.highlight_button_gradient_angle ?? 0,
    highlight_button_background_second_color:
      data.highlight_button_background_second_color ?? "",
    highlight_button_text_color: data.highlight_button_text_color ?? "#ffffff",
    event_logo_url: data.event_logo_url ?? "",
    event_logo_original_filename: data.event_logo_original_filename ?? "",
    event_survey_id: data.event_survey_id ?? "",
    is_active: data.is_active ?? true,
    is_draft: data.is_draft ?? false,
    is_booth_at_event: data.is_booth_at_event ?? false,
    events_organizer_brand_id: data.events_organizer_brand_id ?? null,
    customer: data.customer ?? null,
    survey: data.survey ?? null,
    pre_event_messages: {
      message_id: data?.pre_event_messages?.message_id ?? "",
      pre_event_message_active:
        data?.pre_event_messages?.pre_event_message_active ?? false,
      pre_event_message_forced:
        data?.pre_event_messages?.pre_event_message_forced ?? false,
      pre_event_message_title:
        data?.pre_event_messages?.pre_event_message_title ?? "",
      pre_event_message_description:
        data?.pre_event_messages?.pre_event_message_description ?? "",
      pre_event_media_type:
        data?.pre_event_messages?.pre_event_media_type ?? "",
      pre_event_media_url: data?.pre_event_messages?.pre_event_media_url ?? "",
      pre_event_media_original_filename:
        data?.pre_event_messages?.pre_event_media_original_filename ?? "",
    },
    welcome_messages: {
      message_id: data?.welcome_messages?.message_id ?? "",
      welcome_message_active:
        data?.welcome_messages?.welcome_message_active ?? false,
      welcome_message_forced:
        data?.welcome_messages?.welcome_message_forced ?? false,
      welcome_message_title:
        data?.welcome_messages?.welcome_message_title ?? "",
      welcome_message_description:
        data?.welcome_messages?.welcome_message_description ?? "",
      welcome_media_type: data?.welcome_messages?.welcome_media_type ?? "",
      welcome_media_url: data?.welcome_messages?.welcome_media_url ?? "",
      welcome_media_original_filename:
        data?.welcome_messages?.welcome_media_original_filename ?? "",
    },

    event_shows_details: final_event_shows_details,
    event_settings_details: {
      event_settings_id: data?.event_settings_details?.event_settings_id ?? "",
      is_registration_active:
        data?.event_settings_details?.is_registration_active ?? true,
      is_brand_tier_active:
        data?.event_settings_details?.is_brand_tier_active ?? true,
      is_navigation_bar_active:
        data?.event_settings_details?.is_navigation_bar_active ?? true,
      is_reception_active:
        data?.event_settings_details?.is_reception_active ?? true,
      is_social_wall_active:
        data?.event_settings_details?.is_social_wall_active ?? true,
      is_concourse_active:
        data?.event_settings_details?.is_concourse_active ?? true,
      is_ticker_tape_active:
        data?.event_settings_details?.is_ticker_tape_active ?? true,
      is_expo_hall_active:
        data?.event_settings_details?.is_expo_hall_active ?? true,
      no_of_expo_halls: data?.event_settings_details?.no_of_expo_halls ?? 0,
      is_main_hall_active:
        data?.event_settings_details?.is_main_hall_active ?? true,
      is_sat_auditorium_active:
        data?.event_settings_details?.is_sat_auditorium_active ?? true,
      no_of_sat_auditorium:
        data?.event_settings_details?.no_of_sat_auditorium ?? 0,
      is_networking_active:
        data?.event_settings_details?.is_networking_active ?? true,
      no_of_networking: data?.event_settings_details?.no_of_networking ?? 0,
      is_exterior_active:
        data?.event_settings_details?.is_exterior_active ?? true,
      expo_hall_url: data?.event_settings_details?.expo_hall_url ?? "",
      main_hall_url: data?.event_settings_details?.main_hall_url ?? "",
      sat_audi_hall_url: data?.event_settings_details?.sat_audi_hall_url ?? "",
      networking_url: data?.event_settings_details?.networking_url ?? "",
      exterior_url: data?.event_settings_details?.exterior_url ?? "",
      login_background_theme:
        data?.event_settings_details?.login_background_theme ?? "#EF0855",
    },
    event_support: {
      support_id: data?.event_support?.support_id ?? "",
      is_chat_active: data?.event_support?.is_chat_active ?? false,
      is_video_call_active: data?.event_support?.is_video_call_active ?? false,
      agents: event_support_agents,
    },
    event_technical_support: {
      support_id: data?.event_technical_support?.support_id ?? "",
      is_chat_active: data?.event_technical_support?.is_chat_active ?? false,
      is_video_call_active:
        data?.event_technical_support?.is_video_call_active ?? false,
      agents: event_technical_support_agents,
    },
  };
};

//registration
export function mapRegisterationSchema(data) {
  let searchesArray = [];
  let initiateDataTextArray = [];
  let inviteDataVideoArray = [];
  let calenderDataArray = [];
  let inviteDataArray = [];

  if (data && !isEmpty(data.relationData)) {
    // console.log("relation data key present at update stage");
    data?.relationData?.searchesData?.forEach((item) => {
      const obj = {
        relation_id: item.relation_id,
        is_networking_only: item.is_networking_only ?? false,

        // tier_id: item?.is_networking_only === false ? 1 : item.tier_id,
        // tier:
        //   item?.is_networking_only === false ? "None" : item.tier?.tier ?? "",
        communication_settings_id: item.communication_settings_id,
        tier_id: item.tier_id,
        tier: item.tier,
        is_similar_rights_given: false,
      };
      searchesArray.push(obj);
    });

    data?.relationData?.initiateDataText?.forEach((item) => {
      const obj = {
        relation_id: item.relation_id,
        is_networking_only: item.is_networking_only ?? false,
        // tier_id: item?.is_networking_only === false ? 1 : item.tier_id,
        // tier: item?.is_networking_only === false ? "None" : item.tier?.tier,
        communication_settings_id: item.communication_settings_id,
        tier_id: item.tier_id,
        tier: item.tier,
        is_similar_rights_given: false,
      };
      initiateDataTextArray.push(obj);
    });

    data?.relationData?.initiateDataVideo?.forEach((item) => {
      const obj = {
        relation_id: item.relation_id,
        is_networking_only: item.is_networking_only ?? false,
        // tier_id: item?.is_networking_only === false ? 1 : item.tier_id,
        // tier: item?.is_networking_only === false ? "None" : item.tier?.tier,
        communication_settings_id: item.communication_settings_id,
        tier_id: item.tier_id,
        tier: item.tier,
        is_similar_rights_given: false,
      };
      inviteDataVideoArray.push(obj);
    });

    data?.relationData?.calendarData?.forEach((item) => {
      const obj = {
        relation_id: item.relation_id ?? 1,
        is_networking_only: item.is_networking_only ?? false,
        // tier_id: item?.is_networking_only === false ? 1 : item.tier_id,
        // tier: item?.is_networking_only === false ? "None" : item.tier?.tier,
        communication_settings_id: item.communication_settings_id,
        tier_id: item.tier_id,
        tier: item.tier,
        is_similar_rights_given: false,
      };
      calenderDataArray.push(obj);
    });

    data?.relationData?.inviteData?.forEach((item) => {
      const obj = {
        relation_id: item.relation_id ?? 1,
        is_networking_only: item.is_networking_only ?? false,
        // tier_id: item?.is_networking_only === false ? 1 : item.tier_id,
        // tier: item?.is_networking_only === false ? "None" : item.tier?.tier,
        communication_settings_id: item.communication_settings_id,
        tier_id: item.tier_id,
        tier: item.tier,
        is_similar_rights_given: false,
      };

      inviteDataArray.push(obj);
    });
  } else if (data && isEmpty(data.relationData)) {
    // console.log("relation data key not present at initial stage");
    searchesArray = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Searches")
      .map((item) => {
        return item;
      });
    initiateDataTextArray = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Initiate 1 to 1 Text Chats")
      .map((item) => {
        return item;
      });
    inviteDataVideoArray = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Initiate 1 to 1 Video Chats")
      .map((item) => {
        return item;
      });
    calenderDataArray = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Calendar & Meetings")
      .map((item) => {
        return item;
      });
    inviteDataArray = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Inviting Delegates")
      .map((item) => {
        return item;
      });
  }

  return {
    registration_settings_id: data?.registration_settings_id || "",
    country_collection: data?.country_collection ?? false,
    country_collection_ids: data?.country_collection_ids ?? [],
    allow_other_country: data?.allow_other_country ?? false,
    block_other_country_text: data?.block_other_country_text ?? "",
    job_title_collection: data?.job_title_collection ?? false,
    job_title_collection_ids: data?.job_title_collection_ids ?? [],
    allow_other_job_title: data?.allow_other_job_title ?? false,
    block_other_job_title_text: data?.block_other_job_title_text ?? "",
    is_registration_survey_active: data?.is_registration_survey_active ?? false,
    registration_survey_id: data?.registration_survey_id ?? "",
    relation_id_search: 0,
    terms_id_1:
      data && data.terms_id_1
        ? data.terms_id_1
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[0]
        ? data.event_registration_terms_and_conditions_settings[0].terms_id
        : "",
    check_box_1:
      data && data.check_box_1
        ? data.check_box_1
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[0]
        ? data.event_registration_terms_and_conditions_settings[0].is_active
        : false,
    term_title_1: "one",
    term_link_1: "termlink.com",
    term_description_1:
      data && data.term_description_1
        ? data.term_description_1
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[0]
        ? data.event_registration_terms_and_conditions_settings[0]
            .term_description
        : "<p></p>",
    terms_id_2:
      data && data.terms_id_2
        ? data.terms_id_2
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[1]
        ? data.event_registration_terms_and_conditions_settings[1].terms_id
        : "",
    check_box_2:
      data && data.check_box_2
        ? data.check_box_2
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[1]
        ? data.event_registration_terms_and_conditions_settings[1].is_active
        : false,
    term_title_2: "two",
    term_link_2: "termlink.com",
    term_description_2:
      data && data.term_description_2
        ? data.term_description_2
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[1]
        ? data.event_registration_terms_and_conditions_settings[1]
            .term_description
        : "<p></p>",
    terms_id_3:
      data && data.terms_id_3
        ? data.terms_id_3
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[1]
        ? data.event_registration_terms_and_conditions_settings[2].terms_id
        : "",
    check_box_3:
      data && data.check_box_3
        ? data.check_box_3
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[2]
        ? data.event_registration_terms_and_conditions_settings[2].is_active
        : false,
    term_title_3: "three",
    term_link_3: "termlink.com",
    term_description_3:
      data && data.term_description_3
        ? data.term_description_3
        : data &&
          data.event_registration_terms_and_conditions_settings &&
          data.event_registration_terms_and_conditions_settings[2]
        ? data.event_registration_terms_and_conditions_settings[2]
            .term_description
        : "<p></p>",
    agent_profile_id:
      data && data.agent_profile_id
        ? data.agent_profile_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[0] &&
          data.registration_profile_settings[0].profile_id
        ? data.registration_profile_settings[0].profile_id
        : "",
    agent_tier_id:
      data && data.agent_tier_id
        ? data.agent_tier_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[0]
        ? data.registration_profile_settings[0].tier_id
        : "",
    agent_social_link_tier_id:
      data && data.agent_social_link_tier_id
        ? data.agent_social_link_tier_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[0] &&
          data?.registration_profile_settings[0].social_link_tier &&
          data?.registration_profile_settings[0].social_link_tier.tier_id
        ? data.registration_profile_settings[0].social_link_tier.tier_id
        : "",
    delegate_profile_id:
      data && data.delegate_profile_id
        ? data.delegate_profile_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[1] &&
          data.registration_profile_settings[1].profile_id
        ? data.registration_profile_settings[1].profile_id
        : "",
    delegate_tier_id:
      data && data.delegate_tier_id
        ? data.delegate_tier_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[1] &&
          data.registration_profile_settings[1].tier_id
        ? data.registration_profile_settings[1].tier_id
        : "",
    delegate_social_link_tier_id:
      data && data.delegate_social_link_tier_id
        ? data.delegate_social_link_tier_id
        : data &&
          data.registration_profile_settings &&
          data.registration_profile_settings[1] &&
          data.registration_profile_settings[1].social_link_tier &&
          data.registration_profile_settings[1].social_link_tier.tier_id
        ? data.registration_profile_settings[1].social_link_tier.tier_id
        : "",

    relationData: {
      searchesArray,
      initiateDataTextArray,
      inviteDataVideoArray,
      calenderDataArray,
      inviteDataArray,
    },
  };
}

//registration
export const mapRelationsToRegistrationSchema = (data) => {
  let clonedData = cloneDeep(data);
  const relationData = clonedData.relationData;
  clonedData.relationData = undefined;
  clonedData.search_details = undefined;
  clonedData.initiate_one_to_one_details_text_chat = undefined;
  clonedData.initiate_one_to_one_details_video = undefined;
  clonedData.calendars_meetings_details = undefined;
  clonedData.inviting_delegates_details = undefined;

  clonedData = JSON.parse(JSON.stringify(clonedData));
  return {
    ...clonedData,
    search_details: relationData.searchesArray,
    initiate_one_to_one_details_text_chat: relationData.initiateDataTextArray,
    initiate_one_to_one_details_video: relationData.inviteDataVideoArray,
    calendars_meetings_details: relationData.calenderDataArray,
    inviting_delegates_details: relationData.inviteDataArray,
  };
};

export const mapSingleTierData = (ele, index) => {
  return {
    booth_template_id: ele.booth_template_id
      ? ele.booth_template_id
      : ele.tier_id,
    chart_boards: ele.chart_boards ? ele.chart_boards : false,
    chat_board_moderation: ele.chat_board_moderation
      ? ele.chat_board_moderation
      : false,
    chat_bots: ele.chat_bots ? ele.chat_bots : false,
    dashboard_level: ele.dashboard_level ? ele.dashboard_level : "",
    eventsTiersSettings_booth_template_id:
      ele.eventsTiersSettings_booth_template_id
        ? ele.eventsTiersSettings_booth_template_id
        : "",
    eventsTiersSettings_dashboard_level: ele.eventsTiersSettings_dashboard_level
      ? ele.eventsTiersSettings_dashboard_level
      : "",
    is_selected: ele.is_selected ? ele.is_selected : false,
    new_video_rep: ele.new_video_rep ? ele.new_video_rep : false,
    no_of_agents: ele.no_of_agents ? ele.no_of_agents : 0,
    no_of_assets_per_zone: ele.no_of_assets_per_zone
      ? ele.no_of_assets_per_zone
      : 0,
    no_of_sponserships: ele.no_of_sponserships ? ele.no_of_sponserships : 0,
    no_of_zones: ele.no_of_zones ? ele.no_of_zones : 0,
    on_booth_leads: ele.on_booth_leads ? ele.on_booth_leads : false,
    open_demo_rooms: ele.open_demo_rooms ? ele.open_demo_rooms : false,
    stream_polls: ele.stream_polls ? ele.stream_polls : false,
    survey_builder: ele.survey_builder ? ele.survey_builder : false,
    tier_id: ele.tier_id,
    tier_settings_id: ele.tier_settings_id ? ele.tier_settings_id : "",
  };
};

//tiers
export const mapTiersSchema = (tiersDefaultAPIData, tierDataAlreadyPresent) => {
  if (tierDataAlreadyPresent.length === tiersDefaultAPIData.length) {
    let mappedData = cloneDeep(tierDataAlreadyPresent);
    let event_tier_details = [];
    mappedData.forEach((element) => {
      event_tier_details.push(mapSingleTierData(element));
    });
    return {
      event_tier_details,
    };
  } else {
    let defaultTiersArr = [];
    tiersDefaultAPIData &&
      tiersDefaultAPIData.forEach((element) => {
        defaultTiersArr.push(mapSingleTierData(element));
      });

    if (defaultTiersArr) {
      let event_tier_details = defaultTiersArr.map(
        (obj) =>
          tierDataAlreadyPresent.find((o) => o.tier_id === obj.tier_id) || obj
      );
      return {
        event_tier_details,
      };
    }
  }
};

export const mapDefaultTiersToSchema = (tiersDefaultAPIData) => {
  let event_tier_details = [];
  let clonedTiersData = cloneDeep(tiersDefaultAPIData);
  console.log("tiersDefaultAPIData 232 = ", tiersDefaultAPIData);
  clonedTiersData &&
    clonedTiersData.forEach((element) => {
      event_tier_details.push(mapSingleTierData(element));
    });

  console.log("event_tier_details 232 = ", event_tier_details);
  return {
    event_tier_details,
  };
};

//navigation and reception
export const mapNavigationReceptionSchema = (
  data,
  settingTypesCollectionData,
  mediaLinks
) => {
  let external_social_details = [];
  data?.external_social_details?.forEach((item) => {
    const obj = {
      social_link_id: item.social_link_id ?? "",
      website_url: "website.com",
      social_media_id: item.social_media_id ?? "",
      social_media_url: item.social_media_url ?? null,
      social_media_type: item.social_media_type ?? null,
      social_media: item.social_media ? item.social_media : "",
      selected: item?.social_media_url ?? false,
    };
    external_social_details.push(obj);
  });

  let selected_agents = [];
  data?.agents?.map((val, idx) => {
    selected_agents.push(val.user_id);
  });

  let final_main_setting;
  let clonedsettingTypesCollectionData = cloneDeep(settingTypesCollectionData);
  const settingCollection = clonedsettingTypesCollectionData.map((v) => ({
    ...v,
    is_side_navigation_active: false,
    is_reception_active: false,
  }));
  if (isEmpty(data.navigation_main_settings))
    final_main_setting = settingCollection;
  else final_main_setting = data.navigation_main_settings;

  let final_social_details;
  if (isEmpty(external_social_details)) {
    let initialArr = cloneDeep(mediaLinks);
    const newArr1 = initialArr.map((v) => ({ ...v, selected: false }));
    final_social_details = newArr1;
  } else {
    if (external_social_details.length === mediaLinks.length) {
      final_social_details = external_social_details;
    } else {
      let external_social_details_new = mediaLinks.map(
        (obj) =>
          external_social_details.find(
            (o) => o.social_media_id === obj.social_media_id
          ) || obj
      );

      let tempArr = [];
      external_social_details_new &&
        external_social_details_new.forEach((item) => {
          let ele = item;
          if (!item.selected) ele.selected = false;
          tempArr.push(ele);
        });
      final_social_details = tempArr;
    }
  }

  let agentsFinal = data && data.agents ? data.agents : [];
  agentsFinal &&
    agentsFinal.map((val, idx) => {
      selected_agents.push(val.user_id);
    });

  return {
    navigation_main_settings: final_main_setting,
    reception_zone_editor: {
      reception_zone_id:
        data &&
        data.reception_zone_editor &&
        data.reception_zone_editor.reception_zone_id
          ? data.reception_zone_editor.reception_zone_id
          : "",
    },

    interaction_details: {
      interaction_settings_id:
        data &&
        data.interaction_details &&
        data.interaction_details.interaction_settings_id
          ? data.interaction_details.interaction_settings_id
          : "",
      is_text_chat_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_text_chat_enable
          ? data.interaction_details.is_text_chat_enable
          : false,
      is_message_board_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_message_board_enable
          ? data.interaction_details.is_message_board_enable
          : false,
      is_moderate_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_moderate_enable
          ? data.interaction_details.is_moderate_enable
          : false,
      is_one_to_one_video_calls_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_one_to_one_video_calls_enable
          ? data.interaction_details.is_one_to_one_video_calls_enable
          : false,
      interaction_survey_id:
        data &&
        data.interaction_details &&
        data.interaction_details.interaction_survey_id
          ? data.interaction_details.interaction_survey_id
          : "",
      is_on_booth_leads_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_on_booth_leads_enable
          ? data.interaction_details.is_on_booth_leads_enable
          : false,
      is_video_rep_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_video_rep_enable
          ? data.interaction_details.is_video_rep_enable
          : false,
      is_video_demo_room_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_video_demo_room_enable
          ? data.interaction_details.is_video_demo_room_enable
          : false,
      is_text_bot_enable:
        data &&
        data.interaction_details &&
        data.interaction_details.is_text_bot_enable
          ? data.interaction_details.is_text_bot_enable
          : false,
      bot_responses_file:
        data &&
        data.interaction_details &&
        data.interaction_details.bot_responses_file
          ? data.interaction_details.bot_responses_file
          : "",
      bot_survey_id: null,
    },
    external_social_details: final_social_details,
    agents: !isEmpty(selected_agents) ? selected_agents : [],
    event_assets_settings:
      data && data.event_assets_settings ? data.event_assets_settings : [],
  };
};

export const getAssetObject = (ele) => {
  return {
    asset_file_type: "image",
    asset_file_url: ele && ele.asset_file_url ? ele.asset_file_url : "",
    asset_name: ele && ele.asset_name ? ele.asset_name : "",
    asset_description:
      ele && ele.asset_description ? ele.asset_description : "",
    is_active: ele && ele.is_active ? ele.is_active : true,
    is_downloadable: ele && ele.is_downloadable ? ele.is_downloadable : true,
    max_no_of_downloads:
      ele && ele.max_no_of_downloads ? ele.max_no_of_downloads : 0,
    total_downloads: ele && ele.total_downloads ? ele.total_downloads : 0,
    whitelisted_domains:
      ele && ele.whitelisted_domains ? ele.whitelisted_domains : "",
    blacklisted_domains:
      ele && ele.blacklisted_domains ? ele.blacklisted_domains : "",
    view_count: ele && ele.view_count ? ele.view_count : 0,
  };
};

export const getSocialWallMediaLinkObject = (ele) => {
  return {
    enable_auto_repost: ele.enable_auto_repost ? ele.enable_auto_repost : false,
    enable_sharing: ele.enable_sharing ? ele.enable_sharing : false,
    repost_account: ele.repost_account ? ele.repost_account : "",
    repost_text: ele.repost_text ? ele.repost_text : "",
    sharing_setting_id: ele.sharing_setting_id ? ele.sharing_setting_id : "",
    social_media: ele.social_media ? ele.social_media : "",
    social_media_id: ele.social_media_id ? ele.social_media_id : "",
    social_media_type: ele.social_media_type ? ele.social_media_type : {},
    social_settings_id: ele.social_settings_id ? ele.social_settings_id : "",
  };
};

//social wall
export const mapSocialWallSchema = (data, mediaLinks) => {
  // console.log("mediaLinks = ", mediaLinks);

  let social_wall_sharing_details = [];
  data?.social_wall_sharing_details?.forEach((item) => {
    const obj = getSocialWallMediaLinkObject(item);
    social_wall_sharing_details.push(obj);
  });

  let final_social_wall_sharing_details;
  if (isEmpty(social_wall_sharing_details)) {
    let initialArr = cloneDeep(mediaLinks);
    const newArr1 = initialArr.map((v) => ({
      ...v,
      ...getSocialWallMediaLinkObject(v),
    }));
    final_social_wall_sharing_details = newArr1;
  } else {
    if (social_wall_sharing_details.length === mediaLinks.length) {
      final_social_wall_sharing_details = social_wall_sharing_details;
    } else {
      let social_details_new = mediaLinks.map(
        (obj) =>
          social_wall_sharing_details.find(
            (o) => o.social_media_id === obj.social_media_id
          ) || obj
      );

      let tempArr = [];
      social_details_new &&
        social_details_new.forEach((item) => {
          const ele = getSocialWallMediaLinkObject(item);
          tempArr.push(ele);
        });
      final_social_wall_sharing_details = tempArr;
    }
  }

  let final_social_wall_moderators = [];
  data?.social_wall_moderators?.map((ele) => {
    // console.log("ele in moderator = ", ele);
    final_social_wall_moderators.push(ele.user_id);
  });

  // console.log("final_social_wall_moderators = ", final_social_wall_moderators);

  return {
    social_settings_id:
      data && data.social_settings_id ? data.social_settings_id : "",
    social_wall_title:
      data && data.social_wall_title ? data.social_wall_title : "",
    social_wall_description:
      data && data.social_wall_description ? data.social_wall_description : "",
    default_status_new_post:
      data && data.default_status_new_post
        ? data.default_status_new_post
        : false,
    social_wall_moderators: final_social_wall_moderators,
    social_wall_sharing_details: final_social_wall_sharing_details,
  };
};
