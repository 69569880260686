import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import { Redirect,useHistory} from 'react-router-dom'
import {ApiCall} from '../../utils/api'

const initialState = {
  data:[],
  isLoggedIn:false,
  loading:false
}


export const login = createAsyncThunk(
    
    'adminConsole/login',
    
    async (loginData,thunkAPI) => {
    
    const response=await ApiCall("POST",loginData,"/admin/user/login","").then((res)=>res)
    // console.log('response', response)
    
    return response.data
  })

export const loginSlice = createSlice({
  name: 'adminConsole',
  initialState,
  reducers: {
    reAuthenticate: (state, action) => {
      if(localStorage.getItem("Authorization")){
        state.isLoggedIn = true
      }
      
    },
  },
  extraReducers:{
    [login.pending]: (state) => {
      state.loading = true
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.data = payload
      state.isLoggedIn = true
    },
    [login.rejected]: (state, {payload}) => {
      state.loading = false
    },
  },
})

export const loginReducer = loginSlice.reducer

// export const { fetchEventListing} = loginSlice.actions

// export default loginSlice.reducer