/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
/**
 * @name AppContext
 */

// Dummy Code => Where the dummy code comment is, means there has been some cruddy implemented code that is the logic to make the example work, in no way should this be used as an example of implementation. It is just a quick way of applying the vista messages to logic.
// The important part is the data that is being passed in the vista messages which can be seen in the console logs, and the structure of the different vistas.

import React, { createContext, useEffect, useReducer } from "react";
import logger from "use-reducer-logger";
// DUMMY DATA IMPORT
import { event, hotSpotData, hallData } from "../apiData";

const AppContext = createContext("");
// Context ACTIONS
export const ACTIONS = {
	ON_LOAD: "ON_LOAD",
	SET_ACTIVE_WINDOW: "SET_ACTIVE_WINDOW",
	SET_STAND: "SET_STAND",
	SET_HALL: "SET_HALL",
	SET_HOTSPOT_DATA: "SET_HOTSPOT_DATA",
	SET_VISTA_SRC: "SET_VISTA_SRC",
	SET_VISTA_LOADED: "SET_VISTA_LOADED",
	SET_LOCATION: "SET_LOCATION",
};

// Context Initial State
const initialState = Object.freeze({
	loaded: false,
	// activeWindow: { value: "", assetUrl: "" },
	activeWindow: "",
	stand: null,
	hall: hallData[0],
	location: {
		id: 1,
		hotspots: [
			{
				target: 1,
			},
		],
	},
	currentPosition: "exteriorPublished",
	hotspotData: null,
	src: `${process.env.REACT_APP_VISTA_BASE_URL}exterior-publish/index.html`,
	vistaLoaded: false,
});

// Context Reducer
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.ON_LOAD:
			return { ...state, loaded: true };
		case ACTIONS.SET_STAND:
			return {
				...state,
				stand: action.value,
				vistaLoaded: false,
				src: `${process.env.REACT_APP_VISTA_BASE_URL}${action.value.url}`,
				currentPosition: "standPublished",
			};
		case ACTIONS.SET_ACTIVE_WINDOW:
			// return { ...state, activeWindow: action.value }
			return { ...state, activeWindow: action.value };
		case ACTIONS.SET_HALL:
			return {
				...state,
				hall: action.value,
				src: `${process.env.REACT_APP_VISTA_BASE_URL}${
					action.url ? action.url : action.value.url
				}`,
				currentPosition: "hallPublished",
				stand: null,
			};
		case ACTIONS.SET_HOTSPOT_DATA:
			const hotspot = getHotspot(action.value, state.hall);
			return { ...state, hotspotData: hotspot };
		case ACTIONS.SET_VISTA_SRC:
			return {
				...state,
				// src: `/vista${action.value.src}`,
				src: `${process.env.REACT_APP_VISTA_BASE_URL}${action.value.src}`,
				currentPosition: action.value.currentPosition,
				vistaLoaded: false,
				stand: null,
			};
		case ACTIONS.SET_VISTA_LOADED:
			return { ...state, vistaLoaded: true };
		case ACTIONS.SET_LOCATION:
			return {
				...state,
				location: action.value.location,
				hall: null,
				stand: null,
			};
		default:
			return state;
	}
};

// In place of API call
// Every stand has an index of hot spots ranging from 1-9
// In the database, hot spot of stand X at index Y, relates to hot spot id Z
// this has not been implemented here as this is for **DEMO** purposes only
const getHotspot = (hallIndex, hotspotIndex) => {
	const data = hotSpotData[hotspotIndex] || null;
	// this is awful implementation its just to show the setting of the active window when we get a hotspot
	if (data) {
		reducer.dispatch({
			type: ACTIONS.SET_ACTIVE_WINDOW,
			value: "hotspot",
		});
	}
	return data;
};

// Context Provider
AppContext.Provider = ((Provider) => (props) => {
	const { children } = props || {};

	const [state, dispatch] = useReducer(logger(reducer), initialState);

	useEffect(() => {
		if (!state.loaded) {
			dispatch({
				type: ACTIONS.ON_LOAD,
			});
		}
	}, [state.loaded]);

	return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
})(AppContext.Provider);

export default AppContext;
