/**
 * @name VistaContext
 */

import React, {
	createContext,
	useReducer,
	useContext,
	useEffect,
	useRef,
	useCallback,
	useState,
} from "react";
import logger from "use-reducer-logger";
import { IFrameManager } from "@dbpx/dbutils";
import {
	standsData,
	hotSpotData,
	HOTSPOT_TYPES,
	locationData,
	hallData,
	exteriorPublished,
	concoursePublished,
	auditoriumPublished,
	networkingPublished,
	satellitePublished,
} from "../apiData";
import AppContext, { ACTIONS as AppActions } from "./AppContext";
// import { assertOptionalCallExpression } from "../../../../../../../Library/Caches/typescript/4.6/node_modules/@babel/types/lib/index";

const VistaContext = createContext();

// Context Actions
export const ACTIONS = Object.freeze({
	SET_VIEWER: "SET_VIEWER",
	SET_SRC: "SET_SRC",
	SET_LOADED: "SET_LOADED",
});

// Context Initial State
const initialState = {
	viewer: null,
	loaded: false,
};

// Context Reducer
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_VIEWER:
			return { ...state };
		case ACTIONS.SET_LOADED:
			return { ...state, loaded: true };
		default:
			return { ...state };
	}
};

VistaContext.Provider = ((Provider) => (props) => {
	const { children } = props || {};
	const {
		dispatch: AppDispatch,
		location: vistaLocation,
		stand,
		hall,
		currentPosition,
	} = useContext(AppContext);

	// state
	const [manager, setManager] = useState();

	// ref
	const frame = useRef();
	const frameCache = useRef();

	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (!frame || frame !== frameCache.current) {
			setManager(new IFrameManager(frame));
			frameCache.current = frame;
		}
	}, [frame]);

	// #region Vista event handles
	// Vista is ready for events
	const ready = useCallback(() => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}

		// Dummy Code
		AppDispatch({
			type: AppActions.SET_VISTA_LOADED,
			value: true,
		});
	});

	const hotspot = useCallback((payload) => {
		console.log(currentPosition, "-------default position-------");
		console.log(payload, "-------load---------");
		let activeHotSpot = [];
		let activeVista = "";

		switch (currentPosition) {
			case "exteriorPublished":
				activeHotSpot = exteriorPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				if (payload.data === 1) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `login`,
					});
				}
				break;
			case "concoursePublished":
				console.log(payload, "concourse payload");
				activeHotSpot = concoursePublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				switch (payload.data) {
					case 1:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `reception`,
						});
						break;
					case 2:
						activeVista = "auditoriumPublished";
						
						break;
				
					case 3:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `auditorium`,
						});
						break;
					case 5:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `networking`,
						});
						break;
					case 6:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `sponsorbooth`,
						});
						break;
					case 8:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `socialwall`,
						});
						break;
					case 9:
					case 10:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `expomap`,
						});
						break;
					case 11:
					case 13:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `eventcalendar`,
						});
						break;
					default:
						console.log("No relevant hotspot found!");
						activeVista = "concoursePublished";
						break;
				}
				break;
			case "auditoriumPublished":
				activeHotSpot = auditoriumPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "auditoriumPublished";
				console.log("pd", payload.data);
				if(payload.data === 2) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `audimeet`,
					});
				}
				break;
			case "networkingPublished":
				activeHotSpot = networkingPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "networkingPublished";
				break;
			case "hallPublished":
				activeHotSpot = hallData[0].hotspots.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "hallPublished";
				break;
			case "satellitePublished":
				activeHotSpot = satellitePublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "satellitePublished";
				break;
			case "standPublished":
				activeHotSpot = standsData[0].hotspots.filter(
					(publishData) => publishData.id === payload.data,
				);
				AppDispatch({
					type: AppActions.SET_ACTIVE_WINDOW,
					value: `hallbooth-${payload.data}`,
				});
				activeVista = "standPublished";
				break;
			default:
				console.log("No data for hotspot");
		}

		if (activeHotSpot.length > 0) {
			const data = activeHotSpot[0];
			switch (data.hotSpotType) {
				case HOTSPOT_TYPES.ASSET:
					if (currentPosition !== "concoursePublished" && currentPosition !== "auditoriumPublished") {
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							// value: { value: data.type, assetUrl: data.url }
							value: `${data.type}`,
						});
					} 
					break;
				case HOTSPOT_TYPES.BRAND_ZONE:
					break;
				case HOTSPOT_TYPES.VIEW:
					// AppDispatch({
					//     type: AppActions.SET_ACTIVE_WINDOW,
					//     value: data.value
					// })
					if (currentPosition !== "concoursePublished") {
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							// value: { value: data.type, assetUrl: data.url }
							value: `${data.type}`,
						});
					}
					break;
				case HOTSPOT_TYPES.NAVIGATE:
					console.log("navigate", data);
					if (currentPosition !== "exteriorPublished") {
						AppDispatch({
							type: AppActions.SET_VISTA_SRC,
							value: {
								src: data.redirectionUrl,
								currentPosition: data.nextPostion,
							},
						});
					}
					// if (data.value.location) {
					//     AppDispatch({
					//         type: AppActions.SET_LOCATION,
					//         value: {
					//             data: data.value,
					//             location: locationData[data.value.location - 1]
					//         }
					//     })
					// }
					break;
				default:
					console.log("unhandled case");
					break;
			}
		} else {
			console.log("No Active HotSPot Found");
		}

		// let hotspotId = 0
		// if (stand) {
		//     hotspotId = stand.hotspots.filter(item => item.index === payload.data)[0]?.target || 0
		// } else if (hall) {
		//     hotspotId = hall.hotspots.filter(item => item.index === payload.data)[0]?.target || 0
		// } else {
		//     hotspotId = vistaLocation.hotspots[payload.data - 1]?.target || 0;
		// }

		// if (hotspotId === 0) {
		//     // eslint-disable-next-line no-console
		//     console.log("Something went wrong, hotspotId : 0");
		//     return
		// }

		// const { data } = hotSpotData.filter(hp => hp.id === hotspotId)[0]
		// console.log(data,"------current data0--------");

		// if (data) {
		//     switch (data.type) {
		//         case HOTSPOT_TYPES.ASSET:
		//             AppDispatch({
		//                 type: AppActions.SET_ACTIVE_WINDOW,
		//                 value: `Asset Viewer: ${data.value.name}`
		//             })
		//             break;
		//         case HOTSPOT_TYPES.BRAND_ZONE:
		//             break;
		//         case HOTSPOT_TYPES.VIEW:
		//             AppDispatch({
		//                 type: AppActions.SET_ACTIVE_WINDOW,
		//                 value: data.value
		//             })
		//             break;
		//         case HOTSPOT_TYPES.NAVIGATE:
		//             AppDispatch({
		//                 type: AppActions.SET_VISTA_SRC,
		//                 value: data.value.url
		//             })
		//             // if (data.value.location) {
		//             //     AppDispatch({
		//             //         type: AppActions.SET_LOCATION,
		//             //         value: {
		//             //             data: data.value,
		//             //             location: locationData[data.value.location - 1]
		//             //         }
		//             //     })
		//             // }
		//             break;
		//         default:
		//             console.log("unhandled case");
		//             break;
		//     }
		// }
	});

	// tracking being caught in vista
	const location = useCallback((payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}
	});

	const section = useCallback((payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}
	});

	const booth = useCallback((payload) => {
		console.log(payload, "-----pay load------");
		console.log(hall, "------halll ------");
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}

		const stand = standsData.filter(
			(stand) =>
				stand.id ===
				hall.stands.filter((stand) => stand.index === payload.data)[0].standID,
		)[0];
		if (stand) {
			stand.exit = Math.ceil(payload.data / 2);
			AppDispatch({
				type: AppActions.SET_STAND,
				value: stand,
			});
		}
	});

	const exit = useCallback((payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}

		AppDispatch({
			type: AppActions.SET_HALL,
			value: hall,
			url: `${hall.url}?media-name=${stand.exit}`,
		});
	});
	//  #endregion

	// Events
	useEffect(() => {
		if (manager && frame) {
			manager.on("ready", ready);
			manager.on("hotspot", hotspot);
			manager.on("location", location);
			manager.on("section", section);
			manager.on("booth", booth);
			manager.on("exit", exit);
		}

		return () => {
			if (manager && frame) {
				manager.off("ready", ready);
				manager.off("hotspot", hotspot);
				manager.off("location", location);
				manager.off("section", section);
				manager.off("booth", booth);
				manager.off("exit", exit);
			}
		};
	});

	return (
		<Provider
			value={{
				...state,
				dispatch,
				frame,
			}}
		>
			{children}
		</Provider>
	);
})(VistaContext.Provider);

export default VistaContext;
