import React, { Fragment, useState, useEffect } from "react";
import { Switch, Button, Breadcrumb, Input, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { EmailRed } from "../../components/svgJS/EmailRed";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";
import { useMediaQuery } from "react-responsive";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerDetails,
  editCustomer,
} from "../../redux/slice/customerManagementSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { todayIsBeetweenTwoDates } from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";

const EditCustomer = (props) => {
  var CryptoJS = require("crypto-js");
  const [customerData, setcustomerData] = useState(null);
  const [showNewEmail, setshowNewEmail] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false)
  console.log("customerData", customerData);
  const [initialState, setinitialState] = useState({
    is_delete: false,
    is_active: false,
    account_name: "",
    license_period_start_date: null,
    license_period_end_date: null,
    main_contact_person: "",
    main_contact_email: "",
    new_email: "",
    // primary_exhibitor: "",
    is_exhibitor_booth_available: false,
    orgaziner_brand: 1,
    is_organizer_booth_available: false,
    max_no_of_events: 1,
    max_no_of_concurrent_events: 1,
    max_no_events_per_month: 1,
    max_no_of_exhibitors: 1,
    max_no_of_exhibitors_per_event: 1,
    max_no_of_users: 1,
    max_no_of_concurrent_users: 1,
    max_bandwidth_per_month: 1,
    max_storage_size: 1,
    max_video_room_hours_per_month: 1,
    max_no_of_events_switch: true,
    max_no_of_concurrent_events_switch: true,
    max_no_events_per_month_switch: true,
    max_no_of_exhibitors_switch: true,
    max_no_of_exhibitors_per_event_switch: true,
    max_no_of_users_switch: true,
    max_no_of_concurrent_users_switch: true,
    max_bandwidth_per_month_switch: true,
    max_storage_size_switch: true,
    max_video_room_hours_per_month_switch: true,
  });
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const dispatch = useDispatch();
  const { match } = props;
  const { loading } = useSelector((state) => state.customerManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setCustomerLoading(true)
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }
    setTimeout(() => {
      setCustomerLoading(false);
    }, 1000);
    getCustomerDetail();
  }, []);

  const getCustomerDetail = async () => {
    const responce = await dispatch(
      getCustomerDetails({ customer_id: match.params.customer_id })
    );
    // console.log('responce', responce)

    const { details } = responce.payload.data.data;

    setcustomerData(details);
  };
  useEffect(() => {
    setinitialState({
      ...initialState,
      is_delete: customerData && customerData.is_delete,
      is_active: customerData && customerData.is_active,
      account_name: customerData && customerData.account_name,
      // license_period_start_date: customerData && moment(customerData.license_period_start_date).format('DD-MM-YYYY'),
      // license_period_end_date: customerData && moment(customerData.license_period_end_date).format('DD-MM-YYYY'),
      license_period_start_date:
        customerData && customerData.license_period_start_date,
      license_period_end_date:
        customerData && customerData.license_period_end_date,
      main_contact_person: customerData && customerData.main_contact_person,
      main_contact_email: customerData && customerData.main_contact_email,
      // primary_exhibitor: customerData && customerData.primary_exhibitor,
      is_exhibitor_booth_available:
        customerData && customerData.is_exhibitor_booth_available,
      orgaziner_brand: customerData && customerData.orgaziner_brand,
      is_organizer_booth_available:
        customerData && customerData.is_organizer_booth_available,
      max_no_of_events:
        customerData &&
        customerData.customer_customer_settings_id?.max_no_of_events,
      max_no_of_concurrent_events:
        customerData &&
        customerData.customer_customer_settings_id
          ?.max_no_of_concurrent_events,
      max_no_events_per_month:
        customerData &&
        customerData.customer_customer_settings_id?.max_no_events_per_month,
      max_no_of_exhibitors:
        customerData &&
        customerData.customer_customer_settings_id?.max_no_of_exhibitors,
      max_no_of_exhibitors_per_event:
        customerData &&
        customerData.customer_customer_settings_id
          ?.max_no_of_exhibitors_per_event,
      max_no_of_users:
        customerData &&
        customerData.customer_customer_settings_id?.max_no_of_users,
      max_no_of_concurrent_users:
        customerData &&
        customerData.customer_customer_settings_id
          ?.max_no_of_concurrent_users,
      max_bandwidth_per_month:
        customerData &&
        parseInt(
          customerData.customer_customer_settings_id?.max_bandwidth_per_month
        ),
      max_storage_size:
        customerData &&
        parseInt(
          customerData.customer_customer_settings_id?.max_storage_size
        ),
      max_video_room_hours_per_month:
        customerData &&
        parseInt(
          customerData.customer_customer_settings_id
            ?.max_video_room_hours_per_month
        ),

      max_no_of_events_switch:
        customerData &&
          customerData.customer_customer_settings_id?.max_no_of_events === 0
          ? false
          : true,
      max_no_of_concurrent_events_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_no_of_concurrent_events === 0
          ? false
          : true,
      max_no_events_per_month_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_no_events_per_month === 0
          ? false
          : true,
      max_no_of_exhibitors_switch:
        customerData &&
          customerData.customer_customer_settings_id?.max_no_of_exhibitors === 0
          ? false
          : true,
      max_no_of_exhibitors_per_event_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_no_of_exhibitors_per_event === 0
          ? false
          : true,
      max_no_of_users_switch:
        customerData &&
          customerData.customer_customer_settings_id?.max_no_of_users === 0
          ? false
          : true,
      max_no_of_concurrent_users_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_no_of_concurrent_users === 0
          ? false
          : true,
      max_bandwidth_per_month_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_bandwidth_per_month === 0
          ? false
          : true,
      max_storage_size_switch:
        customerData &&
          customerData.customer_customer_settings_id?.max_storage_size === 0
          ? false
          : true,
      max_video_room_hours_per_month_switch:
        customerData &&
          customerData.customer_customer_settings_id
            ?.max_video_room_hours_per_month === 0
          ? false
          : true,
    });
  }, [customerData]);

  const handleCreateCustomer = async (data) => {
    // console.log(data);
    const formData = {
      customer_id: parseInt(match.params.customer_id),
      // main_contact_email:data.new_email,
      customer_settings_id:
        customerData?.customer_customer_settings_id?.customer_settings_id,
      ...data,
    };
    if (data.new_email) {
      formData.main_contact_email = data.new_email;
    }
    delete formData.new_email;
    // console.log('formData', formData)

    const addcustomerResp = await dispatch(editCustomer(formData));
    // console.log('addcustomerResp', addcustomerResp)

    if (addcustomerResp.payload.code === 200) {
      toast.success(addcustomerResp.payload.message);
      props.history.push("/customermanagement");
    }
    if (addcustomerResp.payload.code !== 200) {
      toast.error(addcustomerResp.payload.message);
      // console.log("200 success");
      // props.history.push("surveyManagement");
    }
  };
  const ValidationSchema = Yup.object().shape({
    // is_active:Yup.boolean().required('Required').oneOf([true],"Required"),
    account_name: Yup.string().required("Required").nullable(),
    // license_period_start_date: Yup.string().required('Required').nullable(),
    // license_period_end_date: Yup.string().required('Required').nullable(),
    main_contact_person: Yup.string().required("Required").nullable(),
    new_email: Yup.string()
      .email("Please Enter Valid Email")
      // .required("Required")
      .nullable(),
    license_period_start_date: Yup.date().required("Required").nullable(),
    license_period_end_date: Yup.date()
      .min(
        Yup.ref("license_period_start_date"),
        "End Date should be greater than Start Date"
      )
      .required("Required")
      .nullable(),
  });

  if (customerLoading) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <Formik
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          initialValues={initialState}
          // isInitialValid={false}
          onSubmit={(values, { setSubmitting }) => {
            handleCreateCustomer(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            /* and other goodies */
          }) => (
            <div className="view-all-common">
              {loading ? (
                <customerLoading />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="fs-24">Edit Customer Account</h4>
                      <Breadcrumb className="my-2" separator=">">
                        <Breadcrumb.Item>
                          <Link to="/customermanagement">Admin Console</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/customermanagement">
                            Customer Management
                          </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Edit Customer Account</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>

                    <div>
                      {/* <Button htmlType="submit" className="button-pink rounded px-3 py-1 mt-2">
                    <span className="fw-normal"> Save Changes </span>
                  </Button> */}
                    </div>
                  </div>

                  {/* <Main Div */}
                  <div className="bg-white p-3">
                    {/* section account details */}
                    <section>
                      <div className="row">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20 px-2">
                            Account Details
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary">
                            Active
                          </label>
                        </div>
                        <div className={Tablet ? "col-2" : "col-1"}>
                          <Switch
                            checked={values.is_active}
                            name="is_active"
                            onChange={() => {
                              // setFieldValue("is_active", !values.is_active)
                            }}
                            className="mx-3"
                          />
                        </div>
                        {/* <div className="col-3">
                    <Button type="primary" style={{ width: "190px" }}>
                      <span className="px-2">
                        <BinWhite />
                      </span>
                      Delete Account
                    </Button>
                  </div> */}
                      </div>
                      <div className="row mt-4">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary mt-1">
                            Account Name{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </label>
                        </div>
                        <div className="col-3">
                          <Input
                            name="account_name"
                            className="w-100 border-gray rounded fs-bold-14"
                            value={values.account_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.account_name && touched.account_name ? (
                            <div className="text-danger">
                              {errors.account_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary">
                            License Start Date{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </label>
                        </div>
                        <div className="col-3">
                          <DatePicker
                            allowClear={false}
                            suffixIcon={<CalenderGrey width="15" />}
                            format="DD-MM-YYYY"
                            className="w-100 fs-bold-14"
                            name="license_period_start_date"
                            value={moment(values.license_period_start_date)}
                            // format="YYYY/MM/DD"
                            onChange={(dateObj, date) => {
                              console.log(dateObj);
                              setFieldValue("license_period_start_date", dateObj);

                              const ed = values.license_period_end_date;
                              const sd = dateObj;
                              const isValidActive = todayIsBeetweenTwoDates(
                                sd,
                                ed
                              );
                              console.log("isValidActivestart", isValidActive);
                              if (isValidActive && ed !== null) {
                                setFieldValue("is_active", true);
                              } else {
                                setFieldValue("is_active", false);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.license_period_start_date &&
                            touched.license_period_start_date ? (
                            <div className="text-danger">
                              {errors.license_period_start_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary">
                            License End Date{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </label>
                        </div>
                        <div className="col-3">
                          <DatePicker
                            allowClear={false}
                            suffixIcon={<CalenderGrey width="15" />}
                            format="DD-MM-YYYY"
                            className="w-100 fs-bold-14"
                            name="license_period_end_date"
                            // defaultValue={moment(values.license_period_end_date,'DD-MM-YYYY')}
                            value={moment(values.license_period_end_date)}
                            onChange={(dateObj, date) => {
                              setFieldValue("license_period_end_date", dateObj);

                              const sd = values.license_period_start_date;
                              const ed = dateObj;
                              const isValidActive = todayIsBeetweenTwoDates(
                                sd,
                                ed
                              );
                              console.log("isValidActive", isValidActive);
                              if (isValidActive && sd !== null) {
                                setFieldValue("is_active", true);
                              } else {
                                setFieldValue("is_active", false);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.license_period_end_date &&
                            touched.license_period_end_date ? (
                            <div className="text-danger">
                              {errors.license_period_end_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Organiser Brand <sup><span className="text-danger" style={{fontSize:"1.1rem"}}>*</span></sup>
                    </label>
                  </div>
                  <div className="col-3">
                    <Input
                      name="orgaziner_brand"
                      type="number"
                      value={values.orgaziner_brand}
                      onChange={handleChange}
                      className="w-100 border-gray rounded"
                    />
                  </div>
                  <div className="col-6">
                    <span>
                      <Switch defaultChecked className="mx-2" name="is_organizer_booth_available" checked={values.is_organizer_booth_available} onChange={()=>setFieldValue("is_organizer_booth_available",!values.is_organizer_booth_available)} />
                      Setup as a Brand within the system
                    </span>
                  </div>
                </div> */}

                      <div className="row mt-4">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary mt-1">
                            Main Contact Name{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </label>
                        </div>
                        <div className="col-3">
                          <Input
                            name="main_contact_person"
                            onChange={handleChange}
                            className="w-100 border-gray rounded fs-bold-14"
                            value={values.main_contact_person}
                            onBlur={handleBlur}
                          />
                          {errors.main_contact_person &&
                            touched.main_contact_person ? (
                            <div className="text-danger">
                              {errors.main_contact_person}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-4 pb-4 border-bottom-gray">
                        <div
                          className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                        >
                          <label htmlFor="" className="text-secondary mt-1">
                            Email Address
                            {/* <sup>
                        <span
                          className="text-danger"
                          style={{ fontSize: "1.1rem" }}
                        >
                          *
                        </span>
                      </sup> */}
                          </label>
                        </div>
                        <div className="col-3">
                          <Input
                            disabled
                            name="main_contact_email"
                            onChange={handleChange}
                            className="w-100 border-gray rounded fs-bold-14"
                            value={values.main_contact_email}
                            onBlur={handleBlur}
                          />
                          {/* {errors.main_contact_email && touched.main_contact_email ? (
                    <div className="text-danger">{errors.main_contact_email}</div>
                  ) : null} */}
                        </div>
                        <div className="col-3">
                          <Button
                            onClick={() => setshowNewEmail(true)}
                            className="px-3 font-pink button-pink rounded font-normal btn-svg"
                          >
                            <EmailRed /> <span className="mx-1"> Change</span>
                          </Button>
                        </div>
                      </div>
                      {showNewEmail && (
                        <>
                          <div className="row my-4">
                            <div className="col-2 text-end">
                              New Email Address
                              <sup>
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "1.1rem" }}
                                >
                                  *
                                </span>
                              </sup>
                            </div>
                            <div className="col-3">
                              <Input
                                name="new_email"
                                onChange={handleChange}
                                className="w-100 border-gray rounded fs-bold-14"
                                value={values.new_email}
                                onBlur={handleBlur}
                              />
                              {errors.new_email && touched.new_email ? (
                                <div className="text-danger">
                                  {errors.new_email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div
                              className={
                                Tablet ? "col-3 text-end" : "col-2 text-end"
                              }
                            ></div>
                            <div className="col-6">
                              <span className="fs-16">
                                <b>NB: </b>This will send an email to the users
                                new email address <br /> (to create a new
                                password). Their old email address / password will
                                no longer work.
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      {/* <div className="row mt-4 pb-4 border-bottom-gray">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Primary Exhibitor
                    </label>
                  </div>
                  <div className="col-3">
                    <Input
                      name="primary_exhibitor"
                      onChange={handleChange}
                      className="w-100 border-gray rounded fs-bold-14"
                      value={values.primary_exhibitor}
                    />
                  </div>
                  <div className="col-6">
                    <span>
                      <Switch
                        onChange={() =>
                          setFieldValue(
                            "is_exhibitor_booth_available",
                            !values.is_exhibitor_booth_available
                          )
                        }
                        checked={values.is_exhibitor_booth_available}
                        className="mx-2"
                      />
                      Do they have a booth at the event
                    </span>
                  </div>
                </div> */}
                    </section>

                    {/* section Account Permissions */}
                    <section>
                      <div className="row">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20 px-2">
                            Account Permissions
                          </h6>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Events
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_events_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_of_events", 0);
                              }
                              setFieldValue(
                                "max_no_of_events_switch",
                                !values.max_no_of_events_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_events <= 0 ||
                                values.max_no_of_events_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_events",
                                  values.max_no_of_events - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_events} */}
                            <input
                              disabled={values.max_no_of_events_switch !== true}
                              type="text"
                              style={{
                                width: "50%",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                                textAlign: "center",
                              }}
                              value={values.max_no_of_events}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_events",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={values.max_no_of_events_switch !== true}
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_events",
                                  values.max_no_of_events + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Concurrent Events
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_concurrent_events_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_of_concurrent_events", 0);
                              }
                              setFieldValue(
                                "max_no_of_concurrent_events_switch",
                                !values.max_no_of_concurrent_events_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_concurrent_events <= 0 ||
                                values.max_no_of_concurrent_events_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_concurrent_events",
                                  values.max_no_of_concurrent_events - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_concurrent_events} */}
                            <input
                              disabled={
                                values.max_no_of_concurrent_events_switch !== true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_no_of_concurrent_events}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_concurrent_events",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_no_of_concurrent_events_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_concurrent_events",
                                  values.max_no_of_concurrent_events + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Events Per Month
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_events_per_month_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_events_per_month", 0);
                              }
                              setFieldValue(
                                "max_no_events_per_month_switch",
                                !values.max_no_events_per_month_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_events_per_month <= 0 ||
                                values.max_no_events_per_month_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_events_per_month",
                                  values.max_no_events_per_month - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_events_per_month} */}
                            <input
                              disabled={
                                values.max_no_events_per_month_switch !== true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_no_events_per_month}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_events_per_month",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_no_events_per_month_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_events_per_month",
                                  values.max_no_events_per_month + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Brands
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_exhibitors_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_of_exhibitors", 0);
                              }
                              setFieldValue(
                                "max_no_of_exhibitors_switch",
                                !values.max_no_of_exhibitors_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_exhibitors <= 0 ||
                                values.max_no_of_exhibitors_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_exhibitors",
                                  values.max_no_of_exhibitors - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_exhibitors} */}
                            <input
                              disabled={
                                values.max_no_of_exhibitors_switch !== true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                                textAlign: "center",
                              }}
                              value={values.max_no_of_exhibitors}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_exhibitors",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_no_of_exhibitors_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_exhibitors",
                                  values.max_no_of_exhibitors + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Brands Per Event
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_exhibitors_per_event_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue(
                                  "max_no_of_exhibitors_per_event",
                                  0
                                );
                              }
                              setFieldValue(
                                "max_no_of_exhibitors_per_event_switch",
                                !values.max_no_of_exhibitors_per_event_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_exhibitors_per_event <= 0 ||
                                values.max_no_of_exhibitors_per_event_switch !==
                                true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_exhibitors_per_event",
                                  values.max_no_of_exhibitors_per_event - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_exhibitors_per_event} */}
                            <input
                              disabled={
                                values.max_no_of_exhibitors_per_event_switch !==
                                true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_no_of_exhibitors_per_event}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_exhibitors_per_event",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_no_of_exhibitors_per_event_switch !==
                                true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_exhibitors_per_event",
                                  values.max_no_of_exhibitors_per_event + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Users
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_users_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_of_users", 0);
                              }
                              setFieldValue(
                                "max_no_of_users_switch",
                                !values.max_no_of_users_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_users <= 0 ||
                                values.max_no_of_users_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_users",
                                  values.max_no_of_users - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_users} */}
                            <input
                              disabled={values.max_no_of_users_switch !== true}
                              type="text"
                              style={{
                                width: "50%",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                                textAlign: "center",
                              }}
                              value={values.max_no_of_users}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_users",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={values.max_no_of_users_switch !== true}
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_users",
                                  values.max_no_of_users + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            Max # Concurrent Users
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_no_of_concurrent_users_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_no_of_concurrent_users", 0);
                              }
                              setFieldValue(
                                "max_no_of_concurrent_users_switch",
                                !values.max_no_of_concurrent_users_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_no_of_concurrent_users <= 0 ||
                                values.max_no_of_concurrent_users_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_concurrent_users",
                                  values.max_no_of_concurrent_users - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_no_of_concurrent_users} */}
                            <input
                              disabled={
                                values.max_no_of_concurrent_users_switch !== true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_no_of_concurrent_users}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_no_of_concurrent_users",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_no_of_concurrent_users_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_no_of_concurrent_users",
                                  values.max_no_of_concurrent_users + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            {/* Max # Bandwidth per month Gb */}
                            Streams : Max Gb Bandwidth Per Month
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_bandwidth_per_month_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_bandwidth_per_month", 0);
                              }
                              setFieldValue(
                                "max_bandwidth_per_month_switch",
                                !values.max_bandwidth_per_month_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_bandwidth_per_month <= 0 ||
                                values.max_bandwidth_per_month_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_bandwidth_per_month",
                                  values.max_bandwidth_per_month - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_bandwidth_per_month} */}
                            <input
                              disabled={
                                values.max_bandwidth_per_month_switch !== true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_bandwidth_per_month}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_bandwidth_per_month",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_bandwidth_per_month_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_bandwidth_per_month",
                                  values.max_bandwidth_per_month + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            {/* Max # Storage Size Gb */}
                            Storage : Max Gb
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_storage_size_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue("max_storage_size", 0);
                              }
                              setFieldValue(
                                "max_storage_size_switch",
                                !values.max_storage_size_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_storage_size <= 0 ||
                                values.max_storage_size_switch !== true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_storage_size",
                                  values.max_storage_size - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_storage_size} */}
                            <input
                              disabled={values.max_storage_size_switch !== true}
                              type="text"
                              style={{
                                width: "50%",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                                textAlign: "center",
                              }}
                              value={values.max_storage_size}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_storage_size",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={values.max_storage_size_switch !== true}
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_storage_size",
                                  values.max_storage_size + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4 pb-4 border-bottom-gray">
                        <div
                          className={
                            Tablet ? "col-4 mt-1 text-end" : "col-3 mt-1 text-end"
                          }
                        >
                          <label htmlFor="" className="text-secondary">
                            {/* Max # Storage Size Gb */}
                            Video Rooms : Max Hours Per Month
                          </label>
                        </div>
                        <div className={Tablet ? "col-2 mt-1" : "col-1"}>
                          <Switch
                            checked={values.max_video_room_hours_per_month_switch}
                            onChange={(bool) => {
                              if (!bool) {
                                setFieldValue(
                                  "max_video_room_hours_per_month",
                                  0
                                );
                              }
                              setFieldValue(
                                "max_video_room_hours_per_month_switch",
                                !values.max_video_room_hours_per_month_switch
                              );
                            }}
                            className="mx-3"
                          />
                        </div>
                        <div className="col-2 text-start">
                          <div
                            class="btn-group mx-auto border-gray event-editor-increment-btn rounded w-75 "
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              disabled={
                                values.max_video_room_hours_per_month <= 0 ||
                                values.max_video_room_hours_per_month_switch !==
                                true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_video_room_hours_per_month",
                                  values.max_video_room_hours_per_month - 1
                                )
                              }
                              class="btn py-1 "
                            >
                              -
                            </button>
                            {/* <button type="button" class="btn  py-1 "> */}
                            {/* {values.max_video_room_hours_per_month} */}
                            <input
                              disabled={
                                values.max_video_room_hours_per_month_switch !==
                                true
                              }
                              type="text"
                              style={{
                                width: "50%",
                                textAlign: "center",
                                backgroundColor: "#FBFBFB",
                                border: "none",
                              }}
                              value={values.max_video_room_hours_per_month}
                              onChange={(e) => {
                                const { value } = e.target;
                                console.log("value", value);
                                const reg = /^-?\d*(\.\d*)?$/;
                                if (
                                  (reg.test(value) ||
                                    value === "" ||
                                    value === "-") &&
                                  value
                                ) {
                                  setFieldValue(
                                    "max_video_room_hours_per_month",
                                    parseInt(value)
                                  );
                                }
                              }}
                            />
                            {/* </button> */}
                            <button
                              disabled={
                                values.max_video_room_hours_per_month_switch !==
                                true
                              }
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  "max_video_room_hours_per_month",
                                  values.max_video_room_hours_per_month + 1
                                )
                              }
                              class="btn  py-1 "
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* section Cancel */}
                    <section>
                      <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                        <div>
                          <Link to="/customermanagement">
                            <Button className="rounded button-pink px-3">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                        <div>
                          <Button
                            htmlType="submit"
                            type="primary"
                            className="rounded  px-3"
                            onClick={() => {
                              if (!isValid) {
                                toast.error("Fields are Missing");
                              }
                              window.scrollTo(0, 0);
                            }}
                          >
                            {!loading ? (
                              "Save Changes"
                            ) : (
                              <div style={{ width: "80px" }}>
                                <Spin
                                  indicator={loadingIcon}
                                  style={{ color: "white" }}
                                />
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </section>
                  </div>
                </form>
              )}
            </div>
          )}
        </Formik>
      </Fragment>
    );
};

export default EditCustomer;
