import {
  createSlice,
  createAsyncThunk,
  miniSerializeError,
} from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

const initialState = {
  eventList: {
    eventEditorData: {},
    registerEventData: {},
    tiersData: {},
    navigationData: {},
  },
  loading: false,
};

export const createEvent = createAsyncThunk(
  "createEvent/createEvent",

  async (body) => {
    console.log("body", body);
    // const response=await ApiCallForm("POST",params,"/admin/events/create","", "")
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/create",
      ""
    );
    console.log("create response createEvent", response);

    return response;
  }
);

export const registerEvent = createAsyncThunk(
  "createEvent/registerEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/registrationandcommunication/create",
      "",
      ""
    );
    console.log("create response registerEvent", response);

    return response;
  }
);

export const tierEvent = createAsyncThunk(
  "createEvent/tierEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/tiers/create",
      "",
      ""
    );
    console.log("create response tierEvent", response);

    return response;
  }
);

export const navigationAndReceptionEvent = createAsyncThunk(
  "createEvent/navigationAndReceptionEvent",

  async (body) => {
    console.log("params", body);
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/navigationplusreception/create",
      ""
    );
    console.log("create response navigationAndReceptionEvent", response);

    return response;
  }
);

export const socialWallEvent = createAsyncThunk(
  "createEvent/socialWallEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "POST",
      params,
      "/admin/events/socialwall/create",
      "",
      ""
    );
    console.log("create response socialWallEvent", response);

    return response;
  }
);

export const getEventEditorData = createAsyncThunk(
  "getEvent/getEventEditorData",

  async (body) => {
    console.log("body", body);
    return body;
  }
);

export const getRegisterData = createAsyncThunk(
  "getEvent/getRegisterData",

  async (body) => {
    console.log("body", body);
    return body;

    // return response;
  }
);

export const getTiersData = createAsyncThunk(
  "getEvent/getTiersData",

  async (body) => {
    console.log("body", body);
    return body;

    // return response;
  }
);

export const getNavigationAndReceptionData = createAsyncThunk(
  "getEvent/getNavigationAndReceptionData",

  async (body) => {
    console.log("body", body);
    return body;

    // return response;
  }
);

export const getSocialData = createAsyncThunk(
  "getEvent/getSocialData",

  async (body) => {
    console.log("body", body);
    return body;

    // return response;
  }
);

export const createEventSlice = createSlice({
  name: "createEvent",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [createEvent.pending]: (state) => {
      state.loading = true;
    },
    [createEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      console.log("state", state);
      state.loading = false;
      state.data = payload;
    },
    [createEvent.rejected]: (state) => {
      state.loading = false;
    },

    [registerEvent.pending]: (state) => {
      state.loading = true;
    },
    [registerEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.data = payload;
    },
    [registerEvent.rejected]: (state) => {
      state.loading = false;
    },

    [tierEvent.pending]: (state) => {
      state.loading = true;
    },
    [tierEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.data = payload;
    },
    [tierEvent.rejected]: (state) => {
      state.loading = false;
    },

    [navigationAndReceptionEvent.pending]: (state) => {
      state.loading = true;
    },
    [navigationAndReceptionEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.data = payload;
    },
    [navigationAndReceptionEvent.rejected]: (state) => {
      state.loading = false;
    },

    [socialWallEvent.pending]: (state) => {
      state.loading = true;
    },
    [socialWallEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.data = payload;
    },
    [socialWallEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getEventSlice = createSlice({
  name: "getEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [getEventEditorData.pending]: (state) => {
      state.loading = true;
    },
    [getEventEditorData.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      console.log("state", state);
      state.loading = false;
      state.eventList.eventEditorData = payload;
    },
    [getEventEditorData.rejected]: (state) => {
      state.loading = false;
    },

    [getRegisterData.pending]: (state) => {
      state.loading = true;
    },
    [getRegisterData.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      console.log("state", state);
      state.loading = false;
      state.eventList.registerEventData = payload;
    },
    [getRegisterData.rejected]: (state) => {
      state.loading = false;
    },
    [getTiersData.pending]: (state) => {
      state.loading = true;
    },
    [getTiersData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventList.tiersData = payload;
    },
    [getTiersData.rejected]: (state) => {
      state.loading = false;
    },

    [getNavigationAndReceptionData.pending]: (state) => {
      state.loading = true;
    },
    [getNavigationAndReceptionData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventList.navigationData = payload;
    },
    [getNavigationAndReceptionData.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const createEventReducer = createEventSlice.reducer;
export const getEventReducer = getEventSlice.reducer;
