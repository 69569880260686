import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  DatePicker,
  TimePicker,
  Collapse,
  Upload,
  List,
  Modal,
} from "antd";
import "./Zone.css";
import {
  MinusOutlined,
  PlusOutlined,
  CalendarOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  cloudComputing,
  cube,
  cubeWhite,
  edit,
  settings,
  bin,
  editRed,
  calendarRed,
  addGroupRed,
} from "../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import { AddGroup } from "../../components/svgJS/AddGroup";
import { CalenderGrey, CalenderRed } from "../../components/svgJS/CalenderRed";
import { EditRed } from "../../components/svgJS/EditRed";
import { Settings } from "../../components/svgJS/Settings";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";
import { ImgUpload } from "../../components/svgJS/ImgUpload";
import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addZone, fetchEventShow } from "../../redux/slice/zoneManagementSlice";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import FileUpload from "../common/FileUpload";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { isEmpty } from "lodash";
import AddNewSurvey from "../surveyManagement/AddNewSurvey";
import { getUserTypeAgents } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { STATIC_URLS } from "../../components/constsnts/common.constants";

// const  DATE_DIFF = require("date-diff-js");

const CreateNewZone = (props) => {
  console.log("props in create zone", props);
  var CryptoJS = require("crypto-js");
  const validator = useRef(new SimpleReactValidator());
  const [userType, setUserType] = useState("");

  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [surveyList, setsurveyList] = useState(null);

  const [allAgentsList, setAllAgentsList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [zoneHeroImg, setzoneHeroImg] = useState(null);
  const [assetFile, setassetFile] = useState(null);

  const [eventList, seteventList] = useState(null);
  const [brandList, setbrandList] = useState(null);
  const [boothLocationList, setboothLocation] = useState(null);
  // console.log(boothLocationList);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);

  const [socialMediaUrl, setSocialMediaUrl] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [updateAssetValue, setupdateAssetValue] = useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [viewVisible, setViewVisible] = useState(false);
  const [assetDetail, setAssetDetail] = useState();

  const [chatBotFileUpload, setChatBotFileUpload] = useState(null);
  const [preEventId, setPreEventId] = useState(null);
  const [preBrandId, setPreBrandId] = useState(null);

  const [assetsArray, setassetsArray] = useState([
    // {
    //   asset_file_type: "image",
    //   asset_name: "Asset 1",
    //   asset_description:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    //   is_active: true,
    //   is_downloadable: true,
    //   max_no_of_downloads: 3,
    //   total_downloads: 6,
    //   whitelisted_domains: "",
    //   blacklisted_domains: "",
    //   view_count: 9,
    // },
  ]);
  console.log(assetsArray);

  // validation states

  const [chatBotDetails, setchatBotDetails] = useState([
    // {
    //   start_time: "",
    //   end_time: "",
    //   start_date: "",
    //   end_date: "",
    // },
  ]);
  const [initial_state, setinitial_state] = useState({
    event_id: props?.event_id ?? null,
    brand_id: null,
    brand_name: null,
    booth_location_id: null,
    is_active: true,
    zone_title: "",
    zone_description: "",
    website_url: null,
    is_text_chat_enable: false,
    is_message_board_enable: false,
    is_moderated: false,
    is_one_to_one_video_calls_enable: false,
    survey_id: null,
    is_on_booth_leads_enable: false,
    is_video_rep_enable: false,
    is_video_demo_room_enable: false,
    is_text_bot_enable: false,
    is_individual: false,
    zone_agents: selectedAgents,
    zone_chat_bot_setting_details: chatBotDetails,
    zones_social_media_handles_details: [],
    zones_assets_details: assetsArray,
    zone_hero_img: null,
    chatbotUpload: null,
    // chatbotFile: null,
    temp_startDate: null,
    temp_endDate: null,
  });
  // console.log(chatBotDetails);
  // console.log(chatBotDetails);
  const [forceUpdate, setForceUpdate] = useState(false);

  const dispatch = useDispatch();
  const assetCreateUploadRef = useRef();

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        seteventList(rows);
        if (rows.length == 1) {
          setPreEventId(rows[0]?.event_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getBrandsList = (val) => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: val
        ? val
        : props?.event_id
        ? props?.event_id
        : initial_state.event_id,
    })
      .then((response) => {
        console.log("Brand Listing Called Again", response);
        const { rows } = response.data.data;
        setbrandList(rows);
        if (rows.length == 1) {
          setPreBrandId(rows[0].brand_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log("Brand list", brandList);

  const getBoothLocation = () => {
    ApiCall("get", null, "/admin/boothlocations/get", { brand_id: brand_id })
      .then((response) => {
        setboothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { loading } = useSelector((state) => state.zoneManagement);
  const { eventShow } = useSelector((state) => state.zoneManagement);
  console.log(eventShow);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);

  const showSurveyModal = () => {
    setIsSurveyModalVisible(true);
  };

  useEffect(() => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    }

    setUserType(decryptedData);
    console.log("running");
    getEventsList();
    getBoothLocation();
    getAgentsList();
    getSurveyList();
    getSocialMediaList();
    getBrandsList();
    setTimeout(() => {
      setZoneLoading(false);
    }, 2000);
  }, []);

  const [event_id, setEvent_id] = useState(null);
  const [brand_id, setBrand_id] = useState(null);
  const [brandTier, setBrandTier] = useState(null);

  const [hero_thmbnail, sethero_thmbnail] = useState(null);

  useEffect(async () => {
    //To display Agents list depending upon Event and Brand
    if (event_id && brand_id) {
      const agentResponse = await getUserTypeAgents({
        event_id,
        brand_id,
      });
      if (agentResponse) {
        // console.log("check 2", agentResponse);
        setAgents(agentResponse);
      }
      callRestrictedAPI();
    }
    ApiCall("get", null, "/admin/boothlocations/get", { brand_id: brand_id })
      .then((response) => {
        setboothLocation(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [event_id, brand_id]);

  useEffect(() => {
    getBrandsList();
  }, [initial_state.event_id, preEventId]);
  const user_name = localStorage.getItem("user_name");
  var bytes = CryptoJS.AES.decrypt(user_name, "user_name");
  var decryptedName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  console.log("decryptedName", decryptedName);
  useEffect(() => {
    console.log("eveennntt");
    let tempArr = eventShow.map((elem) => ({
      start_time: moment(),
      end_time: moment(),
      start_date: moment(elem.start_date).format("YYYY-MM-DD"),
      end_date: moment(elem.end_date).format("YYYY-MM-DD"),
    }));
    setchatBotDetails(tempArr);
  }, [eventShow]);

  useEffect(() => {
    if (preEventId && preBrandId) {
      setinitial_state({
        ...initial_state,
        event_id: preEventId,
        brand_id: preBrandId,
      });
    } else if (preEventId) {
      setinitial_state({
        ...initial_state,
        event_id: preEventId,
      });
    }
  }, [preEventId, preBrandId]);

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  console.log("initial state", initial_state);
  const getAgentsList = () => {
    console.log("check 1", initial_state?.event_id);
    ApiCall("get", null, "/admin/usertypeagents/get", {
      event_id: initial_state?.event_id ? initial_state.event_id : null,
    })
      .then((response) => {
        setAgents(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSurveyList = () => {
    ApiCall("get", null, "/admin/survey/get", null)
      .then((response) => {
        setsurveyList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log("dddddddddddddd", selectedAgents);
  function onChange(value, dateString) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }

  // const onStartTimeChange = (time) => {
  //   setStartTimeValue(time);
  // };
  // const onEndTimeChange = (time) => {
  //   setEndTimeValue(time);
  // };

  const handleHeroImgChange = (event) => {
    // console.log("img upload console", event.target.files[0]);
    setzoneHeroImg(event.target.files[0]);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBrandLogoFileDelete = () => {
    document.getElementById("heroImglogo").value = null;
    setzoneHeroImg(null);
  };
  const onHandleSocialSwitchChange = (checked, idx) => {
    console.log(idx);
    console.log(checked);

    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      initial_state.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: "",
      });
    } else {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      zones_social_media_handles_details.splice(index, 1);
    }
    setinitial_state({
      ...initial_state,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  function handleAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
    console.log("selectedAgents list12", selectedAgents);
  }

  function handleSelectedAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);

    // console.log("selectedAgents list34", selectedAgents);
  }

  const handleCreateZone = async (data) => {
    console.log(data);
    let data_temp = data;
    delete data_temp.zone_hero_img;
    delete data_temp.temp_startDate;
    delete data_temp.temp_endDate;
    console.log("cchatattta", chatBotDetails);
    const chatbot = chatBotDetails.map((elem, index) => ({
      start_date_time:
        elem.start_date + " " + moment(elem.start_time).format("HH:mm"),
      end_date_time:
        elem.end_date + " " + moment(elem.end_time).format("HH:mm"),
    }));

    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);

    data_temp.zone_chat_bot_setting_details = chatbot;
    data_temp.zone_agents = agentsIdArr;

    console.log("finaaalll oobjj", data_temp);

    let formData = new FormData();

    formData.append("data", JSON.stringify(data_temp));
    formData.append("zone_hero_image_file", zoneHeroImg);
    assetsArray.forEach((elem, index) => {
      formData.append(`asset_${index + 1}`, elem.uploadObj);
    });
    formData.append("chat_bot_qa", chatBotFileUpload);

    const res = await dispatch(addZone(formData));

    console.log(res);

    if (res?.payload?.code === 200) {
      console.log("200 success");
      toast.success(res?.payload?.message);
      if (props.isFromEvent) {
        props.onSuccessFromModal();
      } else {
        props.history.push("zonemanagement");
      }
    }
    if (res?.payload?.code != 200) {
      toast.error(res?.payload?.message);
    }
  };

  const addChatbot = async (eventId) => {
    await dispatch(fetchEventShow({ event_id: eventId }));
  };

  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
    setchatBotDetails([...chatbot_arr]);
  };

  // const addChatBot = () => {
  //   let chatbot_arr = chatBotDetails;
  //   chatbot_arr.push({
  //     start_time: "",
  //     end_time: "",
  //     start_date: "",
  //     end_date: "",
  //   });
  //   setchatBotDetails([...chatbot_arr]);
  // };
  console.log(chatBotDetails);
  const handleDeletChatbot = (index) => {
    console.log(index);
    let tempArr = chatBotDetails;

    tempArr.splice(index, 1);

    setchatBotDetails([...tempArr]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAsset = (data) => {
    // console.log(data);
    // let asset_arr = data;
    // asset_arr.push(data);
    setassetsArray([...assetsArray, data]);
    setIsModalVisible(false);
  };

  const handleAssetsUpdate = (elem, index) => {
    console.log(index);
    console.log(elem);
    const temp = { ...elem, arrIndex: index };
    setupdateAssetValue(temp);
    setIsUpdateModalVisible(true);
  };

  const handleAssetsFile = (e) => {
    console.log(e.target.files[0]);
  };

  const updateAsset = (data) => {
    console.log(data);
    const { tempIndex } = data;
    console.log(tempIndex);
    delete data.tempIndex;
    const arr = [...assetsArray];
    arr[tempIndex] = data;
    setassetsArray(arr);
    setIsUpdateModalVisible(false);
  };

  // const socialMediaHandleChange = (id, event) => {
  //   console.log("id...........", id);
  //   console.log("event......", event.target.value);

  //   var social_media_array = socialMediaUrl;

  //   social_media_array.push({
  //     social_media_id: id,
  //     social_media_url: event.target.value,
  //   });

  //   setSocialMediaUrl([...social_media_array]);

  //   console.log("testing array  social media", social_media_array);
  // };
  const [restricedFields, setRestricedFields] = useState({
    tier_settings_id: null,
    open_demo_rooms: null,
    new_video_rep: null,
    chart_boards: null,
    chat_bots: null,
    on_booth_leads: null,
  });

  const callRestrictedAPI = async () => {
    await ApiCall("get", null, "/admin/zone/restriction/details/get", {
      event_id: event_id,
      brand_tier: brandTier,
    })
      .then((response) => {
        console.log("Restricted Response", response);
        let data = response?.data?.data;
        if (response?.data?.code === 200) {
          setRestricedFields({
            tier_settings_id: data?.tier_settings_id,
            open_demo_rooms: data?.open_demo_rooms,
            new_video_rep: data?.new_video_rep,
            chart_boards: data?.chart_boards,
            chat_bots: data?.chat_bots,
            on_booth_leads: data?.on_booth_leads,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const socialMediaHandleChange = (idx, event) => {
    console.log(event);
    console.log(idx);

    let social_media_list = socialMediaList;
    let zones_social_media_handles_details =
      initial_state.zones_social_media_handles_details;
    let isAvailable = zones_social_media_handles_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = zones_social_media_handles_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      zones_social_media_handles_details[index]["social_media_url"] =
        event.target.value;
    } else {
      zones_social_media_handles_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value,
      });
    }

    setinitial_state({
      ...initial_state,
      zones_social_media_handles_details: zones_social_media_handles_details,
    });
  };

  function getDifferenceInDays(date1, date2) {
    // let  dateDiff = DATE_DIFF(date1, date2, "Days");
    // console.log(dateDiff);
  }

  const DetailComponent = ({ assetData }) => {
    console.log("asset details---->", assetDetail);
    console.log("asset data view---->", assetData);
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          {/* <div className="row border-gray rounded  py-2"> */}
          <div className="row">
            <div className="col-4 text-end">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Max D/L's</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.max_no_of_downloads}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2">
              {/* <Switch defaultChecked={assetData?.is_downloadable} /> */}
            </div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.asset_file_name}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date: {moment(assetData?.uploaded_at).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">Uploaded By: {decryptedName}</span>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  const disabledTime = (current) => {
    const now = current;
    return {
      disabledHours: () => range(0, now.get("hours")),
      disabledMinutes: () => range(0, now.get("minutes") + 3),
    };
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  Yup.addMethod(Yup.string, "fileRequired", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        chatBotFileUpload == null ||
        createError({ path, message: errorMessage })
      );
    });
  });
  const CHATBOT_FORMAT = ["image/png"];

  const ValidationSchema = Yup.object().shape({
    event_id: Yup.number().required("Required").nullable(),
    brand_id: Yup.string().required("Required").nullable(),
    booth_location_id: Yup.string().required("Required").nullable(),
    zone_title: Yup.string().required("Required").nullable(),
    // survey_id: Yup.string().required("Required").nullable(),
    // zone_hero_img: Yup.mixed().required("Required"),
    // zone_agents: Yup.array().length(1, "Required"),
    // chatbotFile: Yup.string()
    //   .test("empty-check", "Required", (val) => val != null)
    //   .test(
    //     "FILE_SIZE",
    //     "size",
    //     (val) => !val || (val && val && val.size <= 1200 * 1200)
    //   )
    // .test(
    //   "FILE_FORMAT",
    //   "FORMAT",
    //   (val) => val && CHATBOT_FORMAT.includes(val?.type)
    // )
    // .nullable(),
    chatbotUpload: Yup.mixed().when("is_text_bot_enable", {
      is: true,
      then: Yup.mixed().required("Required"),
    }),
    temp_startDate: Yup.mixed().when("is_text_bot_enable", {
      is: true,
      then: Yup.mixed().required("Start Date Required"),
    }),
    temp_endDate: Yup.mixed().when("is_text_bot_enable", {
      is: true,
      then: Yup.mixed().required("End Date Required"),
    }),
  });
  // console.log(initial_state.zone_agents);
  if (zoneLoading) {
    return <CommonLoader />;
  } else
    return (
      <>
        <Formik
          initialValues={initial_state}
          enableReinitialize={true}
          isInitialValid={false}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            handleCreateZone(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {zoneLoading ? (
                <CommonLoader />
              ) : (
                <div>
                  <h4 className="fs-24">Create New Zone</h4>

                  {props.isFromEvent ? (
                    ""
                  ) : (
                    <Breadcrumb className="my-2" separator=">">
                      <Breadcrumb.Item>
                        <Link to="/zonemanagement">Admin Console</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/zonemanagement">Zone Management</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/createnewzone">Add Zone</Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  )}
                  <div className="bg-white px-5 py-3">
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Zone Editor
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Active</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_active}
                          name="is_active"
                          onChange={() =>
                            setFieldValue("is_active", !values.is_active)
                          }
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Select Event
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        {/* {console.log("RF", restricedFields)} */}
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          placeholder="Select Event"
                          optionFilterProp="children"
                          className="fs-bold-14"
                          name="event_id"
                          onChange={(val) => {
                            setFieldValue("event_id", val);
                            setEvent_id(val);
                            addChatbot(val);
                            getBrandsList(val);
                            setFieldValue("brand_id", null);
                            setFieldValue("booth_location_id", null);
                          }}
                          listHeight={120}
                          listItemHeight={4}
                          disabled={
                            props?.event_id
                              ? true
                              : userType === "BRAND ADMIN"
                              ? true
                              : false
                          }
                          value={values?.event_id}
                        >
                          {eventList &&
                            eventList.map((data) => (
                              <Option value={data.event_id}>
                                {data.event_name}
                              </Option>
                            ))}
                        </Select>
                        {errors.event_id && touched.event_id ? (
                          <div className="text-danger">{errors.event_id}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Select Brand
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          placeholder="Select Brand"
                          className="fs-bold-14"
                          optionFilterProp="children"
                          name="brand_id"
                          value={values?.brand_id}
                          onChange={(val) => {
                            console.log("brand___change");
                            setFieldValue("brand_id", val);
                            setFieldValue("booth_location_id", null);
                            setFieldValue("survey_id", null);
                            console.log("hasjkhfaf", values.booth_location_id);
                            setBrand_id(val);
                            brandList.forEach((elem) => {
                              if (elem.brand_id === val) {
                                setFieldValue("brand_name", elem.brand_title);
                                setBrandTier(elem?.brand_tier);
                              }
                            });
                          }}
                          listHeight={120}
                          listItemHeight={4}
                          disabled={userType === "BRAND ADMIN" ? true : false}
                        >
                          {brandList &&
                            brandList.map((data) => (
                              <Option value={data.brand_id}>
                                {data.brand_title}
                              </Option>
                            ))}
                        </Select>
                        {errors.brand_id && touched.brand_id ? (
                          <div className="text-danger">{errors.brand_id}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Location on Wall
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-9">
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          placeholder="Select Wall"
                          optionFilterProp="children"
                          className="fs-bold-14"
                          name="booth_location_id"
                          onChange={(val) => {
                            setFieldValue("booth_location_id", val);
                            setFieldValue("survey_id", null);
                          }}
                          listHeight={120}
                          listItemHeight={4}
                          value={values?.booth_location_id}
                        >
                          {boothLocationList &&
                            boothLocationList.map((data) => (
                              <Option value={data.booth_location_id}>
                                {data.booth_location}
                              </Option>
                            ))}
                        </Select>
                        {errors.booth_location_id &&
                        touched.booth_location_id ? (
                          <div className="text-danger">
                            {errors.booth_location_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col-3 text-end">
                        Zone Hero Image
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-2 image-upload">
                        <FileUpload
                          labelClassName="rounded border-pink btn-svg px-3 py-1"
                          labelTitle="Image Upload"
                          inputType="file"
                          // name="zone_hero_img"
                          // inputOnChange={handleHeroImgChange}
                          inputOnChange={(e) => {
                            setFieldValue("zone_hero_img", e.target.files[0]);
                            setzoneHeroImg(e.target.files[0]);
                            sethero_thmbnail(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                          // onBlur={() =>
                          //   validator.current.showMessageFor("zoneHeroImg")
                          // }
                          // value={values.zone_hero_img}
                          inputId="heroImglogoCreate"
                          inputName="zone_hero_img"
                        />

                        {zoneHeroImg ? (
                          <>
                            <span className="mx-3"> {zoneHeroImg.name} </span>
                            <span
                              // onClick={handleBrandLogoFileDelete}
                              onClick={() => {
                                // document.getElementById("heroImglogoCreate").value =
                                //   null;
                                setzoneHeroImg(null);
                                setFieldValue("zone_hero_img", null);
                                sethero_thmbnail(null);
                              }}
                            >
                              <DeleteOutlined />
                            </span>
                            <div>
                              <img
                                src={hero_thmbnail}
                                alt="IMG"
                                className="img-fluid user-img-preview"
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {errors.zone_hero_img && touched.zone_hero_img ? (
                          <div className="text-danger">
                            {errors.zone_hero_img}
                          </div>
                        ) : null}
                        {/* {validator.current.message(
                    "zoneHeroImg",
                    zoneHeroImg,
                    "required",
                    { className: "text-danger py-1" }
                  )} */}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Zone Title
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-4">
                        <Input
                          name="zone_title"
                          value={values.zone_title}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                          onChange={handleChange}
                        />
                        {errors.zone_title && touched.zone_title ? (
                          <div className="text-danger">{errors.zone_title}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Zone Description</div>
                      <div className="col-4">
                        <TextArea
                          rows={4}
                          className="w-100 fs-bold-14"
                          name="zone_description"
                          value={values.zone_description}
                          onChange={handleChange}
                          placeholder="Enter a Description"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Interaction
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Text Chat</div>
                      <div className="col-4">
                        <Switch
                          disabled={values.is_message_board_enable}
                          checked={values.is_text_chat_enable}
                          name="is_text_chat_enable"
                          onChange={() => {
                            if (restricedFields.chart_boards !== 0) {
                              setFieldValue(
                                "is_text_chat_enable",
                                !values.is_text_chat_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row mt-1">
                  <div className="col-3 text-end">
                    <span className="fs-12 fs-pink">OR</span>
                  </div>
                </div> */}
                    {/* <div className="row my-3">
                  <div className="col-3 text-end">Message Board</div>
                  <div className="col-1">
                    <Switch
                      disabled={values.is_text_chat_enable}
                      checked={values.is_message_board_enable}
                      name="is_message_board_enable"
                      onChange={() =>
                        setFieldValue(
                          "is_message_board_enable",
                          !values.is_message_board_enable
                        )
                      }
                    />
                  </div>
                  {values.is_message_board_enable ? (
                    <>
                      <div className="col-1 text-start">Moderated?</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_moderated}
                          name="is_moderated"
                          onChange={() =>
                            setFieldValue("is_moderated", !values.is_moderated)
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div> */}
                    <div className="row my-3">
                      <div className="col-3 text-end">1-1 Video Call</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_one_to_one_video_calls_enable}
                          name="is_one_to_one_video_calls_enable"
                          onChange={() => {
                            if (restricedFields.new_video_rep !== 0) {
                              setFieldValue(
                                "is_one_to_one_video_calls_enable",
                                !values.is_one_to_one_video_calls_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Select Survey</div>
                      <div className="col-4">
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Survey"
                          optionFilterProp="children"
                          value={values?.survey_id}
                          name="survey_id"
                          className="fs-bold-14"
                          onChange={(val) => setFieldValue("survey_id", val)}
                        >
                          {surveyList &&
                            surveyList.map((elem) => (
                              <>
                                <Option value={elem.survey_id}>
                                  {elem.survey_title}
                                </Option>
                              </>
                            ))}
                        </Select>
                        {errors.survey_id && touched.survey_id ? (
                          <div className="text-danger">{errors.survey_id}</div>
                        ) : null}
                      </div>
                      <div className="col-4">
                        {/* <Link to="/addnewsurvey"> */}
                        {/* <Button onClick={showSurveyModal} className="">
                      + Create New Survey
                    </Button> */}
                        {/* </Link> */}
                      </div>
                    </div>
                    <Modal
                      visible={isSurveyModalVisible}
                      // onOk={handleOk}
                      onCancel={() => setIsSurveyModalVisible(false)}
                      footer={null}
                    >
                      <div className="p-2">
                        <AddNewSurvey
                          event_id={values?.event_id}
                          isFromEvent={true}
                          onCancelFromModal={() =>
                            setIsSurveyModalVisible(false)
                          }
                          onSuccessFromModal={async () => {
                            // const surveyData = await getSurveys();
                            // setSurveyList(surveyData);
                            setIsSurveyModalVisible(false);
                          }}
                        />
                      </div>
                    </Modal>
                    <div className="row my-3">
                      <div className="col-3 text-end">On Booth Leads</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_on_booth_leads_enable}
                          name="is_on_booth_leads_enable"
                          onChange={() => {
                            if (restricedFields.on_booth_leads !== 0) {
                              setFieldValue(
                                "is_on_booth_leads_enable",
                                !values.is_on_booth_leads_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="row my-3">
                  <div className="col-3 text-end">Available Video Rep</div>
                  <div className="col-4">
                    <Switch
                      checked={values.is_video_rep_enable}
                      name="is_video_rep_enable"
                      onChange={() =>
                        setFieldValue(
                          "is_video_rep_enable",
                          !values.is_video_rep_enable
                        )
                      }
                    />
                  </div>
                </div> */}
                    <div className="row my-3">
                      <div className="col-3 text-end">Video Demo Room</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_video_demo_room_enable}
                          name="is_video_demo_room_enable"
                          onChange={() => {
                            if (restricedFields.open_demo_rooms !== 0) {
                              setFieldValue(
                                "is_video_demo_room_enable",
                                !values.is_video_demo_room_enable
                              );
                            } else {
                              toast.error("Restricted in Event Tier Settings");
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Chat Bot</div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_text_bot_enable}
                          name="is_text_bot_enable"
                          // onChange={() => {
                          //   if (restricedFields.chat_bots) {
                          //     setFieldValue(
                          //       "is_text_bot_enable",
                          //       !values.is_text_bot_enable
                          //     );
                          //   } else {
                          //     toast.error("Restricted in Event Tier Settings");
                          //   }
                          // }}
                          onChange={() =>
                            setFieldValue(
                              "is_text_bot_enable",
                              !values.is_text_bot_enable
                            )
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="row my-3">
                  <div className="col-3 text-end">Zone Calendar</div>
                  <div className="col-4">
                    <Button className=" btn-svg" icon={<CalenderGrey />}>
                      <span className="mx-2">Open Calendar</span>
                    </Button>
                  </div>
                </div> */}
                    {values.is_text_bot_enable ? (
                      <>
                        <Divider />
                        <div className="row my-3">
                          <div className="col-12 mt-4">
                            <h6 className="font-pink fw-bolder fs-20">
                              Chat Bot Settings
                            </h6>
                          </div>
                        </div>
                        <b>
                          A Chat Bot can be enabled for this product zone for
                          set dates / times
                        </b>
                        <br />
                        <b>
                          1. Select the days you want the chat bot on for from
                          the mini calender on the right
                        </b>
                        <br />
                        <b>
                          2. Select the times that times the chatbot will NOT be
                          running for those days below
                        </b>
                        <div className="row mt-4  pb-4">
                          <div className="col-9 event-date-scroll-div">
                            <Collapse
                              bordered={false}
                              defaultActiveKey={["2"]}
                              expandIcon={({ isActive }) =>
                                (isActive && (
                                  <MinusOutlined
                                    className="rounded minus-logo"
                                    style={{}}
                                  />
                                )) || (
                                  <PlusOutlined className="rounded plus-logo" />
                                )
                              }
                              className="site-collapse-custom-collapse"
                            >
                              {chatBotDetails &&
                                chatBotDetails.map((elem, index) => {
                                  console.log(elem);

                                  var start = moment(
                                    chatBotDetails[index].start_date,
                                    "YYYY-MM-DD"
                                  );
                                  var end = moment(
                                    chatBotDetails[index].end_date,
                                    "YYYY-MM-DD"
                                  );
                                  // console.log(end.diff(start,'days'))
                                  return (
                                    <>
                                      <Panel
                                        header={
                                          <span>
                                            <span className="fw-bold">
                                              {/* {end.diff(start, "days") + " "}
                                              Days Show */}
                                              Shows Days/Times - Block{" "}
                                              {index + 1}
                                            </span>
                                            <span
                                              className="font-pink mx-2
                                    "
                                            >
                                              {"[ " +
                                                moment(
                                                  chatBotDetails[index]
                                                    .start_date
                                                ).format("DD-MM-YYYY") +
                                                " - " +
                                                moment(
                                                  chatBotDetails[index].end_date
                                                ).format("DD-MM-YYYY") +
                                                " , " +
                                                moment(
                                                  chatBotDetails[index]
                                                    .start_time
                                                ).format("HH:mm") +
                                                " - " +
                                                moment(
                                                  chatBotDetails[index].end_time
                                                ).format("HH:mm") +
                                                " ]"}
                                            </span>
                                          </span>
                                        }
                                        key={index}
                                        className="site-collapse-custom-panel"
                                        // extra={
                                        //   <img
                                        //     src={bin}
                                        //     onClick={() => handleDeletChatbot(index)}
                                        //     alt="bin"
                                        //   />
                                        // }
                                      >
                                        {/* <p>{accordianForm}</p> */}
                                        {/* <div className="row">
                                <div className="col-3 text-end">
                                  <label className="text-secondary mt-1">
                                    Start Date
                                  </label>
                                </div>
                                <div className="col-6">
                                  <DatePicker
                                    suffixIcon={<CalenderGrey width="15" />}
                                    format="DD/MM/YYYY"
                                    // showTime
                                    onChange={(val, d) => {
                                      console.log(val);
                                      console.log(
                                        getDifferenceInDays(val, val)
                                      );

                                      handlechatbotArrChange(
                                        d,
                                        index,
                                        "start_date"
                                      );
                                    }}
                                    onOk={onOk}
                                    className="w-75"
                                  />
                                </div>
                              </div> */}

                                        <div className="row mt-4">
                                          <div className="col-3 text-end">
                                            <label className="text-secondary mt-1">
                                              Start & End Time
                                            </label>
                                          </div>
                                          <div className="col-6 d-flex m-0">
                                            <TimePicker
                                              // suffixIcon={
                                              //   <CalenderGrey width="15" />
                                              // }
                                              allowClear={false}
                                              // renderExtraFooter={() => "extra footer"}
                                              showTime
                                              onOk={onOk}
                                              className="w-100"
                                              name="temp_startDate"
                                              value={
                                                chatBotDetails[index].start_time
                                              }
                                              format="HH:mm"
                                              onSelect={(val) => {
                                                console.log("startdate", val);
                                                handlechatbotArrChange(
                                                  val,
                                                  index,
                                                  "start_time"
                                                );
                                                setFieldValue(
                                                  "temp_startDate",
                                                  val
                                                );
                                              }}
                                            />

                                            <TimePicker
                                              // suffixIcon={
                                              //   <CalenderGrey width="15" />
                                              // }
                                              // renderExtraFooter={() => "extra footer"}
                                              allowClear={false}
                                              showTime
                                              onOk={onOk}
                                              className="w-100"
                                              format="HH:mm"
                                              // disabledTime={(d) => {
                                              //   console.log(
                                              //     "endtime",
                                              //     moment(d).format("HH:mm")
                                              //   );
                                              //   if (
                                              //     chatBotDetails[index] &&
                                              //     (!d ||
                                              //       d.isSameOrBefore(
                                              //         chatBotDetails[index]
                                              //           .start_time
                                              //       ))
                                              //   ) {
                                              //     return true;
                                              //   } else {
                                              //     return false;
                                              //   }
                                              // }}
                                              disabledTime={() =>
                                                disabledTime(
                                                  chatBotDetails[index]
                                                    .start_time
                                                )
                                              }
                                              value={
                                                chatBotDetails[index].end_time
                                              }
                                              onSelect={(val) => {
                                                handlechatbotArrChange(
                                                  val,
                                                  index,
                                                  "end_time"
                                                );
                                                setFieldValue(
                                                  "temp_endDate",
                                                  val
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-3 text-end"></div>
                                          <div className="col-3 d-flex m-0">
                                            {errors.temp_startDate &&
                                            touched.temp_startDate ? (
                                              <div className="text-danger">
                                                {errors.temp_startDate}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="col-3 d-flex m-0">
                                            {errors.temp_endDate &&
                                            touched.temp_endDate ? (
                                              <div className="text-danger">
                                                {errors.temp_endDate}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>

                                        {/* <div className="row mt-4">
                                <div className="col-3 text-end">
                                  <label className="text-secondary mt-1">
                                    End Date
                                  </label>
                                </div>
                                <div className="col-6">
                                  <DatePicker
                                    suffixIcon={<CalenderGrey width="15" />}
                                    onChange={(val, d) => {
                                      console.log(d);
                                      handlechatbotArrChange(
                                        d,
                                        index,
                                        "end_date"
                                      );
                                    }}
                                    format="DD/MM/YYYY"
                                    onOk={onOk}
                                    className="w-75"
                                  />
                                </div>
                              </div> */}
                                      </Panel>
                                    </>
                                  );
                                })}
                            </Collapse>
                          </div>
                          {/* <div className="col-3">
                  <Button
                    className="px-2 button-pink rounded"
                    onClick={addChatBot}
                  >
                    + Add Days with different times
                  </Button>
                </div> */}
                        </div>
                        <div className="d-flex">
                          <span className="">Bot Responses</span>
                          <div
                            className="ms-2 text-decoration-underline fs-pink cursor-pointer"
                            onClick={() => {
                              window.location.href =
                                STATIC_URLS.default_chatbot;
                            }}
                          >
                            Click Here To Download Our Template
                          </div>
                        </div>
                        <div className="d-flex mt-4">
                          <span className="align-self-center mx-2">
                            Chat Bot{" "}
                            <sup>
                              <span
                                className="text-danger"
                                style={{ fontSize: "1.1rem" }}
                              >
                                *
                              </span>
                            </sup>
                          </span>
                          <FileUpload
                            labelClassName="rounded border-pink btn-svg  px-3 py-2 ms-3"
                            labelTitle="File Upload"
                            name="chatbotUpload"
                            inputType="file"
                            inputOnChange={(e) => {
                              setFieldValue("chatbotUpload", e.target.files[0]);
                              setChatBotFileUpload(e.target.files[0]);
                            }}
                            accept={true}
                            onChange={(e) => console.log("eeeeeeeee", e)}
                            inputId="chatbot-upload"
                            inputName="chatbotUpload"
                            // value={chatBotFileUpload}
                            // onBlur={() =>
                            //   validator.current.showMessageFor("chatbot-upload")
                            // }
                          />
                          {/* <input
                          style={{ color: "white" }}
                          type="file"
                          id="chatbotFile"
                          name="chatbotUpload"
                          onChange={(e) => {
                            setFieldValue("chatbotUpload", e.target.files[0]);
                            setChatBotFileUpload(e.target.files[0]);
                          }}
                        /> */}
                        </div>
                        <div
                          className="ms-2"
                          style={{ paddingLeft: "65px", paddingTop: "10px" }}
                        >
                          {chatBotFileUpload ? (
                            <>
                              <span className="">
                                {" "}
                                {chatBotFileUpload.name}{" "}
                              </span>
                              <span
                                onClick={() => {
                                  setChatBotFileUpload(null);
                                  setFieldValue("chatbotUpload", null);
                                  // document.getElementById("chatbotFile").value =
                                  //   null;
                                }}
                              >
                                <DeleteOutlined />
                              </span>
                            </>
                          ) : null}
                          {errors.chatbotUpload && touched.chatbotUpload ? (
                            <div className="text-danger">
                              {errors.chatbotUpload}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Externals & Social Links
                        </h6>
                      </div>
                    </div>
                    N8: If you paste your details into these boxes then anyone
                    using site will be able to view/contact you via these
                    platform
                    <div className="social-media-div border-bottom-gray pb-4 mb-4">
                      {socialMediaList &&
                        socialMediaList.map((data, idx) => (
                          <div className="row mt-4 ">
                            <div className="col-2 text-end ">
                              {data?.social_media}
                            </div>
                            <div className="col-1 text-center ">
                              <Switch
                                checked={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(checked) =>
                                  onHandleSocialSwitchChange(checked, idx)
                                }
                              />
                            </div>
                            <div className="col-4 text-start ">
                              <Input
                                placeholder="https://webaddress.com"
                                className="w-100 fs-bold-14"
                                disabled={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? false
                                    : true
                                }
                                value={
                                  initial_state?.zones_social_media_handles_details?.some(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                    ? initial_state
                                        .zones_social_media_handles_details[
                                        initial_state.zones_social_media_handles_details.findIndex(
                                          (social_media) =>
                                            social_media.social_media_id ===
                                            data.social_media_id
                                        )
                                      ]["social_media_url"]
                                    : ""
                                }
                                onChange={(event) =>
                                  socialMediaHandleChange(idx, event)
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor(
                                    "social_media"
                                  )
                                }
                              />
                              {validator.current.message(
                                "social_media",
                                initial_state?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? initial_state
                                      ?.zones_social_media_handles_details[
                                      initial_state?.zones_social_media_handles_details.findIndex(
                                        (social_media) =>
                                          social_media.social_media_id ===
                                          data.social_media_id
                                      )
                                    ]["social_media_url"]
                                  : "",
                                initial_state?.zones_social_media_handles_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? "required|url"
                                  : "",
                                { className: "text-danger py-1" }
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">
                            Assign Agents to this Zone
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4 event-editor-dropdown-div">
                        <div className="col-2 text-end mt-4">
                          <span className="text-secondary mt-4">Agents</span>
                        </div>

                        <div
                          className={Tablet ? "col-5" : "col-4"}
                          id="area"
                          style={{ position: "relative" }}
                        >
                          <label className="py-2 mx-2">
                            <b>All Agents</b>
                          </label>
                          <br />
                          <Select
                            getPopupContainer={(triggerNode) => {
                              return triggerNode.parentNode;
                            }}
                            dropdownAlign={{ offset: [0, 4] }}
                            showSearch
                            dropdownClassName="agent-dropdown"
                            style={{ width: 300 }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            value="All Agents"
                            onChange={handleAgentsChange}
                            open={true}
                            listItemHeight={4}
                            listHeight={130}
                            className="border border-gray fs-bold-14"
                          >
                            {agents ? (
                              agents?.map((data, key) => (
                                <Option value={data?.user_id}>
                                  {data?.first_name} {data?.last_name}
                                </Option>
                              ))
                            ) : (
                              <span disabled>
                                No agents created for this zone
                              </span>
                            )}

                            {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                          </Select>
                          {isEmpty(selectedAgents) &&
                          errors.zone_agents &&
                          touched.zone_agents ? (
                            <div className="text-danger agents-error-margin-top">
                              {errors.zone_agents}
                            </div>
                          ) : null}
                        </div>

                        <div
                          className={Tablet ? "col-5" : "col-4"}
                          id="area1"
                          style={{ position: "relative" }}
                        >
                          <label className="py-2 mx-2">
                            <b>Tagged Agents</b>
                          </label>
                          <br />
                          {/* <div className="event-editor-dropdown-background-gray rounded w-75 p-2"> */}
                          <Select
                            getPopupContainer={() =>
                              document.getElementById("area1")
                            }
                            showSearch
                            dropdownClassName="agent-dropdown"
                            style={{ width: "300px" }}
                            placeholder="Search Agents"
                            optionFilterProp="children"
                            value="Tagged Agents"
                            // onChange={handleAgentsChange}
                            open={true}
                            listItemHeight={4}
                            listHeight={130}
                            className="border border-gray  fs-bold-14"
                          >
                            {selectedAgents?.map((data) => (
                              <Option value={data.user_id}>
                                {data.first_name}+" "+{data.last_name}
                                <span className="float-end">
                                  <CloseOutlined
                                    className="float-right text-danger mr-0"
                                    onClick={() =>
                                      handleSelectedAgentsChange(data.user_id)
                                    }
                                  />
                                </span>
                              </Option>
                            ))}
                            {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                          </Select>

                          {/* <Input className="w-100 my-1" />
                      <List
                        className="event-editor-tagged-agents-div"
                        itemLayout="horizontal"
                        dataSource={selectedAgents}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <span className="d-flex justify-content-between bg-white m-0 p-2 text-danger ">
                                  <span>
                                    {item.first_name} {item.last_name}
                                  </span>
                                  <CloseOutlined
                                    className="float-right"
                                    onClick={() =>
                                      handleSelectedAgentsChange(item.user_id)
                                    }
                                  />
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      /> */}
                          {/* </div> */}
                        </div>
                      </div>

                      <div className="row mt-4 border-bottom-gray pb-4">
                        <div className="col-2"></div>
                        <div className="col-4">
                          <div className="d-flex">
                            {/* <Button className="px-3  rounded btn-svg">
                          <AddGroup fill="#ef0855" />
                          <span className="mx-2">Invite Agents</span>
                        </Button> */}
                            {/* <Button
                          className={
                            "px-3 rounded btn-svg" +
                            (Tablet ? " mt-2" : " mx-2")
                          }
                        >
                          <CalenderGrey />
                          <span className="mx-2">Staffing Calendar</span>
                        </Button> */}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className={Tablet ? "col-12" : "col-2"}>
                          <Button
                            className="px-3 button-pink rounded"
                            onClick={showModal}
                          >
                            + Add New Asset
                          </Button>
                        </div>
                        {assetsArray ? (
                          <div className="col-4 assets-scroll-div">
                            {assetsArray &&
                              assetsArray.map((elem, index) => (
                                <>
                                  <div
                                    className={Tablet ? "mt-4" : " py-2"}
                                    onClick={() => setViewVisible(elem)}
                                  >
                                    <div className="row border-gray rounded py-1">
                                      <div className="col-1 ">
                                        <Cube fill="#000" />
                                      </div>
                                      <div className="col-11">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="fs-18 mx-2">
                                              <b>{elem.asset_name}</b>
                                            </span>
                                          </div>
                                          <div>
                                            {/* <span className="px-3">
                                        <Settings />
                                      </span> */}
                                            <span
                                              className="px-3 "
                                              onClick={() => {
                                                console.log(elem);
                                                handleAssetsUpdate(elem, index);
                                              }}
                                            >
                                              <EditRed fill="#000" />
                                            </span>
                                            <span
                                              className="px-3 "
                                              onClick={async () => {
                                                console.log(index);
                                                let temp_asset = assetsArray;
                                                temp_asset.splice(index, 1);
                                                await setassetsArray(
                                                  temp_asset
                                                );
                                                await setViewVisible(false);
                                              }}
                                            >
                                              <Bin fill="#000" />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <span className="fs-16 mx-2">
                                            {elem.asset_description}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        ) : null}

                        {viewVisible || !assetsArray ? (
                          <DetailComponent assetData={viewVisible} />
                        ) : null}
                      </div>
                    </section>
                  </div>
                  <div
                    className="shadow-sm p-3 d-flex justify-content-between"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    {props.onCancelFromModal ? (
                      <Button
                        onClick={() => props.onCancelFromModal()}
                        className="rounded  px-3"
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button className="px-3 d-block button-pink rounded">
                        Cancel
                      </Button>
                    )}
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        if (validator.current.allValid() && isValid) {
                          console.log("AAAAALLLLLLL VAALLIIDD");
                          setFieldValue("zone_agents", selectedAgents);
                          setFieldValue(
                            "zone_chat_bot_setting_details",
                            chatBotDetails
                          );
                          setFieldValue(
                            "zones_social_media_handles_details",
                            // socialMediaUrl
                            initial_state.zones_social_media_handles_details
                          );
                          const temp_asset = assetsArray.map((elem, index) => ({
                            asset_file_type: elem.asset_file_type,
                            asset_file_name: elem.asset_file_name,
                            asset_name: elem.asset_name,
                            asset_description: elem.asset_description,
                            is_active: elem.is_active,
                            is_downloadable: elem.is_downloadable,
                            max_no_of_downloads: parseInt(
                              elem.max_no_of_downloads
                            ),
                            total_downloads: elem.total_downloads,
                            whitelisted_domains: elem.whitelisted_domains,
                            blacklisted_domains: elem.blacklisted_domains,
                            view_count: elem.view_count,
                          }));
                          setFieldValue("zones_assets_details", temp_asset);
                        } else {
                          validator.current.showMessages();
                          toast.error("Fields are missing");
                          setForceUpdate(!forceUpdate);
                        }
                        // props?.onSuccessFromModal()

                        window.scrollTo(0, 0);
                      }}
                    >
                      {!loading ? (
                        "Create Zone"
                      ) : (
                        <div style={{ width: "70px" }}>
                          <Spin
                            indicator={loadingIcon}
                            style={{ color: "white" }}
                          />
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          )}
        </Formik>

        <Modal
          title="Add New Assets"
          footer={false}
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={() => setIsModalVisible(false)}
          style={{ zIndex: "11111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "image",
                asset_file_name: "",
                asset_name: "",
                asset_description: "",
                is_active: true,
                is_downloadable: true,
                max_no_of_downloads: null,
                total_downloads: 6,
                whitelisted_domains: "",
                blacklisted_domains: "",
                view_count: 9,
                uploadObj: null,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
                uploadObj: Yup.mixed().required("Required"),
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                addAsset(values);
                action.resetForm();
                // console.log(values);
                // setIsModalVisible(false);
                // let asset_arr = assetsArray;
                // asset_arr.push(values);
                // setassetsArray([...assetsArray, values]);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets File</div>
                    <div className="col-7 image-upload">
                      {/* <div>
             <label
               htmlFor="asstesFIle"
               className="rounded border-pink btn-svg px-3 py-1"
             >
               <span>
                 <ImgUpload /> "Asstes"
               </span>
             </label>

             <input
               type="file"
               name="logo"
               id="asstesFIle"
               onChange={(e) => {
                 // console.log(e.target.files[0]);
                 setFieldValue("uploadObj", e.target.files[0]);
                 setFieldValue(
                   "asset_file_name",
                   // e.target.files[0].name
                   "image 15"
                 );
               }}
               style={{ display: "none" }}
             />
           </div> */}
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="Image Upload"
                        inputType="file"
                        inputOnChange={(e) => {
                          console.log(e.target.files[0]);
                          setFieldValue("uploadObj", e.target.files[0]);
                          setFieldValue(
                            "asset_file_name",
                            e.target.files[0].name
                          );
                        }}
                        inputId="pree"
                        inputName="uploadObj"
                        ref={assetCreateUploadRef}
                        //  value={values.uploadObj}
                      />
                      {/* {errors.uploadObj && touched.uploadObj ? (
                      <div className="text-danger">{errors.uploadObj}</div>
                    ) : null} */}
                      {errors.uploadObj &&
                      touched.uploadObj &&
                      !values.uploadObj ? (
                        <div className="text-danger">{errors.uploadObj}</div>
                      ) : null}

                      {values.uploadObj ? (
                        <>
                          <span className="mx-3">{values.uploadObj.name}</span>
                          <span
                            onClick={() => {
                              setFieldValue("uploadObj", null);
                              // document.getElementById("pree").value = null;
                              // assetCreateUploadRef.current.value = "";
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={() =>
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Max No. of download</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="max_no_of_downloads"
                        // value={values.max_no_of_downloads}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        name="whitelisted_domains"
                        // value={values.whitelisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        name="blacklisted_domains"
                        // value={values.blacklisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button onClick={() => setIsModalVisible(false)}>
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() =>
                          // addAsset(values)
                          console.log("object")
                        }
                      >
                        Add Assets
                      </Button>
                    </div>
                  </div>
                  {/* <Button
               htmlType="submit"
               onClick={() =>
                 // addAsset(values)
                 console.log("object")
               }
             >
               Add Assets
             </Button> */}
                </form>
              )}
            </Formik>
          </div>
        </Modal>
        {/* update asset model */}
        <Modal
          title="Update Assets"
          footer={false}
          visible={isUpdateModalVisible}
          // onOk={handleOk}
          onCancel={() => {
            setIsUpdateModalVisible(false);
          }}
          style={{ zIndex: "111111111111111111" }}
        >
          <div className="container px-5">
            <Formik
              initialValues={{
                asset_file_type: "image",
                asset_file_name: "",
                asset_name: updateAssetValue?.asset_name,
                asset_description: updateAssetValue?.asset_description,
                is_active: updateAssetValue?.is_active,
                is_downloadable: updateAssetValue?.is_downloadable,
                max_no_of_downloads: updateAssetValue?.max_no_of_downloads,
                total_downloads: updateAssetValue?.total_downloads,
                whitelisted_domains: updateAssetValue?.whitelisted_domains,
                blacklisted_domains: updateAssetValue?.blacklisted_domains,
                view_count: 9,
                uploadObj: null,
                tempIndex: updateAssetValue?.arrIndex,
              }}
              validationSchema={Yup.object().shape({
                asset_name: Yup.string().required("Please enter name"),
                asset_description: Yup.string().required(
                  "Please enter description"
                ),
                // uploadObj: Yup.mixed().required("Required")
              })}
              enableReinitialize={true}
              onSubmit={(values, action) => {
                updateAsset(values);
                action.resetForm();
                // console.log(values);
                // setIsModalVisible(false);
                // let asset_arr = assetsArray;
                // asset_arr.push(values);
                // setassetsArray([...assetsArray, values]);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets File</div>

                    <div className="col-7 image-upload">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="Image Upload"
                        inputType="file"
                        inputOnChange={(e) => {
                          console.log(e.target.files[0]);
                          setFieldValue("uploadObj", e.target.files[0]);
                          setFieldValue(
                            "asset_file_name",
                            e.target.files[0].name
                          );
                        }}
                        inputId="pree"
                        inputName="pree"
                      />
                      {values.uploadObj ? (
                        <>
                          <span className="mx-3">{values.uploadObj.name}</span>
                          <span
                            onClick={() => {
                              setFieldValue("uploadObj", null);
                            }}
                          >
                            <DeleteOutlined />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Assets Name</div>
                    <div className="col-7">
                      <Input
                        name="asset_name"
                        value={values.asset_name}
                        onChange={handleChange}
                      />
                      {errors.asset_name && touched.asset_name ? (
                        <div className="text-danger">{errors.asset_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">
                      Assets Description
                    </div>
                    <div className="col-7">
                      <TextArea
                        name="asset_description"
                        value={values.asset_description}
                        onChange={handleChange}
                      />
                      {errors.asset_description && touched.asset_description ? (
                        <div className="text-danger">
                          {errors.asset_description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Active</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_active}
                        name="is_active"
                        onChange={() =>
                          setFieldValue("is_active", !values.is_active)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Downloadable</div>
                    <div className="col-7">
                      <Switch
                        checked={values.is_downloadable}
                        name="is_downloadable"
                        onChange={() =>
                          setFieldValue(
                            "is_downloadable",
                            !values.is_downloadable
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5  text-end">Max No. of download</div>
                    <div className="col-7">
                      <Input
                        type="number"
                        name="max_no_of_downloads"
                        value={values.max_no_of_downloads}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Whitelist</div>
                    <div className="col-7">
                      <Input
                        name="whitelisted_domains"
                        value={values.whitelisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-5 pt-2 text-end">Blacklist</div>
                    <div className="col-7">
                      <Input
                        name="blacklisted_domains"
                        value={values.blacklisted_domains}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row py-4 mt-2">
                    <div className="col-6  text-end">
                      <Button
                        // htmlType="submit"
                        onClick={
                          () =>
                            // addAsset(values)
                            // handleCancel()
                            setIsUpdateModalVisible(false)
                          // console.log("object")
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() =>
                          // addAsset(values)
                          console.log("object")
                        }
                      >
                        Update Assets
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      </>
    );
};

export default CreateNewZone;
