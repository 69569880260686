import { ApiCall, UploadFile } from "./api";

export const getSurveys = async (params) => {
  const response = await ApiCall("GET", "", "/admin/survey/get", params);
  if (response?.data?.code === 200) {
    if (response?.data?.data?.rows) {
      return response.data.data.rows;
    }
  } else return [];
};

export const getSocialLinks = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/user/socialmediahandledetails/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getZones = async (params) => {
  const response = await ApiCall("GET", "", "/admin/zones/get", "", params);
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getSurveyList = async () => {
  const response = await ApiCall("GET", "", "/admin/survey/get", "", "");
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getSocialMediaLinks = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/socialmediatype/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getTiers = async () => {
  const response = await ApiCall("GET", "", "/admin/tiers/get", "", "");
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getRelations = async () => {
  const response = await ApiCall("GET", "", "/admin/relations/get", "", "");
  if (response?.data?.code === 200) {
    console.log("relation response ", response.data.data);

    const searchesData = await response.data.data
      .filter((item) => item.part_of === "Searches")
      .map((item) => {
        return item;
      });

    const initiateDataText = await response.data.data
      .filter((item) => item.part_of === "Initiate 1 to 1 Text Chats")
      .map((item) => {
        return item;
      });
    const initiateDataVideo = await response.data.data
      .filter((item) => item.part_of === "Initiate 1 to 1 Video Chats")
      .map((item) => {
        return item;
      });
    const calendarData = await response.data.data
      .filter((item) => item.part_of === "Calendar & Meetings")
      .map((item) => {
        return item;
      });
    const inviteData = await response.data.data
      .filter((item) => item.part_of === "Inviting Delegates")
      .map((item) => {
        return item;
      });

    return {
      searchesData,
      initiateDataText,
      calendarData,
      inviteData,
      initiateDataVideo,
      // ...relationData, tempData: tempData
    };
  } else
    return {
      searchesData: [],
      initiateDataText: [],
      calendarData: [],
      inviteData: [],
      initiateDataVideo: [],
    };
};

export const getAgents = async (params) => {
  const response = await ApiCall("GET", "", "/admin/user/get", params);
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getNavigationSettingsType = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/navigationsettingtype/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getUsers = async (params) => {
  const response = await ApiCall("GET", "", "/admin/user/get", params);
  if (response?.data?.code === 200) {
    console.log("social media response ", response.data.data);
    return response.data.data.rows;
  } else return [];
};

export const getSocialWallModerators = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/events/socialWall/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    console.log("social media response ", response.data.data);
    return response.data.data;
  } else return [];
};

export const getCountryList = async (params) => {
  const response = await ApiCall("get", null, "/admin/country/get", params);
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getJobTitles = async (params) => {
  const response = await ApiCall("get", null, "/admin/jobtitle/get", params);
  if (response?.data?.code === 200) return response.data.data;
  else return [];
};

export const getEvents = async (params = "") => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/events/dropdown/get",
    params
  );
  if (response?.data?.data?.rows) {
    return response.data.data.rows;
  } else return [];
};

export const getAudiSession = async (eventParams) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/auditoriumconfiguration/details/get",
    eventParams
  );
  // console.log("response getAudiSession 11 = ", response);
  if (response?.data?.data?.details) {
    return response.data.data.details;
  } else return {};
};

export const getAudiSessionForView = async (eventParams) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/auditoriumconfiguration/details/get",
    eventParams
  );
  console.log("response getAudiSession for View = ", response);
  return response.data.data;
};

export const getBrands = async (eventParams) => {
  const response = await ApiCall("GET", "", "/admin/brand/get", eventParams);
  if (response?.data?.code === 200) {
    if (response?.data?.data?.rows) {
      // console.log("getBrands response ", response.data.data.rows);
      return response?.data?.data?.rows;
    }
  } else return [];
};

export const getNetworkLoungeSession = async (eventParams) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/networkingsession/details/get",
    eventParams
  );
  console.log("response netlounge 11 = ", response);
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getTickerData = async (eventParams) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/eventticker/details/get",
    eventParams
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return null;
};

export const getTickerPickerList = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/tickerconfiguration/get",
    ""
  );
  if (response?.data?.code === 200) {
    console.log("ticker", response.data.data);
    return response.data.data;
  } else return [];
};

// Api Calls For Role Management
export const getBrandDropdowns = async (event_id) => {
  const response = await ApiCall("get", null, "/admin/brand/dropdown/get", {
    event_id,
  });
  if (response?.data?.data) {
    return response.data.data.rows;
  } else return [];
};

export const getAgentsForUser = async (event_id) => {
  const response = await ApiCall("GET", "", "/admin/usertypeagents/get", {
    event_id: event_id,
  });
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getZoneDropdowns = async (event_id) => {
  const response = await ApiCall("GET", "", "/admin/zone/dropdown/get", {
    event_id,
  });
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getUserTypeAgents = async (params) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/usertypeagents/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getSpeakersForBrand = async (params) => {
  console.log("params = ", params);
  const response = await ApiCall("GET", "", "/admin/brand/speaker/get", params);
  console.log("response in speakers = ", response);
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getSocialWallModerationDetails = async (event_id) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/socialwallmoderation/details/get",
    { event_id: event_id }
  );
  if (response?.data?.code === 200) {
    return response.data;
  } else return [];
};

export const getAudiInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/auditoriumsessioninvitation/details/get",
    params
  );
  console.log("response getInvitationDetails = ", response);
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getNetworkInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/networkingsessioninvitation/details/get",
    params
  );
  console.log("response getNetworkInvitationDetails = ", response);
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export async function sendMessageAPI(channel_id, message_text) {
  const response = await ApiCall(
    "POST",
    { channel_id, message_text },
    "/admin/channel/message/send",
    ""
  );
  console.log("response = ", response);
  return response;
}

export async function videoRequestAPI(channel_id) {
  const response = await ApiCall(
    "POST",
    { channel_id },
    "/admin/channel/videocall/request",
    ""
  );
  console.log("response = ", response);
  return response;
}

export async function sendFile(requestData) {
  const response = await UploadFile(
    requestData.headerParam,
    "POST",
    requestData.chunk,
    "/admin/uploadfile/chunk"
  );
  return response;
}
export const fetchTickerList = async () => {
  const response = await ApiCall("GET", "", "/admin/event/ticker/get", "");
  if (response?.data?.code === 200) {
    console.log("ticker", response.data.data);
    return response.data.data;
  } else return [];
};

export const getZoneDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/zones/details/get",
    params
  );
  console.log("response getZoneDetails = ", response);
  if (response?.data?.data?.details) {
    return response?.data?.data?.details;
  } else return {};
};

export const fetchEventShows = async (params) => {
  const response = await ApiCall("GET", "", "/admin/events/shows/get", params);
  if (response?.data?.code === 200) {
    console.log("fetchEventShows", response.data.data);
    return response.data.data;
  } else return [];
};

export const getChatbotJson = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/zone/chatbot/get",
    params
  );
  console.log("response getChatbotJson = ", response);
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const fetchChatbotHistory = async (params) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/chatbot/conversationhistory/fetch",
    params
  );
  if (response?.data?.code === 200) {
    console.log("fetchChatbotHistory", response.data.data);
    return response.data.data;
  } else return [];
};

export async function saveChatbotHistory(params) {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/channel/message/send",
    ""
  );
  console.log("response = ", response);
  return response;
}
