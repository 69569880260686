export const IMAGE_FILE_BASIC_PROPS = {
  mimeTypeArr: ["image/jpeg", "image/png"],
  fileType: "JPG/PNG",
  maxSize: 5,
};

export const UPLOAD_BASIC_PROPS = {
  imageProps: {
    mimeTypeArr: ["image/jpeg", "image/png"],
    fileType: "JPG/PNG",
    maxSize: 5,
  },
  videoProps: {
    mimeTypeArr: [
      "video/mp4",
      "video/x-flv",
      "video/3gpp",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
    ],
    fileType: "mp4/flv/avi/wmv/3gp/mov",
    maxSize: 500,
  },
};

export const TIME_FORMAT = "HH:mm A";

export const COMETCHAT_CONSTANTS = {
  APP_ID: "21067461f3aeda19",
  REGION: "eu",
  AUTH_KEY: "d7b99dff176a6711c2b62d9ed5b89d06e8dd3c5c",
  // UID: "SUPERHERO1",
};

export const LOGO_DEMO = {
  URL: "https://gravit8-dev-static.s3.amazonaws.com/development/event_logo_demo_size.png",
  FILENAME: "event_logo_demo_size.png",
};

export const STATIC_URLS = {
  default_chatbot:
    "https://gravit8-dev-static.s3.amazonaws.com/development/default+chat+bot.xlsx",
};
