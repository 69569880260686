import React, { useState } from "react";
import "./index.css";
import { Button, Row, Col, Rate } from "antd";

const Feedback = () => {
  const [rating1, setRating1] = useState();
  const [rating2, setRating2] = useState();
  const [rating3, setRating3] = useState();
  const [rating4, setRating4] = useState();

  const customIconsFor1 = {
    1: (
      <div
        className={
          rating1 === 1 ||
          rating1 === 2 ||
          rating1 === 3 ||
          rating1 === 4 ||
          rating1 === 5
            ? "color_1"
            : "emptyColor"
        }
      ></div>
    ),
    2: (
      <div
        className={
          rating1 === 2 || rating1 === 3 || rating1 === 4 || rating1 === 5
            ? "color_2"
            : "emptyColor"
        }
      ></div>
    ),
    3: (
      <div
        className={
          rating1 === 3 || rating1 === 4 || rating1 === 5
            ? "color_3"
            : "emptyColor"
        }
      ></div>
    ),
    4: (
      <div
        className={rating1 === 4 || rating1 === 5 ? "color_4" : "emptyColor"}
      ></div>
    ),
    5: <div className={rating1 === 5 ? "color_5" : "emptyColor"}></div>
  };

  const customIconsFor2 = {
    1: (
      <div
        className={
          rating2 === 1 ||
          rating2 === 2 ||
          rating2 === 3 ||
          rating2 === 4 ||
          rating2 === 5
            ? "color_1"
            : "emptyColor"
        }
      ></div>
    ),
    2: (
      <div
        className={
          rating2 === 2 || rating2 === 3 || rating2 === 4 || rating2 === 5
            ? "color_2"
            : "emptyColor"
        }
      ></div>
    ),
    3: (
      <div
        className={
          rating2 === 3 || rating2 === 4 || rating2 === 5
            ? "color_3"
            : "emptyColor"
        }
      ></div>
    ),
    4: (
      <div
        className={rating2 === 4 || rating2 === 5 ? "color_4" : "emptyColor"}
      ></div>
    ),
    5: <div className={rating2 === 5 ? "color_5" : "emptyColor"}></div>
  };

  const customIconsFor3 = {
    1: (
      <div
        className={
          rating3 === 1 ||
          rating3 === 2 ||
          rating3 === 3 ||
          rating3 === 4 ||
          rating3 === 5
            ? "color_1"
            : "emptyColor"
        }
      ></div>
    ),
    2: (
      <div
        className={
          rating3 === 2 || rating3 === 3 || rating3 === 4 || rating3 === 5
            ? "color_2"
            : "emptyColor"
        }
      ></div>
    ),
    3: (
      <div
        className={
          rating3 === 3 || rating3 === 4 || rating3 === 5
            ? "color_3"
            : "emptyColor"
        }
      ></div>
    ),
    4: (
      <div
        className={rating3 === 4 || rating3 === 5 ? "color_4" : "emptyColor"}
      ></div>
    ),
    5: <div className={rating3 === 5 ? "color_5" : "emptyColor"}></div>
  };

  const customIconsFor4 = {
    1: (
      <div
        className={
          rating4 === 1 ||
          rating4 === 2 ||
          rating4 === 3 ||
          rating4 === 4 ||
          rating4 === 5
            ? "color_1"
            : "emptyColor"
        }
      ></div>
    ),
    2: (
      <div
        className={
          rating4 === 2 || rating4 === 3 || rating4 === 4 || rating4 === 5
            ? "color_2"
            : "emptyColor"
        }
      ></div>
    ),
    3: (
      <div
        className={
          rating4 === 3 || rating4 === 4 || rating4 === 5
            ? "color_3"
            : "emptyColor"
        }
      ></div>
    ),
    4: (
      <div
        className={rating4 === 4 || rating4 === 5 ? "color_4" : "emptyColor"}
      ></div>
    ),
    5: <div className={rating4 === 5 ? "color_5" : "emptyColor"}></div>
  };

  return (
    <>
      <h5 className="mb-0">Feedback</h5>
      <span className="opacity-75">feedback</span>
      <div className="feedback_container p-4 mt-3 w-100 bg-white">
        <h6 className="mx-5 mb-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </h6>

        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className="">
              How easy was this Virtual Event System to use?
            </span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2">Hard</span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center"
              >
                <div>
                  <Rate
                    className="ant_rate"
                    onChange={val => setRating1(val)}
                    character={({ index }) => customIconsFor1[index + 1]}
                  />
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2">Easy</span>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* start of numbers row */}
        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className=""></span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2"></span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center "
              >
                <div className="feedback_numbers text-center">
                  <span>1</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>2</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>3</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>4</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>5</span>
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2"></span>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* End of numbers row */}

        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className="">
              How likely is it that you will use this platform again for next
              years event?
            </span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2">Likely</span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center"
              >
                <div>
                  <Rate
                    className="ant_rate"
                    onChange={val => setRating2(val)}
                    character={({ index }) => customIconsFor2[index + 1]}
                  />
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2">Not Likely</span>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* start of numbers row */}
        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className=""></span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2"></span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center "
              >
                <div className="feedback_numbers text-center">
                  <span>1</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>2</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>3</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>4</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>5</span>
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2"></span>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* End of numbers row */}

        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className="">How would you rate this event?</span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2">Not Good</span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center"
              >
                <div>
                  <Rate
                    className="ant_rate"
                    onChange={val => setRating3(val)}
                    character={({ index }) => customIconsFor3[index + 1]}
                  />
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2">Great</span>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* start of numbers row */}
        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className=""></span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2"></span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center "
              >
                <div className="feedback_numbers text-center">
                  <span>1</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>2</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>3</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>4</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>5</span>
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2"></span>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* End of numbers row */}

        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className="">How would you rate this VEP?</span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2">Not Good</span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center"
              >
                <div>
                  <Rate
                    className="ant_rate"
                    onChange={val => setRating4(val)}
                    character={({ index }) => customIconsFor4[index + 1]}
                  />
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2">Great</span>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* start of numbers row */}
        <Row className="mt-2">
          <Col span={10} className="d-flex justify-content-end">
            <span className=""></span>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end">
                <span className="mx-2"></span>
              </Col>
              <Col
                span={9}
                xl={9}
                xs={17}
                className="d-flex justify-content-center "
              >
                <div className="feedback_numbers text-center">
                  <span>1</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>2</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>3</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>4</span>
                </div>
                <div className="feedback_numbers text-center">
                  <span>5</span>
                </div>
              </Col>
              <Col span={3}>
                <span className="ms-2"></span>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* End of numbers row */}

        {/* Submit Btn */}
        <Row className="mt-3">
          <Col span={10} className="d-flex justify-content-end"></Col>
          <Col span={12}>
            <Row>
              <Col span={4} className="text-end"></Col>
              <Col span={9} className="d-flex justify-content-center"></Col>
              <Col span={3}>
                <Button className="feedback_submitBtn">Submit</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Feedback;
