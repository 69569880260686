import React, { useState, useEffect, useRef } from "react";
import "./Brands.css";
import { Row, Col, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { chat, calendarBlack } from "../../components/Images/Images";
import {
  VideoCameraOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Menu, Dropdown, Empty, Input, Modal } from "antd";
import { Sort } from "../../components/svgJS/Sort";
import { Booth } from "../../components/svgJS/Booth";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../utils/commonApis";
import { toast } from "react-toastify";

import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";

const { Option } = Select;

const Brands = ({ setBrandObj, history }) => {
  let CryptoJS = require("crypto-js");
  let localData_event_id = parseInt(localStorage.getItem("event_id"));
  const { Option } = Select;
  const [eventList, setEventList] = useState([]);
  const { TextArea } = Input;
  const [userType, setUserType] = useState();
  const [brandList, setBrandList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    event_id: null,
    brand_id: null,
    brand_tier: null,
    search: null,
  });
  const [disableEvent, setDisableEvent] = useState(false);
  const [showA_Z, setShowA_Z] = useState(false);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: ""
  });
  const [forceUpdate, setForceUpdate] = useState(false);
  const messageValidator = useRef(new SimpleReactValidator());

  let hideEventAndBrandDropdown = false;

  useEffect(() => {
    console.log("window.location.pathname", window.location.pathname)
    if (window.location.pathname === '/brand') {
      hideEventAndBrandDropdown = true
    }
    console.log("hideEventAndBrandDropdown", hideEventAndBrandDropdown)

    getEventList();
    getTierList();
    getBrandViewDetailsOnLoad()

    let chat_uid = localStorage.getItem("chat_uid");

    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        response => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid()
            });
          }
        },
        error => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, []);

  useEffect(() => {
    // getBrandViewDetailsOnLoad()
    getBrandViewDetails();
    getBrandList();
  }, [fields?.event_id, fields?.brand_id, fields?.search]);

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", "")
      .then(response => {
        const { rows } = response?.data?.data;

        const ciphertext = localStorage.getItem("user_type");
        let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
        console.log(bytes);
        let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserType(user_type)

        if (
          user_type === "DELEGATE" ||
          user_type === "SPEAKER" ||
          user_type === "BRAND ADMIN"
        ) {
          const events = rows.filter(item => {
            return item.event_id === localData_event_id;
          });

          setEventList(events);
          let id = events[0].event_id;
          setFields({
            ...fields,
            event_id: id
          });
          setDisableEvent(true);
        } else {
          setEventList(rows);
          if (rows.length === 1) {
            setFields({ ...fields, event_id: rows[0].event_id });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  };


  const getBrandViewDetailsOnLoad = async () => {
    setLoading(true);

    await ApiCall("get", null, "/admin/brandview/details/get", {
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      brand_tier: fields?.brand_tier,
      // sort_type: "brand_title",
    })
      .then(response => {
        const { rows } = response?.data?.data;
        setBrandData(rows);

      })
      .catch(err => {
        console.error(err);
      });
    setLoading(false);
  };

  const getBrandViewDetails = async () => {
    setLoading(true);
    await ApiCall("get", null, "/admin/brandview/details/get", {
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      brand_tier: fields?.brand_tier,
      sort_type: "brand_title",
      sort_by: showA_Z ? "DSC" : "ASC",
      search: fields?.search
    })
      .then(response => {
        const { rows } = response?.data?.data;
        setBrandData(rows);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getBrandList = () => {
    // setLoading(true);
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id
    })
      .then(response => {
        const { rows } = response?.data?.data;
        if (rows.length == 1) {
          setFields({ ...fields, brand_id: rows[0].brand_id })
        }
        setBrandList(rows);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getTierList = () => {
    // setLoading(true);
    ApiCall("get", null, "/admin/tiers/get", null)
      .then(response => {
        const data = response?.data?.data;
        setTierList(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleFieldsChange = (val, name) => {


    if (name == "event_id") {
      setFields({ ...fields, [name]: val, brand_id: null });
    }
    else {
      setFields({ ...fields, [name]: val });
    }


    getBrandViewDetails();
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  // Sorting Logic

  const menu = (
    <Menu>
      {showA_Z == true ? (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => {
            setShowA_Z(false);
            // handleAscendingSort();
            getBrandViewDetails();
          }}
        >
          <span>A-Z</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => {
            setShowA_Z(true);
            getBrandViewDetails();
          }}
        >
          <span>Z-A</span>
        </Menu.Item>
      )}
    </Menu>
  );

  console.log("hideEventAndBrandDropdown", hideEventAndBrandDropdown)
  console.log("fields", fields)
  return (
    <>
      <div className="brands_header">
        <Row className="h-100 d-flex">
          {window.location.pathname === "/brand" ?
            <Col className="d-flex mt-2 flex-column px-3" span={12}>
              <Input
                className="w-100 d-flex py-1 rounded mt-4 brands_search_bar"
                // onChange={ }
                allowClear
                onChange={val => handleFieldsChange(val.target.value, "search")}
                placeholder="Search By Brand Name, Zone Name, Agent Name"
              />
            </Col>
            :
            <>
              {userType && userType === "SUPER ADMIN" || userType === "CUSTOMER" ?
                <Col className="d-flex mt-2 flex-column px-3" span={6}>
                  <span className="pb-1">Select Event</span>
                  <div className="d-flex position-relative w-100">
                    <Select
                      showSearch
                      placeholder="Select Event"
                      name="event_id"
                      allowClear
                      disabled={disableEvent}
                      className="brands_event_search"
                      value={fields.event_id}
                      onChange={val => handleFieldsChange(val, "event_id")}
                      optionFilterProp="children"
                      listHeight={120}
                      listItemHeight={4}
                      bordered={false}
                    >
                      {eventList &&
                        eventList?.map(data => {
                          return (
                            <Option value={data.event_id}>{data.event_name}</Option>
                          );
                        })}
                    </Select>
                  </div>
                </Col>
                :
                ""
              }

              <Col className="d-flex flex-column align-items-center px-3" span={6}>
                <span className="pb-1 align-self-start mt-2">Select Brand</span>
                <div className="d-flex position-relative w-100">
                  <SearchOutlined className="brands_searchicon" />
                  <Select
                    showSearch
                    allowClear
                    className="brands_input_search"
                    placeholder="Search Exibitors"
                    optionFilterProp="children"
                    value={fields?.brand_id}
                    onChange={val => {
                      handleFieldsChange(val, "brand_id");
                      // getSpecificBrandDetails(val);
                    }}
                    bordered={false}
                    disabled={brandList && brandList.length < 2 ? true : false}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {brandList &&
                      brandList?.map(data => {
                        return (
                          <Option value={data.brand_id}>{data.brand_title}</Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
            </>
          }
          <Col
            className="d-flex  align-items-center justify-content-end"
            span={12}
          >
            <Select
              showSearch
              allowClear
              className="brands_tier_search"
              placeholder="Filter By Tier"
              optionFilterProp="children"
              onChange={val => {
                handleFieldsChange(val, "brand_tier");
                // handleTierFilter(val);
              }}
              bordered={false}
              listHeight={120}
              listItemHeight={4}
            >
              {tierList &&
                tierList?.map(data => {
                  return <Option value={data.tier_id}>{data.tier}</Option>;
                })}
            </Select>

            <Dropdown overlay={menu} placement="bottomCenter" arrow>
              {showA_Z ? (
                <Button
                  className="brands_header_btns me-3"
                  onClick={() => {
                    setShowA_Z(false);
                    // handleAscendingSort();
                    getBrandViewDetails();
                  }}
                >
                  <Sort /> <span>A-Z</span>
                </Button>
              ) : (
                <Button
                  className="brands_header_btns me-3"
                  onClick={() => {
                    setShowA_Z(true);
                    // handleAscendingSort();
                    getBrandViewDetails();
                  }}
                >
                  <Sort /> <span>Z-A</span>
                </Button>
              )}
            </Dropdown>
          </Col>
        </Row>
      </div>

      <div className="brands_cards_container">
        {/* Card Start */}

        {loading ? (
          <CommonLoader />
        ) : brandData && brandData?.length === 0 ? (
          <Empty className="mx-auto mt-5 align-self-center" />
        ) : (
          brandData &&
          brandData?.map(data => {
            // console.log("data", data);
            return (
              <div
                className="brands_card"
                onClick={() => {
                  if (setBrandObj) {
                    setBrandObj({
                      event_id: data.event_id ?? null,
                      brand_id: data.brand_id ?? null,
                      show: data.event_id && data?.brand_id ? true : false
                    });
                  }

                }}
              >
                <Link
                  to={{
                    pathname: `/expomap/brandzonedetails/${data.brand_id}`,
                    state: { event_id: data.event_id, brandData: data }
                  }}
                >
                  <div className="brands_card_header">
                    <div className="brands_card_img_container">
                      <img src={data.logo_url} className="brands_card_img" />
                    </div>
                    <div className="brands_card_header_content">
                      <h5 className="brands_card_header_title">
                        {data.brand_title}
                      </h5>
                      <span className="brands_card_header_sub_title ">
                        {data.brand_tier_name}
                      </span>
                      <div className="brands_card_header_booth">
                        <Button className="brands_booth_btn">
                          <Booth />
                        </Button>

                        <span className="fs-pink fs-14 mt-1">Visit Booth</span>
                      </div>
                      <div className="brands_card_header_icon_container">
                        <div
                          onClick={e => {
                            setMessageModal({
                              isOpen: true,
                              channel_id: 4
                            });
                          }}
                          className="brands_card_header_icons_bg"
                        >
                          <img className="brands_card_header_icon" src={chat} />
                        </div>

                        {messageModal.isOpen ? (
                          <Modal
                            title={<b>Send Message</b>}
                            visible={messageModal.isOpen}
                            onOk={() => clearMessageAndModal()}
                            onCancel={() => clearMessageAndModal()}
                            footer={[
                              <Button
                                className="px-3 button-pink rounded"
                                key="cancel"
                                onClick={() => clearMessageAndModal()}
                              >
                                Cancel
                              </Button>,
                              <Button
                                className="px-3 rounded"
                                key="save"
                                type="primary"
                                onClick={() =>
                                  callSendMessageAPI(messageModal.channel_id)
                                }
                              >
                                Send
                              </Button>
                            ]}
                            className="message_modal"
                          >
                            <Row className="ticker_configuration_row mt-2">
                              <Col
                                span={6}
                                className="ticker_configuration_label"
                              >
                                <p>
                                  Message
                                  <RedAsterick />
                                </p>
                              </Col>
                              <Col span={12}>
                                <TextArea
                                  name="message_text"
                                  id="message_text"
                                  className="ticker_configuration_inputDescription"
                                  placeholder="Enter a message"
                                  rows={4}
                                  value={message_text}
                                  onChange={e =>
                                    set_message_text(e.target.value)
                                  }
                                  onBlur={() =>
                                    messageValidator.current.showMessageFor(
                                      "message_text"
                                    )
                                  }
                                />
                                {messageValidator.current.message(
                                  "Message",
                                  message_text,
                                  "required",
                                  { className: "text-danger py-1" }
                                )}
                              </Col>
                            </Row>
                          </Modal>
                        ) : null}

                        {/* <Link
                        to={""}
                      >
                        <div className="brands_card_header_icons_bg text-dark">
                          <VideoCameraOutlined className="brands_card_header_icon" />
                        </div>
                      </Link> */}
                        <Link
                          to={`/brandCalendar/${data.event_id}/${data.brand_id}`}
                        >
                          <div className="brands_card_header_icons_bg">
                            <img
                              className="brands_card_header_icon"
                              src={calendarBlack}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="brands_card_body">
                    <p className="mb-0  text-dark fs-bold-16">
                      {data.brand_description}
                    </p>
                  </div>
                </Link>
                <div
                  onClick={e => e.stopPropagation()}
                  className="brands_card_footer_links w-100 text-dark"
                >
                  {/* {console.log("Brands data", data)} */}
                  {data?.brands_social_media_handles_id?.map(item => {
                    let { brandsSocialMediaHandles_social_media_id } = item;
                    let {
                      social_media
                    } = brandsSocialMediaHandles_social_media_id;
                    let url = "";
                    if (
                      item?.social_media_url.startsWith("http://") ||
                      item?.social_media_url.startsWith("https://")
                    ) {
                      url = item?.social_media_url;
                    } else {
                      url = "https://" + item.social_media_url;
                    }

                    if (social_media === "Instagram") {
                      return (
                        <div
                          className="brand_social_icons"
                          onClick={e => {
                            window.open(url, "_blank");
                          }}
                        >
                          <InstagramOutlined />
                        </div>
                      );
                    } else if (social_media === "LinkedIn") {
                      return (
                        <div onClick={() => window.open(url, "_blank")}>
                          <LinkedinOutlined />
                        </div>
                      );
                    } else if (social_media === "Twitter") {
                      return (
                        <div onClick={() => window.open(url, "_blank")}>
                          <TwitterOutlined />
                        </div>
                      );
                    } else if (social_media === "Facebook" || social_media === "facebook") {
                      return (
                        <div onClick={() => window.open(url, "_blank")}>
                          <FacebookOutlined />
                        </div>
                      );
                    }
                  })}
                  {/* <PublicIcon fontSize="small" />
                   */}
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default Brands;
