import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Select, Input, Button, Switch, Checkbox } from "antd";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";
import { PadLock } from "../../components/svgJS/PadLock";
import { addUser } from "../../redux/slice/userManagementSlice";
import { ApiCall } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../common/FileUpload";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./index.css";
import SimpleReactValidator from "simple-react-validator";
import { getCountryList, getJobTitles } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";

const AddUser = props => {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const validator = useRef(new SimpleReactValidator());

  // validation states
  const [showError, setshowError] = useState(false);
  const [isError, setisError] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  const [userType, setUserType] = useState("");
  const [loadingUser, setLoadingUser] = useState(false);
  // Add User States
  const [eventList, setEventList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [profilePicture, setProfilePicture] = useState();
  const [showNewEmail, setshowNewEmail] = useState(false);
  const [zoneList, setZoneList] = useState([]);
  const [filteredZone, setFilteredZone] = useState([]);
  const [allowOtherCountry, setAllowOtherCountry] = useState(false);
  const [allowOtherJobTitle, setAllowOtherJobTitle] = useState(false);
  const [sideMenuData, setSideMenuData] = useState(false);
  const [fields, setFields] = useState({
    is_active: true,
    event_id: props?.event_id ?? null,
    first_name: "",
    last_name: "",
    email: "",
    new_email: "",
    company: "",
    job_title_id: null,
    job_title: "",
    country_id: null,
    country: "",
    user_type: props?.event_id ? "AGENT" : null,
    tier_type_id: null,
    bio: "",
    is_admin: false, //switch
    is_speaker: false, //switch
    brand_id: props?.brand_id ?? null,
    website_url: "dummy.com",
    zone_tag_details: [],
    external_social_links_details: []
  });

  const { loading } = useSelector(state => state?.userManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setFilteredZone(
      zoneList?.filter(data => data?.brand_id === fields?.brand_id)
    );

    if (fields?.brand_id && props?.event_id === null) {
      getTierList();
    }
  }, [fields.brand_id]);

  useEffect(() => {

    setLoadingUser(true)
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }

    window.scrollTo(0, 0);
    getEventList();
    getUserTypeList();
    getSocialMediaList();
    getZoneList();
    getBrandList();
    getTierList();
    getEventDetails(props?.event_id ? props?.event_id : fields?.event_id);

    setTimeout(() => {
      setLoadingUser(false);
    }, 2000);
  }, []);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);

  const getEventDetails = async event_id => {
    const responseEvent = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get`,
      {
        event_id: event_id
      }
    );

    if (responseEvent?.data?.data?.registration_and_communication_details) {
      let countryFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.country_collection;

      setAllowOtherCountry(responseEvent.data.data.registration_and_communication_details.allow_other_country)

      if (countryFlag) {
        if (countryList) {
          let countryIds =
            responseEvent?.data?.data?.registration_and_communication_details
              ?.country_collection_ids;

          let result = countryList.filter(o1 =>
            countryIds.some(o2 => o1?.country_id === o2)
          );
          setSelectedCountries(result);
        }
      }

      let jobFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.job_title_collection;

      setAllowOtherJobTitle(responseEvent.data.data.registration_and_communication_details.allow_other_job_title)

      if (jobFlag) {
        if (jobTitle) {
          let jobTitleIds =
            responseEvent?.data?.data?.registration_and_communication_details
              ?.job_title_collection_ids;

          let result2 = jobTitle.filter(o1 =>
            jobTitleIds.some(o2 => o1?.job_title_id === o2)
          );
          setSelectedJobTitles(result2);
        }
      }
    }
  };

  useEffect(() => {
    if (fields?.event_id) {
      getBrandList();
      getEventDetails(fields?.event_id);
    }
  }, [fields?.event_id, countryList, jobTitle]);


  useEffect(async () => {
    const countryListAPI = await getCountryList();
    setCountryList(countryListAPI)

    const jobTitlesListAPI = await getJobTitles();
    setJobTitle(jobTitlesListAPI)
  }, [])

  const handleFormSubmit = async e => {

    e.preventDefault();
    window.scrollTo(0, 0);

    const data =
      filteredZone &&
      filteredZone?.map(res => {
        return {
          zone_id: res.zone_id,
          is_assigned: res.is_assigned,
          is_always_get_messages: res.is_always_get_messages
        };
      });

    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ ...fields, zone_tag_details: data })
    );
    formData.append("profile_picture", profilePicture);
    console.log("validator in user", validator.current);

    if (validator.current.allValid()) {
      // post api call

      // if()
      const addNewUserResp = await dispatch(addUser(formData));

      if (addNewUserResp.payload.data.code === 200) {
        toast.success(addNewUserResp.payload.data.message);
        setshowError(false);
        props.history.push("/usermanagement");
      } else if (addNewUserResp.payload.data.code === 403) {
        toast.error(addNewUserResp.payload.data.message);
        setshowError(false);
      } else {
        toast.error(addNewUserResp.payload.data.message);
        setshowError(true);
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }
  };

  const handleFieldsChange = (val, name) => {

    if (name == "event_id") {
      setFields({ ...fields, [name]: val, brand_id: null, zone_tag_details: [] });
      setFilteredZone([])
    }
    else {
      setFields({ ...fields, [name]: val });
    }

  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = fields.external_social_links_details;
    let isAvailable = external_social_links_details.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      external_social_links_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: ""
      });
    } else {
      let index = external_social_links_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      external_social_links_details.splice(index, 1);
    }
    setFields({
      ...fields,
      external_social_links_details: external_social_links_details
    });
  };

  const socialMediaHandleChange = (idx, event) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = fields.external_social_links_details;
    let isAvailable = external_social_links_details.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = external_social_links_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      external_social_links_details[index]["social_media_url"] =
        event.target.value;
    } else {
      external_social_links_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value
      });
    }

    setFields({
      ...fields,
      external_social_links_details: external_social_links_details
    });
  };

  const handleImgChange = event => {
    setProfilePicture(event.target.files[0]);
  };

  // Dropdown api calls

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then(response => {
        const { rows } = response.data.data;
        setEventList(rows);
        if (rows.length == 1) {
          setFields({ ...fields, event_id: rows[0]?.event_id });
        }
        if (userType == "BRAND ADMIN") {
          setFields({ ...fields, user_type: "AGENT" });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getUserTypeList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then(response => {
        const { rows } = response.data.data;
        setUserTypeList(rows);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : ""
    })
      .then(response => {
        const { rows } = response.data.data;
        setBrandList(rows);

        if (rows.length == 1) {
          setFields({ ...fields, brand_id: rows[0].brand_id });
        }
        if (userType == "BRAND ADMIN") {
          setFields({ ...fields, user_type: "AGENT" });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", {
      brand_id: fields?.brand_id ? fields?.brand_id : brandList[0]?.brand_id
    })
      .then(response => {
        const { data } = response.data;
        setTierList(data);

        if (data.length == 1) {
          setFields({ ...fields, tier_type_id: data[0]?.tier_id });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then(response => {
        setSocialMediaList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then(response => {
        let constantData = response.data.data.rows.map(response => {
          return {
            is_assigned: false,
            is_always_get_messages: false,
            ...response
          };
        });
        return constantData;
      })

      .then(data => {
        setZoneList(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleFileDelete = () => {
    setProfilePicture(null);
  };

  const handleCheckBox1Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_assigned = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  const handleCheckBox2Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_always_get_messages = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  if (loadingUser) {
    return <CommonLoader />;
  } else
    return (
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <div>
          {props?.event_id ? "" : <>
            <h4 className="fs-24">Add User</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>
                <Link to="/usermanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/usermanagement">User Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/adduser">Add User</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </>
          }
          <div className="bg-white px-5 py-3">
            <div className="row my-3">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">User Editor</h6>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">Active</div>
              <div className="col-1">
                <Switch
                  name="is_active"
                  checked={fields.is_active}
                  onChange={checked => handleFieldsChange(checked, "is_active")}
                />
              </div>
            </div>
            <div className="row my-3 mt-4">
              <div className="col-3 text-end">
                Select Event
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>

              <div className="col-9">
                <Select
                  showSearch
                  className="w-25 fs-bold-14"
                  placeholder="Select Event"
                  optionFilterProp="children"
                  name="event_id"
                  value={fields.event_id ? fields.event_id : null}
                  onChange={val => handleFieldsChange(val, "event_id")}
                  onBlur={() => validator.current.showMessageFor("event_id")}
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                  disabled={userType === "BRAND ADMIN" ? true : props?.event_id ? true : false}
                >
                  {eventList &&
                    eventList?.map(data => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>
                {validator.current.message(
                  "event",
                  fields.event_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                First Name
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>
              <div className="col-9">
                <Input
                  name="first_name"
                  id="first_name"
                  className="w-25 border-gray rounded fs-bold-14"
                  placeholder="Enter First Name"
                  value={fields.first_name}
                  onChange={e => handleFieldsChange(e.target.value, "first_name")}
                  onBlur={() => validator.current.showMessageFor("first_name")}
                  autoComplete="none"
                />
                {validator.current.message(
                  "First Name",
                  fields.first_name,
                  "required|first_name",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Surname
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>
              <div className="col-9">
                <Input
                  name="last_name"
                  id="last_name"
                  className="w-25 border-gray rounded fs-bold-14"
                  placeholder="Enter Surname"
                  value={fields.last_name}
                  onChange={e => handleFieldsChange(e.target.value, "last_name")}
                  onBlur={() => validator.current.showMessageFor("last_name")}
                  autoComplete="none"
                />
                {validator.current.message(
                  "Last Name",
                  fields.last_name,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Email Address
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>
              <div className="col-9">
                <Input
                  name="email"
                  id="email"
                  className="w-25 border-gray rounded fs-bold-14"
                  placeholder="Enter Email"
                  value={fields.email}
                  onChange={e => handleFieldsChange(e.target.value, "email")}
                  onBlur={() => validator.current.showMessageFor("email")}
                  autoComplete="none"
                />
                {validator.current.message(
                  "email",
                  fields.email,
                  "required|email",
                  { className: "text-danger py-2" }
                )}
              </div>
            </div>
            {showNewEmail && (
              <div className="row my-3">
                <div className="col-3 text-end">
                  New Email Address
                  <sup>
                    <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                      *
                    </span>
                  </sup>
                </div>
                <div className="col-9">
                  <Input
                    name="new_email"
                    value={fields.new_email}
                    onChange={e =>
                      handleFieldsChange(e.target.value, "new_email")
                    }
                    className="w-25 border-gray rounded fs-bold-14"
                    placeholder="Enter New Email"
                    onBlur={() => validator.current.showMessageFor("new_email")}
                    autoComplete="none"
                    disabled
                  />
                  {validator.current.message(
                    "new_email",
                    fields.new_email,
                    "required|email",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-3 text-end">
                Profile Picture
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>
              <div className="col-9 d-flex prof_user_btn">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  "
                  labelTitle="Image Upload"
                  name="profilePicture"
                  inputType="file"
                  inputOnChange={handleImgChange}
                  value=""
                  inputId="add_user_logo"
                  inputName="add_user_logo"
                  onBlur={() =>
                    validator.current.showMessageFor("profilePicture")
                  }
                />
                {profilePicture ? (
                  <>
                    <span className="mx-3"> {profilePicture.name} </span>
                    <span onClick={handleFileDelete}>
                      {" "}
                      <DeleteOutlined />{" "}
                    </span>
                  </>
                ) : (
                  ""
                )}

                <br />
                <br />
              </div>
            </div>
            <div className="row my-0 py-0">
              <div className="col-3"></div>
              <div className="col-9">
                {validator.current.message(
                  "profilePicture",
                  profilePicture,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>


            <div className="row my-3">
              <div className="col-3 text-end">
                Job Role
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>

              </div>
              <div className="col-9">
                <Select
                  showSearch
                  className="w-25 fs-bold-14"
                  placeholder="Select Job Role"
                  optionFilterProp="children"
                  name="job_title_id"
                  value={fields?.job_title_id}
                  onChange={val => handleFieldsChange(val, "job_title_id")}
                  onBlur={() => validator.current.showMessageFor("job_title_id")}
                  // autoComplete="off"
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                >
                  {selectedJobTitles &&
                    selectedJobTitles?.map(data => {
                      return (
                        <Option value={data.job_title_id}>
                          {data.job_title}
                        </Option>
                      );
                    })}
                  {allowOtherJobTitle &&
                    <Option value="Other">Other</Option>
                  }
                </Select>
                {validator.current.message(
                  "Job",
                  fields?.job_title_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
              <div className="col-3"></div>
              {fields?.job_title_id === "Other" &&
                <div className="col-9 mt-2">
                  <Input
                    name="job_title"
                    id="job_title"
                    className="w-25 border-gray rounded fs-bold-14"
                    placeholder="Enter job_title "
                    value={fields?.job_title}
                    onChange={e => handleFieldsChange(e.target.value, "job_title")}
                    autoComplete="none"
                  />
                </div>
              }
            </div>

            <div className="row my-3">
              <div className="col-3 text-end">
                Country
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>
              <div className="col-9">
                <Select
                  showSearch
                  className="w-25 fs-bold-14"
                  placeholder="Select Country"
                  optionFilterProp="children"
                  name="country_id"
                  value={fields.country_id}
                  onChange={val => handleFieldsChange(val, "country_id")}
                  onBlur={() => validator.current.showMessageFor("country_id")}
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                >
                  {selectedCountries &&
                    selectedCountries?.map(data => {
                      return (
                        <Option value={data?.country_id}>{data?.country}</Option>
                      );
                    })}
                  {allowOtherCountry &&
                    <Option value="Other">Other</Option>
                  }
                </Select>
                {validator.current.message(
                  "country",
                  fields.country_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
              <div className="col-3"></div>
              {fields?.country_id === "Other" &&
                <div className="col-9 mt-2">
                  <Input
                    name="country"
                    id="country"
                    className="w-25 border-gray rounded fs-bold-14"
                    placeholder="Enter Country Name"
                    value={fields.country}
                    onChange={e => handleFieldsChange(e.target.value, "country")}
                    autoComplete="none"
                  />
                </div>
              }
            </div>

            <div className="row my-3">
              <div className="col-3 text-end">
                User Type
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>

              <div className="col-9">
                <Select
                  showSearch
                  className="w-25 fs-bold-14"
                  placeholder="Select User"
                  optionFilterProp="children"
                  name="user_type"
                  value={fields.user_type}
                  onChange={val => handleFieldsChange(val, "user_type")}
                  autoComplete="none"
                  disabled={userType === "BRAND ADMIN" ? true : props?.event_id ? true : false}
                >
                  <Option value="AGENT">Agent</Option>
                  <Option
                    value="DELEGATE"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Delegate
                  </Option>
                  <Option
                    value="SPEAKER"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Speaker
                  </Option>
                </Select>
                {validator.current.message(
                  "User Type",
                  fields.user_type,
                  "required|text",
                  { className: "text-danger py-1" }
                )}

              </div>
            </div>
            {fields.user_type === "AGENT" && (
              <>
                <div className="row my-3">
                  <div className="col-3 text-end">Admin</div>
                  <div className="col-7">
                    <Switch
                      name="is_admin"
                      checked={fields.is_admin}
                      onChange={checked =>
                        handleFieldsChange(checked, "is_admin")
                      }
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Brand
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  {userType && userType === "BRAND ADMIN" ? (
                    <div className="col-9">
                      <Input className="w-25" value={brandList[0]?.brand_title} />
                    </div>
                  ) : (
                    <div className="col-9">
                      <Select
                        showSearch
                        className="w-25 fs-bold-14"
                        placeholder="Select Brand"
                        optionFilterProp="children"
                        name="brand_id"
                        value={fields.brand_id}
                        onChange={val => handleFieldsChange(val, "brand_id")}
                        onBlur={() =>
                          validator.current.showMessageFor("brand_id")
                        }
                        autoComplete="none"
                        listHeight={120}
                        listItemHeight={4}
                        disabled={
                          userType && userType === "BRAND ADMIN" ? true : props?.brand_id ? true : false
                        }
                      >
                        {brandList &&
                          brandList?.map(data => {
                            return (
                              <Option value={data.brand_id}>
                                {data.brand_title}
                              </Option>
                            );
                          })}
                      </Select>
                      {fields.user_type === "Agent" &&
                        validator.current.message(
                          "brand",
                          fields.brand_id
                            ? fields.brand_id
                            : brandList[0].brand_id,
                          "required|text",
                          { className: "text-danger py-1" }
                        )}
                      {brandList && brandList.length === 0 && (
                        <div className="d-block mt-2 fs-14 text-danger">
                          <span>
                            Opps, No brands is available for above selected
                            "Event"{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row my-3">
                  <div className="col-3 text-end">Zone Tags</div>
                  <div className="col-8 border-bottom zone-tag-scroll-div">
                    <div className="row border border-gray rounded py-1 sticky-top bg-white">
                      <div className="col-4">
                        <b>Zones</b>
                      </div>
                      <div className="col-4">
                        {" "}
                        <b>Assign</b>
                      </div>
                      <div className="col-4">
                        <b>Always get Msgs</b>
                      </div>
                    </div>

                    {filteredZone &&
                      filteredZone?.map((data, idx) => (
                        <div className="row border-start border-end">
                          <div className="col-4">
                            <div className="my-3">{data.zone_title}</div>
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className="mx-3"
                              name="assign"
                              onChange={e => handleCheckBox1Change(e, data, idx)}
                            />
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              disabled={filteredZone[idx].is_assigned === true ? false : true}
                              className="mx-3"
                              name="alwaysGetMags"
                              onChange={e => handleCheckBox2Change(e, data, idx)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            {fields?.user_type === "DELEGATE" || fields?.user_type === "SPEAKER" ?
              <div className="row my-3">
                <div className="col-3 text-end">
                  Company
                  <sup>
                    <span className="text-danger"
                      style={{ fontSize: "1.1rem" }}>
                      *
                    </span>
                  </sup>
                </div>
                <div className="col-9">
                  <Input
                    name="company"
                    id="company"
                    className="w-25 border-gray rounded fs-bold-14"
                    placeholder="Enter Company Name"
                    value={fields.company}
                    onChange={e => handleFieldsChange(e.target.value, "company")}
                    onBlur={() => validator.current.showMessageFor("company")}
                    autoComplete="none"
                  />
                  {validator.current.message(
                    "Company",
                    fields.company,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>
              : ""
            }
            <div className="row my-3">
              <div className="col-3 text-end">
                Tier
                <sup>
                  <span className="text-danger" style={{ fontSize: "1.1rem" }}>
                    *
                  </span>
                </sup>
              </div>

              {/* {tierList && tierList.length === 1 ? (
                <div className="col-9">
                  <Input className="w-25" value={tierList[0]?.tier} />
                </div>
              ) : ( */}
              <div className="col-9">
                <Select
                  showSearch
                  name="tier_type_id"
                  className="w-25 fs-bold-14"
                  placeholder="Select Tier"
                  optionFilterProp="children"
                  value={fields.tier_type_id}
                  onChange={val => handleFieldsChange(val, "tier_type_id")}
                  onBlur={() =>
                    validator.current.showMessageFor("tier_type_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                  autoComplete="none"
                  disabled={
                    userType && userType === "BRAND ADMIN" ? true : false
                  }
                >
                  {tierList &&
                    tierList?.map(data => {
                      return <Option value={data.tier_id}>{data.tier}</Option>;
                    })}
                </Select>
                {validator.current.message(
                  "tiers",
                  fields.tier_type_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
              {/* )} */}
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Bio
              </div>
              <div className="col-9">
                <TextArea
                  rows={6}
                  className="w-50"
                  name="bio"
                  placeholder="Enter a Bio here"
                  value={fields.bio}
                  onChange={e => handleFieldsChange(e.target.value, "bio")}
                  onBlur={() => validator.current.showMessageFor("bio")}
                  autoComplete="none"
                />
              </div>
            </div>
            <div className="row border-bottom-gray pb-4">
              <div className="col-3"></div>
              <div className="col-9">
                <Button
                  className={
                    "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                  }
                >
                  <CalenderGrey /> <span className="mx-2">View Calendar</span>
                </Button>
                <Button disabled={true} className="px-3  rounded btn-svg">
                  <PadLock /> <span className="mx-2">Reset Password</span>
                </Button>
                <br />
                <br />
                <span className="">
                  NB: This will send an email to the users current email address
                  to create a new password.
                </span>
              </div>
            </div>
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    External & Social Links
                  </h6>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-12 mt-1">
                  <span className="fs-16">
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </span>
                </div>
              </div>

              <div className="social-media-div border-bottom-gray pb-4 mb-4">
                {socialMediaList &&
                  socialMediaList.map((data, idx) => (
                    <div className="row mt-4 ">
                      <div className="col-2 text-end ">{data?.social_media}</div>
                      <div className="col-1 text-center ">
                        <Switch
                          checked={
                            fields?.external_social_links_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? true
                              : false
                          }
                          onChange={checked =>
                            onHandleSocialSwitchChange(checked, idx)
                          }
                        />
                      </div>
                      <div className="col-4 text-start ">
                        <Input
                          placeholder="https://webaddress.com"
                          name={data?.social_media}
                          className="w-100 fs-bold-14"
                          disabled={
                            fields?.external_social_links_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? false
                              : true
                          }
                          value={
                            fields?.external_social_links_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? fields.external_social_links_details[
                              fields.external_social_links_details.findIndex(
                                social_media =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                              ]["social_media_url"]
                              : ""
                          }
                          onChange={event => socialMediaHandleChange(idx, event)}
                          onBlur={() => validator.current.showMessageFor("social_media")}
                        />
                        {validator.current.message(
                          "social_media",
                          fields?.external_social_links_details?.some(
                            social_media =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? fields?.external_social_links_details[
                            fields.external_social_links_details.findIndex(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                            ]["social_media_url"]
                            : ""
                          ,
                          fields?.external_social_links_details?.some(
                            social_media =>
                              social_media.social_media_id ===
                              data.social_media_id
                          ) ? "required|url" : "",
                          { className: "text-danger py-1" }
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>
          </div>
          <div
            className="shadow-sm p-3 d-flex justify-content-between"
            style={{ backgroundColor: "#eeeeee" }}
          >
            {props?.onCancelFromModal ?
              <Button className="px-3 d-block button-pink rounded" onClick={() => props?.onCancelFromModal()}>
                Cancel
              </Button>
              :
              <Link to="/usermanagement">
                <Button className="px-3 d-block button-pink rounded">Cancel</Button>
              </Link>
            }


            {props?.onSuccessFromModal ?
              <Button type="primary" onClick={() => props?.onSuccessFromModal(), handleFormSubmit}>
                {!loading ? (
                  "Send Invitation"
                ) : (
                  <div style={{ width: "80px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button> :
              <Button type="primary" htmlType="submit">
                {!loading ? (
                  "Add User"
                ) : (
                  <div style={{ width: "60px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button>
            }
          </div>
        </div>
      </form >
    );
};

export default AddUser;
