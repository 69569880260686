import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  TimePicker,
  Collapse,
  Modal,
} from "antd";
import "./Zone.css";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { AddGroup } from "../../components/svgJS/AddGroup";
import { CalenderGrey, CalenderRed } from "../../components/svgJS/CalenderRed";
import { EditRed } from "../../components/svgJS/EditRed";
import { Settings } from "../../components/svgJS/Settings";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";
import { ImgUpload } from "../../components/svgJS/ImgUpload";
import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addZone, fetchEventShow } from "../../redux/slice/zoneManagementSlice";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { getUserTypeAgents } from "../../utils/commonApis";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash-es";
import CommonLoader from "../../components/Widgets/CommonLoader";

const ViewZone = ({ match }) => {
  var CryptoJS = require("crypto-js");
  const [surveyList, setsurveyList] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentList, setagentList] = useState([]);
  const [zoneHeroImg, setzoneHeroImg] = useState(null);
  const [fetchedZoneData, setfetchedZoneData] = useState(null);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [socialMediaUrl, setSocialMediaUrl] = useState([]);
  const [viewVisible, setViewVisible] = useState(false);
  const [assetsArray, setassetsArray] = useState([]);
  const [chatBotDetails, setchatBotDetails] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const dispatch = useDispatch();
  const { eventShow } = useSelector((state) => state.zoneManagement);
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const { loading } = useSelector((state) => state.zoneManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    }
    getAgentsList();
    getSurveyList();
    getSocialMediaList();

    setTimeout(() => {
      setZoneLoading(false);
    }, 2000);
  }, []);

  useEffect(async () => {
    let tempArr = await eventShow.map((elem) => ({
      start_time: elem.start_time,
      end_time: elem.end_time,
      start_day: moment(elem.start_date).format("DD-MM-YYYY"),
      end_day: moment(elem.end_date).format("DD-MM-YYYY"),
    }));
    setchatBotDetails(tempArr);
  }, [eventShow]);

  useEffect(async () => {
    await getZoneDetail();
    getSocialMediaList();
    const agentListAPI = await getUserTypeAgents({
      event_id: (fetchedZoneData && fetchedZoneData?.event_id) || "",
    });
    setAgentsCollectionData(agentListAPI);
  }, [fetchedZoneData?.event_id]);

  useEffect(() => {
    if (agentsCollectionData) {
      setAgentsFromAPIAndUseEffect(fetchedZoneData);
    }
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    let zones_agents = [];
    dataHere &&
      dataHere.zones_agents &&
      dataHere.zones_agents.map((val) => {
        zones_agents.push(val.user_id);
      });
    if (dataHere && !isEmpty(dataHere.zones_agents) && !isEmpty(zones_agents)) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      zones_agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let agents_list = clonedAgents;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = uniqWith(selectedAgents_list, isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else setAgents(agentsCollectionData);
  };
  useEffect(() => {
    getZoneDetail();
  }, []);

  const getZoneDetail = async () => {
    await ApiCall("get", null, "/admin/zones/details/get", {
      zone_id: match.params.zoneid,
    })
      .then((response) => {
        const { details } = response.data.data;
        setfetchedZoneData(details);
        setSocialMediaList(details.zones_social_media_handles_id);
        setagentList(details?.zones_agents);
        setassetsArray(details?.zones_assets_id);
        addChatbot(details?.event_id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  var initial_state = {
    zone_hero_image_url:
      fetchedZoneData && fetchedZoneData?.zone_hero_image_url,
    event_id: fetchedZoneData && fetchedZoneData?.zone_event_name,
    brand_id: fetchedZoneData && fetchedZoneData?.zone_brand_name,
    booth_location_id:
      fetchedZoneData && fetchedZoneData.booth_location.booth_location,
    is_active: fetchedZoneData && fetchedZoneData.is_active,
    zone_title: fetchedZoneData && fetchedZoneData.zone_title,
    zone_description: fetchedZoneData && fetchedZoneData.zone_description,
    website_url: fetchedZoneData && fetchedZoneData.website_url,
    is_text_chat_enable: fetchedZoneData && fetchedZoneData.is_text_chat_enable,
    is_message_board_enable:
      fetchedZoneData && fetchedZoneData.is_message_board_enable,
    is_moderated: fetchedZoneData && fetchedZoneData.is_moderated,
    is_one_to_one_video_calls_enable:
      fetchedZoneData && fetchedZoneData.is_one_to_one_video_calls_enable,
    survey_id: fetchedZoneData && fetchedZoneData.zones_survey_id.survey_title,
    is_on_booth_leads_enable:
      fetchedZoneData && fetchedZoneData.is_on_booth_leads_enable,
    is_video_rep_enable: fetchedZoneData && fetchedZoneData.is_video_rep_enable,
    is_video_demo_room_enable:
      fetchedZoneData && fetchedZoneData.is_video_demo_room_enable,
    is_text_bot_enable: fetchedZoneData && fetchedZoneData.is_text_bot_enable,
    is_individual: fetchedZoneData && fetchedZoneData.is_individual,
    zone_agents: selectedAgents,
    zone_chat_bot_setting_details: chatBotDetails,
    zones_social_media_handles_details: socialMediaUrl,
    zones_assets_details: assetsArray,
  };

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then((response) => {
        setAgents(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSurveyList = () => {
    ApiCall("get", null, "/admin/survey/get", null)
      .then((response) => {
        setsurveyList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        // setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCreateZone = async (data) => {
    let data_temp = data;
    const chatbot = chatBotDetails.map((elem, index) => ({
      start_date_time: elem.start_day + " " + elem.start_time,
      end_date_time: elem.end_day + " " + elem.end_time,
    }));

    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);
    data_temp.zone_chat_bot_setting_details = chatbot;
    data_temp.zone_agents = agentsIdArr;
    let formData = new FormData();
    formData.append("data", JSON.stringify(data_temp));
    formData.append("zone_hero_image_file", zoneHeroImg);
    assetsArray.forEach((elem, index) => {
      formData.append(`asset_${index + 1}`, elem.uploadObj);
    });

    const res = await dispatch(addZone(formData));
    if (res.payload.code === 200) {
      // props.history.push("customermanagement");
    }
    if (res.payload.code != 200) {
      toast.error(res.payload.message);
    }
  };

  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
    // setchatBotDetails([...chatbot_arr]);
  };
  const addChatbot = async (eventId) => {
    await dispatch(fetchEventShow({ event_id: eventId }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addAsset = (data) => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DetailComponent = ({ assetData }) => {
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          <div className="row">
            <div className="col-4 text-end">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Max D/L's</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.max_no_of_downloads}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2"></div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date: {moment(assetData?.uploaded_at).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Uploaded By: {assetData?.uploaded_by}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const preSelectedAgents =
    agentList &&
    agentList?.map((data) => {
      return data;
    });

  const selectedAgentNames =
    preSelectedAgents !== null &&
    preSelectedAgents?.map((data) => {
      return (
        data.zonesAgents_user_id.first_name + data.zonesAgents_user_id.last_name
      );
    });
  if (zoneLoading) {
    return <CommonLoader />;
  } else
    return (
      <Formik
        initialValues={initial_state}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleCreateZone(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="view-all-common">
              <div>
                <h4 className="fs-24">View Zone</h4>
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/zonemanagement">Admin Console</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/zonemanagement">Zone Management</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/createnewzone">View Zone</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="bg-white px-5 py-3">
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">Zone Editor</h6>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Active</div>
                    <div className="col-4">
                      <Switch checked={values.is_active} name="is_active" />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Zone Hero Image</div>
                    <div className="col-4">
                      <img src={values.zone_hero_image_url} height={100} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Event</div>
                    <div className="col-4">
                      <Input
                        disabled
                        name="zone_title"
                        value={values.event_id}
                        className="w-100 border-gray rounded fs-bold-14"
                        placeholder="Enter a Title Here"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Brand</div>
                    <div className="col-4">
                      <Input
                        disabled
                        name="zone_title"
                        value={values.brand_id}
                        className="w-100 border-gray rounded fs-bold-14"
                        placeholder="Enter a Title Here"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Location on Wall</div>
                    <div className="col-4">
                      <Input
                        disabled
                        name="zone_title"
                        value={values.booth_location_id}
                        className="w-100 border-gray rounded fs-bold-14"
                        placeholder="Enter a Title Here"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Zone Title</div>
                    <div className="col-4">
                      <Input
                        disabled
                        name="zone_title"
                        value={values.zone_title}
                        className="w-100 border-gray rounded fs-bold-14"
                        placeholder="Enter a Title Here"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Zone Description</div>
                    <div className="col-4">
                      <TextArea
                        disabled
                        rows={4}
                        className="w-100 fs-bold-14"
                        name="zone_description"
                        value={values.zone_description}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">Interaction</h6>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Text Chat</div>
                    <div className="col-4">
                      <Switch
                        checked={values.is_text_chat_enable}
                        name="is_text_chat_enable"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">1-1 Video Call</div>
                    <div className="col-4">
                      <Switch
                        checked={values.is_one_to_one_video_calls_enable}
                        name="is_one_to_one_video_calls_enable"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Select Survey</div>
                    <div className="col-4">
                      <Input value={values.survey_id} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">On Booth Leads</div>
                    <div className="col-4">
                      <Switch
                        checked={values.is_on_booth_leads_enable}
                        name="is_on_booth_leads_enable"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Video Demo Room</div>
                    <div className="col-4">
                      <Switch
                        checked={values.is_video_demo_room_enable}
                        name="is_video_demo_room_enable"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">Text Bot</div>
                    <div className="col-4">
                      <Switch
                        checked={values.is_text_bot_enable}
                        name="is_text_bot_enable"
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        Chat Bot Settings
                      </h6>
                    </div>
                  </div>
                  <b>
                    A Chat Bot can be enabled for this product zone for set
                    dates / times
                  </b>
                  <br />
                  <b>
                    1. Select the days you want the chat bot on for from the
                    mini calender on the right
                  </b>
                  <br />
                  <b>
                    2. Select the times that times the chatbot will NOT be
                    running for those days below
                  </b>
                  <div className="row mt-4 border-bottom-gray pb-4">
                    <div className="col-9 event-date-scroll-div">
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) =>
                          (isActive && (
                            <MinusOutlined
                              className="rounded minus-logo"
                              style={{}}
                            />
                          )) || <PlusOutlined className="rounded plus-logo" />
                        }
                        className="site-collapse-custom-collapse"
                      >
                        {chatBotDetails &&
                          chatBotDetails.map((elem, index) => {
                            var start = moment(
                              chatBotDetails[index].start_day,
                              "DD-MM-YYYY"
                            );
                            var end = moment(
                              chatBotDetails[index].end_day,
                              "DD-MM-YYYY"
                            );

                            return (
                              <>
                                <Panel
                                  header={
                                    <span>
                                      <span className="fw-bold">
                                        {/* {end.diff(start, "days") + " "} Day Show */}
                                        Shows Days/Times - Block {index + 1}
                                      </span>
                                      <span
                                        className="font-pink mx-2
                                  "
                                      >
                                        {"[ " +
                                          chatBotDetails[index].start_day +
                                          " - " +
                                          chatBotDetails[index].end_day +
                                          " , " +
                                          chatBotDetails[index].start_time +
                                          "-" +
                                          chatBotDetails[index].end_time +
                                          " ]"}
                                      </span>
                                    </span>
                                  }
                                  key={index}
                                  className="site-collapse-custom-panel"
                                >
                                  <div className="row mt-4">
                                    <div className="col-3 text-end">
                                      <label className="text-secondary mt-1">
                                        Start & End Time
                                      </label>
                                    </div>
                                    <div className="col-6 d-flex m-0">
                                      <TimePicker
                                        suffixIcon={<CalenderGrey width="15" />}
                                        renderExtraFooter={() => "extra footer"}
                                        showTime
                                        value={moment(elem.start_time, "h:mm")}
                                        disabled
                                        className="w-100"
                                        onSelect={(val) =>
                                          handlechatbotArrChange(
                                            moment(val).format("hh:mm"),
                                            index,
                                            "start_time"
                                          )
                                        }
                                      />
                                      <TimePicker
                                        suffixIcon={<CalenderGrey width="15" />}
                                        renderExtraFooter={() => "extra footer"}
                                        showTime
                                        disabled
                                        value={moment(elem.end_time, "h:mm")}
                                        className="w-100"
                                        onChange={(val) =>
                                          handlechatbotArrChange(
                                            moment(val).format("hh:mm"),
                                            index,
                                            "end_time"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Panel>
                              </>
                            );
                          })}
                      </Collapse>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <Divider />
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        Externals & Social Links
                      </h6>
                    </div>
                  </div>
                  N8: If you paste your details into these boxes then anyone
                  using site will be able to view/contact you via these platform
                  <div className="social-media-div border-bottom-gray pb-4 mb-4">
                    {socialMediaList &&
                      socialMediaList.map((data) => (
                        <div className="row mt-4 ">
                          <div className="col-2 text-end ">
                            {
                              data?.zonesSocialMediaHandles_social_media_id
                                ?.social_media
                            }
                          </div>
                          <div className="col-1 text-center ">
                            <Switch checked={true} />
                          </div>
                          <div className="col-4 text-start ">
                            <Input
                              disabled
                              placeholder="https://webaddress.com"
                              className="w-100 fs-bold-14"
                              value={data?.social_media_url}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                  <section>
                    <div className="row mt-4">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Assign Agents to this Zone
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4 event-editor-dropdown-div">
                      <div className="col-2 text-end mt-4">
                        <span className="text-secondary mt-4">Agents</span>
                      </div>

                      <div
                        className={Tablet ? "col-5" : "col-4"}
                        id="area"
                        style={{ position: "relative" }}
                      >
                        <label className="py-2 mx-2">
                          <b>All Agents</b>
                        </label>
                        <br />
                        <Select
                          getPopupContainer={() =>
                            document.getElementById("area")
                          }
                          showSearch
                          style={{ width: 300 }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          value="All Agents"
                          open={true}
                          listItemHeight={4}
                          listHeight={130}
                          className="border border-gray fs-bold-14"
                        >
                          {agents?.map((data) => (
                            <Option value={data.user_id}>
                              {data.first_name} {data.last_name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className={Tablet ? "col-5" : "col-4"}>
                        <label className="py-2 mx-2">
                          <b>Tagged Agents</b>
                        </label>
                        <br />
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: 300 }}
                          placeholder="Search Tagged Agents"
                          open={true}
                          value="Tagged Agents"
                          listItemHeight={4}
                          listHeight={130}
                          className="border fs-bold-14 border-gray"
                          getPopupContainer={() =>
                            document.getElementById("area")
                          }
                        >
                          {selectedAgentNames?.map((data) => (
                            <Option value={data}>{data}</Option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="row mt-4 border-bottom-gray pb-4">
                      <div className="col-2"></div>
                      <div className="col-4">
                        <Button className="px-3 fs-pink border-pink rounded btn-svg">
                          <AddGroup fill="#ef0855" />{" "}
                          <span className="mx-2">Invite Agents</span>
                        </Button>
                        <Button
                          className={
                            "px-3 fs-pink border-pink rounded btn-svg" +
                            (Tablet ? " mt-2" : " mx-2")
                          }
                        >
                          <CalenderRed />
                          <span className="mx-2">Staffing Calendar</span>
                        </Button>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="row mt-4">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className={Tablet ? "col-12" : "col-2"}>
                        <Button
                          className="px-3 button-pink rounded"
                          onClick={showModal}
                        >
                          + Add New Asset
                        </Button>
                      </div>
                      {assetsArray ? (
                        <div className="col-4 assets-scroll-div">
                          {assetsArray &&
                            assetsArray.map((elem) => (
                              <>
                                <div
                                  className={Tablet ? "mt-4" : " py-2"}
                                  onClick={() => setViewVisible(elem)}
                                >
                                  <div className="row border-gray rounded py-1">
                                    <div className="col-1 navigation-reception-cube">
                                      <Cube fill="#ef0855" />
                                    </div>
                                    <div className="col-11">
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <span className="fs-18 mx-2">
                                            <b>{elem.asset_name}</b>
                                          </span>
                                        </div>
                                        <div>
                                          <span className="px-3">
                                            <EditRed />
                                          </span>
                                          <span className="px-3">
                                            <Settings />
                                          </span>
                                          <span className="px-3">
                                            <Bin />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <span className="fs-16 mx-2">
                                          {elem.asset_description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      ) : null}

                      {viewVisible ? (
                        <DetailComponent assetData={viewVisible} />
                      ) : null}
                      <Modal
                        title="Add New Assets"
                        footer={false}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        style={{ zIndex: "111111111111111111" }}
                      >
                        <div className="container px-5">
                          <Formik
                            initialValues={{
                              asset_file_type: "image",
                              asset_file_name: "",
                              asset_name: "",
                              asset_description: "",
                              is_active: true,
                              is_downloadable: true,
                              max_no_of_downloads: 3,
                              total_downloads: 6,
                              whitelisted_domains: "",
                              blacklisted_domains: "",
                              view_count: 9,
                              uploadObj: null,
                            }}
                            enableReinitialize={true}
                            onSubmit={(values, action) => {
                              addAsset(values);
                              action.resetForm();
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="row mt-2">
                                  <div className="col-5 pt-2 text-end">
                                    Assets File
                                  </div>

                                  <div className="col-7 image-upload">
                                    <label for="Asset-file-input">
                                      <span className=" rounded border-pink btn-svg px-3 py-1">
                                        <ImgUpload /> Image Upload
                                      </span>
                                    </label>
                                    <input
                                      type="file"
                                      id="Asset-file-input"
                                      name="uploadObj"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "uploadObj",
                                          e.target.files[0]
                                        );
                                        setFieldValue(
                                          "asset_file_name",
                                          e.target.files[0].name
                                        );
                                      }}
                                      style={{ display: "flex" }}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5 pt-2 text-end">
                                    Assets Name
                                  </div>
                                  <div className="col-7">
                                    <Input
                                      name="asset_name"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5 pt-2 text-end">
                                    Assets Description
                                  </div>
                                  <div className="col-7">
                                    <TextArea
                                      name="asset_description"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5  text-end">Active</div>
                                  <div className="col-7">
                                    <Switch
                                      checked={values.is_active}
                                      name="is_active"
                                      onChange={() =>
                                        setFieldValue(
                                          "is_active",
                                          !values.is_active
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5  text-end">
                                    Downloadable
                                  </div>
                                  <div className="col-7">
                                    <Switch
                                      checked={values.is_downloadable}
                                      name="is_downloadable"
                                      onChange={() =>
                                        setFieldValue(
                                          "is_downloadable",
                                          !values.is_downloadable
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5  text-end">
                                    Max No. of download
                                  </div>
                                  <div className="col-7">
                                    <Input
                                      name="max_no_of_downloads"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5 pt-2 text-end">
                                    Whitelist
                                  </div>
                                  <div className="col-7">
                                    <Input
                                      name="whitelisted_domains"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5 pt-2 text-end">
                                    Blacklist
                                  </div>
                                  <div className="col-7">
                                    <Input
                                      name="blacklisted_domains"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-5  text-end">Info</div>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </Modal>
                    </div>
                  </section>
                </div>
                <div
                  className="shadow-sm p-3 d-flex justify-content-between"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      setFieldValue("zone_agents", selectedAgents);
                      setFieldValue(
                        "zone_chat_bot_setting_details",
                        chatBotDetails
                      );
                      setFieldValue(
                        "zones_social_media_handles_details",
                        socialMediaUrl
                      );
                      const temp_asset = assetsArray.map((elem, index) => ({
                        asset_file_type: elem.asset_file_type,
                        asset_file_name: "image 15",
                        asset_name: elem.asset_name,
                        asset_description: elem.asset_description,
                        is_active: elem.is_active,
                        is_downloadable: elem.is_downloadable,
                        max_no_of_downloads: parseInt(elem.max_no_of_downloads),
                        total_downloads: elem.total_downloads,
                        whitelisted_domains: elem.whitelisted_domains,
                        blacklisted_domains: elem.blacklisted_domains,
                        view_count: elem.view_count,
                      }));
                      setFieldValue("zones_assets_details", temp_asset);
                    }}
                  ></Button>
                  <Link to="/zonemanagement">
                    <Button className="rounded button-pink px-3">Cancel</Button>
                  </Link>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
};

export default ViewZone;
