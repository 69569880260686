import React, { Fragment, useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Checkbox, Input, Select, Button } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./index.css";
import { logo } from "../../components/Images/Images";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomerDetails,
  createRegisterCustomer
} from "../../redux/slice/customerManagementSlice";
import { addUser } from "../../redux/slice/register";
import { getCountries } from "../../redux/slice/register";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import axios from "axios";
import CommonLoader from "../../components/Widgets/CommonLoader";

const CustomerRegistration = props => {
  const location = useLocation();
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const dispatch = useDispatch();
  const user = useSelector(
    state => state?.customerManagement?.customerDetails?.data?.data
  );
  const loading = useSelector(state => state?.customerManagement?.loading);

  const { Option } = Select;
  const [forceUpdate, setForceUpdate] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [fields, setFields] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);

    const query = new URLSearchParams(location.search);
    const customer_id = parseInt(query.get("customer_id"));
    window.less.modifyVars({ "@primary-color": "#ef0855" }).then(() => {
      // console.log("Theme updated successfully");
    });

    dispatch(
      getCustomerDetails({
        customer_id
      })
    );
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (user) {
      initializeUserData();
    }
  }, [user]);

  const initializeUserData = () => {
    const query = new URLSearchParams(location.search);
    const customer_id = parseInt(query.get("customer_id"));

    if (user) {
      setFields({
        customer_id: customer_id,
        account_name: user?.details?.account_name,
        main_account_name: user?.details?.main_contact_person,
        email: user?.details?.main_contact_email,
        password: "",
        confirmPassword: ""
      });
    }
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleRegisterSubmit = async e => {
    e.preventDefault();

    if (validator.current.allValid()) {
      if (fields.password !== fields.confirmPassword) {
        toast.error("Confirm Passord does not match");
      } else {
        setPageLoading(true);
        let newUser = {
          customer_id: fields?.customer_id,
          email: fields?.email,
          password: fields?.password,
          is_signup: true
        };
        console.log("FInal data to Send ->", newUser);
        const addCustomerResponse = await dispatch(
          createRegisterCustomer(newUser)
        );
        if (addCustomerResponse.payload.code === 200) {
          toast.success(addCustomerResponse.payload.message);

          props.history.push("/login");
        } else {
          toast.error(addCustomerResponse.payload.message);
        }
      }
      setPageLoading(false);
    } else {
      validator.current.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  if (pageLoading) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div style={{ height: "100vh" }} className="registration-bg-img-div">
          <div className="site-card-border-less-wrapper py-5 text-center">
            <img src={logo} alt="logo" />
            {/* {console.log("Customer Details", user)} */}
            {console.log("Customer Fields", fields)}

            <Card
              bordered={true}
              style={{ width: 500 }}
              headStyle={{ width: 500 }}
              className={
                Tablet
                  ? "m-auto w-50 mt-4 rounded registration-div"
                  : "m-auto w-25 mt-4 rounded registration-div"
              }
            >
              <h5 className="text-center mt-3 fs-26"> Sign up </h5>

              <form autocomplete="off">
                <div className="container px-5">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Account Name
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="account_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Account Name"
                          autoComplete="account_name"
                          className="py-2"
                          value={fields?.account_name}
                          onChange={e =>
                            handleFieldsChange(e.target.value, "account_name")
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("account_name")
                          }
                        />
                        {validator.current.message(
                          "Account Name",
                          fields.account_name,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Main Contact Name
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="main_contact_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Main Contact Name"
                          autoComplete="main_account_name"
                          className="py-2"
                          value={fields.main_account_name}
                          onChange={e =>
                            handleFieldsChange(
                              e.target.value,
                              "main_account_name"
                            )
                          }
                          onBlur={() =>
                            validator.current.showMessageFor(
                              "main_contact_name"
                            )
                          }
                        />
                        {validator.current.message(
                          "Main contact name",
                          fields.main_account_name,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-6 mt-4">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Email Address
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="email"
                          type="email"
                          disabled
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoComplete="customer_email"
                          className="py-2"
                          value={fields.email}
                          onChange={e =>
                            handleFieldsChange(e.target.value, "email")
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("email")
                          }
                        />
                        {validator.current.message(
                          "Email",
                          fields.email,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-6 mt-4"></div>

                    <div className="col-6 mt-2">
                      <div className="form-group text-start">
                        <label className="fs-16">Password</label>
                        <Input.Password
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          // iconRender={visible =>
                          //   visible ? <EyeTwoTone className="password_icon_color" /> : <EyeInvisibleOutlined className="password_icon_color" />
                          // }
                          className="py-2 customer_regis_passInput"
                          onBlur={() =>
                            validator.current.showMessageFor("password")
                          }
                          onChange={e =>
                            handleFieldsChange(e.target.value, "password")
                          }
                          autoComplete="new-password"
                        />
                        {validator.current.message(
                          "Password",
                          fields.password,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-6 mt-2">
                      <div className="form-group text-start">
                        <label className="fs-16">Confirm Password</label>
                        <Input.Password
                          name="confirmPassword"
                          type="password"
                          className="form-control"
                          placeholder="Enter password"

                          // iconRender={visible =>
                          //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          // }
                          className="py-2 customer_regis_passInput"
                          onBlur={() =>
                            validator.current.showMessageFor("confirmPassword")
                          }
                          onChange={e =>
                            handleFieldsChange(
                              e.target.value,
                              "confirmPassword"
                            )
                          }
                          autoComplete="new-password"
                        />
                        {validator.current.message(
                          "Confirm Password",
                          fields.confirmPassword,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <Checkbox className="mx-1" />
                      <span>
                        Please check the box to show that you have read and
                        understood our{" "}
                        <span className="fs-pink">
                          <u>Terms & Conditions</u>
                        </span>
                      </span>
                    </div>
                    <div className="col-12">
                      <Button
                        type="submit"
                        className="w-100 mt-2 register_submit_btn"
                        onClick={handleRegisterSubmit}
                        loading={loading}
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="mt-2 text-danger">
                {/* {showError && <span className = "text-center fs-14 mt-5">{isError}</span>} */}
              </div>
              <p className="fs-14">
                Already Registered?{" "}
                <Link to="/">
                  <span className="fs-pink">
                    <u>Sign In</u>
                  </span>
                </Link>
              </p>
            </Card>
          </div>
        </div>
      </Fragment>
    );
};

export default CustomerRegistration;
