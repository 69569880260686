import { ApiCall } from "../../../../../../utils/api";

export const getBrandZoneData = async (params) => {
    const response = await ApiCall("get", null, "/admin/zone/card/details/get", params);

    if (response?.data?.code === 200) {
        return response.data.data;
    } else return null;
}

export const getAudiSourceDetails = async (params) => {
    const response = await ApiCall("get", null, "/admin/vista/auditoriumsourcedetails/get", params);
    console.log("Audisource Resp", response.data.data)
    if (response?.data?.code === 200) {
        return response.data.data;
    } else return null;
}





