import React, { useState } from "react";
import "./surveyscreen.css";
import { Button, Checkbox, Input, Switch, Steps, message, Select } from "antd";

const { TextArea } = Input;

const { Option } = Select;

const Survey = () => {
  const [start, setStart] = useState(true);

  const { Step } = Steps;

  const steps = [
    <ToggleForm />,
    <SingleTickBox />,
    <MultipleTickBoxes />,
    <MultipleChoice />,
    <FreeFormText />
  ];

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <h5>Survey</h5>
      <span className="opacity-75">Survey</span>
      <br />
      {!start ? (
        <>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current]}</div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button className="mt-2" type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                className="mt-2"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button
                className="mt-2"
                style={{ margin: "0 8px" }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
          </div>
        </>
      ) : (
        <StartSurvey setStart={setStart} />
      )}
    </>
  );
};

export default Survey;

const StartSurvey = ({ setStart }) => {
  return (
    <>
      <div className="survey_container   w-100 bg-white">
        <h5 className="fs-pink">Start Survey</h5>

        <div className="row">
          <div className="col-1 my-2">Survey Type</div>
          <div className="col-4 my-2">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select"
              optionFilterProp="children"
              name="survey_type"
              className="fs-bold-14"
            >
              <Option value="EVENT">Event</Option>
              <Option value="REGISTRATION">Registration</Option>
              <Option value="Brand/Zone">Brand/Zone</Option>
            </Select>
          </div>
        </div>

        <div className="row">
          <div className="col-1 my-2">Survey Title</div>
          <div className="col-4 my-2">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select"
              optionFilterProp="children"
              name="survey_title"
              className="fs-bold-14"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
            </Select>
          </div>
        </div>

        <div className="row">
          <div className="col-4 my-2"></div>
          <div className="col-4 my-2">
            <Button type="primary" onClick={() => setStart(false)}>
              Start
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const ToggleForm = () => {
  return (
    <>
      <div className="survey_container  w-100 bg-white">
        <h5 className="fs-pink">Toggle Type Question</h5>
        <span>No</span> <Switch /> <span>Yes</span>
      </div>
    </>
  );
};

const SingleTickBox = () => {
  return (
    <>
      <div className="survey_container  w-100 bg-white">
        <h5 className="fs-pink">Single Tick Type Question</h5>

        <Checkbox checked />
      </div>
    </>
  );
};

const MultipleTickBoxes = () => {
  return (
    <>
      <div className="survey_container  w-100 bg-white">
        <h5 className="fs-pink">Multiple Tick Type Question</h5>
        <div className="d-flex">
          <div className=" d-flex border p-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 1</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox checked />
            <span className="ms-2">Option 2</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 3</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 4</span>
          </div>
        </div>
      </div>
    </>
  );
};

const MultipleChoice = () => {
  return (
    <>
      <div className="survey_container  w-100 bg-white">
        <h5 className="fs-pink">Multiple Choice Type Question</h5>
        <div className="d-flex">
          <div className=" d-flex border p-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 1</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox checked />
            <span className="ms-2">Option 2</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 3</span>
          </div>
          <div className=" d-flex border p-2 ms-2 option_block">
            <Checkbox />
            <span className="ms-2">Option 4</span>
          </div>
        </div>
      </div>
    </>
  );
};

const FreeFormText = () => {
  return (
    <>
      <div className="survey_container  w-100 bg-white">
        <h5 className="fs-pink">Free Form Type Question</h5>
        <TextArea placeholder="Type here" className="w-50" rows={4} />
      </div>
    </>
  );
};
