import { cloneDeep, isEqual, uniqWith } from "lodash";
import moment from "moment";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
const halfHours = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];
export const getHalfAnHourIntervals = () => {
  let hours = [];
  halfHours.forEach((ele, index) => {
    if (halfHours[index] && halfHours[index + 1])
      hours.push({
        start_time: halfHours[index],
        end_time: halfHours[index + 1],
      });
  });
  return hours;
};

export function ifTimeSlotIncluded(
  halfHrStart,
  halfHrEnd,
  currentBlockStartTime,
  currentBlockEndTime
) {
  if (
    (halfHrStart.isAfter(currentBlockStartTime) &&
      halfHrEnd.isBefore(currentBlockEndTime)) ||
    (halfHrStart.format("HH:mm A") ===
      currentBlockStartTime.format("HH:mm A") &&
      halfHrEnd.isBefore(currentBlockEndTime)) ||
    (halfHrEnd.format("HH:mm A") === currentBlockEndTime.format("HH:mm A") &&
      halfHrStart.isAfter(currentBlockStartTime)) ||
    (currentBlockStartTime.isAfter(halfHrStart) &&
      currentBlockStartTime.isBefore(halfHrEnd)) ||
    (currentBlockEndTime.isAfter(halfHrStart) &&
      currentBlockEndTime.isBefore(halfHrEnd)) ||
    (halfHrStart.format("HH:mm A") ===
      currentBlockStartTime.format("HH:mm A") &&
      halfHrEnd.format("HH:mm A") === currentBlockEndTime.format("HH:mm A"))
  )
    return true;
  else return false;
}

export function isFirstTitleCell(
  halfHrStart,
  halfHrEnd,
  currentBlockStartTime
) {
  if (
    (currentBlockStartTime &&
      currentBlockStartTime.format("HH:mm A") ===
        halfHrStart.format("HH:mm A")) ||
    (currentBlockStartTime &&
      currentBlockStartTime.isAfter(halfHrStart) &&
      currentBlockStartTime.isBefore(halfHrEnd))
  )
    return true;
  else return false;
}

export function ifTimeSlotAvailable(startTime, endTime, existingTimings) {
  let isTimeSlotAvailble = true;
  console.log("existingTimings = ", existingTimings);

  if (existingTimings) {
    existingTimings.some(function (ele, index) {
      let presentStart = moment(ele.start_time, TIME_FORMAT);
      let presentEnd = moment(ele.end_time, TIME_FORMAT);
      if (
        startTime.format("HH:mm A") === presentStart.format("HH:mm A") ||
        endTime.format("HH:mm A") === presentEnd.format("HH:mm A") ||
        (startTime.isBefore(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isBefore(presentEnd)) ||
        (startTime.isBefore(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isAfter(presentEnd)) ||
        (startTime.isAfter(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isAfter(presentEnd))
      )
        isTimeSlotAvailble = false;
    });

    return isTimeSlotAvailble;
  } else return isTimeSlotAvailble;
}

export function isPastDate(dateStringToCheck) {
  const dateToCheck = new Date(dateStringToCheck);
  let todayDateString = moment().format("YYYY-MM-DD");
  const now = new Date(todayDateString);
  // console.log("dateToCheck, now = ", dateToCheck, now);
  // console.log("dateToCheck > now = ", dateToCheck > now);

  if (dateToCheck < now) return "yes";
  else return "no";
}

export function getCurrentTimeAndPercentage() {
  let interval = 30 * 60 * 1000; // 30 minutes in milliseconds
  let finalDate = new Date(
    Math.ceil(new Date().getTime() / interval) * interval
  ).toLocaleTimeString("en-US", {
    // timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  let new_interval = 1 * 60 * 1000;
  let newFinalDate = new Date(
    Math.ceil(new Date().getTime() / new_interval) * new_interval
  ).toLocaleTimeString("en-US", {
    // timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  const expiration = moment(finalDate, TIME_FORMAT);
  const nownow = moment(newFinalDate, TIME_FORMAT);
  const diff = expiration.diff(nownow);
  const diffDuration = moment.duration(diff);
  let finalDiff = diffDuration.minutes();
  let heightMargin = (30 - finalDiff) * 2;
  let halfHours = getHalfAnHourIntervals();

  let timeSlotOfCurrentTime = { start_time: "", end_time: "" };
  halfHours.forEach((ele, index) => {
    let halfHrStart = moment(ele.start_time, TIME_FORMAT);
    let halfHrEnd = moment(ele.end_time, TIME_FORMAT);

    if (
      moment() === halfHrStart ||
      moment() === halfHrEnd ||
      (moment().isAfter(halfHrStart) && moment().isBefore(halfHrEnd))
    ) {
      if (halfHours[index - 1])
        timeSlotOfCurrentTime = {
          start_time: halfHours[index - 1].start_time,
          end_time: halfHours[index - 1].end_time,
        };
    }
  });

  return {
    time: moment(finalDate, TIME_FORMAT),
    currentTime: moment(),
    heightMargin,
    timeSlotOfCurrentTime,
  };
}

export function checkIfChatbotTimeSlot(dataToCheck) {
  // console.log("dataToCheck = ", dataToCheck);
  if (dataToCheck && dataToCheck.chat_bot_start_time) {
    let chat_start_time = moment(dataToCheck.chat_bot_start_time, TIME_FORMAT);
    let chat_end_time = moment(dataToCheck.chat_bot_end_time, TIME_FORMAT);
    let getNowTime = moment();

    if (
      ifTimeSlotIncluded(getNowTime, getNowTime, chat_start_time, chat_end_time)
    ) {
      // console.log("included");
      return true;
    } else {
      // console.log("not included");
      return false;
    }
  } else {
    // console.log("not found condition");
    return false;
  }
}

export function searchSessionsFromData(allSessions, searchTerm) {
  let clonedAllSessions = cloneDeep(allSessions);
  let allSearchedData = [];
  clonedAllSessions &&
    clonedAllSessions.forEach((element) => {
      if (element) {
        if (element && element.auditorium_hosting_brands) {
          let isItemFound = false;
          element.auditorium_hosting_brands.forEach((item) => {
            if (item?.brand_title?.toLowerCase().includes(searchTerm))
              isItemFound = true;
          });
          if (isItemFound) allSearchedData.push(element);
          else if (element.session_title.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(element);
          }
        } else if (element && element.networking_lounge_sponsoring_brands) {
          let isItemFound = false;
          element.networking_lounge_sponsoring_brands.forEach((item) => {
            if (item?.brand_title?.toLowerCase().includes(searchTerm))
              isItemFound = true;
          });
          if (isItemFound) allSearchedData.push(element);
          else if (element.session_title.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(element);
          }
        }
      }
    });
  return allSearchedData;
}

export function getSponserTimingsAndHeader(ele, key, liveTime) {
  let timings = [];
  let newHeader = {};
  ele[key].forEach((item) => {
    if (item && item.start_time && item.end_time) {
      let start_time = moment(item.start_time, TIME_FORMAT);
      let end_time = moment(item.end_time, TIME_FORMAT);
      timings.push({
        start_time: item.start_time,
        end_time: item.end_time,
        data: item,
      });
      if (
        liveTime &&
        liveTime.currentTime.isAfter(start_time) &&
        liveTime.currentTime.isBefore(end_time)
      )
        newHeader = item;
    }
  });
  timings = uniqWith(timings, isEqual);
  return { timings, newHeader };
}

export function getAllSessions(result) {
  let allSessionsToSearch = [];
  if (
    result.main_auditorium_configurations &&
    result.main_auditorium_configurations.auditorium_sessions
  ) {
    result.main_auditorium_configurations.auditorium_sessions.forEach(
      (element) => {
        allSessionsToSearch.push(element);
      }
    );
  }

  if (result.satellite_auditorium_configurations) {
    result.satellite_auditorium_configurations.forEach((ele) => {
      if (ele && ele.auditorium_sessions) {
        ele.auditorium_sessions.forEach((element) => {
          allSessionsToSearch.push(element);
        });
      }
    });
  }

  if (result.networking_lounge_configurations) {
    result.networking_lounge_configurations.forEach((ele) => {
      if (ele && ele.networking_lounge_sessions) {
        ele.networking_lounge_sessions.forEach((element) => {
          allSessionsToSearch.push(element);
        });
      }
    });
  }

  return allSessionsToSearch;
}
