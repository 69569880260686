import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Select, Input, Button, Switch, Checkbox } from "antd";
import { EmailWhite } from "../../components/svgJS/EmailRed";
import { useMediaQuery } from "react-responsive";
import { CalenderRed } from "../../components/svgJS/CalenderRed";
import { PadLock } from "../../components/svgJS/PadLock";
import {
  editUser,
  viewUserDetails
} from "../../redux/slice/userManagementSlice";
import { ApiCall } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../common/FileUpload";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { getCountryList, getJobTitles } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";

const EditUser = props => {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");

  const { match } = props;

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const validator = useRef(new SimpleReactValidator());

  // Validation states
  const [showError, setshowError] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");
  const [loadingUser, setLoadingUser] = useState(false);

  // Edit user states

  const [eventList, setEventList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [profilePicture, setProfilePicture] = useState();
  const [jobTitle, setJobTitle] = useState([]);
  const [userData, setUserData] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [filteredZone, setFilteredZone] = useState([]);
  const [showNewEmail, setshowNewEmail] = useState(false);
  const [newTaggedZone, setNewTaggedZone] = useState(null);
  const [loadingReset, setLoadingReset] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  const [allowOtherCountry, setAllowOtherCountry] = useState(false);
  const [allowOtherJobTitle, setAllowOtherJobTitle] = useState(false);

  const [fields, setFields] = useState({
    is_active: true,
    event_id: "",
    first_name: userData.first_name,
    last_name: "",
    email: "",
    new_email: "",
    company: "",
    job_title_id: "",
    job_title: null,
    country_id: null,
    country: null,
    user_type: "",
    tier_type_id: null,
    bio: "",
    is_admin: false, //switch
    is_speaker: false, //switch
    brand_id: null,
    website_url: "dummy.com",
    zone_tag_details: [],
    external_social_links_details: []
  });
  const { loading } = useSelector(state => state.userManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setLoadingUser(true)
    getUserDetail();
    getEventList();
    getUserTypeList();
    getSocialMediaList();
    setTimeout(() => {
      setLoadingUser(false);
    }, 2000);
  }, []);

  useEffect(() => {
    //For Restriced Countries and Job title
    if (countryList && jobTitle && fields.event_id) {
      getEventDetails(fields?.event_id);
    }
  }, [countryList, jobTitle, fields.event_id]);

  useEffect(() => {
    if (fields?.event_id) {
      getBrandList();
    }
  }, [fields?.event_id]);

  useEffect(() => {
    getTierList();
  }, [fields?.brand_id]);

  useEffect(async () => {
    const countryListAPI = await getCountryList();
    setCountryList(countryListAPI)

    const jobTitlesListAPI = await getJobTitles();
    setJobTitle(jobTitlesListAPI)
  }, [])

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }

    let external_social_links_details = [];

    userData?.brands_social_media_handles_id?.map((val, idx) => {
      external_social_links_details.push({
        brand_social_id: val.brand_social_id,
        social_media_id: val.social_media_id,
        social_media_url: val.social_media_url
      });
    });
  }, [userData]);

  useEffect(() => {
    if (zoneList?.length > 0) {
    }
  }, [fields.brand_id, zoneList, fields?.user_type]);

  const getUserDetail = async () => {
    const response = await dispatch(
      viewUserDetails({ user_id: match.params.userid })
    );

    const { details } = response?.payload?.data;

    setUserData(details);
    getZoneList(details);
    setNewTaggedZone(details);

    setFields({
      ...fields,
      user_id: parseInt(match.params.userid),
      is_active: details?.is_active, //switch
      event_id: details?.event_id,
      first_name: details?.first_name,
      last_name: details?.last_name,
      email: details?.email,
      company: details?.company,
      job_title_id: details?.job_title_id,
      job_title: null,
      country_id: details?.country?.country_id,
      country: null,
      user_type: details?.user_type,
      tier_type_id: details?.tier_type_id,
      bio: details?.bio,
      is_admin: details?.is_admin, //switch
      is_speaker: details?.is_speaker, //switch
      brand_id: details?.brand_id,
      website_url: details?.website_url,
      external_social_links_details:
        details?.users_users_social_media_handles_user_id,
      image_url: details?.image_url
    });
  };

  const handleFormSubmit = async e => {
    window.scrollTo(0, 0);
    e.preventDefault();

    const data =
      filteredZone &&
      filteredZone?.map(res => {
        return {
          zone_id: res.zone_id,
          is_assigned: res?.is_assigned ? res?.is_assigned : false,
          is_always_get_messages: res?.is_always_get_messages
            ? res?.is_always_get_messages
            : false
        };
      });
    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ ...fields, zone_tag_details: data ? data : [] })
    );
    formData.append("profile_picture", fields?.image_url);

    if (validator.current.allValid()) {
      // post api call
      const editUserResp = await dispatch(editUser(formData));

      if (editUserResp.payload.data.code === 200) {
        toast.success(editUserResp.payload.data.message);
        setshowError(false);
        props.history.push("/usermanagement");
      } else if (editUserResp.payload.data.code === 403) {
        toast.error(editUserResp.payload.data.message);
        setshowError(false);
      } else {
        toast.error(editUserResp?.payload?.data?.message);
        setshowError(true);
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }
  };

  const handleFieldsChange = (val, name) => {

    if (name == "event_id") {
      setFields({ ...fields, [name]: val, brand_id: null, zone_tag_details: [] });
      setFilteredZone([])
    }
    else if (name == "brand_id") {
      setFields({ ...fields, [name]: val, zone_tag_details: [] });
      setFilteredZone([])

      let newTemp = zoneList?.filter(
        data => data?.brand_id === val
      );
      const dummyTemp = setCurrentZoneTags(
        newTemp,
        zoneList
      );
      setFilteredZone(dummyTemp);
    }
    else {
      setFields({ ...fields, [name]: val });
    }
  };

  const socialMediaHandleChange = (event, idx) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = fields.external_social_links_details;
    let isAvailable = external_social_links_details.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (isAvailable) {
      let index = external_social_links_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );

      external_social_links_details[index]["social_media_url"] =
        event.target.value;
    } else {
      external_social_links_details.push({
        brand_social_id: social_media_list[idx].brand_social_id,
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: social_media_list[idx].social_media_url
      });
    }

    setFields({
      ...fields,
      external_social_links_details: external_social_links_details
    });
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = fields.external_social_links_details;
    let isAvailable = external_social_links_details.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      if (isAvailable) {
        let index = external_social_links_details.findIndex(
          social_media =>
            social_media.social_media_id ===
            social_media_list[idx]["social_media_id"]
        );
        external_social_links_details[index]["social_media_url"] = "";
      } else {
        external_social_links_details.push({
          social_media_id: social_media_list[idx].social_media_id,
          social_media_url: ""
        });
      }
    } else {
      let index = external_social_links_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      external_social_links_details[index]["social_media_url"] = null;
    }
    setFields({
      ...fields,
      external_social_links_details: external_social_links_details
    });
  };

  const handleImgChange = event => {
    console.log("img event", event.target.files[0])
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
    setFields({ ...fields, image_url: event.target.files[0] });
  };
  const handleFileDelete = () => {
    setProfilePicture(null);
    setFields({ ...fields, image_url: null });
  };


  console.log("profile picture", profilePicture)
  console.log("Logo Field", fields.image_url)

  // Dropdown api calls

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then(response => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch(err => {
        console.error(err);
      });
  };


  const getUserTypeList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then(response => {
        const { rows } = response.data.data;
        setUserTypeList(rows);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : null
    })
      .then(response => {
        const { rows } = response.data.data;
        setBrandList(rows);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", {
      brand_id: fields?.brand_id ? fields?.brand_id : null
    })
      .then(response => {
        const { data } = response.data;
        setTierList(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then(response => {
        setSocialMediaList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const setCurrentZoneTags = (newZones, zoneListHere) => {
    const dataArray =
      zoneListHere &&
      zoneListHere.map(res => {
        return res.zone_id;
      });
    const Data =
      newZones.length > 0 &&
      newZones.map(res => {
        const NewData = dataArray.includes(res.zone_id)
          ? {
            ...res,
            is_assigned: res.is_assigned,
            is_always_get_messages: res.is_always_get_messages
          }
          : {
            ...res,
            is_assigned: false,
            is_always_get_messages: false
          };

        return NewData;
      });
    return Data;
  };

  const getZoneList = details => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then(response => {
        const Data = setCurrentZoneTags(
          details.user_zones,
          response.data.data.rows
        );

        var flattened = [].concat.apply([], Data);
        setZoneList(response.data.data.rows);
        setFilteredZone(flattened);
      })
      .catch(err => {
        console.error("err", err);
      });
  };

  const handleCheckBox1Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_assigned = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  const handleCheckBox2Change = (e, data, idx) => {
    let filtered_zone = filteredZone;

    filtered_zone[idx].is_always_get_messages = e.target.checked;

    setFilteredZone([...filtered_zone]);
  };

  const resetPass = async () => {
    setLoadingReset(true);
    console.log(fields?.email);
    let values = {
      email: fields?.email
    };
    const response = await ApiCall("POST", values, "/admin/forgotpassword", "");
    console.log(response);

    if (response.data.code === 200) {
      toast.success(response.data.message);
      setLoadingReset(false);
    } else {
      toast.error(response.data.message);
      setLoadingReset(false);
    }
  };

  const getEventDetails = async event_id => {
    const responseEvent = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get`,
      {
        event_id: event_id
      }
    );

    if (responseEvent?.data?.data?.registration_and_communication_details) {
      let countryFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.country_collection;
      setAllowOtherCountry(responseEvent.data.data.registration_and_communication_details.allow_other_country)

      if (countryFlag) {
        if (countryList) {
          let countryIds =
            responseEvent?.data?.data?.registration_and_communication_details
              ?.country_collection_ids;

          let result = countryList.filter(o1 =>
            countryIds.some(o2 => o1?.country_id === o2)
          );

          setSelectedCountries(result);
        }
      }

      let jobFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.job_title_collection;

      setAllowOtherJobTitle(responseEvent.data.data.registration_and_communication_details.allow_other_job_title)

      if (jobFlag) {
        if (jobTitle) {
          let jobTitleIds =
            responseEvent?.data?.data?.registration_and_communication_details
              ?.job_title_collection_ids;

          let result2 = jobTitle.filter(o1 =>
            jobTitleIds.some(o2 => o1?.job_title_id === o2)
          );
          setSelectedJobTitles(result2);
        }
      }
    }
  };

  if (loadingUser) {
    return <CommonLoader />;
  } else
    return (
      <div className="view-all-common">
        <form onSubmit={handleFormSubmit}>

          <div>
            <h4 className="fs-24">Edit User</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>
                <Link to="/usermanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/usermanagement">User Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/edituser">Edit User</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className="bg-white px-5 py-3">
              <div className="row my-3">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">User Editor</h6>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">Active</div>
                <div className="col-1">
                  <Switch
                    name="is_active"
                    checked={fields.is_active}
                    onChange={checked => handleFieldsChange(checked, "is_active")}
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Select Event
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      className="w-25"
                      placeholder="Select Event"
                      name="event_id"
                      value={fields.event_id}
                      onChange={val => handleFieldsChange(val, "event_id")}
                      onBlur={() => validator.current.showMessageFor("event_id")}
                      autoComplete="none"
                      listHeight={120}
                      listItemHeight={4}
                    >
                      {eventList &&
                        eventList?.map(data => {
                          return (
                            <Option value={data.event_id}>
                              {data.event_name}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "event",
                      fields.event_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    First Name
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Input
                      name="first_name"
                      id="first_name"
                      className="w-25 border-gray rounded"
                      placeholder="Enter First Name"
                      value={fields.first_name}
                      onChange={e =>
                        handleFieldsChange(e.target.value, "first_name")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("first_name")
                      }
                      autoComplete="none"
                    />
                    {validator.current.message(
                      "First Name",
                      fields.first_name,
                      "required|first_name",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Surname
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Input
                      name="last_name"
                      id="last_name"
                      className="w-25 border-gray rounded"
                      placeholder="Enter Surname"
                      value={fields.last_name}
                      onChange={e =>
                        handleFieldsChange(e.target.value, "last_name")
                      }
                      onBlur={() => validator.current.showMessageFor("last_name")}
                      autoComplete="none"
                    />
                    {validator.current.message(
                      "Last Name",
                      fields.last_name,
                      "required|last_name",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Email Address
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Input
                      name="email"
                      id="email"
                      className="w-25 border-gray rounded"
                      placeholder="Enter Email"
                      value={fields.email}
                      onChange={e => handleFieldsChange(e.target.value, "email")}
                      onBlur={() => validator.current.showMessageFor("email")}
                      autoComplete="none"
                      disabled
                    />
                    {validator.current.message(
                      "email",
                      fields.email,
                      "required|email",
                      { className: "text-danger py-2" }
                    )}
                    <Button
                      className="mx-2 ant-btn ant-btn-primary rounded"
                      icon={<EmailWhite />}
                      onClick={() => setshowNewEmail(true)}
                    >
                      <span className="mx-2">Change </span>
                    </Button>
                  </div>
                </div>
                {showNewEmail && (
                  <div className="row my-3">
                    <div className="col-3 text-end">
                      New Email Address
                      <sup>
                        <span
                          className="text-danger"
                          style={{ fontSize: "1.1rem" }}
                        >
                          *
                        </span>
                      </sup>
                    </div>
                    <div className="col-9">
                      <Input
                        name="new_email"
                        id="new_email"
                        value={fields.new_email}
                        onChange={e =>
                          handleFieldsChange(e.target.value, "new_email")
                        }
                        className="w-25 border-gray rounded"
                        placeholder="Enter New Email"
                        onBlur={() =>
                          validator.current.showMessageFor("new_email")
                        }
                        autoComplete="none"
                      />
                      {validator.current.message(
                        "new_email",
                        fields.new_email,
                        "required|email",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                    <div className="col-3"></div>
                    <div className="col-8">
                      NB: This will send an email to the users new email address
                      (to create a new password).
                      <br /> Their old email address / password will no longer
                      work.
                    </div>
                  </div>
                )}
                <div className="row my-3 mb-0">
                  <div className="col-3 text-end">
                    Profile Picture
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9 d-flex prof_user_btn">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={handleImgChange}
                      inputId="edit_user_logo"
                      inputName="edit_user_logo"
                      onBlur={() =>
                        validator.current.showMessageFor("profilePicture")
                      }
                    />
                    {profilePicture ? (
                      <>
                        <span className="mx-3"> {fields.image_url?.name} </span>
                        <span onClick={handleFileDelete}>
                          {" "}
                          <DeleteOutlined />{" "}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <br />
                  </div>
                  <div className="col-3"></div>
                  <div className="col-9">
                    {validator.current.message(
                      "profilePicture",
                      fields?.image_url,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>

                {profilePicture ? (
                  <div className="row ">
                    <div className="col-3"></div>
                    <div className="col-9">
                      <img
                        src={profilePicture}
                        alt=" Uploaded Logo"
                        className="img-fluid user-img-preview"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row ">
                    <div className="col-3"></div>
                    <div className="col-9">
                      <img
                        src={fields?.image_url}
                        alt={fields.image_url ? "Uploaded Logo" : ""}
                        className="img-fluid user-img-preview"
                      />
                    </div>
                  </div>
                )}

                <div className="row my-3">
                  <div className="col-3 text-end">
                    Job Role
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      className="w-25 fs-bold-14"
                      placeholder="Select Job Role"
                      optionFilterProp="children"
                      name="job_title_id"
                      value={fields.job_title_id}
                      onChange={val => handleFieldsChange(val, "job_title_id")}
                      onBlur={() =>
                        validator.current.showMessageFor("job_title_id")
                      }
                      autoComplete="none"
                      listHeight={120}
                      listItemHeight={4}
                    >
                      {selectedJobTitles &&
                        selectedJobTitles?.map(data => {
                          return (
                            <Option value={data.job_title_id}>
                              {data.job_title}
                            </Option>
                          );
                        })}
                      {allowOtherJobTitle &&
                        <Option value="Other">Other</Option>
                      }
                    </Select>
                    {validator.current.message(
                      "job_title_id",
                      fields.job_title_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                  <div className="col-3"></div>
                  {fields?.job_title_id === "Other" &&
                    <div className="col-9 mt-2">
                      <Input
                        name="job_title"
                        id="job_title"
                        className="w-25 border-gray rounded fs-bold-14"
                        placeholder="Enter job_title "
                        value={fields?.job_title}
                        onChange={e => handleFieldsChange(e.target.value, "job_title")}
                        autoComplete="none"
                      />
                    </div>
                  }
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Country
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      className="w-25"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      name="country_id"
                      value={fields.country_id}
                      onChange={val => handleFieldsChange(val, "country_id")}
                      onBlur={() =>
                        validator.current.showMessageFor("country_id")
                      }
                      autoComplete="none"
                      listHeight={120}
                      listItemHeight={4}
                    >
                      {selectedCountries &&
                        selectedCountries?.map(data => {
                          return (
                            <Option value={parseInt(data.country_id)}>
                              {data.country}
                            </Option>
                          );
                        })}
                      {allowOtherCountry &&
                        <Option value="Other">Other</Option>
                      }
                    </Select>
                    {validator.current.message(
                      "country",
                      fields.country_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                  <div className="col-3"></div>
                  {fields?.country_id === "Other" &&
                    <div className="col-9 mt-2">
                      <Input
                        name="country"
                        id="country"
                        className="w-25 border-gray rounded fs-bold-14"
                        placeholder="Enter Country Name"
                        value={fields.country}
                        onChange={e => handleFieldsChange(e.target.value, "country")}
                        autoComplete="none"
                      />
                    </div>
                  }
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    User Type
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      className="w-25"
                      placeholder="Select User"
                      optionFilterProp="children"
                      name="user_type"
                      value={fields.user_type}
                      onChange={val => handleFieldsChange(val, "user_type")}
                      autoComplete="none"
                    >
                      <Option value="AGENT">Agent</Option>
                      <Option
                        value="DELEGATE"
                        className={userType === "BRAND ADMIN" ? "d-none" : ""}
                      >
                        Delegate
                      </Option>
                      <Option
                        value="SPEAKER"
                        className={userType === "BRAND ADMIN" ? "d-none" : ""}
                      >
                        Speaker
                      </Option>
                    </Select>
                  </div>
                </div>

                {fields.user_type === "AGENT" && (
                  <>
                    <div className="row my-3">
                      <div className="col-3 text-end">Admin</div>
                      <div className="col-9">
                        <Switch
                          name="is_admin"
                          checked={fields.is_admin}
                          onChange={checked =>
                            handleFieldsChange(checked, "is_admin")
                          }
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">
                        Brand
                        <sup>
                          <span
                            className="text-danger"
                            style={{ fontSize: "1.1rem" }}
                          >
                            *
                          </span>
                        </sup>
                      </div>
                      <div className="col-3">
                        <Select
                          showSearch
                          className="w-100"
                          placeholder="Select Brand"
                          optionFilterProp="children"
                          name="brand_id"
                          value={fields.brand_id ? fields.brand_id : null}
                          onChange={val => {
                            handleFieldsChange(val, "brand_id");
                          }}
                          onBlur={() =>
                            validator.current.showMessageFor("brand_id")
                          }
                          autoComplete="none"
                          listHeight={120}
                          listItemHeight={4}
                        >
                          {brandList &&
                            brandList?.map(data => {
                              return (
                                <Option value={data.brand_id}>
                                  {data.brand_title}
                                </Option>
                              );
                            })}
                        </Select>
                        {validator.current.message(
                          "brand",
                          fields.brand_id,
                          "required|text",
                          { className: "text-danger py-1" }
                        )}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-3 text-end">Zone Tags</div>
                      <div className="col-8 border-bottom zone-tag-scroll-div">
                        <div className="row border border-gray rounded py-1">
                          <div className="col-4">
                            <b>Zones</b>
                          </div>
                          <div className="col-4">
                            {" "}
                            <b>Assign</b>
                          </div>
                          <div className="col-4">
                            <b>Always get Msgs</b>
                          </div>
                        </div>
                        {filteredZone &&
                          filteredZone?.map((data, idx) => (
                            <div className="row border-start border-end">
                              <div className="col-4">
                                <div className="my-3">{data?.zone_title}</div>
                              </div>
                              <div className="col-4 text-start pt-3">
                                <Checkbox
                                  className=" mx-3"
                                  name="assign"
                                  checked={data?.is_assigned}
                                  onChange={e =>
                                    handleCheckBox1Change(e, data, idx)
                                  }
                                />
                              </div>

                              <div className="col-4 text-start pt-3">
                                <Checkbox
                                  disabled={filteredZone[idx].is_assigned === true ? false : true}
                                  className=" mx-3"
                                  name="alwaysGetMags"
                                  checked={data.is_always_get_messages}
                                  onChange={e =>
                                    handleCheckBox2Change(e, data, idx)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}


                {fields?.user_type === "DELEGATE" || fields?.user_type === "SPEAKER" ?
                  <div className="row my-3">
                    <div className="col-3 text-end">
                      Company
                      <sup>
                        <span
                          className="text-danger"
                          style={{ fontSize: "1.1rem" }}
                        >
                          *
                        </span>
                      </sup>
                    </div>
                    <div className="col-9">
                      <Input
                        name="company"
                        id="company"
                        className="w-25 border-gray rounded"
                        placeholder="Enter Company Name"
                        value={fields.company}
                        onChange={e =>
                          handleFieldsChange(e.target.value, "company")
                        }
                        onBlur={() => validator.current.showMessageFor("company")}
                        autoComplete="none"
                      />
                      {validator.current.message(
                        "Company",
                        fields.company,
                        "required|text",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                  : ""
                }

                <div className="row my-3">
                  <div className="col-3 text-end">
                    Tier
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      className="w-25"
                      placeholder="Select Tier"
                      optionFilterProp="children"
                      value={fields.tier_type_id}
                      onChange={val => handleFieldsChange(val, "tier_type_id")}
                      onBlur={() =>
                        validator.current.showMessageFor("tier_type_id")
                      }
                      autoComplete="none"
                    >
                      {tierList &&
                        tierList?.map(data => {
                          return (
                            <Option value={data.tier_id}>{data.tier}</Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "tiers",
                      fields.tier_type_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Bio
                  </div>
                  <div className="col-9">
                    <TextArea
                      rows={6}
                      className="w-50"
                      name="bio"
                      placeholder="Enter a Bio here"
                      value={fields.bio}
                      onChange={e => handleFieldsChange(e.target.value, "bio")}
                      onBlur={() => validator.current.showMessageFor("bio")}
                      autoComplete="none"
                    />
                  </div>
                </div>
                <div className="row border-bottom-gray pb-4">
                  <div className="col-3"></div>
                  <div className="col-9">
                    <Button
                      className={
                        "px-3 fs-pink border-pink rounded btn-svg" +
                        (Tablet ? " mt-2" : " mx-2")
                      }
                    >
                      <CalenderRed /> <span className="mx-2">View Calendar</span>
                    </Button>
                    <Button
                      loading={loadingReset}
                      onClick={() => resetPass()}
                      className="px-3 fs-pink border-pink rounded btn-svg"
                    >
                      <PadLock /> <span className="mx-2">Reset Password</span>
                    </Button>
                    <br />
                    <br />
                    <span className="">
                      NB: This will send an email to the users current email
                      address to create a new password.
                    </span>
                  </div>
                </div>
                <section>
                  <div className="row">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        External & Social Links
                      </h6>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-12 mt-1">
                      <span className="fs-16">
                        NB: If you paste your details into these boxes then anyone
                        using this site will be able to view/contact you via these
                        platforms.
                      </span>
                    </div>
                  </div>

                  <div className="social-media-div border-bottom-gray pb-4 mb-4">
                    {socialMediaList &&
                      socialMediaList.map((data, idx) => (
                        <div className="row mt-4 ">
                          <div className="col-2 text-end ">
                            {data?.social_media}
                          </div>
                          <div className="col-1 text-center ">
                            <Switch
                              checked={
                                fields?.external_social_links_details?.some(
                                  social_media =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                ) &&
                                  fields?.external_social_links_details[
                                  fields.external_social_links_details.findIndex(
                                    social_media =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                  ]["social_media_url"] !== null
                                  ? true
                                  : false
                              }
                              onChange={checked =>
                                onHandleSocialSwitchChange(checked, idx)
                              }
                            />
                          </div>
                          <div className="col-4 text-start ">
                            <Input
                              placeholder="https://webaddress.com"
                              className="w-100"
                              disabled={
                                fields?.external_social_links_details?.some(
                                  social_media =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? false
                                  : true
                              }
                              value={
                                fields?.external_social_links_details?.some(
                                  social_media =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? fields.external_social_links_details[
                                  fields.external_social_links_details.findIndex(
                                    social_media =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                  ]["social_media_url"]
                                  : ""
                              }
                              onChange={event =>
                                socialMediaHandleChange(event, idx)
                              }
                              onBlur={() => validator.current.showMessageFor("social_media")}
                            />

                            {fields?.external_social_links_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            ) &&
                              fields?.external_social_links_details[
                              fields.external_social_links_details.findIndex(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                              ]["social_media_url"] !== null && validator.current.message(
                                "social_media",
                                fields?.external_social_links_details?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? fields?.external_social_links_details[
                                  fields.external_social_links_details.findIndex(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                  ]["social_media_url"]
                                  : ""
                                ,
                                "required|url",
                                { className: "text-danger py-1" }
                              )}
                          </div>
                        </div>
                      ))}
                  </div>
                </section>
              </div>
              <div
                className="shadow-sm p-3 d-flex justify-content-between"
                style={{ backgroundColor: "#eeeeee" }}
              >
                <Link to="/usermanagement">
                  <Button className="px-3 d-block button-pink rounded">
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="primary"
                  className="px-3 d-block rounded"
                  htmlType="submit"
                >
                  {!loading ? (
                    "Edit User"
                  ) : (
                    <div style={{ width: "80px" }}>
                      <Spin indicator={loadingIcon} style={{ color: "white" }} />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
};

export default EditUser;
