import React, { useState, useRef, useEffect } from "react";
import "./ProductZoneChat.css";
import { AddGroup } from "../../../components/svgJS/AddGroup";

import {
    Modal,
    Row,
    Col,
    TimePicker,
    Input,
    Select,
    Switch,
    Button,
    Form,
    DatePicker,
} from "antd";

import moment from "moment";

import { CloseOutlined } from "@ant-design/icons";

import SimpleReactValidator from "simple-react-validator";
import { ApiCall } from "../../../utils/api";
import { getAgents } from "../../../utils/commonApis";
import CommonLoader from "../../../components/Widgets/CommonLoader";

const ProductZoneChat = (props) => {
    console.log("props chat staffing", props);
    const { chat_configuration_id } = props;
    console.log("chat_configuration_id", chat_configuration_id);
    const staffingValidator = useRef(new SimpleReactValidator());
    const [staffingForceUpdate, setStaffingForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const format = "HH:mm";

    const [staffing_fields, setStaffing_Fields] = useState();

    const [staffingStartDateTime, setStaffingStartDateTime] = useState(
        staffing_fields?.start_date_time
    );

    const [isModalVisible, setIsModalVisible] = useState(true);

    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);

    const initializeZoneStaffingData = (data) => {
        setLoading(true);
        setStaffing_Fields({
            chat_configuration_id: data?.chat_configuration_id ?? null,
            brand_id: data?.brand_id ?? null,
            zone_id: data?.zone_id ?? null,
            start_date_time: data?.start_date_time ?? moment(new Date()),
            end_date_time: data?.end_date_time ?? moment(new Date()),
            is_update: true,
            product_area_chat_tagged_agents:
                data?.product_area_chat_tagged_agents?.map((elem) => elem?.user_id),
        });

        let userObj = data?.product_area_chat_tagged_agents.map((item) => {
            return {
                user_id: item?.user_id,
                user_name: item?.user?.first_name + " " + item?.user?.last_name,
            };
        });

        setSelectedAgents(userObj);
        setLoading(false);
    };

    useEffect(async () => {
        getAgentsList();
        await ApiCall("get", null, "/admin/zoneareastaff/details/get", {
            chat_configuration_id: props.data.chat_configuration_id,
        })
            .then((response) => {
                let data =
                    response?.data?.data?.details
                        ?.product_area_chat_configuration_details[0];
                console.log("rrrrrresponseData", data);

                initializeZoneStaffingData(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [props]);

    const { Option } = Select;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getAgentsList = () => {
        ApiCall("get", null, "/admin/user/get", {
            event_id: props.extraProps.eventId,
        })
            .then((response) => {
                setAgents(response?.data?.data?.rows);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleStaffingSubmit = async () => {
        // document.location.reload();
        const finalSelectedAgents = selectedAgents?.map((item) => item.user_id);
        staffing_fields.product_area_chat_tagged_agents = finalSelectedAgents;
        staffing_fields.brand_id = props.extraProps.brandId;
        staffing_fields.zone_id = props.extraProps.zoneId;
        staffing_fields.is_update = props.data.chat_configuration_id ? true : false;
        console.log("finalDataToSend", staffing_fields);
        let body = staffing_fields;
        if (staffingValidator.current.allValid()) {
            // alert("You submitted the form and stuff!");
            if (props.data.chat_configuration_id) {
                await ApiCall("PUT", body, "/admin/zoneareastaff/update", null)
                    .then((response) => {
                        console.log("finalResp", response);
                        // props.extraProps.visibleChange()
                        props.onSave();
                        // props.extraProps.visibleChange();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                await ApiCall("POST", body, "/admin/zoneareastaff/create", null)
                    .then((response) => {
                        console.log("finalResp", response);
                        props.onSave();
                        // props.extraProps.visibleChange()
                        // props.extraProps.getAppointmentDetails();
                        // props.extraProps.visibleChange();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        } else {
            staffingValidator.current.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            setStaffingForceUpdate(!staffingForceUpdate);
            // props.extraProps.visibleChange()
            props.extraProps.getAppointmentDetails();
        }
    };

    const handleStaffingFieldsChange = (val, name) => {
        console.log({ ...staffing_fields, [name]: val });
        setStaffing_Fields({ ...staffing_fields, [name]: val });
    };

    function handleAgentsChange(value) {
        // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

        let agents_list = agents;
        let selectedAgents_list = selectedAgents || [];

        let index = agents_list.findIndex((agent) => agent.user_id === value);
        if (selectedAgents_list) {
            selectedAgents_list.push(agents_list[index]);
            agents_list.splice(index, 1);

            setSelectedAgents([...selectedAgents_list]);
            setAgents([...agents_list]);
            console.log("selectedAgents list12", selectedAgents);
        }
    }

    function handleSelectedAgentsChange(value) {
        // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

        let agents_list = agents;
        let selectedAgents_list = selectedAgents;

        let index = selectedAgents_list.findIndex(
            (agent) => agent.user_id === value
        );

        agents_list.push(selectedAgents_list[index]);
        selectedAgents_list.splice(index, 1);

        setAgents([...agents_list]);
        setSelectedAgents([...selectedAgents_list]);

        // console.log("selectedAgents list34", selectedAgents);
    }

    if (loading) {
        return <CommonLoader />
    } else
        return (
            <>
                {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
                {/* <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      > */}

                {console.log("staffingFields", staffing_fields)}
                <div className="pb-4">
                    <h4 className="text-center">Product/area chat staffing</h4>
                    <p className="text-center fs-16 fs-pink">
                        Zone name (pulled in from zone editor)
                    </p>
                    <Row>
                        <Col
                            span={8}
                            className="d-flex align-items-center justify-content-center"
                        >
                            <span className="fs-16">
                                Start Date & Time <span style={{ color: "#EF0855" }}>*</span>
                            </span>
                        </Col>
                        <Col span={14}>
                            {console.log(staffing_fields?.start_date_time)}
                            <DatePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                name="staffing_start_date_time"
                                format={"D/M/YYYY HH:mm"}
                                placeholder="Select Date & Time"
                                className="w-100 date_time"
                                showTime
                                value={moment(staffing_fields?.start_date_time)}
                                // value={appointmentDetails && appointmentDetails?.start_date_time}
                                onChange={(val) => {
                                    if (val) {
                                        handleStaffingFieldsChange(val, "start_date_time");
                                    }
                                    // setStaffingStartDateTime(val.format("YYYY-MM-DD HH:mm"));
                                }}
                                onBlur={() =>
                                    staffingValidator.current.showMessageFor(
                                        "staffing_start_date_time"
                                    )
                                }
                            />
                            {staffingValidator.current.message(
                                "",
                                staffing_fields?.start_date_time,
                                "required",
                                {
                                    className: "text-danger py-1",
                                }
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col
                            className="d-flex align-items-center justify-content-center"
                            span={8}
                        >
                            <span className="fs-16">
                                End Date & Time <span style={{ color: "#EF0855" }}>*</span>
                            </span>
                        </Col>
                        <Col span={14}>
                            <DatePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                name="staffing_end_date_time"
                                disabledDate={(d) =>
                                    !d || d.isSameOrBefore(staffing_fields?.start_date_time)
                                }
                                disabledTime={(d) =>
                                    !d || d.isSameOrBefore(staffing_fields?.start_date_time)
                                }
                                format={"D/M/YYYY HH:mm"}
                                placeholder="Select Date & Time"
                                className="w-100 date_time"
                                showTime
                                value={moment(staffing_fields?.end_date_time)}
                                onChange={(val) => {
                                    if (val) {
                                        handleStaffingFieldsChange(val, "end_date_time");
                                    }
                                }}
                                onBlur={() =>
                                    staffingValidator.current.showMessageFor(
                                        "staffing_end_date_time"
                                    )
                                }
                            />
                            {staffingValidator.current.message(
                                "",
                                staffing_fields?.end_date_time,
                                "required",
                                {
                                    className: "text-danger py-1",
                                }
                            )}
                        </Col>
                    </Row>
                    {/* <Row className="mt-3">
          <Col className="d-flex  justify-content-center" span={8}>
            <span className="fs-16">
              Tagged Agents &nbsp; &nbsp;
              <span style={{ color: "#EF0855" }}>*</span>
            </span>
          </Col>
          <Col span={14}>
            <div className="w-100 d-flex align-items-center justify-content-between px-2 tagged_agents">
              <span className="fs-pink">Addilynn Dodge</span>
              <CloseOutlined className="fs-pink fs-10" />
            </div>

            <div className="w-100 mt-1 d-flex align-items-center justify-content-between px-2 tagged_agents">
              <span className="fs-pink">Monroe Bond</span>
              <CloseOutlined className="fs-pink fs-10" />
            </div>
          </Col>
        </Row> */}

                    {/* <Row className="mt-3">
          <Col className="d-flex  justify-content-center" span={8}></Col>
          <Col span={14}>
            <div
              id="staffing_area"
              style={{ position: "relative" }}
              className="w-100 staffing_select_container"
            >
              <Select
                getPopupContainer={() =>
                  document.getElementById("staffing_area")
                }
                showSearch
                showArrow={false}
                open={true}
                placeholder=""
                className="w-100 staffing_select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option>
                <Option value="Andrew">Andrew</Option>
              </Select>
            </div>
          </Col>
        </Row> */}

                    {/* <Row className="mt-3">
          <Col span={8}></Col>
          <Col span={14}>
            <Button className="px-3 fs-pink  fs-18 w-50 p-2 border-pink rounded btn-svg staffing_inviteAgents">
              <AddGroup fill="#ef0855" />
              <span className="mx-2">Invite Agents</span>
            </Button>
            <br />
            ,
          </Col>
        </Row> */}

                    <section>
                        <div className="row mt-4 event-editor-dropdown-div">
                            <div className="col-3 text-center mt-4">
                                <span className="text-secondary mt-4">Agents</span>
                            </div>

                            <div
                                className={false ? "col-5" : "col-4"}
                                id="area"
                                style={{ position: "relative" }}
                            >
                                <label className="py-2 mx-2">
                                    <b>All Agents</b>
                                </label>
                                <br />
                                <Select
                                    getPopupContainer={() => document.getElementById("area")}
                                    showSearch
                                    dropdownClassName="agent-dropdown"
                                    style={{ width: 220 }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    value="All Agents"
                                    onChange={handleAgentsChange}
                                    open={true}
                                    listItemHeight={4}
                                    listHeight={130}
                                    className="border border-gray fs-bold-14"
                                >
                                    {agents?.map((data) => (
                                        <Option value={data.user_id}>
                                            {data.first_name} {data.last_name}
                                        </Option>
                                    ))}
                                    {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                                </Select>
                                {/* {isEmpty(selectedAgents) &&
                      errors.zone_agents &&
                      touched.zone_agents ? (
                        <div className="text-danger agents-error-margin-top">
                          {errors.zone_agents}
                        </div>
                      ) : null} */}
                            </div>

                            <div
                                className={false ? "col-5" : "col-4"}
                                id="area1"
                                style={{ position: "relative" }}
                            >
                                <label className="py-2 mx-2">
                                    <b>Tagged Agents</b>
                                </label>
                                <br />
                                {/* <div className="event-editor-dropdown-background-gray rounded w-75 p-2"> */}
                                <Select
                                    getPopupContainer={() => document.getElementById("area1")}
                                    showSearch
                                    dropdownClassName="agent-dropdown"
                                    style={{ width: 220 }}
                                    placeholder="Search Agents"
                                    optionFilterProp="children"
                                    value="Tagged Agents"
                                    // onChange={handleAgentsChange}
                                    open={true}
                                    listItemHeight={4}
                                    listHeight={130}
                                    className="border border-gray fs-bold-14"
                                >
                                    {selectedAgents?.map((data) => (
                                        <Option value={data.user_id}>
                                            {data.user_name}
                                            <span className="float-end">
                                                <CloseOutlined
                                                    className="float-right text-danger mr-0"
                                                    onClick={() =>
                                                        handleSelectedAgentsChange(data?.user_id)
                                                    }
                                                />
                                            </span>
                                        </Option>
                                    ))}
                                    {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                                </Select>

                                {/* <Input className="w-100 my-1" />
                      <List
                        className="event-editor-tagged-agents-div"
                        itemLayout="horizontal"
                        dataSource={selectedAgents}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <span className="d-flex justify-content-between bg-white m-0 p-2 text-danger ">
                                  <span>
                                    {item.first_name} {item.last_name}
                                  </span>
                                  <CloseOutlined
                                    className="float-right"
                                    onClick={() =>
                                      handleSelectedAgentsChange(item.user_id)
                                    }
                                  />
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      /> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className=" d-flex justify-content-between mx-5">
                            <Button
                                className="w-25 mt-4 fs-16 rounded staffing_save_btn"
                                key="cancel"
                                type="primary"
                                // htmlType="submit"
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="w-25 mt-4 fs-16 rounded staffing_save_btn"
                                key="saveZoneChat"
                                type="primary"
                                htmlType="submit"
                                onClick={handleStaffingSubmit}
                            >
                                Save
                            </Button>
                        </div>
                    </section>
                </div>
                {/* </Modal> */}
            </>
        );
};

export default ProductZoneChat;
