export const data = [
    {
      key: "1",
      zoneid: "#041254121",
      createdat: "12-12-21 10:251",
      zonetitle: "Super Admin",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "2",
      zoneid: "#041254215",
      createdat: "12-12-21 10:251",
      zonetitle: "Super Admin",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Inactive",
    },
    {
      key: "3",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "4",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Inactive",
    },
    {
      key: "5",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "6",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "7",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Inactive",
    },
    {
      key: "8",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "9",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Inactive",
    },
    {
      key: "10",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "11",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "12",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "13",
      zoneid: "#041254121",
      createdat: "12-12-21 10:251",
      zonetitle: "Super Admin",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "14",
      zoneid: "#041254215",
      createdat: "12-12-21 10:251",
      zonetitle: "Super Admin",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "15",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "16",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "17",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "18",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "19",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "20",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "21",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "22",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "23",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
    {
      key: "24",
      zoneid: "#041254125",
      createdat: "12-12-21 10:251",
      zonetitle: "Customer Name",
      brands: "Apple",
      locationonbooth:"Wall c",
      assets: "5",
      status: "Active",
    },
  ];