import React, { useEffect, useState } from "react";
import "./index.css";
import { Breadcrumb, Button, Empty, Modal, Result, Select, Switch } from "antd";
import { Input } from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  ExclamationOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { User } from "../../components/svgJS/User";
import { SendRed } from "../../components/svgJS/SendRed";
import { Video } from "../../components/svgJS/Video";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { CometChatUI } from "../../cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { getEvents } from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import MyProfile from "../MyProfile/MyProfile";

const Messages = () => {
  var CryptoJS = require("crypto-js");
  const { Option } = Select;
  const [isActive, setActive] = useState({ key: 0, show: true });
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [eventList, setEventList] = useState([]);
  const [currentEventID, setCurrentEventID] = useState();
  const [subScribedChannels, setSubScribedChannels] = useState([]);
  const [currentChannelParentData, setCurrentChannelParentData] = useState({});
  const [currentChannelData, setCurrentChannelData] = useState({});
  const [loader, setLoader] = useState(false);
  const [userType, setUserType] = useState("");
  const [insideLoader, setInsideLoader] = useState(false);
  const [userProfileModal, setUserProfileModal] = useState({
    isOpen: false,
    otherUser: { user_id: "" },
  });

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          // CometChat.init()
          // CometChat.launch({
          //   widgetID: "10b7383d-e5ca-408e-b1d8-35723ed59223",
          //   target: "#cometchat",
          //   roundedCorners: "true",
          //   height: "600px",
          //   width: "800px",
          //   defaultID: user.getUid(), //'superhero1', //default UID (user) or GUID (group) to show,
          //   defaultType: "user", //user or group
          // });
          console.log("CometChat Login Successful:", response);
          setCometChatUser({
            isValidUser: true,
            data: response,
            uid: response.getUid(),
          });
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }

    const eventsData = await getEvents();
    console.log("eventsData = ", eventsData);
    setEventList(eventsData);
    if (
      decryptedData &&
      (decryptedData === "BRAND ADMIN" ||
        decryptedData === "AGENT" ||
        decryptedData === "DELEGATE") &&
      localStorage.getItem("event_id")
    ) {
      const event_id = localStorage.getItem("event_id");
      console.log("event_id from local storage ", event_id);
      setCurrentEventID(event_id);
    }
  }, []);

  useEffect(async () => {
    console.log("change event id = ", currentEventID);
    if (currentEventID) {
      setLoader(true);
      const responseChannels = await ApiCall(
        "GET",
        "",
        "/admin/channel/get",
        ""
      );

      console.log("responseChannels = ", responseChannels);

      if (responseChannels?.data?.data) {
        setLoader(false);
        const channelsHere = responseChannels.data.data;
        if (channelsHere && !isEmpty(channelsHere)) {
          setSubScribedChannels(channelsHere);
        }
        // else dataNotFound();
      } else dataNotFound();
    }
  }, [currentEventID]);

  function dataNotFound() {
    toast.error("Event data not found");
    setLoader(false);
  }

  async function respondAPI(ele) {
    setInsideLoader(true);
    const response = await ApiCall(
      "POST",
      {
        message_id: ele.message_id,
        user_id: ele.user_id,
        message_text: ele.message_text,
      },
      "/admin/channel/message/respond",
      ""
    );
    console.log("response = ", response);
    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      setActive({ key: 2, show: true });
      fetchChannelDetailsAPI(currentChannelParentData.channel_id);
    } else {
      toast.error(response.data.message);
      setInsideLoader(false);
    }
  }

  async function fetchChannelDetailsAPI(channel_id) {
    console.log("channel_id = ", channel_id);
    setInsideLoader(true);
    const response = await ApiCall(
      "GET",
      "",
      `/admin/channel/detail/get?channel_id=${channel_id}`,
      ""
    );
    console.log("response fetchChannelDetailsAPI = ", response);
    if (response?.data?.code === 200) {
      if (response?.data?.message) {
        setCurrentChannelData(response.data.data);
      }
      setInsideLoader(false);
    } else {
      setInsideLoader(false);
    }
  }

  async function acceptVideoAPI(ele) {
    setInsideLoader(true);
    const response = await ApiCall(
      "POST",
      { call_request_id: ele.call_request_id },
      "/admin/channel/videocall/respond",
      ""
    );
    console.log("response = ", response);
    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      fetchChannelDetailsAPI(currentChannelParentData.channel_id);
      setTimeout(() => {
        window.open(ele.video_call_link, "_blank").focus();
      }, 2000);
    } else {
      toast.error(response.data.message);
      setInsideLoader(false);
    }
  }

  const renderZoneDellReception = (currentChannelData) => {
    console.log("currentChannelData = ", currentChannelData);
    if (insideLoader) return <CommonLoader />;
    if (currentChannelData && currentChannelParentData)
      return (
        <>
          <div className="d-flex w-100 p-3 justify-content-between border-bottom-gray">
            <div className="w-50">
              <h5>{currentChannelParentData.channel}</h5>
            </div>
            <div className="d-flex w-50 justify-content-end">
              <Button className="messages_unsubscribeBtn d-flex justify-content-evenly align-items-center">
                <CloseOutlined />
                Unsubscribe
              </Button>
              <Button className="messages_boothBtn ms-2 d-flex justify-content-evenly align-items-center">
                <BoothRed />
                Visit Booth
              </Button>
            </div>
          </div>
          <div className="messages_content p-3 w-100">
            {currentChannelData &&
              currentChannelData.messageRequests &&
              currentChannelData.messageRequests.map((ele) => (
                <div
                  key={"message_request_" + ele.message_id}
                  className="messages_card mb-3 d-flex"
                >
                  <div className="w-50 bg-grey p-2 border rounded">
                    <h6 className="fs-pink">
                      {ele.first_name + " " + ele.last_name}
                    </h6>
                    <p>{ele.message_text}</p>
                  </div>

                  <div className="my-auto ms-2">
                    <Button
                      onClick={() => {
                        console.log("ele.user_id = ", ele.user_id);
                        setUserProfileModal({
                          isOpen: true,
                          otherUser: { user_id: ele.user_id },
                        });
                      }}
                      className="messages_profileBtn d-flex justify-content-evenly align-items-center w-100"
                    >
                      <User />
                      <span style={{ paddingLeft: "5px" }}>Profile</span>
                    </Button>
                    <Button
                      onClick={() => respondAPI(ele)}
                      className="messages_respondBtn mt-2 d-flex justify-content-evenly align-items-center w-100"
                    >
                      <SendRed />
                      <span style={{ paddingLeft: "5px" }}>Respond</span>
                    </Button>
                  </div>
                </div>
              ))}

            {currentChannelData &&
              currentChannelData.videoRequests &&
              currentChannelData.videoRequests.map((ele) => (
                <div
                  key={"call_request_" + ele.call_request_id}
                  className="d-flex mt-3 w-100"
                >
                  <div className="d-flex p-2 border border-danger rounded w-50">
                    <div className="messages_warning_icon  rounded-circle  border border-danger p-1 d-flex justify-content-center align-items-center">
                      <ExclamationOutlined />
                    </div>
                    <span className="fs-pink ms-2">
                      {ele.first_name + " " + ele.last_name} has requested a
                      video call.
                    </span>
                  </div>
                  <Button
                    className="videoBtn my-auto ms-2 d-flex justify-content-evenly align-items-center"
                    onClick={() => acceptVideoAPI(ele)}
                  >
                    <Video />
                    Accept
                  </Button>
                </div>
              ))}
          </div>
        </>
      );
  };

  const renderIndividualMessage = () => {
    return (
      <div id="cometchat" style={{ width: "100%", height: "100%" }}>
        {cometChatUser.isValidUser ? (
          <CometChatUI
            extraProps={{
              themeColor: "#c1a51e",
              singleChatScreenProps: {
                header: (
                  <div>
                    <div>Profile</div>
                    <div>Video Call</div>
                    <div>Visit Booth</div>
                  </div>
                ),
              },
            }}
          />
        ) : null}
      </div>
    );
  };

  function clearProfileModal() {
    setUserProfileModal({ isOpen: false, otherUser: { user_id: "" } });
  }

  if (loader) return <CommonLoader />;
  else
    return (
      <>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24">Messages</h4>
            <Breadcrumb className="my-2" separator=">"></Breadcrumb>
          </div>

          {userType === "CUSTOMER" ? (
            <div style={{ width: "20%" }}>
              <div className={"col-9"} style={{ display: "flex" }}>
                <div className={"col-6"}>
                  <span>Event Name</span>
                </div>

                <div className={"col-9"}>
                  <Select
                    showSearch
                    placeholder="Select Event"
                    className="w-100 fs-bold-14"
                    value={currentEventID}
                    onChange={(val) => setCurrentEventID(val)}
                    optionFilterProp="children"
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {!isEmpty(eventList) &&
                      eventList.map((data, index) => {
                        return (
                          <Option key={"event_" + index} value={data.event_id}>
                            {data.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {!currentEventID &&
        userType !== "SUPER ADMIN" &&
        userType !== "BRAND ADMIN" &&
        userType !== "DELEGATE" ? (
          <Result
            icon={<SmileOutlined />}
            title="Select an event to display messages"
          />
        ) : (
          <div className="messages_container d-flex w-100 mt-2">
            {userProfileModal.isOpen ? (
              <Modal
                title="User Profile"
                visible={true}
                onOk={() => clearProfileModal()}
                onCancel={() => clearProfileModal()}
                className="user_profile_modal"
              >
                <MyProfile otherUser={userProfileModal.otherUser} />
              </Modal>
            ) : null}
            {userType === "CUSTOMER" || userType === "AGENT" ? (
              <>
                <div className="messages_sidebar">
                  <div className="messages_search_wrapper mt-2 mx-auto">
                    <Input
                      size="medium"
                      className="w-100 rounded fs-bold-14"
                      // onChange={(e) => searchSessions(e)}
                      allowClear
                      placeholder="Search Channels"
                      style={{ width: "70%" }}
                      prefix={<SearchOutlined />}
                    />
                  </div>
                  <div className="mt-4 ">
                    <h6 className="fs-pink mb-3 px-4 fs-12">
                      SUBSCRIBED CHANNELS
                    </h6>
                    {subScribedChannels ? (
                      subScribedChannels.map((ele) => (
                        <div
                          className={
                            isActive.key === 1 && isActive.show
                              ? "activeBtn messages_subscribe mb-2 d-flex justify-content-between px-4 py-2"
                              : "messages_subscribe mb-2 d-flex justify-content-between px-4 py-2"
                          }
                          onClick={() => {
                            setActive({ key: 1, show: true });
                            setCurrentChannelParentData(ele);
                            fetchChannelDetailsAPI(ele.channel_id);
                          }}
                        >
                          <div>
                            <h6 className="opacity-75 fs-16">{ele.channel}</h6>
                          </div>

                          {/* <div>
                        <div className="ms-2 mb-2 h-25">
                          <span className="messages_notifications_number">
                            7
                          </span>
                        </div>
                        <span className="opacity-50">13.11</span>
                      </div> */}
                        </div>
                      ))
                    ) : (
                      <Empty description="No Channels Found" />
                    )}
                  </div>

                  <div
                    className={
                      isActive.key === 3 && isActive.show
                        ? "activeBtn messages_users d-flex justify-content-between px-4"
                        : "messages_users d-flex justify-content-between px-4"
                    }
                    onClick={() => setActive({ key: 2, show: true })}
                  >
                    <h6 className="opacity-75 fs-16 fs-pink">
                      Individual messages
                    </h6>
                  </div>
                </div>
                <div className="w-100">
                  {isActive.key === 1
                    ? renderZoneDellReception(currentChannelData)
                    : isActive.key === 2
                    ? renderIndividualMessage()
                    : null}
                </div>
              </>
            ) : userType === "SUPER ADMIN" ||
              userType === "BRAND ADMIN" ||
              userType === "DELEGATE" ? (
              renderIndividualMessage()
            ) : null}
          </div>
        )}
      </>
    );
};

export default Messages;
