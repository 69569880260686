import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

const initialState = {
  navigationAndReceptionEventList: {},
  loading: false,
};

export const navigationAndReceptionEvent = createAsyncThunk(
  "navigationAndReceptionEvent/navigationAndReceptionEvent",

  async (body) => {
    console.log("params", body);
    const response = await ApiCallForm(
      "POST",
      body,
      "/admin/events/navigationplusreception/create",
      ""
    );
    console.log("create response navigationAndReceptionEvent", response);

    return response;
  }
);

export const navigationAndReceptionEventSlice = createSlice({
  name: "navigationAndReceptionEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [navigationAndReceptionEvent.pending]: (state) => {
      state.loading = true;
    },
    [navigationAndReceptionEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.navigationAndReceptionEventList = payload;
    },
    [navigationAndReceptionEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const navigationAndReceptionEventReducer =
  navigationAndReceptionEventSlice.reducer;
