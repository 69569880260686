import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Menu,
  Dropdown,
  message,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./index.css";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import {
  getBrand,
  changeBrandStatus,
} from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Filter } from "../../components/svgJS/Filter";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { ApiCall } from "../../utils/api";

const BrandManagement = () => {
  var CryptoJS = require("crypto-js");
  const [searchData, setsearchData] = useState("");
  const [userType, setUserType] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [tierList, setTierList] = useState();
  const [creatorName, setCreatorName] = useState();
  const [fields, setFields] = useState({
    customerName: null,
    eventName: null,
    creatorName: null,
    tier: null,
  });

  const { Option } = Select;

  useEffect(() => {
    dispatch(getBrand());
  }, []);

  useEffect(() => {
    // setLoadingBrand(true);

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    }
    window.scrollTo(0, 0);
    const searchObj = {
      search: searchData,
      customer_id: fields?.customerName,
      event_id: fields?.eventName,
      creatorName: fields?.creatorName,
      brand_tier: fields?.tier,
    };
    getCustomerList();
    getEventList();
    getTierList();

    dispatch(getBrand(searchObj));
    // setTimeout(() => {
    //   setLoadingBrand(false);
    // }, 2000);
  }, [searchData, fields?.eventName, fields?.customerName, fields?.tier]);

  const { rows } = useSelector((state) => state?.brandManagement?.data);

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        brand_id: elem.brand_id,
        brand: elem.brand_title,
        name: elem.customer.account_name,
        domain: elem.event_domain_name ?? "NA",
        tier: elem.tier?.tier ?? "NA",
        zones: elem.no_of_zones + "/" + elem.total_zone_count,
        assets: elem.no_of_assets_per_zone + "/" + elem.total_assets_count,
        agents: elem.no_of_agents + "/" + elem.total_agents_count,
        storage: elem.storage ?? "NA",
        streaming: elem.streaming ?? "NA",
        videoHours: elem.videoHours ?? "NA",
        contact: elem.customer.main_contact_name ?? "NA",
        createdBy:
          elem.brands_created_by.first_name +
            " " +
            elem.brands_created_by.last_name ?? "NA",
        created_at: elem.created_at,
        logos:
          elem?.logo_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            "Incomplete"
          ),
        gfx:
          elem.wall_graphics_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            "Incomplete"
          ),
        panos:
          elem.final_booth_url_status === true ? (
            <span className="fs-16" style={{ color: "#39e75f" }}>
              Complete
            </span>
          ) : (
            "Incomplete"
          ),
        is_active: elem.is_active,
      };
    });

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : null,
    })
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        setTierList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  const handleStatus = (id, bool) => {
    dispatch(
      changeBrandStatus({
        brand_id: id,
        is_active: !bool,
      })
    );
  };

  const columns = [
    {
      title: "Brand ID",
      dataIndex: "brand_id",
      key: "brand_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: false,
      width: 150,
      className: "p-4",
      ellipsis: {
        showTitle: false,
      },
      render: (brand) => (
        <Tooltip placement="topLeft" title={brand}>
          {brand}
        </Tooltip>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: false,
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: false,
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (domain) => (
        <Tooltip placement="topLeft" title={domain}>
          {domain}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <button
          className={
            record.is_active === true
              ? "bg-button-green active_btns "
              : "bg-button-red  inactive_btns"
          }
          onClick={() => handleStatus(record.brand_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      sorter: false,
      width: 100,
    },
    {
      title: "# Zones",
      dataIndex: "zones",
      key: "zones",
      sorter: false,
      width: 100,
    },
    {
      title: "# Assets",
      dataIndex: "assets",
      key: "assets",
      sorter: false,
      width: 100,
    },
    {
      title: "# Agents",
      dataIndex: "agents",
      key: "agents",
      sorter: false,
      width: 100,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sorter: false,
      width: 100,
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "streaming",
      sorter: false,
      width: 100,
    },
    {
      title: "Video Hours",
      dataIndex: "videoHours",
      key: "videoHours",
      sorter: false,
      width: 100,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      sorter: false,
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <Tooltip placement="topLeft" title={contact}>
          {contact}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: false,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (createdBy) => (
        <Tooltip placement="topLeft" title={createdBy}>
          {createdBy}
        </Tooltip>
      ),
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "Logos",
      dataIndex: "logos",
      key: "logos",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "GFX",
      dataIndex: "gfx",
      key: "gfx",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "Panos",
      dataIndex: "panos",
      key: "panos",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      align: "Center",
      render: (_, record) => (
        <Popover
          content={
            <div>
              <p className="options">
                <Link
                  className="options"
                  to={`viewbranddetails/${record.brand_id}`}
                >
                  <EyeOutlined /> <span className="">View Details</span>
                </Link>
              </p>
              <p className="options">
                <Link
                  className="options"
                  to={`editbranddetails/${record.brand_id}`}
                >
                  <EditOutlined /> <span className="">Edit Brand</span>
                </Link>
              </p>
              {userType && userType === "BRAND ADMIN" ? null : (
                <p className="options">
                  <DeleteOutlined /> <span className=""> Delete Brand </span>
                </p>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 60,
      render: (_, record) => (
        <div className="px-1">
          <Link to={`editbranddetails/${record.brand_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    dispatch(getBrand(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  console.log("fields in brand listing", fields);

  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24">Brand Management</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
              <Breadcrumb.Item>Brand Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div>
            <Link to="createnewbrand">
              {userType && userType === "BRAND ADMIN" ? null : (
                <Button className="button-pink rounded px-3 py-1 mt-2">
                  <span className="fw-normal"> + Add New Brand </span>
                </Button>
              )}
            </Link>
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="d-flex justify-content-between  mb-4">
            <div className="w-75">
              <Input
                size="medium"
                value={searchData}
                onChange={handleInputSearch}
                placeholder="Search by Cust. Name,Event Name,Brand Name,Creator Name,Zone Name,User Name"
                style={{ width: "70%" }}
                prefix={<SearchOutlined />}
                allowClear
              />
            </div>

            <div className="d-flex">
              <Button
                className=" rounded"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>
            </div>
          </div>
          <div className="mb-2">
            {showFilter ? (
              <div>
                <Select
                  name="customerName"
                  placeholder="Customer Name"
                  style={{
                    width: 280,
                  }}
                  onChange={(val) => handleFieldsChange(val, "customerName")}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {customerList?.map((data) => {
                    return (
                      <Option value={data.customer_id}>
                        {data.account_name}
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  name="eventName"
                  placeholder="Event Name"
                  className="ms-2"
                  style={{
                    width: 280,
                  }}
                  onChange={(val) => handleFieldsChange(val, "eventName")}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {eventList &&
                    eventList?.map((data) => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>

                <Select
                  name="creatorName"
                  placeholder="Creator Name"
                  className="ms-2"
                  onChange={(val) => handleFieldsChange(val, "creatorName")}
                  style={{
                    width: 280,
                  }}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  <option>dummy</option>
                </Select>

                <Select
                  name="tier"
                  placeholder="Tier"
                  className="ms-2"
                  onChange={(val) => handleFieldsChange(val, "tier")}
                  style={{
                    width: 280,
                  }}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {tierList &&
                    tierList?.map((data) => {
                      return <Option value={data.tier_id}>{data.tier}</Option>;
                    })}
                </Select>
              </div>
            ) : (
              ""
            )}
          </div>

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
            }}
            scroll={{
              x: 1300,
            }}
            bordered={false}
            showSizeChange={true}
            onChange={handleTableChange}
          />
        </div>
      </Fragment>
    );
};

export default BrandManagement;
