import React, { useEffect, useState } from "react";
import "./index.css";

import { Select, Tabs } from "antd";

import { Layout, Menu, Breadcrumb, Button, Input, Row, Col } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { Menu1 } from "../../components/svgJS/Menu1";
import { Video } from "../../components/svgJS/Video";
import { SendRed } from "../../components/svgJS/SendRed";
import { SmileRed } from "../../components/svgJS/SmileRed";
import { Headphone } from "../../components/svgJS/Headphone";
import { Lifesaver } from "../../components/svgJS/Lifesaver";
import { VideoBlack } from "../../components/svgJS/VideoBlack";
import TutorialVideo from "./TutorialVideo";
import EventSupport from "./EventSupport";
import TechnicalSupport from "./TechnicalSupport";

const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;

const HelpAndSupport = () => {
  return (
    <>
      <h4 className="mb-0">Help & Support</h4>
      {/* <span className="opacity-75">Help & Support</span> */}
      <div className="card-container mt-4">
        <Tabs type="card">
          <TabPane
            tab={
              <>
                <span className="mx-2">Tutorial Video</span>
                <VideoBlack />
              </>
            }
            key="1"
          >
            <TutorialVideo />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Event Support</span>
                <Headphone />
              </>
            }
            key="2"
          >
            <EventSupport />
          </TabPane>
          <TabPane
            tab={
              <>
                <span className="mx-2">Technical Support</span>
                <Lifesaver />
              </>
            }
            key="3"
          >
            <TechnicalSupport />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default HelpAndSupport;
