import _ from "lodash";
import moment from "moment";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
export async function mapSelectedDdlData(
  idsFromAPIs,
  selectedIds,
  clonedData,
  keyToCheck
) {
  return new Promise(function (resolve, reject) {
    if (idsFromAPIs) {
      let mainList = clonedData;
      let selectedList = selectedIds;
      idsFromAPIs.map((val) => {
        clonedData &&
          clonedData.forEach((ele) => {
            if (ele[keyToCheck] === val) {
              let index = mainList.findIndex((ele) => ele[keyToCheck] === val);
              selectedList.push(mainList[index]);
              mainList.splice(index, 1);
            }
          });
      });
      selectedList = _.uniqWith(selectedList, _.isEqual);
      resolve({ mainList, selectedList });
    } else {
      reject({ mainList: clonedData, selectedList: [] });
    }
  });
}

export function mapTickerSchema(tickerAPIData) {
  return {
    event_ticker_id: tickerAPIData ? tickerAPIData.event_ticker_id : null,
    event_id: tickerAPIData ? tickerAPIData.event_ticker_id : null,
    ticker_details: {
      ticker_id:
        tickerAPIData && tickerAPIData.ticker_details
          ? tickerAPIData.ticker_details.ticker_id
          : null,
      ticker_name:
        tickerAPIData && tickerAPIData.ticker_details
          ? tickerAPIData.ticker_details.ticker_name
          : null,
      ticker_fixed_title:
        tickerAPIData && tickerAPIData.ticker_details
          ? tickerAPIData.ticker_details.ticker_fixed_title
          : null,
      ticker_description:
        tickerAPIData && tickerAPIData.ticker_details
          ? tickerAPIData.ticker_details.ticker_description
          : null,

      start_time:
        tickerAPIData && tickerAPIData.ticker_details
          ? moment(tickerAPIData.ticker_details.start_time, "HH:mm")
          : moment(moment().format("HH:mm"), TIME_FORMAT),
      end_time:
        tickerAPIData && tickerAPIData.ticker_details
          ? moment(tickerAPIData.ticker_details.end_time, "HH:mm")
          : moment(moment().format("HH:mm"), TIME_FORMAT),
    },
    ticker_link_details: {
      ticker_linking_id:
        tickerAPIData && tickerAPIData.ticker_link_details
          ? tickerAPIData.ticker_link_details.ticker_linking_id
          : null,
      brand_id:
        tickerAPIData && tickerAPIData.ticker_link_details
          ? tickerAPIData.ticker_link_details.brand_id
          : null,
      reference_type:
        tickerAPIData && tickerAPIData.ticker_link_details
          ? tickerAPIData.ticker_link_details.reference_type
          : null,
      reference_id:
        tickerAPIData && tickerAPIData.ticker_link_details
          ? tickerAPIData.ticker_link_details.reference_id
          : null,
    },
  };
}
