import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Select,
  Switch,
  Button,
  Breadcrumb,
  Input,
  DatePicker,
  Modal
} from "antd";
import "./index.css";
import { Spin, Empty } from "antd";
import {
  LoadingOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";
import { Download } from "../../components/svgJS/Download";
import { addNewBrand } from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { EyeView } from "../../components/svgJS/EyeView";
import FileUpload from "../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import { getBrandDropdowns } from "../../utils/commonApis";
import ExpoHalls from "../ExpoMap/ExpoHalls";
import { chunkFileUpload } from "./../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";

const CreateNewBrand = props => {
  var CryptoJS = require("crypto-js");

  const validator = useRef(new SimpleReactValidator());
  // validation states
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");

  // Add User States
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [allAgentsList, setAllAgentsList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [brandLogoFileState, setBrandLogoFileState] = useState(null);
  const [wallGraphicsFileState, setWallGraphicsFileState] = useState(null);
  const [wallGraphicsFileUrl, setWallGraphicsFileUrl] = useState(null);
  const [wallGraphicsFileLoader, setWallGraphicsFileLoader] = useState(null);
  const [finalBoothFileState, setFinalBoothFileState] = useState(null);
  const [finalBoothFileUrl, setFinalBoothFileUrl] = useState(null);
  const [finalBoothFileLoader, setFinalBoothFileLoader] = useState(null);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandLogo, setBrandLogo] = useState()

  // ExpoHall States
  const [expoHallModalVisible, setExpoHallModalVisible] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [expoHallDetails, setExpoHallDetails] = useState([]);

  const [fields, setFields] = useState({
    is_active: true,
    customer_account: props?.customer_id ?? null,
    event_id: props?.event_id ?? null,
    brand_name: "",
    brand_description: "",
    brand_tier: null,
    brand_color: "#ef0855",
    // New add in Schema
    default_button_background_color: "#ffffff",
    is_default_button_gradient_active: false,
    default_button_gradient_angle: 45,
    default_button_background_second_color: null,
    default_button_text_color: "#ef0855",
    highlight_button_background_color: "#ef0855",
    is_highlight_button_gradient_active: false,
    highlight_button_gradient_angle: 60,
    highlight_button_background_second_color: "#213cc4",
    highlight_button_text_color: "#ffffff",
    location_id: "",
    default_zone_id: null,
    brand_agent_details: selectedAgents,
    brand_website: "",
    brand_social_media_details: [],
    expo_hall_configuration_id: "",
    sequence: ""
  });

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const { loading } = useSelector(state => state.brandManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const [chunkSize, setChunkSize] = useState(1024 * 1024 * 5);
  const [fileKey, setFileKey] = useState("");

  useEffect(() => { }, [agents]);

  useEffect(() => {
    setLoadingBrand(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);


    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });
    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }


    getCustomerList();
    getTierList();
    getZoneList();
    getSocialMediaList();
    setFields({ ...fields, brand_agent_details: selectedAgents });
    console.log(loading);
    setTimeout(() => {
      setLoadingBrand(false);
    }, 1000);
  }, []);

  useEffect(() => {
    getEventList();
  }, [fields?.customer_account]);

  useEffect(async () => {
    getAgentsList();
    getExpoHallDetails();

    const brandListForDropdown = await getBrandDropdowns(
      fields?.event_id ? fields?.event_id : ""
    );
    setBrandData(brandListForDropdown);
  }, [fields.event_id]);

  function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = agents_list.findIndex(agent => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = selectedAgents_list.findIndex(agent => agent.user_id === value);
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
  }

  const onHandleSocialSwitchChange = (checked, idx) => {

    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details?.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (checked) {
      brand_social_media_details?.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: ""
      });
    }
    else {
      let index = brand_social_media_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details.splice(index, 1);
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details
    });
  };

  const socialMediaHandleChange = (idx, event) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details.some(
      social_media =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = brand_social_media_details.findIndex(
        social_media =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details[index]["social_media_url"] =
        event.target.value;
    } else {
      brand_social_media_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value
      });
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details
    });
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    window.scrollTo(0, 0);
    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);
    let formData = new FormData();
    fields.brand_website = "www.testweb.com";
    fields.brand_agent_details = agentsIdArr;
    fields["wall_graphics_url"] = wallGraphicsFileUrl;
    fields["final_booth_url"] = finalBoothFileUrl;
    formData.append("data", JSON.stringify(fields));
    formData.append("brand_logo_file", brandLogoFileState);

    if (validator.current.allValid()) {
      // post api call

      const addNewBrandResp = await dispatch(addNewBrand(formData));
      if (addNewBrandResp.payload.data.code === 200) {
        toast.success(addNewBrandResp.payload.data.message);
        if (props.isFromEvent) props.onSuccessFromModal();
        else props.history.push("brandManagement");
      }
      if (addNewBrandResp.payload.data.code != 200) {
        toast.error(addNewBrandResp.payload.data.message);
      }
    } else {
      validator.current.showMessages();
      toast.error("Fields are missing");
      setForceUpdate(!forceUpdate);
    }
  };

  const customerParams = {
    is_details: true
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then(response => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);

        if (rows.length < 2) {
          setFields({ ...fields, customer_account: rows[0].customer_id })
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : null
    })
      .then(response => {
        const { rows } = response.data.data;
        setEventList(rows);

        if (rows.length == 1) {
          setFields({ ...fields, event_id: rows[0]?.event_id });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then(response => {
        setTierList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then(response => {
        setZoneList(response.data.data.rows);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/usertypeagents/get", {
      event_id: fields?.event_id ? fields.event_id : null
    })
      .then(response => {
        setAllAgentsList(response?.data?.data?.rows);
        setAgents(response?.data?.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then(response => {
        setSocialMediaList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getExpoHallDetails = async () => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/expohallsmap/details/get",
      { event_id: fields?.event_id ?? "" }
    );
    console.log("repsonse expo hall", response);
    setExpoHallDetails(response.data.data);
    if (response?.data?.code === 200) {
      return response.data.data;
    } else return [];
  };

  const initializeEmptyData = val => {
    const items = [];
    let emptyCount = 10 - val;

    for (let i = 1; i <= emptyCount; i++) {
      items.push(
        <div className="expohalls_booth">
          <Empty description={false} className="expohalls_booth_empty" />
        </div>
      );
    }
    return items;
  };

  const onFileChange = event => {
    setBrandLogoFileState(event.target.files[0]);
  };

  const handleImgChangeLogo = event => {
    setBrandLogoFileState(event.target.files[0]);
    setBrandLogo(URL.createObjectURL(event.target.files[0]));
  };

  const handleBrandLogoFileDelete = () => {
    setBrandLogoFileState(null);
    setBrandLogo(null);
  };

  const handleImgChange2 = event => {
    setWallGraphicsFileState(event.target.files[0]);
    setWallGraphicsFileLoader(true);
    chunkFileUpload(event.target.files[0], function cb(fileUrl) {
      setWallGraphicsFileLoader(false);
      setWallGraphicsFileUrl(fileUrl);
    });
  };

  const handleAllGraphicsFileStateDelete = () => {
    setWallGraphicsFileState(null);
    setWallGraphicsFileUrl(null);
  };

  const handleImgChange3 = event => {
    setFinalBoothFileState(event.target.files[0]);
    setFinalBoothFileLoader(true);
    chunkFileUpload(event.target.files[0], function cb(fileUrl) {
      setFinalBoothFileLoader(false);
      setFinalBoothFileUrl(fileUrl);
    });
  };

  const handleFinalBoothDelete = () => {
    setFinalBoothFileState(null);
    setFinalBoothFileUrl(null);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleOk = () => {
    setExpoHallModalVisible(false);
  };

  const handleCancel = () => {
    setExpoHallModalVisible(false);
  };

  const getDataFromExpoHall = (expo_hall_configuration_id, elemIndex) => {

    setFields({
      ...fields,
      expo_hall_configuration_id: expo_hall_configuration_id,
      sequence: elemIndex
    });
  };


  console.log("Fields Brand", fields)
  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <form onSubmit={handleFormSubmit}>
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="fs-24">Add New Brand</h4>
              {props.isFromEvent ? null : (
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/brandmanagement">Admin Console</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/brandmanagement">Brand Management</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/createnewbrand">Add New Brand</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
            </div>
          </div>

          <div className="bg-white p-3">
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Brand Editor{" "}
                  </h6>
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    {" "}
                    Active
                  </label>
                </div>
                <div className="col-3 text-start">
                  <Switch
                    name="is_active"
                    checked={fields.is_active}
                    onChange={checked =>
                      handleFieldsChange(checked, "is_active")
                    }
                    className="mx-3"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Logo
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>

                <div className="col-5 image-upload d-flex">
                  <FileUpload
                    labelClassName="rounded border-pink btn-svg px-3 py-1"
                    labelTitle="Image Upload"
                    inputType="file"
                    inputOnChange={handleImgChangeLogo}
                    inputId="add_brand_logo"
                    inputName="add_brand_logo"
                    name="logo-brand"
                    onBlur={() =>
                      validator.current.showMessageFor("logo-brand")
                    }
                  />

                  {brandLogoFileState ? (
                    <>
                      <span className="mx-3"> {brandLogoFileState.name} </span>
                      <span onClick={handleBrandLogoFileDelete}>
                        {" "}
                        <DeleteOutlined />{" "}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  {brandLogo ? (
                    <>
                      <img
                        src={brandLogo}
                        alt={brandLogo ? "Uploaded Logo" : ""}
                        className="img-fluid mx-2 user-img-preview"
                      />
                    </>
                  ) : ""
                  }
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-2"></div>
                <div className="col-5">
                  {validator.current.message(
                    "logo",
                    brandLogoFileState,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Customer Account
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>

                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    getPopupContainer={triggerNode => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Customer Account"
                    className="w-100 fs-bold-14"
                    name="customer_account"
                    allowClear
                    value={fields.customer_account}
                    onChange={val =>
                      handleFieldsChange(val, "customer_account")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("customer_account")
                    }
                    listHeight={120}
                    listItemHeight={4}
                    disabled={props?.customer_id || userType === "CUSTOMER" ? true : false}
                  >
                    {customerList?.map(data => {
                      return (
                        <Option value={data.customer_id}>
                          {data.account_name}
                        </Option>
                      );
                    })}
                  </Select>
                  {validator.current.message(
                    "customer_account",
                    fields.customer_account,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
                {/* )} */}
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Select Event
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    getPopupContainer={triggerNode => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder="Select Event"
                    className="w-100 fs-bold-14"
                    allowClear
                    name="event_id"
                    value={fields.event_id}
                    onChange={val => handleFieldsChange(val, "event_id")}
                    onBlur={() => validator.current.showMessageFor("event_id")}
                    optionFilterProp="children"
                    listHeight={120}
                    listItemHeight={4}
                    disabled={props?.event_id ? true : false}
                  >
                    {eventList &&
                      eventList?.map(data => {
                        return (
                          <Option value={data.event_id}>
                            {data.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "event_id",
                    fields.event_id,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Name
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Input
                    name="brand_name"
                    id=""
                    className="w-100 border-gray rounded fs-bold-14"
                    value={fields.brand_name}
                    placeholder="Enter A Title Here"
                    onChange={e =>
                      handleFieldsChange(e.target.value, "brand_name")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("brand_name")
                    }
                  />
                  {validator.current.message(
                    "brand_name",
                    fields.brand_name,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Brand Description
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className="col-5">
                  <TextArea
                    name="brand_description"
                    rows={4}
                    className="fs-bold-14"
                    value={fields.brand_description}
                    onChange={e =>
                      handleFieldsChange(e.target.value, "brand_description")
                    }
                    placeholder="Enter A Description Here"
                    onBlur={() =>
                      validator.current.showMessageFor("brand_description")
                    }
                  />
                  {validator.current.message(
                    "brand_description",
                    fields.brand_description,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Tier
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    getPopupContainer={triggerNode => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder="Select Tier"
                    className="w-100 fs-bold-14"
                    allowClear
                    name="brand_tier "
                    value={fields.brand_tier}
                    onChange={val => handleFieldsChange(val, "brand_tier")}
                    onBlur={() =>
                      validator.current.showMessageFor("brand_tier")
                    }
                    listHeight={120}
                    listItemHeight={4}
                    autoComplete="none"
                    optionFilterProp="children"
                  >
                    {tierList &&
                      tierList?.map(data => {
                        return (
                          <Option value={data.tier_id}>{data.tier}</Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "brand_tier",
                    fields.brand_tier,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Color
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-2"}>
                  <Input
                    name="brand_color"
                    type="color"
                    id=""
                    className="brand_input_color border-gray fs-bold-14 rounded eventtheme_input_style"
                    value={fields.brand_color}
                    addonBefore={fields.brand_color}
                    onChange={e =>
                      handleFieldsChange(e.target.value, "brand_color")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("brand_color")
                    }
                  />
                  {validator.current.message(
                    "color",
                    fields.brand_color,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>


              {/* ------------------------New ADDed Code ------------------ */}
              <div className="row mt-4">
                <div className={Tablet ? "col-2 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Background
                  </label>
                </div>
                <div className={Tablet ? "col-2" : "col-2"}>
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.default_button_background_color}
                    addonBefore={fields?.default_button_background_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "default_button_background_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>

                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch className="mx-2"
                    onChange={(val) => {
                      handleFieldsChange(val, "is_default_button_gradient_active")
                    }}
                  />
                </div>
                {
                  fields.is_default_button_gradient_active ? (
                    <div className={Tablet ? "col-6" : "col-6 text-start"}>
                      <div className="d-flex">
                        <label htmlFor="" className="text-secondary mt-1">
                          Angle
                        </label>
                        <Input className="ms-2 mx-2" style={{ width: "50px" }}
                          onChange={(e) => {
                            handleFieldsChange(
                              e.target.value,
                              "default_button_gradient_angle"
                            );
                          }}
                        />



                        <label htmlFor="" className="text-secondary mx-4 mt-1">
                          Event 2nd Color
                        </label>
                        <Input
                          type="color"
                          id=""
                          defaultValue={
                            fields?.default_button_background_second_color
                          }
                          addonBefore={
                            fields?.default_button_background_second_color
                          }
                          onChange={(e) => {
                            handleFieldsChange(
                              e.target.value,
                              "default_button_background_second_color"
                            );
                          }}
                          className="eventtheme_input_style border-gray rounded fs-bold-14"
                        />
                      </div>
                    </div>
                  ) : ""
                }


              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.default_button_text_color}
                    addonBefore={fields?.default_button_text_color}
                    onChange={(e) => {
                      handleFieldsChange(e.target.value, "default_button_text_color");
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button style={{ color: fields.default_button_text_color, border: `1px solid ${fields.default_button_text_color}`, background: fields.is_default_button_gradient_active ? `linear-gradient(${fields.default_button_gradient_angle}deg, ${fields.default_button_background_color} 30%,${fields.default_button_background_second_color} 100%)` : `${fields.default_button_background_color}` }} className="rounded">Example Test</Button>
                </div>
              </div>

              <div className="row w-100 mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Background
                  </label>
                </div>
                <div className="col-2">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.highlight_button_background_color}
                    addonBefore={fields?.highlight_button_background_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "highlight_button_background_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>


                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch
                    onChange={(val) => {
                      handleFieldsChange(val, "is_highlight_button_gradient_active")
                    }}
                    className="mx-2" />
                </div>

                {
                  fields?.is_highlight_button_gradient_active ? (
                    <div className={Tablet ? "col-6" : "col-6 text-start"}>
                      <div className="d-flex">
                        <label htmlFor="" className="text-secondary mt-1">
                          Angle
                        </label>
                        <Input className="ms-2 mx-2" style={{ width: "50px" }}
                          onChange={(e) => {
                            handleFieldsChange(
                              e.target.value,
                              "highlight_button_gradient_angle"
                            );
                          }}
                        />

                        <label htmlFor="" className="text-secondary mx-4 text-nowrap mt-1">
                          Event 2nd Color
                        </label>
                        <Input
                          type="color"
                          id=""
                          defaultValue={
                            fields?.highlight_button_background_second_color
                          }
                          addonBefore={
                            fields?.highlight_button_background_second_color
                          }
                          onChange={(e) => {
                            handleFieldsChange(
                              e.target.value,
                              "highlight_button_background_second_color"
                            );
                          }}
                          className="eventtheme_input_style ms-2 border-gray rounded fs-bold-14"
                        />
                      </div>
                    </div>
                  ) : ""
                }

              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.highlight_button_text_color}
                    addonBefore={fields?.highlight_button_text_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "highlight_button_text_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button
                    style={{ color: fields.highlight_button_text_color, border: `1px solid ${fields.highlight_button_text_color}`, background: fields.is_highlight_button_gradient_active ? `linear-gradient(${fields.highlight_button_gradient_angle}deg, ${fields.highlight_button_background_color} 30%,${fields.highlight_button_background_second_color} 100%)` : `${fields.highlight_button_background_color}` }}
                    className="rounded">Example Test</Button>
                </div>
              </div>

              {/* New Added Code Ends here */}
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Booth Location{" "}
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Button
                    className="rounded  btn-svg px-3"
                    onClick={() => setExpoHallModalVisible(true)}
                  >
                    Select Expo Hall
                  </Button>

                  <Modal
                    visible={expoHallModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                  >
                    <ExpoHalls
                      event_id={fields.event_id ?? null}
                      getDataFromExpoHall={getDataFromExpoHall}
                    />
                  </Modal>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Download Graphics Template
                  </label>
                </div>
                <div className={Tablet ? "col-3 text-end" : "col-4 text-end"}>
                  <div className="d-flex">
                    <Button className="rounded btn-svg " icon={<EyeView />}>
                      View
                    </Button>
                    <Button type="primary" className="ms-3">
                      <Download />
                      <span className="ms-1">Download</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Upload Graphics
                  </label>
                </div>

                <div className="col-2 image-upload d-flex">
                  <div className="h-25">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-1 "
                      labelTitle="File Upload"
                      inputType="file"
                      inputOnChange={handleImgChange2}
                      inputId="wallgraphicsupload"
                      inputName="wallgraphicsupload"
                      name="wallgraphicsupload"
                      onBlur={() =>
                        validator.current.showMessageFor("wallgraphicsupload")
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  {wallGraphicsFileState && !wallGraphicsFileLoader ? (
                    <>
                      <span className="mx-3">
                        {" "}
                        {wallGraphicsFileState.name}{" "}
                      </span>
                      <span onClick={handleAllGraphicsFileStateDelete}>
                        {" "}
                        <DeleteOutlined />{" "}
                      </span>
                    </>
                  ) : wallGraphicsFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-2"></div>
              </div>

              <div className="border-bottom-gray pb-4">
                <div className="row mt-4 ">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      360 Booth Upload
                    </label>
                  </div>

                  <div className="col-2 image-upload d-flex">
                    <div className="h-25">
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="File Upload"
                        inputType="file"
                        inputOnChange={handleImgChange3}
                        inputId="finalboothupload"
                        inputName="finalboothupload"
                        name="finalboothupload"
                        onBlur={() =>
                          validator.current.showMessageFor("finalboothupload")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    {finalBoothFileState && !finalBoothFileLoader ? (
                      <>
                        <span className="mx-3">
                          {" "}
                          {finalBoothFileState.name}{" "}
                        </span>
                        <span onClick={handleFinalBoothDelete}>
                          {" "}
                          <DeleteOutlined />{" "}
                        </span>
                      </>
                    ) : finalBoothFileLoader ? (
                      <Spin
                        className="spinner"
                        style={{ color: "#ef0855" }}
                        indicator={loadingIcon}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row my-0 py-0">
                  <div className="col-2"></div>
                </div>
              </div>
            </section>

            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    External & Social Links
                  </h6>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-12 mt-1">
                  <span className="fs-16">
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </span>
                </div>
              </div>

              <div className="social-media-div border-bottom-gray pb-4 mb-4">
                {socialMediaList &&
                  socialMediaList.map((data, idx) => (
                    <div className="row mt-4 ">
                      <div className="col-2 text-end ">
                        {data?.social_media}
                      </div>
                      <div className="col-1 text-center ">
                        <Switch
                          checked={
                            fields?.brand_social_media_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? true
                              : false
                          }
                          onChange={checked =>
                            onHandleSocialSwitchChange(checked, idx)
                          }
                        />
                      </div>
                      <div className="col-4 text-start ">
                        <Input
                          name="social_media"
                          placeholder="https://webaddress.com"
                          className="w-100"
                          disabled={
                            fields?.brand_social_media_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? false
                              : true
                          }
                          value={
                            fields?.brand_social_media_details?.some(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? fields?.brand_social_media_details[
                              fields.brand_social_media_details.findIndex(
                                social_media =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                              ]["social_media_url"]
                              : ""
                          }
                          onChange={event =>
                            socialMediaHandleChange(idx, event)
                          }
                          onBlur={() => validator.current.showMessageFor("social_media")}
                        />
                        {validator.current.message(
                          "social_media",
                          fields?.brand_social_media_details?.some(
                            social_media =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? fields?.brand_social_media_details[
                            fields.brand_social_media_details.findIndex(
                              social_media =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                            ]["social_media_url"]
                            : ""
                          ,
                          fields?.brand_social_media_details?.some(
                            social_media =>
                              social_media.social_media_id ===
                              data.social_media_id
                          ) ? "required|url" : "",
                          { className: "text-danger py-1" }
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>

            {/* -------------------This Code is kept as backup if client will say he wants it back thats why------------------------ */}

            {/* section Assign Agents to this Brand */}

            {/* <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Assign Agents to this Brand{" "}
                  </h6>
                </div>
              </div>
              <div className="row mt-4 event-editor-dropdown-div">
                <div className="col-2 text-end mt-4">
                  <span className="text-secondary mt-4">
                    Agents
                  </span>
                </div>

                <div className={Tablet ? "col-5" : "col-4 mt-2"} id="area">
                  <label className="py-2 mx-2">
                    <b>All Agents</b>
                  </label>
                  <br />
                  <Select
                    getPopupContainer={triggerNode => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    name="agents"
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search to Select"
                    onChange={handleAgentsChange}
                    open={true}
                    value="All Agents"
                    className="border border-gray fs-bold-14 "
                    onBlur={() => validator.current.showMessageFor("agents")}
                    listHeight={128}
                    listItemHeight={4}
                  >
                    {agents ? (
                      agents?.map((data, key) => (
                        <Option value={data?.user_id}>
                          {data?.first_name} {data?.last_name}
                        </Option>
                      ))
                    ) : (
                      <span disabled>Agents are not created for this brand</span>
                    )}
                  </Select>
                </div>
                <div
                  className={Tablet ? "col-5 " : "col-4 mt-2"}
                  id="selectedAgents"
                >
                  <label className="py-2 mx-2">
                    <b>Tagged Agents</b>
                  </label>
                  <br />
                  <Select
                    getPopupContainer={triggerNode => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    name="selectedAgents"
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search Tagged Agents"
                    open={true}
                    value="Tagged Agents"
                    className="border border-gray fs-bold-14 "
                    listHeight={128}
                    listItemHeight={4}
                  >
                    {selectedAgents?.map(data => {
                      return (
                        <Option value={data.user_id}>
                          {data.first_name} {data.last_name}{" "}
                          <span className="float-end">
                            <CloseOutlined
                              className="float-right text-danger mr-0"
                              onClick={() =>
                                handleSelectedAgentsChange(data.user_id)
                              }
                            />{" "}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="row border-bottom-gray pb-5">
                <div className="col-2"></div>
                <div className="col-4">
                  <div className="d-flex">
                    <Button
                      className={
                        "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                      }
                    >
                      <CalenderGrey />{" "}
                      <span className="mx-2">Staffing Calendar</span>{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </section> */}
            {/* section Cancel */}
            <section>
              <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                {props.onCancelFromModal ? (
                  <Button
                    onClick={() => props.onCancelFromModal()}
                    className="rounded  px-3"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link to="brandmanagement">
                    <Button className="rounded px-3">Cancel</Button>
                  </Link>
                )}

                <div>
                  <Button
                    className="mx-2"
                    htmlType="submit"
                    type="primary"
                    disabled={loading}
                  >
                    {!loading ? (
                      "Save Brand"
                    ) : (
                      <div style={{ width: "70px" }}>
                        <Spin
                          style={{ color: "white" }}
                          indicator={loadingIcon}
                        />
                      </div>
                    )}
                  </Button>
                  {/* <Button type="primary" onClick={testChunkUpload}>
                  Test Chunk Upload
                </Button> */}
                </div>
              </div>
            </section>
          </div>
        </form >
      </Fragment >
    );
};

export default CreateNewBrand;
