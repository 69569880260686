import React, { useState, useRef, useEffect } from "react";
import "./ProductChatStaffing.css";
import { AddGroup } from "../../../components/svgJS/AddGroup";

import {
  Modal,
  Row,
  Col,
  TimePicker,
  Input,
  Select,
  Switch,
  Button,
  Form,
  DatePicker,
} from "antd";

import moment from "moment";

import { CloseOutlined } from "@ant-design/icons";

import SimpleReactValidator from "simple-react-validator";
import { ApiCall } from "../../../utils/api";
import { getAgents } from "../../../utils/commonApis";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

const ProductChatStaffingCreate = (props) => {
  console.log("props chat staffing cretae", props);
  const { chat_configuration_id } = props;
  // console.log("chat_configuration_id", chat_configuration_id);
  const staffingValidator = useRef(new SimpleReactValidator());
  const [staffingForceUpdate, setStaffingForceUpdate] = useState(false);

  const format = "HH:mm";

  const [staffing_fields, setStaffing_Fields] = useState();

  const [staffingStartDateTime, setStaffingStartDateTime] = useState(
    staffing_fields?.start_date_time
  );

  const [isModalVisible, setIsModalVisible] = useState(true);

  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const [formData, setformData] = useState({
    brand_id: props.extraProps.brandId,
    zone_id: props.extraProps.zoneId,
    start_date_time: moment(),
    end_date_time: moment(),
    is_update: true,
    product_area_chat_tagged_agents: [],
  });

  const initializeZoneStaffingData = (data) => {
    setStaffing_Fields({
      chat_configuration_id: data?.chat_configuration_id ?? null,
      brand_id: data?.brand_id ?? null,
      zone_id: data?.zone_id ?? null,
      // start_date_time: data?.start_date_time ?? moment(new Date()),
      // end_date_time: data?.end_date_time ?? moment(new Date()),
      is_update: true,
      product_area_chat_tagged_agents:
        data?.product_area_chat_tagged_agents?.map((elem) => elem?.user_id),
    });

    let userObj = data?.product_area_chat_tagged_agents.map((item) => {
      return {
        user_id: item?.user_id,
        user_name: item?.user?.first_name + " " + item?.user?.last_name,
      };
    });

    setSelectedAgents(userObj);
  };

  useEffect(async () => {
    getAgentsList();
    // await ApiCall("get", null, "/admin/zoneareastaff/details/get", {
    //   chat_configuration_id: props.data.chat_configuration_id
    // })
    //   .then(response => {
    //     let data =
    //       response?.data?.data?.details
    //         ?.product_area_chat_configuration_details[0];
    //     console.log("rrrrrresponseData", data);

    //     initializeZoneStaffingData(data);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //   });
  }, []);

  const { Option } = Select;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/usertypeagents/get", {
      zone_id: props.extraProps.zoneId,
      is_zone_calendar: true,
    })
      .then((response) => {
        console.log("response", response);
        setAgents(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStaffingSubmit = () => {
    // document.location.reload();
    const finalSelectedAgents = selectedAgents?.map((item) => item.user_id);

    var clone_formData = cloneDeep(formData);

    clone_formData.start_date_time =
      moment(props.data.startDate).format("YYYY-MM-DD") +
      " " +
      moment(formData.start_date_time).format("HH:mm");

    clone_formData.end_date_time =
      moment(props.data.endDate).format("YYYY-MM-DD") +
      " " +
      moment(formData.end_date_time).format("HH:mm");
    clone_formData.product_area_chat_tagged_agents = finalSelectedAgents;
    console.log("clone_formData", clone_formData);

    ApiCall("POST", clone_formData, "/admin/zoneareastaff/create", null)
      .then((response) => {
        console.log("finalResp", response);

        if (response.data.code === 200) {
          toast.success(response?.data?.message);
          props.extraProps.getAppointmentDetails();
          // appointmentData.extraProps.visibleChange();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    props.extraProps.visibleChange();
  };

  const handleStaffingFieldsChange = (val, name) => {
    console.log({ ...staffing_fields, [name]: val });
    setStaffing_Fields({ ...staffing_fields, [name]: val });
  };

  function handleAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    if (selectedAgents_list) {
      selectedAgents_list.push(agents_list[index]);
      agents_list.splice(index, 1);

      setSelectedAgents([...selectedAgents_list]);
      setAgents([...agents_list]);
      console.log("selectedAgents list12", selectedAgents);
    }
  }

  function handleSelectedAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let agents_list = agents;
    let selectedAgents_list = selectedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);

    // console.log("selectedAgents list34", selectedAgents);
  }
  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      {/* <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      > */}
      {/* <Modal footer={null} visible={props.extraProps.visible} width={950}> */}
      {console.log("staffingFields", staffing_fields)}
      <div className="pb-4">
        <div
          className="my-3 p-3"
          style={{ height: "60px", backgroundColor: "rgb(244 244 244)" }}
        >
          <h4 className="text-center text-danger">
            Product/area chat staffing
          </h4>
          {/* <p className="text-center fs-16 fs-pink">
              Zone name (pulled in from zone editor)
            </p> */}
        </div>
        <Row>
          <Col
            span={8}
            className="d-flex align-items-center justify-content-center"
          >
            <span className="fs-16">
              Start Date & Time <span style={{ color: "#EF0855" }}>*</span>
            </span>
          </Col>
          <Col span={14}>
            {console.log(staffing_fields?.start_date_time)}
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              // disabled
              name="start_date_time"
              format={"HH:mm"}
              placeholder="Select Time"
              className="adminSatellite_date_time w-100"
              value={formData?.start_date_time}
              onSelect={(val) => {
                setformData((prev) => ({ ...prev, start_date_time: val }));
              }}
              // onBlur={() =>
              //   productzonesession_validator.current.showMessageFor(
              //     "productZoneSession_start_date_time"
              //   )
              // }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            className="d-flex align-items-center justify-content-center"
            span={8}
          >
            <span className="fs-16">
              End Date & Time <span style={{ color: "#EF0855" }}>*</span>
            </span>
          </Col>
          <Col span={14}>
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              // disabled
              name="start_date_time"
              format={"HH:mm"}
              placeholder="Select Time"
              className="adminSatellite_date_time w-100"
              value={formData.end_date_time}
              onSelect={(val) => {
                setformData((prev) => ({ ...prev, end_date_time: val }));
              }}
              // onBlur={() =>
              //   productzonesession_validator.current.showMessageFor(
              //     "productZoneSession_start_date_time"
              //   )
              // }
            />
          </Col>
        </Row>

        <section>
          <div className="row mt-4 event-editor-dropdown-div">
            <div className="col-3 text-center mt-4">
              <span className="text-secondary mt-4">Agents</span>
            </div>

            <div
              className={false ? "col-5" : "col-4"}
              id="area"
              style={{ position: "relative" }}
            >
              <label className="py-2 mx-2">
                <b>All Agents</b>
              </label>
              <br />
              <Select
                getPopupContainer={() => document.getElementById("area")}
                showSearch
                dropdownClassName="agent-dropdown"
                style={{ width: 220 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                value="All Agents"
                onChange={handleAgentsChange}
                open={true}
                listItemHeight={4}
                listHeight={130}
                className="border border-gray fs-bold-14"
              >
                {agents?.map((data) => (
                  <Option value={data.user_id}>
                    {data.first_name} {data.last_name}
                  </Option>
                ))}
                {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
              </Select>
              {/* {isEmpty(selectedAgents) &&
                      errors.zone_agents &&
                      touched.zone_agents ? (
                        <div className="text-danger agents-error-margin-top">
                          {errors.zone_agents}
                        </div>
                      ) : null} */}
            </div>

            <div
              className={false ? "col-5" : "col-4"}
              id="area1"
              style={{ position: "relative" }}
            >
              <label className="py-2 mx-2">
                <b>Tagged Agents</b>
              </label>
              <br />
              {/* <div className="event-editor-dropdown-background-gray rounded w-75 p-2"> */}
              <Select
                getPopupContainer={() => document.getElementById("area1")}
                showSearch
                dropdownClassName="agent-dropdown"
                style={{ width: 220 }}
                placeholder="Search Agents"
                optionFilterProp="children"
                value="Tagged Agents"
                // onChange={handleAgentsChange}
                open={true}
                listItemHeight={4}
                listHeight={130}
                className="border border-gray fs-bold-14"
              >
                {selectedAgents?.map((data) => (
                  <Option value={data.user_id}>
                    {data.first_name} {data.last_name}
                    <span className="float-end">
                      <CloseOutlined
                        className="float-right text-danger mr-0"
                        onClick={() =>
                          handleSelectedAgentsChange(data?.user_id)
                        }
                      />
                    </span>
                  </Option>
                ))}
                {/* {agents.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
              </Select>

              {/* <Input className="w-100 my-1" />
                      <List
                        className="event-editor-tagged-agents-div"
                        itemLayout="horizontal"
                        dataSource={selectedAgents}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <span className="d-flex justify-content-between bg-white m-0 p-2 text-danger ">
                                  <span>
                                    {item.first_name} {item.last_name}
                                  </span>
                                  <CloseOutlined
                                    className="float-right"
                                    onClick={() =>
                                      handleSelectedAgentsChange(item.user_id)
                                    }
                                  />
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      /> */}
              {/* </div> */}
            </div>
          </div>
          <div className=" d-flex justify-content-between mx-5">
            <Button
              className="w-25 mt-4 fs-16 rounded staffing_save_btn"
              key="save"
              type="primary"
              htmlType="submit"
              onClick={() => props.extraProps.visibleChange()}
            >
              Cancel
            </Button>
            <Button
              className="w-25 mt-4 fs-16 rounded staffing_save_btn"
              key="save"
              type="primary"
              htmlType="submit"
              onClick={handleStaffingSubmit}
            >
              Save
            </Button>
          </div>
        </section>
      </div>
      {/* </Modal> */}
    </>
  );
};

export default ProductChatStaffingCreate;
