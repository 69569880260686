import React, { Fragment, useEffect, useState } from "react";
import { Select, Switch, Input, Divider } from "antd";
import { useMediaQuery } from "react-responsive";
import { getAgents, getUserTypeAgents } from "../../../utils/commonApis";
import { isEmpty } from "lodash";
import { uniqWith } from "lodash";
import { isEqual } from "lodash";

const SocialWall = ({ data }) => {
  const { TextArea } = Input;
  const [agents, setAgents] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const { Option } = Select;

  useEffect(async () => {
    console.log("data in social wall", data);
    window.scrollTo(0, 0);
    // const agentListAPI = await getAgents();
    // setAgentsCollectionData(agentListAPI);
  }, [data]);

  useEffect(async () => {
    if (data?.event_id) {
      const agentListAPI = await getUserTypeAgents({
        event_id: data?.event_id,
      });
      console.log("check1", agentListAPI);
      setAgents(agentListAPI);
    }
  }, [data?.event_id]);

  useEffect(() => {
    if (!isEmpty(agentsCollectionData)) setAgentsFromAPIAndUseEffect(data);
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    let agents_list = [];

    if (dataHere && !isEmpty(dataHere.social_wall_moderators)) {
      dataHere.social_wall_moderators.map((item) => {
        if (
          agentsCollectionData &&
          agentsCollectionData.some((agent) => agent?.user_id === item.user_id)
        ) {
          agents_list = agentsCollectionData;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex(
            (agent) => agent.user_id === item.user_id
          );
          selectedAgents_list.push(agents_list[index]);
          console.log(
            "agent from list to delete from main - ",
            agents_list[index]
          );
          agents_list.splice(index, 1);
          selectedAgents_list = uniqWith(selectedAgents_list, isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else setAgents(agentsCollectionData);
  };

  return (
    <Fragment>
      <div className="container-fluid px-5 view-register-and-com">
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Main Setting</h6>
            </div>
          </div>
          <section>
            <div className="row mt-4">
              <div className="col-3 text-end">
                <span className="text-secondary">
                  Default status of new posts
                </span>
              </div>
              <div className="col-9">
                <Switch
                  value={data?.default_status_new_post}
                  checked={
                    data?.default_status_new_post
                      ? data.default_status_new_post
                      : false
                  }
                />
              </div>
              <div className="col-1"></div>
              <div className="col-10 my-2">
                {data?.default_status_new_post === true ? (
                  <p className="fs-14 my-2">
                    By default, posts are: "On until turned Off"
                  </p>
                ) : (
                  <p className="fs-14 my-2">
                    By default, posts are: "Off until turned On"
                  </p>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Title</span>
              </div>
              <div className="col-10 ">
                <Input
                  readOnly
                  placeholder="Enter Title Here"
                  defaultValue={data?.social_wall_title}
                  className="w-50 fs-bold-14"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-2 text-end">
                <span
                  className="text-secondary"
                  placeholder="Enter Description here"
                >
                  Description
                </span>
              </div>
              <div className="col-10">
                <TextArea
                  rows={4}
                  className="w-50 fs-bold-14"
                  defaultValue={data?.social_wall_description}
                  readOnly
                />
              </div>
            </div>
          </section>
          <div className="row mt-4">
            <div className="col-4">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-5 text-end"></div>
                <div className="col-3 text-center">
                  <b>Shareable </b>
                </div>
                <div className="col-3 text-start">
                  <b>Auto </b>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <b>Re-post Handle / Account</b>
                </div>
                <div className="col-6">
                  <b>Re-post Added Text</b>
                </div>
              </div>
            </div>
          </div>

          {data?.social_wall_sharing_details?.map((item, index) => {
            return (
              <div className="row mt-4">
                <div className="col-4">
                  <div className="row">
                    <div className="col-6 text-end">{item?.social_media}</div>
                    <div className="col-3 text-center">
                      <Switch
                        disabled
                        readOnly
                        defaultChecked={item?.enable_sharing}
                      />
                    </div>
                    <div className="col-3 text-center">
                      <Switch
                        disabled
                        readOnly
                        defaultChecked={item?.enable_auto_repost}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <Input
                    readOnly
                    defaultValue={item?.repost_account}
                    placeholder="Enter handle or account name here"
                    className="fs-bold-14"
                  />
                </div>
                <div className="col-4">
                  <Input
                    readOnly
                    defaultValue={item?.repost_text}
                    placeholder="Enter Text here"
                    className="fs-bold-14"
                  />
                </div>
              </div>
            );
          })}

          <Divider />
        </section>

        {/* Assign Moderators */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Assign Moderators</h6>
            </div>
          </div>
          <div className="row mt-4 event-editor-dropdown-div">
            <div className="col-2 text-end mt-4">
              <span className="text-secondary mt-4">Agents</span>
            </div>

            <div className={Tablet ? "col-5" : "col-4"}>
              <label className="py-2 mx-2">
                <b>All Agents</b>
              </label>
              <br />
              <Select
                readOnly
                showSearch
                optionFilterProp="children"
                style={{ width: 300 }}
                placeholder="Search Agents"
                open={true}
                listItemHeight={4}
                listHeight={130}
                className="border border-gray fs-bold-14"
                value="All Agents"
              >
                {agents &&
                  agents?.map((data) => {
                    // console.log("agent data",data)
                    return (
                      <Option>
                        {data?.first_name} {data?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className={Tablet ? "col-5" : "col-4"}>
              <label className="py-2 mx-2">
                <b>Tagged Agents</b>
              </label>
              <br />
              <div className="event-editor-dropdown-background-gray rounded w-75">
                <Select
                  readOnly
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Search Tagged Agents"
                  optionFilterProp="children"
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                  value="Tagged Agents"
                >
                  {selectedAgents?.map((data) => {
                    return (
                      <Option value={data?.user_id}>
                        {data?.first_name} {data?.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-2"></div>
            {/* <div className="col-4">
              <p className="fs-pink fs-12">OR</p>

              <Button className="px-3 fs-pink border-pink rounded btn-svg">
                <AddGroup fill="#ef0855" /> <span className="mx-2">Invite Agents</span>{" "}
              </Button>
              <Button
                className={
                  Tablet
                    ? "px-3 fs-pink border-pink rounded mt-2 btn-svg"
                    : "px-3 fs-pink border-pink rounded mt-2 d-block btn-svg"
                }
              >
                <CalenderRed />{" "}
                <span className="mx-2">Social Wall Moderator Calendar</span>{" "}
              </Button>
            </div> */}
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default SocialWall;
