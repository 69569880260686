import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";

export const Conference = props => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="_174-conference"
          data-name="174-conference"
          d="M19.3,17.3a4.591,4.591,0,0,0-1.124-.738,2.344,2.344,0,1,0-4.016-.014,4.584,4.584,0,0,0-1.075.684,4.613,4.613,0,0,0-1.074-.681,2.344,2.344,0,1,0-4.021,0,4.613,4.613,0,0,0-1.074.681A4.584,4.584,0,0,0,5.9,16.58a2.344,2.344,0,1,0-4.015-.042A4.587,4.587,0,0,0,.7,17.3,2.106,2.106,0,0,0,0,18.876v.343a.781.781,0,1,0,1.563,0v-.343a.543.543,0,0,1,.178-.406A3.1,3.1,0,0,1,3.847,17.7a3.1,3.1,0,0,1,2.107.775.543.543,0,0,1,.178.406v.343a.781.781,0,1,0,1.563,0v-.343a.539.539,0,0,1,.179-.4A3.136,3.136,0,0,1,10,17.7a3.136,3.136,0,0,1,2.126.777.539.539,0,0,1,.179.4v.343a.781.781,0,0,0,1.563,0v-.343a.543.543,0,0,1,.178-.406,3.252,3.252,0,0,1,4.214,0,.543.543,0,0,1,.178.406v.343a.781.781,0,1,0,1.563,0v-.343a2.107,2.107,0,0,0-.7-1.572ZM3.906,14.57a.781.781,0,1,1-.781.781A.782.782,0,0,1,3.906,14.57Zm5.313.781a.781.781,0,1,1,.781.781A.782.782,0,0,1,9.218,15.351Zm6.953-.781a.781.781,0,1,1-.781.781A.782.782,0,0,1,16.172,14.57ZM3.906,7.539h.62L6.145,12.1a.781.781,0,0,0,1.473-.523L6.184,7.539h7.631l-1.433,4.036a.781.781,0,1,0,1.472.523l1.619-4.558h.62a.781.781,0,1,0,0-1.563H13.672V5.744a2.019,2.019,0,0,0-.677-1.511,4.386,4.386,0,0,0-1-.665A2.344,2.344,0,1,0,8,3.568a4.38,4.38,0,0,0-1,.665,2.019,2.019,0,0,0-.677,1.511v.233H3.906a.781.781,0,1,0,0,1.563Zm5.313-5.2A.781.781,0,1,1,10,3.125.782.782,0,0,1,9.218,2.343ZM7.89,5.744A.455.455,0,0,1,8.041,5.4,2.889,2.889,0,0,1,10,4.687a2.889,2.889,0,0,1,1.958.715.455.455,0,0,1,.151.341v.233H7.89Zm0,0"
          transform="translate(0 0)"
          opacity="0.7"
        />
      </svg>
    </>
  );
};
