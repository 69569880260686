import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Select,
  Switch,
  Input,
  DatePicker,
  Collapse,
  TimePicker,
  Modal,
  Spin,
  Row,
  Col,
} from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { iconSelected, bin } from "../../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import {
  CalenderGrey,
  TimeCalendar,
} from "../../../components/svgJS/CalenderRed";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getCustomerList } from "../../../redux/slice/customerData";
import { getBrand } from "../../../redux/slice/brandManagementSlice";
import FileUpload from "../../common/FileUpload";
import _, { cloneDeep, isEmpty } from "lodash";
import CreateNewBrand from "../../brandManagement/CreateNewBrand";
import SimpleReactValidator from "simple-react-validator";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import {
  chunkFileUpload,
  compareAllDatesWithEveryArrayElement,
  compareAllStartEndDates,
  getDisabledHours,
  isImage,
} from "../../../utils/commonFunctions";
import {
  getSurveys,
  getBrandDropdowns,
  getUserTypeAgents,
} from "../../../utils/commonApis";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import "./index.css";
import AddNewSurvey from "../../surveyManagement/AddNewSurvey";
import { Download } from "../../../components/svgJS/Download";
import ReactPlayer from "react-player/lazy";
import { LOGO_DEMO } from "../../../components/constsnts/common.constants";
import AddUser from "../../UserManagement/AddUser";

const EventEditor = ({
  data,
  onChangeData,
  onNavigateToRegistration,
  onNavigateToReception,
  onNavigateToTier,
  onNavigateToSocialWall,
  onValid,
  initiateValidations,
  setLoaderOnDemand,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const chatBotNewObj = {
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    show_id: "",
    is_delete: false,
  };
  const validator = useRef(new SimpleReactValidator());
  var CryptoJS = require("crypto-js");
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [userType, setUserType] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerStartDate, setCustomerStartDate] = useState("");
  const [customerEndDate, setCustomerEndDate] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentsTechnical, setAgentsTechnical] = useState([]);
  const [selectedAgentsTechnical, setSelectedAgentsTechnical] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [chatBotDetails, setchatBotDetails] = useState([chatBotNewObj]);
  const [logoFileState, setLogoFileState] = useState(null);
  const [preEventImg, setPreEventImg] = useState(null);
  const [welcomeImg, setWelcomeImg] = useState(null);
  const [controlImg, setControlImg] = useState(null);
  const [expochecked, setExpoChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_expo_hall_active
      ? true
      : false
  );
  const [satAuditoriumChecked, setSatAuditoriumChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_sat_auditorium_active
      ? true
      : false
  );
  const [networkingChecked, setNetworkingChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_networking_active
      ? true
      : false
  );
  const [exteriorChecked, setExteriorChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_exterior_active
      ? true
      : false
  );
  const [isControlPreview, setIsControlPreview] = useState(false);
  const [isPreEventImgPreview, setIsPreEventImgPreview] = useState(false);
  const [isWelcomePreview, setisWelcomePreview] = useState(false);
  const [isLogoPreview, setIsLogoPreview] = useState(false);
  const [newBrandModal, setNewBrandModal] = useState(false);
  const [brandListDropdown, setBrandListDropdown] = useState([]);
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [expoHallFileLoader, setExpoHallFileLoader] = useState(false);
  const [expoHallFileState, setExpoHallFileState] = useState(null);
  const [expoHallFileUrl, setExpoHallFileUrl] = useState(null);
  const [mainAudiFileLoader, setMainAudiFileLoader] = useState(false);
  const [mainAudiFileState, setMainAudiFileState] = useState(null);
  const [mainAudiFileUrl, setMainAudiFileUrl] = useState(null);
  const [satAudiFileLoader, setSatAudiFileLoader] = useState(false);
  const [satAudiFileState, setSatAudiFileState] = useState(null);
  const [satAudiFileUrl, setSatAudiFileUrl] = useState(null);
  const [netLoungeFileLoader, setNetLoungeFileLoader] = useState(false);
  const [netLoungeFileState, setNetLoungeFileState] = useState(null);
  const [netLoungeFileUrl, setNetLoungeFileUrl] = useState(null);
  const [concourseFileLoader, setConcourseFileLoader] = useState(false);
  const [concourseFileState, setConcourseFileState] = useState(null);
  const [concourseFileUrl, setConcourseFileUrl] = useState(null);
  const [exteriorFileLoader, setExteriorFileLoader] = useState(false);
  const [exteriorFileState, setExteriorFileState] = useState(null);
  const [exteriorFileUrl, setExteriorFileUrl] = useState(null);
  const [logoThumbNail, setLogoThumbNail] = useState(null);
  const [preEventThumbNail, setPreEventThumbNail] = useState(null);
  const [welcomeThumbNail, setWelcomeThumbNail] = useState(null);
  const [controlThumbNail, setControlThumbNail] = useState(null);

  const [isAddUserModalVisible1, setIsAddUserModalIsVisible1] = useState(false);
  const [isAddUserModalVisible2, setIsAddUserModalVisible2] = useState(false);
  const [brand_id, setBrand_id] = useState(data?.organizer_brand_id);

  useEffect(async () => {
    // setLoaderOnDemand(true);

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const brandListForDropdown = await getBrandDropdowns(
      data?.event_id ? data?.event_id : ""
    );
    if (data.event_id) setBrandListDropdown(brandListForDropdown);
    let paramsForAgents = "";
    if (data && data.event_id && data.organizer_brand_id)
      paramsForAgents = {
        event_id: data.event_id,
        brand_id: data.organizer_brand_id,
      };
    const agentListAPI = await getUserTypeAgents(paramsForAgents);
    setAgentsCollectionData(agentListAPI);
    dispatch(getBrand());
    const surveyData = await getSurveys({
      event_id: data.event_id ?? null,
      for_event: true,
    });
    setSurveyList(surveyData);
    const responseData = await dispatch(getCustomerList({ is_details: true }));
    if (responseData?.payload?.rows) setCustomerData(responseData.payload.rows);
    setChatbotAudiExpoNetworkFlags(data);

    if (data && data.event_logo_url) {
      let logoFileName = data.event_logo_url.split("\\").pop().split("/").pop();

      if (data.event_logo_original_filename)
        logoFileName = data.event_logo_original_filename;

      setLogoFileState({
        name: logoFileName,
        event_logo_url: data.event_logo_url,
      });
    }

    if (data && data.pre_event_messages.pre_event_media_url) {
      let logoFileName = data.pre_event_messages.pre_event_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data.pre_event_messages.pre_event_media_original_filename)
        logoFileName =
          data.pre_event_messages.pre_event_media_original_filename;

      setPreEventImg({
        name: logoFileName,
        pre_event_media_url: data.pre_event_messages.pre_event_media_url,
      });
    }

    if (data && data.welcome_messages.welcome_media_url) {
      let logoFileName = data.welcome_messages.welcome_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data.welcome_messages.welcome_media_original_filename)
        logoFileName = data.welcome_messages.welcome_media_original_filename;

      setWelcomeImg({
        name: logoFileName,
        welcome_media_url: data.welcome_messages.welcome_media_url,
      });
    }

    if (data && data.control_media_url) {
      let logoFileName = data.control_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.control_media_original_filename)
        logoFileName = data.control_media_original_filename;

      setControlImg({
        name: logoFileName,
        control_media_url: data.control_media_url,
      });
    }
    // setLoaderOnDemand(false);
  }, []);

  useEffect(async () => {
    if (brand_id) {
      let newParamsForAgents = {};
      if (data && data.event_id)
        newParamsForAgents = {
          event_id: data.event_id,
          brand_id: brand_id,
        };
      console.log("newParamsForAgents = ", newParamsForAgents);
      const agentListAPI = await getUserTypeAgents(newParamsForAgents);
      setAgentsCollectionData(agentListAPI);
    }
  }, [brand_id]);

  useEffect(() => {
    if (customerData) setLocalCustomerName(data?.customer_id);
  }, [customerData]);

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  useEffect(() => {
    if (data) setChatbotAudiExpoNetworkFlags(data);
  }, [data]);

  const setChatbotAudiExpoNetworkFlags = (data) => {
    if (data && !isEmpty(data.event_shows_details)) {
      setchatBotDetails(data?.event_shows_details);
    }
  };

  useEffect(() => {
    if (!isEmpty(agentsCollectionData)) setAgentsFromAPIAndUseEffect(data);
  }, [agentsCollectionData]);

  const handleFormSubmit = () => {
    const areEventDatesProper = compareAllStartEndDates(chatBotDetails);
    const areAllDatesDifferent =
      compareAllDatesWithEveryArrayElement(chatBotDetails);

    if (validator.current.allValid()) {
      console.log("valid....");
      if (
        data.event_settings_details &&
        data.event_settings_details.is_expo_hall_active &&
        data.event_settings_details.no_of_expo_halls === 0
      ) {
        toast.error("Please enter number of halls more than 0");
        onValid(false);
      } else if (
        data.event_settings_details &&
        data.event_settings_details.is_sat_auditorium_active &&
        data.event_settings_details.no_of_sat_auditorium === 0
      ) {
        toast.error("Please enter number of satellite auditoriums more than 0");
        onValid(false);
      } else if (
        data.event_settings_details &&
        data.event_settings_details.is_networking_active &&
        data.event_settings_details.no_of_networking === 0
      ) {
        toast.error("Please enter number of network lounges more than 0");
        onValid(false);
      } else if (
        areEventDatesProper === "no" ||
        areAllDatesDifferent === "no"
      ) {
        toast.error("Please re check event dates");
        onValid(false);
      } else if (
        moment(data.registration_end_date_time) <
        moment(data.registration_start_date_time)
      ) {
        toast.error("Please change registration end date");
        onValid(false);
      } else onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    if (
      dataHere &&
      !isEmpty(dataHere.event_support) &&
      !isEmpty(dataHere.event_support.agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      let leftList = [];
      let rightList = [];
      dataHere.event_support.agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let agents_list = clonedAgents;
          let selectedAgents_list = selectedAgents;

          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = _.uniqWith(selectedAgents_list, _.isEqual);
          leftList = [...agents_list];
          rightList = [...selectedAgents_list];
        }
      });
      if (isEmpty(leftList) && isEmpty(rightList)) {
        setAgents(agentsCollectionData);
      } else {
        setAgents(leftList);
        setSelectedAgents(rightList);
      }
    } else setAgents(agentsCollectionData);

    if (
      dataHere &&
      !isEmpty(dataHere.event_technical_support) &&
      !isEmpty(dataHere.event_technical_support.agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      let leftList = [];
      let rightList = [];
      dataHere.event_technical_support.agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let technical_agents_list = clonedAgents;
          let technical_selectedAgents_list = selectedAgentsTechnical;
          let index = technical_agents_list.findIndex(
            (agent) => agent.user_id === val
          );
          technical_selectedAgents_list.push(technical_agents_list[index]);
          technical_agents_list.splice(index, 1);
          technical_selectedAgents_list = _.uniqWith(
            technical_selectedAgents_list,
            _.isEqual
          );
          leftList = [...technical_agents_list];
          rightList = [...technical_selectedAgents_list];
        }
      });
      console.log("leftList = ", leftList);
      console.log("rightList = ", rightList);

      if (isEmpty(leftList) && isEmpty(rightList)) {
        setAgentsTechnical(agentsCollectionData);
      } else {
        setAgentsTechnical(leftList);
        setSelectedAgentsTechnical(rightList);
      }
    } else {
      setAgentsTechnical(agentsCollectionData);
    }
  };

  const addChatBot = async () => {
    const chatbot_arr = cloneDeep(chatBotDetails);
    let finalArr = [];

    if (!chatbot_arr || !isEmpty(chatbot_arr)) {
      let deletedArr = [];
      chatbot_arr &&
        chatbot_arr.forEach((ele) => {
          if (!ele.is_delete) finalArr.push(ele);
          else deletedArr.push(ele);
        });
      finalArr.push(chatBotNewObj);
      if (deletedArr) finalArr = [...finalArr, ...deletedArr];
    } else finalArr = chatbot_arr;
    // setchatBotDetails(finalArr);
    await onChangeData({ event_shows_details: finalArr });
  };

  const handleChatbots = async (index, key, value) => {
    const tempArr = cloneDeep(chatBotDetails);
    tempArr[index][key] = value;
    let finalArr = [];
    let deletedArr = [];
    let event_shows_details_arr = [];
    if (key === "is_delete") {
      setchatBotDetails([]);
      console.log("key is_delete");
      tempArr &&
        tempArr.forEach((ele) => {
          if (!ele.is_delete) finalArr.push({ ...ele });
          else deletedArr.push(ele);
        });
      console.log("finalArr = ", finalArr);
      console.log("deletedArr = ", deletedArr);

      if (deletedArr) event_shows_details_arr = [...finalArr, ...deletedArr];
    } else event_shows_details_arr = tempArr;

    console.log("event_shows_details_arr = ", event_shows_details_arr);

    await onChangeData({ event_shows_details: event_shows_details_arr });
  };

  const onHandleChange = (key1, value, parentKey = "") => {
    onChangeData({ [key1]: value }, parentKey);
  };

  const setLocalCustomerName = (id) => {
    const filteredData =
      customerData && customerData.filter((item) => item.customer_id === id);
    setCustomerName(filteredData[0]?.customer_name);
    setCustomerStartDate(filteredData[0]?.license_period_start_date);
    setCustomerEndDate(filteredData[0]?.license_period_end_date);
  };

  const onHandleChangeTemp = async (key1, value) => {
    setLocalCustomerName(value);
    onChangeData({ [key1]: value });
  };

  async function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    const filteredData = await agents_list.filter(
      (item) => item.user_id === value
    );
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);
    onHandleChange("agents", agentsIdArr, "event_support");
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);
    onHandleChange("agents", agentsIdArr, "event_support");
  }

  async function handleAgentsChangetechnical(value) {
    let agents_list = agentsTechnical;
    let selectedAgents_list = selectedAgentsTechnical;
    const filteredData = await agents_list.filter(
      (item) => item.user_id === value
    );
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    const agentsIdArrTechnical = selectedAgentsTechnical.map(
      (elem, index) => elem.user_id
    );
    onHandleChange("agents", agentsIdArrTechnical, "event_technical_support");
    setSelectedAgentsTechnical([...selectedAgents_list]);
    setAgentsTechnical([...agents_list]);
  }

  function handleSelectedAgentsChangeTechnical(value) {
    let agents_list = agentsTechnical;
    let selectedAgents_list = selectedAgentsTechnical;
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgentsTechnical([...agents_list]);
    setSelectedAgentsTechnical([...selectedAgents_list]);
    const agentsIdArrTechnical = selectedAgentsTechnical.map(
      (elem) => elem.user_id
    );
    onHandleChange("agents", agentsIdArrTechnical, "event_technical_support");
  }

  const handleLogoImageChange = (event) => {
    onChangeData({
      event_logo_file: event.target.files[0],
      event_logo_original_filename: event.target.files[0].name,
    });
    setLogoFileState(event.target.files[0]);
    setLogoThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const deleteEventLogoFile = () => {
    setLogoFileState(null);
    onHandleChange("event_logo_file", null);
    setLogoThumbNail(null);
  };

  const handlePreImgChange = (event) => {
    onChangeData(
      {
        pre_event_message_media_file: event.target.files[0],
        pre_event_media_original_filename: event.target.files[0].name,
      },
      "pre_event_messages"
    );
    setPreEventImg(event.target.files[0]);
    setPreEventThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleWelcomeImgChange = (event) => {
    onChangeData(
      {
        welcome_message_media_file: event.target.files[0],
        welcome_media_original_filename: event.target.files[0].name,
      },
      "welcome_messages"
    );
    setWelcomeImg(event.target.files[0]);
    setWelcomeThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleControlImgChange = (event) => {
    onChangeData({
      controls_help_media_file: event.target.files[0],
      control_media_original_filename: event.target.files[0].name,
    });
    setControlImg(event.target.files[0]);
    setControlThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handle360 = (event, type) => {
    if (type === "expoHall") {
      setExpoHallFileState(event.target.files[0]);
      setExpoHallFileLoader(true);
      onHandleChange("expo_hall_url", event.target.files[0]);
    } else if (type === "mainAudi") {
      setMainAudiFileState(event.target.files[0]);
      setMainAudiFileLoader(true);
      onHandleChange("main_audi_url", event.target.files[0]);
    } else if (type === "satAudi") {
      setSatAudiFileState(event.target.files[0]);
      setSatAudiFileLoader(true);
      onHandleChange("sat_audi_url", event.target.files[0]);
    } else if (type === "netLounge") {
      setNetLoungeFileState(event.target.files[0]);
      setNetLoungeFileLoader(true);
      onHandleChange("net_lounge_url", event.target.files[0]);
    } else if (type === "concourse") {
      setConcourseFileState(event.target.files[0]);
      setConcourseFileLoader(true);
      onHandleChange("concourse_lounge_url", event.target.files[0]);
    } else if (type === "exterior") {
      setExteriorFileState(event.target.files[0]);
      setExteriorFileLoader(true);
      onHandleChange("exterior_url", event.target.files[0]);
    }

    chunkFileUpload(event.target.files[0], function cb(fileUrl) {
      setChunkLoaderOff();
      if (type === "expoHall") setExpoHallFileUrl(fileUrl);
      else if (type === "mainAudi") setMainAudiFileUrl(fileUrl);
      else if (type === "satAudi") setSatAudiFileUrl(fileUrl);
      else if (type === "netLounge") setNetLoungeFileUrl(fileUrl);
      else if (type === "concourse") setConcourseFileUrl(fileUrl);
      else if (type === "exterior") setExteriorFileUrl(fileUrl);
    });
  };

  const handleFileStateDelete = (type) => {
    if (type === "expoHall") {
      setExpoHallFileState(null);
      document.getElementById("expohall-upload").value = null;
      setExpoHallFileUrl(null);
      onHandleChange("expo_hall_url", null);
    } else if (type === "mainAudi") {
      setMainAudiFileState(null);
      document.getElementById("mainaudi-upload").value = null;
      setMainAudiFileUrl(null);
      onHandleChange("main_audi_url", null);
    } else if (type === "satAudi") {
      setSatAudiFileState(null);
      document.getElementById("sataudi-upload").value = null;
      setSatAudiFileUrl(null);
      onHandleChange("sat_audi_url", null);
    } else if (type === "netLounge") {
      setNetLoungeFileState(null);
      document.getElementById("sataudi-upload").value = null;
      setNetLoungeFileUrl(null);
      onHandleChange("net_lounge_url", null);
    } else if (type === "concourse") {
      setConcourseFileState(null);
      document.getElementById("concourse-upload").value = null;
      setConcourseFileUrl(null);
      onHandleChange("concourse_lounge_url", null);
    } else if (type === "exterior") {
      setExteriorFileState(null);
      document.getElementById("exterior-upload").value = null;
      setExteriorFileUrl(null);
      onHandleChange("exterior_url", null);
    }
  };

  function setChunkLoaderOff() {
    setExpoHallFileLoader(false);
    setMainAudiFileLoader(false);
    setSatAudiFileLoader(false);
    setNetLoungeFileLoader(false);
    setConcourseFileLoader(false);
  }

  if (data) {
    return (
      <div className="container-fluid">
        <Modal
          footer={null}
          visible={newBrandModal}
          onCancel={() => setNewBrandModal(false)}
          width={1000}
        >
          <div style={{ padding: "20px" }}>
            <CreateNewBrand
              isFromEvent={true}
              event_id={data?.event_id}
              customer_id={data?.customer_id}
              onCancelFromModal={() => setNewBrandModal(false)}
              onSuccessFromModal={async () => {
                setNewBrandModal(false);
                dispatch(getBrand());
                const brandListForDropdown = await getBrandDropdowns(
                  data?.event_id ? data?.event_id : ""
                );
                if (data.event_id) setBrandListDropdown(brandListForDropdown);
              }}
            />
          </div>
        </Modal>
        <section>
          <div className="row border-bottom-gray pb-3 mb-2 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Select Customer</h6>

            {userType && userType === "CUSTOMER" ? (
              <div className="col-6 text-left px-5 d-flex">
                <span className="mt-1">
                  Customer Name <RedAsterick />
                </span>
                <span>
                  <Input
                    name="Customer"
                    value={customerData[0]?.customer_name}
                    className="mx-4"
                  />
                  {validator.current.message(
                    "Customer",
                    data.customer_id,
                    "required",
                    { className: "text-danger py-1 mx-4" }
                  )}
                </span>
              </div>
            ) : (
              <div className="col-6 text-left px-5 d-flex">
                <span className="mt-1">
                  Customer Name <RedAsterick />
                </span>
                <span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 300, marginLeft: "20px" }}
                    placeholder="Search to Select"
                    onChange={(e) => {
                      onHandleChangeTemp("customer_id", e);
                    }}
                    defaultValue={data?.customer?.account_name}
                    value={data?.customer_id ? data.customer_id : null}
                    className="fs-bold-14"
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {customerData &&
                      customerData.map((item, i) => {
                        return (
                          <Option value={item.customer_id}>
                            {item.customer_name}
                          </Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "Customer",
                    data.customer_id,
                    "required",
                    { className: "text-danger py-1 mx-4" }
                  )}
                </span>
              </div>
            )}

            <div className="col-6">
              <p>
                <span className="text-secondary mx-3">Account Name </span> :
                {userType && userType === "CUSTOMER" ? (
                  <span className="fw-bold mx-3">
                    {customerData[0]?.customer_name}
                  </span>
                ) : (
                  <span className="fw-bold mx-3">
                    {customerName ? customerName : data.customer?.account_name}
                  </span>
                )}
              </p>

              {userType === "CUSTOMER" ? (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {customerData[0]?.license_period_start_date} -
                    {customerData[0]?.license_period_end_date}
                  </span>
                </p>
              ) : (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {`${
                      customerStartDate
                        ? customerStartDate
                        : data?.customer?.license_period_start_date
                        ? moment(
                            data?.customer?.license_period_start_date
                          ).format("DD-MM-YYYY")
                        : ""
                    } - ${
                      customerEndDate
                        ? customerEndDate
                        : data?.customer?.license_period_end_date
                        ? moment(
                            data?.customer?.license_period_end_date
                          ).format("DD-MM-YYYY")
                        : ""
                    }`}
                  </span>
                </p>
              )}
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Details </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                Active
              </label>
            </div>
            <div className="col-3">
              <Switch
                defaultChecked={data.is_active}
                // defaultChecked={true}
                onClick={() => onHandleChange("is_active", !data.is_active)}
                className="mx-3"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Event Name
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                id="event_name"
                className="w-100 border-gray rounded fs-bold-14"
                defaultValue={data.event_name}
                onChange={(e) => onHandleChange("event_name", e.target.value)}
                onBlur={() => validator.current.showMessageFor("event_name")}
              />
              {validator.current.message(
                "Event Name",
                data?.event_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Sub domain
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                name="sub_domain"
                id="sub_domain"
                placeholder=""
                defaultValue={data.sub_domain}
                suffix={<img src={iconSelected} alt="icon" />}
                className="w-100 border-gray rounded fs-bold-14"
                onChange={(e) => {
                  onHandleChange("sub_domain", e.target.value);
                }}
                onBlur={() => validator.current.showMessageFor("sub_domain")}
              />
              {validator.current.message(
                "Sub domain",
                data?.sub_domain,
                "required|alpha_num",
                { className: "text-danger py-1" }
              )}
            </div>
            <div className="col-3 mt-1">
              <p>{process.env.REACT_APP_SUB_DOMAIN}</p>
            </div>
          </div>

          <div className={data?.event_id ? "mt-4 row" : "mt-0"}>
            <div className="col-3 text-end">
              {data?.event_id ? (
                <label htmlFor="" className="text-secondary">
                  Organiser
                </label>
              ) : null}
            </div>
            {data?.event_id ? (
              <div className="col-3">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Brand"
                  className="w-100 fs-bold-14"
                  value={
                    data && data.organizer_brand_id
                      ? data.organizer_brand_id
                      : null
                  }
                  onChange={(e) => {
                    onHandleChange("organizer_brand_id", e);
                    setBrand_id(e);
                  }}
                  listHeight={120}
                  listItemHeight={4}
                >
                  {brandListDropdown?.map((item, i) => {
                    return (
                      <Option value={item.brand_id}>{item.brand_title}</Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}
            {data?.event_id ? (
              <div className="col-6">
                <span>
                  <Switch
                    defaultChecked={data.is_booth_at_event}
                    onClick={(value) =>
                      onHandleChange("is_booth_at_event", value)
                    }
                    className="mx-3"
                  />
                  Do they have a booth at the event?
                </span>
              </div>
            ) : null}
          </div>

          <div className={data?.event_id ? "row mt-4" : ""}>
            <div className="col-3"></div>
            <div className="col-3 ">
              {data?.event_id && (
                <Button
                  className="px-2 button-pink rounded"
                  onClick={() => setNewBrandModal(true)}
                >
                  + Create New Brand
                </Button>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Main Contact Name
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                id="main_contact_name"
                className="w-100 border-gray rounded fs-bold-14"
                defaultValue={data.main_contact_name}
                onChange={(e) =>
                  onHandleChange("main_contact_name", e.target.value)
                }
                onBlur={() =>
                  validator.current.showMessageFor("main_contact_name")
                }
              />
              {validator.current.message(
                "Main Contact Name",
                data?.main_contact_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Email Address
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                id="email"
                className="w-100 border-gray rounded fs-bold-14"
                defaultValue={data.email_address}
                onChange={(e) =>
                  onHandleChange("email_address", e.target.value)
                }
                onBlur={() => validator.current.showMessageFor("email")}
              />
              {validator.current.message(
                "email",
                data?.email_address,
                "required|email",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>
        </section>

        {/* section registration details*/}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">
                Registration Details
              </h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                Start Date & Time
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <DatePicker
                allowClear={false}
                suffixIcon={<CalenderGrey width="15" />}
                id="registration_start_date_time"
                showTime
                className="w-100"
                defaultPickerValue={
                  data && data.registration_start_date_time
                    ? moment(data.registration_start_date_time)
                    : moment()
                }
                value={
                  data && data.registration_start_date_time
                    ? moment(data.registration_start_date_time)
                    : moment()
                }
                format={"DD/MM/YYYY HH:mm"}
                onSelect={(e) => {
                  console.log("e - ", moment(e).format("YYYY-MM-DD HH:mm:ss"));
                  onHandleChange(
                    "registration_start_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor(
                    "registration_start_date_time"
                  )
                }
              />
              {validator.current.message(
                "Start Date & Time",
                data?.registration_start_date_time,
                "required",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>

          <div className="row mt-4 pb-3 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                End Date & Time
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <DatePicker
                allowClear={false}
                id="registration_end_date_time"
                disabledDate={(d) =>
                  !d ||
                  d.isSameOrBefore(moment(data?.registration_start_date_time))
                }
                disabledTime={(d) =>
                  !d || d.isSameOrBefore(data?.registration_start_date_time)
                }
                suffixIcon={<CalenderGrey width="15" />}
                showTime
                className="w-100 fs-bold-14"
                defaultPickerValue={
                  data && data.registration_end_date_time
                    ? moment(data.registration_end_date_time)
                    : moment()
                }
                value={
                  data && data.registration_end_date_time
                    ? moment(data.registration_end_date_time)
                    : moment()
                }
                format={"DD/MM/YYYY HH:mm"}
                onSelect={(e) =>
                  onHandleChange(
                    "registration_end_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  )
                }
                onBlur={() =>
                  validator.current.showMessageFor("registration_end_date_time")
                }
              />
              {validator.current.message(
                "End Date & Time",
                data?.registration_end_date_time,
                "required",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Theme</h6>
            </div>
          </div>

          <Row>
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary mt-1">
                Event Colour Theme
              </label>
            </Col>
            <Col span={6}>
              <Input
                type="color"
                id=""
                defaultValue={data.event_color_theme}
                addonBefore={data.event_color_theme}
                onChange={(e) => {
                  onHandleChange("event_color_theme", e.target.value);
                }}
                className="eventtheme_input_style border-gray rounded fs-bold-14"
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Default BTN Background
              </label>
            </Col>
            <Col span={4}>
              <Input
                type="color"
                id=""
                defaultValue={data?.default_button_background_color}
                addonBefore={data?.default_button_background_color}
                onChange={(e) => {
                  onHandleChange(
                    "default_button_background_color",
                    e.target.value
                  );
                }}
                className="eventtheme_input_style border-gray rounded  fs-bold-14"
              />
            </Col>
            <Col span={2}>
              <label htmlFor="" className="text-secondary mt-1">
                Gradient
              </label>
              <Switch
                className="mx-2"
                checked={data.is_default_button_gradient_active}
                onChange={(val) => {
                  onHandleChange("is_default_button_gradient_active", val);
                }}
              />
            </Col>
            <Col span={10}>
              {data.is_default_button_gradient_active ? (
                <>
                  <div className="">
                    <label htmlFor="" className="text-secondary">
                      Angle
                    </label>
                    <Input
                      className="ms-2"
                      style={{ width: "50px" }}
                      value={data.default_button_gradient_angle}
                      onChange={(e) => {
                        onHandleChange(
                          "default_button_gradient_angle",
                          e.target.value
                        );
                      }}
                    />

                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap  ms-2"
                    >
                      Event 2nd Color
                    </label>
                    <Input
                      type="color"
                      id=""
                      defaultValue={
                        data?.default_button_background_second_color
                      }
                      addonBefore={data?.default_button_background_second_color}
                      onChange={(e) => {
                        onHandleChange(
                          "default_button_background_second_color",
                          e.target.value
                        );
                      }}
                      className="eventtheme_input_style ms-2 border-gray rounded fs-bold-14"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Default BTN Text Colour
              </label>
            </Col>
            <Col span={6}>
              <Input
                type="color"
                id=""
                defaultValue={data?.default_button_text_color}
                addonBefore={data?.default_button_text_color}
                onChange={(e) => {
                  onHandleChange("default_button_text_color", e.target.value);
                }}
                className="eventtheme_input_style  border-gray rounded fs-bold-14"
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Button Preview
              </label>
            </Col>
            <Col span={6}>
              <Button
                style={{
                  color: data.default_button_text_color,
                  background: data.is_default_button_gradient_active
                    ? `linear-gradient(${data.default_button_gradient_angle}deg, ${data.default_button_background_color} 30%,${data.default_button_background_second_color} 100%)`
                    : `${data.default_button_background_color}`,
                  border: `1px solid ${data.default_button_text_color}`,
                }}
                className="rounded"
              >
                Example Test
              </Button>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Highlight BTN Background
              </label>
            </Col>
            <Col span={4}>
              <Input
                type="color"
                id=""
                defaultValue={data?.highlight_button_background_color}
                addonBefore={data?.highlight_button_background_color}
                onChange={(e) => {
                  onHandleChange(
                    "highlight_button_background_color",
                    e.target.value
                  );
                }}
                className="eventtheme_input_style border-gray rounded  fs-bold-14"
              />
            </Col>
            <Col span={2}>
              <label htmlFor="" className="text-secondary mt-1">
                Gradient
              </label>
              <Switch
                checked={data.is_highlight_button_gradient_active}
                onChange={(val) => {
                  onHandleChange("is_highlight_button_gradient_active", val);
                }}
                className="mx-2"
              />
            </Col>
            <Col span={10}>
              {data?.is_highlight_button_gradient_active ? (
                <>
                  <div>
                    <label htmlFor="" className="text-secondary mt-1">
                      Angle
                    </label>
                    <Input
                      className="ms-2"
                      style={{ width: "50px" }}
                      value={data.highlight_button_gradient_angle}
                      onChange={(e) => {
                        onHandleChange(
                          "highlight_button_gradient_angle",
                          e.target.value
                        );
                      }}
                    />

                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap ms-2 mt-1"
                    >
                      Event 2nd Color
                    </label>
                    <Input
                      type="color"
                      id=""
                      defaultValue={
                        data?.highlight_button_background_second_color
                      }
                      addonBefore={
                        data?.highlight_button_background_second_color
                      }
                      onChange={(e) => {
                        onHandleChange(
                          "highlight_button_background_second_color",
                          e.target.value
                        );
                      }}
                      className="eventtheme_input_style ms-2 border-gray rounded fs-bold-14"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Highlight BTN Text Colour
              </label>
            </Col>
            <Col span={6}>
              <Input
                type="color"
                id=""
                defaultValue={data?.highlight_button_text_color}
                addonBefore={data?.highlight_button_text_color}
                onChange={(e) => {
                  onHandleChange("highlight_button_text_color", e.target.value);
                }}
                className="eventtheme_input_style  border-gray rounded fs-bold-14"
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col span={6} className="text-end px-2">
              <label htmlFor="" className="text-secondary text-nowrap mt-1">
                Button Preview
              </label>
            </Col>
            <Col span={6}>
              <Button
                style={{
                  color: data.highlight_button_text_color,
                  background: data.is_highlight_button_gradient_active
                    ? `linear-gradient(${data.highlight_button_gradient_angle}deg, ${data.highlight_button_background_color} 30%,${data.highlight_button_background_second_color} 100%)`
                    : `${data.highlight_button_background_color}`,
                }}
                className="rounded"
              >
                Example Test
              </Button>
            </Col>
          </Row>

          <div className="row mt-4">
            <div className={Tablet ? "col-3 text-end" : "col-3 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Logo
              </label>
            </div>
            {data?.event_id && (
              <div className={Tablet ? "col-2" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setIsLogoPreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span>View</span>
                </Button>
                <Modal
                  title="Event Theme Logo"
                  visible={isLogoPreview}
                  onCancel={() => setIsLogoPreview(false)}
                  footer={null}
                  width={400}
                  style={{ padding: "0px" }}
                >
                  {data && data.event_logo_url ? (
                    <img
                      src={data.event_logo_url}
                      alt="wall graphics upload file"
                      className="img-fluid uploaded-image-modal-preview"
                    />
                  ) : (
                    <h4 className="fs-pink py-3 text-center">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className="h-25">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Image Upload"
                  inputType="file"
                  inputOnChange={handleLogoImageChange}
                  inputId="logo"
                  inputName="logo"
                  value=""
                  onBlur={() => validator.current.showMessageFor("logo")}
                />
              </div>
              {logoFileState ? (
                <>
                  <span className="mx-3"> {logoFileState.name} </span>
                  <span onClick={deleteEventLogoFile}>
                    <DeleteOutlined />
                  </span>
                  <img
                    src={
                      logoThumbNail
                        ? logoThumbNail
                        : logoFileState.event_logo_url
                    }
                    alt="Pre Event File"
                    className="user-img-preview"
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-3">
              {validator.current.message(
                "logo",
                logoFileState,
                "required|text",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Logo Demo Size
              </label>
            </div>
            <div className="col-3">
              <Button
                onClick={() => {
                  window.open(LOGO_DEMO.URL, "_blank");
                }}
                download={LOGO_DEMO.FILENAME}
              >
                <Download />
                <span className="ms-2">Download</span>
              </Button>
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Dates</h6>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-9 event-date-scroll-div">
              <Collapse
                bordered={false}
                defaultActiveKey={["2"]}
                expandIcon={({ isActive }) =>
                  (isActive && (
                    <MinusOutlined className="rounded minus-logo" />
                  )) || <PlusOutlined className="rounded plus-logo" />
                }
                className="site-collapse-custom-collapse"
              >
                {chatBotDetails &&
                  chatBotDetails.map((elem, index) => {
                    if (elem && !elem.is_delete)
                      return (
                        <Panel
                          header={
                            <span>
                              <span className="fw-bold">
                                Shows Days/Times - Block {index + 1}
                              </span>
                              <span className="font-pink mx-2">
                                {elem.start_time + " - " + elem.end_time}
                              </span>
                            </span>
                          }
                          key={index}
                          className="site-collapse-custom-panel"
                          extra={
                            index > 0 ? (
                              <img
                                src={bin}
                                onClick={() =>
                                  handleChatbots(
                                    index,
                                    "is_delete",
                                    !elem?.is_delete
                                  )
                                }
                                alt="bin"
                              />
                            ) : null
                          }
                        >
                          <div className="row">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                Start Date
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-6">
                              <DatePicker
                                allowClear={false}
                                id="start_date"
                                suffixIcon={<CalenderGrey width="15" />}
                                defaultValue={
                                  elem.start_date
                                    ? moment(elem?.start_date, "YYYY-MM-DD")
                                    : null
                                }
                                onChange={(e, i) =>
                                  handleChatbots(
                                    index,
                                    "start_date",
                                    moment(e).format("YYYY-MM-DD")
                                  )
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor("start_date")
                                }
                                format="DD-MM-YYYY"
                                className="w-75 fs-bold-14"
                              />
                              {validator.current.message(
                                "Start Date",
                                elem?.start_date,
                                "required",
                                { className: "text-danger py-2" }
                              )}
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                Start & End Time
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-4 d-flex m-0">
                              <div style={{ flexDirection: "column" }}>
                                <TimePicker
                                  id="start_time"
                                  format={"HH:mm"}
                                  suffixIcon={<TimeCalendar width="15" />}
                                  allowClear={false}
                                  value={
                                    elem?.start_time
                                      ? moment(elem?.start_time, "HH:mm")
                                      : null
                                  }
                                  showTime
                                  className="w-100"
                                  onSelect={(val) => {
                                    let abc = moment(val).format("HH:mm");
                                    console.log("abc = ", abc);
                                    handleChatbots(
                                      index,
                                      "start_time",
                                      moment(val).format("HH:mm")
                                    );
                                  }}
                                  onBlur={() =>
                                    validator.current.showMessageFor(
                                      "start_time"
                                    )
                                  }
                                />
                                {validator.current.message(
                                  "Start Time",
                                  elem?.start_time,
                                  "required",
                                  { className: "text-danger py-2" }
                                )}
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  marginLeft: "5px",
                                }}
                              >
                                <TimePicker
                                  id="end_time"
                                  suffixIcon={<TimeCalendar width="15" />}
                                  allowClear={false}
                                  format={"HH:mm"}
                                  disabledHours={() =>
                                    getDisabledHours(
                                      elem?.start_date,
                                      elem?.start_time
                                    )
                                  }
                                  value={
                                    elem?.end_time
                                      ? moment(elem?.end_time, "HH:mm")
                                      : null
                                  }
                                  showTime
                                  className="w-100"
                                  onSelect={(val) =>
                                    handleChatbots(
                                      index,
                                      "end_time",
                                      moment(val).format("HH:mm")
                                    )
                                  }
                                />
                                {validator.current.message(
                                  "End Time",
                                  elem?.end_time,
                                  "required",
                                  { className: "text-danger py-2" }
                                )}
                              </div>
                            </div>
                            <div className="col-5 text-start mt-1">
                              <Switch defaultChecked className=" mr-0" />
                              <span className="mx-2">
                                Use these times for all the days?
                              </span>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                End Date
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-6">
                              <DatePicker
                                allowClear={false}
                                disabledDate={(d) =>
                                  !d ||
                                  d.isSameOrBefore(moment(elem?.start_date))
                                }
                                defaultValue={
                                  elem?.end_date
                                    ? moment(elem?.end_date, "YYYY-MM-DD")
                                    : null
                                }
                                suffixIcon={<CalenderGrey width="15" />}
                                onChange={(e, i) =>
                                  handleChatbots(
                                    index,
                                    "end_date",
                                    moment(e).format("YYYY-MM-DD")
                                  )
                                }
                                format="DD-MM-YYYY"
                                className="w-75"
                              />
                              {validator.current.message(
                                "End Date",
                                elem?.end_date,
                                "required",
                                { className: "text-danger py-2" }
                              )}
                            </div>
                          </div>
                        </Panel>
                      );
                    else return null;
                  })}
              </Collapse>
            </div>
            <div className="col-3">
              <Button className="px-2 button-pink rounded" onClick={addChatBot}>
                + Add Days with different times
              </Button>
            </div>
            {validator.current.message(
              "Event Date",
              data?.event_shows_details,
              "required",
              { className: "text-danger py-2" }
            )}
          </div>
        </section>

        {/* section event setting */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Settings</h6>
            </div>
          </div>

          <div className="eventsettings_padding_left">
            <table className="eventsettings_table">
              <tr>
                <td>
                  <label className="text-secondary">
                    Registration <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_registration_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_registration_active
                        ? data.event_settings_details.is_registration_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      if (data && data.event_id) onNavigateToRegistration();
                      else toast.error("Please complete the first step");
                    }}
                    className="px-3 button-pink rounded border-gray"
                  >
                    Settings
                  </Button>
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Ticker Tape <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_ticker_tape_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_ticker_tape_active
                        ? data.event_settings_details.is_ticker_tape_active
                        : false
                    }
                    className="mx-2 invisible"
                  />
                </td>
                <td>
                  <Button className="px-3 button-pink rounded border-gray invisible">
                    Settings
                  </Button>
                </td>
                <td>
                  <Button
                    type="primary"
                    className=" button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2"> Sponser Calendar</span>
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="text-secondary mx-2">
                    Brand Tier <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_brand_tier_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_brand_tier_active
                        ? data.event_settings_details.is_brand_tier_active
                        : false
                    }
                    className="mx-2 invisible"
                  />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      if (data && data.event_id) onNavigateToTier();
                      else toast.error("Please complete the first step");
                    }}
                    className="px-3 button-pink rounded border-gray"
                  >
                    Settings
                  </Button>
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Expo Halls <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    defaultChecked={expochecked}
                    onChange={(value) => {
                      onChangeData(
                        { is_expo_hall_active: value, no_of_expo_halls: 0 },
                        "event_settings_details"
                      );
                      setExpoChecked(value);
                    }}
                    className="mx-2"
                    checked={expochecked}
                  />
                </td>
                <td>
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-50 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button
                      onClick={() => {
                        if (expochecked)
                          onHandleChange(
                            "no_of_expo_halls",
                            data?.event_settings_details.no_of_expo_halls > 0
                              ? data?.event_settings_details?.no_of_expo_halls -
                                  1
                              : 0,
                            "event_settings_details"
                          );
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      -
                    </button>
                    <Input
                      className="text-center"
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        const reg = /^-?\d*(\.\d*)?$/;
                        if (
                          reg.test(inputValue) ||
                          inputValue === "" ||
                          inputValue === "-"
                        ) {
                          onHandleChange(
                            "no_of_expo_halls",
                            parseInt(inputValue),
                            "event_settings_details"
                          );
                        }
                      }}
                      value={
                        data &&
                        data.event_settings_details &&
                        data.event_settings_details.no_of_expo_halls
                          ? data.event_settings_details.no_of_expo_halls
                          : 0
                      }
                    />
                    <button
                      onClick={() => {
                        if (expochecked)
                          onHandleChange(
                            "no_of_expo_halls",
                            data?.event_settings_details.no_of_expo_halls + 1,
                            "event_settings_details"
                          );
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      +
                    </button>
                  </div>
                </td>
                <td>
                  <Button
                    type="primary"
                    className=" button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Calendar</span>
                  </Button>
                </td>

                <td>
                  <label className="text-secondary">
                    Expo Halls 360 Upload
                  </label>
                </td>

                <td>
                  <div className="eventsettings_fileuload_style">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-4 py-2"
                      labelTitle="File Upload"
                      name="expohall-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "expoHall")}
                      onChange={(e) => console.log("eeeeeeeee", e)}
                      inputId="expohall-upload"
                      inputName="expohall-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("expohall-upload")
                      }
                    />
                  </div>
                </td>
                <td>
                  {expoHallFileState && !expoHallFileLoader ? (
                    <>
                      <span className=""> {expoHallFileState.name} </span>
                      <span onClick={() => handleFileStateDelete("expoHall")}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : expoHallFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Navigation Bar <span className="fs-pink">*</span>
                  </label>
                </td>

                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_navigation_bar_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_navigation_bar_active
                        ? data.event_settings_details.is_navigation_bar_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>

                <td>
                  <Button
                    onClick={() => {
                      if (data && data.event_id) onNavigateToReception();
                      else toast.error("Please complete the first step");
                    }}
                    className="px-3 button-pink rounded border-gray"
                  >
                    Settings
                  </Button>
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Main Auditorium <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_main_hall_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_main_hall_active
                        ? data.event_settings_details.is_main_hall_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>

                <td></td>
                <td>
                  <Button
                    type="primary"
                    className=" button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Calendar</span>
                  </Button>
                </td>

                <td>
                  <label className="text-secondary">
                    Main Auditorium 360 Upload
                  </label>
                </td>
                <td>
                  <div className="eventsettings_fileuload_style">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg  px-4 py-2"
                      labelTitle="File Upload"
                      name="mainaudi-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "mainAudi")}
                      inputId="mainaudi-upload"
                      inputName="mainaudi-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("mainaudi-upload")
                      }
                    />
                  </div>
                </td>
                <td>
                  {mainAudiFileState && !mainAudiFileLoader ? (
                    <>
                      <span> {mainAudiFileState.name} </span>
                      <span onClick={() => handleFileStateDelete("mainAudi")}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : mainAudiFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Reception <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_reception_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_reception_active
                        ? data.event_settings_details.is_reception_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      if (data && data.event_id) onNavigateToReception();
                      else toast.error("Please complete the first step");
                    }}
                    className="px-3 button-pink rounded border-gray"
                  >
                    Settings
                  </Button>
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Sat. Auditoriums <span className="fs-pink">*</span>
                  </label>
                </td>

                <td>
                  <Switch
                    defaultChecked={satAuditoriumChecked}
                    onChange={(value) => {
                      onChangeData(
                        {
                          is_sat_auditorium_active: value,
                          no_of_sat_auditorium: 0,
                        },
                        "event_settings_details"
                      );
                      setSatAuditoriumChecked(value);
                    }}
                    checked={satAuditoriumChecked}
                    className="mx-2"
                  />
                </td>
                <td>
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-50 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button
                      onClick={() => {
                        if (satAuditoriumChecked) {
                          onHandleChange(
                            "no_of_sat_auditorium",
                            data?.event_settings_details?.no_of_sat_auditorium >
                              0
                              ? data?.event_settings_details
                                  ?.no_of_sat_auditorium - 1
                              : 0,
                            "event_settings_details"
                          );
                        }
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      -
                    </button>
                    <Input
                      className="text-center"
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        const reg = /^-?\d*(\.\d*)?$/;
                        if (
                          reg.test(inputValue) ||
                          inputValue === "" ||
                          inputValue === "-"
                        ) {
                          onHandleChange(
                            "no_of_sat_auditorium",
                            parseInt(inputValue),
                            "event_settings_details"
                          );
                        }
                      }}
                      value={
                        data &&
                        data.event_settings_details &&
                        data.event_settings_details.no_of_sat_auditorium
                          ? data.event_settings_details.no_of_sat_auditorium
                          : 0
                      }
                    />
                    <button
                      onClick={() => {
                        if (satAuditoriumChecked) {
                          onHandleChange(
                            "no_of_sat_auditorium",
                            data?.event_settings_details?.no_of_sat_auditorium +
                              1,
                            "event_settings_details"
                          );
                        }
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      +
                    </button>
                  </div>
                </td>
                <td>
                  <Button
                    type="primary"
                    className=" button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Calendar</span>
                  </Button>
                </td>

                <td>
                  <label className="text-secondary">
                    Sat Auditoriums 360 Upload
                  </label>
                </td>
                <td>
                  <div className="eventsettings_fileuload_style">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg  px-4 py-2"
                      labelTitle="File Upload"
                      name="sataudi-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "satAudi")}
                      inputId="sataudi-upload"
                      inputName="sataudi-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("sataudi-upload")
                      }
                    />
                  </div>
                </td>

                <td>
                  {satAudiFileState && !satAudiFileLoader ? (
                    <>
                      <span className="">{satAudiFileState.name} </span>
                      <span onClick={() => handleFileStateDelete("satAudi")}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : satAudiFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : null}
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Social Wall <span className="fs-pink">*</span>
                  </label>
                </td>

                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_social_wall_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_social_wall_active
                        ? data.event_settings_details.is_social_wall_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>

                <td>
                  <Button
                    onClick={() => {
                      if (data && data.event_id) onNavigateToSocialWall();
                      else toast.error("Please complete the first step");
                    }}
                    className="px-3 button-pink rounded border-gray"
                  >
                    Settings
                  </Button>
                </td>

                <td>
                  <Button
                    type="primary"
                    className="button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Sponser Calendar</span>
                  </Button>
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Networking <span className="fs-pink">*</span>
                  </label>
                </td>

                <td>
                  <Switch
                    defaultChecked={networkingChecked}
                    onChange={(value) => {
                      onChangeData(
                        { is_networking_active: value, no_of_networking: 0 },
                        "event_settings_details"
                      );
                      setNetworkingChecked(value);
                    }}
                    checked={networkingChecked}
                    className="mx-2"
                  />
                </td>

                <td>
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-50 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button
                      onClick={() => {
                        if (networkingChecked) {
                          onHandleChange(
                            "no_of_networking",
                            data?.event_settings_details?.no_of_networking > 0
                              ? data?.event_settings_details?.no_of_networking -
                                  1
                              : 0,
                            "event_settings_details"
                          );
                        }
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      -
                    </button>
                    <Input
                      className="text-center"
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        const reg = /^-?\d*(\.\d*)?$/;
                        if (
                          reg.test(inputValue) ||
                          inputValue === "" ||
                          inputValue === "-"
                        ) {
                          onHandleChange(
                            "no_of_networking",
                            parseInt(inputValue),
                            "event_settings_details"
                          );
                        }
                      }}
                      value={
                        data &&
                        data.event_settings_details &&
                        data.event_settings_details.no_of_networking
                          ? data.event_settings_details.no_of_networking
                          : 0
                      }
                    />
                    <button
                      onClick={() => {
                        if (networkingChecked) {
                          onHandleChange(
                            "no_of_networking",
                            data?.event_settings_details?.no_of_networking + 1,
                            "event_settings_details"
                          );
                        }
                      }}
                      type="button"
                      className="btn  py-1 "
                    >
                      +
                    </button>
                  </div>
                </td>

                <td>
                  <Button
                    type="primary"
                    className=" button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Calendar</span>
                  </Button>
                </td>

                <td>
                  <label className="text-secondary">
                    Networking 360 Upload
                  </label>
                </td>

                <td>
                  <div className="eventsettings_fileuload_style">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg  px-4 py-2"
                      labelTitle="File Upload"
                      name="networking-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "netLounge")}
                      inputId="networking-upload"
                      inputName="networking-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("expohall-upload")
                      }
                    />
                  </div>
                </td>

                <td>
                  {netLoungeFileState && !netLoungeFileLoader ? (
                    <>
                      <span className="">{netLoungeFileState.name} </span>
                      <span onClick={() => handleFileStateDelete("netLounge")}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : netLoungeFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Concourse <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_concourse_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_concourse_active
                        ? data.event_settings_details.is_concourse_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>

                <td></td>

                <td>
                  <Button
                    type="primary"
                    className="button-pink rounded btn-svg border-gray"
                    icon={<CalenderGrey />}
                    onClick={() => window.open("/eventCalender", "_blank")}
                  >
                    <span className="mx-2">Sponser Calendar</span>
                  </Button>
                </td>
                <td></td>
                <td>
                  <div className="eventsettings_fileuload_style">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg  px-4 py-2"
                      labelTitle="File Upload"
                      name="concourse-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "concourse")}
                      onChange={(e) => console.log("eeeeeeeee", e)}
                      inputId="concourse-upload"
                      inputName="concourse-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("concourse-upload")
                      }
                    />
                  </div>
                </td>
                <td>
                  {concourseFileState && !concourseFileLoader ? (
                    <>
                      <span className="mx-3"> {concourseFileState.name} </span>
                      <span onClick={() => setConcourseFileState(null)}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : concourseFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>

              <tr>
                <td>
                  <label className="text-secondary">
                    Exterior <span className="fs-pink">*</span>
                  </label>
                </td>
                <td>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_exterior_active",
                        value,
                        "event_settings_details"
                      );
                    }}
                    checked={
                      data.event_settings_details &&
                      data.event_settings_details.is_exterior_active
                        ? data.event_settings_details.is_exterior_active
                        : false
                    }
                    className="mx-2"
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {data.event_settings_details.is_exterior_active ? (
                    <>
                      <FileUpload
                        labelClassName="rounded border-pink btn-svg  px-4 py-2"
                        labelTitle="File Upload"
                        name="exterior-upload"
                        inputType="file"
                        inputOnChange={(e) => handle360(e, "exterior")}
                        onChange={(e) => console.log("eeeeeeeee", e)}
                        inputId="exterior-upload"
                        inputName="exterior-upload"
                        onBlur={() =>
                          validator.current.showMessageFor("exterior-upload")
                        }
                      />
                    </>
                  ) : (
                    <>
                      <label className="text-secondary mx-1 pt-1">
                        Login background theme
                      </label>
                      <Input
                        type="color"
                        id=""
                        // value={data?.login_background_theme}
                        value={
                          data.event_settings_details.login_background_theme
                        }
                        addonBefore={
                          data.event_settings_details.login_background_theme
                        }
                        onChange={(e) => {
                          onHandleChange(
                            "login_background_theme",
                            e.target.value,
                            "event_settings_details"
                          );
                        }}
                        className="w-50 border-gray rounded fs-bold-14"
                      />
                    </>
                  )}
                </td>
                <td>
                  {exteriorFileState && !exteriorFileLoader ? (
                    <>
                      <span className="mx-3"> {exteriorFileState.name} </span>
                      <span onClick={() => setExteriorFileState(null)}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : exteriorFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : null}
                </td>
              </tr>
            </table>
          </div>
        </section>

        {/* section Pre-event message */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Pre-event Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "pre_event_message_active",
                      value,
                      "pre_event_messages"
                    );
                  }}
                  checked={
                    data.pre_event_messages &&
                    data.pre_event_messages.pre_event_message_active
                      ? data.pre_event_messages.pre_event_message_active
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: This video will switch OFF at the start of the event (even
                if set to ‘Active’)
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "pre_event_message_forced",
                      value,
                      "pre_event_messages"
                    );
                  }}
                  checked={
                    data.pre_event_messages &&
                    data.pre_event_messages.pre_event_message_forced
                      ? data.pre_event_messages.pre_event_message_forced
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                placeholder="Enter Title Here"
                defaultValue={
                  data.pre_event_messages &&
                  data.pre_event_messages.pre_event_message_title
                }
                className="w-50 fs-bold-14"
                onChange={(e) =>
                  onHandleChange(
                    "pre_event_message_title",
                    e.target.value,
                    "pre_event_messages"
                  )
                }
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data.pre_event_messages &&
                  data.pre_event_messages.pre_event_message_description
                }
                onChange={(e) =>
                  onHandleChange(
                    "pre_event_message_description",
                    e.target.value,
                    "pre_event_messages"
                  )
                }
              />
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Video/Image Upload
              </label>
            </div>
            {data && data.event_id && (
              <div className={Tablet ? "col-2" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setIsPreEventImgPreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span>View</span>
                </Button>

                <Modal
                  title="Wall Graphics File"
                  visible={isPreEventImgPreview}
                  onCancel={() => setIsPreEventImgPreview(false)}
                  footer={null}
                  width={1000}
                >
                  {data.pre_event_messages &&
                  data.pre_event_messages.pre_event_media_url ? (
                    isImage(data.pre_event_messages.pre_event_media_url) ? (
                      <img
                        src={data.pre_event_messages.pre_event_media_url}
                        alt="Pre Event File"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center player-wrapper">
                        <ReactPlayer
                          playing={true}
                          loop={true}
                          controls={true}
                          url={data.pre_event_messages.pre_event_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="text-center fs-pink py-3">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className="h-25">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handlePreImgChange}
                  inputId="pre"
                  inputName="pre"
                  value=""
                />
              </div>
              {preEventImg ? (
                <>
                  <span className="mx-3">{preEventImg.name}</span>
                  <span
                    onClick={() => {
                      setPreEventImg(null);
                      setPreEventThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(preEventThumbNail || preEventImg.pre_event_media_url) &&
                  (isImage(preEventThumbNail) ||
                    isImage(preEventImg.pre_event_media_url)) ? (
                    <img
                      src={
                        preEventThumbNail
                          ? preEventThumbNail
                          : preEventImg.pre_event_media_url
                      }
                      alt="Pre Event File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>

        {/* section Welcome message */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Welcome Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "welcome_message_active",
                      value,
                      "welcome_messages"
                    );
                  }}
                  checked={
                    data.welcome_messages &&
                    data.welcome_messages.welcome_message_active
                      ? data.welcome_messages.welcome_message_active
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: This video will switch ON at the start of the event (if set
                to 'Active')
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "welcome_message_forced",
                      value,
                      "welcome_messages"
                    );
                  }}
                  checked={
                    data.welcome_messages &&
                    data.welcome_messages.welcome_message_forced
                      ? data.welcome_messages.welcome_message_forced
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                onChange={(e) =>
                  onHandleChange(
                    "welcome_message_title",
                    e.target.value,
                    "welcome_messages"
                  )
                }
                defaultValue={
                  data.welcome_messages &&
                  data.welcome_messages.welcome_message_title
                }
                placeholder="Enter Title Here"
                className="w-50 fs-bold-14"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data.welcome_messages &&
                  data.welcome_messages.welcome_message_description
                }
                onChange={(e) =>
                  onHandleChange(
                    "welcome_message_description",
                    e.target.value,
                    "welcome_messages"
                  )
                }
              />
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-2 text-end">
              <span className="text-secondary ">Video/Image Upload</span>
            </div>
            {data?.event_id && (
              <div className={Tablet ? "col-2" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setisWelcomePreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span> View</span>
                </Button>

                <Modal
                  title="Wall Graphics File"
                  visible={isWelcomePreview}
                  onCancel={() => setisWelcomePreview(false)}
                  footer={null}
                  width={1000}
                >
                  {data.welcome_messages &&
                  data.welcome_messages.welcome_media_url ? (
                    isImage(data.welcome_messages.welcome_media_url) ? (
                      <img
                        src={data.welcome_messages.welcome_media_url}
                        alt="Welcome upload file"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center  player-wrapper">
                        <ReactPlayer
                          playing={true}
                          loop={true}
                          controls={true}
                          url={data.welcome_messages.welcome_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="text-center fs-pink py-3">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className="h-25">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handleWelcomeImgChange}
                  inputId="welcome"
                  inputName="welcome"
                  value=""
                />
              </div>
              {welcomeImg ? (
                <>
                  <span className="mx-3"> {welcomeImg.name} </span>
                  <span
                    onClick={() => {
                      setWelcomeImg(null);
                      setWelcomeThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(welcomeThumbNail || welcomeImg.welcome_media_url) &&
                  (isImage(welcomeThumbNail) ||
                    isImage(welcomeImg.welcome_media_url)) ? (
                    <img
                      src={
                        welcomeThumbNail
                          ? welcomeThumbNail
                          : welcomeImg.welcome_media_url
                      }
                      alt="Welcome Media File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>

        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Event Support</h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Chat</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange("is_chat_active", value, "event_support");
                    }}
                    checked={
                      data.event_support && data.event_support.is_chat_active
                        ? data.event_support.is_chat_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>

            <div className="row mt-4 pb-4">
              <div className="col-2 text-end">
                <span className="text-secondary">Video Call</span>
              </div>
              <div className="col-10  ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_video_call_active",
                        value,
                        "event_support"
                      );
                    }}
                    checked={
                      data.event_support &&
                      data.event_support.is_video_call_active
                        ? data.event_support.is_video_call_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>

            <div className="row mt-4 event-editor-dropdown-div">
              <div className="col-2 text-end mt-4">
                <span className="text-secondary mt-4">Agents</span>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="supportEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>All Agents</b>
                </label>
                <br />
                <Select
                  id="agents"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 300 }}
                  placeholder="Search to Select"
                  onChange={handleAgentsChange}
                  open={true}
                  className="border border-gray fs-bold-14 select_agents"
                  getPopupContainer={() =>
                    document.getElementById("supportEdit")
                  }
                  onBlur={() => validator.current.showMessageFor("agents")}
                  listHeight={100}
                  listItemHeight={4}
                  value="All Agents"
                >
                  {agents?.map((data) => (
                    <Option className="select-text-dark" value={data?.user_id}>
                      {data?.first_name} {data?.last_name}
                    </Option>
                  ))}
                  {agents.length === 0 && (
                    <Option disabled>
                      No agents created for organiser brand
                    </Option>
                  )}
                </Select>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="selectedAgentsEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>Tagged Agents</b>
                </label>
                <br />
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 300 }}
                  placeholder="Search Tagged Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selectedAgentsEdit")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {selectedAgents?.map((data) => {
                    return (
                      <Option value={data.user_id}>
                        {data.first_name} {data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChange(data.user_id)
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-2"></div>
              <div className="col-4">
                {data?.event_id && (
                  <>
                    <Button
                      className="px-3  rounded btn-svg"
                      onClick={() => setIsAddUserModalIsVisible1(true)}
                    >
                      <AddGroup fill="#ef0855" />
                      <span className="mx-2">Invite Agents</span>
                    </Button>
                    <Modal
                      title="Add User"
                      visible={isAddUserModalVisible1}
                      onOk={() => setIsAddUserModalIsVisible1(false)}
                      onCancel={() => setIsAddUserModalIsVisible1(false)}
                      footer={null}
                      width={1000}
                    >
                      <AddUser
                        event_id={data?.event_id}
                        onCancelFromModal={() =>
                          setIsAddUserModalIsVisible1(false)
                        }
                        onSuccessFromModal={async () => {
                          setIsAddUserModalIsVisible1(false);
                        }}
                      />
                    </Modal>
                  </>
                )}
                <Button
                  className={
                    "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                  }
                  onClick={() => {
                    if (brand_id)
                      window.open(
                        `/brandCalendar/${data?.event_id}/${brand_id}`,
                        "_blank"
                      );
                    else
                      toast.error(
                        "Brand Selection is Necessary for this action"
                      );
                  }}
                >
                  <CalenderGrey />
                  <span className="mx-2">Staffing Calendar</span>
                </Button>
              </div>
            </div>
          </section>
        ) : null}
        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Technical Support</h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Chat</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_chat_active",
                        value,
                        "event_technical_support"
                      );
                    }}
                    checked={
                      data.event_technical_support &&
                      data.event_technical_support.is_chat_active
                        ? data.event_technical_support.is_chat_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Video Call</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_video_call_active",
                        value,
                        "event_technical_support"
                      );
                    }}
                    checked={
                      data.event_technical_support &&
                      data.event_technical_support.is_video_call_active
                        ? data.event_technical_support.is_video_call_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>
            <div className="row mt-4 event-editor-dropdown-div">
              <div className="col-2 text-end mt-4">
                <span className="text-secondary mt-4">Agents</span>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4 mt-2"}
                id="techEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>All Agents</b>
                </label>
                <br />
                <Select
                  id="agents_technical"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 300 }}
                  placeholder="Search to Select"
                  onChange={handleAgentsChangetechnical}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() => document.getElementById("techEdit")}
                  onBlur={() =>
                    validator.current.showMessageFor("agents_technical")
                  }
                  value="All Agents"
                >
                  {agentsTechnical?.map((data, idx) => (
                    <Option
                      className="select-text-dark"
                      key={idx}
                      value={data.user_id}
                    >
                      {data?.first_name} {data?.last_name}
                    </Option>
                  ))}
                  {agentsTechnical.length === 0 && (
                    <Option disabled>
                      No agents created for organiser brand
                    </Option>
                  )}
                </Select>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="selectedAgentsTechnicalEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>Tagged Agents</b>
                </label>
                <br />
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 300 }}
                  placeholder="Search Tagged Agents"
                  open={true}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selectedAgentsTechnicalEdit")
                  }
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {selectedAgentsTechnical?.map((data) => {
                    return (
                      <Option value={data?.user_id}>
                        {data?.first_name} {data?.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangeTechnical(data?.user_id)
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-2"></div>
              <div className="col-4">
                <span className={data?.event_id ? "" : "d-none"}>
                  <Button
                    className="px-3  rounded btn-svg"
                    onClick={() => setIsAddUserModalVisible2(true)}
                  >
                    <AddGroup fill="#ef0855" />
                    <span className="mx-2">Invite Agents</span>
                  </Button>
                  <Modal
                    title="Add User"
                    visible={isAddUserModalVisible2}
                    onOk={() => setIsAddUserModalVisible2(false)}
                    onCancel={() => setIsAddUserModalVisible2(false)}
                    footer={null}
                    width={1000}
                  >
                    <AddUser
                      event_id={data?.event_id}
                      onCancelFromModal={() =>
                        setIsAddUserModalIsVisible1(false)
                      }
                      onSuccessFromModal={async () => {
                        setIsAddUserModalIsVisible1(false);
                      }}
                    />
                  </Modal>
                </span>
                <Button
                  className={
                    "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                  }
                  onClick={() => {
                    if (brand_id) {
                      window.open(
                        `/brandCalendar/${data?.event_id}/${brand_id}`,
                        "_blank"
                      );
                    } else
                      toast.error(
                        "Brand Selection is Necessary for this action"
                      );
                  }}
                >
                  <CalenderGrey />
                  <span className="mx-2">Staffing Calendar</span>
                </Button>
              </div>
            </div>
          </section>
        ) : null}

        <section>
          <div className="row ">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Controls Help</h6>
            </div>
          </div>
          <div className="row mt-4 border-bottom-gray pb-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Upload
              </label>
            </div>
            {data?.event_id && (
              <div className={Tablet ? "col-2" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setIsControlPreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span> View</span>
                </Button>
                <Modal
                  title="Wall Graphics File"
                  visible={isControlPreview}
                  onCancel={() => setIsControlPreview(false)}
                  footer={null}
                  width={1000}
                >
                  {data?.control_media_url ? (
                    isImage(data?.control_media_url) ? (
                      <img
                        src={data && data?.control_media_url}
                        alt="control media upload file"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center player-wrapper">
                        <ReactPlayer
                          playing={true}
                          loop={true}
                          controls={true}
                          url={data?.control_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="fs-pink py-3 text-center">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className="h-25">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handleControlImgChange}
                  inputId="control"
                  inputName="control"
                />
              </div>
              {controlImg ? (
                <>
                  <span className="mx-3"> {controlImg.name} </span>
                  <span
                    onClick={() => {
                      setControlImg(null);
                      setControlThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(controlThumbNail || controlImg.control_media_url) &&
                  (isImage(controlThumbNail) ||
                    isImage(controlImg.control_media_url)) ? (
                    <img
                      src={
                        controlThumbNail
                          ? controlThumbNail
                          : controlImg.control_media_url
                      }
                      alt="Control Image File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>
        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Feedback Survey</h6>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-12 mt-1 pb-4">
                <span className="fs-16">
                  NB: This survey will be available to all users in the
                  "Feedback" section.
                </span>
              </div>
              <div className="col-2 text-end">
                <label className="text-secondary">Survey</label>
              </div>
              <div className="col-4">
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={
                    data?.event_survey_id ? data?.event_survey_id : null
                  }
                  placeholder="Select Survey"
                  className="w-100 fs-bold-14"
                  onChange={(e) => onHandleChange("event_survey_id", e)}
                  listHeight={120}
                  listItemHeight={4}
                >
                  {surveyList &&
                    surveyList.map((item, i) => {
                      return (
                        <Option value={item.survey_id}>
                          {item.survey_title}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-4 text-start">
                {/* <Link
                to={{
                  pathname: "addnewsurvey"
                }} */}
                {/* > */}
                {data?.event_id && (
                  <Button
                    onClick={() => setIsSurveyModalVisible(true)}
                    className="px-3 button-pink rounded"
                  >
                    + Create New Survey
                  </Button>
                )}
                {/* </Link> */}
              </div>
            </div>
          </section>
        ) : null}
        <Modal
          visible={isSurveyModalVisible}
          onCancel={() => setIsSurveyModalVisible(false)}
          footer={null}
          width={1000}
        >
          <div className="p-2">
            <AddNewSurvey
              event_id={data?.event_id}
              isFromEvent={true}
              onCancelFromModal={() => setIsSurveyModalVisible(false)}
              onSuccessFromModal={async () => {
                const surveyData = await getSurveys();
                setSurveyList(surveyData);
                setIsSurveyModalVisible(false);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  } else return null;
};

export default EventEditor;
