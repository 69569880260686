/**
 * @name MainContainer
 * Main container for the vista, this also controls any content that appears over the top of it.
 */

import React, { useContext, useEffect, useState } from "react";
// import AppContext from "../../contexts/AppContext";
import Vista from "../../components/vista";
import OverlayContainer from "../../components/overlayContainer";
// import Menu from "../../components/menu";
import { Wrapper, Container, MenuContainer } from "./styles";
import {
	Layout,
	Breadcrumb,
	Menu,
	Dropdown,
	Space,
	Button,
	message,
} from "antd";
import ExpoHalls from "../../../../ExpoMap/ExpoHalls";
import Brands from "../../../../ExpoMap/Brands";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import { Chat } from "../../../../../components/svgJS/Chat";
import { ExpoHallSvg } from "../../../../../components/svgJS/ExpoHallSvg";

import { Menu1 } from "../../../../../components/svgJS/Menu1";
import { Chartdiagram } from "../../../../../components/svgJS/Chartdiagram";
import { Calendar3 } from "../../../../../components/svgJS/Calendar3";
import { Conference } from "../../../../../components/svgJS/Conference";
import { Meeting } from "../../../../../components/svgJS/Meeting";
import VistaContext from "../../contexts/VistaContext";
import AppContext, { ACTIONS } from "../../contexts/AppContext";
import "./index.css";
import { useDispatch } from "react-redux";
import { setHeaderData } from "../../../../../redux/slice/headerData";

const { Header, Content, Sider } = Layout;

const MainContainer = (props) => {
	const dispatchRedux = useDispatch();
	const [selectedMenuItem, setSelectedMenuItem] = useState("");

	const { activeWindow } = useContext(AppContext);

	const { hotspot } = useContext(VistaContext);
	const { dispatch } = useContext(AppContext);

	useEffect(() => {
		console.log("Main container props", props);
		if (props.props.location.pathname === "/login") {
			// dispatch({
			// 	type: ACTIONS.SET_VISTA_SRC,
			// 	value: {
			// 		src: "/vista/exterior-publish/index.html",
			// 		currentPosition: "exteriorPublished",
			// 	},
			// });
			setSelectedMenuItem("0");
		} else if (props.props.location.pathname === "/expomap") {
			setSelectedMenuItem("4");
		}
	}, [props]);

	useEffect(() => {
		console.log("menu changed to: ", selectedMenuItem);
		if (selectedMenuItem === "0") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/exterior-publish/index.html",
					currentPosition: "exteriorPublished",
				},
			});
			if (props.props.location.pathname === "/expomap") {
				dispatch({
					type: ACTIONS.SET_ACTIVE_WINDOW,
					value: `expomap`,
				});
			}
		} else if (selectedMenuItem === "1") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/concourse-publish/index.html?media-name=6",
					currentPosition: "concoursePublished",
				},
			});
			dispatch({
				type: ACTIONS.SET_ACTIVE_WINDOW,
				value: `expomap`,
			});
		} else if (selectedMenuItem === "3") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/concourse-publish/index.html?media-name=2",
					currentPosition: "concoursePublished",
				},
			});
		} else if (selectedMenuItem === "4") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/concourse-publish/index.html",
					currentPosition: "concoursePublished",
				},
			});
		} else if (selectedMenuItem === "5") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/auditorium-publish/index.html",
					currentPosition: "auditoriumPublished",
				},
			});
		} else if (selectedMenuItem === "6") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/satellite-publish/index.html",
					currentPosition: "satellitePublished",
				},
			});
		} else if (selectedMenuItem === "7") {
			dispatch({
				type: ACTIONS.SET_VISTA_SRC,
				value: {
					src: "/networking-publish/index.html",
					currentPosition: "networkingPublished",
				},
			});
		}
	}, [selectedMenuItem]);

	const componentsSwitch = (key) => {
		console.log(key, "key value");
		switch (key) {
			// return (
			// 	<div className="expo_item_wrapper">
			// 			<ExpoHalls />
			// 		</div>
			// 	);
			case "2":
				return (
					<div className="expo_item_wrapper">
						<Brands />
					</div>
				);
			case "0":
			case "1":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				return <Vista props={props} />;
			case "8":
				return <Vista url={"/vista/auditorium-publish/index.html"} />;
			default:
				break;
		}
	};

	const handleMenuClick = (e) => {
		setSelectedMenuItem(e.key);

		switch (e.key) {
			case "1":
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Expo Halls"],
					}),
				);
				break;
			case "2":
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Brands"],
					}),
				);
				break;
			case "3":
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Reception"],
					}),
				);
				break;
			case "4":
				setSelectedMenuItem("4");
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Concourse"],
					}),
				);
				break;
			case "5":
				setSelectedMenuItem("5");
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Main Auditorium"],
					}),
				);
				break;
			case "6":
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Satellite Auditorium"],
					}),
				);
				break;
			case "7":
				setSelectedMenuItem("7");
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Networking Lounges"],
					}),
				);
				break;
			case "8":
				dispatchRedux(
					setHeaderData({
						title: "Expo Map",
						breadcrumbs: ["Expo Map", "Event Calendar"],
					}),
				);
				break;
			default:
				break;
		}
	};

	const menu = (
		<Menu
			onClick={handleMenuClick}
			items={[
				{
					label: <span className="ms-3">Expo Halls</span>,
					key: "1",
					icon: <ExpoHallSvg />,
				},
				{
					label: <span className="ms-3">Brands</span>,
					key: "2",
					icon: <Menu1 />,
				},
				{
					label: <span className="ms-3">Reception</span>,
					key: "3",
					icon: <Menu1 />,
				},
				{
					label: <span className="ms-3">Concourse</span>,
					key: "4",
					icon: <Meeting />,
				},
				{
					label: <span className="ms-3">Main Auditorium</span>,
					key: "5",
					icon: <Conference />,
				},
				{
					label: <span className="ms-3">Satellite Auditorium</span>,
					key: "6",
					icon: <Meeting />,
				},
				{
					label: <span className="ms-3"> Networking Lounges</span>,
					key: "7",
					icon: <Chartdiagram />,
				},
				{
					label: <span className="ms-3">Event Calendar</span>,
					key: "8",
					icon: <Calendar3 />,
				},
			]}
		/>
	);

	return (
		<>
			<Wrapper isLogin={props.props.location.pathname}>
				{/* <Menu /> */}
				<MenuContainer>
					{props.props.location.pathname !== "/login" && (
						<Dropdown className="ms-2" overlay={menu}>
							<Button className="w-100 vista_expomap_btn">
								<span className="">Quick jump to</span>
								<DownOutlined />
							</Button>
						</Dropdown>
					)}
				</MenuContainer>
				<Container>
					{/** React overlay */}
					{activeWindow && <OverlayContainer props={props} />}

					{/** Vista */}

					{componentsSwitch(selectedMenuItem)}

					{/* <Vista url={props.url} /> */}
				</Container>
			</Wrapper>
		</>
	);
};

export default MainContainer;
