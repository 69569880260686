import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.less";
import "./assets/css/custom.css";
import { BrowserRouter } from "react-router-dom";
import Loader from "./views/common/Loader";
import MainRoute from "./routes/index";
import { loginSlice } from "./redux/slice/login";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
    dispatch(loginSlice.actions.reAuthenticate());
    let obj = localStorage.getItem("btnColorData");
    let btnColorData = JSON.parse(obj);
    
      window.less
      .modifyVars({
        "@primary-color": localStorage.getItem("event_color_theme"),
        "@default_button_background_color": btnColorData?.default_button_background_color,
            "@default_button_background_second_color": btnColorData?.default_button_background_second_color,
            "@default_button_text_color": btnColorData?.default_button_text_color,
            "@highlight_button_background_color": btnColorData?.highlight_button_background_color,
            "@highlight_button_background_second_color": btnColorData?.highlight_button_background_second_color,
            "@highlight_button_text_color": btnColorData?.highlight_button_text_color,
            "@default_button_gradient_angle": btnColorData?.default_button_gradient_angle,
            "@highlight_button_gradient_angle": btnColorData?.highlight_button_gradient_angle
      })
      .then(() => {
        // console.log("Theme updated successfully");
      });
    
  

  
  }, []);

  return (
    <BrowserRouter>
      {loading ? <Loader /> : <MainRoute />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
