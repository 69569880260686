import React, { useState, useEffect } from "react";
import Header from "../../views/common/Header";
import SideBar from "../../views/common/SideBar";
import { Button, Divider, Layout } from "antd";
import { fetchTickerList } from "../../utils/commonApis";
import { TIME_FORMAT } from "../constsnts/common.constants";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import { BoothRed } from "../svgJS/BoothRed";
import Marquee from "react-fast-marquee";

const AdminLayout = (props) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [tickerToDisplay, setTickerToDisplay] = useState(null);
  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(async () => {
    const response = await fetchTickerList();
    console.log("response ticker list = ", response);
    if (response && isArray(response) && !isEmpty(response)) {
      response.forEach((item) => {
        let ele = item.ticker_master;
        if (ele.start_time && ele.end_time) {
          let start_time_date = moment.utc(ele.start_time).format("YYYY-MM-DD");
          let end_time_date = moment.utc(ele.end_time).format("YYYY-MM-DD");

          let start_time = moment(
            moment.utc(ele.start_time).format("HH:mm"),
            TIME_FORMAT
          );
          let end_time = moment(
            moment.utc(ele.end_time).format("HH:mm"),
            TIME_FORMAT
          );

          const start = new Date(start_time_date);
          const end = new Date(end_time_date);
          let current_date = new Date(moment().format("YYYY-MM-DD"));
          if (current_date >= start && current_date <= end) {
            if (
              moment() === start_time ||
              moment() === end_time ||
              (moment().isAfter(start_time) && moment().isBefore(end_time))
            ) {
              setTickerToDisplay(ele);
            }
          }
        }
      });
    }
  }, [props]);

  return (
    <Layout>
      <SideBar
        isSidebarCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <Layout
        className={
          "site-layout" + (isSidebarCollapsed ? " ml-80px" : " ml-200px")
        }
        style={{ zIndex: 0 }}
      >
        <Header
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />
        <Layout.Content
          style={{
            margin: "15px 16px",
            minHeight: 280,
          }}
        >
          {props.children}
        </Layout.Content>
        {tickerToDisplay && tickerToDisplay.ticker_name ? (
          <Layout.Footer
            className="d-flex flex-row"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div className="layout_footer_left d-flex align-content-center">
              <Marquee pauseOnHover gradient={false}>
                <span className="marquee_text">
                  {tickerToDisplay.ticker_name}
                </span>
                <Divider type="vertical" />
                <span className="marquee_text">
                  {tickerToDisplay.ticker_fixed_title}
                </span>
              </Marquee>
            </div>
            <div className="layout_footer_right d-flex align-content-center align-items-center">
              <Button className="secondary_button">
                <BoothRed />
                <span style={{ marginLeft: "2%" }}>Go To Booth</span>
              </Button>
            </div>
          </Layout.Footer>
        ) : null}
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
