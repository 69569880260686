import React, { useState, useEffect, useContext } from "react";
import "./ExpoHalls.css";
import { Row, Col, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { getBrands } from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import {
	setHeaderDefaultEventId,
	setHeaderEventList,
} from "../../redux/slice/headerData";
import AppContext, { ACTIONS } from "../Vista/src/contexts/AppContext";
import { hallData, standsData } from "../Vista/src/apiData";

import { cloneDeep } from "lodash";
import {
	setVistaBrandData,
	setVistaExpoHallSequence,
} from "../../redux/slice/vista";

const { Option } = Select;

const ExpoHalls = (props) => {
	const [selectedBoothBorder, setSelectedBoothBorder] = useState({
		display_name: "",
		flag: false,
	});
	const dispatch = useDispatch();
	var CryptoJS = require("crypto-js");
	const { dispatch: AppDispatch } = useContext(AppContext);
	const [userType, setUserType] = useState("");
	const [expoHallDetails, setExpoHallDetails] = useState([]);
	const [allExpoHalls, setAllExpoHalls] = useState([]);
	const [loading, setLoading] = useState(false);
	const [brandData, setBrandData] = useState([]);
	const [eventList, setEventList] = useState([]);
	const event_id = useSelector((state) => state?.header?.event_id); //from header component
	const [fields, setFields] = useState({
		event_id: null,
		brand_id: null,
		brand_tier: null,
	});

	useEffect(() => {
		const ciphertext = localStorage.getItem("user_type");
		var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
		var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		setUserType(decryptedData);
	}, []);

	const getExpoHallDetails = async (event_id) => {
		setLoading(true);
		const response = await ApiCall(
			"GET",
			"",
			"/admin/expohallsmap/details/get",
			event_id,
		);
		if (response?.data?.code === 200) {
			setLoading(false);
			console.log("Expo hall Data", response.data.data);
			return response.data.data;
		} else {
			setLoading(false);
			return [];
		}
	};

	useEffect(() => {
		getEventList();
	}, [brandData]);

	useEffect(() => {
		//Constructor called when event in header component is changed
		if (event_id) setFields({ ...fields, event_id });
	}, [event_id]);

	useEffect(async () => {
		if (fields.event_id) {
			const brandData = await getBrands({
				event_id: fields?.event_id,
			});
			if (brandData.length === 1) {
				setFields({ ...fields, brand_id: brandData[0].brand_id });
			}
			setBrandData(brandData);
		}
	}, [fields?.event_id]);

	const handleFieldsChange = (val, name) => {
		setFields({ ...fields, [name]: val });
	};

	useEffect(async () => {
		dispatch(setHeaderDefaultEventId(fields.event_id ?? props.event_id));
		let responseData = await getExpoHallDetails({
			event_id: fields?.event_id ?? props.event_id,
		});

		const alphabet_arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
		if (responseData) {
			let finalExpoHalls = [];
			responseData.forEach((parentElement, parentIndex) => {
				if (!isEmpty(parentElement.expo_hall_mappings)) {
					let new10HallMappings = [];
					let alreadyPresentLength = parentElement.expo_hall_mappings.length;

					for (let i = 0; i < 10; i++) {
						new10HallMappings.push({
							display_name:
								(parentIndex + 1).toString() + "-" + alphabet_arr[i],
							isElementPresent: false,
							sequence: i + 1,
						});
					}

					let existedMappings = [];
					parentElement.expo_hall_mappings.forEach(
						(childElement, childIndex) => {
							existedMappings.push({
								...childElement,
								display_name:
									(parentIndex + 1).toString() + "-" + alphabet_arr[childIndex],
								isElementPresent: true,
							});
						},
					);
					existedMappings = existedMappings.sort(
						(a, b) => a.sequence - b.sequence,
					);

					const sequenceArr = existedMappings.map((ele) => ele.sequence);
					if (sequenceArr)
						sequenceArr.forEach((ele, indexHere) => {
							new10HallMappings[ele - 1] = existedMappings[indexHere];
						});

					finalExpoHalls.push({
						...parentElement,
						expo_hall_mappings: new10HallMappings,
					});
				} else {
					let new10HallMappings = [];
					for (let i = 0; i < 10; i++) {
						new10HallMappings.push({
							display_name:
								(parentIndex + 1).toString() + "-" + alphabet_arr[i],
							isElementPresent: false,
						});
					}
					finalExpoHalls.push({
						...parentElement,
						expo_hall_mappings: new10HallMappings,
					});
				}
			});

			console.log("finalExpoHalls = ", finalExpoHalls);

			let allExpoHalls = cloneDeep(finalExpoHalls);
			let totalFinalAllHalls = [];
			allExpoHalls &&
				allExpoHalls.forEach((parentEle, indexParentHall) => {
					if (
						parentEle &&
						parentEle.expo_hall_mappings &&
						parentEle.expo_hall_mappings.length === 10
					) {
						const clonedMappings1 = cloneDeep(parentEle.expo_hall_mappings);
						const clonedMappings2 = cloneDeep(parentEle.expo_hall_mappings);
						let clonedMappingsF5 = clonedMappings1.splice(0, 5);
						let clonedMappingsS5 = clonedMappings2.splice(5, 5);

						let upperArr = [];
						let lowerArr = [];
						let combineArr = [];

						clonedMappingsF5.forEach((ele, ind) => {
							if (ind % 2 === 0) lowerArr.push(ele); //A C E
							else upperArr.push(ele); //B D
						});

						clonedMappingsS5.forEach((ele, ind) => {
							if (ind % 2 === 0) upperArr.push(ele); //B D F H J
							else lowerArr.push(ele); //A C E G I
						});

						if (indexParentHall % 2 !== 0)
							combineArr = [...lowerArr, ...upperArr];
						else combineArr = [...upperArr.reverse(), ...lowerArr.reverse()];
						totalFinalAllHalls.push({
							...parentEle,
							expo_hall_mappings: combineArr,
						});
					} else totalFinalAllHalls.push(parentEle);
				});
			console.log(
				"totalFinalAllHalls after all process = ",
				totalFinalAllHalls,
			);
			if (totalFinalAllHalls) setAllExpoHalls(totalFinalAllHalls);
			// setAllExpoHalls(finalExpoHalls);
		}
		setExpoHallDetails(responseData);
	}, [fields.event_id]);

	let expoHallSelection;

	if (props.getDataFromExpoHall) {
		expoHallSelection = (expo_hall_configuration_id, elemIndex) => {
			console.log(
				"expo_hall_configuration_id, elemIndex 111 =",
				expo_hall_configuration_id,
				elemIndex,
			);

			props?.getDataFromExpoHall(expo_hall_configuration_id, elemIndex);
		};
	}

	const getEventList = () => {
		ApiCall("get", null, "/admin/events/dropdown/get", "")
			.then((response) => {
				const { rows } = response?.data?.data;
				if (rows.length == 1) {
					setFields({ ...fields, event_id: rows[0].event_id });
				}
				setEventList(rows);
				dispatch(setHeaderEventList(rows));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	if (loading) return <CommonLoader />;
	else
		return (
			<>
				<div className={props.isFromVista ? "bg-white" : ""}>
					<div className="expohalls_header ">
						<Row className="h-100">
							<Col
								className="d-flex justify-content-center align-items-center "
								span={8}
							>
								<div className="d-flex position-relative w-75">
									<SearchOutlined className="expohalls_searchicon" />
									<Select
										showSearch
										className="expohalls_input_search"
										placeholder="Search Exibitors"
										optionFilterProp="children"
										bordered={false}
										value={fields.brand_id}
										disabled={brandData && brandData.length < 2 ? true : false}
									>
										{brandData &&
											brandData?.map((data) => {
												return (
													<Option value={data.brand_id}>
														{data.brand_title}
													</Option>
												);
											})}
									</Select>
								</div>
							</Col>
							<Col className="d-flex align-items-center" span={8}>
								<p className="text-center fs-12">
									Click the brand icon you wish to visit or type <br /> the
									brand name in the search box
								</p>
							</Col>

							{(userType && userType === "SUPER ADMIN") ||
							userType === "CUSTOMER" ? (
								<Col className="d-flex mt-2 flex-column px-3" span={6}>
									<div className="d-flex position-relative w-100 mt-2">
										<Select
											showSearch
											placeholder="Select Event"
											name="event_id"
											allowClear
											className="brands_event_search"
											disabled={
												eventList && eventList.length < 2 ? true : false
											}
											value={fields.event_id}
											onChange={(val) => handleFieldsChange(val, "event_id")}
											optionFilterProp="children"
											listHeight={120}
											listItemHeight={4}
											bordered={false}
										>
											{eventList &&
												eventList?.map((data) => {
													return (
														<Option value={data.event_id}>
															{data.event_name}
														</Option>
													);
												})}
										</Select>
									</div>
								</Col>
							) : (
								""
							)}
						</Row>
					</div>
					<div className="expohalls_container ">
						{/* Top Block  */}
						<div className="expohalls_topblock"></div>
						{/* /Top Section/ */}
						{allExpoHalls &&
							allExpoHalls.map((item, i) => {
								return (
									<>
										<div
											className="expohalls_hall"
											onClick={() => {
												dispatch(setVistaExpoHallSequence(item.sequence));
												if (
													props.isFromVista &&
													item.expo_hall_configuration_id
												) {
													// alert(item.expo_hall_configuration_id);
													AppDispatch({
														type: ACTIONS.SET_ACTIVE_WINDOW,
														value: "",
													});
													AppDispatch({
														type: ACTIONS.SET_HALL,
														value: hallData[0],
													});
												}
											}}
										>
											{item.expo_hall_mappings.map((elem, elemIndex) => {
												return (
													<>
														{props === null ? (
															<div
																className={
																	selectedBoothBorder.display_name ===
																		elem.display_name && !elem.logo_url
																		? "expohalls_booth booth_border"
																		: "expohalls_booth "
																}
																onClick={(e) => {
																	e.stopPropagation();
																	dispatch(setVistaBrandData(elem));
																	if (
																		elem.expo_hall_mapping_id &&
																		props.isFromVista
																	) {
																		console.log(elem.expo_hall_mapping_id);
																		// alert(elem.expo_hall_mapping_id)
																	}

																	if (!elem?.logo_url) {
																		setSelectedBoothBorder({
																			display_name: elem?.display_name,
																			flag: true,
																		});
																	}

																	expoHallSelection(
																		item.expo_hall_configuration_id,
																		elem.sequence + 1,
																	);
																}}
															>
																{elem.logo_url || !isEmpty(elem.logo_url) ? (
																	<img
																		className="expohall_booth_logo"
																		src={elem.logo_url}
																	/>
																) : (
																	// <span>{elem.logo_url}</span>
																	<span>{elem.display_name}</span>
																)}
															</div>
														) : (
															<div
																className={
																	selectedBoothBorder.display_name ===
																		elem.display_name && !elem?.logo_url
																		? "expohalls_booth booth_border"
																		: "expohalls_booth "
																}
																onClick={(e) => {
																	e.stopPropagation();
																	dispatch(setVistaBrandData(elem));
																	if (
																		elem.expo_hall_mapping_id &&
																		elem.brand_tier &&
																		props.isFromVista
																	) {
																		AppDispatch({
																			type: ACTIONS.SET_ACTIVE_WINDOW,
																			value: "",
																		});
																		let selectedStand =
																			standsData[elem.brand_tier - 1];
																		selectedStand.exit = Math.ceil(
																			elem.sequence / 2,
																		);
																		AppDispatch({
																			type: ACTIONS.SET_STAND,
																			value: selectedStand,
																		});
																	}

																	if (!elem?.logo_url) {
																		setSelectedBoothBorder({
																			display_name: elem?.display_name,
																		});
																	}
																	if (props.getDataFromExpoHall) {
																		expoHallSelection(
																			item.expo_hall_configuration_id,
																			elem.sequence + 1,
																		);
																	}
																}}
															>
																{elem.logo_url || !isEmpty(elem.logo_url) ? (
																	<img
																		className="expohall_booth_logo"
																		src={elem.logo_url}
																	/>
																) : (
																	// <span>{elem.logo_url}</span>
																	<span>{elem.display_name}</span>
																)}
															</div>
														)}
													</>
												);
											})}

											<div className="expohall_hallname">
												<h6> {item.hall_title ? item.hall_title : "NA"}</h6>
											</div>
										</div>
									</>
								);
							})}

						{expoHallDetails.length < 1 && (
							<div className="mx-auto text-dark text-bold mt-2 fs-18">
								Opps No Expo Hall Available For The Event
							</div>
						)}

						{expoHallDetails.length === 1 ? (
							<>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
							</>
						) : expoHallDetails.length === 2 ? (
							<>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
							</>
						) : expoHallDetails.length === 3 ? (
							<>
								<div className="expohalls_hall">
									<Empty className="mx-auto" />
								</div>
							</>
						) : (
							""
						)}

						<div className="expohalls_bottomblock"></div>
					</div>
				</div>
			</>
		);
};

export default ExpoHalls;
