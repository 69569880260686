import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import {
  Row,
  Col,
  Carousel,
  Button,
  Select,
  DatePicker,
  Divider,
  Breadcrumb,
  Tooltip,
  Avatar,
  Input,
  Empty,
  Modal,
} from "antd";
import moment from "moment";
import { toast } from "react-toastify";

import CommonLoader from "../../components/Widgets/CommonLoader";

import { SearchOutlined } from "@ant-design/icons";

import { isEmpty } from "lodash";

import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { auto } from "@popperjs/core";
import { Download } from "../../components/svgJS/Download";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import { User } from "../../components/svgJS/User";

//Redux
import {
  getEventNetworkingLoungeDetails,
  getNetworkingLoungeSessionDetails,
  getNetworkingSessionTableDetails,
} from "../../redux/slice/networking";
import { useDispatch, useSelector } from "react-redux";
import NetworkTableUI from "../networkTable";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI, videoRequestAPI } from "../../utils/commonApis";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { getEvents } from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";

const Networking = (props) => {
  let CryptoJS = require("crypto-js");
  const localData_event_id = parseInt(localStorage.getItem("event_id"));

  //Role State
  const [userType, setUserType] = useState();
  const [disableEvent, setDisableEvent] = useState(false);

  const { Option } = Select;
  const dateFormat = "LL";
  const { TextArea } = Input;

  const joinTableRef = useRef(null);

  const dispatch = useDispatch();
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [showEmpty, setShowEmpty] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [sessionData, setSessionData] = useState([]);
  const [cardSponsorData, setCardSponsorData] = useState([]);
  const [showTable, setShowTable] = useState(
    props?.fromEventCalendar ? true : false
  );
  const [joinTableBtn, setJoinTableBtn] = useState(false);
  const [joinTableIDs, setJoinTableIDs] = useState({
    networking_configuration_id: props?.networking_configuration_id ?? null,
    networking_session_id: props?.networking_session_id ?? null,
  });
  const [eventid, setEventID] = useState();

  //Sponsored BY data
  const [sponsorData, setSponsorData] = useState([]);

  const eventNetworkingLoungeDetails = useSelector(
    (state) => state.networking.eventNetLoungeData
  );

  const networkingLoungeSessionDetails = useSelector(
    (state) => state.networking.networkingLoungeSessionData
  );

  const networkingSessionTableDetails = useSelector(
    (state) => state.networking.networkingTableData
  );

  // const [date, setDate] = useState(moment());
  const [forceUpdate, setForceUpdate] = useState(false);

  const [eventList, setEventList] = useState([]);
  const [date, setDate] = useState(moment());

  // const [date, setDate] = useState(
  //   moment("29-04-2022 17:09", "DD-MM-YYYY HH:mm")
  // );

  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });

  const [message_text, set_message_text] = useState("");

  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });

  const messageValidator = useRef(new SimpleReactValidator());

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const [eventNetLoungeData, setEventNetLoungeData] = useState([]);

  const initializeEventNetLoungeData = () => {
    let date1 = moment(date).format("YYYY-MM-DD");

    const arr = eventNetworkingLoungeDetails?.filter((item) => {
      let date2 = moment.utc(item?.start_date_time).format("YYYY-MM-DD");
      if (date1 === date2) {
        return item;
      }
    });

    setEventNetLoungeData(arr);
  };

  useEffect(async () => {
    const eventsData = await getEvents();
    if (eventsData.length == 1) {
      setEventID(eventsData[0].event_id);
    }
    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(user_type);
    if (
      user_type === "DELEGATE" ||
      user_type === "SPEAKER" ||
      user_type === "BRAND ADMIN"
    ) {
      const events = eventsData.filter((item) => {
        return item.event_id === localData_event_id;
      });

      setEventList(events);
      let id = events[0].event_id;
      setEventID(id);
      dispatch(
        getEventNetworkingLoungeDetails({
          event_id: id,
        })
      );
      setDisableEvent(true);
    } else {
      setEventList(eventsData);
    }

    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (eventNetworkingLoungeDetails && eventid) {
      initializeEventNetLoungeData();
    }
  }, [eventNetworkingLoungeDetails]);

  useEffect(() => {
    if (eventNetLoungeData) {
      handleFilterChange(0);
    }
  }, [eventNetLoungeData]);

  useEffect(() => {
    if (date && eventid) {
      dispatch(
        getEventNetworkingLoungeDetails({
          event_id: eventid,
        })
      );
    }
  }, [date]);

  useEffect(() => {
    if (networkingSessionTableDetails) {
      handleTableFilterChange(0);
    }
  }, [networkingSessionTableDetails]);

  useEffect(() => {
    if (networkingLoungeSessionDetails && eventid) {
      setCurrentAvailableSessions();
      setCurrentAvailableSponsors();
    }
  }, [networkingLoungeSessionDetails, date]);

  useEffect(() => {
    if (props?.fromEventCalendar) {
      dispatch(getNetworkingSessionTableDetails(joinTableIDs));
    }
  }, []);

  const setCurrentAvailableSessions = () => {
    const { session_details } = networkingLoungeSessionDetails;
    const card_sponsorDatas = [];

    let date1 = moment(date).format("YYYY-MM-DD");

    const sessData = session_details?.filter((elem, i) => {
      let date2 = moment.utc(elem?.start_date_time).format("YYYY-MM-DD");

      let nowTime = moment(date).format("HH:mm:ss");
      let startTime = moment.utc(elem?.start_date_time).format("HH:mm:ss");
      let endTime = moment.utc(elem?.end_date_time).format("HH:mm:ss");

      if (date1 === date2) {
        if (nowTime > endTime) {
          //To filter out if Session is over
          return;
        } else {
          card_sponsorDatas.push(elem?.networking_lounge_sponsoring_brands);
          return elem;
        }
      }
    });

    if (sessData?.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
    setSessionData(sessData);
    initializeJoinTableIDValues(sessData);
    setCardSponsorData(card_sponsorDatas);
  };

  const callTableSessionDetails = () => {};

  const initializeJoinTableIDValues = (sessData) => {
    if (sessData?.length !== 0) {
      let obj = sessData?.find((data) => {
        let nowTime = moment(date).format("HH:mm:ss");
        let startTime = moment.utc(data?.start_date_time).format("HH:mm:ss");
        let endTime = moment.utc(data?.end_date_time).format("HH:mm:ss");

        if (nowTime >= startTime && nowTime <= endTime) {
          return data;
        }
      });

      if (obj) {
        setJoinTableBtn(true);
        setJoinTableIDs({
          networking_configuration_id: obj?.networking_configuration_id,
          networking_session_id: obj?.networking_session_id,
          event_id: eventid,
        });
        // setJoinTableIDs({
        //   networking_configuration_id: 113,
        //   networking_session_id: 3
        // });
      } else {
        setJoinTableBtn(false);
      }
    }
  };

  const setCurrentAvailableSponsors = () => {
    const { sponsor_details, default_sponsor_details } =
      networkingLoungeSessionDetails;

    let date1 = moment(date).format("YYYY-MM-DD");

    const sponsData = sponsor_details?.filter((elem, i) => {
      let date2 = moment.utc(elem?.start_date_time).format("YYYY-MM-DD");

      let nowTime = moment(date).format("HH:mm:ss");
      let startTime = moment.utc(elem?.start_date_time).format("HH:mm:ss");
      let endTime = moment.utc(elem?.end_date_time).format("HH:mm:ss");

      if (date1 === date2) {
        if (nowTime >= startTime && nowTime <= endTime) {
          return elem;
        } else {
          return;
        }
      }
    });
    if (sponsData?.length === 0) {
      sponsData.push(default_sponsor_details);
    }
    setSponsorData(sponsData);
  };

  const handleFilterChange = (index) => {
    if (eventNetLoungeData) {
      const item = eventNetLoungeData.find((elem, i) => {
        return i === index;
      });

      if (item) {
        setLoading(true);
        dispatch(
          getNetworkingLoungeSessionDetails({
            networking_configuration_id: item?.networking_configuration_id,
          })
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const [tableData, setTableData] = useState({});

  const handleTableFilterChange = (index) => {
    if (networkingSessionTableDetails) {
      const currentTableData = networkingSessionTableDetails.find((elem, i) => {
        return i === index;
      });

      if (currentTableData) {
        setTableData(currentTableData);
      }
    }
    setLoadingTable(false);
  };

  const searchSessions = (e) => {
    // setFilteredSessions([]);
    let searchTerm = e.target.value.toLowerCase().trim();

    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm)) {
      sessionData &&
        sessionData?.forEach((item) => {
          if (item?.session_title?.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(item);
          }
        });
    }

    setFilteredSessions(allSearchedData);
  };

  const text = (
    <div className="d-flex p-2 networking_tooltipContent">
      <div>
        <span className="fs-18">John Richards</span> <br />
        <span>Del</span>
      </div>
      <Avatar
        shape="square"
        size={64}
        className="ms-2"
        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
      />
    </div>
  );

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <>
        <div className="networking_next_arrow" onClick={onClick}>
          <i class="arrow right"></i>
        </div>
      </>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <>
        <div className="networking_prev_arrow" onClick={onClick}>
          <i class="arrow left"></i>
        </div>
      </>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleAddtoCalendar = async (sessId, hallId) => {
    let body = {
      event_id: eventid,
      hall_type: "NETWORKING",
      hall_id: hallId,
      session_id: sessId,
    };

    const response = await ApiCall(
      "POST",
      body,
      "/admin/mycalendar/create",
      ""
    );
    if (response?.data?.code === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  };

  return (
    <>
      <div className={props.isFromVista ? "bg-80vh bg-white p-4" : "bg-80vh"}>
        <div className="d-flex justify-content-between flex-wrap">
          {props?.fromEventCalendar == true ? (
            " "
          ) : (
            <div className="d-flex flex-wrap">
              {props.isFromVista ? (
                ""
              ) : (
                <div className="mb-2 ">
                  <h4 className="mb-1">Networking</h4>
                  <Breadcrumb className="my-2" separator=">">
                    <Breadcrumb.Item>
                      <span>Networking</span>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item onClick={() => setShowTable(false)}>
                      Networking Lounge
                    </Breadcrumb.Item>
                    {showTable ? (
                      <Breadcrumb.Item>Networking Table</Breadcrumb.Item>
                    ) : (
                      ""
                    )}
                  </Breadcrumb>
                </div>
              )}

              {showTable ? (
                <>
                  <Carousel
                    afterChange={handleTableFilterChange}
                    className="networking_carousel "
                    arrows
                    {...settings}
                  >
                    {networkingSessionTableDetails &&
                      networkingSessionTableDetails?.map((data, i) => {
                        return (
                          <div className="d-flex justify-content-center align-items-center mt-2">
                            <h6>{data?.table_name}</h6>
                          </div>
                        );
                      })}
                  </Carousel>
                </>
              ) : eventNetLoungeData && eventNetLoungeData?.length !== 0 ? (
                <>
                  <Carousel
                    afterChange={handleFilterChange}
                    className="networking_carousel "
                    arrows
                    {...settings}
                  >
                    {eventNetLoungeData &&
                      eventNetLoungeData?.map((data, i) => {
                        return (
                          <div className="d-flex justify-content-center align-items-center mt-2">
                            <h6>{data?.lounge_title}</h6>
                          </div>
                        );
                      })}
                  </Carousel>
                </>
              ) : (
                ""
              )}

              {showTable ? (
                <></>
              ) : joinTableBtn ? (
                <Button
                  ref={joinTableRef}
                  onClick={() => {
                    setShowTable(true);
                    setLoadingTable(true);
                    dispatch(getNetworkingSessionTableDetails(joinTableIDs));
                  }}
                  className="join_table mx-4 fs-16 mb-3"
                >
                  <BoothRed />
                  <span className="ms-2">Join Table</span>
                </Button>
              ) : (
                ""
              )}
            </div>
          )}
          {props?.fromEventCalendar == true ? (
            " "
          ) : props.isFromVista &&
            (userType !== "SUPER ADMIN" || userType !== "CUSTOMER") ? (
            ""
          ) : userType === "SUPER ADMIN" || userType === "CUSTOMER" ? (
            <div className="mb-3">
              <span className="mx-2">Event Name</span>
              <Select
                showSearch
                className="audi_event_search"
                placeholder="Select Events"
                optionFilterProp="children"
                disabled={disableEvent}
                bordered={false}
                value={eventid}
                listHeight={120}
                listItemHeight={4}
                onChange={(val) => {
                  setEventID(val);
                  dispatch(
                    getEventNetworkingLoungeDetails({
                      event_id: val,
                    })
                  );
                }}
              >
                {eventList &&
                  eventList.map((data, index) => {
                    return (
                      <Option key={"event_" + index} value={data.event_id}>
                        {data.event_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>

        {showTable ? (
          <NetworkTableUI
            networkingSessionTableDetails={networkingSessionTableDetails}
            currentTableData={tableData}
            loadingTable={loadingTable}
          />
        ) : (
          <>
            {eventid ? (
              <div className="networking_container w-100 d-flex">
                <div className="networking_left_container d-flex flex-column  p-3">
                  <h5 className="mb-3 mx-auto">Sponsored by</h5>

                  <img
                    className="networking_img mb-3 mx-auto"
                    src={sponsorData && sponsorData?.[0]?.logo_url}
                  />

                  <div className="d-flex justify-content-center  w-100 mb-3 mx-auto">
                    {sponsorData?.[0]?.is_asset_enable &&
                    sponsorData?.[0]?.is_asset_enable !== "NA" &&
                    sponsorData?.[0]?.asset_file_url ? (
                      <Tooltip placement="right" title="Download">
                        <Button
                          onClick={() =>
                            (window.location.href =
                              sponsorData?.[0]?.asset_file_url)
                          }
                          className="ms-2 border"
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    {sponsorData?.[0]?.is_booth_link_enable &&
                    sponsorData?.[0]?.is_booth_link_enable !== "NA" ? (
                      <Tooltip placement="right" title="Visit Booth">
                        <Button className="border ms-2">
                          <Booth />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    {sponsorData?.[0]?.is_chat_enable &&
                    sponsorData?.[0]?.is_chat_enable !== "NA" ? (
                      <Tooltip placement="right" title="Chat">
                        <Button
                          className="border ms-2"
                          onClick={() => {
                            setMessageModal({
                              isOpen: true,
                              channel_id: sponsorData?.[0]?.channel_id,
                            });
                          }}
                        >
                          <Chat />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    {messageModal.isOpen ? (
                      <Modal
                        title={<b>Send Message</b>}
                        visible={messageModal.isOpen}
                        onOk={() => clearMessageAndModal()}
                        onCancel={() => clearMessageAndModal()}
                        footer={[
                          <Button
                            className="px-3 button-pink rounded"
                            key="cancel"
                            onClick={() => clearMessageAndModal()}
                          >
                            Cancel
                          </Button>,
                          <Button
                            className="px-3 rounded"
                            key="save"
                            type="primary"
                            onClick={() =>
                              callSendMessageAPI(messageModal.channel_id)
                            }
                          >
                            Send
                          </Button>,
                        ]}
                        className="message_modal"
                      >
                        <Row className="ticker_configuration_row mt-2">
                          <Col span={6} className="ticker_configuration_label">
                            <p>
                              Message
                              <RedAsterick />
                            </p>
                          </Col>
                          <Col span={12}>
                            <TextArea
                              name="message_text"
                              id="message_text"
                              className="ticker_configuration_inputDescription"
                              placeholder="Enter a message"
                              rows={4}
                              value={message_text}
                              onChange={(e) => set_message_text(e.target.value)}
                              onBlur={() =>
                                messageValidator.current.showMessageFor(
                                  "message_text"
                                )
                              }
                            />
                            {messageValidator.current.message(
                              "Message",
                              message_text,
                              "required",
                              { className: "text-danger py-1" }
                            )}
                          </Col>
                        </Row>
                      </Modal>
                    ) : null}
                    {sponsorData?.[0]?.zone_demo_room_link &&
                    sponsorData?.[0]?.is_demo_room_enable !== "NA" ? (
                      <Tooltip placement="right" title="Demo Room">
                        <Button
                          onClick={() =>
                            window
                              .open(
                                sponsorData[0]?.zone_demo_room_link,
                                "_blank"
                              )
                              .focus()
                          }
                          className="border ms-2"
                          disabled={
                            sponsorData && !sponsorData[0]?.is_demo_room_enable
                          }
                        >
                          <Video />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    {sponsorData?.[0]?.is_video_call_enable &&
                    sponsorData?.[0]?.is_video_call_enable !== "NA" ? (
                      <Tooltip placement="right" title="Next Available Agent">
                        <Button
                          onClick={async () => {
                            let channel_id = sponsorData?.[0]?.channel_id;
                            const response = await videoRequestAPI(channel_id);
                            if (response.data.code === 200)
                              toast.success(response.data.message);
                            else toast.error(response.data.message);
                          }}
                          className="border ms-2"
                        >
                          <User />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                  <h6 className="mb-3 mx-auto">Agenda</h6>
                  <DatePicker
                    className="networking_date mb-2"
                    value={date}
                    format={dateFormat}
                    onChange={(val) => {
                      setDate(val);
                    }}
                  />
                  <Input
                    placeholder="Search by Session"
                    className="net_search_input"
                    onChange={(e) => searchSessions(e)}
                    prefix={<SearchOutlined />}
                  />

                  <div className=" mt-2 w-100">
                    {filteredSessions?.map((item) => {
                      return (
                        <div className="net_options mt-2 custom_border">
                          <span>{item?.session_title}</span>
                        </div>
                      );
                    })}
                  </div>

                  <Divider />

                  {sessionData &&
                    sessionData?.map((data) => {
                      return (
                        <>
                          <div className="d-flex position-relative ">
                            <div className="w-25 h-25">
                              <p className="position-absolute top-0 start-0 m-0">
                                {moment
                                  .utc(data?.start_date_time)
                                  .format("HH:mm")}{" "}
                              </p>
                              <p className="position-absolute bottom-0 start-0 m-0">
                                {moment
                                  .utc(data?.end_date_time)
                                  .format("HH:mm")}{" "}
                              </p>
                            </div>
                            <div className="networking_session d-flex flex-column p-2 ms-2">
                              <span className="text-white">
                                {" "}
                                {data?.session_title
                                  ? data?.session_title
                                  : "NA"}
                              </span>
                              <span className="text-white">
                                {" "}
                                {data?.session_description
                                  ? data?.session_description
                                  : "NA"}
                              </span>
                              <span className="text-white">
                                {moment
                                  .utc(data?.start_date_time)
                                  .format("HH:mm")}{" "}
                                -{" "}
                                {moment
                                  .utc(data?.end_date_time)
                                  .format("HH:mm")}{" "}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="networking_right_container w-100  d-flex  ms-4">
                  {!loading ? (
                    <>
                      {showEmpty ? (
                        <div className="w-100 d-flex justify-content-center align-items-center">
                          <Empty
                            description={
                              <span className="fs-pink">
                                No Upcoming Sessions
                              </span>
                            }
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <Row>
                        {console.log(
                          "All Sessions",
                          networkingLoungeSessionDetails
                        )}
                        {console.log("Current Sessions", sessionData)}
                        {sessionData &&
                          sessionData?.map((data) => {
                            let nowTime = moment(date).format("HH:mm:ss");
                            let startTime = moment
                              .utc(data?.start_date_time)
                              .format("HH:mm:ss");
                            let endTime = moment
                              .utc(data?.end_date_time)
                              .format("HH:mm:ss");

                            return (
                              <>
                                <Col
                                  className="networking_col mb-2"
                                  offset={1}
                                  span={10}
                                >
                                  <div>
                                    <h5>
                                      {nowTime >= startTime &&
                                      nowTime <= endTime
                                        ? "Up Now"
                                        : "Up Next"}
                                    </h5>
                                    {/* Card 1 */}
                                    <div className="bg-white p-3">
                                      <span>Title :</span>
                                      <h5>
                                        {data?.session_title
                                          ? data?.session_title
                                          : "NA"}
                                      </h5>
                                      <span>Time :</span>
                                      <h6>
                                        {moment
                                          .utc(data?.start_date_time)
                                          .format("HH:mm")}{" "}
                                        -
                                        {moment
                                          .utc(data?.end_date_time)
                                          .format("HH:mm")}
                                      </h6>
                                      <span>Description :</span>
                                      <h6>{data?.session_description}</h6>
                                      <Row className="mb-3">
                                        <Col span={12}>
                                          <span className="opacity-75">
                                            Seats per Table :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            {data?.seats_per_table}
                                          </span>{" "}
                                          <br />
                                          <span className="opacity-75">
                                            Shuffle Time :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            {data?.mins_per_shuffle
                                              ? data?.mins_per_shuffle
                                              : "NA"}
                                          </span>{" "}
                                          <br />
                                          <span className="opacity-75">
                                            Waterfall (Intro) Time :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            {data?.seconds_per_intro
                                              ? data?.seconds_per_intro
                                              : "NA"}{" "}
                                            seconds
                                          </span>{" "}
                                          <br />
                                          <span className="opacity-75">
                                            Attending Live Currently :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            NA
                                          </span>{" "}
                                          <br />
                                        </Col>
                                        <Col span={12}>
                                          <span className="opacity-75">
                                            Speed Dating Enabled? :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            {data?.is_speed_dating_enabled
                                              ? "Yes"
                                              : "No"}
                                          </span>{" "}
                                          <br />
                                          <span className="opacity-75">
                                            Waterfall Intros Enabled :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            {data?.is_waterfall_intros_enabled
                                              ? "Yes"
                                              : "No"}
                                          </span>{" "}
                                          <br />
                                          <span className="opacity-75">
                                            Provisionally Attending :
                                          </span>{" "}
                                          <br />
                                          <span className="text-dark fs-bold-14">
                                            NA
                                          </span>{" "}
                                          <br />
                                        </Col>
                                      </Row>
                                      {nowTime >= startTime &&
                                      nowTime <= endTime ? (
                                        <Button
                                          className="networking_join_sessionBtn"
                                          onClick={
                                            () => joinTableRef.current.click()
                                            // window
                                            //   .open(
                                            //     data?.join_session_url,
                                            //     "_blank"
                                            //   )
                                            //   .focus()
                                          }
                                        >
                                          <span className="ms-3">
                                            <Booth />
                                            <span className="ms-2">
                                              Join Session Now
                                            </span>
                                          </span>
                                        </Button>
                                      ) : (
                                        <>
                                          {data?.is_iCal_download_enabled ? (
                                            <Button className="networking_downloadBtn">
                                              <Download />
                                              <span className="ms-1">
                                                Download iCal{" "}
                                              </span>
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                          {data?.added_to_my_calendar ? (
                                            <Button
                                              onClick={() =>
                                                handleAddtoCalendar(
                                                  data?.networking_session_id,
                                                  data?.networking_configuration_id
                                                )
                                              }
                                              className="networking_calendarBtn ms-2"
                                            >
                                              <Calendar6 />
                                              <span className="ms-2">
                                                Add to My Calendar{" "}
                                              </span>
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </div>

                                    <Carousel
                                      className="networking_card_carousel w-100  bg-white mt-3"
                                      arrows
                                      {...settings}
                                    >
                                      {cardSponsorData &&
                                        cardSponsorData[0]?.map((data) => {
                                          return (
                                            <>
                                              <div className="w-100 d-flex  align-items-center mt-2">
                                                <div className=" mx-2">
                                                  <img
                                                    width={140}
                                                    height={150}
                                                    className="img-responsive"
                                                    src={data?.logo_url}
                                                    // src="https://www.emotivebrand.com/wp-content/uploads/2016/09/tumblr_o05v3eZmyT1ugn1wu_og_1280-1080x675.png"
                                                  />
                                                </div>
                                                <div className="d-flex flex-column w-100">
                                                  {data?.is_assets_enabled &&
                                                  data?.zone_assets?.[0]
                                                    ?.asset_file_url ? (
                                                    <Tooltip
                                                      placement="right"
                                                      title="Download"
                                                    >
                                                      <Button
                                                        onClick={() =>
                                                          (window.location.href =
                                                            data?.zone_assets[0]?.asset_file_url)
                                                        }
                                                        className="networking_pdfBtn mb-3"
                                                      >
                                                        <Download />

                                                        <span className="ms-2 fs-16">
                                                          sponsorasset.pdf
                                                        </span>
                                                      </Button>
                                                    </Tooltip>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <div className="d-flex justify-content-evenly w-100 mb-3">
                                                    {data?.is_booth_link_enabled ? (
                                                      <Tooltip
                                                        placement="right"
                                                        title="Visit Booth"
                                                      >
                                                        <Button className="border p-1 ms-2">
                                                          <Booth />
                                                        </Button>
                                                      </Tooltip>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {data?.is_chat_enabled &&
                                                    data?.channel_id ? (
                                                      <Tooltip
                                                        placement="right"
                                                        title="Chat"
                                                      >
                                                        <Button
                                                          className="ms-2"
                                                          onClick={() => {
                                                            setMessageModal({
                                                              isOpen: true,
                                                              channel_id:
                                                                data?.channel_id,
                                                            });
                                                          }}
                                                          className="border p-1"
                                                        >
                                                          <Chat />
                                                        </Button>
                                                      </Tooltip>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {data?.zone_demo_room_link &&
                                                    data?.is_demo_room_enabled ? (
                                                      <Tooltip
                                                        placement="right"
                                                        title="Demo room"
                                                      >
                                                        <Button
                                                          className="ms-2"
                                                          onClick={() => {
                                                            window
                                                              .open(
                                                                data?.zone_demo_room_link,
                                                                "_blank"
                                                              )
                                                              .focus();
                                                          }}
                                                          className="border p-1"
                                                        >
                                                          <Video />
                                                        </Button>
                                                      </Tooltip>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {data?.is_video_call_enabled ? (
                                                      <Tooltip
                                                        placement="right"
                                                        title="Next Available Agent"
                                                      >
                                                        <Button
                                                          className="ms-2"
                                                          onClick={async () => {
                                                            let channel_id =
                                                              data?.channel_id;
                                                            const response =
                                                              await videoRequestAPI(
                                                                channel_id
                                                              );
                                                            if (
                                                              response.data
                                                                .code === 200
                                                            )
                                                              toast.success(
                                                                response.data
                                                                  .message
                                                              );
                                                            else
                                                              toast.error(
                                                                response.data
                                                                  .message
                                                              );
                                                          }}
                                                          className="border p-1"
                                                        >
                                                          <User />
                                                        </Button>
                                                      </Tooltip>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </Carousel>
                                  </div>
                                </Col>
                              </>
                            );
                          })}
                      </Row>
                    </>
                  ) : (
                    <CommonLoader />
                  )}
                </div>
                <div
                  style={{
                    height: "500px",
                    width: "50px",
                    overflow: "auto",
                    position: "fixed",
                    right: 0,
                  }}
                  className="bg-white   ms-2 mt-5 "
                  id="net_tooltip"
                >
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip   p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip  p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                  <div className="networking_tooltip  p-1">
                    <Tooltip placement="left" title={text}>
                      <Avatar
                        shape="square"
                        src="https://media.istockphoto.com/photos/portrait-of-handsome-latino-african-man-picture-id1007763808?k=20&m=1007763808&s=612x612&w=0&h=q4qlV-99EK1VHePL1-Xon4gpdpK7kz3631XK4Hgr1ls="
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <h4 className="fs-pink text-center mt-5">
                  Please Select an Event to display Sessions
                </h4>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Networking;
