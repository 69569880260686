import React from "react";

export const ImgUpload = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="17.773"
        viewBox="0 0 20 17.773"
        className="mx-1"
      >
        <g
          id="_027-cloud-computing"
          data-name="027-cloud-computing"
          transform="translate(0 -28.5)"
        >
          <g
            id="Group_1195"
            data-name="Group 1195"
            transform="translate(5.434 35.19)"
          >
            <g
              id="Group_1194"
              data-name="Group 1194"
              transform="translate(0 0)"
            >
              <path
                id="Path_49252"
                data-name="Path 49252"
                d="M154.892,219.817l-2.559-2.58a2.485,2.485,0,0,0-3.532,0l-2.559,2.58a.83.83,0,0,0,1.179,1.169l2.275-2.294v8.06a.83.83,0,1,0,1.66,0v-8.144l2.358,2.377a.83.83,0,0,0,1.179-1.169Z"
                transform="translate(-146 -216.5)"
              />
            </g>
          </g>
          <g
            id="Group_1197"
            data-name="Group 1197"
            transform="translate(0 28.5)"
          >
            <g
              id="Group_1196"
              data-name="Group 1196"
              transform="translate(0 0)"
            >
              <path
                id="Path_49253"
                data-name="Path 49253"
                d="M18.047,34.246v-.512A5.286,5.286,0,0,0,12.722,28.5a5.378,5.378,0,0,0-3.481,1.273,5.252,5.252,0,0,0-1.449,1.981,3.523,3.523,0,0,0-1.581-.374A3.451,3.451,0,0,0,2.734,34.8v.7A3.445,3.445,0,0,0,0,38.829a3.409,3.409,0,0,0,3.438,3.418h.273a.768.768,0,1,0,0-1.536H3.438a1.858,1.858,0,0,1-1.875-1.882A1.917,1.917,0,0,1,3.51,36.948h.006A.775.775,0,0,0,4.3,36.18V34.8a1.9,1.9,0,0,1,1.914-1.882,1.93,1.93,0,0,1,1.435.638.79.79,0,0,0,.805.229A.772.772,0,0,0,9,33.163a3.768,3.768,0,0,1,7.481.572v.945a.766.766,0,0,0,.415.678,2.827,2.827,0,0,1-.706,5.282.768.768,0,0,0-.588.92.782.782,0,0,0,.935.578,4.482,4.482,0,0,0,2.472-1.52,4.33,4.33,0,0,0-.966-6.371Z"
                transform="translate(0 -28.5)"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
