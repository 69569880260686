/**
 * @name Popup
 * Pop up container
 * @param {children} ReactNode
 * @param {onClose} Function
 */

import React, { useContext, useEffect, useState } from "react";
import {Button} from "antd";
import PropTypes from "prop-types";
import AppContext, { ACTIONS } from "../../contexts/AppContext";
import ReactPlayer from "react-player/lazy";
import {
	ShadowBox,
	PopupContainer,
	PopupHeader,
	PopupContent,
	CloseButton,
} from "./styles";
import SocialWall from "../../../../socialWall";
import Networking from "../../../../networking";
import LoginBox from "../../../../login/LoginBox";
import Auditorium from "../../../../auditorium/Auditorium";
import EventCalendar from "../../../../EventCalender/index";
import ExpoHalls from "../../../../ExpoMap/ExpoHalls";
import BrandZoneDetails from "../../../../ExpoMap/BrandZoneDetails";
import { getAudiSourceDetails, getBrandZoneData } from "./Api/api";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../../../../../components/Widgets/CommonLoader";
import moment from "moment";
import { VistaPopupFrame } from "./styles";


const Popup = (props) => {
	const { children, onClose, name, url } = props;
	console.log(name, "------Popup props-=");
	const { dispatch } = useContext(AppContext);
	const [isFromVista, setIsFromVista] = useState(true);
	let event_id = localStorage.getItem("event_id");
	const [zoneData, setZoneData] = useState([]);
	const brandObj = useSelector((state) => state?.vistaBrand?.brandObj); //from header component
	const [showJoinBtn, setShowJoinBtn] = useState(false);
	const [joinSessUrl, setJoinSessUrl] = useState("");	
	const [loading, setLoading] = useState(false);


	useEffect(async () => {
		if (name.includes(`hallbooth-${name.split("-")[1]}`)) {
			console.log("booth_zone_id", name.split("-")[1])
			console.log("event_id", event_id);
			console.log("Brand Obj", brandObj);
			let data = await getBrandZoneData({
				booth_zone_id: name.split("-")[1],
				event_id: event_id,
				brand_id: brandObj.brand_id
			});

			if (data) {
				setZoneData(data);
			} else {
				setZoneData(null)
			}
		}
	
		if(name.includes("audimeet")) {
		setLoading(true);
	 	let data = await getAudiSourceDetails({
				event_id: event_id,
				current_timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
				auditorium_type: "MAIN"
			})
			if(data?.join_session_url) {
				setJoinSessUrl(data?.join_session_url);

				window.open(data.join_session_url, "_blank")
				handleOnClose()

			}
			setLoading(false);
		}
	}, [name]);


	const handleOnClose = () => {
		if (onClose) {
			onClose();
			return;
		}

		dispatch({
			type: ACTIONS.SET_ACTIVE_WINDOW,
			// value: { value: "", assetUrl: "" }
			value: "",
		});
	};

	return (
		<ShadowBox>
			<PopupContainer>
				<PopupHeader>
					<CloseButton type="button" onClick={handleOnClose}>
						X
					</CloseButton>
				</PopupHeader>
				<PopupContent>
					{name === "login" && (
						<LoginBox
							isRenderLogin={true}
							loginProps={props.props.props.props}
						/>
					)}
					{name === "socialwall" && <SocialWall isFromVista={isFromVista} />}

					{name === "networking" && <Networking isFromVista={isFromVista} />}

					{name === "auditorium" && <Auditorium isFromVista={isFromVista} />}

					{name === "eventcalendar" && (
						<EventCalendar isFromVista={isFromVista} />
					)}

					{name === "expomap" && <ExpoHalls isFromVista={isFromVista} />}



					{
						name === "sponsorbooth" && (
							<BrandZoneDetails vistaZoneData={zoneData} isFromVista={isFromVista} />
						)
					}

					{
						name === "reception" && (
							<BrandZoneDetails vistaZoneData={zoneData} isFromVista={isFromVista} />
						)
					}
					{name.includes("hallbooth") && (

						<BrandZoneDetails

							vistaZoneData={zoneData}
							isFromVista={isFromVista}
						/>
					)}

					
					
					{name.includes("audimeet") && (
						<div className="w-100 d-flex justify-content-center align-items-center">
							{loading ? (
								<div className="w-25">
									<CommonLoader />
								</div>
							):(
									<h6 className="mt-4">No Session</h6>

							)
							}
						</div>		
					
					)}

					{/* {name.includes("audimeet") && (
						!loading ? (
							<div className={`w-100 h-100 bg-white ${!joinSessUrl?"flex_center":""}`} >
								{
									joinSessUrl? (
									<VistaPopupFrame src={joinSessUrl}/>
									):(
										<h5 className="text-center">No Session</h5>
									)
								}
							</div>	
						):(
							<CommonLoader/>
						)
							
					)} */}



					{/* {
							name === "image" && <img style={{ width: "100%", height: "100%" }} src={"https://worldfinancialreview.com/wp-content/uploads/2022/03/Eventos-virtuales-1080x675-1.jpg"}></img>
						}
						{
							name === "video" && <ReactPlayer url={"https://www.youtube.com/watch?v=linlz7-Pnvw"} />
						}
						{
							name === "default" && { children }
                	} */}
				</PopupContent>
			</PopupContainer>
		</ShadowBox>
	);
};

Popup.defaultProps = {
	onClose: null,
};

Popup.propTypes = {
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func,
};

export default Popup;
