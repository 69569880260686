import React, { useState, useEffect } from "react";
import { Button, Steps, message, Breadcrumb } from "antd";
import "./event.css";
import EventEditor from "./ViewEventsDetails/EventEditor";
import RegistrationAndCommunication from "./ViewEventsDetails/RegistrationAndCommunication";
import NavigationAndReception from "./ViewEventsDetails/NavigationAndReception";
import SocialWall from "./ViewEventsDetails/SocialWall";
import { ApiCall } from "../../utils/api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Tiers from "./ViewEventsDetails/Tiers";

function ViewNewEvent() {
  const location = useLocation();
  const { Step } = Steps;
  var CryptoJS = require("crypto-js");
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(true);

  const getEventData = async () => {
    const response = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get?event_id=${location?.eventId?.event_id ?? 441
      }`,
      ""
    );
    console.log("result in view event", response.data);
    setLoading(false);
    if (response?.data?.code === 200) {
      setEventData(response?.data?.data);
    } else {
      console.log("in error");
      toast.error(response?.data?.message);
    }
  };

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE" || decryptedData === "BRAND ADMIN") {
      window.location.href = '/pagenotfound'
    }


    getEventData();
    console.log("location ", location, location?.eventId?.event_id);
  }, []);

  const steps = [
    {
      title: "Event Editor",
      content: <EventEditor data={eventData.editor_details} />,
    },
    {
      title: "Registration and Communication",
      content: (
        <RegistrationAndCommunication
          data={eventData.registration_and_communication_details}
        />
      ),
    },
    {
      title: "Tiers",
      content: <Tiers tierData={eventData.tier_details} />,
    },
    {
      title: "Navigation + Reception",
      content: (
        <NavigationAndReception
          data={eventData.navigation_plus_reception_details}
        />
      ),
    },
    {
      title: "Social Wall",
      content: <SocialWall data={eventData.social_wall_details} />,
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onChange = (current) => {
    setCurrentStep(current);
  };
  if (loading) {
    return null;
  } else
    return (
      <div className="site-layout-background">
        <div className="customer-management-form">
          <div className="background-color-gray">
            <h4 className="fs-24">View Event Details</h4>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/eventmanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/eventmanagement">Event Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>View Event Details</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Steps
            type="navigation"
            current={currentStep}
            onChange={onChange}
            className="site-navigation-steps"
          >
            {/* //  status="process" */}
            {/* //  status="wait" */}

            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="px-4 bg-white">
            <div className="steps-content">{steps[currentStep].content}</div>
            <div className="steps-action py-4">
              {currentStep > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => next()}
                  className="justify-content-end"
                >
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => message.success("Processing complete!")}
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default ViewNewEvent;
