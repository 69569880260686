import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const initialState = {
  eventList: [],
  loading: false,
};

export const getEventList = createAsyncThunk(
  "eventManagement/getEventList",
  async (params) => {
    const response = await ApiCall("GET", "", "/admin/events/get", params);
    // console.log("response getEventList = ", response);
    const checkIfError = JSON.parse(JSON.stringify(response));
    if (checkIfError.name === "Error") {
      toast.error(checkIfError.message);
      return [];
    } else return response.data.data;
  }
);
export const changeStatus = createAsyncThunk(
  "eventManagement/changeStatus",

  async (body) => {
    // console.log('paramsAPIBrand', params)

    await ApiCall("PUT", body, "/admin/events/status/update");

    const response = await ApiCall("GET", "", "/admin/events/get");

    // console.log('response of editcustomer  ', response)
    return response.data.data;
  }
);

export const eventManagementSlice = createSlice({
  name: "eventManagement",
  initialState,
  reducers: {},
  extraReducers: {
    [getEventList.pending]: (state) => {
      state.loading = true;
    },
    [getEventList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.eventList = payload;
    },
    [getEventList.rejected]: (state) => {
      state.loading = false;
    },

    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },

    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      state.eventList = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const eventReducer = eventManagementSlice.reducer;

// export const { fetchEventListing} = eventManagementSlice.actions

// export default eventManagementSlice.reducer
