import { configureStore } from "@reduxjs/toolkit";
import { eventReducer } from "./slice/eventManagement";
import { surveyReducer } from "./slice/surveyManagement";
import { loginReducer } from "./slice/login";
import { brandReducer } from "./slice/brandManagementSlice";
import { customerReducer } from "./slice/customerManagementSlice";
import { createEventReducer, getEventReducer } from "./slice/createEvent";
import { registerEventReducer } from "./slice/registerEvent";
import { tierEventReducer } from "./slice/tierEvent";
import { userReducer } from "./slice/userManagementSlice";
import { navigationAndReceptionEventReducer } from "./slice/navigationAndReceptionEvent";
import { socialWallEventReducer } from "./slice/socialWallEvent";
import { customReducer } from "./slice/customerData";
import { updateEventReducer } from "./slice/updateEvent";
import { updateRegisterEventReducer } from "./slice/updateRegisterEvent";
import { updateTierEventReducer } from "./slice/updateTierEvent";
import { updateNavigationAndReceptionEventReducer } from "./slice/updateNavigationAndReceptionEvent";
import { updateSocialWallEventReducer } from "./slice/updateSocialWallEvent";
import { usersReducer } from "./slice/getUsers";
import { createAssetsReducer } from "./slice/createAssets";
import logger from "redux-logger";
import { zoneReducer } from "./slice/zoneManagementSlice";
import editEventReducer from "./slice/editEvent.js";
import createEventNewReducer from "./slice/createEventNew.js";
import { registerReducer } from "./slice/register";
import { eventCalenderReducer } from "./slice/eventCalender";
import { peopleReducer } from "./slice/people";
import { profileReducer } from "./slice/myProfile";
import { audiReducer } from "./slice/auditorium";
import { networkingReducer } from "./slice/networking";
import { headerReducer } from "./slice/headerData";
import { vistaBrandReducer } from "./slice/vista";

export const store = configureStore({
  reducer: {
    eventManagement: eventReducer,
    surveyManagement: surveyReducer,
    login: loginReducer,
    brandManagement: brandReducer,
    customerManagement: customerReducer,
    createEvent: createEventReducer,
    registerEvent: registerEventReducer,
    tierEvent: tierEventReducer,
    userManagement: userReducer,
    navigationAndReceptionEvent: navigationAndReceptionEventReducer,
    socialWallEvent: socialWallEventReducer,
    customerData: customReducer,
    updateEvent: updateEventReducer,
    updateRegisterEvent: updateRegisterEventReducer,
    updateTierEvent: updateTierEventReducer,
    updateNavigationAndReceptionEvent: updateNavigationAndReceptionEventReducer,
    updateSocialWallEvent: updateSocialWallEventReducer,
    getUsers: usersReducer,
    creteAssets: createAssetsReducer,
    zoneManagement: zoneReducer,
    getEventData: getEventReducer,
    editEventData: editEventReducer,
    createEventData: createEventNewReducer,
    register: registerReducer,
    eventCalender: eventCalenderReducer,
    people: peopleReducer,
    profile: profileReducer,
    auditorium: audiReducer,
    networking: networkingReducer,
    header: headerReducer,
    vistaBrand: vistaBrandReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  serializableCheck: false
});
