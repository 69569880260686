import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  updateNavigationAndReceptionEventList: {},
  loading: false,
};

export const updateNavigationAndReceptionEvent = createAsyncThunk(
  "updateNavigationAndReceptionEvent/updateNavigationAndReceptionEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/navigationplusreception/update",
      "",
      ""
    );
    console.log("create response updateNavigationAndReceptionEvent", response);

    return response;
  }
);

export const updateNavigationAndReceptionEventSlice = createSlice({
  name: "updateNavigationAndReceptionEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [updateNavigationAndReceptionEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateNavigationAndReceptionEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.updateNavigationAndReceptionEventList = payload;
    },
    [updateNavigationAndReceptionEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const updateNavigationAndReceptionEventReducer =
  updateNavigationAndReceptionEventSlice.reducer;
