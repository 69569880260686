import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  updateRegisterEventList: {},
  loading: false,
};

export const updateRegisterEvent = createAsyncThunk(
  "updateRegisterEvent/updateRegisterEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/registrationandcommunication/update",
      "",
      ""
    );
    console.log("create response updateRegisterEvent", response);

    return response;
  }
);

export const updateRegisterEventSlice = createSlice({
  name: "updateRegisterEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [updateRegisterEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateRegisterEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.updateRegisterEventList = payload;
    },
    [updateRegisterEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const updateRegisterEventReducer = updateRegisterEventSlice.reducer;
