import React, { useEffect, useState } from "react";
import { Table, Popover, Breadcrumb, Input, Button, Select } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DeleteOutlined,
  DownOutlined
} from "@ant-design/icons";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyList } from "../../redux/slice/surveyManagement";
import "./survey.css";
import moment from "moment";
import { isEmpty } from "lodash";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { Filter } from "../../components/svgJS/Filter";

const SurveyManagement = () => {
  const [searchData, setsearchData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [brandList, setBrandList] = useState();
  const [fields, setFields] = useState({
    customerName: null,
    eventName: null,
    creatorName: null,
    brandName: null,
  })
  const dispatch = useDispatch();
  const surveySelector = useSelector(
    (state) => state.surveyManagement.surveyList
  );
  const { Option } = Select;
  var CryptoJS = require("crypto-js");

  useEffect(() => {

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false) {
      window.location.href = '/pagenotfound'
    }
    window.scrollTo(0, 0);
    dispatch(getSurveyList());

    getEventList()
    getCustomerList()
    getBrandList()
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
      customer_id: fields?.customerName,
      event_id: fields?.eventName,
      brand_id: fields?.brandName,
      createdby: fields?.creatorName
    };
    dispatch(getSurveyList(searchObj));
  }, [searchData, fields?.customerName, fields?.eventName, fields?.brandName]);

  const handleDeleteSurvey = async (surveyData) => {
    console.log("Survey Data", surveyData);

    const body = {
      survey_id: surveyData.survey_id,
      is_delete: true,
    };

    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/survey/delete",
      "",
      ""
    );
    console.log("Delete Survey response ", response);
    if (response.data.code === 200) {
      toast.success(response.data.message);
      dispatch(getSurveyList());
    } else {
      toast.error(response.data.message);
    }
  };
  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const tableData =
    surveySelector &&
    !isEmpty(surveySelector) &&
    surveySelector.rows.map((elem) => {
      return {
        // key: "1",
        survey_id: elem.survey_id,
        created_at: elem.created_at,
        surveytitle: elem.survey_title,
        eventdomainname: elem.survey_type,
        survey_type: elem.survey_type,
        count_question: elem.count_question,
        displaychart: "On",
        question_details: elem.surveys_qa_survey_id,
        customername: elem.created_user_name,
        eventdomain: elem?.sub_domain ?? "NA",
        createdby: elem.created_user_name,
        brand: elem.brand ?? "-",
        zone: elem.zone ?? "-",
        // displaychart: elem.display_chart,
        is_active: elem.is_active
      };
    });

  const customerParams = {
    is_details: true
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then(response => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : null
    })
      .then(response => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : ""
    })
      .then(response => {
        const { rows } = response.data.data;
        setBrandList(rows);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Survey Id",
      dataIndex: "survey_id",
      key: "survey_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
      //   render: text => <a href="#3">{text}</a>,
    },
    {
      title: "Survey Type",
      dataIndex: "survey_type",
      key: "survey_type",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120
    },
    {
      title: "Survey Title",
      dataIndex: "surveytitle",
      key: "surveytitle",
      // width: "25%",
      width: 150
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      // width: "25%",
      width: 160
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
      // width: "25%",
      width: 140
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 100
      // width: "25%",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      width: 100
      // width: "25%",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   // width: "25%",
    // },
    {
      title: "Question",
      dataIndex: "count_question",
      key: "count_question",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      // width: "25%",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150
    },
    // {
    //   title: "Display Chart",
    //   dataIndex: "displaychart",
    //   key: "displaychart",
    //   sorter: true,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    // },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewsurvey/${record.survey_id}`}>
                <p className="options">
                  <EyeOutlined className="mx-1" />{" "}
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editsurvey/${record.survey_id}`}>
                <p className="options">
                  <EditOutlined className="mx-1" />{" "}
                  <span className="">Edit Survey</span>
                </p>
              </Link>

              <p
                className="options cursor-pointer"
                onClick={() => {
                  handleDeleteSurvey(record);
                }}
              >
                <DeleteOutlined className="mx-1" />{" "}
                <span className="">Delete Survey</span>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 60,
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editsurvey/${record.survey_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };

    dispatch(getSurveyList(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  console.log("fields in survey listing", fields)
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Survey Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Survey Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="/addnewsurvey">
            <Button className="rounded px-3 py-1 mt-2">
              <span className="fw-bold"> + Add New Survey </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            <Input
              size="medium"
              placeholder="Search by Cust. Name, Event Name, Brand Name, Creator Name, Survey Title"
              style={{ width: "130%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>
          <div className="d-flex">

            <Button className=" rounded" onClick={() => setShowFilter(!showFilter)}>
              <Filter />
              <span className="ms-2"> FILTER</span> <DownOutlined />
            </Button>

          </div>
        </div>
        <div className="mb-2">
          {
            showFilter ? (
              <div>
                <Select
                  name="customerName"
                  placeholder="Customer Name"
                  style={{
                    width: 280,
                  }}
                  onChange={val =>
                    handleFieldsChange(val, "customerName")
                  }
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {customerList?.map(data => {
                    return (
                      <Option value={data.customer_id}>
                        {data.account_name}
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  name="eventName"
                  placeholder="Event Name"
                  className="ms-2"
                  style={{
                    width: 280,
                  }}
                  onChange={val =>
                    handleFieldsChange(val, "eventName")
                  }
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {eventList &&
                    eventList?.map(data => {
                      return (
                        <Option value={data.event_id}>
                          {data.event_name}
                        </Option>
                      );
                    })}
                </Select>

                <Select
                  name="brandName"
                  placeholder="Brand"
                  className="ms-2"
                  onChange={val =>
                    handleFieldsChange(val, "brandName")
                  }
                  style={{
                    width: 280,
                  }}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                >
                  {brandList &&
                    brandList?.map(data => {
                      return (
                        <Option value={data.brand_id}>
                          {data.brand_title}
                        </Option>
                      );
                    })}
                </Select>

                <Select
                  name="eventName"
                  placeholder="Creator Name"
                  className="ms-2"
                  showSearch
                  // onChange={val =>
                  //   handleFieldsChange(val, "eventName")
                  // }
                  style={{
                    width: 280,
                  }}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                >
                  <option>Data dall</option>
                </Select>



              </div>
            ) : ""
          }

        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            // total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default SurveyManagement;
