import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, TimePicker, Button, Select, Switch } from "antd";
import moment from "moment";
import "../eventCalender.css";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import {
  createSponser,
  updateSponser,
} from "../../../redux/slice/eventCalender";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { ifTimeSlotAvailable } from "../eventCalender.utils";
import { getBrandDropdowns, getZoneDropdowns } from "../../../utils/commonApis";

const Sponser = (props) => {
  const dispatch = useDispatch();
  const { sponserData } = props;
  console.log("sponserData = ", sponserData);
  const { Option } = Select;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [zones, setZones] = useState([]);
  const [zoneCollection, setZoneCollection] = useState([]);
  const [brandsCollection, setBrandsCollection] = useState([]);
  const [sponserForceUpdate, setSponserForceUpdate] = useState(false);
  const [sponserLocalData, setSponserLocalData] = useState({
    ...sponserData,
    brand_configuration_id: sponserData.brand_configuration_id ?? "",
    brand_id: sponserData?.brand_id ?? null,
    zone_id: sponserData.zone_id ?? null,
    start_time: sponserData.start_time
      ? moment(sponserData.start_time, TIME_FORMAT)
      : moment(moment().format("HH:mm"), TIME_FORMAT),
    end_time: sponserData.start_time
      ? moment(sponserData.end_time, TIME_FORMAT)
      : moment(moment().format("HH:mm"), TIME_FORMAT),
    is_chat_enable: sponserData.is_chat_enable ?? false,
    is_demo_room_enable: sponserData.is_demo_room_enable ?? false,
    is_asset_enable: sponserData.is_asset_enable ?? false,
    is_booth_link_enable: sponserData.is_booth_link_enable ?? false,
    is_video_call_enable: sponserData.is_video_call_enable ?? false,
  });

  useEffect(async () => {
    const brandListAPI = await getBrandDropdowns(props.sponserData.event_id);
    setBrandsCollection(brandListAPI);
    const zonesHere = await getZoneDropdowns(props.sponserData.event_id);
    if (sponserData && sponserData.brand_id) {
      const filteredData =
        zonesHere &&
        zonesHere.filter((item) => item.brand_id === sponserData.brand_id);
      setZones(filteredData);
    } else setZones(zonesHere);

    setZoneCollection(zonesHere);
  }, []);

  const createUpdateAPI = async (dataToUpdate) => {
    console.log("dataToUpdate/create = ", dataToUpdate);
    console.log("json clonedData = ", JSON.stringify(dataToUpdate));
    if (dataToUpdate.brand_configuration_id) {
      const updateRes = await dispatch(updateSponser(dataToUpdate));
      console.log("update register response", updateRes);
      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error update sponser");
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      const createRes = await dispatch(createSponser(dataToUpdate));
      console.log("create sponser response", createRes);
      if (createRes?.payload?.data?.code === 200) {
        console.log("in 200 create sponser", createRes);
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error create sponser");
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    console.log("sponserLocalData = ", sponserLocalData);
    if (validator.current.allValid()) {
      const { sponserData } = props;
      console.log(
        "currentDateForCalender = ",
        sponserData.currentDateForCalender
      );
      let clonedData = cloneDeep(sponserLocalData);
      let dataToCheckTime = cloneDeep(sponserLocalData);
      const dateHere = sponserData.currentDateForCalender;
      clonedData.start_time =
        dateHere + " " + moment(clonedData.start_time).format("HH:mm:ss");
      clonedData.end_time =
        dateHere + " " + moment(clonedData.end_time).format("HH:mm:ss");
      console.log("clonedData final = ", clonedData);

      if (sponserData.title === "Expo Hall Sponsorship") {
        clonedData.hall_type = "EXPO";
        clonedData.hall_id = clonedData.expo_hall_configuration_id;
      }

      if (sponserData.title === "Main Auditorium Sponsorship") {
        clonedData.hall_type = "MAIN";
        clonedData.hall_id = clonedData.auditorium_configuration_id;
      }

      if (sponserData.title === "Satellite Auditorium Sponsorship") {
        clonedData.hall_type = "SATELLITE";
        clonedData.hall_id = clonedData.auditorium_configuration_id;
      }

      if (sponserData.title === "Network Lounge Sponsorship") {
        clonedData.hall_type = "NETWORKING";
        clonedData.hall_id = clonedData.networking_configuration_id;
      }

      if (sponserData.title === "Social Wall Sponsorship") {
        clonedData.hall_type = "SOCIAL";
        clonedData.hall_id = null;
      }

      if (sponserData.title === "Concourse Sponsors") {
        clonedData.hall_type = "CONCOURSE";
        clonedData.hall_id = null;
      }

      if (clonedData.start_time === clonedData.end_time)
        toast.error("Please change end time");
      else if (
        !ifTimeSlotAvailable(
          moment(dataToCheckTime.start_time),
          moment(dataToCheckTime.end_time),
          props.sponserData.existingTimings
        )
      ) {
        toast.error("Timeslot is not available");
      } else createUpdateAPI(clonedData);
    } else {
      validator.current.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setSponserForceUpdate(!sponserForceUpdate);
    }
  };

  const onChangeFields = (val, name) => {
    setSponserLocalData({ ...sponserLocalData, [name]: val });
  };

  return (
    <Modal
      title={<b>{sponserData.title}</b>}
      visible={true}
      onOk={() => props.onCloseModal()}
      onCancel={() => props.onCloseModal()}
      footer={[
        <Button
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
      width={750}
    >
      <div style={{ padding: "30px" }}>
        <Row className="mt-2" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span> Start & End Time</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              name="start_time"
              id="start_time"
              format={format}
              value={sponserLocalData ? sponserLocalData.start_time : null}
              style={{ width: "100%" }}
              onSelect={(val) => {
                onChangeFields(
                  moment(moment(val).format("HH:mm"), TIME_FORMAT),
                  "start_time"
                );
              }}
              onBlur={() => validator.current.showMessageFor("start_time")}
            />

            {sponserLocalData &&
              validator.current.message(
                "Start Time",
                sponserLocalData.start_time,
                "required",
                { className: "text-danger py-1" }
              )}
          </Col>
          {/* <Col span={1}></Col> */}
          <Col xs={2} sm={4} md={6} lg={8} xl={8}>
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              name="end_time"
              id="end_time"
              disabledDate={(d) =>
                !d ||
                (sponserLocalData &&
                  d.isSameOrBefore(sponserLocalData.start_time))
              }
              value={sponserLocalData ? sponserLocalData.end_time : null}
              format={format}
              style={{ width: "100%" }}
              onSelect={(val) =>
                onChangeFields(
                  moment(moment(val).format("HH:mm"), TIME_FORMAT),
                  "end_time"
                )
              }
              onBlur={() => validator.current.showMessageFor("end_time")}
            />
            {sponserLocalData &&
              validator.current.message(
                "End Time",
                sponserLocalData.end_time,
                "required",
                { className: "text-danger py-1" }
              )}
          </Col>
        </Row>

        <Row className="mt-3" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Brand Name</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={7} xl={16}>
            <Select
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              showSearch
              optionFilterProp="children"
              placeholder="Select Brand"
              className="w-100 fs-bold-14"
              value={sponserLocalData ? sponserLocalData.brand_id : null}
              onChange={(e) => {
                if (zoneCollection) {
                  const filteredData =
                    zoneCollection &&
                    zoneCollection.filter((item) => item.brand_id === e);
                  console.log("filteredData - ", filteredData);
                  setSponserLocalData({
                    ...sponserLocalData,
                    brand_id: e,
                    zone_id: null,
                  });
                  setZones(filteredData);
                } else
                  setSponserLocalData({ ...sponserLocalData, brand_id: e });
              }}
              listHeight={120}
              listItemHeight={4}
            >
              {brandsCollection &&
                brandsCollection.map((item, i) => {
                  return (
                    <Option
                      key={item.brand_id + "_brand"}
                      value={item.brand_id}
                    >
                      {item.brand_title}
                    </Option>
                  );
                })}
            </Select>
            {sponserLocalData &&
              validator.current.message(
                "Brand Name",
                sponserLocalData.brand_id,
                "required",
                { className: "text-danger py-1" }
              )}
          </Col>
        </Row>
        <Row className="mt-3" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Zone</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Select
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              showSearch
              optionFilterProp="children"
              placeholder="Select Zone"
              className="w-100 fs-bold-14"
              value={
                sponserLocalData?.zone_id ? sponserLocalData.zone_id : null
              }
              onChange={(e) => {
                onChangeFields(e, "zone_id");
                console.log("zone id = ", e);
                console.log("zones = ", zones);
              }}
            >
              {zones.map((item, i) => {
                return (
                  <Option key={item.zone_id + "_zone"} value={item.zone_id}>
                    {item.zone_title}
                  </Option>
                );
              })}
            </Select>
            {validator.current.message(
              "Zone",
              sponserLocalData.zone_id,
              "required",
              { className: "text-danger py-1" }
            )}
          </Col>
        </Row>
        <Row className="mt-4" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Chat</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Switch
              checked={sponserLocalData.is_chat_enable}
              onChange={() =>
                onChangeFields(
                  !sponserLocalData.is_chat_enable,
                  "is_chat_enable"
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-4" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Demo Room</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Switch
              checked={sponserLocalData.is_demo_room_enable}
              onChange={() =>
                onChangeFields(
                  !sponserLocalData.is_demo_room_enable,
                  "is_demo_room_enable"
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-4" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Asset/s</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Switch
              checked={sponserLocalData.is_asset_enable}
              onChange={() =>
                onChangeFields(
                  !sponserLocalData.is_asset_enable,
                  "is_asset_enable"
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-4" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Booth Link</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Switch
              checked={sponserLocalData.is_booth_link_enable}
              onChange={() =>
                onChangeFields(
                  !sponserLocalData.is_booth_link_enable,
                  "is_booth_link_enable"
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-4" gutter={[16, 16]}>
          <Col xs={24} sm={4} md={6} lg={18} xl={8} className="text-end">
            <span>Video Call</span>
          </Col>
          <Col xs={24} sm={16} md={12} lg={8} xl={16}>
            <Switch
              checked={sponserLocalData.is_video_call_enable}
              onChange={() =>
                onChangeFields(
                  !sponserLocalData.is_video_call_enable,
                  "is_video_call_enable"
                )
              }
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default Sponser;
