import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Select,
  Switch,
  Button,
  Breadcrumb,
  Input,
  DatePicker,
  Modal,
} from "antd";
import { Spin } from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";
import { AddGroup } from "../../components/svgJS/AddGroup";
import {
  getBrandDetails,
  editBrand,
} from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { EyeView } from "../../components/svgJS/EyeView";
import FileUpload from "../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import ExpoHalls from "../ExpoMap/ExpoHalls";
import CreateNewZone from "../ZoneManagement/CreateNewZone";
import { Download } from "../../components/svgJS/Download";
import { chunkFileUpload } from "./../../utils/commonFunctions";
import InviteAttend from "../UserManagement/InviteAttend";
import CommonLoader from "../../components/Widgets/CommonLoader";
import AddUser from "../UserManagement/AddUser";

const EditBrandDetails = (props) => {
  var CryptoJS = require("crypto-js");

  const validator = useRef(new SimpleReactValidator());
  const { match } = props;

  // validation states
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");
  const [loadingBrand, setLoadingBrand] = useState(false);

  // ExpoHall State
  const [expoHallModalVisible, setExpoHallModalVisible] = useState(false);

  // Add User States
  const { Option } = Select;
  const [customerList, setCustomerList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [allAgentsList, setAllAgentsList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [brandLogoFileState, setBrandLogoFileState] = useState();
  const [wallGraphicsFileState, setWallGraphicsFileState] = useState(null);
  const [wallGraphicsFileUrl, setWallGraphicsFileUrl] = useState(null);
  const [wallGraphicsFileLoader, setWallGraphicsFileLoader] = useState(null);
  const [finalBoothFileState, setFinalBoothFileState] = useState(null);
  const [finalBoothFileUrl, setFinalBoothFileUrl] = useState(null);
  const [finalBoothFileLoader, setFinalBoothFileLoader] = useState(null);
  const [isModalWallGraphicVisible, setIsModalWallGraphicVisible] =
    useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isZoneModalVisible, setIsZoneModalVisible] = useState(false);
  const [fields, setFields] = useState({
    brand_id: match.params.brand_id,
    is_active: null,
    logo_url: "brand_logo.com",
    customer_account: null,
    event_id: null,
    brand_name: "",
    brand_description: "",
    brand_tier: null,
    brand_color: "",
    //New Added Schema
    default_button_background_color: "#fa0505",
    is_default_button_gradient_active: true,
    default_button_gradient_angle: 45,
    default_button_background_second_color: null,
    default_button_text_color: "#e61e1e",
    highlight_button_background_color: "#d71919",
    is_highlight_button_gradient_active: false,
    highlight_button_gradient_angle: 60,
    highlight_button_background_second_color: "#213cc4",
    highlight_button_text_color: "#e16666",

    //old schema
    brand_gradient: "",
    button_text_color_1: "",
    button_text_color_2: "",
    //till here
    expo_hall_mapping_id: "",
    wall_graphics_url: "",
    final_booth_url: "",
    default_zone_id: null,
    brand_agent_details: [],
    brand_website: "",
    brand_social_media_details: [],
    image_url: "",
    expo_hall_configuration_id: "",
    sequence: "",
  });

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.brandManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { TextArea } = Input;

  useEffect(async () => {
    setLoadingBrand(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach((element) => {
      if (element.route == window.location.pathname) {
        is_route_present = true;
      }
    });

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    }

    getCustomerList();
    getTierList();
    getSocialMediaList();
    await getSelectedBrandDetails();
    setTimeout(() => {
      setLoadingBrand(false);
    }, 1000);
  }, []);

  useEffect(() => {
    getEventList();
  }, [fields?.customer_account]);

  useEffect(() => {
    getZoneList();
    getAgentsList();
  }, [fields?.event_id, match.params.brand]);

  const showModal = () => {
    setIsModalWallGraphicVisible(true);
  };

  const handleOk = () => {
    setIsModalWallGraphicVisible(false);
  };

  const handleCancel = () => {
    setIsModalWallGraphicVisible(false);
  };

  const showZoneModal = () => {
    setIsZoneModalVisible(true);
  };

  const showAddUserModal = () => {
    setIsAddUserModalVisible(true);
  };
  const handleOKAddUser = () => {
    setIsAddUserModalVisible(false);
  };

  const handleCancelAddUser = () => {
    setIsAddUserModalVisible(false);
  };

  // Api Call

  function handleAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = agents;
    let selectedAgents_list = selectedAgents;
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
  }

  const handleFieldsChange = (val, name) => {
    console.log("Field Name", name);

    if (name == "customer_account") {
      setFields({
        ...fields,
        [name]: val,
        event_id: null,
        default_zone_id: null,
      });
    } else if (name == "event_id") {
      setFields({ ...fields, [name]: val, default_zone_id: null });
    } else {
      setFields({ ...fields, [name]: val });
    }
  };
  console.log("fields", fields);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    const agentsIdArr = selectedAgents.map((elem, index) => elem.user_id);

    let formData = new FormData();
    fields.brand_agent_details = agentsIdArr;
    if (wallGraphicsFileUrl) fields["wall_graphics_url"] = wallGraphicsFileUrl;
    else delete fields.wall_graphics_url;

    if (finalBoothFileUrl) fields["final_booth_url"] = finalBoothFileUrl;
    else delete fields.final_booth_url;

    formData.append("data", JSON.stringify(fields));
    formData.append("brand_logo_file", fields?.logo_url);

    console.log("validator check", validator.current);
    if (validator.current.allValid()) {
      // post api call
      console.log("Validator", validator);
      const updateBrand = await dispatch(editBrand(formData));
      if (updateBrand?.payload?.code === 200) {
        toast.success(updateBrand?.payload?.message);
        props?.history?.push("/brandManagement");
      }
      if (updateBrand.payload.code != 200) {
        toast.error(updateBrand.payload.message);
        console.log("200 success");
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }
  };

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = async () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : "",
    })
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        setTierList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zone/dropdown/get", {
      event_id: fields?.event_id,
      brand_id: match?.params?.brand_id,
    })
      .then((response) => {
        setZoneList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/usertypeagents/get", {
      event_id: fields?.event_id,
      brand_id: match?.params?.brand_id,
    })
      .then((response) => {
        setAllAgentsList(response?.data?.data?.rows);
        setAgents(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImgChange1 = (event) => {
    console.log();
    setBrandLogoFileState(URL.createObjectURL(event.target.files[0]));
    setFields({ ...fields, logo_url: event.target.files[0] });
  };
  const handleBrandLogoFileDelete = () => {
    setBrandLogoFileState(null);
  };

  const handleImgChange2 = (event) => {
    setWallGraphicsFileState(event.target.files[0]);
    setWallGraphicsFileLoader(true);
    chunkFileUpload(event.target.files[0], function cb(fileUrl) {
      setWallGraphicsFileLoader(false);
      setWallGraphicsFileUrl(fileUrl);
    });
  };
  const handleAllGraphicsFileStateDelete = () => {
    setWallGraphicsFileState(null);
  };

  const handleImgChange3 = (event) => {
    setFinalBoothFileState(event.target.files[0]);
    setFinalBoothFileLoader(true);
    chunkFileUpload(event.target.files[0], function cb(fileUrl) {
      setFinalBoothFileLoader(false);
      setFinalBoothFileUrl(fileUrl);
    });
  };
  const handleFinalBoothDelete = () => {
    setFinalBoothFileState(null);
  };

  let [bool, setBool] = useState(false);
  useEffect(() => {
    //To call it only once
    if (agents && !bool) {
      fields.brand_agent_details.map((val, idx) => {
        if (agents?.some((agent) => agent.user_id === val)) {
          handleAgentsChange(val);
          setBool(true);
        }
      });
    }
  }, [agents]);

  const getSelectedBrandDetails = async () => {
    const response = await dispatch(
      getBrandDetails({ brand_id: match.params.brand_id })
    );

    const { details } = response?.payload?.data?.data;
    let selected_agents = [];
    details?.brands_agents?.map((val, idx) => {
      selected_agents.push(val.user_id);
    });

    let brand_social_media_details = [];

    details.brands_social_media_handles_id.map((val, idx) => {
      brand_social_media_details.push({
        brand_social_id: val.brand_social_id,
        social_media_id: val.social_media_id,
        social_media_url: val.social_media_url,
      });
    });

    setFields({
      ...fields,
      brand_id: parseInt(match?.params?.brand_id),
      is_active: details.is_active,
      logo_url: "brand_logo.com",
      customer_account: details?.customer?.customer_id,
      event_id: details?.event_id,
      brand_name: details.brand_title,
      brand_description: details.brand_description,
      brand_tier: details.tier?.tier_id,
      brand_color: details.brand_color,
      //new schema
      default_button_background_color: details?.default_button_background_color,
      is_default_button_gradient_active:
        details?.is_default_button_gradient_active,
      default_button_gradient_angle: details?.default_button_gradient_angle,
      default_button_background_second_color:
        details?.default_button_background_second_color,
      default_button_text_color: details?.default_button_text_color,
      highlight_button_background_color:
        details?.highlight_button_background_color,
      is_highlight_button_gradient_active:
        details?.is_highlight_button_gradient_active,
      highlight_button_gradient_angle: details?.highlight_button_gradient_angle,
      highlight_button_background_second_color:
        details?.highlight_button_background_second_color,
      highlight_button_text_color: details?.highlight_button_text_color,
      //end
      expo_hall_mapping_id: details.location_id,
      wall_graphics_url: details.wall_graphics_url,
      final_booth_url: details.final_booth_url,
      default_zone_id: details?.brands_default_zone_id?.zone_id
        ? details?.brands_default_zone_id?.zone_id
        : null,
      brand_agent_details: selected_agents,
      brand_website: "brand.com",
      brand_social_media_details: brand_social_media_details,
      logo_url: details.logo_url,
    });
    setWallGraphicsFileState({ name: details.wall_graphics_url });
    setFinalBoothFileState({ name: details.final_booth_url });
  };

  const socialMediaHandleChange = (event, idx) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details?.some(
      (social_media) =>
        social_media?.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (isAvailable) {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details[index]["social_media_url"] =
        event.target.value;
    } else {
      brand_social_media_details.push({
        brand_social_id: social_media_list[idx].brand_social_id,
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: social_media_list[idx].social_media_url,
      });
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      if (isAvailable) {
        let index = brand_social_media_details.findIndex(
          (social_media) =>
            social_media.social_media_id ===
            social_media_list[idx]["social_media_id"]
        );
        brand_social_media_details[index]["social_media_url"] = "";
      } else {
        brand_social_media_details.push({
          social_media_id: social_media_list[idx].social_media_id,
          social_media_url: "",
        });
      }
    } else {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details[index]["social_media_url"] = null;
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const handleOkExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const handleCancelExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const getDataFromExpoHall = (expo_hall_configuration_id, elemIndex) => {
    console.log(
      "expo_hall_configuration_id, elemIndex = ",
      expo_hall_configuration_id,
      elemIndex
    );
    setFields({
      ...fields,
      expo_hall_configuration_id: expo_hall_configuration_id,
      sequence: elemIndex,
    });
  };

  console.log("fields brand's edit", fields);
  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <form onSubmit={handleFormSubmit}>
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="fs-24">Edit Brand</h4>
              <Breadcrumb className="my-2" separator=">">
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Admin Console</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Brand Management</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Brand Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          {/* <Main Div */}
          <div className="bg-white p-3">
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Brand Editor
                  </h6>
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Active
                  </label>
                </div>
                <div className="col-3 text-start">
                  <Switch
                    name="is_active"
                    checked={fields.is_active}
                    onChange={(checked) =>
                      handleFieldsChange(checked, "is_active")
                    }
                    className="mx-3"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Logo
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className="col-5 image-upload d-flex">
                  <FileUpload
                    labelClassName="rounded border-pink btn-svg px-3 py-1"
                    labelTitle="Image Upload"
                    inputType="file"
                    inputOnChange={handleImgChange1}
                    inputId="edit_brand_logo"
                    inputName="edit_brand_logo"
                    name="logo"
                    onBlur={() => validator.current.showMessageFor("logo")}
                  />
                  {brandLogoFileState ? (
                    <>
                      <span className="mx-3"> {fields?.logo_url?.name} </span>
                      <span onClick={handleBrandLogoFileDelete}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-2"></div>
                <div className="col-5">
                  {validator.current.message(
                    "logo",
                    fields.logo_url,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              {brandLogoFileState ? (
                <div className="row mt-2">
                  <div className="col-2"></div>
                  <div className="col-9">
                    <img
                      src={brandLogoFileState}
                      alt={
                        brandLogoFileState === null
                          ? "Uploaded Logo"
                          : "Uploaded Logo"
                      }
                      className="img-fluid user-img-preview"
                    />
                  </div>
                </div>
              ) : (
                <div className="row mt-2">
                  <div className="col-2"></div>
                  <div className="col-9">
                    <img
                      src={fields?.logo_url}
                      alt={fields.logo_url === null ? "Uploaded Logo" : ""}
                      className="img-fluid user-img-preview"
                    />
                  </div>
                </div>
              )}

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Customer Account
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Customer Account"
                    className="w-100 fs-bold-14"
                    name="customer_account"
                    allowClear
                    value={fields.customer_account}
                    onChange={(val) =>
                      handleFieldsChange(val, "customer_account")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("customer_account")
                    }
                    listHeight={120}
                    listItemHeight={4}
                    disabled={
                      (userType && userType === "BRAND ADMIN") ||
                      userType === "CUSTOMER"
                        ? true
                        : false
                    }
                  >
                    {customerList &&
                      customerList?.map((data) => {
                        return (
                          <Option value={data.customer_id}>
                            {data.account_name}
                          </Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "customer_account",
                    fields.customer_account,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
                {/* )} */}
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Select Event
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Event"
                    className="w-100 fs-bold-14"
                    allowClear
                    name="event_id"
                    value={fields?.event_id}
                    onChange={(val) => handleFieldsChange(val, "event_id")}
                    onBlur={() => validator.current.showMessageFor("event_id")}
                    listHeight={120}
                    listItemHeight={4}
                    disabled={
                      userType && userType === "BRAND ADMIN" ? true : false
                    }
                  >
                    {eventList &&
                      eventList?.map((data) => {
                        return (
                          <Option value={data.event_id}>
                            {data?.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "event_id",
                    fields.event_id,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Name
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Input
                    name="brand_name"
                    id=""
                    className="w-100 border-gray fs-bold-14 rounded"
                    value={fields.brand_name}
                    placeholder="Enter A Title Here"
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "brand_name")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("brand_name")
                    }
                  />
                  {validator.current.message(
                    "brand_name",
                    fields.brand_name,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Brand Description
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className="col-5">
                  <TextArea
                    name="brand_description"
                    rows={4}
                    className="fs-bold-14"
                    value={fields.brand_description}
                    onBlur={() =>
                      validator.current.showMessageFor("brand_description")
                    }
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "brand_description")
                    }
                    placeholder="Enter A Description Here"
                  />
                  {validator.current.message(
                    "brand_description",
                    fields.brand_description,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Tier
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Tier"
                    className="w-100 fs-bold-14"
                    allowClear
                    name="brand_tier"
                    value={fields.brand_tier}
                    onChange={(val) => handleFieldsChange(val, "brand_tier")}
                    onBlur={() =>
                      validator.current.showMessageFor("brand_tier")
                    }
                    listHeight={120}
                    listItemHeight={4}
                    autoComplete="none"
                  >
                    {tierList &&
                      tierList?.map((data) => {
                        return (
                          <Option value={data.tier_id}>{data.tier}</Option>
                        );
                      })}
                  </Select>
                  {validator.current.message(
                    "brand_tier",
                    fields.brand_tier,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Color
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-2"}>
                  <Input
                    name="brand_color"
                    type="color"
                    id=""
                    className="border-gray fs-bold-14 rounded eventtheme_input_style"
                    value={fields.brand_color}
                    addonBefore={fields.brand_color}
                    onBlur={() =>
                      validator.current.showMessageFor("brand_color")
                    }
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "brand_color")
                    }
                  />
                  {validator.current.message(
                    "color",
                    fields.brand_color,
                    "required|text",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>

              {/* ------------------------New ADDed Code ------------------ */}

              <div className="row mt-4">
                <div className={Tablet ? "col-2 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Background
                  </label>
                </div>
                <div className={Tablet ? "col-2" : "col-2"}>
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.default_button_background_color}
                    value={fields?.default_button_background_color}
                    addonBefore={fields?.default_button_background_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "default_button_background_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>

                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch
                    className="mx-2"
                    checked={fields?.is_default_button_gradient_active}
                    onChange={(val) => {
                      handleFieldsChange(
                        val,
                        "is_default_button_gradient_active"
                      );
                    }}
                  />
                </div>
                {fields.is_default_button_gradient_active ? (
                  <div className={Tablet ? "col-6" : "col-6 text-start"}>
                    <div className="d-flex">
                      <label htmlFor="" className="text-secondary mt-1">
                        Angle
                      </label>
                      <Input
                        className="ms-2 mx-2"
                        style={{ width: "50px" }}
                        value={fields?.default_button_gradient_angle}
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "default_button_gradient_angle"
                          );
                        }}
                      />
                      <label htmlFor="" className="text-secondary mx-4 mt-1">
                        Event 2nd Color
                      </label>
                      <Input
                        type="color"
                        id=""
                        defaultValue={
                          fields?.default_button_background_second_color
                        }
                        value={fields?.default_button_background_second_color}
                        addonBefore={
                          fields?.default_button_background_second_color
                        }
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "default_button_background_second_color"
                          );
                        }}
                        className="eventtheme_input_style border-gray rounded fs-bold-14"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.default_button_text_color}
                    addonBefore={fields?.default_button_text_color}
                    value={fields?.default_button_text_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "default_button_text_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button
                    style={{
                      color: fields.default_button_text_color,
                      background: fields.is_default_button_gradient_active
                        ? `linear-gradient(${fields.default_button_gradient_angle}deg, ${fields.default_button_background_color} 30%,${fields.default_button_background_second_color} 100%)`
                        : `${fields.default_button_background_color}`,
                      border: `1px solid ${fields.default_button_text_color}`,
                    }}
                    className="rounded"
                  >
                    Example Test
                  </Button>
                </div>
              </div>

              <div className="row w-100 mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Background
                  </label>
                </div>
                <div className="col-2">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.highlight_button_background_color}
                    value={fields?.highlight_button_background_color}
                    addonBefore={fields?.highlight_button_background_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "highlight_button_background_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>

                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch
                    checked={fields?.is_highlight_button_gradient_active}
                    onChange={(val) => {
                      handleFieldsChange(
                        val,
                        "is_highlight_button_gradient_active"
                      );
                    }}
                    className="mx-2"
                  />
                </div>

                {fields?.is_highlight_button_gradient_active ? (
                  <div className={Tablet ? "col-6" : "col-6 text-start"}>
                    <div className="d-flex">
                      <label htmlFor="" className="text-secondary mt-1">
                        Angle
                      </label>
                      <Input
                        className="ms-2 mx-2"
                        style={{ width: "50px" }}
                        value={fields?.highlight_button_gradient_angle}
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "highlight_button_gradient_angle"
                          );
                        }}
                      />

                      <label
                        htmlFor=""
                        className="text-secondary mx-4 text-nowrap mt-1"
                      >
                        Event 2nd Color
                      </label>
                      <Input
                        type="color"
                        id=""
                        defaultValue={
                          fields?.highlight_button_background_second_color
                        }
                        value={fields?.highlight_button_background_second_color}
                        addonBefore={
                          fields?.highlight_button_background_second_color
                        }
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "highlight_button_background_second_color"
                          );
                        }}
                        className="eventtheme_input_style ms-2 border-gray rounded fs-bold-14"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    type="color"
                    id=""
                    defaultValue={fields?.highlight_button_text_color}
                    value={fields?.highlight_button_text_color}
                    addonBefore={fields?.highlight_button_text_color}
                    onChange={(e) => {
                      handleFieldsChange(
                        e.target.value,
                        "highlight_button_text_color"
                      );
                    }}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button
                    style={{
                      color: fields.highlight_button_text_color,
                      background: fields.is_highlight_button_gradient_active
                        ? `linear-gradient(${fields.highlight_button_gradient_angle}deg, ${fields.highlight_button_background_color} 30%,${fields.highlight_button_background_second_color} 100%)`
                        : `${fields.highlight_button_background_color}`,
                      border: `1px solid ${fields.highlight_button_text_color}`,
                    }}
                    className="rounded"
                  >
                    Example Test
                  </Button>
                </div>
              </div>

              {/* New Added Code Ends here */}

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Booth Location
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Button
                    className="rounded  btn-svg px-3"
                    onClick={() => setExpoHallModalVisible(true)}
                  >
                    Select Expo Hall
                  </Button>

                  <Modal
                    visible={expoHallModalVisible}
                    onOk={handleOkExpoHall}
                    onCancel={handleCancelExpoHall}
                    width={1000}
                  >
                    <ExpoHalls
                      event_id={fields.event_id ?? null}
                      getDataFromExpoHall={getDataFromExpoHall}
                    />
                  </Modal>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Download Graphics Template
                  </label>
                </div>
                <div className={Tablet ? "col-2" : "col-1"}>
                  <Button
                    className="rounded btn-svg px-3"
                    onClick={showModal}
                    icon={<EyeView />}
                  >
                    View
                  </Button>
                  <Modal
                    title="Wall Graphics File "
                    visible={isModalWallGraphicVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    width={500}
                  >
                    <img
                      src={fields && fields.wall_graphics_url}
                      alt="wall graphics upload file"
                      className="img-fluid uploaded-image-modal-preview"
                    />
                  </Modal>
                </div>
                <div className="col-2 text-start">
                  <Button type="primary" className="px-2">
                    <Download />
                    <span className="ms-2">Download</span>
                  </Button>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Upload Graphics
                  </label>
                </div>

                <div className="col-2 image-upload d-flex">
                  <div className="h-25">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="File Upload"
                      inputType="file"
                      inputOnChange={handleImgChange2}
                      inputId="wallgraphicsupload"
                      inputName="wallgraphicsupload"
                      name="wallgraphicsupload"
                    />
                  </div>
                </div>
                <div className="col-6">
                  {wallGraphicsFileState && !wallGraphicsFileLoader ? (
                    <>
                      <span className="mx-3">
                        {" "}
                        {wallGraphicsFileState.name}{" "}
                      </span>
                      <span onClick={handleAllGraphicsFileStateDelete}>
                        {wallGraphicsFileState.name ? <DeleteOutlined /> : ""}
                      </span>
                    </>
                  ) : wallGraphicsFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-2"></div>
                <div className="col-9"></div>
              </div>

              <div className="row mt-4 ">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    360 Booth Upload
                  </label>
                </div>
                <div className="col-2 image-upload d-flex">
                  <div className="h-25">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="File Upload"
                      inputType="file"
                      inputOnChange={handleImgChange3}
                      inputId="finalboothupload"
                      inputName="finalboothupload"
                      name="finalboothupload"
                    />
                  </div>
                </div>
                <div className="col-6">
                  {finalBoothFileState && !finalBoothFileLoader ? (
                    <>
                      <span className="mx-3"> {finalBoothFileState.name} </span>
                      <span onClick={handleFinalBoothDelete}>
                        {finalBoothFileState.name ? <DeleteOutlined /> : ""}
                      </span>
                    </>
                  ) : finalBoothFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row my-0 py-0 border-bottom-gray pb-4">
                <div className="col-2"></div>
                <div className="col-9"></div>
              </div>
            </section>

            {/* section External & Social Links */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    External & Social Links
                  </h6>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-12 mt-1">
                  <span className="fs-16">
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </span>
                </div>
              </div>

              <div className="social-media-div border-bottom-gray pb-4 mb-4">
                {socialMediaList &&
                  socialMediaList.map((data, idx) => (
                    <div className="row mt-4 ">
                      <div className="col-2 text-end ">
                        {data?.social_media}
                      </div>
                      <div className="col-1 text-center ">
                        <Switch
                          checked={
                            fields?.brand_social_media_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            ) &&
                            fields?.brand_social_media_details[
                              fields.brand_social_media_details.findIndex(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                            ]["social_media_url"] !== null
                              ? true
                              : false
                          }
                          onChange={(checked) =>
                            onHandleSocialSwitchChange(checked, idx)
                          }
                        />
                      </div>
                      <div className="col-4 text-start ">
                        <Input
                          placeholder="https://webaddress.com"
                          className="w-100 fs-bold-14"
                          disabled={
                            fields?.brand_social_media_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            ) &&
                            fields?.brand_social_media_details[
                              fields.brand_social_media_details.findIndex(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                            ]["social_media_url"] !== null
                              ? false
                              : true
                          }
                          value={
                            fields?.brand_social_media_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? fields?.brand_social_media_details[
                                  fields.brand_social_media_details.findIndex(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                ]["social_media_url"]
                              : ""
                          }
                          onChange={(event) =>
                            socialMediaHandleChange(event, idx)
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("social_media")
                          }
                        />
                        {fields?.brand_social_media_details?.some(
                          (social_media) =>
                            social_media.social_media_id ===
                            data.social_media_id
                        ) &&
                          fields?.brand_social_media_details[
                            fields.brand_social_media_details.findIndex(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                          ]["social_media_url"] !== null &&
                          validator.current.message(
                            "social_media",
                            fields?.brand_social_media_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? fields?.brand_social_media_details[
                                  fields.brand_social_media_details.findIndex(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                ]["social_media_url"]
                              : "",
                            "required|url",
                            { className: "text-danger py-1" }
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>

            {/* section default zone */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Default Zone
                  </h6>
                </div>
              </div>

              <div className="row ">
                <div className="col-12 px-3">
                  <span className="fs-16">
                    NB: This zones content (including
                    assets/descriptions/interactivity etc.) will be used when
                    sponsoring areas/events and the brand profile page.
                    <br /> (Unless a different zone is selected when setting up
                    an event/sponsorship etc.)
                  </span>
                </div>
              </div>

              <div className="row mt-4 border-bottom-gray pb-4">
                <div className="col-2 text-end mt-1">Default Zone</div>
                <div className="col-3 text-start">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={
                      zoneList && zoneList.length < 1
                        ? "No zones created for this brand"
                        : "Select Default Zone"
                    }
                    className="w-100 fs-bold-14"
                    name="default_zone_id"
                    value={
                      fields?.default_zone_id ? fields?.default_zone_id : null
                    }
                    allowClear
                    onChange={(val) =>
                      handleFieldsChange(val, "default_zone_id")
                    }
                    onBlur={() =>
                      validator.current.showMessageFor("default_zone_id")
                    }
                  >
                    {zoneList &&
                      zoneList.map((data) => (
                        <Option value={data.zone_id}>{data.zone_title}</Option>
                      ))}
                  </Select>
                </div>
                <div className="col-3">
                  <Button
                    onClick={showZoneModal}
                    className="px-2 button-pink rounded"
                  >
                    + Create New Zone
                  </Button>
                </div>
              </div>
            </section>

            <Modal
              visible={isZoneModalVisible}
              onCancel={() => setIsZoneModalVisible(false)}
              footer={null}
              width={1200}
            >
              <div className="p-2">
                <CreateNewZone
                  event_id={fields?.event_id}
                  isFromEvent={true}
                  onCancelFromModal={() => setIsZoneModalVisible(false)}
                  onSuccessFromModal={async () => {
                    setIsZoneModalVisible(false);
                  }}
                />
              </div>
            </Modal>

            {/* section Assign Agents to this Brand */}

            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Assign Agents to this Brand
                  </h6>
                </div>
              </div>
              <div className="row mt-4 event-editor-dropdown-div">
                <div className="col-2 text-end mt-4">
                  <span className="text-secondary mt-4">Agents</span>
                </div>

                <div
                  className={Tablet ? "col-5" : "col-4"}
                  id="area"
                  style={{ position: "relative" }}
                >
                  <label className="py-2 mx-2">
                    <b>All Agents</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search to Select"
                    value="All Agents"
                    onChange={handleAgentsChange}
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() => document.getElementById("area")}
                    listHeight={100}
                    listItemHeight={4}
                  >
                    {agents ? (
                      agents?.map((data) => (
                        <Option
                          className="select-text-dark"
                          value={data.user_id}
                        >
                          {data.first_name} {data.last_name}
                        </Option>
                      ))
                    ) : (
                      <span disabled>
                        Agents are not created for this brand
                      </span>
                    )}
                  </Select>
                </div>

                <div
                  className={Tablet ? "col-5" : "col-4"}
                  id="selectedAgents"
                  style={{ position: "relative" }}
                >
                  <label className="py-2 mx-2">
                    <b>Tagged Agents</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    name="selectedAgents"
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search Tagged Agents"
                    value="Tagged Agents"
                    open={true}
                    className="border border-gray fs-bold-14"
                    getPopupContainer={() =>
                      document.getElementById("selectedAgents")
                    }
                    listHeight={100}
                    listItemHeight={4}
                  >
                    {selectedAgents &&
                      selectedAgents?.map((data) => {
                        return (
                          <Option value={data?.user_id}>
                            {data?.first_name} {data?.last_name}
                            <span className="float-end">
                              <CloseOutlined
                                className="float-right text-danger mr-0"
                                onClick={() => {
                                  handleSelectedAgentsChange(data.user_id);
                                }}
                              />
                            </span>
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>

              <div className="row border-bottom-gray pb-4">
                <div className="col-2"></div>
                <div className="col-4">
                  <Button
                    className="px-3  rounded btn-svg"
                    onClick={showAddUserModal}
                  >
                    <AddGroup fill="#ef0855" />
                    <span className="mx-2">Invite Agents</span>
                  </Button>
                  <Modal
                    title="Add User"
                    visible={isAddUserModalVisible}
                    onOk={handleOKAddUser}
                    onCancel={handleCancelAddUser}
                    footer={null}
                    width={1000}
                  >
                    {/* <InviteAttend
                      event_id={fields?.event_id}
                      onCancelFromModal={() =>
                        setIsAddUserModalVisible(false)
                      }
                      onSuccessFromModal={async () => {
                        setIsAddUserModalVisible(false);
                      }}
                    /> */}
                    <AddUser
                      event_id={fields?.event_id}
                      brand_id={parseInt(match.params.brand_id)}
                      onCancelFromModal={() => setIsAddUserModalVisible(false)}
                      onSuccessFromModal={async () => {
                        setIsAddUserModalVisible(false);
                      }}
                    />
                  </Modal>
                  <Button
                    className={
                      "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                    }
                    onClick={() =>
                      window.open(
                        `/brandCalendar/${fields?.event_id}/${match.params.brand_id}`,
                        "_blank"
                      )
                    }
                  >
                    <CalenderGrey />
                    <span className="mx-2">Staffing Calendar</span>
                  </Button>
                </div>
              </div>
            </section>
            {/* section Cancel */}
            <section>
              <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                <Link to="/brandmanagement">
                  <Button className="rounded  px-3">Cancel</Button>
                </Link>
                <div>
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleFormSubmit}
                  >
                    {!loading ? (
                      "Save Changes"
                    ) : (
                      <div style={{ width: "70px" }}>
                        <Spin
                          style={{ color: "white" }}
                          indicator={loadingIcon}
                        />
                      </div>
                    )}
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </form>
      </Fragment>
    );
};

export default EditBrandDetails;
