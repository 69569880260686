import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_EDIT_DATA = {
  editor_details: {},
  navigation_plus_reception_details: {},
  registration_and_communication_details: {},
  social_wall_details: {},
  tier_details: {},
  event_id: "",
};

const initialState = {
  editEventData: INITIAL_EDIT_DATA,
};

export const editEventSlice = createSlice({
  name: "editEventData",
  initialState,
  reducers: {
    storeEventData(state, { payload }) {
      // console.log("565656 state = ", state);
      // console.log("565656 action = ", payload);
      if (payload.keyToUpdate === "main")
        state.editEventData = { ...state.editEventData, ...payload.data };
      else
        state.editEventData[payload.keyToUpdate] = {
          ...state.editEventData[payload.keyToUpdate],
          ...payload.data,
        };
    },
    clearEventData(state, { payload }) {
      state.editEventData = INITIAL_EDIT_DATA;
    },
    getCurrentEventData(state, { payload }) {
      return state.editEventData;
    },
  },
});

export const { getCurrentEventData, storeEventData, clearEventData } =
  editEventSlice.actions;
export default editEventSlice.reducer;
