import React from "react";

export const EyeView = () => {
  return (
    <>
      <svg
        id="eye-line"
        xmlns="http://www.w3.org/2000/svg"
        width="19.93"
        height="19.93"
        viewBox="0 0 19.93 19.93"
        fill="white"
        className="mx-1"
      >
        <g>
          {/* <path id="Path_49234" data-name="Path 49234" d="M0,0H19.93V19.93H0Z"  /> */}
          <path
            id="Path_49235"
            data-name="Path 49235"
            d="M11.146,3a10.136,10.136,0,0,1,9.965,8.289,10.134,10.134,0,0,1-19.93,0A10.135,10.135,0,0,1,11.146,3Zm0,14.737a8.294,8.294,0,0,0,8.084-6.446,8.294,8.294,0,0,0-16.168,0,8.294,8.294,0,0,0,8.084,6.446Zm0-2.3a4.145,4.145,0,1,1,4.145-4.145A4.145,4.145,0,0,1,11.146,15.434Zm0-1.842a2.3,2.3,0,1,0-2.3-2.3A2.3,2.3,0,0,0,11.146,13.592Z"
            transform="translate(-1.181 -1.325)"
          />
        </g>
      </svg>
    </>
  );
};
