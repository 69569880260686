import React, { Fragment, useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  DayView,
  AppointmentForm,
  AppointmentTooltip,
  ConfirmationDialog,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { appointments } from "./demo-data/month-appointment";
import { Input, Button, Tooltip, Row, Col, Modal } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import moment from "moment";
import { wallClock, chat, booth } from "../../components/Images/Images";
import "./MyCalendar.css";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import SearchBar from "../common/SearchBar";
// import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
// import { sendMessageAPI } from "../../utils/commonApis";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { Chat } from "../../components/svgJS/Chat";

import { sendMessageAPI } from "../../utils/commonApis";

const MyCalendar = (props) => {
  const { TextArea } = Input;
  const [currentDate, setCurrentDate] = useState(moment().format("yyyy-MM-DD"));
  const [data, setData] = useState(appointments);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [userResponse, setUserResponse] = useState({});
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [color, setColor] = useState();
  const [minStartTime, setMinStartTime] = useState();
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });

  const messageValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          // console.log("CometChat Login failed with exception:", { error });
        }
      );
    }

    getAppointmentDetails();

    setUserResponse({
      event_id: selectedAppointment && selectedAppointment?.event_id,
      hall_type: selectedAppointment && selectedAppointment?.hall_type,
      hall_id: selectedAppointment && selectedAppointment?.hall_id,
      session_id: selectedAppointment && selectedAppointment?.session_id,
      invitation_setup_id:
        selectedAppointment && selectedAppointment?.invitation_setup_id,
      rsvp_type: "YES",
      hosting_speaker_id:
        selectedAppointment && selectedAppointment?.hosting_speaker_id,
      rsvp_detail_id:
        selectedAppointment && selectedAppointment?.rsvp_detail_id,
    });
  }, [selectedAppointment, userResponse.rsvp_type]);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const eventId = {
    event_id: 92,
  };
  const getAppointmentDetails = async () => {
    await ApiCall("get", null, "/admin/mycalendar/details/get", null)
      .then((response) => {
        setAppointmentDetails(response?.data?.data ? response?.data?.data : []);
        console.log("GET appointmentDetails RESPONSE", appointmentDetails)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...userResponse, rsvp_type: "YES" };
    else objToPass = { ...userResponse, rsvp_type: "NO" };
    selectedAppointment !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            // window.location.reload();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  // console.log("brand_color",color)

  const Appointment = ({ children, style, ...restProps }) => {
    // console.log("children", children);
    // console.log("style", style);
    // console.log("restprops", restProps);
    // console.log("restppropColor", restProps.data.brand_color);

    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: restProps.data.brand_color,
          borderRadius: "8px",
        }}
      >
        {children}
      </Appointments.Appointment>
    );
  };

  const overrideStyles = {
    backgroundColor: "red",
  };
  const formattedData =
    appointmentDetails && appointmentDetails
      ? appointmentDetails.map((data) => {
        return {
          ...data,
          startDate: moment(data.start_date_time, "YYYY-MM-DD HH:mm"),
          endDate: moment(data.end_date_time, "YYYY-MM-DD HH:mm"),
          title: data.session_title,
          startHour: parseInt(moment(data.start_date_time).utcOffset(data.start_date_time).format("HH"))
        };
      })
      : "";

  console.log("formattedData", formattedData);
  const Content = ({ children, appointmentData, ...restProps }) => (
    setSelectedAppointment(appointmentData),
    (
      <AppointmentTooltip.Content
        {...restProps}
        appointmentData={appointmentData}
        children="dfgfg"
        style={{ position: "relative" }}
      >
        {/* {document.querySelectorAll('.MuiGrid-root div:nth-child(2)') = "aaaaaaa"} */}
        {setColor(appointmentData.brand_color)}
        <div className="position-absolute top-0 w-100">
          <div className="row">
            <div className="col-1 text-center"></div>
            <div className="col-2 popup_1_modal_circle"></div>
            <div className="col-5 mx-2 fs-22">
              <b>{appointmentData?.title}</b>
              <p className="fs-14">
                by{" "}
                <b>
                  {appointmentData?.brand_title
                    ? appointmentData?.brand_title
                    : "NA"}
                </b>
              </p>
            </div>
          </div>
        </div>
        <hr />

        {appointmentData.rsvp_type == "YES" ? (
          <div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-1">
                <img src={wallClock} />
              </div>
              <div className="col-5 fs-14">
                <b>
                  {appointmentData.start_time} - {appointmentData.end_time}{" "}
                </b>
              </div>
            </div>
            {appointmentData.channel_id == 0 ? (
              " "
            ) : (
              <div className="row mt-3">
                <div className="col-2"></div>
                <div className="col-1">
                  {/* <img src={chat} className="admin_popup_icons" /> */}
                  <Tooltip
                    color="#ef0855"
                    placement="right"
                    title={"Chat"}
                    className=""
                  >
                    <Button
                      className="border px-2"
                      onClick={() => {
                        setMessageModal({
                          isOpen: true,
                          channel_id: appointmentData.channel_id,
                        });
                      }}
                    >
                      <Chat />
                    </Button>
                  </Tooltip>
                </div>

                <div className="col-8">
                  <span className="fs-16 popup_text_main">
                    Go to Moderation or Chat{" "}
                  </span>

                  <br />
                  <span className="fs-13">
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                  </span>
                </div>
                <div className="col-1"></div>
              </div>
            )}
            <div className="row">
              <div className="col-2"></div>
              <div className="col-8">
                {messageModal.isOpen ? (
                  <div className="w-100">
                    <div className="w-100 row mt-3">
                      <div className="d-block col-2">
                        <p>
                          Message
                          <RedAsterick />
                        </p>
                      </div>
                      <div className="w-75 col-8">
                        <input
                          name="message_text"
                          id="message_text"
                          className=" w-100 mb-2 p-2 border border-secondary rounded"
                          placeholder="Enter a message"
                          // rows={4}
                          value={message_text}
                          onChange={(e) => set_message_text(e.target.value)}
                          onBlur={() =>
                            messageValidator.current.showMessageFor(
                              "message_text"
                            )
                          }
                          autoFocus={true}
                        />
                        <Button
                          className="px-3 my-2 rounded"
                          key="save"
                          type="primary"
                          onClick={() =>
                            callSendMessageAPI(messageModal?.channel_id)
                          }
                        >
                          Send
                        </Button>
                        <Button
                          className="px-3 my-2 rounded mx-2"
                          key="save"
                          type="primary"
                          onClick={() => {
                            setMessageModal({ ...messageModal, isOpen: false });
                          }}
                        >
                          Close
                        </Button>
                        {messageValidator.current.message(
                          "Message",
                          message_text,
                          "required",
                          { className: "text-danger py-1" }
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2"></div>
              <div className="col-1">
                <img src={booth} className="admin_popup_icons px-2" />
              </div>
              <div className="col-8">
                <span className="fs-16 popup_text_main">
                  Go to Booth or Session
                </span>
                <br />
                <span className="fs-13">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </span>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="row mt-3 mb-2">
              <div className="col-2"></div>
              <div className="col-1">
                <VideoCameraOutlined
                  className="admin_popup_icons"
                  onClick={() =>
                    window
                      .open(appointmentData.join_session_url, "_blank")
                      .focus()
                  }
                />
              </div>
              <div className="col-8">
                <span
                  className="fs-16 popup_text_main"
                  onClick={() =>
                    window
                      .open(appointmentData.join_session_url, "_blank")
                      .focus()
                  }
                >
                  Go to Speakers Room
                </span>
                <br />
                <span className="fs-13">
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal
                </span>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-1">
                <img src={wallClock} />
              </div>
              <div className="col-5 fs-14">
                <b>
                  {appointmentData.start_time} - {appointmentData.end_time}{" "}
                </b>
              </div>
            </div>
            <div className="row mt-3 mb-2">
              <div className="col-2"></div>
              <div className="col-6">
                <Button
                  type="primary"
                  className="button-pink py-1 rounded"
                  onClick={() => saveResponseData(true)}
                >
                  Accept
                </Button>
                <Button
                  type="primary"
                  className="button-pink py-1 mx-3 rounded"
                  onClick={() => saveResponseData(false)}
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
        )}
      </AppointmentTooltip.Content>
    )
  );

  const minTime =
    Math.min(
      ...formattedData?.map(element => {
        return element.startHour
      }),
    );

  setTimeout(() => {
    setMinStartTime(minTime)
    console.log("minTimeStartState", minStartTime)
  }, 5000)

  console.log("minTime", minTime)



  return (
    <Fragment>
      <Paper className="d-flex">
        <Paper className="w-25 p-3 border border-end">
          {/* <Input placeholder="Search Sponsor" className="rounded w-100" /> */}
          <SearchBar
            placeholder="Search Sponsor"
            className="rounded w-100"
            details={appointmentDetails}
          />
        </Paper>

        <Scheduler
          data={formattedData}
          height={660}
          style={{ border: "1px solid red" }}
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={(e) => setCurrentDate(e)}
          />
          <Toolbar />
          <TodayButton />
          <DateNavigator />

          <WeekView
            startDayHour={1}
            endDayHour={24}
          // CurrentTimeIndicator={true}
          />

          {/* <ConfirmationDialog /> */}
          {/* <Appointments /> */}

          <Appointments appointmentComponent={Appointment}>
            <Appointments.Appointment
              style={{ backgroundColor: "green" }}
            ></Appointments.Appointment>
          </Appointments>
          {/* <CurrentTimeIndicator indicatorComponent={() => (
            <div className="current_time_indicator"></div>)} /> */}

          <AppointmentTooltip contentComponent={Content} showCloseButton />
        </Scheduler>
      </Paper>
    </Fragment>
  );
};

export default MyCalendar;
