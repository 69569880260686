import React, { useState, useRef } from "react";
import { Modal, Row, Col, TimePicker, Input, Button, Typography } from "antd";
import moment from "moment";
import "../eventCalender.css";
import SimpleReactValidator from "simple-react-validator";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { toast } from "react-toastify";
import {
  createExpoHall,
  updateExpoHall,
} from "../../../redux/slice/eventCalender";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { dialogs } from "../../../components/constsnts/string.constants";

const ExpoHallOpen = (props) => {
  console.log("props ExpoHallOpen = ", props);
  const dispatch = useDispatch();
  const { expoHallData } = props;
  const { TextArea } = Input;
  const { Text } = Typography;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [expHallForceUpdate, setExpoHallForceUpdate] = useState(false);
  const [expoHallLocalData, setExpoHallLocalData] = useState({
    expo_hall_configuration_id: expoHallData.expo_hall_configuration_id ?? "",
    expo_hall_description: expoHallData.expo_hall_description ?? "",
    hall_title: expoHallData.hall_title ?? "",
    start_time: expoHallData.start_time
      ? moment(expoHallData.start_time, TIME_FORMAT)
      : moment(moment().format("HH:mm"), TIME_FORMAT),
    end_time: expoHallData.start_time
      ? moment(expoHallData.end_time, TIME_FORMAT)
      : moment(moment().format("HH:mm"), TIME_FORMAT),
    event_id: expoHallData.event_id,
  });

  const createUpdateAPI = async (dataToUpdate) => {
    console.log("dataToUpdate/create = ", dataToUpdate);
    if (dataToUpdate.expo_hall_configuration_id) {
      const updateRes = await dispatch(updateExpoHall(dataToUpdate));
      console.log("update response", updateRes);
      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error update expo");
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      const createRes = await dispatch(createExpoHall(dataToUpdate));
      console.log("create expo response", createRes);

      if (createRes?.payload?.data?.code === 200) {
        console.log("in 200 create expo", createRes);
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        console.log("in error create expo");
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    console.log("expoHallLocalData = ", expoHallLocalData);
    if (validator.current.allValid()) {
      const { expoHallData } = props;
      console.log(
        "currentDateForCalender = ",
        expoHallData.currentDateForCalender
      );
      let clonedData = cloneDeep(expoHallLocalData);
      const dateHere = expoHallData.currentDateForCalender;

      clonedData.start_time =
        dateHere + " " + moment(clonedData.start_time).format("HH:mm:ss");
      clonedData.end_time =
        dateHere + " " + moment(clonedData.end_time).format("HH:mm:ss");
      console.log("clonedData final = ", clonedData);

      if (clonedData.start_time === clonedData.end_time)
        toast.error("Please change end time");
      else createUpdateAPI(clonedData);
    } else {
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setExpoHallForceUpdate(!expHallForceUpdate);
    }
  };

  const onChangeFields = (val, name) => {
    setExpoHallLocalData({ ...expoHallLocalData, [name]: val });
  };

  return (
    <Modal
      title={<b>Expo Hall Open</b>}
      visible={true}
      width={750}
      // bodyStyle={{ height: 450 }}
      onOk={() => props.onCloseModal()}
      onCancel={() => props.onCloseModal()}
      footer={[
        <Button
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <div className="expohall_form">
        <Row
          className="expohall_row"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={6} className="expohall_label gutter-row">
            <Text style={{ color: "#666666", fontWeight: "500" }}>
              Start & End Time
            </Text>
          </Col>
          <Col className="gutter-row" span={8}>
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              name="start_time"
              id="start_time"
              format={format}
              value={expoHallLocalData.start_time}
              className="expohall_timepicker"
              style={{ width: "100%" }}
              onSelect={(val) => {
                console.log(" val =  ", val);
                onChangeFields(
                  moment(moment(val).format("HH:mm"), TIME_FORMAT),
                  "start_time"
                );
              }}
              onBlur={() => validator.current.showMessageFor("start_time")}
            />

            {validator.current.message(
              "Start Time",
              expoHallLocalData.start_time,
              "required",
              {
                className: "text-danger py-1",
              }
            )}
          </Col>
          <Col className="gutter-row" span={8}>
            <TimePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              name="end_time"
              id="end_time"
              disabledDate={(d) =>
                !d || d.isSameOrBefore(expoHallLocalData.start_time)
              }
              value={expoHallLocalData.end_time}
              format={format}
              className="expohall_timepicker"
              style={{ width: "100%" }}
              onSelect={(val) =>
                onChangeFields(
                  moment(moment(val).format("HH:mm"), TIME_FORMAT),
                  "end_time"
                )
              }
              onBlur={() => validator.current.showMessageFor("end_time")}
            />
            {validator.current.message(
              "End Time",
              expoHallLocalData.end_time,
              "required",
              { className: "text-danger py-1" }
            )}
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={6} className="expohall_label gutter-row">
            <Text style={{ color: "#666666", fontWeight: "500" }}>
              Expo Hall Description
            </Text>
          </Col>
          <Col className="gutter-row" span={16}>
            <TextArea
              name="expo_hall_description"
              id="expo_hall_description"
              className="expohall_inputDescription"
              placeholder="Enter a description here"
              rows={4}
              value={expoHallLocalData.expo_hall_description}
              onChange={(e) =>
                onChangeFields(e.target.value, "expo_hall_description")
              }
              onBlur={() =>
                validator.current.showMessageFor("expo_hall_description")
              }
              minLength={1}
              maxLength={200}
            />
            {validator.current.message(
              "Description",
              expoHallLocalData.expo_hall_description,
              "required",
              { className: "text-danger py-1" }
            )}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ExpoHallOpen;
