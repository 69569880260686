import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const getEventNetworkingLoungeDetails = createAsyncThunk(
  "networking/getEventNetworkingLounge",
  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkinglounges/get",
      params
    );
    // console.log(response?.data?.data);
    if (response?.data?.code === 200) {
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getNetworkingLoungeSessionDetails = createAsyncThunk(
  "networking/getEventNetworkingSessionDetails",
  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkingloungesessions/get",
      params
    );
    if (response?.data?.code === 200) {
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getNetworkingSessionTableDetails = createAsyncThunk(
  "networking/getNetworkingSessionTableDetails",
  async params => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/networkingsession/jointables/get",
      params
    );
    if (response?.data?.data) return response?.data?.data;
    else return [];
  }
);

export const networkingSlice = createSlice({
  name: "networking",
  initialState: {
    loading: false,
    eventNetLoungeData: [],
    networkingLoungeSessionData: [],
    networkingTableData: []
  },
  reducers: {},
  extraReducers: {
    [getEventNetworkingLoungeDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventNetworkingLoungeDetails.fulfilled]: (state, { payload, meta }) => {
      state.eventNetLoungeData = payload;
      state.loading = false;
    },
    [getEventNetworkingLoungeDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [getNetworkingLoungeSessionDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getNetworkingLoungeSessionDetails.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      state.networkingLoungeSessionData = payload;
      state.loading = false;
    },
    [getNetworkingLoungeSessionDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [getNetworkingSessionTableDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getNetworkingSessionTableDetails.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      state.networkingTableData = payload;
      state.loading = false;
    },
    [getNetworkingSessionTableDetails.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export const networkingReducer = networkingSlice.reducer;
