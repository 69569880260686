import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

export const getCustomer = createAsyncThunk(
  "customerManagement/getCustomer",

  async params => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/get?is_details=false",
      params
    );
    // console.log('response of api list', response)

    return response.data.data;
  }
);

export const getCustomerDetails = createAsyncThunk(
  "customerManagement/getCustomerDetails",

  async params => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/details/get",
      params
    );
    console.log("customerResp", response);
    // console.log('response of getcustomer details123455 ', response)
    return response;
  }
);

export const createCustomer = createAsyncThunk(
  "customerManagement/createCustomer",

  async body => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall("POST", body, "/website/customer/create");

    // console.log('response of getcustomer details123455 ', response)
    return response.data;
  }
);

export const editCustomer = createAsyncThunk(
  "customerManagement/editCustomer",

  async body => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall("PUT", body, "/website/customer/update");

    // console.log('response of editcustomer  ', response)
    return response.data;
  }
);

export const changeStatus = createAsyncThunk(
  "customerManagement/changeStatus",

  async body => {
    // console.log('paramsAPIBrand', params)

    await ApiCall("PUT", body, "/website/customer/status/update");

    const response = await ApiCall(
      "GET",
      "",
      "/website/customer/get?is_details=false"
    );

    // console.log('response of editcustomer  ', response)
    return response.data.data;
  }
);

export const createRegisterCustomer = createAsyncThunk(
  "customerManagement/createCustomer",

  async body => {
    // console.log('paramsAPIBrand', params)

    const response = await ApiCall(
      "POST",
      body,
      "/website/customer/password/create"
    );

    console.log("response of create Register Customer", response);
    return response.data;
  }
);

export const customerManagementSlice = createSlice({
  name: "customerManagement",
  initialState: {
    loading: false,
    data: [],
    customerDetails: []
  },
  reducers: {},
  extraReducers: {
    [getCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomer.fulfilled]: (state, { payload, meta }) => {
      // console.log('listing payload console', payload)
      state.data = payload;
      state.loading = false;
    },
    [getCustomer.rejected]: (state, action) => {
      state.loading = false;
    },

    [editCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [editCustomer.fulfilled]: (state, { payload, meta }) => {
      // console.log('listing payload console', payload)
      state.data = payload;
      state.loading = false;
    },
    [editCustomer.rejected]: (state, action) => {
      state.loading = false;
    },

    // customer details

    [getCustomerDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerDetails.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      state.customerDetails = payload;

      // state.data = payload
      state.loading = false;
    },
    [getCustomerDetails.rejected]: (state, action) => {
      state.loading = false;
    },

    //create customer

    [createCustomer.pending]: (state, action) => {
      state.loading = true;
    },

    [createCustomer.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      // state.data = payload
      state.loading = false;
    },
    [createCustomer.rejected]: (state, action) => {
      state.loading = false;
    },

    [createRegisterCustomer.pending]: (state, action) => {
      state.loading = true;
    },

    [createRegisterCustomer.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      // state.data = payload
      state.loading = false;
    },
    [createRegisterCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    //status

    [changeStatus.pending]: (state, action) => {
      state.loading = true;
    },

    [changeStatus.fulfilled]: (state, { payload, meta }) => {
      // console.log("payload console ",payload)
      // state.customerDetails = payload

      state.data = payload;
      state.loading = false;
    },
    [changeStatus.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export const customerReducer = customerManagementSlice.reducer;
