import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Scheduler,
  DayView,
  Appointments,
  AppointmentForm,
  GroupingPanel,
  Resources,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import { connectProps } from "@devexpress/dx-react-core";
import moment from "moment";
// import appointments from './demo-data/today-appointments';
import { Input, Breadcrumb } from "antd";
import { ApiCall } from "../../utils/api";
import ProductChatStaffing from "./zone-pop-up/ProductChatStaffing";
import ProductZoneSession from "./zone-pop-up/ProductZoneSession";
import AppointmentFormContainerBasic from "./AppointmentForm";
import "./zoneCalender.css";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

const ZoneCalender = ({ match }) => {
  console.log("match", match);
  const [date, setdate] = useState(moment());
  // console.log("date", date);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [appointments, setappointments] = useState([]);
  const [editingFormVisible, seteditingFormVisible] = useState(false);
  const [editingAppointment, seteditingAppointment] = useState(undefined);
  const [previousAppointment, setpreviousAppointment] = useState(undefined);
  const [addedAppointment, setaddedAppointment] = useState({});
  const [isNewAppointment, setisNewAppointment] = useState(false);
  const [confirmationVisible, setconfirmationVisible] = useState(false);
  const [deletedAppointmentId, setdeletedAppointmentId] = useState(undefined);

  const [showtooltip, setshowtooltip] = useState(false);
  // console.log("appointments", appointments);
  // console.log('appointmentDetails', appointmentDetails)

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  useEffect(async () => {
    var allArr = [];

    (await appointmentDetails.auditorium_session_details) &&
      appointmentDetails.auditorium_session_details.forEach((elem) => {
        allArr.push({
          ...elem,
          startDate: new Date(elem.start_date_time),
          endDate: new Date(elem.end_date_time),
          title: elem.session_title,
          priorityId: 2,
        });
      });
    // console.log(allArr)

    (await appointmentDetails.networking_lounge_session_details) &&
      appointmentDetails.networking_lounge_session_details.forEach((elem) => {
        allArr.push({
          ...elem,
          startDate: new Date(elem.start_date_time),
          endDate: new Date(elem.end_date_time),
          title: elem.session_title,
          priorityId: 2,
        });
      });
    // console.log(allArr)

    (await appointmentDetails.product_zone_session_demo_details) &&
      appointmentDetails.product_zone_session_demo_details.forEach((elem) => {
        allArr.push({
          ...elem,
          startDate: new Date(elem.start_date_time),
          endDate: new Date(elem.end_date_time),
          title: elem.session_title,
          priorityId: 1,
        });
      });
    // console.log(allArr)

    (await appointmentDetails.product_area_session_chat_details) &&
      appointmentDetails.product_area_session_chat_details.forEach((elem) => {
        allArr.push({
          ...elem,
          startDate: new Date(elem.start_date_time),
          endDate: new Date(elem.end_date_time),
          title: "Zone Staffing",
          priorityId: 3,
        });
      });

    // console.log(allArr)
    await setappointments(allArr);
  }, [appointmentDetails]);

  const Content = ({ children, appointmentData, ...restProps }) => {
    // setSelectedAppointment(appointmentData),
    // <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
    {
      console.log("bbbbbbbbbbbbbb", appointmentData);
    }
    //   <div className="row">

    //   </div>
    //   <hr />

    // </AppointmentTooltip.Content>

    return (
      <div style={{ marginBottom: "100px" }}>
        {appointmentData.priorityId === 1 ? (
          <ProductZoneSession
            data={appointmentData}
            extraProps={{
              visibleChange: toggleEditingTooltipVisibility,
              getAppointmentDetails: getAppointmentDetails,
              eventId: parseInt(match?.params?.event_id),
              zoneId: parseInt(match?.params?.zone_id),
            }}
          />
        ) : (
          <ProductChatStaffing
            data={appointmentData}
            extraProps={{
              visibleChange: toggleEditingTooltipVisibility,
              getAppointmentDetails: getAppointmentDetails,
              eventId: parseInt(match?.params?.event_id),
              zoneId: parseInt(match?.params?.zone_id),
            }}
          />
        )}
      </div>
    );
  };
  const Header = ({ children, appointmentData, ...restProps }) => {
    return (
      <div
        style={{
          height: "50px",
          backgroundColor: "rgb(246 246 246)",
          marginBottom: "30px",
        }}
        className="d-flex justify-content-between"
      >
        <div className="my-auto mx-4">
          <h5 style={{ color: "#EF0855" }}>
            <b>
              {appointmentData.priorityId === 1
                ? "Product Zone - Session"
                : "Product/area chat staffing"}
            </b>
          </h5>
        </div>
        <div
          className="my-auto mx-4"
          onClick={() => toggleEditingTooltipVisibility()}
        >
          <CloseOutlined />
        </div>
      </div>
    );
  };

  // const appointmentForm = ({ children, appointmentData, ...restProps }) => {
  //   console.log("step 1 children ", children);
  //   console.log("step 2 appointmentData", appointmentData);
  //   console.log("step 3 restProps = ", restProps);

  //   return (
  //     <div style={{ marginBottom: "100px" }}>
  //       <span>123456</span>
  //       {/* {appointmentData.priorityId === 1 ? (
  //         <ProductZoneSession data={appointmentData} />
  //       ) : (
  //         <ProductChatStaffing data={appointmentData} />
  //       )} */}
  //     </div>
  //   );
  // };

  const params = {
    // event_id:match?.params?.event_id ,
    // date:moment(date).format('YYYY-MM-DD'),
    // date: "2022-01-19",
    zone_id: parseInt(match?.params?.zone_id),
    brand_id: parseInt(match?.params?.brand_id),
    // zone_id:3
  };
  const getAppointmentDetails = () => {
    ApiCall("get", null, "/admin/zonecalendar/details/get", params)
      .then((response) => {
        // console.log('response', response)
        setAppointmentDetails(response?.data?.data ? response?.data?.data : []);
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const resources = [
    {
      fieldName: "priorityId",
      title: "Priority",
      instances: [
        { text: "Demo Room", id: 1, color: "rgb(238, 37, 70)" },
        {
          text: "Auditorium and Networking Session",
          id: 2,
          color: "rgb(238, 37, 70)",
        },
        { text: "Zone Staffing", id: 3, color: "rgb(238, 37, 70)" },
      ],
    },
  ];

  const grouping = [
    {
      resourceName: "priorityId",
    },
  ];

  const commitChanges = ({ added, changed, deleted }) => {
    // this.setState((state) => {
    if (added) {
      const startingAddedId =
        appointments.length > 0
          ? appointments[appointments.length - 1].id + 1
          : 0;
      appointments = [...appointments, { id: startingAddedId, ...added }];
    }
    if (changed) {
      appointments = appointments.map((appointment) =>
        changed[appointment.id]
          ? { ...appointment, ...changed[appointment.id] }
          : appointment
      );
    }
    if (deleted !== undefined) {
      setdeletedAppointmentId(deleted);
      setconfirmationVisible(!confirmationVisible);
    }
    return { appointments, addedAppointment: {} };
    // });
  };

  const toggleEditingFormVisibility = () => {
    seteditingFormVisible(!editingFormVisible);
  };

  const toggleEditingTooltipVisibility = () => {
    setshowtooltip(!showtooltip);
  };

  const onEditingAppointmentChange = (editingAppointment) => {
    seteditingAppointment(editingAppointment);
  };

  const onAddedAppointmentChange = (addedAppointment) => {
    setaddedAppointment(addedAppointment);
    if (editingAppointment !== undefined) {
      setpreviousAppointment(editingAppointment);
    }
    seteditingAppointment(undefined);
    setisNewAppointment(true);
  };

  return (
    <>
      <div className="zone_calendar">
        <h4 className="fs-24">{appointmentDetails?.zone_title} Calendar</h4>
        <Breadcrumb className="my-2" separator=">">
          <Breadcrumb.Item>
            <Link to="/zonemanagement">Expo Maps</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/zonemanagement">Brands</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/createnewzone">Zone Calendar</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/createnewzone">{appointmentDetails?.zone_title}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Paper className="d-flex">
          <Paper className="w-25 p-3">
            <Input placeholder="Search Sponsor" className="rounded w-100" />
          </Paper>
          <Scheduler data={appointments}>
            <ViewState
              // currentDate='2021/02/02'
              currentDate={date}
              onCurrentDateChange={(r) => {
                setdate(r);
              }}
            />
            <EditingState
              // onCommitChanges={commitChanges}
              onEditingAppointmentChange={onEditingAppointmentChange}
              onAddedAppointmentChange={onAddedAppointmentChange}
            />
            <GroupingState
              grouping={grouping}
              // groupOrientation={groupOrientation}
            />
            <DayView startDayHour={0} endDayHour={24} />
            <Appointments />

            <Resources data={resources} mainResourceName="priorityId" />
            <IntegratedGrouping />
            <GroupingPanel />
            <Toolbar />
            <DateNavigator />
            <TodayButton />
            <AppointmentTooltip
              headerComponent={Header}
              contentComponent={Content}
              showCloseButton
              visible={showtooltip}
              onVisibilityChange={() => setshowtooltip(!showtooltip)}
            />
            <AppointmentForm
              overlayComponent={connectProps(
                AppointmentFormContainerBasic,
                () => {
                  const currentAppointment =
                    appointments.filter(
                      (appointment) =>
                        editingAppointment &&
                        appointment.id === editingAppointment.id
                    )[0] || addedAppointment;
                  const cancelAppointment = () => {
                    if (isNewAppointment) {
                      setisNewAppointment(false);
                      seteditingAppointment(previousAppointment);
                    }
                  };

                  // console.log("currentAppointment =", currentAppointment);

                  return {
                    visible: editingFormVisible,
                    appointmentData: currentAppointment,
                    zoneId: parseInt(match?.params?.zone_id),
                    brandId: parseInt(match?.params?.brand_id),
                    eventId: parseInt(match?.params?.event_id),
                    commitChanges: commitChanges,
                    visibleChange: toggleEditingFormVisibility,
                    onEditingAppointmentChange: onEditingAppointmentChange,
                    cancelAppointment,
                    getAppointmentDetails: getAppointmentDetails,
                  };
                }
              )}
              visible={editingFormVisible}
              onVisibilityChange={() =>
                seteditingFormVisible(!editingFormVisible)
              }
            />
          </Scheduler>
        </Paper>
      </div>
    </>
  );
};

export default ZoneCalender;
