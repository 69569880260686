import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState = {
    brandObj: {},
    expo_hall_sequence: null
}


export const setVistaBrandData = createAsyncThunk(

    'vistaBrandData',

    async (brandObj) => {

        return brandObj;
    })

export const setVistaExpoHallSequence = createAsyncThunk(

    'vistaexpohallsequence',

    async (value) => {

        return value;
    })


export const setVistaBrandSlice = createSlice({
    name: 'vistaBrandData',
    initialState,
    reducers: {},
    extraReducers: {
        [setVistaBrandData.pending]: (state) => {
            state.loading = true
        },
        [setVistaBrandData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.brandObj = payload
        },
        [setVistaBrandData.rejected]: (state) => {
            state.loading = false
        },
        [setVistaExpoHallSequence.pending]: (state) => {
            state.loading = true
        },
        [setVistaExpoHallSequence.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.expo_hall_sequence = payload
        },
        [setVistaExpoHallSequence.rejected]: (state) => {
            state.loading = false
        }
    },
})

export const vistaBrandReducer = setVistaBrandSlice.reducer
