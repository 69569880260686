import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { Button, Card, Input, Avatar } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { logo } from "../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/login";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";
import { auto } from "@popperjs/core";
import CommonLoader from "../../components/Widgets/CommonLoader";
import Vista from "../Vista/src/components/vista";
import VistaApp from "../Vista/src/VistaApp";
import LoginBox from "./LoginBox";

const Login = (props) => {
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [email, setEmail] = useState("");
  const [showError, setshowError] = useState(false);
  const [isError, setisError] = useState("");
  const [password, setPassword] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");
  const validator = useRef(new SimpleReactValidator());
  const { loading } = useSelector((state) => state.login);
  const [isRenderLogin, setIsRenderLogin] = useState(false);
  const [flag, setFlag] = useState(false);
  let [bgColor, setBgColor] = useState("");

  const btnRef = useRef(null);

  useEffect(() => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "admin-grv8.dev.brainvire.net" ||
      window.location.hostname === "admin.staging.gravit8.io" ||
      window.location.hostname === "grv8-admin.dev.brainvire.net"
    ) {
      // setFlag(true);

      window.less.modifyVars({ "@primary-color": "#ef0855" }).then(() => {
        // console.log("Theme updated successfully");
      });
      localStorage.setItem("event_color_theme", "#ef0855");

      localStorage.setItem("event_logo_url", logo);

      setIsRenderLogin(true);
    } else if (window.location.hostname === "admin-grv8.demo.brainvire.net") {
      setFlag(true);
    } else
      ApiCall("get", null, "/admin/subdomain/get", null)
        .then((response) => {
          console.log("response subdomain resp= ", response);
          if (
            response &&
            response.data &&
            response.data.message === "Successful Operation"
          ) {
            setIsRenderLogin(true);
            setFlag(true);
            if (response.data && response.data.data) {
              if (response.data.data.login_background_theme) {
                setBgColor(response.data.data.login_background_theme);
              }

              let default_button_background_color =
                response.data.data.default_button_background_color ?? "#ffffff";
              let default_button_background_second_color = response.data.data
                .default_button_background_second_color
                ? response.data.data.default_button_background_second_color
                : response.data.data.default_button_background_color
                ? response.data.data.default_button_background_color
                : "#ffffff";

              let default_button_gradient_angle =
                response.data.data.default_button_gradient_angle ?? 0;
              let default_button_text_color =
                response.data.data.default_button_text_color ?? "#ef0855";
              let highlight_button_background_color =
                response.data.data.highlight_button_background_color ??
                "#ef0855";
              let highlight_button_background_second_color = response.data.data
                .highlight_button_background_second_color
                ? response.data.data.highlight_button_background_second_color
                : response.data.data.highlight_button_background_color
                ? response.data.data.highlight_button_background_color
                : "#ef0855";
              let highlight_button_gradient_angle =
                response.data.data.highlight_button_gradient_angle ?? 0;
              let highlight_button_text_color =
                response.data.data.highlight_button_text_color ?? "#ffffff";
              let event_color_theme =
                response.data.data.event_color_theme ?? "#ef0855";

              if (!localStorage.getItem("event_color_theme")) {
                window.less
                  .modifyVars({
                    "@primary-color": event_color_theme,
                    "@default_button_background_color":
                      default_button_background_color,
                    "@default_button_background_second_color":
                      default_button_background_second_color,
                    "@default_button_text_color": default_button_text_color,
                    "@highlight_button_background_color":
                      highlight_button_background_color,
                    "@highlight_button_background_second_color":
                      highlight_button_background_second_color,
                    "@highlight_button_text_color": highlight_button_text_color,
                    "@default_button_gradient_angle":
                      default_button_gradient_angle + "deg",
                    "@highlight_button_gradient_angle":
                      highlight_button_gradient_angle + "deg",
                  })
                  .then(() => {
                    // console.log("Theme updated successfully 1234");
                  });

                localStorage.setItem("event_color_theme", event_color_theme);

                let btnColorData = {
                  default_button_background_color,
                  default_button_background_second_color,
                  default_button_gradient_angle:
                    default_button_gradient_angle + "deg",
                  default_button_text_color,
                  highlight_button_background_color,
                  highlight_button_background_second_color,
                  highlight_button_gradient_angle:
                    highlight_button_gradient_angle + "deg",
                  highlight_button_text_color,
                };

                localStorage.setItem(
                  "btnColorData",
                  JSON.stringify(btnColorData)
                );
              }

              if (!localStorage.getItem("event_logo_url")) {
                localStorage.setItem(
                  "event_logo_url",
                  response.data.data.event_logo_url
                );
              }

              localStorage.setItem("event_id", response.data.data.event_id);
            }
          }
        })
        .catch((err) => {
          console.error("err in domain api", err);
        });
  }, []);

  return (
    <Fragment>
      {flag ? (
        <div style={{ width: "100%", height: "100vh", position: "relative" }}>
          <VistaApp props={props} />
          <LoginBox isRenderLogin={isRenderLogin} loginProps={props} />
        </div>
      ) : (
        <div
          style={{ backgroundColor: bgColor }}
          className="pt-5 login-bg-img-div"
        >
          <div className="site-card-border-less-wrapper  pt-5 text-center">
            <img
              className="login_img_logo"
              src={localStorage.getItem("event_logo_url")}
              alt="logo"
            />
            <LoginBox isRenderLogin={isRenderLogin} loginProps={props} />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Login;
