/**
 * Axios config  setup
 * Set interceptor for global api response error handling
 * Set access token in headers
 */
import axios from "axios";
import { toast } from "react-toastify";

// const REACT_APP_BASE_URL = "https://api.staging.gravit8.io";
// const REACT_APP_BASE_URL = "https://api-grv8.dev.brainvire.net";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ApiCall = async (method, body, endPoint, params, Token = "") => {
  try {
    const token = localStorage.getItem("Authorization");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
    }

    // console.log("APICALL===>", method,params, body, endPoint, Token);
    // console.log("params===>", params);
    const { data } = await axios({
      method: method,
      url: REACT_APP_BASE_URL + endPoint,
      data: body,
      headers: {
        "Content-Type": "application/json"
      },
      params: params
    });

    if (data.code === 200) {
      return {
        data: data,
        status: true
      };
    }
    if (data.code === 401 && data.message === "Unauthorised User" && localStorage.getItem("Authorization")) {
      localStorage.clear()
      window.location.href = "/login";
    } else {
      return {
        data: data,
        status: false
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        data: {},
        status: false,
        error: error.response.data.errors
      };
    }
    toast.error(error);
    return error;
  }
};

//for images
export const ApiCallForm = async (method, body, endPoint, Token) => {
  try {
    const token = localStorage.getItem("Authorization");
    // if (token) {
    //   fetch.defaults.headers.common["Authorization"] = token;
    // } else {
    //   fetch.defaults.headers.common["Authorization"] = null;
    // }

    console.log("APICALL method===>", method);
    console.log("APICALL body===>", body);
    console.log("APICALL endPoint===>", endPoint);
    console.log("APICALL Token===>", Token);

    //  console.log(params)

    const requestOptions = {
      method: method,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: token ? token : null
      },
      body: body
      // redirect: 'follow'
    };

    let url = REACT_APP_BASE_URL + endPoint;
    console.log("APICALL url===>", url);

    return await fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("APICALL result", result);
        if (result.code === 200) {
          console.log("APICALL 200", result.code);
          return {
            data: result,
            status: true
          };
        } else {
          return {
            data: result,
            status: false
          };
        }
      })
      .catch(error => {
        console.log("APICALL api", error);
        toast.error(error);
        return error;
      });

    // const { data } = await fetch({

    //   method: 'POST',
    //   url: "https://api-grv8.dev.brainvire.net" + endPoint,
    //   // process.env.REACT_APP_baseURl +endPoint,
    //   data: body,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'Authorization': token ? token : null,
    //   },
    //   //  params:params
    // });

    // if (data.code === 200) {
    //   return {
    //     data: data,
    //     status: true
    //   };
    // } else {
    //   // console.log('Error', data)
    //   return {
    //     data: data,
    //     status: false
    //   };
    // }
  } catch (error) {
    if (error.response) {
      // console.log("error", error.response);
      return {
        data: {},
        status: false,
        error: error.response.data.errors
      };
    }
    toast.error(error);
    return error;
  }
};

export const UploadFile = async (headerParams, method, body, endPoint) => {
  try {
    const token = localStorage.getItem("Authorization");
    const requestOptions = {
      method: method,
      headers: {
        "X-Chunk-Id": headerParams.chunkId.toString(),
        "X-ChuckSize": headerParams.chunkSize.toString(),
        "X-Chuck-Default-Size": headerParams.defaultChunkSize.toString(),
        "X-Content-Length": headerParams.fileSize.toString(),
        "X-Content-Name": headerParams.fileName,
        "X-Chunk-Last-Call": headerParams.lastChunk ? 1 : 0,
        "X-Original-Filename": headerParams.originalFileName,
        Authorization: token ? token : null
      },
      body: body
    };

    let url = REACT_APP_BASE_URL + endPoint;
    console.log("APICALL url===>", url);

    return await fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("APICALL result", result);
        if (result.code === 200) {
          console.log("APICALL 200", result.code);
          return {
            data: result,
            status: true
          };
        } else {
          return {
            data: result,
            status: false
          };
        }
      })
      .catch(error => {
        console.log("APICALL api", error);
        toast.error(error);
        return error;
      });
  } catch (error) {
    console.log(error);
    if (error.response) {
      // console.log("error", error.response);
      return {
        data: {},
        status: false,
        error: error.response.data.errors
      };
    }
    toast.error(error);
    return error;
  }
};
