import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm, UploadFile } from "../../utils/api";

export const getBrand = createAsyncThunk(
  "brandManagement/getBrand",
  async params => {
    // console.log("paramsAPIBrand", params);
    const response = await ApiCall("GET", "", "/admin/brand/get", params);
    // console.log("response get brand...", response);
    if (response?.data?.data) return response.data.data;
    else return [];
  }
);

export const getBrandDetails = createAsyncThunk(
  "brandManagement/getBrandDetails",
  async params => {
    // console.log("paramsAPIBrand ......", params);

    const response = await ApiCall(
      "GET",
      "",
      "/admin/brand/details/get",
      params
    );

    return response;
  }
);

export const addNewBrand = createAsyncThunk(
  "brandManagement/addNewBrand",
  async body => {
    console.log("bodyAPIBrand ......", typeof body);
    const response = await ApiCallForm("POST", body, "/admin/brand/create");
    return response;
  }
);

export const editBrand = createAsyncThunk(
  "brandManagement/editBrand",
  async body => {
    console.log("bodyAPIBrand ......", body);
    const response = await ApiCall("PUT", body, "/admin/brand/update");
    return response.data;
  }
);

export const changeBrandStatus = createAsyncThunk(
  "brandManagement/changeBrandStatus",
  async body => {
    console.log("bodyAPIBrand ......111111", body);
    await ApiCall("PUT", body, "/admin/brand/status/update");
    const response = await ApiCall("GET", "", "/admin/brand/get");
    return response.data.data;
  }
);

export const brandManagementSlice = createSlice({
  name: "brandManagement",
  initialState: {
    loading: false,
    data: [],
    brandViewData: []
  },
  reducers: {},
  extraReducers: {
    [getBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [getBrand.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [getBrand.rejected]: (state, action) => {
      state.loading = false;
    },

    [addNewBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [addNewBrand.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [addNewBrand.rejected]: (state, action) => {
      state.loading = false;
    },
    [editBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [editBrand.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [editBrand.rejected]: (state, action) => {
      state.loading = false;
    },
    [changeBrandStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [changeBrandStatus.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [changeBrandStatus.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export const brandReducer = brandManagementSlice.reducer;
