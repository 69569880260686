import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import "./SideBar.css";
import { Link, withRouter } from "react-router-dom";
import { logo, collapsedLogo } from "../../components/Images/Images";
import { location_png, add_group_png } from "../../components/Images/Images";
import { useHistory } from "react-router";

const SideBar = (props) => {
  var CryptoJS = require("crypto-js");

  const [menuDetails, setMenuDetails] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState([]);
  const [over, setOver] = useState({
    key: null,
    show: false,
  });
  const [overAdmin, setOverAdmin] = useState({
    key: null,
    show: false,
  });
  const { location, menuItem } = props;
  let pathname = location.pathname;

  useEffect(() => {
    const ciphertext = localStorage.getItem("side_menu");
    if(ciphertext){
      var bytes = CryptoJS.AES.decrypt(ciphertext, "side_menu");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else {
      window.open("/login", "_self");
    }

    let menu_index =
      decryptedData &&
      decryptedData?.findIndex((menu_iteam, parentIndex) => {
        // console.log("menu_iteam, parentIndex = ", menu_iteam, parentIndex);
        if (menu_iteam.route === pathname) return parentIndex;
        else if (menu_iteam.subRoutes) {
          menu_iteam.subRoutes.forEach((sub_menu_iteam) => {
            if (pathname.includes(sub_menu_iteam)) return parentIndex;
          });
        }
      });

    setMenuDetails(decryptedData);
    if (menu_index >= 0) {
      setMenuDetails(decryptedData);
    }
    setDefaultSelected([
      decryptedData &&
        decryptedData[menu_index] &&
        decryptedData[menu_index].menu_id.toString(),
    ]);
  }, []);

  const non_admin_menu =
    menuDetails && menuDetails.filter((data) => data.is_admin_menu == false);
  const admin_menu =
    menuDetails && menuDetails.filter((data) => data.is_admin_menu == true);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={props?.isSidebarCollapsed}
      className="bg-light sidebar-div"
    >
      {props?.isSidebarCollapsed ? (
        <div className="logo">
          <img src={collapsedLogo} alt="logo" className="img-fluid" />
        </div>
      ) : (
        <div className="logo">
          <img
            src={localStorage.getItem("event_logo_url")}
            alt="logo"
            className="img-fluid sidebar_logo"
          />
        </div>
      )}

      <Menu theme="light" mode="inline" selectedKeys={defaultSelected}>
        {/* <Menu.Item key="2" icon={<CalenderGrey />} className="list">
          <Link to="/mycalendar" className="link-list">
            My Calendar
          </Link>
        </Menu.Item> */}

        {non_admin_menu &&
          non_admin_menu.map((data, idx) => (
            <Menu.Item
              key={data.menu_id.toString()}
              className="list"
              onMouseOver={() =>
                setOver({
                  key: idx,
                  show: true,
                })
              }
              onMouseOut={() =>
                setOver({
                  key: idx,
                  show: false,
                })
              }
              icon={
                <img
                  src={
                    over.show && over.key === idx
                      ? data.white_icon_url
                      : data.icon_url
                  }
                  alt="sidebar icon"
                  className="rounded sidebar_icon"
                />
              }
              // onClick={() => onClickChange(data.menu_id.toString())}
              onClick={() => setDefaultSelected([data.menu_id.toString()])}
            >
              <Link to={data.route} className="link-list">
                {data.menu}
              </Link>
            </Menu.Item>
          ))}
        {admin_menu && admin_menu.length > 0 ? (
          <Menu.Item
            key="12"
            disabled
            style={{ cursor: "pointer" }}
            className="text-secondary"
          >
            Admin Console
          </Menu.Item>
        ) : (
          ""
        )}

        {admin_menu &&
          admin_menu.map((data, idx) => (
            <Menu.Item
              key={data.menu_id.toString()}
              className="list"
              onMouseOver={() =>
                setOverAdmin({
                  key: idx,
                  show: true,
                })
              }
              onMouseOut={() =>
                setOverAdmin({
                  key: idx,
                  show: false,
                })
              }
              icon={
                <img
                  src={
                    overAdmin.show && overAdmin.key === idx
                      ? data.white_icon_url
                      : data.icon_url
                  }
                  alt="sidebar icon"
                  className="rounded sidebar_icon"
                />
              }
              onClick={() => setDefaultSelected([data.menu_id.toString()])}
            >
              <Link to={data.route} className="link-list">
                {data.menu}
              </Link>
            </Menu.Item>
          ))}
      </Menu>
    </Layout.Sider>
  );
};

export default withRouter(SideBar);
