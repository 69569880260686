import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall, ApiCallForm } from "../../utils/api";

export const createExpoHall = createAsyncThunk(
  "eventCalender/createExpoHall",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/expohallconfiguration/create",
      ""
    );
    return response;
  }
);

export const updateExpoHall = createAsyncThunk(
  "eventCalender/updateExpoHall",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/expohallconfiguration/update",
      ""
    );
    return response;
  }
);

export const createSponser = createAsyncThunk(
  "eventCalender/createSponser",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/brandsponsorshipconfiguration/create",
      ""
    );
    return response;
  }
);

export const updateSponser = createAsyncThunk(
  "eventCalender/updateSponser",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/brandsponsorshipconfiguration/update",
      ""
    );
    return response;
  }
);

export const createTickerConfig = createAsyncThunk(
  "eventCalender/createTickerConfig",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/eventticker/create",
      ""
    );
    return response;
  }
);

export const updateTickerConfig = createAsyncThunk(
  "eventCalender/updateTickerConfig",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/eventticker/update",
      ""
    );
    return response;
  }
);

export const createAudiSession = createAsyncThunk(
  "eventCalender/createAudiSession",
  async (params) => {
    const response = await ApiCallForm(
      "POST",
      params,
      "/admin/auditoriumsessionconfiguration/create",
      ""
    );
    return response;
  }
);

export const updateAudiSession = createAsyncThunk(
  "eventCalender/updateAudiSession",
  async (params) => {
    const response = await ApiCallForm(
      "PUT",
      params,
      "/admin/auditoriumconfiguration/update",
      ""
    );
    return response;
  }
);

export const createNetworkSession = createAsyncThunk(
  "eventCalender/createNetworkSession",
  async (params) => {
    const response = await ApiCall(
      "POST",
      params,
      "/admin/networkingsession/create",
      ""
    );
    return response;
  }
);

export const updateNetworkSession = createAsyncThunk(
  "eventCalender/updateNetworkSession",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/networkingsession/update",
      ""
    );
    return response;
  }
);

export const updateExpoHallTitle = createAsyncThunk(
  "eventCalender/updateExpoHallTitle",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/expohall/title/update",
      ""
    );
    return response;
  }
);

export const updateAudiTitle = createAsyncThunk(
  "eventCalender/updateAudiTitle",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/auditoriumconfiguration/title/update",
      ""
    );
    return response;
  }
);

export const updateNetworkTitle = createAsyncThunk(
  "eventCalender/updateNetworkTitle",
  async (params) => {
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/networkingloungeconfiguration/title/update",
      ""
    );
    return response;
  }
);

export const eventCalenderSlice = createSlice({
  name: "eventCalender",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [createExpoHall.pending]: (state, action) => {
      state.loading = true;
    },
    [createExpoHall.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [createExpoHall.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateExpoHall.pending]: (state, action) => {
      state.loading = true;
    },
    [updateExpoHall.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateExpoHall.rejected]: (state, action) => {
      state.loading = false;
    },

    [createSponser.pending]: (state, action) => {
      state.loading = true;
    },
    [createSponser.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [createSponser.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateSponser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateSponser.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateSponser.rejected]: (state, action) => {
      state.loading = false;
    },
    [createTickerConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createTickerConfig.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [createTickerConfig.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateTickerConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTickerConfig.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateTickerConfig.rejected]: (state, action) => {
      state.loading = false;
    },
    [createAudiSession.pending]: (state, action) => {
      state.loading = true;
    },
    [createAudiSession.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [createAudiSession.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateAudiSession.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAudiSession.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [updateAudiSession.rejected]: (state, action) => {
      state.loading = false;
    },

    [createNetworkSession.pending]: (state, action) => {
      state.loading = true;
    },
    [createNetworkSession.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [createNetworkSession.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateNetworkSession.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNetworkSession.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.loading = false;
    },
    [updateNetworkSession.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateExpoHallTitle.pending]: (state, action) => {
      state.loading = true;
    },
    [updateExpoHallTitle.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateExpoHallTitle.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateAudiTitle.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAudiTitle.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateAudiTitle.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateNetworkTitle.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNetworkTitle.fulfilled]: (state, { payload, meta }) => {
      // state.data = payload
      state.loading = false;
    },
    [updateNetworkTitle.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const eventCalenderReducer = eventCalenderSlice.reducer;
