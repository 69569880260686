import React, { useEffect, useState } from "react";
import {
  Layout,
  Badge,
  Avatar,
  Menu,
  Dropdown,
  Breadcrumb,
  Select,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderNewEventId } from "../../redux/slice/headerData";

const Header = (props) => {
  var CryptoJS = require("crypto-js");
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [logo, setLogo] = useState();
  const headerData = useSelector((state) => state?.header?.headerObj);
  const eventList = useSelector((state) => state?.header?.events);

  const initial_event_id = useSelector(
    (state) => state?.header?.initial_event_id
  );
  const { Option } = Select;
  const dispatch = useDispatch();

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const ciphertextName = localStorage.getItem("user_name");
    var bytes2 = CryptoJS.AES.decrypt(ciphertextName, "user_name");
    var decryptedDataName = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
    setUserName(decryptedDataName);

    setLogo(localStorage.getItem("logo_url"));
  }, []);

  const history = useHistory();
  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    history.push("/login");
  };

  const menu = (
    <Menu className="gravit8_header_menu">
      <Menu.Item
        className="gravit8_header_menu_item"
        onClick={() => {
          history.push("/myprofile");
        }}
      >
        <span className="fs-14"> My Profile</span>
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>
        <span className="fs-14">Log Out</span>
      </Menu.Item>
    </Menu>
  );

  var nameArray = [];
  var user_name = userName.split(" ");
  nameArray.push(user_name.shift());
  nameArray.push(user_name.join(" "));

  console.log("logo in header", logo);

  return (
    <Layout.Header className="site-layout-background" style={{ padding: 0 }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {React.createElement(
            props?.isSidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger mt-4",
              onClick: props?.toggleSidebar,
            }
          )}
          {headerData?.title ? (
            <div className="d-flex justify-content-start">
              <div className="mt-1">
                <h5>{headerData?.title}</h5>
                <Breadcrumb separator=">">
                  {headerData?.breadcrumbs?.map((item) => {
                    return <Breadcrumb.Item>{item}</Breadcrumb.Item>;
                  })}
                </Breadcrumb>
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex">
          {headerData.title === "Expo Map" &&
          (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
            <div className="header_events_list mt-2">
              <Select
                showSearch
                placeholder="Select Event"
                name="event_id"
                allowClear
                className="brands_event_search"
                disabled={eventList && eventList.length < 2 ? true : false}
                value={initial_event_id}
                onChange={(val) => {
                  dispatch(setHeaderNewEventId(val));
                }}
                optionFilterProp="children"
                listHeight={120}
                listItemHeight={4}
                bordered={false}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}

          <span className="mx-2 d-flex">
            <span className="mx-2">
              <Badge count={5}>
                <BellOutlined style={{ fontSize: "22px" }} />
              </Badge>
            </span>
            <span
              className="mx-2"
              onClick={() => {
                history.push("/myprofile");
              }}
            >
              <Avatar src={logo}> {userName.slice(0, 1)}</Avatar>
              {/* {logo ? (
                <img
                  src={
                    logo && logo
                      ? logo
                      : // <Avatar><span>{userName.slice(0, 1)}</span>
                        // 	<span>{nameArray[1].slice(0, 1)}</span></Avatar>
                        ""
                  }
                  alt="logo"
                  style={{ height: "34px", width: "34px", borderRadius: "50%" }}
                />
              ) : (
                <Avatar>
                  <span>{userName.slice(0, 1)}</span>
                </Avatar>
              )} */}

              {/* </Avatar> */}
            </span>
            <span
              className="my-2 mx-2 d-flex flex-column"
              style={{ lineHeight: "20px" }}
            >
              <small className="d-inline-block fs-12 text-secondary">
                {userType}
              </small>
              <span className="d-inline-block">{userName}</span>
            </span>
            <span className="mx-2">
              <Dropdown
                overlayStyle={{
                  width: "200px",
                }}
                overlay={menu}
              >
                <div>
                  <DownOutlined style={{ fontSize: "15px" }} />
                </div>
              </Dropdown>
            </span>
          </span>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
