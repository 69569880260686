import React from 'react';

export function itemRender(current, type, originalElement) {
    if (type === "prev") {
        return <a href="#0">Previous</a>;
    }
    if (type === "next") {
        return <a href="#0">Next</a>;
    }
    console.log("originalElement", originalElement);
    return originalElement;
}

export function onShowSizeChange(current, pageSize,originalElement ) {
    
    console.log("originalElement", originalElement);

}