import React, { useState, useEffect, useRef } from "react";
import "./MyProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Row, Col, Switch, Modal } from "antd";
import { BinWhite } from "../../components/svgJS/Bin";
import { PadLock } from "../../components/svgJS/PadLock";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import { getCountryList, getJobTitles } from "../../utils/commonApis";
import FileUpload from "../common/FileUpload";
import { getProfileDetails, updateProfile } from "../../redux/slice/myProfile";
import MyProfileCustomer from "./MyProfileCustomer";

const MyProfile = (props) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [profileLocalData, setProfileLocalData] = useState({});
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [previewPic, setPreviewPic] = useState();
  const [profPic, setProfPic] = useState();
  const [existingPass, setExistingPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [customerProfileData, setCustomerProfileData] = useState(null);
  const [checkCustomer, setCheckCustomer] = useState(false);

  useEffect(async () => {
    setLoading(true);

    if (props.otherUser)
      await dispatch(getProfileDetails({ user_id: props.otherUser.user_id }));
    else await dispatch(getProfileDetails({}));
    const countriesHere = await getCountryList();
    setCountries(countriesHere);
    const jobTitlesHere = await getJobTitles();
    setJobTitles(jobTitlesHere);
    setLoading(false);
  }, []);

  useEffect(async () => {
    //Check if its not a customer
    if (!profileData?.customer_id) {
      setCheckCustomer(false);
      setProfileLocalData({
        user_profile_details: {
          first_name: profileData?.user_profile_details?.first_name ?? "",
          last_name: profileData?.user_profile_details?.last_name ?? "",
          fullname: `${profileData?.user_profile_details?.first_name} ${profileData?.user_profile_details?.last_name}`,
          email: profileData?.user_profile_details?.email ?? "",
          bio: profileData?.user_profile_details?.bio ?? "",
          company: profileData?.user_profile_details?.company ?? "",
          job_title_id: profileData?.user_profile_details?.job_title_id ?? null,
          country_id: profileData?.user_profile_details?.country_id ?? null,
          image_url:
            profileData && profileData?.user_profile_details?.image_url,
          user_type: profileData?.user_profile_details?.user_type ?? "",
        },
        external_social_links_details:
          profileData?.external_social_links_details?.map((item) => {
            return {
              user_social_id: item?.user_social_id ?? null,
              social_media_url: item?.social_media_url ?? "",
              social_media_id: item?.social_media_id ?? null,
              social_media: item?.social_media ?? "",
              sequence: item?.sequence ?? null,
              is_active: item?.is_active ?? false,
            };
          }),
        in_app_notifications: profileData?.in_app_notifications?.map((item) => {
          return {
            notification_settings_id: item?.notification_settings_id ?? null,
            is_enabled: item?.is_enabled ?? false,
            notification_type_id: item?.notification_type_id ?? null,
            notification_type: item?.notification_type ?? "",
            notification_title: item?.notification_title ?? "",
            sequence: item?.sequence ?? null,
            is_active: item?.is_active ?? false,
          };
        }),
        email_notifications: profileData?.email_notifications?.map((item) => {
          return {
            notification_settings_id: item?.notification_settings_id ?? null,
            is_enabled: item?.is_enabled ?? false,
            notification_type_id: item?.notification_type_id ?? null,
            notification_type: item?.notification_type ?? "",
            notification_title: item?.notification_title ?? "",
            sequence: item?.sequence ?? null,
            is_active: item?.is_active ?? false,
          };
        }),
      });
      setPreviewPic(profileData?.user_profile_details?.image_url);
    } else {
      setCheckCustomer(true);
      console.log("New Dat set");
      setCustomerProfileData({
        ...profileData,
      });
    }
  }, [profileData]);

  const handleImgChange = (e) => {
    setProfPic(e.target.files[0]);
    setPreviewPic(URL.createObjectURL(e.target.files[0]));
  };

  const updateProfileAPI = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      let dataToSend = profileLocalData;

      let arr = dataToSend?.user_profile_details?.fullname.split(" ");

      let firsName = arr.shift();

      let lastName = arr.join(" ");

      dataToSend.user_profile_details.first_name = firsName;
      dataToSend.user_profile_details.last_name = lastName;
      delete dataToSend.user_profile_details.email;
      delete dataToSend.user_profile_details.image_url;

      if (existingPass && newPass) {
        dataToSend.user_profile_details.existing_password = existingPass;
        dataToSend.user_profile_details.new_password = newPass;
      }

      let formData = new FormData();
      formData.append("data", JSON.stringify(dataToSend));
      formData.append("profile_picture", profPic);
      const updateRes = await dispatch(updateProfile(formData));
      if (updateRes?.payload?.code === 200) {
        await dispatch(getProfileDetails());
        toast.success(updateRes.payload.message);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("in error update profile");
        if (updateRes?.payload?.code !== 200)
          toast.error(updateRes.payload.message);
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  if (loading) return <CommonLoader />;
  else
    return (
      <>
        {!props.otherUser ? (
          <>
            <h4 className="mb-1">My Profile</h4>
            <span className="opacity-50 ">My Profile</span>
          </>
        ) : null}

        {!checkCustomer ? (
          <div className="w-100 mt-2 p-4 bg-white">
            <Row className="border-bottom">
              <Col className="d-flex flex-column align-items-center" span={5}>
                <div className="myprofile_img_container d-flex mb-3 justify-content-center align-items-center">
                  <img
                    src={previewPic}
                    className={props.otherUser ? "w-50 h-50" : "w-100 h-100"}
                  />
                </div>
                {!props.otherUser ? (
                  <FileUpload
                    labelClassName="rounded border-pink btn-svg px-3  py-1"
                    labelTitle="Upload"
                    inputType="file"
                    inputOnChange={handleImgChange}
                    inputId="my_profile_logo"
                    inputName="my_profile_logo"
                    name="logo"
                  />
                ) : null}
              </Col>
              <Col offset={2} span={14}>
                <Row>
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1">Name</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <Input
                      disabled={props.otherUser ? true : false}
                      name="myprofile_nameInput"
                      className="myprofile_Input"
                      placeholder="Enter a name here"
                      value={profileLocalData?.user_profile_details?.fullname}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            fullname: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_nameInput")
                      }
                    />
                    {validator.current.message(
                      "Name",
                      profileLocalData?.user_profile_details?.fullname,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1 align-self-start">Bio</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <TextArea
                      name="myprofile_textAreaInput"
                      id="myprofile_textAreaInput"
                      className="myprofile_textAreaInput"
                      placeholder="Enter Bio"
                      rows={4}
                      value={profileLocalData?.user_profile_details?.bio}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            bio: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor(
                          "myprofile_textAreaInput"
                        )
                      }
                    />
                    {validator.current.message(
                      "Bio",
                      profileLocalData?.user_profile_details?.bio,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1">Company</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <Input
                      name="myprofile_company"
                      className="myprofile_Input"
                      placeholder="Enter Company Name here"
                      value={profileLocalData?.user_profile_details?.company}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            company: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_company")
                      }
                    />
                    {validator.current.message(
                      "Company",
                      profileLocalData?.user_profile_details?.company,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1">Email</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <Input
                      disabled
                      name="myprofile_email"
                      className="myprofile_Input"
                      placeholder="Enter Email"
                      value={profileLocalData?.user_profile_details?.email}
                      onChange={(e) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            email: e.target.value,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_email")
                      }
                    />
                    {validator.current.message(
                      "Email",
                      profileLocalData?.user_profile_details?.email,
                      "required|email",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1">Job Role</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <Select
                      showSearch
                      name="myprofile_jobrole"
                      bordered={false}
                      className="myprofile_select w-100"
                      placeholder="Enter Job Role"
                      value={
                        profileLocalData?.user_profile_details?.job_title_id
                      }
                      onChange={(val) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            job_title_id: val,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_jobrole")
                      }
                    >
                      {jobTitles &&
                        jobTitles.map((item, i) => {
                          return (
                            <Option
                              key={item.job_title_id + "_jobtitle"}
                              value={item.job_title_id}
                            >
                              {item.job_title}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "",
                      profileLocalData?.user_profile_details?.job_title_id,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  >
                    <span className="mr-1">Country</span>
                  </Col>
                  <Col offset={1} span={18}>
                    <Select
                      showSearch
                      name="myprofile_country"
                      bordered={false}
                      className="myprofile_select w-100 "
                      placeholder="Enter Country"
                      value={profileLocalData?.user_profile_details?.country_id}
                      onChange={(val) =>
                        setProfileLocalData({
                          ...profileLocalData,
                          user_profile_details: {
                            ...profileLocalData.user_profile_details,
                            country_id: val,
                          },
                        })
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("myprofile_country")
                      }
                    >
                      {countries &&
                        countries.map((item, i) => {
                          return (
                            <Option
                              key={item.brand_id + "_country"}
                              value={item.country_id}
                            >
                              {item.country}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "",
                      profileLocalData?.user_profile_details?.country_id,
                      "required",
                      {
                        className: "text-danger py-1",
                      }
                    )}
                  </Col>
                </Row>

                <Row className="mt-3 mb-5">
                  <Col
                    className="d-flex justify-content-end align-items-center"
                    span={2}
                  ></Col>
                  {!props.otherUser ? (
                    <Col offset={1} span={18}>
                      <div>
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          className="myprofile_passwordBtn"
                        >
                          <PadLock />
                          <span className="ms-2">Change Password</span>
                        </Button>

                        <Button className="myprofile_delete ms-3">
                          <BinWhite />
                          <span className="ms-2">Delete Account</span>
                        </Button>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
            {!props.otherUser ? (
              <>
                <div className="mt-5 ">
                  <h5 className="fs-pink">External & Social Links</h5>
                  <p>
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </p>
                </div>

                <Row className="border-bottom pb-4">
                  <Col span={5}></Col>
                  <Col offset={2} span={14}>
                    {profileLocalData?.external_social_links_details &&
                      profileLocalData?.external_social_links_details.map(
                        (item) => {
                          if (item.is_active)
                            return (
                              <Row className="mt-3">
                                <Col
                                  className="d-flex justify-content-end align-items-center"
                                  span={2}
                                >
                                  <span>{item?.social_media}</span>
                                </Col>
                                <Col
                                  className="d-flex align-items-center"
                                  offset={1}
                                  span={2}
                                >
                                  <Switch
                                    checked={item?.social_media_url}
                                    onChange={(val) =>
                                      setProfileLocalData({
                                        ...profileLocalData,
                                        external_social_links_details:
                                          profileLocalData?.external_social_links_details.map(
                                            (elem) => {
                                              if (
                                                elem.social_media ===
                                                item.social_media
                                              ) {
                                                return {
                                                  ...elem,
                                                  social_media_url:
                                                    !item.social_media_url,
                                                };
                                              } else {
                                                return elem;
                                              }
                                            }
                                          ),
                                      })
                                    }
                                  />
                                </Col>
                                <Col span={10}>
                                  <Input
                                    disabled={!item?.social_media_url}
                                    defaultValue={item?.social_media_url}
                                    onChange={(e, i) => {
                                      if (item?.is_active) {
                                        setProfileLocalData({
                                          ...profileLocalData,
                                          external_social_links_details:
                                            profileLocalData?.external_social_links_details.map(
                                              (val) => {
                                                if (
                                                  item?.social_media ===
                                                  val?.social_media
                                                ) {
                                                  return {
                                                    ...val,
                                                    social_media_url:
                                                      e.target.value,
                                                  };
                                                } else return val;
                                              }
                                            ),
                                        });
                                      }
                                    }}
                                    placeholder="https://webaddress.com"
                                    className="myprofile_Input"
                                  />
                                </Col>
                              </Row>
                            );
                        }
                      )}
                  </Col>
                </Row>

                <div className="mt-5 mb-4">
                  <h5 className="fs-pink">In-app Notifications</h5>
                </div>
                <Row className="mt-4">
                  <Col className="d-flex justify-content-end" span={9}>
                    <span className="mx-5">
                      All (off = do not disturb mode)
                    </span>
                  </Col>
                  <Col span={14}>
                    <Switch
                      onChange={(val) => {
                        setProfileLocalData({
                          ...profileLocalData,
                          in_app_notifications:
                            profileLocalData?.in_app_notifications.map(
                              (elem) => {
                                return {
                                  ...elem,
                                  is_enabled: false,
                                };
                              }
                            ),
                        });
                      }}
                    />
                  </Col>
                </Row>

                {profileLocalData?.in_app_notifications &&
                  profileLocalData?.in_app_notifications.map((item) => {
                    return (
                      <Row className="mt-4">
                        <Col className="d-flex justify-content-end" span={9}>
                          <span className="mx-5">
                            {item?.notification_title}
                          </span>
                        </Col>
                        <Col span={14}>
                          <Switch
                            checked={item?.is_enabled}
                            onChange={(val) => {
                              setProfileLocalData({
                                ...profileLocalData,
                                in_app_notifications:
                                  profileLocalData?.in_app_notifications.map(
                                    (elem) => {
                                      if (
                                        item?.notification_title ===
                                        elem?.notification_title
                                      ) {
                                        return {
                                          ...elem,
                                          is_enabled: !item?.is_enabled,
                                        };
                                      } else {
                                        return elem;
                                      }
                                    }
                                  ),
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}

                {profileLocalData?.user_profile_details?.user_type ===
                "DELEGATE" ? (
                  <>
                    <div className="mt-5 mb-4">
                      <h5 className="fs-pink">Email Notifications</h5>
                    </div>
                    <Row className="mt-4">
                      <Col className="d-flex justify-content-end" span={9}>
                        <span className="mx-5">
                          All (off = do not disturb mode)
                        </span>
                      </Col>
                      <Col span={14}>
                        <Switch
                          onChange={(val) => {
                            setProfileLocalData({
                              ...profileLocalData,
                              email_notifications:
                                profileLocalData?.email_notifications.map(
                                  (elem) => {
                                    return {
                                      ...elem,
                                      is_enabled: false,
                                    };
                                  }
                                ),
                            });
                          }}
                        />
                      </Col>
                    </Row>

                    {profileLocalData?.email_notifications &&
                      profileLocalData?.email_notifications.map((item) => {
                        return (
                          <Row className="mt-4">
                            <Col
                              className="d-flex justify-content-end"
                              span={9}
                            >
                              <span className="mx-5">
                                {item?.notification_title}
                              </span>
                            </Col>
                            <Col span={14}>
                              <Switch
                                checked={item?.is_enabled}
                                onChange={(val) => {
                                  setProfileLocalData({
                                    ...profileLocalData,
                                    email_notifications:
                                      profileLocalData?.email_notifications.map(
                                        (elem) => {
                                          if (
                                            item?.notification_title ===
                                            elem?.notification_title
                                          ) {
                                            return {
                                              ...elem,
                                              is_enabled: !item?.is_enabled,
                                            };
                                          } else return elem;
                                        }
                                      ),
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                  </>
                ) : null}
              </>
            ) : null}

            <Modal
              title="Change Password"
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
              width={600}
            >
              <div className="profile_modal mx-5 mt-4">
                <h6>Existing Password:</h6>
                <Input
                  name="myprofile_existing_pass"
                  className="myprofile_Input"
                  placeholder="Enter Existing Password"
                  value={existingPass}
                  onChange={(e) => setExistingPass(e.target.value)}
                />
                <h6 className="mt-4">New Password:</h6>

                <Input
                  name="myprofile_new_pass"
                  className="myprofile_Input"
                  placeholder="Enter New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </div>
            </Modal>
            {!props.otherUser ? (
              <div className="d-flex justify-content-end">
                <Button
                  size={"large"}
                  type="primary"
                  onClick={updateProfileAPI}
                >
                  Update
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <MyProfileCustomer
            getProfileDetails={getProfileDetails}
            profileData={customerProfileData}
          />
        )}
      </>
    );
};

export default MyProfile;
