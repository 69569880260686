import React, { useState, useEffect, useRef } from "react";
import "../eventCalender.css";
import { Modal, Row, Col, Button, Carousel, Input, Tooltip } from "antd";
import { getNetworkLoungeSession } from "../../../utils/commonApis";
import { Booth } from "../../../components/svgJS/Booth";
import { Chat } from "../../../components/svgJS/Chat";
import { Video } from "../../../components/svgJS/Video";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";
import { BoothRed } from "../../../components/svgJS/BoothRed";
import { toast } from "react-toastify"
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../../utils/commonApis";
import { COMETCHAT_CONSTANTS } from "../../../components/constsnts/common.constants";
import { CometChat } from "@cometchat-pro/chat";
import Networking from "../../networking";
import { videoRequestAPI } from "../../../utils/commonApis";

const NetworkModalView = ({ sessionData, title, onSuccess, onCloseModal }) => {

  const { TextArea } = Input;
  const messageValidator = useRef(new SimpleReactValidator());

  const [networkSessionAllViewData, setNetworkSessionAllViewData] = useState(
    {}
  );

  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [forceUpdate, setForceUpdate] = useState(false)
  const [message_text, set_message_text] = useState("");
  const [tableView, setTableView] = useState(false)
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });


  useEffect(() => {
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
  }, [])
  useEffect(async () => {
    if (sessionData) {
      let sessionAPIData = await getNetworkLoungeSession({
        networking_configuration_id: sessionData.networking_configuration_id,
        networking_session_id: sessionData.networking_session_id,
      });
      setNetworkSessionAllViewData(sessionAPIData);
    }
  }, []);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }
  return (
    <>
      <Modal
        title="Networking Session View"
        visible={true}
        onOk={() => onCloseModal(false)}
        onCancel={() => onCloseModal(false)}
        className=""
        width={tableView == true ? 1100 : 700}
        footer={null}
      >
        {tableView == true ?
          <div>
            <Networking
              fromEventCalendar={true}
              networking_session_id={networkSessionAllViewData?.networking_session_id}
              networking_configuration_id={networkSessionAllViewData.networking_configuration_id}
            />
          </div>
          :
          <div className="demoroom_container w-100 p-4">
            <span className="fs-18">Title :</span>
            <h5>{networkSessionAllViewData?.session_title}</h5>
            <span className="fs-18">Time :</span>
            <h6>
              {networkSessionAllViewData?.start_time} -
              {networkSessionAllViewData?.end_time}
            </h6>
            <span className="fs-18">Description :</span>
            <p className="fs-16">
              <b>{networkSessionAllViewData?.session_description} </b>
            </p>
            <Row className="mb-3">
              <Col span={12}>
                <span className="opacity-75 fs-18">Seats per Table :</span> <br />
                <span>
                  <b>{networkSessionAllViewData?.seats_per_table ?? "NA"} </b>
                </span>
                <br />
                <span className="opacity-75 fs-18">Shuffle Time :</span> <br />
                <span>
                  <b>{networkSessionAllViewData?.mins_per_shuffle ?? "NA"} </b>
                </span>
                <br />
                <span className="opacity-75 fs-18">Waterfall (Intro) Time :</span>
                <br />
                <span>
                  <b>{networkSessionAllViewData?.seconds_per_intro ?? "NA"} </b>
                  seconds
                </span>
                <br />
                <span className="opacity-75 fs-18">Attending Live Currently :</span>
                <br />
                <span>
                  <b>1,432</b>
                </span>
                <br />
              </Col>
              <Col span={12}>
                <span className="opacity-75 fs-18">Speed Dating Enabled? :</span>
                <br />
                <span>
                  <b>
                    {networkSessionAllViewData?.is_speed_dating_enabled
                      ? "Yes"
                      : "No"}
                  </b>
                </span>
                <br />
                <span className="opacity-75 fs-18">Waterfall Intros Enabled :</span>
                <br />
                <span>
                  <b>
                    {networkSessionAllViewData?.is_waterfall_intros_enabled
                      ? "Yes"
                      : "No"}
                  </b>
                </span>
                <br />
                <span className="opacity-75 fs-18">Provisionally Attending :</span>
                <br />
                <span>
                  <b>1,732</b>
                </span>
                <br />

              </Col>
              <Col span={12}>
                {networkSessionAllViewData.is_iCal_download_enabled == true ?
                  <Button className="mt-2 networking_downloadBtn">
                    <span className="">Download iCal </span>
                  </Button>
                  :

                  ""
                }
              </Col>
              <Col span={12}>
                <Button
                  className="joinsess_calendarBtn mt-2"
                  disabled={
                    networkSessionAllViewData?.added_to_my_calendar === true
                      ? true
                      : false
                  }
                >
                  <CalenderGrey />
                  <span className="ms-2">Add to My Calendar </span>
                </Button>
              </Col>
            </Row>

            <h5 className="mb-3">Sponsored by</h5>
            {messageModal.isOpen ? (
              <Modal
                title={<b>Send Message</b>}
                visible={messageModal.isOpen}
                onOk={() => clearMessageAndModal()}
                onCancel={() => clearMessageAndModal()}
                footer={[
                  <Button
                    className="px-3 button-pink rounded"
                    key="cancel"
                    onClick={() => clearMessageAndModal()}
                  >
                    Cancel
                  </Button>,
                  <Button
                    className="px-3 rounded"
                    key="save"
                    type="primary"
                    onClick={() => callSendMessageAPI(messageModal.channel_id)}
                  >
                    Send
                  </Button>,
                ]}
                className="message_modal"
              >
                <Row className="ticker_configuration_row mt-2">
                  <Col span={6} className="ticker_configuration_label">
                    <p>
                      Message
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name="message_text"
                      id="message_text"
                      className="ticker_configuration_inputDescription"
                      placeholder="Enter a message"
                      rows={4}
                      value={message_text}
                      onChange={(e) => set_message_text(e.target.value)}
                      onBlur={() =>
                        messageValidator.current.showMessageFor("message_text")
                      }
                    />
                    {messageValidator.current.message(
                      "Message",
                      message_text,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
              </Modal>
            ) : null}

            <Carousel
              className="w-75 mx-auto"
              arrows
              nextArrow={
                <div>
                  <i class="arrow right"></i>
                </div>
              }
              prevArrow={
                <div>
                  <i class="arrow left"></i>
                </div>
              }
            >
              {networkSessionAllViewData?.networking_lounge_sponsoring_brands &&
                networkSessionAllViewData?.networking_lounge_sponsoring_brands?.map(
                  (data) => {
                    return (
                      <div className="networksess_card_container p-2 d-flex  align-items-center mt-2">
                        <div className="mx-2">
                          <img
                            width={150}
                            height={150}
                            className="img-responsive"
                            src={
                              data.logo_url
                                ? data.logo_url
                                : "https://www.emotivebrand.com/wp-content/uploads/2016/09/tumblr_o05v3eZmyT1ugn1wu_og_1280-1080x675.png"
                            }
                          />
                        </div>
                        <div>
                          <p className="fs-16 mx-3">
                            <b>{data?.brand_title}</b>
                          </p>
                          <p className="fs-16 mx-3">{data?.brand_tier}</p>
                          <Button className="audi_pdfBtn mb-3" onClick={() => (window.location.href = data?.asset_file_url)}>
                            <span className="ms-2 fs-16">sponsorasset.pdf</span>
                          </Button>
                          <div className="d-flex">

                          </div>
                          <div className="d-flex mx-1">
                            {data?.is_booth_link_enabled ? (
                              <div
                                className="border mx-1 p-3"
                              >
                                <BoothRed />
                                <span className="fs-pink"> </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {data?.is_chat_enabled ? (
                              <div
                                style={{ border: "none" }}
                                className="border-none border mx-1 p-3"
                                onClick={() => {
                                  setMessageModal({
                                    isOpen: true,
                                    channel_id:
                                      data.channel_id
                                  });
                                }}
                              >
                                <Chat />
                              </div>
                            ) : (
                              ""
                            )}

                            {data?.is_video_call_enabled ? (
                              <div className="border mx-1 p-3"
                                onClick={async () => {

                                  const response = await videoRequestAPI(data.channel_id);
                                  if (response.data.code === 200)
                                    toast.success(response.data.message);
                                  else toast.error(response.data.message);
                                }}
                              // className="social_video_btn ms-2 mb-2"
                              >
                                <Video />
                              </div>
                            ) : (
                              ""
                            )}
                            {data?.is_demo_room_enabled ? (
                              <div
                                className="border p-3 mx-1"
                                onClick={() => window.open(data?.zone_demo_room_link, "_blank").focus()}
                              >
                                <Video />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </Carousel>

            <div className="w-100 d-flex mt-4 mb-4">
              <div className="w-50"></div>
              {/* <Button className="networkingsess_deleteBtn  fs-16">
                    Delete Event
                  </Button> */}
              <Button
                className="networkingsess_audiBtn mx-4 fs-16"
                onClick={() => setTableView(true)}
              >
                Join Session
              </Button>
            </div>
          </div >
        }
      </Modal>

    </>
  );
};

export default NetworkModalView;
