import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";

const initialState = {
  updateSocialWallEventList: {},
  loading: false,
};

export const updateSocialWallEvent = createAsyncThunk(
  "updateSocialWallEvent/updateSocialWallEvent",

  async (params) => {
    console.log("params", params);
    const response = await ApiCall(
      "PUT",
      params,
      "/admin/events/socialwall/update",
      "",
      ""
    );
    console.log("create response updateSocialWallEvent", response);

    return response;
  }
);

export const updateSocialWallEventSlice = createSlice({
  name: "updateSocialWallEvent",
  initialState,
  reducers: {},
  extraReducers: {
    [updateSocialWallEvent.pending]: (state) => {
      state.loading = true;
    },
    [updateSocialWallEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.loading = false;
      state.updateSocialWallEventList = payload;
    },
    [updateSocialWallEvent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const updateSocialWallEventReducer = updateSocialWallEventSlice.reducer;
